import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MasterTable from "components/MasterTable/MasterTable";
import { modalActions } from "actions/modalActions";
import {
  getNativeClosedBalances,
  getNativeOpenedBalances,
  getOpenedPortfolioBalances,
  getPortfolioClosedBalances,
  getPortfolioOpenedBalances,
} from "selectors/portfoliosSelectors";
import { getGlobalFetchingState } from "selectors/globalSelectors";
import { Heading, LoaderOverlay, Tab, Tabs } from "components/Common";
import { formatDate } from "common/formatters";
import { useTranslation } from "react-i18next";
import TransactionsTooltip from "components/Tooltips/TransactionsTooltip";
import cx from "classnames";
import styles from "../Styles/Dashboard.module.scss";
import { getIsWalkthroughEnabled } from "selectors/walkthroughSelectors";

const LegacyPositions = ({
  selectedDate,
  nativeOpened,
  nativeClosed,
  portfolioOpened,
  portfolioClosed,
  isBalancesFetching,
  isWalkthroughEnabled,
  modalActions: { openBaseQuote },
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.master_table}>
      <div className={styles.master_table_header}>
        <Heading level={3} margin="0 0 1rem 0">
          {t("dashboard.master_table.table_header")}{" "}
          {selectedDate && <span className={styles.small}>{`(${formatDate(selectedDate)})`}</span>}
        </Heading>
      </div>
      <LoaderOverlay isLoading={isBalancesFetching}>
        <Tabs
          HeaderComponent={({ children }) => (
            <div
              className={cx(styles.master_table_header, "mb-2")}
              style={isWalkthroughEnabled ? undefined : { overflowX: "scroll" }}>
              {children}
              <TransactionsTooltip />
            </div>
          )}>
          <Tab title={t("dashboard.master_table.accounting_currency_tab")}>
            <MasterTable
              open={portfolioOpened}
              close={portfolioClosed}
              openBaseQuote={base => openBaseQuote(base)}
              isWalkthroughEnabled={isWalkthroughEnabled}
            />
          </Tab>
          <Tab title={t("dashboard.master_table.trade_currency_tab")}>
            <MasterTable
              open={nativeOpened}
              close={nativeClosed}
              openBaseQuote={(base, quote) => openBaseQuote(base, quote)}
              isWalkthroughEnabled={isWalkthroughEnabled}
            />
          </Tab>
        </Tabs>
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  const { timestamp, isBalancesFetching } = getOpenedPortfolioBalances(state);
  return {
    selectedDate: timestamp,
    nativeOpened: getNativeOpenedBalances(state),
    nativeClosed: getNativeClosedBalances(state),
    portfolioOpened: getPortfolioOpenedBalances(state),
    portfolioClosed: getPortfolioClosedBalances(state),
    isBalancesFetching: isBalancesFetching === true && !getGlobalFetchingState(state),
    isWalkthroughEnabled: getIsWalkthroughEnabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LegacyPositions);
