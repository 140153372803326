import React from "react";
import Benefit1 from "img/benefit-1.png";
import Benefit2 from "img/benefit-2.png";
import Benefit3 from "img/benefit-3.png";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "hooks";
import { Heading } from "../Common";
import styles from "../Styles/Landing.module.scss";

export default () => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <>
      {isSmall ? (
        <div>
          <div className={styles.feature}>
            <div>
              <Heading level={2} margin="0 0 2rem 0">
                {t("landing.features.track_portfolio.heading")}
              </Heading>
              <img src={Benefit3} alt="" />
            </div>
            <div className={styles.paragraph}>{t("landing.features.track_portfolio.content")}</div>
          </div>

          <div className={styles.feature}>
            <div>
              <Heading level={2} margin="0 0 2rem 0">
                {t("landing.features.manage_transactions.heading")}
              </Heading>
              <img src={Benefit2} alt="" />
            </div>
            <div className={styles.paragraph}>{t("landing.features.manage_transactions.content")}</div>
          </div>

          <div className={styles.feature}>
            <div>
              <Heading level={2} margin="0 0 2rem 0">
                {t("landing.features.handle_taxes.heading")}
              </Heading>
              <img src={Benefit1} alt="" />
            </div>
            <div className={styles.paragraph}>{t("landing.features.handle_taxes.content")}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.feature}>
            <div>
              <Heading level={2} margin="0 0 2rem 0">
                {t("landing.features.track_portfolio.heading")}
              </Heading>
              <div className={styles.paragraph}>{t("landing.features.track_portfolio.content")}</div>
            </div>
            <img src={Benefit3} alt="" />
          </div>

          <div className={styles.feature}>
            <img src={Benefit2} alt="" />
            <div>
              <Heading level={2} margin="0 0 2rem 0">
                {t("landing.features.manage_transactions.heading")}
              </Heading>
              <div className={styles.paragraph}>{t("landing.features.manage_transactions.content")}</div>
            </div>
          </div>

          <div className={styles.feature}>
            <div>
              <Heading level={2} margin="0 0 2rem 0">
                {t("landing.features.handle_taxes.heading")}
              </Heading>
              <div className={styles.paragraph}>{t("landing.features.handle_taxes.content")}</div>
            </div>
            <img src={Benefit1} alt="" />
          </div>
        </div>
      )}
    </>
  );
};
