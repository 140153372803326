import React from "react";
import { Formik } from "formik";
import { DateFormField, SelectFormField } from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { formatDate, utc } from "common/formatters";
import { adminReportTypeOptions } from "common/constants/adminReportTypes";
import { object, string } from "yup";

const DownloadAdminReportForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const typeOptions = adminReportTypeOptions(t);

  return (
    <Formik
      initialValues={{
        type: typeOptions[0],
        changeFrom: formatDate(utc().add(-7, "d")),
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        type: object().required(t("validation.required")),
        changeFrom: string().required(t("validation.required")).nullable(),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <SelectFormField
            name="type"
            options={typeOptions}
            label={t("form.download_admin_report.type")}
            required
            disabled={isSubmitting}
          />

          <DateFormField
            name="changeFrom"
            label={t("form.download_admin_report.change_from")}
            required
            disabled={isSubmitting}
            isClearable
          />

          <PrimaryButton label={t("form.download_admin_report.download")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default DownloadAdminReportForm;
