import React from "react";
import { Form, CheckBoxGroup } from "grommet";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "components/Common";
import { TextFormField, CustomFormField } from "components/Common/Inputs";
import { userCategories, userCategoriesTranslator, OTHER } from "common/constants/userCategories";
import { object, string } from "yup";

import styles from "../Styles/Forms.module.scss";

const UserCategoryForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();
  const options = userCategories.map(o => ({
    value: o,
    label: t(`${userCategoriesTranslator(o)}`),
  }));

  return (
    <Formik
      initialValues={{
        userCategories: [],
        ...initialValues,
        otherUserCategory: initialValues?.otherUserCategory || "",
      }}
      validationSchema={object({
        otherUserCategory: string().when("userCategories", {
          is: uc => uc.find(o => o === OTHER) !== undefined,
          then: string().required(),
        }),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className={styles.grommet_form}>
          <CustomFormField name="userCategories" disabled={isSubmitting} className={styles.user_categories_field} plain>
            <CheckBoxGroup
              options={options}
              labelKey="label"
              valueKey="value"
              disabled={isSubmitting}
              onChange={e => setFieldValue("userCategories", e.value)}
              value={values.userCategories}
            />
          </CustomFormField>
          {values.userCategories.find(o => o === OTHER) && (
            <TextFormField
              name="otherUserCategory"
              label={t("form.user_category.description")}
              disabled={isSubmitting}
              required
            />
          )}
          <PrimaryButton label={t("form.user_category.save")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default UserCategoryForm;
