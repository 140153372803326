import axios from "axios";
import queryString from "query-string";
import FileSaver from "file-saver";
import * as contentDisposition from "content-disposition";
import { useDefaultsStore } from "stores/defaultsStore";
import { formatDate, utc } from "common/formatters";
import { losslesslyParseJSON, mapTransaction } from "utils";
import { DATA_CHANGED } from "common/constants/apiConnectionInputs";
import { mapJob } from "./jobService";

const cancelToken = axios.CancelToken;
let getTransactionsCancelToken;
let getContainersCancelToken;

let disableGetContainers = false;

const mapIncomingContainers = ({ apiTransactionContainers, csvTransactionContainers, manualTransactionContainers }) => {
  const apis = apiTransactionContainers.map(({ transactionContainer, ...additionalInfo }) => ({
    ...transactionContainer,
    ...additionalInfo,
  }));
  const csvs = csvTransactionContainers.map(({ transactionContainer, fileImport }) => ({
    ...transactionContainer,
    fileImport: mapJob(fileImport),
  }));
  return [...apis, ...csvs, ...manualTransactionContainers];
};

async function getContainers({ organizationId, vaultId }) {
  if (disableGetContainers) return Promise.reject();

  if (getContainersCancelToken) getContainersCancelToken.cancel();
  getContainersCancelToken = cancelToken.source();

  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/containers`,
    },
    { skipNull: true }
  );

  const response = await axios.get(query, { cancelToken: getContainersCancelToken.token });

  return mapIncomingContainers(response.data);
}

function toggleGetContainers(enable = false) {
  if (enable) {
    disableGetContainers = false;
  } else {
    disableGetContainers = true;
    if (getContainersCancelToken) getContainersCancelToken.cancel();
  }
}

async function getContainersAssignmentInfo({ organizationId, vaultId }, portfolioId) {
  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/containers`,
      query: { "portfolio-id": portfolioId },
    },
    { skipNull: true }
  );

  const response = await axios.get(query);

  return mapIncomingContainers(response.data);
}

async function getContainerDetail(containerId, { organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}`);
  return response.data;
}

async function getContainerDetailCurrencyAccounts(containerId, { organizationId, vaultId }) {
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/currency-accounts`
  );
  return response.data;
}

async function getContainerDetailAccounts(containerId, { organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/accounts`);
  return response.data;
}

async function getContainerDetailPairs(containerId, { organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/pair-amounts`);
  return response.data;
}

async function updateContainer(
  { id, name, type, accountIds, source, references, ...container },
  portfolioId,
  { organizationId, vaultId }
) {
  const response = await axios.put(`organizations/${organizationId}/vaults/${vaultId}/containers/${id}`, {
    name,
    portfolioId,
    connectorId: container.connection?.connectorId,
    type,
    accountIds,
    source: source || null,
    references,
  });
  return response.data;
}

async function initialUploadRequest(fileName, fileSize, parts, portfolioId, { organizationId, vaultId }) {
  const response = await axios.post(`organizations/${organizationId}/vaults/${vaultId}/containers/0/file-import/files`, {
    originalFileName: fileName,
    portfolioId,
    partsCount: parts,
    sizeInKB: fileSize,
  });
  return response.data;
}

async function uploadContainerPart(id, data, partNumber, { organizationId, vaultId }, onProgress) {
  const response = await axios.post(
    `organizations/${organizationId}/vaults/${vaultId}/containers/0/file-import/files/${id}/content`,
    {
      base64FilePart: data,
      partNumber: partNumber + 1,
      sizeInKB: 0,
    },
    {
      onUploadProgress: progressEvent => onProgress(progressEvent.loaded / progressEvent.total),
    }
  );
  return response.data;
}

async function getLabels({ organizationId, vaultId }, portfolioId, includeDefaults = true) {
  const query = queryString.stringifyUrl(
    {
      url: portfolioId
        ? `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/labels`
        : `organizations/${organizationId}/vaults/${vaultId}/labels`,
      query: {
        "include-default": includeDefaults,
      },
    },
    { skipNull: true }
  );
  const response = await axios.get(query);

  return response.data;
}

async function labelTransactions(transactionIds, transactionLabel, { organizationId, vaultId }) {
  const response = await axios.post(`organizations/${organizationId}/vaults/${vaultId}/labels`, {
    portfolioTransactionIds: transactionIds,
    transactionLabels: [{ transactionLabelId: transactionLabel.value, transactionLabel: transactionLabel.label }],
  });
  return response.data;
}

async function deleteTransactionLabels(transactionIds, transactionLabel, { organizationId, vaultId }) {
  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/labels`,
      query: {
        "transaction-id": transactionIds,
        "label-id": transactionLabel.value,
      },
    },
    { skipNull: true }
  );

  const response = await axios.delete(query);
  return response.data;
}

async function getTransactions(
  { organizationId, vaultId },
  containers,
  portfolioId,
  page = 0,
  filter,
  count = useDefaultsStore.getState().perPageDefault
) {
  if (getTransactionsCancelToken) getTransactionsCancelToken.cancel();
  getTransactionsCancelToken = cancelToken.source();

  const dateFromMoment = utc(filter?.dateFrom);
  const dateFrom =
    filter?.timeFrom
      ?.set({
        year: dateFromMoment.year(),
        month: dateFromMoment.month(),
        date: dateFromMoment.date(),
      })
      .toISOString() ?? null;

  const dateToMoment = utc(filter?.dateTo);
  const dateTo =
    filter?.timeTo
      ?.set({
        year: dateToMoment.year(),
        month: dateToMoment.month(),
        date: dateToMoment.date(),
      })
      .toISOString() ?? null;

  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/transactions`,
      query: {
        "container-id": containers?.length > 0 ? containers : null,
        page: page + 1,
        count,
        "portfolio-id": portfolioId,
        base: filter?.pair?.map(x => x.base),
        quote: filter?.pair?.map(x => x.quote),
        type: filter?.type?.map(x => x.value),
        label: filter?.label?.map(x => x.value),
        currency: filter?.currency?.map(x => x.value),
        amount: filter?.amount !== "" ? filter?.amount : null,
        operator: filter?.operator?.value,
        from: dateFrom,
        to: dateTo,
      },
    },
    { skipNull: true }
  );

  const response = await axios.get(query, {
    cancelToken: getTransactionsCancelToken.token,
    transformResponse: response => losslesslyParseJSON(response),
  });

  return {
    data: response.data.map(mapTransaction),
    meta: {
      page: Number.parseInt(response.headers["page-number"]) - 1,
      total: Number.parseInt(response.headers["total-records-count"]),
    },
  };
}

async function exportContainersCsv({ organizationId, vaultId }, containers, portfolioId, page = 0, filter, count = 0) {
  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/transactions/export`,
      query: {
        "container-id": containers?.length > 0 ? containers : null,
        page,
        count,
        "portfolio-id": portfolioId,
        base: filter?.pair?.base,
        quote: filter?.pair?.quote,
        type: filter?.type?.value,
        label: filter?.label?.value,
        currency: filter?.currency?.value,
        amount: filter?.amount !== "" ? filter?.amount : null,
        operator: filter?.operator?.value,
        from: filter?.dateFrom ? formatDate(filter.dateFrom) : null,
        to: filter?.dateTo ? formatDate(filter.dateTo) : null,
      },
    },
    { skipNull: true }
  );

  const response = await axios.get(query, { responseType: "blob" });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);
  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "export.csv");
}

async function getTransaction({ txId, containerId }, { organizationId, vaultId }) {
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/transactions/${txId}`,
    {
      transformResponse: response => losslesslyParseJSON(response),
    }
  );
  return mapTransaction(response.data);
}

async function downloadTransactionContainerErrorLog(containerId, { organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/error-log`, {
    responseType: "blob",
  });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);
  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_file_error.log");
}

async function downloadIgnoredTransactionsLog(containerId, { organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/ignored-log`, {
    responseType: "blob",
  });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);
  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_ignored_transactions.log");
}

async function downloadIgnoredFeesLog(containerId, { organizationId, vaultId }) {
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/ignored-fees-log`,
    {
      responseType: "blob",
    }
  );

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);
  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_ignored_fees.log");
}

async function downloadFailedFeesLog(containerId, { organizationId, vaultId }) {
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/failed-fees-log`,
    {
      responseType: "blob",
    }
  );

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);
  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_failed_fees.log");
}

async function downloadTransactionContainerSyncLog(containerId, { organizationId, vaultId }) {
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/synchronization-log`,
    {
      responseType: "blob",
    }
  );

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);
  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_file_error.log");
}

async function setPortfolioContainerAssignmentInfo(portfolioId, transactionContainerIds, { organizationId, vaultId }) {
  const response = await axios.post(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/container-assignments`,
    transactionContainerIds
  );
  return response.data.transactionContainerIds;
}

async function createTransaction(transaction, containerId, { organizationId, vaultId }) {
  const isMulti = transaction.mainTransaction !== undefined;
  const response = await axios.post(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/transactions${isMulti ? "/multiple" : ""}`,
    transaction
  );
  return response.data;
}

async function editTransaction(transaction, transactionId, containerId, { organizationId, vaultId }) {
  const response = await axios.put(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/transactions/${transactionId}`,
    transaction
  );
  return response.data;
}

async function createNewContainer(container, portfolioId, { organizationId, vaultId }, connectorId, source) {
  const response = await axios.post(`organizations/${organizationId}/vaults/${vaultId}/containers`, {
    ...container,
    portfolioId,
    connectorId,
    source: source ?? container.source ?? null,
  });
  return response.data;
}

async function putConnection(connection, containerId, { organizationId, vaultId }) {
  const response = await axios.put(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/connection`,
    connection
  );
  return response.data;
}

async function deleteContainer(containerId, { organizationId, vaultId }) {
  const response = await axios.delete(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}`);
  return response.data;
}

async function deleteTransactions(transactionIds, { organizationId, vaultId }) {
  const response = await axios.delete(`organizations/${organizationId}/vaults/${vaultId}/containers/0/transactions/multiple`, {
    data: {
      transactionIds,
    },
  });
  return response.data;
}

async function setBulkPrice(values, { organizationId, vaultId }) {
  const response = await axios.put(`organizations/${organizationId}/vaults/${vaultId}/containers/transactions-price`, values);
  return response.data;
}

async function setBulkLabel(labels, { organizationId, vaultId }) {
  const response = await axios.put(`organizations/${organizationId}/vaults/${vaultId}/containers/transactions-label`, labels);
  return response.data;
}

async function getTransactionsFilterOptions({ organizationId, vaultId }, containerIds) {
  const query = queryString.stringifyUrl({
    url: `organizations/${organizationId}/vaults/${vaultId}/transactions-filter-option`,
    query: {
      "container-id": containerIds,
    },
  });

  const response = await axios.get(query);
  return response.data;
}

async function reloadAllContainerTransactions({ organizationId, vaultId }, containerId) {
  const query = queryString.stringifyUrl({
    url: `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/connection/reload-transactions`,
  });

  const response = await axios.put(query);
  return response.data;
}

async function fetchNewContainerTransactions({ organizationId, vaultId }, containerId) {
  const query = queryString.stringifyUrl({
    url: `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/connection/fetch-transactions`,
  });

  const response = await axios.put(query);
  return response.data;
}

async function validateApiContainerConnectionChanged(connection, containerId, { organizationId, vaultId }) {
  const response = await axios.put(
    `organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/connection/validate-update`,
    connection
  );
  return response.data.result === DATA_CHANGED;
}

export const transactionService = {
  getContainers,
  toggleGetContainers,
  getContainersAssignmentInfo,
  getContainerDetail,
  getContainerDetailCurrencyAccounts,
  getContainerDetailAccounts,
  getContainerDetailPairs,
  updateContainer,
  initialUploadRequest,
  uploadContainerPart,
  getTransactions,
  exportContainersCsv,
  getTransaction,
  getLabels,
  labelTransactions,
  deleteTransactionLabels,
  deleteTransactions,
  setPortfolioContainerAssignmentInfo,
  downloadTransactionContainerErrorLog,
  downloadIgnoredTransactionsLog,
  downloadIgnoredFeesLog,
  downloadFailedFeesLog,
  downloadTransactionContainerSyncLog,
  createNewContainer,
  putConnection,
  createTransaction,
  editTransaction,
  deleteContainer,
  setBulkPrice,
  setBulkLabel,
  getTransactionsFilterOptions,
  reloadAllContainerTransactions,
  fetchNewContainerTransactions,
  validateApiContainerConnectionChanged,
};
