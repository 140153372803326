import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { Form, GhostButton, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { object } from "yup";
import { rewardTimingOptions } from "common/constants/rewardTimingOptions";
import styles from "../Styles/Forms.module.scss";

const EditRewardsForm = ({ ruleSet, onSubmit, hideModal }) => {
  const { t } = useTranslation();

  const options = useMemo(() => rewardTimingOptions(t), [t]);

  return (
    <Formik
      initialValues={{
        STAKING_REWARD: options.find(x => x.value === ruleSet?.realizationTypes?.STAKING_REWARD) ?? options[0],
        REWARD: options.find(x => x.value === ruleSet?.realizationTypes?.REWARD) ?? options[0],
        AIRDROP: options.find(x => x.value === ruleSet?.realizationTypes?.AIRDROP) ?? options[0],
        EARNING: options.find(x => x.value === ruleSet?.realizationTypes?.EARNING) ?? options[0],
        FORK: options.find(x => x.value === ruleSet?.realizationTypes?.FORK) ?? options[0],
      }}
      validationSchema={object({
        STAKING_REWARD: object().required(),
        REWARD: object().required(),
        AIRDROP: object().required(),
        EARNING: object().required(),
        FORK: object().required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          <SelectFormField
            options={options}
            name="STAKING_REWARD"
            label={t("organization.stake_reward")}
            required
            disabled={isSubmitting}
          />
          <SelectFormField options={options} name="REWARD" label={t("organization.reward")} required disabled={isSubmitting} />
          <SelectFormField options={options} name="AIRDROP" label={t("organization.airdrop")} required disabled={isSubmitting} />
          <SelectFormField options={options} name="EARNING" label={t("organization.earn")} required disabled={isSubmitting} />
          <SelectFormField options={options} name="FORK" label={t("organization.fork")} required disabled={isSubmitting} />

          <div className="w-100 d-flex align-items-center justify-content-end mt-4">
            <GhostButton label={t("button.cancel")} onClick={hideModal} />
            <PrimaryButton label={t("button.update")} isLoading={isSubmitting} onClick={handleSubmit} noIcon className="w-auto" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditRewardsForm;
