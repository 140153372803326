import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { CONTAINER_API } from "common/constants/containerTypes";
import { getActiveOrganizationId, getConnectors, getFiatOptionsWithIcon } from "selectors";
import { FIAT_CURRENCY } from "common/constants/dynamicFieldTypes";
import { transactionActions } from "actions/transactionActions";
import CreateBlankExplanation from "components/Modals/ModalExplanations/CreateBlankExplanation";
import ConnectApiExplanation from "components/Modals/ModalExplanations/ConnectApiExplanation";
import ApiContainerForm from "../Forms/ApiContainerForm";
import CsvContainerForm from "../Forms/CsvContainerForm";
import ModalEnvelope from "./ModalEnvelope";
import { modalActions } from "actions/modalActions";
import { useQuery } from "@tanstack/react-query";
import { getReferenceOptions } from "queries/organizations";

const CreateDataContainerModal = ({
  portfolioId,
  containerType,
  activeOrganizationId,
  transactionActions: { createNewContainer, createNewConnection },
  modalActions: { hideModal },
  connectorsData: { connectors, isConnectorsFetching },
  fiatOptions,
}) => {
  const { t } = useTranslation();

  const connectorsWithImages = useMemo(() => {
    return connectors.map(x => {
      const nameSubstring = x.id.substring(x.id.indexOf(".") + 1);
      x.image = `/img/exchanges/${nameSubstring}.svg`;
      return x;
    });
  }, [connectors]);

  const { data } = useQuery(getReferenceOptions(activeOrganizationId));
  const referenceOptions = data ?? [];

  return containerType === CONTAINER_API ? (
    <ModalEnvelope
      id="create-api-modal"
      heading={t("modal.create_api.heading")}
      description={containerType === CONTAINER_API ? <ConnectApiExplanation /> : <CreateBlankExplanation />}>
      <ApiContainerForm
        connectors={connectorsWithImages}
        fiatOptions={fiatOptions}
        isConnectorsFetching={isConnectorsFetching}
        onSubmit={({ connectorType, ...data }) => {
          connectorType.parameters.forEach(o => {
            // Map selectors value
            if (o.type === FIAT_CURRENCY && data.parameters[o.id]?.value) {
              data.parameters[o.id] = data.parameters[o.id].value;
            }
          });

          return createNewConnection(
            {
              ...data,
              connectorId: connectorType.id,
            },
            portfolioId
          );
        }}
        referenceOptions={referenceOptions}
        onClose={hideModal}
      />
    </ModalEnvelope>
  ) : (
    <ModalEnvelope id="create-container-modal" heading={t("modal.create_file.heading")} description={<CreateBlankExplanation />}>
      <CsvContainerForm
        onSubmit={data => createNewContainer(data, portfolioId)}
        referenceOptions={referenceOptions}
        activeOrganizationId={activeOrganizationId}
        onClose={hideModal}
      />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    connectorsData: getConnectors(state),
    fiatOptions: getFiatOptionsWithIcon(state),
    activeOrganizationId: getActiveOrganizationId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDataContainerModal);
