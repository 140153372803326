import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { getYear } from "date-fns";
import { SelectFormField, TextFormField } from "components/Common/Inputs";
import { Form, GhostButton, LoaderOverlay, PrimaryButton } from "components/Common";
import {
  ACCOUNTING_REPORT_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX,
  ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES,
  ACCOUNTING_REPORT_REFERENCE_MAPPING,
  ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING,
  ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT,
  ACCOUNTING_SOFTWARE_REPORT_FORMAT,
  accountingSoftwareDataFormatTranslator,
  accountingSoftwareReferenceMappingTranslator,
  accountingSoftwareTypeTranslator,
  COST_CENTER,
  DATA_FORMAT_XLSX,
  POHODA_FORMAT,
  REFERENCE,
  WHALEBOOKS_FORMAT,
  IMMEDIATELY,
  MOMENT_OF_SALE,
  accountingSoftwareMomentOfAcquisitionTranslator,
  ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION,
  ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION,
} from "common/constants/accountSettings";
import { accountingIncludeOptions } from "common/constants/accountingIncludeOptions";
import styles from "../Styles/Forms.module.scss";

const AccountSettingsForm = ({ accountSettings, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const softwareOptions = [
    { value: WHALEBOOKS_FORMAT, label: t(accountingSoftwareTypeTranslator(WHALEBOOKS_FORMAT)) },
    { value: POHODA_FORMAT, label: t(accountingSoftwareTypeTranslator(POHODA_FORMAT)) },
  ];
  const dataFormatOptions = [{ value: DATA_FORMAT_XLSX, label: t(accountingSoftwareDataFormatTranslator(DATA_FORMAT_XLSX)) }];
  const numberOfPlacesOfSubAccountsOptions = [
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
  ];
  const referenceMappingOptions = [
    { value: REFERENCE, label: t(accountingSoftwareReferenceMappingTranslator(REFERENCE)) },
    { value: COST_CENTER, label: t(accountingSoftwareReferenceMappingTranslator(COST_CENTER)) },
  ];

  const momentOfAcquisitionOptions = [
    { value: IMMEDIATELY, label: t(accountingSoftwareMomentOfAcquisitionTranslator(IMMEDIATELY)) },
    { value: MOMENT_OF_SALE, label: t(accountingSoftwareMomentOfAcquisitionTranslator(MOMENT_OF_SALE)) },
  ];

  const yearEnd = getYear(new Date()).toString().substr(2, 2);

  const getConfigValue = name => accountSettings?.config?.find(x => x.name === name)?.value;

  const includeOptions = accountingIncludeOptions(t);

  return (
    <Formik
      initialValues={{
        name: accountSettings?.name ? softwareOptions.find(x => x.value === accountSettings.name) : softwareOptions[0],

        // Whalebooks
        dataFormat:
          dataFormatOptions.find(x => x.value === getConfigValue(ACCOUNTING_SOFTWARE_REPORT_FORMAT)) ?? dataFormatOptions[0],
        includeDeposits:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_INCLUDE_DEPOSIT)) ?? includeOptions[0],
        includeWithdrawals:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL)) ?? includeOptions[0],
        referenceMapping:
          referenceMappingOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_REFERENCE_MAPPING)) ??
          referenceMappingOptions[0],
        momentOfAcquisition:
          momentOfAcquisitionOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION)) ??
          momentOfAcquisitionOptions[0],

        // Pohoda
        dataFormatPohoda:
          dataFormatOptions.find(x => x.value === getConfigValue(ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT)) ??
          dataFormatOptions[0],
        numberOfPlacesOfSubAccounts:
          numberOfPlacesOfSubAccountsOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES)) ??
          numberOfPlacesOfSubAccountsOptions[0],
        queuePrefix: getConfigValue(ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX) ?? "",
        includeDepositsPohoda:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT)) ?? includeOptions[0],
        includeWithdrawalsPohoda:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL)) ?? includeOptions[0],
        referenceMappingPohoda:
          referenceMappingOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING)) ??
          referenceMappingOptions[0],
        momentOfAcquisitionPohoda:
          momentOfAcquisitionOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION)) ??
          momentOfAcquisitionOptions[0],
      }}
      onSubmit={onSubmit}
      validationSchema={object().shape({
        name: object().required(),

        // Whalebooks
        dataFormat: object().when("name", {
          is: name => name?.value === WHALEBOOKS_FORMAT,
          then: object().required(),
        }),
        includeDeposits: object().when("name", {
          is: name => name?.value === WHALEBOOKS_FORMAT,
          then: object().required(),
        }),
        includeWithdrawals: object().when("name", {
          is: name => name?.value === WHALEBOOKS_FORMAT,
          then: object().required(),
        }),
        referenceMapping: object().when("name", {
          is: name => name?.value === WHALEBOOKS_FORMAT,
          then: object().required(),
        }),
        momentOfAcquisition: object().when("name", {
          is: name => name?.value === WHALEBOOKS_FORMAT,
          then: object().required(),
        }),

        // Pohoda
        dataFormatPohoda: object().when("name", {
          is: name => name?.value === POHODA_FORMAT,
          then: object().required(),
        }),
        numberOfPlacesOfSubAccounts: object().when("name", {
          is: name => name?.value === POHODA_FORMAT,
          then: object().required(),
        }),
        queuePrefix: string()
          .when("name", {
            is: name => name?.value === POHODA_FORMAT,
            then: string().required(),
          })
          .matches(/[A-Z]{2}/, "A..Z"),
        includeDepositsPohoda: object().when("name", {
          is: name => name?.value === POHODA_FORMAT,
          then: object().required(),
        }),
        includeWithdrawalsPohoda: object().when("name", {
          is: name => name?.value === POHODA_FORMAT,
          then: object().required(),
        }),
        referenceMappingPohoda: object().when("name", {
          is: name => name?.value === POHODA_FORMAT,
          then: object().required(),
        }),
        momentOfAcquisitionPohoda: object().when("name", {
          is: name => name?.value === WHALEBOOKS_FORMAT,
          then: object().required(),
        }),
      })}>
      {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
        <LoaderOverlay isLoading={isSubmitting}>
          <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
            <SelectFormField
              name="name"
              label={t("form.account_settings.name")}
              disabled={isSubmitting}
              required
              options={softwareOptions}
              onSelect={value => {
                if (value.value !== POHODA_FORMAT) {
                  setFieldValue("numberOfPlacesOfSubAccounts", numberOfPlacesOfSubAccountsOptions[0]);
                }
              }}
            />

            {values.name?.value === WHALEBOOKS_FORMAT && (
              <>
                <SelectFormField
                  name="dataFormat"
                  label={t("form.account_settings.data_format")}
                  disabled={isSubmitting}
                  required
                  options={dataFormatOptions}
                />
                <SelectFormField
                  name="includeDeposits"
                  label={t("form.account_settings.include_deposits")}
                  disabled={isSubmitting}
                  required
                  options={includeOptions}
                />
                <SelectFormField
                  name="includeWithdrawals"
                  label={t("form.account_settings.include_withdrawals")}
                  disabled={isSubmitting}
                  required
                  options={includeOptions}
                />
                <SelectFormField
                  name="referenceMapping"
                  label={t("form.account_settings.reference_mapping")}
                  disabled={isSubmitting}
                  required
                  options={referenceMappingOptions}
                />
                <SelectFormField
                  name="momentOfAcquisition"
                  label={t("form.account_settings.moment_of_acquisition")}
                  disabled={isSubmitting}
                  required
                  options={momentOfAcquisitionOptions}
                />
              </>
            )}

            {values.name?.value === POHODA_FORMAT && (
              <>
                <SelectFormField
                  name="dataFormatPohoda"
                  label={t("form.account_settings.data_format")}
                  disabled={isSubmitting}
                  required
                  options={dataFormatOptions}
                />

                <SelectFormField
                  name="numberOfPlacesOfSubAccounts"
                  label={t("form.account_settings.number_of_places_of_subaccounts")}
                  disabled={isSubmitting}
                  required
                  options={numberOfPlacesOfSubAccountsOptions}
                />

                <div>
                  <div className="d-flex align-items-center">
                    <div className="mr-3" style={{ marginTop: "12px" }}>
                      {yearEnd}
                    </div>
                    <TextFormField
                      name="queuePrefix"
                      label={t("form.account_settings.queue_prefix")}
                      disabled={isSubmitting}
                      required
                      maxLength={2}
                    />
                    <div className="ml-3" style={{ marginTop: "12px" }}>
                      00001
                    </div>
                  </div>
                </div>

                <SelectFormField
                  name="includeDepositsPohoda"
                  label={t("form.account_settings.include_deposits")}
                  disabled={isSubmitting}
                  required
                  options={includeOptions}
                />
                <SelectFormField
                  name="includeWithdrawalsPohoda"
                  label={t("form.account_settings.include_withdrawals")}
                  disabled={isSubmitting}
                  required
                  options={includeOptions}
                />
                <SelectFormField
                  name="referenceMappingPohoda"
                  label={t("form.account_settings.reference_mapping")}
                  disabled={isSubmitting}
                  required
                  options={referenceMappingOptions}
                />
                <SelectFormField
                  name="momentOfAcquisitionPohoda"
                  label={t("form.account_settings.moment_of_acquisition")}
                  disabled={isSubmitting}
                  required
                  options={momentOfAcquisitionOptions}
                />
              </>
            )}

            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
              <GhostButton onClick={onClose} className="mr-4" label={t("form.chart_account_detail.cancel")} />
              <PrimaryButton
                type="submit"
                noIcon
                label={t("form.chart_account_detail.update")}
                isLoading={isSubmitting}
                className="w-auto"
              />
            </div>
          </Form>
        </LoaderOverlay>
      )}
    </Formik>
  );
};

export default AccountSettingsForm;
