export const ENGLISH = "en-US";
export const CZECH = "cs-CZ";
export const SLOVAK = "sk-SK";
export const SPANISH = "es";
export const GERMAN = "de";

const t_fake = str => str;

export const languages = [
  {
    value: ENGLISH,
    label: t_fake("language.english"),
    lang: "us",
  },
  {
    value: CZECH,
    label: t_fake("language.czech"),
    lang: "cz",
  },
  {
    value: SLOVAK,
    label: t_fake("language.slovak"),
    lang: "sk",
  },
  {
    value: SPANISH,
    label: t_fake("language.spanish"),
    lang: "es",
  },
  {
    value: GERMAN,
    label: t_fake("language.german"),
    lang: "de",
  },
];
