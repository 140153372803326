import ReactGA from "react-ga";
import { isProduction } from "utils";
import Tapfiliate from "common/tapfiliate";
import history from "common/history";

const launchGoogleTagManager = () => {
  ((w, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-57V55JG");
};

const launchGoogleAnalytics4 = () => {
  ((w, d, s, l, i) => {
    w[l] = w[l] || [];
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);

    j.async = true;
    j.src = `https://www.googletagmanager.com/gtag/js?id=G-4SFNKGQ6VM`;
    f.parentNode.insertBefore(j, f);

    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      w[l].push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-4SFNKGQ6VM");
  })(window, document, "script", "dataLayer", "G-4SFNKGQ6VM");
};

export const initAnalyticalScripts = () => {
  // don't send reset password token to third parties
  const locationSearch = window.location.pathname === "/forgottenpassword" ? "" : window.location.search;

  if (isProduction) {
    launchGoogleAnalytics4();
    launchGoogleTagManager();

    ReactGA.initialize("UA-148470110-1");

    history.listen(location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(window.location.pathname + locationSearch);
    });

    Tapfiliate.initializeAndDetect("22246-0bc745", locationSearch);
  }
};
