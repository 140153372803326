import React, { useEffect, useState } from "react";
import { Popup } from "@atlaskit/popup";

export const ContextMenu = ({ trigger, children, onContextMenu, placement = "auto-end", focusClearElementId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hideMenu = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", hideMenu);
      document.addEventListener("dblclick", hideMenu);
      return () => {
        document.removeEventListener("click", hideMenu);
        document.removeEventListener("dblclick", hideMenu);
      };
    }
  }, [isOpen]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={hideMenu}
      shouldUseCaptureOnOutsideClick
      placement={placement}
      content={() => (
        <span
          onClick={e => {
            // Prevent event propagation to element behind menu
            hideMenu();
            e.stopPropagation();
          }}>
          {children}
        </span>
      )}
      trigger={triggerProps => (
        <div
          {...triggerProps}
          onContextMenu={e => {
            e.stopPropagation();
            e.preventDefault();

            document.getElementById(focusClearElementId)?.click();
            setTimeout(() => {
              setIsOpen(true);
              onContextMenu?.(e);
            });
          }}>
          {trigger}
        </div>
      )}
    />
  );
};
