import React from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize, useUrlHelper } from "hooks";
import { useRouteMatch } from "react-router-dom";
import { getGlobalFetchingState, getRawOrganizationDetails } from "selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import References from "containers/Organizations/Lists/References";

export const lists = "lists";
export const listsCategories = ["references"];

const Lists = ({ organizationsDetail, isGlobalFetching, organizationActions: { editOrganizationInfo } }) => {
  const { t } = useTranslation();
  const { id: organizationId } = useUrlHelper();
  const match = useRouteMatch();
  const { isSmall } = useScreenSize();

  const organization = organizationsDetail[organizationId];
  const organizationData = organization?.organizationData;
  const isOrganizationDetailLoading = organization?.isOrganizationDataFetching && !isGlobalFetching;

  return (
    <div data-tour="organization-lists" className="mt-3">
      {/*<Tabs
      listClassName={cx("mt-3", styles.modal_tabs)}
      tabWrapperClassName={isSmall ? undefined : styles.detail_tab}
      HeaderComponent={({ children }) => (
        <>
          <Heading level={3} className="mt-3">
            {t(`organization_detail.transactions.lists.${match.params.subTab ?? listsCategories[0]}`)}
          </Heading>
          {children}
        </>
      )}
      onTabClick={index => history.push(`/organizations/${organizationId}/${lists}/${listsCategories[index] || ""}`)}
      defaultTabIndex={Math.max(
        listsCategories.findIndex(o => o === match.params.subTab),
        0
      )}>
      <Tab title={t("organization_detail.lists.references")}>
        <References />
      </Tab>
    </Tabs>*/}

      <References />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizationsDetail: getRawOrganizationDetails(state),
    isGlobalFetching: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lists);
