import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getActiveOrganizationId, getOwnedPortfolios } from "selectors";
import { CheckBox } from "grommet";
import { GhostButton, LoaderOverlay, PrimaryButton, Text } from "components/Common";
import { portfolioActions } from "actions/portfolioActions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import cx from "classnames";
import { Link } from "react-router-dom";
import { modalActions } from "actions/modalActions";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { copyToClipboard } from "utils";
import ModalEnvelope from "./ModalEnvelope";
import styles from "../Styles/Modals.module.scss";

const CopyButton = ({ text }) => (
  <GhostButton icon={<FontAwesomeIcon icon={faClipboard} />} actionButton onClick={() => copyToClipboard(text)} />
);

const SharePortfolioModal = ({
  portfolioId,
  portfolios,
  activeOrganizationId,
  portfolioActions,
  modalActions: { hideModal },
}) => {
  const { t } = useTranslation();
  const portfolio = useMemo(() => portfolios.find(o => o.id === portfolioId), [portfolios, portfolioId]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isSharingEnabled, setIsSharingEnabled] = useState(portfolio.shortLink !== null);
  useEffect(() => {
    setIsSharingEnabled(portfolio.shortLink !== null);
  }, [portfolio.shortLink]);

  const handleSubmit = async isEnabled => {
    try {
      setIsSubmitting(true);
      await portfolioActions.togglePortfolioSharing(portfolioId, isEnabled);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalEnvelope id="share-portfolio-modal" heading={t("modal.share_portfolio.heading")}>
      <div className="mb-1">
        {t("form.share_portfolio.tooltip")}{" "}
        <Link
          className={styles.organization_members_link}
          to={`/organizations/${activeOrganizationId}/members`}
          onClick={hideModal}>
          {t("form.share_portfolio.tooltip_link")}
        </Link>
        .
      </div>

      <LoaderOverlay isLoading={isSubmitting}>
        <div className={cx(styles.vr_share, "pt-2 pb-3")}>
          <div>
            <BigCheckbox>
              <CheckBox
                toggle
                checked={isSharingEnabled}
                onChange={event => {
                  const isEnabled = event.target.checked;
                  setIsSharingEnabled(isEnabled);
                  handleSubmit(isEnabled);
                }}
                label={t("form.share_portfolio.enable")}
              />
            </BigCheckbox>

            <DynamicTooltip buttonClassName={styles.share_ptf_tooltip}>{t("form.share_portfolio.enable_tooltip")}</DynamicTooltip>
          </div>
        </div>

        {portfolio.shortLink && (
          <>
            <div className={styles.vr_share}>
              <Text>{t("form.share_portfolio.link")}</Text>
              <div>
                <Text size="small">{t("form.share_portfolio.vr_link")}</Text>
                <div className="d-flex align-items-center">
                  <a
                    className={cx(styles.link, "mr-1")}
                    href={`/${portfolio.shortLink}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {`${document.location.origin}/${portfolio.shortLink}`}
                  </a>
                  <CopyButton text={`${document.location.origin}/${portfolio.shortLink}`} />
                </div>
              </div>
            </div>

            <div className={styles.vr_share}>
              <Text>{t("form.share_portfolio.pc_mobile_vr")}</Text>
              <div>
                <Text size="small">{t("form.share_portfolio.vr_link")}</Text>
                <div className="d-flex align-items-center">
                  <a
                    className={cx(styles.link, "mr-1")}
                    href={`/vr/${portfolio.shortLink.substr(1)}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {`${document.location.origin}/vr/${portfolio.shortLink.substr(1)}`}
                  </a>
                  <CopyButton text={`${document.location.origin}/vr/${portfolio.shortLink.substr(1)}`} />
                </div>
              </div>
            </div>

            <div className={styles.vr_share}>
              <Text>{t("form.share_portfolio.vr")}</Text>
              <div>
                <Text size="small">{t("form.share_portfolio.vr_link")}</Text>
                <div className="d-flex align-items-center">
                  <a className={cx(styles.link, "mr-1")} href="/vr" target="_blank" rel="noopener noreferrer">
                    {`${document.location.origin}/vr`}
                  </a>
                  <CopyButton text={`${document.location.origin}/vr`} />
                </div>
              </div>
              <div>
                <Text size="small">{t("form.share_portfolio.vr_code")}</Text>
                <div className="d-flex align-items-center">
                  <Text className="mr-1">{portfolio.shortLink.substr(1)}</Text>
                  <CopyButton text={portfolio.shortLink.substr(1)} />
                </div>
              </div>
            </div>
          </>
        )}
      </LoaderOverlay>

      <PrimaryButton isLoading={isSubmitting} className="mt-4" label={t("button.close")} onClick={hideModal} noIcon />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    portfolios: getOwnedPortfolios(state),
    activeOrganizationId: getActiveOrganizationId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharePortfolioModal);

const BigCheckbox = styled.div`
  & > label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.1rem;

    input:checked + span > span {
      left: calc(60px - 29px) !important;
    }

    & > div > span {
      height: 29px;
      width: 60px;

      & > span {
        height: 27px;
        width: 27px;
      }
    }
  }
`;
