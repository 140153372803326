import React from "react";
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { SelectInput } from "components/Common/Inputs";
import { Heading } from "components/Common";
import { customBoldSelectStyles } from "common/styles/selectStyles";
import styles from "containers/Styles/Reports.module.scss";
import DateLabel from "components/Dashboard/DateLabel";
import Delimiter from "components/Common/Delimiter";
import dashboardStyles from "../Styles/Dashboard.module.scss";

const DataManagerHeading = ({ onChange, showFilter, rawOpenedPortfolio, ownedPortfolios }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.reports_heading}>
      <Heading level={2}>{t("reports.heading")}</Heading>
      <div className={styles.portfolio_filter_wrapper} data-tour="reports-select-portfolio">
        {showFilter && (
          <SelectInput
            label={<b>{t("reports.portfolio_label")}</b>}
            isInline
            styles={customBoldSelectStyles}
            wrapperClassName={styles.portfolio_select}
            value={rawOpenedPortfolio || ""}
            options={ownedPortfolios}
            onChange={({ id }) => onChange(id)}
            getOptionValue={({ id }) => `${id}`}
            getOptionLabel={({ name }) => name}
            components={{
              Option: props => {
                const {
                  data: { name, start, end, currency, computationType, isSelected, latestRuleSet, experimentalComputation },
                } = props;

                const PortfolioInfo = () => (
                  <span className="mr-2">
                    {latestRuleSet?.name} <span className="mx-1">•</span> {computationType} <span className="mx-1">•</span>{" "}
                    {currency} <span className="mx-1">•</span> {experimentalComputation ? "Y" : "N"}
                  </span>
                );
                const PortfolioRange = () => <DateLabel start={start} end={end} color={isSelected ? "white" : undefined} />;

                return (
                  <components.Option {...props}>
                    <div className={dashboardStyles.portfolio_select_option}>
                      <div className={dashboardStyles.name}>{name}</div>
                      <div className={cx(dashboardStyles.portfolio_detail_row)}>
                        <PortfolioRange />
                        <PortfolioInfo />
                      </div>
                    </div>
                  </components.Option>
                );
              },
              Group: props => (
                <components.Group {...props} headingProps={{ ...props.headingProps, display: props.label !== undefined }} />
              ),
              GroupHeading: ({ display, ...props }) => (display ? <components.GroupHeading {...props} /> : null),
              Menu: props => (
                <components.Menu {...props}>
                  {props.children}
                  <Delimiter />
                  <div className={dashboardStyles.ptf_count_label}>
                    {t("dashboard.dashboard_header.portfolio_count", { ptfCount: props.options.length })}
                  </div>
                </components.Menu>
              ),
            }}
            name="active-portfolio"
          />
        )}
      </div>
    </div>
  );
};

export default DataManagerHeading;
