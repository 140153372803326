import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import ForgotPasswordForm from "containers/Forms/ForgotPasswordForm";
import styles from "pages/Pages.module.scss";

const Forgot = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.subpage_form}>
      <Heading level={3} margin="0 auto 1.1rem">
        {t("forgot_password.heading")}
      </Heading>
      <ForgotPasswordForm />
    </div>
  );
};

export default Forgot;
