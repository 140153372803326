import React from "react";
import styles from "../Styles/Graphs.module.scss";

export default ({ dottedLegendLabel, positiveLegendLabel, negativeLegendLabel, lockedLegendLabel, displayNegativeLabel }) => (
  <div className={styles.legend_wrapper}>
    <div>
      <div className={styles.legend_item_dotted} />
      {dottedLegendLabel}
    </div>
    <div>
      <div className={styles.legend_item_full} />
      {positiveLegendLabel}
    </div>
    {displayNegativeLabel && (
      <div>
        <div className={styles.legend_item_negative} />
        {negativeLegendLabel}
      </div>
    )}
    <div>
      <div className={styles.legend_item_locked} />
      {lockedLegendLabel}
    </div>
  </div>
);
