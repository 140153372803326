import React from "react";
import cx from "classnames";
import { useScreenSize } from "hooks";
import Profile from "components/AppStructure/Header/Profile";
import Organizations from "components/AppStructure/Header/Organizations";
import ProfileIcon from "components/AppStructure/Header/ProfileIcon";
import ExpandableMenu from "components/Common/ExpandableMenu";
import Delimiter from "components/Common/Delimiter";
import { getDataTestId } from "utils";
import MainMenu from "components/AppStructure/Header/MainMenu";
import styles from "../../Styles/AppStructure.module.scss";
import Landing from "./Landing";
import UpgradeButton from "containers/UpgradeButton";

const HeaderMenu = ({
  isLanding, // used for determining background / color of pages like "Help" and "About us"
  user: { isAuthenticated, email },
  isMenuExpanded,
  setIsMenuExpanded,
  isProfileExpanded,
  setIsProfileExpanded,
  userActions: { logout, switchOrganization },
  modalActions: { openFeedbackForm, openLoginModal },
  activePortfolio,
  memberships,
  activeMemberShip,
  topSpace,
}) => {
  const { isSmall } = useScreenSize();

  return !isAuthenticated ? (
    <Landing
      isLanding={isLanding}
      isMenuExpanded={isMenuExpanded}
      setIsMenuExpanded={setIsMenuExpanded}
      openLoginModal={openLoginModal}
    />
  ) : (
    <>
      <div
        className={cx({
          [styles.mobile_header_menu_outer_wrapper]: isSmall && isMenuExpanded,
        })}
        style={{ top: topSpace }}>
        <div
          className={cx(styles.header_menu_signed_in, {
            [styles.header_menu_expanded]: isMenuExpanded,
            [styles.mobile_header_menu_inner_wrapper]: isSmall && isMenuExpanded,
          })}>
          <ul style={{ marginRight: isSmall ? undefined : "calc(56px - 10px - 0.5rem)" }}>
            <MainMenu activePortfolio={activePortfolio} />
          </ul>

          {!isSmall && <UpgradeButton className="d-flex align-items-center" />}

          <ul>
            {!isSmall && (
              <>
                <Organizations
                  activeMemberShip={activeMemberShip}
                  memberships={memberships}
                  switchOrganization={switchOrganization}
                />
                <span className={styles.header_delimiter} />
                <ExpandableMenu
                  listClassName={styles.profile_menu}
                  label={<ProfileIcon />}
                  testId={getDataTestId("link-expandable", "profile-menu")}>
                  <Profile
                    logoutAction={logout}
                    openFeedbackForm={openFeedbackForm}
                    setExpanded={setIsMenuExpanded}
                    email={email}
                  />
                </ExpandableMenu>
              </>
            )}
          </ul>
        </div>
      </div>

      {isSmall && ( // User profile on mobile resolution
        <div
          className={cx({
            [styles.mobile_header_menu_outer_wrapper]: isSmall && isProfileExpanded,
          })}
          style={{ top: topSpace }}>
          <div
            className={cx(styles.header_menu_signed_in, {
              [styles.header_menu_expanded]: isProfileExpanded,
              [styles.mobile_header_menu_inner_wrapper]: isSmall && isProfileExpanded,
            })}>
            <ul>
              <Profile
                logoutAction={logout}
                openFeedbackForm={openFeedbackForm}
                setExpanded={setIsProfileExpanded}
                email={email}
                mobileChildren={
                  <>
                    <Delimiter style={{ marginTop: "var(--dropdown-padding)" }} />

                    <Organizations
                      activeMemberShip={activeMemberShip}
                      memberships={memberships}
                      switchOrganization={switchOrganization}
                      disableBorder
                    />
                  </>
                }
              />
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMenu;
