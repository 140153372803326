import React, { useMemo, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { usePrevious } from "hooks";
import { formatRegex } from "common/formatters";
import {
  getContainers,
  getSelectedContainers,
  getPortfolioContainerAssignments,
  getActivePortfolioId,
  getIsPortfolioSelected,
  getTransactionFilter,
} from "selectors";
import { CONTAINER_CSV } from "common/constants/containerTypes";
import { IN_PROGRESS } from "common/constants/synchronizationStatusTypes";
import ContainersWrapper from "./ContainersWrapper";

const ContainersConsole = ({
  activePortfolioId,
  isPortfolioSelected,
  containers,
  selectedContainers,
  portfolioContainerAssignments,
  isEditMode,
  filters: { containerType, containerName },
  isPortfolioContainerAssignmentFetching,
  transactionActions: { clickOnContainer, getTransactions, uploadBigContainer, changeFilter },
  activeManualContainerSyncIds,
}) => {
  const onDrop = containers => {
    if (containerType !== CONTAINER_CSV) changeFilter({ containerType: CONTAINER_CSV });
    containers.forEach(o => uploadBigContainer(o, isPortfolioSelected ? activePortfolioId : null));
  };

  const previousAssignments = usePrevious(portfolioContainerAssignments);
  const previousActivePortfolio = usePrevious(activePortfolioId);

  const relevantContainers = useMemo(
    () =>
      // filters containers relevant to selected portfolio
      containers.filter(
        o =>
          isEditMode || // show all if edit mode
          !isPortfolioSelected || // shows all if no portfolio is selected
          (!isPortfolioContainerAssignmentFetching && activePortfolioId && portfolioContainerAssignments?.[o.id]) || // shows assigned only if all data is fetched
          (isPortfolioContainerAssignmentFetching && activePortfolioId && previousAssignments?.[o.id]) || // shows assigned to previous portfolio while new assignments are loading
          (isPortfolioContainerAssignmentFetching && !previousActivePortfolio) // shows all if assignments are fetching and no portfolio was selected before
      ),
    [
      containers,
      isPortfolioSelected,
      activePortfolioId,
      isPortfolioContainerAssignmentFetching,
      portfolioContainerAssignments,
      previousAssignments,
      previousActivePortfolio,
      isEditMode,
    ]
  );

  const visibleContainers = useMemo(() => {
    // filters container relevant to container search
    const regex = new RegExp(formatRegex(containerName), "i");

    relevantContainers.forEach(x => {
      if (activeManualContainerSyncIds.has(x.id)) {
        x.connection.status = IN_PROGRESS;
      }
      return x;
    });
    return containerName ? relevantContainers.filter(o => o.name.match(regex)) : relevantContainers;
  }, [relevantContainers, containerName, activeManualContainerSyncIds]);

  const containerClickAction = useCallback(
    (id, ctrl, shift) => {
      clickOnContainer(visibleContainers, id, ctrl, shift);
      if (!isEditMode) getTransactions();
    },
    [isEditMode, clickOnContainer, visibleContainers, getTransactions]
  );

  const portfolioId = isPortfolioSelected ? activePortfolioId : null;

  return (
    <ContainersWrapper
      containerName={containerName}
      onContainersDrop={onDrop}
      onContainerClick={containerClickAction}
      visibleContainers={visibleContainers}
      selectedContainers={selectedContainers}
      portfolioId={portfolioId}
    />
  );
};

function mapStateToProps(state) {
  return {
    activePortfolioId: getActivePortfolioId(state),
    isPortfolioSelected: getIsPortfolioSelected(state),
    containers: getContainers(state),
    selectedContainers: getSelectedContainers(state),
    filters: getTransactionFilter(state),
    portfolioContainerAssignments: getPortfolioContainerAssignments(state),
    isEditMode: state.dataManager.isEditMode,
    isPortfolioContainerAssignmentFetching: state.dataManager.isPortfolioContainerAssignmentFetching,
    activeManualContainerSyncIds: state.dataManager.activeManualContainerSyncIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainersConsole);
