import { combineReducers } from "redux";
import userReducer from "./userReducer";
import portfolioReducer from "./portfolioReducer";
import dataManagerReducer from "./dataManagerReducer";
import marketDataReducer from "./marketDataReducer";
import jobReducer from "./jobReducer";
import modalReducer from "./modalReducer";
import walkthroughReducer from "./walkthroughReducer";
import reports from "./reportReducer";
import organizations from "./organizationReducer";
import support from "./supportReducer";
import accounts from "./accountReducer";
import buckets from "./bucketReducer";
import apiManagement from "./apiManagementReducer";

const rootReducer = combineReducers({
  user: userReducer,
  listOfPortfolios: portfolioReducer,
  dataManager: dataManagerReducer,
  marketData: marketDataReducer,
  jobs: jobReducer,
  modal: modalReducer,
  walkthrough: walkthroughReducer,
  reports,
  organizations,
  support,
  accounts,
  buckets,
  apiManagement,
});

const reducers = (state, action) => rootReducer(state, action);

export default reducers;
