import React from "react";
import { useTranslation } from "react-i18next";
import { changelogLink } from "common/constants/changelogLink";
import { CloseButton } from "../Common";
import styles from "../Styles/AppStructure.module.scss";
import { connect } from "react-redux";
import cx from "classnames";

const InfoBar = ({ isAuthenticated, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.info_bar, { [styles.unauthenticated]: !isAuthenticated })}>
      <div>
        <a href={changelogLink} rel="noopener noreferrer" target="_blank">
          <span style={{ fontFamily: "archivoheavy" }}>{t("infobar.message.title")}:</span>{" "}
          <span className={styles.message}>{t("infobar.message.text")}</span>
        </a>
        <CloseButton onClick={onClose} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
  };
}

export default connect(mapStateToProps)(InfoBar);
