import React from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PortfolioWalkthrough from "./PortfolioWalkthrough";
import DataManagerWalkthrough from "./DataManagerWalkthrough";
import ReportsWalkthrough from "./ReportsWalkthrough";
import OrganizationWalkthrough from "./OrganizationWalkthrough";

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const WalkThrough = () => (
  <>
    <PortfolioWalkthrough disableBodyScroll={disableBody} enableBodyScroll={enableBody} />
    <DataManagerWalkthrough disableBodyScroll={disableBody} enableBodyScroll={enableBody} />
    <ReportsWalkthrough disableBodyScroll={disableBody} enableBodyScroll={enableBody} />
    <OrganizationWalkthrough disableBodyScroll={disableBody} enableBodyScroll={enableBody} />
  </>
);

export default WalkThrough;
