import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const LogoutToAcceptInvitation = ({ onConfirm, onCancel, email }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      criticalCancel
      heading={t("confirmation.logout_accept_invitation.heading")}
      confirmLabel={t("confirmation.logout_accept_invitation.confirm_label")}>
      <p>
        <Trans i18nKey="confirmation.logout_accept_invitation.content_trans">
          To accept invitation, you first have to login as <b>{{ email }}</b>.
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default LogoutToAcceptInvitation;
