import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import {
  Checkmark as CheckIcon,
  Inspect as InspectIcon,
  Search as SearchIcon,
  UserSettings as UserSettingsIcon,
} from "grommet-icons";
import { supportActions } from "actions/supportActions";
import { modalActions } from "actions/modalActions";
import { alertActions } from "actions/alertActions";
import { userActions } from "actions/userActions";
import { ActionCell, GhostButton, Heading, LoaderOverlay, SortableCell, Table, TableFooter, Tooltip } from "components/Common";
import { dateTimeFormat, formatDate } from "common/formatters";
import { TextInput } from "components/Common/Inputs";
import { WRITE } from "common/constants/userPermission";
import { getGlobalFetchingState, getIsSpectatorMode } from "selectors";
import { WB_SUPPORT } from "common/constants/whaleBooksRoles";
import history from "common/history";

import { usePrevious } from "hooks";
import styles from "pages/Pages.module.scss";
import { useDefaultsStore } from "stores/defaultsStore";

const getUserId = row => row.userId;

const AdminUsers = ({
  supportActions,
  userActions,
  isSpectator,
  adminUsers: { users, totalUsersCount, isUsersFetching },
  globalFetchingState,
  modalActions,
}) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({ page: 0, count: useDefaultsStore.getState().perPageDefault });
  const [search, setSearch] = useState("");
  const [sortedBy, setSortedBy] = useState({ id: "joined", order: "asc" });

  const handlePagingChange = value => {
    if (value.count) setPagination(prevState => ({ ...prevState, count: value.count }));
    if (value.page || value.page === 0) setPagination(prevState => ({ ...prevState, page: value.page }));
  };

  useEffect(() => {
    supportActions.getUsers(pagination.page, pagination.count, search, sortedBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sortedBy, supportActions]);

  const prevSearch = usePrevious(search);
  useEffect(() => {
    if ((prevSearch === undefined && search === "") || (prevSearch === "" && search === "") || prevSearch === search) return;

    const delayDebounceFn = setTimeout(() => {
      setPagination(prevState => ({ ...prevState, page: 0 }));
      supportActions.getUsers(0, pagination.count, search, sortedBy);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, prevSearch]);

  useEffect(() => {
    if (isSpectator) supportActions.leaveSpectatorMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInspect = useCallback(
    async user => {
      try {
        const membershipsResult = await userActions.getJoinedOrganizations(user.userId, true);
        await userActions.getUserOrganizations(user.userId);
        await userActions.switchOrganization(membershipsResult.value[0].organizationId, user.userId);
        history.push("/dashboard");
      } catch (e) {
        alertActions.error(t("alert.error.cannot_inspect_user"));
      }
    },
    [userActions, t]
  );

  const etUsersTableColumns = useMemo(
    () => [
      {
        Header: t("et_users.table_header.id"),
        accessor: "userId",
        width: 35,
      },
      {
        Header: props => <SortableCell {...props}>{t("et_users.table_header.email")}</SortableCell>,
        accessor: "email",
      },
      {
        Header: props => <SortableCell {...props}>{t("et_users.table_header.organization_count")}</SortableCell>,
        id: "memberships",
        Cell: ({ row }) => row.original.memberships.length,
      },
      {
        Header: props => <SortableCell {...props}>{t("et_users.table_header.joined")}</SortableCell>,
        accessor: "createdAt",
        Cell: ({ value }) => formatDate(value, dateTimeFormat),
      },
      {
        Header: t("et_users.table_header.certified_accountant"),
        accessor: "certifiedAccountant",
        Cell: ({ value }) => (value ? <CheckIcon size="medium" /> : null),
      },
      {
        Header: t("et_users.table_header.internal_accountant"),
        accessor: "internalAccountant",
        Cell: ({ value }) => (value ? <CheckIcon size="medium" /> : null),
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("account_detail.table_header.action")}</div>,
        id: "action",
        Cell: ({ row }) => (
          <ActionCell activeRole={WRITE}>
            <Tooltip
              tooltipContent={t("et_organizations.table_header.inspect_tooltip")}
              disabled={row.original.userRole === WB_SUPPORT}>
              <GhostButton
                icon={<InspectIcon />}
                onClick={e => {
                  e.stopPropagation();
                  onInspect(row.original);
                }}
                actionButton
              />
            </Tooltip>
            <Tooltip tooltipContent={t("et_organizations.table_header.accountant_settings_tooltip")}>
              <GhostButton
                icon={<UserSettingsIcon />}
                onClick={e => {
                  e.stopPropagation();
                  modalActions.openAccountantDetail({ user: row.original });
                }}
                actionButton
              />
            </Tooltip>
          </ActionCell>
        ),
      },
    ],
    [t, onInspect, modalActions]
  );

  return (
    <div className={styles.app_content_padding}>
      <div className={styles.heading_block}>
        <Heading level={2}>{t("et_users.heading")}</Heading>
        <div className={styles.filters}>
          <TextInput
            placeholder={t("et_users.search_placeholder")}
            value={search}
            label={<SearchIcon />}
            isInline
            onChange={setSearch}
            wrapperStyle={{ alignItems: "center" }}
          />
        </div>
      </div>
      <LoaderOverlay isLoading={isUsersFetching && !globalFetchingState}>
        <div style={{ minHeight: "200px" }}>
          <Table
            data={users}
            columns={etUsersTableColumns}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
            onRowClick={row => history.push("/support/organizations", { user: row })}
            getRowId={getUserId}
          />

          <TableFooter
            page={pagination.page}
            count={pagination.count}
            totalRecords={totalUsersCount}
            onChange={handlePagingChange}
            isVisible={users.length > 0}
          />
        </div>
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isSpectator: getIsSpectatorMode(state),
    adminUsers: state.support,
    globalFetchingState: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supportActions: bindActionCreators(supportActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
