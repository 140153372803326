import React from "react";
import { formatCurrency } from "common/formatters";
import CurrencyDecimal from "../Formating/CurrencyDecimal";

export default ({ formattedValue, dx = 0, dy = 0, fontSize = 10, x, y, textAnchor, fill, fontFamily, ...props }) => (
  <svg x={dx} y={dy} fontSize={fontSize}>
    <text x={x} y={y} fontFamily={fontFamily} fontSize={fontSize} fill={fill} textAnchor={textAnchor} {...props}>
      <tspan x={x} dy="0em">
        <CurrencyDecimal {...formatCurrency(formattedValue)} fixedDecimalScale={false} useSpan={false} />
      </tspan>
    </text>
  </svg>
);
