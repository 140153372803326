import React from "react";
import { LinearGradient } from "@vx/gradient";
import { normalizeNumber } from "utils";
import { colors } from "common/colors";

export default ({ width, height, markerXGray, markerXPurple }) => (
  <defs>
    <LinearGradient from={colors.graphGradient2} to={colors.graphGradient3} id="main-graph-gradient" />
    <LinearGradient from="var(--yellow-2)" to="var(--yellow-1)" id="main-graph-negative-gradient" />
    <LinearGradient
      to="#d6d6d6"
      fromOpacity={0.6}
      toOpacity={0.6}
      from="#7c7c7c"
      rotate={270}
      id="main-graph-grey-gradient"
      fromOffset="0%"
      toOffset="90%"
    />
    <LinearGradient
      from="#d9d2e9"
      fromOpacity={0.7}
      toOpacity={0.7}
      to="#b4a7d6"
      rotate={270}
      id="main-graph-purple-gradient"
      fromOffset="0%"
      toOffset="90%"
    />
    <LinearGradient from="#ff2828" to="#ffffff" id="main-graph-red-gradient" fromOpacity={0} toOpacity={0} />
    <LinearGradient from={colors.gradientBlue2} to={colors.gradientBlue1} id="main-graph-blue-gradient" />
    <clipPath id="coloured-graph">
      <rect x="0" y="0" width={normalizeNumber(markerXGray)} height={normalizeNumber(height)} />
    </clipPath>
    <clipPath id="purple-graph">
      <rect
        x={normalizeNumber(markerXPurple)}
        y="0"
        width={normalizeNumber(width - markerXPurple - (width - (markerXGray ?? 0)))}
        height={normalizeNumber(height)}
      />
    </clipPath>
    <clipPath id="grey-graph">
      <rect
        x={normalizeNumber(markerXGray)}
        y="0"
        width={normalizeNumber(width - markerXGray)}
        height={normalizeNumber(height)}
      />
    </clipPath>
  </defs>
);
