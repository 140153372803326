import React from "react";
import { Heading as HeadingComponent, Text as TextComponent } from "grommet";

const Heading = ({ level, ...props }) => <HeadingComponent color="text" margin="none" level={level} {...props} />;

export const Text = ({ level, ...props }) => (
  <TextComponent
    color="text"
    // margin='none'
    {...props}
  />
);

export default Heading;
