import React from "react";
import cx from "classnames";

import { getDataTestId } from "utils";
import styles from "./Buttons.module.scss";

export const Button = ({ label, icon, iconRight = false, critical = false, className, labelClassName, ...rest }) => (
  <button className={cx(styles.button, className)} type="button" {...rest}>
    {React.isValidElement(icon) && !iconRight && icon}
    {label && <div className={cx(styles.label, labelClassName)}>{label}</div>}
    {React.isValidElement(icon) && iconRight && icon}
  </button>
);

const GhostButton = ({ className, critical, actionButton, label, testId, ...props }) => (
  <Button
    className={cx(styles.ghost_button, className, {
      [styles.critical]: critical,
      [styles.action_button]: actionButton,
    })}
    data-testid={getDataTestId("button", testId ?? (actionButton ? "action" : label))}
    label={label}
    {...props}
  />
);

export default GhostButton;
