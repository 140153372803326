import React from "react";
import styles from "pages/Pages.module.scss";
import { useTranslation } from "react-i18next";
import { Heading, Tab, Tabs } from "components/Common";
import history from "common/history";
import { useRouteMatch } from "react-router-dom";
import ErrorRates from "containers/Support/ErrorRates";
import ErrorLogs from "containers/Support/ErrorLogs";
import ErrorRateDetail from "containers/Support/ErrorRateDetail";

const pageIndexes = ["error-rates", "error-logs"];

function ConfiguredPairs() {
  const { t } = useTranslation();
  const match = useRouteMatch();

  return (
    <div className={styles.app_content_padding}>
      <Tabs
        HeaderComponent={({ children }) => (
          <div className="mb-5 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
            <Heading level={2} className="mb-2 mb-md-0">
              {t("et_configured_pairs.heading")}
            </Heading>{" "}
            {children}
          </div>
        )}
        onTabClick={index => history.push(`/support/pairs/${pageIndexes[index] || ""}`)}
        defaultTabIndex={Math.max(
          pageIndexes.findIndex(o => o === match.params.tab),
          0
        )}>
        <Tab title={t("et_configured_pairs.heading_error_rates")}>{!match.params.id ? <ErrorRates /> : <ErrorRateDetail />}</Tab>
        <Tab title={t("et_configured_pairs.heading_error_logs")}>{!match.params.id ? <ErrorLogs /> : null}</Tab>
      </Tabs>
    </div>
  );
}

export default ConfiguredPairs;
