import axios from "axios";
import i18n from "i18n";
import { OWNER } from "common/constants/userPermission";
import { getLocalStorageValue, REF_CODE } from "common/localStorageManager";

const mapUser = user => ({
  ...user,
  organizationId: (user.memberships.find(o => o.role === OWNER) || user.memberships[0]).organizationId,
});

async function fetchGoogleIdentity(accessToken, inviteToken, isSignUp) {
  let response;
  if (isSignUp) {
    response = await axios.post(`public/sign-ups/google`, {
      token: accessToken,
      locale: i18n.language,
      inviteToken,
      affiliateId: getLocalStorageValue(REF_CODE) || null,
    });
  } else {
    response = await axios.post(`public/logins/google`, {
      token: accessToken,
      inviteToken,
    });
  }

  return response.data.redirectTo ? response.data : mapUser(response.data);
}

async function register({ email, password, terms_agreed }, token) {
  const response = await axios.post("public/sign-ups/local", {
    email,
    password,
    agreedTerms: terms_agreed,
    locale: i18n.language,
    inviteToken: token,
    affiliateId: getLocalStorageValue(REF_CODE) || null,
  });
  return response.data;
}

async function authenticate({ email, password }, token) {
  const response = await axios.post("public/logins/local", {
    login: email,
    password,
    inviteToken: token,
  });

  return mapUser(response.data);
}

async function resetPassword(email) {
  const response = await axios.post("public/emails/password-reset", {
    email,
    locale: i18n.language,
  });
  return response.data;
}

async function setPassword(email) {
  const response = await axios.post("emails/password-creation", {
    email,
    locale: i18n.language,
  });
  return response.data;
}

async function changePassword({ previousPassword, newPassword }, { userId }) {
  const response = await axios.post(`users/${userId}/password`, {
    previousPassword,
    newPassword,
    locale: i18n.language,
  });
  return response.data;
}

async function setForgottenPassword({ password }, token) {
  const response = await axios.post("public/sign-ups/password", {
    password,
    token,
    locale: i18n.language,
  });
  return response.data;
}

async function resendVerification(email) {
  const response = await axios.post("public/emails/welcome", {
    email,
    locale: i18n.language,
  });
  return response.data;
}

async function postFeedback({ message, type, subtype, overallExperience, file }) {
  const bodyFormData = new FormData();
  bodyFormData.append("message", message);
  bodyFormData.append("type", type);
  bodyFormData.append("subtype", subtype || "unset");
  bodyFormData.append("overallExperience", overallExperience || "unset");
  bodyFormData.append("file", file);

  const response = await axios.post("feedback", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data; charset=utf-8",
    },
  });
  return response.data;
}

async function editUserPreferences(preferences, { userId }) {
  // TODO: merge with editProfile fn
  return new Promise(resolve => setTimeout(() => resolve(preferences), 100));
}

async function editProfile(profile, { userId }) {
  const response = await axios.put(`users/${userId}/preferences`, profile);
  return response.data;
}

async function deleteProfile({ userId }) {
  await axios.delete(`users/${userId}`);
}

async function getJoinedOrganizations(userId) {
  const response = await axios.get(`users/${userId}`);
  return response.data.memberships;
}

async function joinOrganization(invitationId) {
  const response = await axios.post("invitation-confirmation", invitationId, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
  return response.data;
}

async function getUserOrganizations(userId) {
  const response = await axios.get(`users/${userId}/organizations`);
  return response.data;
}

export const userService = {
  fetchGoogleIdentity,
  register,
  authenticate,
  resetPassword,
  setPassword,
  changePassword,
  postFeedback,
  setForgottenPassword,
  resendVerification,
  editUserPreferences,
  editProfile,
  deleteProfile,
  getJoinedOrganizations,
  joinOrganization,
  getUserOrganizations,
};
