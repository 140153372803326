export const USERS = "USERS";
export const ORGANIZATIONS = "ORGANIZATIONS";
export const CONTAINERS = "CONTAINERS";
export const PLANS = "PLANS";

export const adminReportTypes = [USERS, ORGANIZATIONS, CONTAINERS, PLANS];

const t_fake = str => str;

const adminReportTypesTranslations = {
  [USERS]: t_fake("admin_report_type.users"),
  [ORGANIZATIONS]: t_fake("admin_report_type.organizations"),
  [CONTAINERS]: t_fake("admin_report_type.containers"),
  [PLANS]: t_fake("admin_report_type.plans"),
};

export const adminReportTypesTranslator = val => adminReportTypesTranslations[val] || "";

export const adminReportTypeOptions = t => [
  { label: t(adminReportTypesTranslator(USERS)), value: USERS },
  { label: t(adminReportTypesTranslator(ORGANIZATIONS)), value: ORGANIZATIONS },
  { label: t(adminReportTypesTranslator(CONTAINERS)), value: CONTAINERS },
  { label: t(adminReportTypesTranslator(PLANS)), value: PLANS },
];
