import { useTranslation } from "react-i18next";
import { CZECH, SLOVAK } from "common/constants/languages";

// Returns true if comma is required as a decimal separator
const useDecimalSeparator = () => {
  const { i18n } = useTranslation();
  return i18n.language === CZECH || i18n.language === SLOVAK;
};

export default useDecimalSeparator;
