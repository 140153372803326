import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { alertActions } from "actions/alertActions";
import { Formik } from "formik";
import { DateFormField, RadioButtonsFormField, SelectFormField } from "components/Common/Inputs";
import { Form, LoaderOverlay, PrimaryButton } from "components/Common";
import { validatePortfolioPredecessor } from "common/validators";
import { getNotEmptyPortfoliosToSelect, getNotEmptyPortfoliosToSelectExcludingCurrent } from "selectors";
import { portfolioService } from "services";
import {
  dateSelectOptionTranslator,
  dateSelectOptionValues,
  SOURCE_PORTFOLIO_END,
  SOURCE_PORTFOLIO_LAST_TRANSACTION,
  USER_SPECIFIED,
} from "common/constants/dateSelectOptions";
import { useTranslation } from "react-i18next";
import { MEDIUM } from "common/constants/screenSizes";
import { formatDate, utc } from "common/formatters";

import styles from "../../Styles/Forms.module.scss";

const PortfolioFifoPredecessorForm = ({
  nonEmptyPortfolios,
  nonEmptyPortfoliosWithoutCurrent,
  user,
  onClose,
  isEdit,
  initialValues,
  computationType,
}) => {
  const { t } = useTranslation();
  const [isOptionsFetching, setIsOptionsFetching] = useState(false);
  const [selectOptions, setSelectOptions] = useState(false);

  const portfolioOptions = useMemo(
    () =>
      (isEdit ? nonEmptyPortfoliosWithoutCurrent : nonEmptyPortfolios)
        .filter(x => x.computationType === computationType)
        .map(x => ({ ...x, label: `${x.label} (${x.computationType})` })),
    [isEdit, nonEmptyPortfolios, nonEmptyPortfoliosWithoutCurrent, computationType]
  );

  const onSubmit = values => {
    if (onClose)
      onClose({
        initialState: {
          portfolio: values.portfolio,
          dateType: values.dateType,
          timestamp: utc(values.timestamp).startOf("day").valueOf(),
        },
      });
  };

  const getDateOptions = async (portfolioId, setFieldValue) => {
    setIsOptionsFetching(true);
    try {
      const res = await portfolioService.getPortfolioFinalBalancesDateOptions(portfolioId, user);
      const resObj = res.reduce((res, val) => {
        res[val.type] = { timestamp: val.timestamp || null };
        return res;
      }, {});
      setSelectOptions(resObj);
      setIsOptionsFetching(false);
      if (setFieldValue) {
        if (res.length === 0) {
          alertActions.error(t("alert.error.portfolio_no_assignments"));
          setFieldValue("dateType", null);
          setFieldValue("timestamp", "");
        } else {
          const hasEnd = resObj[SOURCE_PORTFOLIO_END] !== undefined;
          const dateType = hasEnd ? SOURCE_PORTFOLIO_END : SOURCE_PORTFOLIO_LAST_TRANSACTION;
          const timestamp = hasEnd ? resObj[SOURCE_PORTFOLIO_END].timestamp : resObj[SOURCE_PORTFOLIO_LAST_TRANSACTION].timestamp;
          setFieldValue("dateType", dateType);
          setFieldValue("timestamp", formatDate(utc(timestamp)));
        }
      }
    } catch (err) {
      setIsOptionsFetching(false);
    }
  };

  useEffect(() => {
    if (initialValues) getDateOptions(initialValues.portfolio?.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={{
        dateType: "",
        portfolio: portfolioOptions.find(x => x.value === initialValues?.sourcePortfolioId) ?? "",
        ...initialValues,
        timestamp: initialValues ? formatDate(utc(initialValues.timestamp)) : "",
      }}
      validate={validatePortfolioPredecessor}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        useEffect(() => {
          if (values.portfolio?.value) {
            getDateOptions(values.portfolio.value, setFieldValue);
          }
        }, [values.portfolio]);

        return (
          <Form onSubmit={handleSubmit}>
            <SelectFormField
              name="portfolio"
              options={portfolioOptions}
              label={t("form.portfolio_predecessor.source_portfolio")}
              required
              disabled={isSubmitting}
            />
            <LoaderOverlay isLoading={isOptionsFetching} spinnerSize={MEDIUM}>
              <b>{t("form.portfolio_predecessor.date_strategy_heading")}</b>

              <div className={styles.fields_row}>
                <RadioButtonsFormField
                  name="dateType"
                  disabled={isSubmitting}
                  isPlain
                  className={styles.radio_buttons_block_group}
                  options={dateSelectOptionValues.map(o => ({
                    value: o,
                    label: t(`${dateSelectOptionTranslator(o)}`),
                    disabled: selectOptions[o] === undefined,
                    timestamp: selectOptions[o] ? selectOptions[o].timestamp : null,
                  }))}
                  onSelect={value => {
                    const timestamp = selectOptions[value]?.timestamp || values.timestamp;
                    setFieldValue("dateType", value);
                    setFieldValue("timestamp", formatDate(timestamp));
                  }}
                />
                <DateFormField
                  name="timestamp"
                  label={t("form.portfolio_predecessor.date")}
                  disabled={isSubmitting || values.dateType !== USER_SPECIFIED}
                  className={styles.inline_field}
                  wrapperClassName={styles.height_fit_content}
                />
              </div>
            </LoaderOverlay>
            <PrimaryButton
              label={t("form.portfolio_predecessor.copy_balances")}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    nonEmptyPortfolios: getNotEmptyPortfoliosToSelect(state),
    nonEmptyPortfoliosWithoutCurrent: getNotEmptyPortfoliosToSelectExcludingCurrent(state),
    user: state.user,
  };
}

export default connect(mapStateToProps)(PortfolioFifoPredecessorForm);
