import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssetAccountsTable from "components/Dashboard/AssetAccountsTable";
import { accountActions } from "actions/accountActions";
import {
  getActivePortfolioId,
  getActivePortfolioIdSearchUrl,
  getFiatOptions,
  getGlobalFetchingState,
  getOpenedPortfolioBalances,
  getOpenedPortfolioReportParamsFetching,
} from "selectors";
import styles from "./Styles.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { reportActions } from "actions/reportActions";
import NoAccountsImage from "img/no_asset_accounts.png";
import { PrimaryButton } from "components/Common";
import { Info } from "react-feather";
import history from "common/history";

const AssetAccountsContainer = ({
  filter,
  activePortfolioId,
  accountStates,
  isLoading,
  portfolioIdSearch,
  fiatOptions,
  accountActions: { getAccountStates },
  reportActions: { fetchParams },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getAccountStates(activePortfolioId, filter);
  }, [activePortfolioId, getAccountStates, filter]);

  // Needed for account export (from, to)
  useEffect(() => {
    if (activePortfolioId) fetchParams(activePortfolioId, true);
  }, [activePortfolioId, fetchParams]);

  return (
    <div data-tour="ptf-asset-accounts">
      <AssetAccountsTable
        accountStates={accountStates}
        isLoading={isLoading}
        portfolioIdSearch={portfolioIdSearch}
        fiatOptions={fiatOptions}
      />

      {accountStates?.length === 0 && !isLoading && (
        <div className={styles.no_asset_accounts}>
          <img src={NoAccountsImage} alt="" />

          <div className={styles.content}>
            <div>{t("accounts.no_accounts")}</div>
            <div>
              <Trans i18nKey="accounts.continue_to_define">
                Go to Data Manager &gt <Link to={`/asset-accounts${portfolioIdSearch}`}>Asset Accounts</Link> and define your
                accounts.
              </Trans>
            </div>

            <div className="mb-24">
              <Trans i18nKey="accounts.assign_manually">
                Manually assign the accounts in Data Manager &gt
                <Link to={`/datamanager/containers${portfolioIdSearch}`}>Transactions</Link> in the details of each data
                container.
              </Trans>
            </div>

            <div className={styles.info}>
              <Info className="mr-1" />
              <div>{t("accounts.tooltip")}</div>
            </div>
          </div>

          <PrimaryButton
            label={t("accounts.add_asset_accounts")}
            className="w-auto"
            noIcon
            onClick={() => history.push("/asset-accounts?add=true")}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { filter } = getOpenedPortfolioBalances(state);

  return {
    filter,
    activePortfolioId: getActivePortfolioId(state),
    accountStates: state.accounts.accountStates,
    isLoading:
      (state.accounts.isAccountsStateFetching || getOpenedPortfolioReportParamsFetching(state)) && !getGlobalFetchingState(state),
    portfolioIdSearch: getActivePortfolioIdSearchUrl(state),
    fiatOptions: getFiatOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetAccountsContainer);
