import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cx from "classnames";
import { GhostButton, Heading, SortableCell, Table, TableFooter } from "components/Common";
import { accountActions } from "actions/accountActions";
import { useScreenSize, useUrlHelper } from "hooks";
import { formatDate, formatUtcDate, timeFormatWithSeconds } from "common/formatters";
import { transactionTypeTranslator } from "common/constants/transactionType";
import { SelectInput } from "components/Common/Inputs";
import { getActivePortfolioId, getContainers, getPortfoliosToSelect } from "selectors";
import { colors } from "common/colors";
import { customBoldSelectStyles } from "common/styles/selectStyles";
import history from "common/history";
import styles from "pages/Pages.module.scss";
import FormattedNumber from "components/Common/FormattedNumber";
import { copyToClipboard } from "utils";
import { useDefaultsStore } from "stores/defaultsStore";
import ExportAccount from "containers/Accounts/ExportAccount";

const Transactions = ({
  accountTransactions,
  accountDetail,
  portfolioOptions,
  containers,
  activePortfolioId,
  selectedPortfolio,
  accountActions: { loadAccountTransactions, openAccountTransactionDetail },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();
  const { id } = useUrlHelper();

  const { entries, totalCount, startBalance, startDate, endBalance, endDate } = accountTransactions;

  const [pagination, setPagination] = useState({ page: 0, count: useDefaultsStore.getState().perPageDefault });
  const [filteredBy, setFilteredBy] = useState({ portfolioId: null });
  const [sortedBy, setSortedBy] = useState({ id: "timestamp", order: "desc" });

  const handlePagingChange = value => {
    if (value.count) setPagination(prevState => ({ ...prevState, count: value.count }));
    if (value.page || value.page === 0) setPagination(prevState => ({ ...prevState, page: value.page }));
  };

  const handleLoadData = useCallback(() => {
    if (!filteredBy.portfolioId) return;

    if (id) {
      loadAccountTransactions(
        pagination.page,
        pagination.count,
        {
          ...filteredBy,
          timeOrder: sortedBy.order.toUpperCase(),
        },
        id
      );
    }
  }, [pagination, filteredBy, sortedBy, id, loadAccountTransactions]);

  useEffect(() => {
    handleLoadData();
  }, [filteredBy, sortedBy, pagination, handleLoadData]);

  // Set initial portfolioId value
  useEffect(() => {
    if (!filteredBy.portfolioId && selectedPortfolio) {
      setFilteredBy(prevState => ({ ...prevState, portfolioId: portfolioOptions.find(o => o.value === activePortfolioId) }));
    }
  }, [selectedPortfolio, portfolioOptions, activePortfolioId, filteredBy.portfolioId]);

  const transactionsColumns = useMemo(
    () => [
      {
        Header: props => <SortableCell {...props}>{t("account_detail.table_header.date")}</SortableCell>,
        Cell: ({ value }) => (
          <>
            {formatUtcDate(value)}
            <span className={styles.time_small}>{formatUtcDate(value, timeFormatWithSeconds)}</span>
          </>
        ),
        accessor: "timestamp",
        width: 100,
      },
      {
        Header: t("account_detail.table_header.source"),
        Cell: ({ value }) => value || "-",
        accessor: "source",
        width: 50,
      },
      {
        Header: t("account_detail.table_header.container"),
        Cell: ({ value }) => containers.find(x => x.id === value)?.name ?? "",
        accessor: "containerId",
        width: 190,
      },
      {
        Header: t("account_detail.table_header.type"),
        Cell: ({ row: { original } }) => t(`${transactionTypeTranslator(original.type, original.transfer)}`),
        accessor: "type",
        width: 70,
      },
      {
        Header: t("account_detail.table_header.address"),
        Cell: ({ value }) => (
          <span className="cursor-pointer" onClick={() => copyToClipboard(value)} title={value}>
            {value?.length > 30 ? `${value.substr(0, 5)}......${value.substr(value.length - 5, 5)}` : value}
          </span>
        ),
        accessor: "address",
        width: 80,
      },
      {
        Header: <div style={{ textAlign: "right" }}>{t("account_detail.table_header.amount")}</div>,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right", color: value?.toString().startsWith("-") ? colors.red : colors.text }}>
            <FormattedNumber number={value} currency={accountDetail?.currency} displayCurrency />
          </div>
        ),
        accessor: "amount",
        width: 90,
      },
      {
        Header: <div style={{ textAlign: "right" }}>{t("account_detail.table_header.running_balance")}</div>,
        Cell: ({ value }) => (
          <div style={{ textAlign: "right", color: value?.toString().startsWith("-") ? colors.red : colors.text }}>
            <FormattedNumber number={value} currency={accountDetail?.currency} displayCurrency />
          </div>
        ),
        accessor: "runningBalance",
        width: 90,
      },
      {
        Header: t("account_detail.table_header.note"),
        accessor: "note",
        width: 70,
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("account_detail.table_header.action")}</div>,
        Cell: ({ row }) => {
          const { original, index } = row;

          return (
            <div style={{ textAlign: "right", float: "right" }}>
              <GhostButton
                icon={<EditIcon />}
                onClick={() => openAccountTransactionDetail(original, handleLoadData)}
                actionButton
                testId={`detail_row-${index + 1}`}
              />
            </div>
          );
        },
        id: "action",
        width: isSmall ? 30 : 30,
        sticky: true,
      },
    ],
    [t, accountDetail, openAccountTransactionDetail, handleLoadData, containers, isSmall]
  );

  return (
    <>
      <div className={styles.heading_block}>
        <Heading level={3} className="mr-4">
          {t("account_detail.transactions.header")}
        </Heading>

        <div className={styles.portfolio_filter_wrapper}>
          <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center justify-content-lg-start">
            <SelectInput
              styles={customBoldSelectStyles}
              value={filteredBy.portfolioId}
              options={portfolioOptions}
              onChange={value => {
                setFilteredBy(prevState => ({ ...prevState, portfolioId: value }));
                history.push(value ? `${history.location.pathname}?portfolioId=${value.value}` : history.location.pathname);
              }}
              label={<b>{t("account_detail.table_filter.portfolio")}</b>}
              isInline
              maxMenuHeight={250}
              wrapperClassName={styles.tx_portfolio_select}
              name="active-portfolio"
            />

            <ExportAccount accountId={id} testId="export-account" isPrimaryButton />
          </div>
        </div>
      </div>

      <div className="w-100 d-flex flex-column flex-md-row justify-content-end my-2">
        {t("account_detail.transactions.start_balance")}
        <b className={cx("mx-1", { "negative-value": (startBalance ?? 0) < 0 })}>
          <FormattedNumber number={startBalance ?? 0} currency={accountDetail?.currency} displayCurrency />
        </b>
        {startDate && <>({formatDate(startDate)})</>} <span className="mr-3" />
        {t("account_detail.transactions.end_balance")}
        <b className={cx("mx-1", { "negative-value": (endBalance ?? 0) < 0 })}>
          <FormattedNumber number={endBalance ?? 0} currency={accountDetail?.currency} displayCurrency />
        </b>
        {endDate && <>({formatDate(endDate)})</>}
      </div>

      <Table
        data={entries}
        columns={transactionsColumns}
        filteredBy={filteredBy}
        setFilteredBy={setFilteredBy}
        getRowId={row => row.txId}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        className="mb-4"
        stickyHeader
        minWidth={1400}
      />

      <TableFooter
        page={pagination.page}
        count={pagination.count}
        totalRecords={totalCount}
        onChange={handlePagingChange}
        isVisible={entries.length > 0}
      />

      {entries.length === 0 && (
        <div className={styles.empty_table_row}>
          <Heading level={4} margin="1rem 0 0" color="gray3">
            {t("et_configured_pairs.pairs_table.no_records_message")}
          </Heading>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    accountTransactions: state.accounts.accountTransactions,
    accountDetail: state.accounts.accountDetail,
    portfolioOptions: getPortfoliosToSelect(state),
    containers: getContainers(state),
    activePortfolioId: getActivePortfolioId(state),
    selectedPortfolio: state.dataManager.selectedPortfolio,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
