import React, { useState } from "react";
import { Tooltip } from "@vx/tooltip";
import { formatDate, formatCurrency } from "common/formatters";
import { LEFT, RIGHT } from "common/constants/positions";
import CurrencyDecimal from "../Formating/CurrencyDecimal";
import GraphTooltip from "./GraphTooltip";

import styles from "../Styles/Graphs.module.scss";

const Tooltips = ({ selectedDate, selectedDatePosition, tooltipData, yScale, left, yMax, currencySymbol }) => {
  const [fullOverflow, setFullOverflow] = useState(false);
  const [dottedOverflow, setDottedOverflow] = useState(false);
  const [stakeOverflow, setStakeOverflow] = useState(false);

  const SelectedTooltip = selectedDate ? (
    <Tooltip
      top={-14}
      left={selectedDatePosition}
      className={styles.tooltip_date}
      style={{ transform: "translateX(-50%)", WebkitTransform: "translateX(-50%)", zIndex: 9 }}>
      {formatDate(selectedDate)}
    </Tooltip>
  ) : null;

  if (tooltipData) {
    const fullTop = yScale(tooltipData.data.fullY);
    const dottedTop = yScale(tooltipData.data.dottedY);
    const stakeTop = yScale(tooltipData.data.stakeY);

    return (
      <>
        {tooltipData && (
          <>
            {tooltipData.data.fullY >= 0 ? (
              <GraphTooltip
                top={fullTop}
                secondTop={dottedTop}
                left={tooltipData.middle + left}
                position={LEFT}
                notifyOverflow={val => setFullOverflow(val)}
                rotateCount={dottedOverflow ? 1 : 0}
                overflow={fullOverflow}
                className={styles.main_color_tooltip}>
                <CurrencyDecimal currencyPrefix={currencySymbol} {...formatCurrency(tooltipData.data.fullY)} useSpan={false} />
              </GraphTooltip>
            ) : (
              <GraphTooltip
                top={fullTop}
                secondTop={dottedTop}
                left={tooltipData.middle + left}
                position={LEFT}
                notifyOverflow={val => setFullOverflow(val)}
                rotateCount={dottedOverflow ? 1 : 0}
                overflow={fullOverflow}
                className={styles.negative_tooltip}>
                <CurrencyDecimal currencyPrefix={currencySymbol} {...formatCurrency(tooltipData.data.fullY)} useSpan={false} />
              </GraphTooltip>
            )}
            <GraphTooltip
              top={dottedTop}
              secondTop={fullTop}
              left={tooltipData.middle + left}
              position={RIGHT}
              notifyOverflow={val => setDottedOverflow(val)}
              rotateCount={fullOverflow ? 1 : 0}
              overflow={dottedOverflow}
              className={styles.main_dotted_tooltip}>
              <CurrencyDecimal currencyPrefix={currencySymbol} {...formatCurrency(tooltipData.data.dottedY)} useSpan={false} />
            </GraphTooltip>
            {stakeTop && (
              <GraphTooltip
                top={stakeTop}
                secondTop={fullTop}
                left={tooltipData.middle + left}
                position={LEFT}
                notifyOverflow={val => setStakeOverflow(val)}
                rotateCount={dottedOverflow || fullOverflow ? 2 : Math.abs(fullTop - stakeTop) < 22 ? 1 : 0}
                overflow={stakeOverflow}
                className={styles.staked_tooltip}>
                <CurrencyDecimal currencyPrefix={currencySymbol} {...formatCurrency(tooltipData.data.stakeY)} useSpan={false} />
              </GraphTooltip>
            )}
            <Tooltip
              top={yMax - 26}
              left={tooltipData.middle + left}
              className={styles.tooltip_date}
              style={{ transform: "translateX(-50%)", WebkitTransform: "translateX(-50%)", zIndex: 10 }}>
              {formatDate(tooltipData.data.timestamp)}
            </Tooltip>
          </>
        )}
        {SelectedTooltip}
      </>
    );
  }
  return SelectedTooltip;
};

export default Tooltips;
