import axios from "axios";
import queryString from "query-string";
import * as contentDisposition from "content-disposition";
import FileSaver from "file-saver";

const cancelToken = axios.CancelToken;
let getUsersCancelToken;
let getOrganizationsCancelToken;
let getErrorRatesCancelToken;

async function getUsers(page, count, search, sortBy) {
  if (getUsersCancelToken) getUsersCancelToken.cancel();
  getUsersCancelToken = cancelToken.source();

  const query = queryString.stringifyUrl(
    {
      url: "users",
      query: {
        page: page + 1,
        "filter-by-email": search,
        count,
      },
    },
    {
      skipNull: true,
    }
  );

  const res = await axios.get(query, {
    cancelToken: getUsersCancelToken.token,
  });

  return {
    data: res.data,
    meta: {
      total: Number.parseInt(res.headers["total-records-count"]),
    },
  };
}

async function getOrganizations(page, count, search, sortBy, filterBy) {
  if (getOrganizationsCancelToken) getOrganizationsCancelToken.cancel();
  getOrganizationsCancelToken = cancelToken.source();

  const query = queryString.stringifyUrl(
    {
      url: "organizations",
      query: {
        page: page + 1,
        "subscription-plan": filterBy.plan?.value,
        "owner-id": filterBy.user?.userId,
        "filter-by-display-name": search,
        count,
      },
    },
    {
      skipNull: true,
    }
  );

  const res = await axios.get(query, {
    cancelToken: getOrganizationsCancelToken.token,
  });

  return {
    data: res.data,
    meta: {
      total: Number.parseInt(res.headers["total-records-count"]),
    },
  };
}

async function setOrganizationTrialPeriod(organizationId, plan, timestiamp) {
  const res = await axios.put(`organizations/${organizationId}/trial`, {
    plan,
    end: timestiamp,
  });
  return res.data;
}

async function editUserDetail(userId, user) {
  const res = await axios.put(`users/${userId}/accountant`, user);

  const {
    data: { memberships, ...data },
  } = res;

  return data;
}

async function downloadAdminReport(type, changeFrom) {
  const response = await axios.get("users/statistics", {
    responseType: "blob",
    params: {
      type,
      changeFrom,
    },
  });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);

  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_report.pdf");
}

async function getErrorRatesFilterOptions() {
  const res = await axios.get("rates/error-rates/filter-options");
  return res.data;
}

async function getErrorRates(page, count, filteredBy) {
  if (getErrorRatesCancelToken) getErrorRatesCancelToken.cancel();
  getErrorRatesCancelToken = cancelToken.source();

  const query = queryString.stringifyUrl(
    {
      url: "rates/error-rates",
      query: {
        page: page + 1,
        count,
        base: filteredBy?.pair?.base,
        quote: filteredBy?.pair?.quote,
        "problem-type": filteredBy?.problemType?.value,
        sortOrder: filteredBy?.sortOrder,
      },
    },
    {
      skipNull: true,
    }
  );

  const res = await axios.get(query, {
    cancelToken: getErrorRatesCancelToken.token,
  });

  return res.data;
}

async function getErrorRate(id) {
  const res = await axios.get(`rates/error-rate/${id}`);
  return res.data;
}

export const supportService = {
  getUsers,
  getOrganizations,
  setOrganizationTrialPeriod,
  editUserDetail,
  downloadAdminReport,
  getErrorRatesFilterOptions,
  getErrorRates,
  getErrorRate,
};
