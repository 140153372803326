import axios from "axios";
import i18n from "i18n";
import { formatDate } from "common/formatters";
import queryString from "query-string";

const cancelToken = axios.CancelToken;
let getRateCancelToken;
let getConnectorsCancelToken;

const dummyLanguages = [
  {
    id: "en-US",
    name: "US English",
  },
  {
    id: "cs-CZ",
    name: "Czech",
  },
  {
    id: "sk-SK",
    name: "Slovak",
  },
];

async function getMarketInfo() {
  const response = await axios.get("public/crypto-market");
  return response.data;
}

async function getExchangeData() {
  const currenciesResponse = axios.get("currencies");

  const [currencies] = await Promise.all([currenciesResponse]);

  return {
    currencies: currencies.data,
  };
}

async function getRate(base, quote, date) {
  getRateCancelToken = cancelToken.source(); // Used only on Add Transaction Form - just call at a time is relevant

  const query = queryString.stringifyUrl({
    url: "rates",
    query: { base, quote, date: formatDate(date) },
  });

  const response = await axios.get(query, {
    cancelToken: getRateCancelToken.token,
    timeout: 3000,
  });
  return response.data;
}

async function getConnectors() {
  getConnectorsCancelToken = cancelToken.source();

  const response = await axios.get("/connectors", {
    cancelToken: getConnectorsCancelToken.token,
  });
  return response.data;
}

async function getGeographicInfo() {
  return new Promise(resolve =>
    setTimeout(
      () =>
        resolve({
          // TODO: Remove
          countries: [
            {
              id: "USA",
              name: "United states",
              states: [
                {
                  id: "NY",
                  name: "New York",
                },
                {
                  id: "TX",
                  name: "Texas",
                },
              ],
            },
            {
              id: "CZ",
              name: "Czech republic",
              states: [],
            },
          ],
          currencies: [
            {
              id: "USD",
              name: "US Dollar",
            },
            {
              id: "CZK",
              name: "CZ Koruna",
            },
          ],
          cryptocurrencies: [
            {
              id: "BTC",
              name: "Bitcoin",
            },
            {
              id: "ETH",
              name: "Ethereum",
            },
            {
              id: "LTC",
              name: "Litecoin",
            },
          ],
          languages: dummyLanguages,
        }),
      100
    )
  );
  // getGeographicInfoCancelToken = cancelToken.source();

  // const response = await axios.get(
  //   '/connectors',
  //   {
  //     cancelToken: getConnectorsCancelToken.token,
  //   }
  // );
  // return response.data;
}

async function getAccountants(search, internalAccountants = false) {
  const query = queryString.stringifyUrl({
    url: "users/accountants",
    query: {
      count: 100,
      page: 1,
      "filter-by-area": search,
      internalAccountants,
    },
  });
  const response = await axios.get(query);

  return response.data;
}

async function contactAccountants(accountantId) {
  const response = await axios.post("emails/contact-certified-accountant", {
    userId: accountantId,
    locale: i18n.language,
  });
  return response.data;
}

export const marketDataService = {
  getMarketInfo,
  getExchangeData,
  getRate,
  getConnectors,
  getGeographicInfo,
  getAccountants,
  contactAccountants,
};
