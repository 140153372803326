import {
  WALKTHROUGH_START,
  WALKTHROUGH_DATAMANAGER_START,
  WALKTHROUGH_END,
  WALKTHROUGH_UPDATE_STEP,
  WALKTHROUGH_SETUP,
  LOGOUT,
  WALKTHROUGH_REPORTS_START,
  WALKTHROUGH_ORGANIZATION_START,
} from "actions/types";

const initialState = {
  walkthroughSetupPending: false,
  portfolioIsOpen: false,
  dataManagerIsOpen: false,
  reportsIsOpen: false,
  organizationIsOpen: false,
  portfolioStep: 0,
  dataManagerStep: 0,
  reportsStep: 0,
  organizationStep: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ====================================
    case `${WALKTHROUGH_SETUP}_PENDING`:
      return {
        ...state,
        walkthroughSetupPending: true,
      };
    case `${WALKTHROUGH_SETUP}_FULFILLED`:
      return {
        ...state,
        walkthroughSetupPending: false,
        portfolioIsOpen: true,
        portfolioStep: 0,
      };
    case `${WALKTHROUGH_SETUP}_REJECTED`:
      return {
        ...state,
        walkthroughSetupPending: false,
      };
    // ====================================
    case WALKTHROUGH_START:
      return {
        ...initialState,
        portfolioIsOpen: true,
      };
    // ====================================
    case WALKTHROUGH_DATAMANAGER_START:
      return {
        ...initialState,
        dataManagerIsOpen: true,
      };
    // ====================================
    case WALKTHROUGH_REPORTS_START:
      return {
        ...initialState,
        reportsIsOpen: true,
      };
    // ====================================
    case WALKTHROUGH_ORGANIZATION_START:
      return {
        ...initialState,
        organizationIsOpen: true,
      };
    // ====================================
    case WALKTHROUGH_END:
      return {
        ...initialState,
      };
    // ====================================
    case WALKTHROUGH_UPDATE_STEP:
      return {
        ...state,
        portfolioStep: state.portfolioIsOpen ? Math.max(action.payload, 0) : state.portfolioStep,
        dataManagerStep: state.dataManagerIsOpen ? Math.max(action.payload, 0) : state.dataManagerStep,
        reportsStep: state.reportsIsOpen ? Math.max(action.payload, 0) : state.reportsStep,
        organizationStep: state.organizationIsOpen ? Math.max(action.payload, 0) : state.organizationStep,
      };
    // ====================================
    case LOGOUT:
      return initialState;

    // ====================================
    default:
      return state;
  }
};
