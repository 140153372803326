import { useEffect } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { connect } from "react-redux";
import { getGlobalFetchingState } from "selectors";
import { alertActions } from "actions/alertActions";
import { apiManagementService } from "services/apiManagementService";
import { confirmationActions } from "actions/confirmationActions";
import { bindActionCreators } from "redux";
import history from "common/history";

const ApiAccept = ({ user, isGlobalLoading, confirmationActions: { openAddApiManagementPermissionConfirmation } }) => {
  const [requestedEmail] = useQueryParam("user", StringParam);
  const [apiAccessId] = useQueryParam("subject-id", StringParam);

  const openPermissionAcceptConfirmation = async () => {
    const permission = await apiManagementService.getApiPermission(user, apiAccessId);
    if (permission?.id && apiAccessId && requestedEmail) {
      openAddApiManagementPermissionConfirmation(permission, apiAccessId, requestedEmail);
    }
  };

  useEffect(() => {
    if (!isGlobalLoading) {
      if (user?.email && user.email !== requestedEmail) {
        alertActions.info(`Login under email ${requestedEmail} and reopen the URL to add permission.`);
        history.push("/api-management");
      } else if (user?.email && user.email === requestedEmail) {
        openPermissionAcceptConfirmation();
      } else {
        alertActions.info(`Login under email ${requestedEmail} and reopen the URL to add permission.`);
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalLoading]);

  return null;
};

function mapStateToProps(state) {
  return {
    user: state.user,
    isGlobalLoading: getGlobalFetchingState(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiAccept);
