import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const LeaveOrganization = ({ onConfirm, onCancel, organization }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.leave_organization.heading")}
      confirmLabel={t("confirmation.leave_organization.confirm_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.leave_organization.content">
          You will not be able to join the organization <b>{{ organization }}</b> unless you get another invite.
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default LeaveOrganization;
