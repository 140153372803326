import React from "react";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import EmailSubscriptionForm from "../Forms/EmailSubscriptionForm";

const MailSubscription = props => {
  const { t } = useTranslation();
  return (
    <ModalEnvelope id="email-subscription" heading={t("modal.email_subscribtion.heading")} onClose={props.onClose}>
      <EmailSubscriptionForm {...props} />
    </ModalEnvelope>
  );
};

export default MailSubscription;
