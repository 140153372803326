import React from "react";
import { TableRow } from "grommet";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { colors } from "common/colors";
import FormattedNumber from "components/Common/FormattedNumber";
import { getCurrencyIconProps } from "utils/currencyIcon";
import { ChangeIcon, PercentDecimal } from "../Formating";
import CurrencySpan from "./CurrencySpan";
import styles from "../Styles/MasterTable.module.scss";
import { GhostButton } from "../Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export default ({
  base,
  quote,
  baseQuantity,
  quoteQuantity,
  acquisitionUnitPrice,
  marketValueUnitPrice,
  marketValue,
  acquisitionCost,
  acquisitionCostFee,
  acquisitionCostRewards,
  unrealizedProfit,
  valueChange,
  stakeQuantity,
  openBaseQuote,
  withComputation = false,
  showDetails = true,
  rowId,
}) => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    // https://stackoverflow.com/questions/56801421/how-to-select-text-to-copy-without-triggering-click-event-in-reactjs
    const selectedText = window.getSelection().toString();
    if (selectedText === "") openBaseQuote(base, quote);
  };

  return (
    <TableRow className={cx(styles.table_row_clickable, { [styles.table_row_details]: showDetails })} onClick={handleOnClick}>
      <td>
        <div>
          <img {...getCurrencyIconProps(base)} alt={base} />
          <h4 style={{ marginTop: "3px" }} className="mr-2">
            {withComputation ? base : `${base} - ${quote}`}
          </h4>
        </div>
      </td>
      <td>
        <div className="mr-2">
          <h4>
            <span className={cx({ "negative-value": baseQuantity < 0 })}>
              <CurrencySpan label={base} />
              <FormattedNumber number={baseQuantity} currency={base} />
            </span>
          </h4>
          <h6>
            {!!(withComputation && stakeQuantity && Number(stakeQuantity) !== 0) && showDetails && (
              <span>
                <FormattedNumber
                  number={stakeQuantity}
                  currency={base}
                  prefix={`${t("dashboard.master_table.staked")} ${base} `}
                />
              </span>
            )}

            {!withComputation && (
              <span>
                <CurrencySpan small label={quote} />
                <FormattedNumber number={quoteQuantity} currency={quote} />
              </span>
            )}
          </h6>
        </div>
      </td>
      <td>
        <div className="mr-2">
          <h4 className={cx({ "negative-value": acquisitionCost < 0 })}>
            <CurrencySpan label={quote} />
            <FormattedNumber number={acquisitionCost} currency={quote} />
          </h4>
          {showDetails && (
            <h6>
              <span className={cx({ "negative-value": baseQuantity < 0 })}>
                <FormattedNumber number={baseQuantity} currency={base} suffix={` ${base}`} />
              </span>
              {` @ `}
              <FormattedNumber number={acquisitionUnitPrice} currency={quote} suffix={` ${quote}`} />

              <div className="mt-1">
                <FormattedNumber
                  number={acquisitionCostFee}
                  currency={quote}
                  prefix={`${t("dashboard.master_table.acquisition_cost_fee")} ${quote} `}
                />
              </div>
              {withComputation && (
                <div className="mt-1">
                  <FormattedNumber
                    number={acquisitionCostRewards}
                    currency={quote}
                    prefix={`${t("dashboard.master_table.acquisition_cost_rewards")} ${quote} `}
                  />
                </div>
              )}
            </h6>
          )}
        </div>
      </td>
      <td>
        <div className="mr-2">
          <h4 className={cx({ "negative-value": marketValue < 0 })}>
            <CurrencySpan label={quote} />
            <FormattedNumber number={marketValue} currency={quote} />
          </h4>
          {showDetails && (
            <h6>
              <span className={cx({ "negative-value": baseQuantity < 0 })}>
                <FormattedNumber number={baseQuantity} currency={base} suffix={` ${base}`} />
              </span>
              {` @ `}
              <FormattedNumber number={marketValueUnitPrice} currency={quote} suffix={` ${quote}`} />
            </h6>
          )}
        </div>
      </td>
      <td>
        <h4>
          <CurrencySpan label={quote} />
          <FormattedNumber number={unrealizedProfit} currency={quote} />
        </h4>
        {showDetails && (
          <h6 className={cx(styles.change_field, "ml-n1")} style={{ height: "15px" }}>
            <ChangeIcon val={valueChange} />
            <span style={valueChange < 0 ? { color: colors.red } : undefined}>
              <PercentDecimal number={valueChange} useSpan={false} />
            </span>
          </h6>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-end align-items-center">
          <GhostButton
            icon={<FontAwesomeIcon icon={faChartLine} />}
            onClick={handleOnClick}
            actionButton
            testId={`open-position-detail_${rowId}`}
          />
        </div>
      </td>
    </TableRow>
  );
};
