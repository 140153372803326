import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upgrade as UpgradeIcon } from "grommet-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactGA from "react-ga";
import history from "common/history";
import {
  ACCOUNTING_REPORT,
  ACCOUNTS_STATEMENT,
  COMPUTATION_DEBUG,
  reportDescriptionTranslator,
  reportTypes as defaultReportTypes,
  TAX_TRADING_STATEMENT,
} from "common/constants/reportTypes";
import AdminPermission from "components/Common/AdminPermission";
import ReportTypeRow from "./ReportTypeRow";
import DateLabel from "../Dashboard/DateLabel";
import { GhostButton, Heading } from "../Common";

import styles from "../Styles/Reports.module.scss";
import DynamicTooltip from "../Tooltips/DynamicTooltip";

const DownloadReport = ({
  reportFromDefault,
  reportToDefault,
  reportTypes = {},
  withoutTransactions,
  upgradeRedirect,
  portfolio: { experimentalComputation } = {},
  onDownload,
  onDownloadAccountsStatement,
  onDownloadAccountingReport,
  isAccountsStatementReportDownloading,
  isAccountsReportDisabled,
  openDownloadAccountingReport,
}) => {
  const { t } = useTranslation();
  const [from, setFrom] = useState(reportFromDefault ? moment.utc(reportFromDefault).valueOf() : null);
  const [to, setTo] = useState(reportToDefault ? moment(reportToDefault).valueOf() : null);

  useEffect(() => {
    setFrom(reportFromDefault ? moment.utc(reportFromDefault).valueOf() : null);
    setTo(reportToDefault ? moment.utc(reportToDefault).valueOf() : null);
  }, [reportFromDefault, reportToDefault]);

  const visibleDefaultReportTypes = useMemo(
    () =>
      defaultReportTypes.filter(defaultReportType => !!reportTypes[defaultReportType] && reportTypes[defaultReportType].visible),
    [reportTypes]
  );
  const anyAvailable = useMemo(
    () => visibleDefaultReportTypes.find(o => reportTypes[o]?.available) !== undefined,
    [reportTypes, visibleDefaultReportTypes]
  );

  return (
    <div>
      <Heading level={3}>{t("reports.download.heading")}</Heading>
      <div className={styles.report_overview} data-tour="reports-period">
        <span>{t("reports.download.period")}</span>
        <span className="d-flex align-items-center font-weight-normal">
          <DateLabel start={from} end={to} />{" "}
          <DynamicTooltip buttonClassName="ml-1">
            <div style={{ maxWidth: "400px" }}>{t("reports.download.period_tooltip")}</div>
          </DynamicTooltip>
        </span>
        <span>{t("reports.download.accounting_currency.label")}</span>
        <span className="d-flex align-items-center justify-content-end">
          {experimentalComputation ? t("reports.download.accounting_currency.on") : t("reports.download.accounting_currency.off")}

          <DynamicTooltip buttonClassName="ml-1">
            <span style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: t("dashboard.convert_pairs_help") }} />
          </DynamicTooltip>
        </span>
      </div>
      {visibleDefaultReportTypes
        .filter(x => x !== ACCOUNTING_REPORT && x !== COMPUTATION_DEBUG)
        .map(o => (
          <ReportTypeRow
            key={o}
            reportType={o}
            reportTypeDescription={t(reportDescriptionTranslator(o))}
            available={reportTypes[o]?.available}
            isDownloading={reportTypes[o]?.isDownloading}
            onClick={() => {
              if (reportTypes[o]?.available) {
                onDownload(from, to, o);
                ReactGA.pageview(`${window.location.pathname}/download?${o}`);
              } else {
                history.push("/upgrade");
              }
            }}
            dataTour={o === TAX_TRADING_STATEMENT ? "reports-tax-statement" : undefined}
          />
        ))}

      <ReportTypeRow
        reportType={ACCOUNTS_STATEMENT}
        reportTypeDescription={t(reportDescriptionTranslator(ACCOUNTS_STATEMENT))}
        available={!isAccountsReportDisabled}
        isDownloading={isAccountsStatementReportDownloading || isAccountsReportDisabled === undefined}
        onClick={() => {
          onDownloadAccountsStatement(from, to, () =>
            ReactGA.pageview(`${window.location.pathname}/download?${ACCOUNTS_STATEMENT}`)
          );
        }}
        dataTour="reports-accounts-statement"
      />

      <ReportTypeRow
        reportType={ACCOUNTING_REPORT}
        reportTypeDescription={t(reportDescriptionTranslator(ACCOUNTING_REPORT))}
        available={reportTypes[ACCOUNTING_REPORT]?.available}
        isDownloading={reportTypes[ACCOUNTING_REPORT]?.isDownloading}
        onClick={() => {
          if (reportTypes[ACCOUNTING_REPORT]?.available) {
            openDownloadAccountingReport(from, to, async options => {
              await onDownloadAccountingReport(options);
              ReactGA.pageview(`${window.location.pathname}/download?${ACCOUNTING_REPORT}`);
            });
          } else {
            history.push("/upgrade");
          }
        }}
        isAccountingReport
        dataTour="reports-accounting-report"
      />

      <AdminPermission>
        <ReportTypeRow
          reportType={COMPUTATION_DEBUG}
          reportTypeDescription={t(reportDescriptionTranslator(COMPUTATION_DEBUG))}
          available={reportTypes[COMPUTATION_DEBUG]?.available}
          isDownloading={reportTypes[COMPUTATION_DEBUG]?.isDownloading}
          onClick={() => {
            if (reportTypes[COMPUTATION_DEBUG]?.available) {
              onDownload(from, to, COMPUTATION_DEBUG);
              ReactGA.pageview(`${window.location.pathname}/download?${COMPUTATION_DEBUG}`);
            } else {
              history.push("/upgrade");
            }
          }}
        />
      </AdminPermission>

      {!anyAvailable && upgradeRedirect && !withoutTransactions && (
        <Link className={styles.upgrade_link} to="/upgrade">
          <GhostButton icon={<UpgradeIcon />} label={t("reports.download.upgrade")} />
        </Link>
      )}
    </div>
  );
};

export default DownloadReport;
