import { transactionService, portfolioService } from "services";
import { CONTAINER_MANUAL } from "common/constants/containerTypes";
import moment from "moment";
import i18n from "i18n";
import {
  WALKTHROUGH_START,
  WALKTHROUGH_DATAMANAGER_START,
  WALKTHROUGH_END,
  WALKTHROUGH_UPDATE_STEP,
  WALKTHROUGH_SETUP,
  WALKTHROUGH_REPORTS_START,
  WALKTHROUGH_ORGANIZATION_START,
} from "./types";
import { portfolioActions } from "./portfolioActions";
import { alertActions } from "./alertActions";
import { userActions } from "./userActions";
import { WalkthroughCategory } from "config/walkthroughCategories";
import history from "common/history";
import { modalActions } from "actions/modalActions";
import { getActiveOrganizationId, getActivePortfolioId } from "selectors";

const examplePortfolio = {
  name: "Walkthrough Portfolio",
  note: "",
  computationType: "AVCO",
  feeApplicationType: "AT_PROFIT_REALIZATION",
  currency: "USD",
  start: null,
  end: null,
  labelFilterType: "BLACKLIST",
  labels: [],
  initialState: {
    pairStates: [],
  },
  rates: [],
  accountInitialBalances: [],
  experimentalComputation: true,
};

const exampleFile = {
  name: "Walkthrough CSV",
};

const exampleTransactions = [
  {
    baseCurrency: "BTC",
    quoteCurrency: "USD",
    type: "BUY",
    timestamp: moment
      .utc(new Date(2019, 0, 1))
      .startOf("day")
      .valueOf(),
    baseQuantity: 1,
    quoteQuantity: 3755,
  },
  {
    baseCurrency: "ETH",
    quoteCurrency: "USD",
    type: "BUY",
    timestamp: moment
      .utc(new Date(2019, 3, 8))
      .startOf("day")
      .valueOf(),
    baseQuantity: 7,
    quoteQuantity: 1246,
  },
  {
    baseCurrency: "ETH",
    quoteCurrency: "USD",
    type: "SELL",
    timestamp: moment
      .utc(new Date(2019, 10, 24))
      .startOf("day")
      .valueOf(),
    baseQuantity: 5,
    quoteQuantity: 717,
  },
];

function setWalkthrough() {
  return (dispatch, getState) =>
    dispatch({
      type: WALKTHROUGH_SETUP,
      async payload() {
        const { user } = getState();
        try {
          const ptf = await portfolioService.createPortfolio(examplePortfolio, user);
          const container = await transactionService.createNewContainer(
            { name: exampleFile.name, type: CONTAINER_MANUAL },
            ptf.id,
            user
          );
          await Promise.all(exampleTransactions.map(o => transactionService.createTransaction(o, container.id, user)));
          await dispatch(portfolioActions.getPortfolioList());
        } catch (e) {
          alertActions.error(i18n.t("alert.error.walkthrough_setup_failed"));
        }
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function startWalkthrough(category) {
  return (dispatch, getState) => {
    dispatch(modalActions.hideModal());

    const activePortfolioId = getActivePortfolioId(getState());
    const activeOrganizationId = getActiveOrganizationId(getState());

    switch (category) {
      case WalkthroughCategory.Transactions:
        history.push(`/datamanager/containers?portfolioId=${activePortfolioId}`);
        dispatch(startDataManagerWalkthrough());
        return;
      case WalkthroughCategory.Reports:
        history.push(`/reports?portfolioId=${activePortfolioId}`);
        dispatch(startReportsWalkthrough());
        return;
      case WalkthroughCategory.Organizations:
        history.push(`/organizations/${activeOrganizationId}/tax-rules`);
        dispatch(startOrganizationWalkthrough());
        return;
      default:
        dispatch(startWalkthroughPortfolio());
        return;
    }
  };
}

function startWalkthroughPortfolio() {
  return {
    type: WALKTHROUGH_START,
  };
}

function startDataManagerWalkthrough() {
  return {
    type: WALKTHROUGH_DATAMANAGER_START,
  };
}

function startReportsWalkthrough() {
  return {
    type: WALKTHROUGH_REPORTS_START,
  };
}

function startOrganizationWalkthrough() {
  return {
    type: WALKTHROUGH_ORGANIZATION_START,
  };
}

function endWalkthrough() {
  return dispatch => {
    dispatch(userActions.checkUserCategory());
    dispatch({
      type: WALKTHROUGH_END,
    });
  };
}

function updateStep(step) {
  return {
    type: WALKTHROUGH_UPDATE_STEP,
    payload: step,
  };
}

export const walkthroughActions = {
  startWalkthrough,
  startWalkthroughPortfolio,
  startDataManagerWalkthrough,
  startReportsWalkthrough,
  startOrganizationWalkthrough,
  endWalkthrough,
  setWalkthrough,
  updateStep,
};
