import React from "react";
import { Formik } from "formik";
import { DecimalFormField, SelectFormField } from "components/Common/Inputs";
import { Form, PrimaryButton, Text } from "components/Common";
import { useTranslation } from "react-i18next";
import { utc } from "common/formatters";
import { BUY, SELL, transactionTypeOptions } from "common/constants/transactionType";
import { mixed, number, object } from "yup";
import styles from "containers/Styles/Forms.module.scss";
import BulkFilterFormContent from "./BulkFilterFormContent";

const SetPriceForm = ({ onSubmit, containers, labelOptions }) => {
  const { t } = useTranslation();

  const containerNames = containers.map(o => o.name).join(", ");

  const typeOptions = transactionTypeOptions(t, BUY, SELL);

  return (
    <Formik
      initialValues={{
        type: "",
        baseCurrency: "",
        quoteCurrency: "",
        dateFrom: "",
        timestampFrom: utc().startOf("day"),
        dateTo: "",
        timestampTo: utc().endOf("day"),
        price: "",
        labels: [],
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        type: mixed().required(t("validation.required")),
        price: number()
          .required()
          .test("Is positive?", t("validation.positive"), value => value > 0),
        baseCurrency: object().required(t("validation.required")),
        quoteCurrency: object().required(t("validation.required")),
      })}>
      {formikProps => (
        <Form onSubmit={formikProps.handleSubmit}>
          <BulkFilterFormContent {...formikProps} transactionTypeOptions={typeOptions} isRequired />
          <div className={styles.fields_row}>
            <DecimalFormField name="price" label={t("form.set_bulk_price.price")} required disabled={formikProps.isSubmitting}>
              {formikProps.values?.quoteCurrency?.value || ""}
            </DecimalFormField>
            <SelectFormField name="labels" label={t("form.set_bulk_price.label")} options={labelOptions} isClearable isMulti />
          </div>
          <Text as="div" margin="0 0 1.1rem" size="small">
            {t("form.set_bulk_price.selected_containers", { containers: containerNames })}
          </Text>
          <PrimaryButton
            label={t("form.set_bulk_price.set")}
            isLoading={formikProps.isSubmitting}
            onClick={formikProps.handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SetPriceForm;
