import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getRawOpenedPortfolio } from "selectors";
import { portfolioActions } from "actions/portfolioActions";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Heading, LoaderOverlay } from "components/Common";
import DashboardHeadingReadonly from "containers/Dashboard/DashboardHeadingReadonly";
import DashboardContainer from "containers/Dashboard/DashboardContainer";

import styles from "pages/Pages.module.scss";

const SharedPortfolio = ({
  portfolio,
  portfolioActions: { getSharedPortfolio, setSharedPortfolio, getPortfolioBalancesAndHistory },
}) => {
  const { pid } = useParams();
  const { search } = useLocation();
  const [getPtfResult, setGetPtfResult] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const { sharingToken, o: organizationId, v: vaultId } = queryString.parse(search);
    const getPortfolioData = async id => {
      const result = await getSharedPortfolio(id);
      if (result && result.value) {
        getPortfolioBalancesAndHistory(result.value);
        setGetPtfResult(true);
      } else {
        setGetPtfResult(false);
      }
    };
    const portfolioId = Number.parseInt(pid);
    if (!Number.isNaN(portfolioId)) {
      setSharedPortfolio(portfolioId, sharingToken, organizationId, vaultId);
      getPortfolioData(portfolioId);
    }
    return () => setSharedPortfolio(null);
  }, [pid, search, getPortfolioBalancesAndHistory, setSharedPortfolio, getSharedPortfolio]);

  return (
    <div className={styles.app_content_padding}>
      {getPtfResult === null ? ( // isFetching
        <LoaderOverlay isLoading className={styles.shared_ptf_loader} />
      ) : getPtfResult ? (
        <>
          <DashboardHeadingReadonly portfolio={portfolio || {}} />
          <DashboardContainer />
        </>
      ) : (
        <div className={styles.icon_header_label}>
          <Heading level={3} textAlign="center">
            {t("dashboard.not_shared_portfolio_message")}
          </Heading>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    portfolio: getRawOpenedPortfolio(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedPortfolio);
