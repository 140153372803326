export const SELF_GENERATED_DIGITAL_ASSETS = "SELF_GENERATED_DIGITAL_ASSETS";
export const ACQUISITION_OF_DIGITAL_ASSETS = "ACQUISITION_OF_DIGITAL_ASSETS";
export const DIGITAL_ASSETS = "DIGITAL_ASSETS";
export const BANK_ACCOUNT_OR_EXCHANGE = "BANK_ACCOUNT_OR_EXCHANGE";
export const SOLD_DIGITAL_ASSETS = "SOLD_DIGITAL_ASSETS";
export const DIGITAL_ASSET_STATUS_CHANGE = "DIGITAL_ASSET_STATUS_CHANGE";
export const DIGITAL_ASSET_SELL_PROCEEDS = "DIGITAL_ASSET_SELL_PROCEEDS";
export const REWARDS_PROCEEDS = "REWARDS_PROCEEDS";
export const UNBOUND_FEES = "UNBOUND_FEES";
export const MONEY_ON_THE_WAY = "MONEY_ON_THE_WAY";
export const OTHER_RECEIVABLES = "OTHER_RECEIVABLES";

export const chartOfAccountNameOptions = [
  SELF_GENERATED_DIGITAL_ASSETS,
  ACQUISITION_OF_DIGITAL_ASSETS,
  DIGITAL_ASSETS,
  BANK_ACCOUNT_OR_EXCHANGE,
  SOLD_DIGITAL_ASSETS,
  DIGITAL_ASSET_STATUS_CHANGE,
  DIGITAL_ASSET_SELL_PROCEEDS,
  REWARDS_PROCEEDS,
  UNBOUND_FEES,
  MONEY_ON_THE_WAY,
  OTHER_RECEIVABLES,
];

const t_fake = str => str;

const chartOfAccountNameTranslations = {
  [SELF_GENERATED_DIGITAL_ASSETS]: t_fake("constant.chart_of_account_name.self_generated"),
  [ACQUISITION_OF_DIGITAL_ASSETS]: t_fake("constant.chart_of_account_name.acquisition"),
  [DIGITAL_ASSETS]: t_fake("constant.chart_of_account_name.digital"),
  [BANK_ACCOUNT_OR_EXCHANGE]: t_fake("constant.chart_of_account_name.bank_account"),
  [SOLD_DIGITAL_ASSETS]: t_fake("constant.chart_of_account_name.sold_digital"),
  [DIGITAL_ASSET_STATUS_CHANGE]: t_fake("constant.chart_of_account_name.status_change"),
  [DIGITAL_ASSET_SELL_PROCEEDS]: t_fake("constant.chart_of_account_name.sell_proceeds"),
  [REWARDS_PROCEEDS]: t_fake("constant.chart_of_account_name.rewards_proceeds"),
  [UNBOUND_FEES]: t_fake("constant.chart_of_account_name.unbound_fees"),
  [MONEY_ON_THE_WAY]: t_fake("constant.chart_of_account_name.money_on_the_way"),
  [OTHER_RECEIVABLES]: t_fake("constant.chart_of_account_name.other_receivables"),
};

export const chartOfAccountNameTranslator = val => chartOfAccountNameTranslations[val] || "";
