export const ONLINE = "FREE";
export const TRIAL = "FREE_UNLIMITED";
export const ONLINE_PLUS = "ONLINE_PLUS";
export const BUSINESS = "BUSINESS";

export const getTranslatedPlanName = name => {
  if (name === ONLINE) return "Free";
  if (name === ONLINE_PLUS) return "Pro";
  if (name === BUSINESS) return "Enterprise";
  if (name === TRIAL) return "Pro Trial";
};

const t_fake = str => str;

export const paymentPlans = {
  [ONLINE]: {
    name: getTranslatedPlanName(ONLINE),
    price: "$0",
    priceNote: t_fake("payment_plan.feature.per_year_label"),
    lines: [
      {
        content: t_fake("payment_plan.feature.dashboard"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.connection"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.without_support"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.import_10000"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.assign_10000"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.report_10000"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.on_premise"),
        disabled: true,
      },
    ],
  },
  [ONLINE_PLUS]: {
    name: getTranslatedPlanName(ONLINE_PLUS),
    price: "$1199",
    priceNote: t_fake("payment_plan.feature.per_year_label"),
    lines: [
      {
        content: t_fake("payment_plan.feature.dashboard"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.connection"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.technical_support"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.import_unlimited"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.assign_unlimited"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.report_unlimited"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.on_premise"),
        disabled: true,
      },
    ],
  },
  [BUSINESS]: {
    name: getTranslatedPlanName(BUSINESS),
    price: t_fake("payment_plan.feature.contact_us_label"),
    buttonLabel: t_fake("payment_plan.business.button_label"),
    priceNote: "",
    lines: [
      {
        content: t_fake("payment_plan.feature.dashboard"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.connection"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.technical_support"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.import_unlimited"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.assign_unlimited"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.report_unlimited"),
        disabled: false,
      },
      {
        content: t_fake("payment_plan.feature.on_premise"),
        disabled: false,
      },
    ],
  },
  [TRIAL]: {
    name: getTranslatedPlanName(TRIAL),
  },
};
