import { supportService } from "services/supportService";
import { OWNER } from "common/constants/userPermission";
import { SUPPORT_GET_USERS, SUPPORT_GET_ORGANIZATIONS, SET_TRIAL_PERIOD, EDIT_ET_USER_DETAIL } from "./types";
import { alertActions } from "./alertActions";
import { userActions } from "./userActions";

function leaveSpectatorMode() {
  return async dispatch => {
    const res = await dispatch(userActions.getJoinedOrganizations(null, true));
    const orgId = (res.value.find(o => o.role === OWNER) || res.value[0]).organizationId;
    dispatch(userActions.switchOrganization(orgId, null, "/support/users")); // TODO: change 1 to first membership organizationId
  };
}

function getUsers(page, count, search, sortBy) {
  return dispatch =>
    dispatch({
      type: SUPPORT_GET_USERS,
      payload: supportService.getUsers(page, count, search, sortBy),
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
}

function getOrganizations(page, count, search, sortBy, filterBy) {
  return dispatch =>
    dispatch({
      type: SUPPORT_GET_ORGANIZATIONS,
      payload: supportService.getOrganizations(page, count, search, sortBy, filterBy),
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
}

function setOrganizationTrialPeriod(organizationId, plan, timestamp) {
  return dispatch =>
    dispatch({
      type: SET_TRIAL_PERIOD,
      payload: supportService.setOrganizationTrialPeriod(organizationId, plan, timestamp),
    }).catch(err => {
      alertActions.error(err);
    });
}

function editUserDetail(userId, user) {
  return dispatch =>
    dispatch({
      type: EDIT_ET_USER_DETAIL,
      payload: supportService.editUserDetail(userId, user),
    }).catch(err => {
      alertActions.error(err);
    });
}

export const supportActions = {
  leaveSpectatorMode,
  getUsers,
  getOrganizations,
  setOrganizationTrialPeriod,
  editUserDetail,
};
