import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { alertActions } from "actions/alertActions";
import { CheckBoxFormField, DateFormField, RadioButtonsFormField, SelectFormField } from "components/Common/Inputs";
import { Form, LoaderOverlay, PrimaryButton } from "components/Common";
import { validatePortfolioPredecessor } from "common/validators";
import QuoteTable from "components/Modals/QuoteTable";
import { getCurrencyOptions, getPortfoliosToSelect, getRawOpenedPortfolio } from "selectors";
import { portfolioService } from "services";
import {
  dateSelectOptionTranslator,
  dateSelectOptionValues,
  SOURCE_PORTFOLIO_END,
  SOURCE_PORTFOLIO_LAST_TRANSACTION,
  USER_SPECIFIED,
} from "common/constants/dateSelectOptions";
import { useTranslation } from "react-i18next";
import { MEDIUM, SMALL } from "common/constants/screenSizes";
import { formatDate } from "common/formatters";
import styles from "../../Styles/Forms.module.scss";
import { AVCO } from "common/constants/computationTypes";
import cx from "classnames";

const PortfolioAvcoPredecessorForm = ({
  onClose,
  isEdit,
  allPortfolios,
  activePortfolio,
  user,
  currencyOptions,
  currentSourcePortfolioId,
}) => {
  const [isBalancesFetching, setIsBalancesFetching] = useState(false);
  const [isOptionsFetching, setIsOptionsFetching] = useState(false);
  const [selectOptions, setSelectOptions] = useState(false);
  const [finalState, setFinalState] = useState([]);
  const { t } = useTranslation();

  const onSubmit = values => {
    const formattedValues = {
      initialState: {
        pairStates: finalState.map(({ base, quote, id, ...rest }) => ({
          ...rest,
          pair: {
            base: currencyOptions.find(x => x.value === base) ?? "",
            quote: currencyOptions.find(x => x.value === quote) ?? "",
          },
        })),
        portfolio: values.portfolio,
        timestamp: new Date(values.timestamp).valueOf(),
        updatedAt: Date.now(),
      },
    };

    onClose?.(formattedValues);
  };

  const getPortfolioFinalBalances = async values => {
    setIsBalancesFetching(true);
    try {
      const res = await portfolioService.getPortfolioFinalBalances(values, user);
      setFinalState(res.pairStates);
      setIsBalancesFetching(false);
    } catch (err) {
      setIsBalancesFetching(false);
    }
  };

  const [hasEnd, setHasEnd] = useState(true);

  const dateTypeOptions = useMemo(
    () =>
      dateSelectOptionValues.map(o => ({
        value: o,
        label: t(`${dateSelectOptionTranslator(o)}`),
        disabled: selectOptions[o] === undefined,
        timestamp: selectOptions[o] ? selectOptions[o].timestamp : null,
      })),
    [selectOptions, hasEnd]
  );

  const getDateOptions = async (portfolioId, setFieldValue, { collapse }) => {
    setIsOptionsFetching(true);

    const response = await portfolioService.getPortfolioFinalBalancesDateOptions(portfolioId, user);

    setIsOptionsFetching(false);

    if (response.length === 0) {
      alertActions.error(t("alert.error.portfolio_no_assignments"));
      setFieldValue("dateType", null);
      setFieldValue("timestamp", "");
      setFinalState([]);
    } else {
      const allowedDateTypes = {};
      response.forEach(({ type, ...rest }) => {
        allowedDateTypes[type] = rest;
      });

      setSelectOptions(allowedDateTypes);

      const hasEndInternal = !!allowedDateTypes[SOURCE_PORTFOLIO_END]?.timestamp;
      setHasEnd(hasEndInternal);

      const dateType = hasEndInternal ? SOURCE_PORTFOLIO_END : SOURCE_PORTFOLIO_LAST_TRANSACTION;
      const timestamp = hasEndInternal
        ? allowedDateTypes[SOURCE_PORTFOLIO_END]?.timestamp
        : allowedDateTypes[SOURCE_PORTFOLIO_LAST_TRANSACTION]?.timestamp;

      setFieldValue("timestamp", formatDate(timestamp));
      setFieldValue("dateType", dateType);

      getPortfolioFinalBalances({ portfolioId, dateType, timestamp, collapse });
    }
  };

  const availablePortfolios = useMemo(
    () =>
      [...(isEdit ? allPortfolios.filter(x => x.value !== activePortfolio.id) : allPortfolios)]
        .filter(x => x.computationType === AVCO)
        .map(x => ({ ...x, label: `${x.label} (${x.computationType})` })),
    [allPortfolios, activePortfolio, isEdit]
  );

  return (
    <Formik
      initialValues={{
        portfolio: availablePortfolios.find(x => x.value === currentSourcePortfolioId) ?? "",
        dateType: "",
        timestamp: "",
        collapse: false,
      }}
      validate={values => validatePortfolioPredecessor(values)}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        useEffect(() => {
          if (values.portfolio?.value) {
            getDateOptions(values.portfolio.value, setFieldValue, values);
          }
        }, [values.portfolio]);

        return (
          <Form onSubmit={handleSubmit}>
            <SelectFormField
              name="portfolio"
              options={availablePortfolios}
              label={t("form.portfolio_predecessor.source_portfolio")}
              required
              disabled={isSubmitting}
            />
            <LoaderOverlay isLoading={isOptionsFetching} spinnerSize={MEDIUM}>
              <b>{t("form.portfolio_predecessor.date_strategy_heading")}</b>

              <div className={styles.fields_row}>
                <RadioButtonsFormField
                  name="dateType"
                  disabled={isSubmitting || isBalancesFetching}
                  isPlain
                  className={styles.radio_buttons_block_group}
                  options={dateTypeOptions}
                  onSelect={value => {
                    setFinalState([]);

                    const timestamp = selectOptions[value]?.timestamp || values.timestamp;

                    if (timestamp) {
                      setFieldValue("timestamp", formatDate(timestamp));

                      getPortfolioFinalBalances({
                        ...values,
                        portfolioId: values.portfolio.value,
                        dateType: value,
                        timestamp,
                      });
                    }
                  }}
                />

                <div>
                  <DateFormField
                    name="timestamp"
                    label={t("form.portfolio_predecessor.date")}
                    disabled={isSubmitting || isBalancesFetching || values.dateType !== USER_SPECIFIED}
                    inputProps={{ readOnly: true }}
                    onChange={val =>
                      getPortfolioFinalBalances({
                        ...values,
                        portfolioId: values.portfolio.value,
                        timestamp: val,
                      })
                    }
                    className={styles.inline_field}
                    wrapperClassName={cx(styles.height_fit_content, "mb-3")}
                  />
                  <div style={{ height: "80px" }}>
                    <CheckBoxFormField
                      name="collapse"
                      label={t("form.portfolio_predecessor.merge_balances")}
                      disabled={isSubmitting || isBalancesFetching || !values.portfolio}
                      onChange={val =>
                        getPortfolioFinalBalances({
                          ...values,
                          portfolioId: values.portfolio.value,
                          collapse: val,
                        })
                      }
                      wrapperClassName={styles.form_checkbox}
                    />
                  </div>
                </div>
              </div>

              <LoaderOverlay spinnerSize={SMALL} isLoading={isBalancesFetching && !isOptionsFetching}>
                <QuoteTable initialBalances={finalState} />
              </LoaderOverlay>
            </LoaderOverlay>
            <PrimaryButton
              label={t("form.portfolio_predecessor.copy_balances")}
              isLoading={isBalancesFetching || isOptionsFetching}
              onClick={handleSubmit}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    allPortfolios: getPortfoliosToSelect(state),
    activePortfolio: getRawOpenedPortfolio(state),
    user: state.user,
    currencyOptions: getCurrencyOptions(state),
  };
}

export default connect(mapStateToProps)(PortfolioAvcoPredecessorForm);
