import {
  GET_PORTFOLIOS,
  GET_SHARED_PORTFOLIO,
  UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS,
  SWITCH_PORTFOLIO,
  CREATE_PORTFOLIO,
  UPDATE_PORTFOLIO,
  DELETE_PORTFOLIO,
  GET_PORTFOLIO_BALANCES,
  GET_PORTFOLIO_HISTORY,
  GET_BASE_QUOTE,
  PORTFOLIO_CONTAINER_ASSIGNMENT,
  CREATE_CONTAINER,
  CONTAINER_UPLOADED,
  SET_SHARED_PORTFOLIO,
  LOGOUT,
  SWITCH_ORGANIZATION,
  SET_VR_MODE,
  DELETE_CONTAINER,
} from "actions/types";

const initialState = {
  portfolios: [],
  activePortfolio: null,
  isFetching: false,
  portfoliosHistories: {},
  portfoliosBaseQuotes: {},
  portfoliosBalances: {},
  portfolioAssignmentsCountsFetching: false,
  portfoliosFetching: {}, // updates etc..
  sharedPortfolio: null,
  sharedPortfolioId: null, // if not null -> used as activePortfolio
  sharedPortfolioToken: null,
  isSharedPortfolioFetching: false,
  vrMode: false,
  vrPortfolioId: null,
};

const sortPortfolios = portfolios => {
  portfolios.sort((a, b) => a.name.localeCompare(b.name));
};

export default function portfolioReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_PORTFOLIOS}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${GET_PORTFOLIOS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        portfolios: action.payload,
      };
    case `${GET_PORTFOLIOS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    //= ========================================
    case SWITCH_PORTFOLIO:
      return {
        ...state,
        activePortfolio: action.payload,
      };
    //= ========================================
    case `${UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS}_PENDING`: // Integrate to graphSelectors?
      return {
        ...state,
        portfolioAssignmentsCountsFetching: true,
      };
    case `${UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS}_FULFILLED`:
      const newAssignmentCounts = action.payload.reduce((res, val) => {
        res[val.id] = val.transactionContainerIds;
        return res;
      }, {});

      return {
        ...state,
        portfolios: state.portfolios.map(o => ({
          ...o,
          transactionContainerIds: newAssignmentCounts[o.id],
        })),
      };
    case `${UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS}_REJECTED`:
      return {
        ...state,
        portfolioAssignmentsCountsFetching: false,
      };
    //= ========================================
    case `${CREATE_PORTFOLIO}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${CREATE_PORTFOLIO}_FULFILLED`:
      const addPortfolios = [...state.portfolios, action.payload];
      sortPortfolios(addPortfolios);
      return {
        ...state,
        isFetching: false,
        portfolios: addPortfolios,
      };
    case `${CREATE_PORTFOLIO}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    //= ========================================
    case `${UPDATE_PORTFOLIO}_PENDING`:
      return {
        ...state,
        portfoliosFetching: { ...state.portfoliosFetching, [action.meta.portfolioId]: true },
      };
    case `${UPDATE_PORTFOLIO}_FULFILLED`:
      const updatePortfolios = state.portfolios.map(o =>
        o.id === action.meta.portfolioId ? { ...o, ...action.payload.portfolio } : o
      );
      sortPortfolios(updatePortfolios);
      return {
        ...state,
        portfoliosFetching: { ...state.portfoliosFetching, [action.meta.portfolioId]: false }, // Fetching state
        portfolios: updatePortfolios,
      };
    case `${UPDATE_PORTFOLIO}_REJECTED`:
      return {
        ...state,
        portfoliosFetching: { ...state.portfoliosFetching, [action.meta.portfolioId]: false },
      };
    //= ========================================
    case `${GET_SHARED_PORTFOLIO}_PENDING`:
      return {
        ...state,
        isSharedPortfolioFetching: true,
      };
    case `${GET_SHARED_PORTFOLIO}_FULFILLED`:
      return {
        ...state,
        isSharedPortfolioFetching: false,
        sharedPortfolio: action.payload,
      };
    case `${GET_SHARED_PORTFOLIO}_REJECTED`:
      return {
        ...state,
        isSharedPortfolioFetching: false,
      };
    //= ========================================
    case SET_SHARED_PORTFOLIO:
      return {
        ...state,
        sharedPortfolioId: action.payload.id,
        sharedPortfolioToken: action.payload.sharingToken,
        sharedPortfolioOrganizationId: action.payload.organizationId,
        sharedPortfolioVaultId: action.payload.vaultId,
        sharedPortfolio: action.payload.id ? state.sharedPortfolio : null,
      };
    //= ========================================
    case `${DELETE_PORTFOLIO}_PENDING`:
      return {
        ...state,
        portfolios: state.portfolios.filter(o => o.id !== action.payload),
      };
    // ====================================
    case `${GET_PORTFOLIO_BALANCES}_PENDING`:
      return {
        ...state,
        portfoliosBalances: {
          ...state.portfoliosBalances,
          [action.meta.portfolioId]: {
            ...state.portfoliosBalances[action.meta.portfolioId],
            isBalancesFetching: true,
            filter: action.meta.filter,
          },
        },
      };
    case `${GET_PORTFOLIO_BALANCES}_FULFILLED`:
      return {
        ...state,
        portfoliosBalances: {
          ...state.portfoliosBalances,
          [action.meta.portfolioId]: {
            ...state.portfoliosBalances[action.meta.portfolioId],
            isBalancesFetching: false,
            ...action.payload,
          },
        },
      };
    case `${GET_PORTFOLIO_BALANCES}_REJECTED`:
      return {
        ...state,
        portfoliosBalances: {
          ...state.portfoliosBalances,
          [action.meta.portfolioId]: {
            isBalancesFetching: false,
          },
        },
      };
    // ====================================
    case `${GET_PORTFOLIO_HISTORY}_PENDING`:
      return {
        ...state,
        portfoliosHistories: {
          ...state.portfoliosHistories,
          [action.meta.portfolioId]: {
            ...state.portfoliosHistories[action.meta.portfolioId],
            isHistoryFetching: true,
          },
        },
      };
    case `${GET_PORTFOLIO_HISTORY}_FULFILLED`:
      return {
        ...state,
        portfoliosHistories: {
          ...state.portfoliosHistories,
          [action.meta.portfolioId]: {
            ...state.portfoliosHistories[action.meta.portfolioId],
            isHistoryFetching: false,
            ...action.payload,
          },
        },
      };
    case `${GET_PORTFOLIO_HISTORY}_REJECTED`:
      return {
        ...state,
        portfoliosHistories: {
          ...state.portfoliosHistories,
          [action.meta.portfolioId]: {
            isHistoryFetching: false,
          },
        },
      };
    // ====================================
    case `${GET_BASE_QUOTE}_PENDING`:
      return {
        ...state,
        portfoliosBaseQuotes: {
          ...state.portfoliosBaseQuotes,
          [action.meta.portfolioId]: {
            ...state.portfoliosBaseQuotes[action.meta.portfolioId],
            isBQFetching: true,
            base: { name: action.payload.base },
            quote: { name: action.payload.quote },
            isSummary: action.payload.isSummary,
          },
        },
      };
    case `${GET_BASE_QUOTE}_FULFILLED`:
      return {
        ...state,
        portfoliosBaseQuotes: {
          ...state.portfoliosBaseQuotes,
          [action.meta.portfolioId]: {
            ...state.portfoliosBaseQuotes[action.meta.portfolioId],
            isBQFetching: false,
            ...action.payload,
          },
        },
      };
    case `${GET_BASE_QUOTE}_REJECTED`:
      return {
        ...state,
        portfoliosBaseQuotes: {
          ...state.portfoliosBaseQuotes,
          [action.meta.portfolioId]: {
            ...state.portfoliosBaseQuotes[action.meta.portfolioId],
            isBQFetching: false,
            graphData: null,
          },
        },
      };
    //= ========================================
    case `${PORTFOLIO_CONTAINER_ASSIGNMENT}_FULFILLED`: // Updates assigned container count
      if (action.meta.isEdit) {
        return {
          ...state,
          portfolios: state.portfolios.map(o =>
            o.id === action.meta.portfolioId
              ? {
                  ...o,
                  transactionContainerIds: action.payload,
                }
              : o
          ),
        };
      }
      return state;
    //= ========================================
    case `${CREATE_CONTAINER}_FULFILLED`: // Updates assigned container count
      if (action.meta.portfolioId) {
        return {
          ...state,
          portfolios: state.portfolios.map(o =>
            o.id === action.meta.portfolioId
              ? {
                  ...o,
                  transactionContainerIds: [...o.transactionContainerIds, action.payload.id],
                }
              : o
          ),
        };
      }
      return state;
    //= ========================================
    case CONTAINER_UPLOADED: // Updates assigned container count
      if (action.meta.portfolioId) {
        return {
          ...state,
          portfolios: state.portfolios.map(o =>
            o.id === action.meta.portfolioId
              ? {
                  ...o,
                  transactionContainerIds: [...o.transactionContainerIds, action.payload.container.id],
                }
              : o
          ),
        };
      }
      return state;
    case `${DELETE_CONTAINER}_FULFILLED`:
      return {
        ...state,
        portfolios: state.portfolios.map(o =>
          o.id === action.meta.portfolioId
            ? { ...o, transactionContainerIds: o.transactionContainerIds.filter(x => x !== action.meta.containerId) }
            : o
        ),
      };
    //= ========================================
    case `${SWITCH_ORGANIZATION}_FULFILLED`:
      return {
        ...state,
        portfolios: [],
      };
    //= ========================================
    case SET_VR_MODE:
      return {
        ...state,
        vrMode: action.payload,
        vrPortfolioId: action.payload ? state.vrPortfolioId : null,
      };
    //= ========================================
    case LOGOUT:
      if (state.sharedPortfolioId) {
        return {
          ...initialState,
          sharedPortfolioId: state.sharedPortfolioId,
          sharedPortfolio: state.sharedPortfolio,
          sharedPortfolioToken: state.sharedPortfolioToken,
          portfoliosHistories: {
            [state.sharedPortfolioId]: state.portfoliosHistories[state.sharedPortfolioId],
          },
          portfoliosBaseQuotes: {
            [state.sharedPortfolioId]: state.portfoliosBaseQuotes[state.sharedPortfolioId],
          },
          portfoliosBalances: {
            [state.sharedPortfolioId]: state.portfoliosBalances[state.sharedPortfolioId],
          },
        };
      }
      return initialState;

    default:
      return state;
  }
}
