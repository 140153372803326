import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { object } from "yup";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";

const DownloadAccountsStatementForm = ({ onSubmit, recordGA, accountStates, modalActions: { hideModal } }) => {
  const { t } = useTranslation();

  const handleSubmit = async values => {
    const accIds = values.accountId.value === "all" ? accountStates.map(x => x.id) : [values.accountId.value];

    onSubmit(accIds);
    recordGA();
    hideModal();
  };

  const accountOptions = useMemo(
    () => [
      { label: t("form.download_accounts_statement.all_accounts"), value: "all" },
      ...accountStates.map(x => ({ label: `${x.accountName} (${x.currency})`, value: x.id })),
    ],
    [accountStates, t]
  );

  return (
    <Formik
      initialValues={{
        accountId: accountOptions[0],
      }}
      onSubmit={handleSubmit}
      validationSchema={object({
        accountId: object().required(),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <SelectFormField
            name="accountId"
            label={t("form.download_accounts_statement.account")}
            disabled={isSubmitting}
            required
            options={accountOptions}
          />
          <PrimaryButton
            disabled={isSubmitting}
            label={t("form.download_accounts_statement.download")}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    accountStates: state.accounts.accountStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAccountsStatementForm);
