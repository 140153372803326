import React from "react";
import { connect } from "react-redux";
import PortfolioSummary from "components/Dashboard/PortfolioSummary";
import { getSummary } from "selectors/portfoliosSelectors";

const PortfolioSummaryContainer = ({ data }) => <PortfolioSummary {...data} />;

function mapStateToProps(state) {
  return {
    data: getSummary(state),
  };
}

export default connect(mapStateToProps)(PortfolioSummaryContainer);
