import React, { useMemo } from "react";
import { GhostButton, Heading, LoaderOverlay, PrimaryButton, Table, Tooltip } from "components/Common";
import { useTranslation } from "react-i18next";
import { Add as AddIcon, Close as DeleteIcon, Edit as EditIcon } from "grommet-icons/icons";
import { colors } from "common/colors";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { useUrlHelper } from "hooks";
import { useQuery } from "@tanstack/react-query";
import { getOrganizationReferences } from "queries/organizations";
import { organizationService } from "services";
import { confirmationActions } from "actions/confirmationActions";
import styles from "pages/Pages.module.scss";
import { useAddItem } from "hooks/useAddItem";

const References = ({
  modalActions: { openReferenceDetailModal },
  confirmationActions: { openDeleteOrganizationReferenceConfirmation },
}) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  const { data, isLoading, refetch } = useQuery(getOrganizationReferences(id));

  const handleAddReference = () =>
    openReferenceDetailModal(undefined, async referenceDetail => {
      await organizationService.createOrganizationReference(id, referenceDetail);
      refetch();
    });

  useAddItem(handleAddReference);

  const referencesTableColumns = useMemo(
    () => [
      {
        Header: t("form.reference_detail.name"),
        accessor: "name",
      },
      {
        Header: t("form.reference_detail.description"),
        accessor: "description",
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("accounts.table_header.action")}</div>,
        accessor: "id",
        Cell: ({ row }) => (
          <div className={styles.action_cell}>
            <Tooltip tooltipContent={t("organization_members.table_header.edit")}>
              <GhostButton
                icon={<EditIcon />}
                actionButton
                onClick={() =>
                  openReferenceDetailModal(row.original, async referenceDetail => {
                    await organizationService.createOrganizationReference(id, referenceDetail);
                    refetch();
                  })
                }
              />
            </Tooltip>
            <Tooltip tooltipContent={t("organization_members.table_header.delete")}>
              <GhostButton
                icon={<DeleteIcon />}
                actionButton
                onClick={() =>
                  openDeleteOrganizationReferenceConfirmation(async () => {
                    await organizationService.deleteOrganizationReference(id, row.original.id);
                    refetch();
                  })
                }
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    [refetch, openReferenceDetailModal]
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading level={3}>{t("organization_detail.transactions.lists.references")}</Heading>

        <PrimaryButton
          label={t("organization_detail.transactions.lists.add_reference")}
          icon={<AddIcon color={colors.white} />}
          onClick={handleAddReference}
          className="w-auto"
        />
      </div>

      <LoaderOverlay isLoading={isLoading}>
        <Table columns={referencesTableColumns} data={data ?? []} />
      </LoaderOverlay>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(References);
