import React, { useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { LoaderOverlay, TableFooter } from "components/Common";
import BucketTransactionsTable from "components/DataManager/BucketTransactionsTable";
import { getSelectedBuckets } from "selectors/bucketSelectors";
import { confirmationActions } from "actions/confirmationActions";
import { bucketActions } from "actions/bucketActions";
import BucketTransactionsHeading from "./BucketTransactionsHeading";

const BucketTransactions = ({
  selectedBuckets,
  buckets: {
    transactions,
    filters: { page, count },
    filterOptions,
    isTransactionsFetching,
    totalTransactions,
  },
  modalActions: { openEditTransaction },
  confirmationActions: { openDeleteTransactionsFromBucketConfirmation },
  bucketActions: { changeFilterAndFetch, getBucketTransactions, setBucketTransactionsFilter },
}) => {
  const [transactionSelection, setTransactionSelection] = useState({});

  const selectedBucket = selectedBuckets.length === 1 ? selectedBuckets[0] : undefined;

  useEffect(() => {
    // Reset selection when transactions change
    setTransactionSelection({});
  }, [transactions]);

  const selectedTransactions = useMemo(
    () => transactions.filter(o => transactionSelection[o.id]),
    [transactionSelection, transactions]
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <>
      <BucketTransactionsHeading
        selectedBucket={selectedBucket}
        selectedTransactions={selectedTransactions}
        filter={{ isFilterVisible, setIsFilterVisible }}
        selectedBuckets={selectedBuckets}
        openDeleteTransactionsFromBucketConfirmation={openDeleteTransactionsFromBucketConfirmation}
      />
      <LoaderOverlay isLoading={isTransactionsFetching}>
        <BucketTransactionsTable
          transactions={transactions}
          editTransaction={openEditTransaction}
          transactionSelection={transactionSelection}
          setTransactionSelection={setTransactionSelection}
          selectedBucket={selectedBucket}
          filter={{ isFilterVisible, setIsFilterVisible }}
          getBucketTransactions={getBucketTransactions}
          filterOptions={filterOptions}
          setBucketTransactionsFilter={setBucketTransactionsFilter}
          openDeleteTransactionsFromBucketConfirmation={openDeleteTransactionsFromBucketConfirmation}
        />
        <TableFooter
          page={page}
          count={count}
          totalRecords={totalTransactions}
          onChange={changeFilterAndFetch}
          isVisible={transactions.length > 0}
        />
      </LoaderOverlay>
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedBuckets: getSelectedBuckets(state),
    buckets: state.buckets,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketTransactions);
