import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { useTranslation } from "react-i18next";
import { languages } from "common/constants/languages";
import LanguageIcon from "components/Common/LanguageIcon";

const ApplicationPreferencesForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  const languageOptions = useMemo(
    () =>
      languages.map(x => ({
        ...x,
        label: (
          <>
            <LanguageIcon lang={x.lang} /> {t(x.label)}
          </>
        ),
      })),
    [t]
  );

  return (
    <Formik initialValues={{ language: languageOptions.find(x => x.value === initialValues.language.value) }} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <SelectFormField
            name="language"
            label={t("form.application_preferences.language")}
            disabled={isSubmitting}
            options={languageOptions}
          />
          <PrimaryButton label={t("form.application_preferences.edit")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationPreferencesForm;
