import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAnchors = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-vars
        const element = document.getElementById(location.hash.substr(1, location.hash.length))?.scrollIntoView(true);
        /* setTimeout(() => {
          window.scrollBy(0, -100);
        }, 200); */
      });
    }
  }, [location.hash]);
};
