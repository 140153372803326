import React from "react";
import { AxisBottom } from "@vx/axis";
import { timeFormatLocale } from "d3-time-format";
import { dateFormat, timeFormat, dateTimeFormat } from "common/formatters";
import { utcSecond, utcMinute, utcHour, utcDay, utcMonth, utcWeek, utcYear } from "d3-time";
import { colors } from "common/colors";
import { useTranslation } from "react-i18next";

const heavyFont = "archivoheavy";

// https://bl.ocks.org/wboykinm/34627426d84f3242e0e6ecb2339e9065
// locales https://github.com/d3/d3-time-format/blob/master/locale/cs-CZ.json

export default ({ scale, top, label, numTicks = 11, ...rest }) => {
  const { t } = useTranslation();
  const locale = timeFormatLocale({
    dateTime: dateTimeFormat,
    date: dateFormat,
    time: timeFormat,
    periods: ["AM", "PM"],
    days: [
      t("time.day.d1"),
      t("time.day.d2"),
      t("time.day.d3"),
      t("time.day.d4"),
      t("time.day.d5"),
      t("time.day.d6"),
      t("time.day.d7"),
    ],
    shortDays: [
      t("time.day_short.d1"),
      t("time.day_short.d2"),
      t("time.day_short.d3"),
      t("time.day_short.d4"),
      t("time.day_short.d5"),
      t("time.day_short.d6"),
      t("time.day_short.d7"),
    ],
    months: [
      t("time.month.d1"),
      t("time.month.d2"),
      t("time.month.d3"),
      t("time.month.d4"),
      t("time.month.d5"),
      t("time.month.d6"),
      t("time.month.d7"),
      t("time.month.d8"),
      t("time.month.d9"),
      t("time.month.d10"),
      t("time.month.d11"),
      t("time.month.d12"),
    ],
    shortMonths: [
      t("time.month_short.d1"),
      t("time.month_short.d2"),
      t("time.month_short.d3"),
      t("time.month_short.d4"),
      t("time.month_short.d5"),
      t("time.month_short.d6"),
      t("time.month_short.d7"),
      t("time.month_short.d8"),
      t("time.month_short.d9"),
      t("time.month_short.d10"),
      t("time.month_short.d11"),
      t("time.month_short.d12"),
    ],
  });

  const formatMillisecond = locale.utcFormat(".%L");
  const formatSecond = locale.utcFormat(":%S");
  const formatMinute = locale.utcFormat("%I:%M");
  const formatHour = locale.utcFormat("%I %p");
  const formatDay = locale.utcFormat("%a %d");
  const formatWeek = locale.utcFormat("%b %d");
  const formatMonth = locale.utcFormat("%B");
  const formatYear = locale.utcFormat("%Y");

  const multiFormat = date =>
    (utcSecond(date) < date
      ? formatMillisecond
      : utcMinute(date) < date
      ? formatSecond
      : utcHour(date) < date
      ? formatMinute
      : utcDay(date) < date
      ? formatHour
      : utcMonth(date) < date
      ? utcWeek(date) < date
        ? formatDay
        : formatWeek
      : utcYear(date) < date
      ? formatMonth
      : formatYear)(date);

  return (
    <AxisBottom
      scale={scale}
      top={top}
      label={label}
      labelOffset={15}
      labelProps={{
        textAnchor: "middle",
        fontFamily: heavyFont,
        fontSize: 13,
        fill: colors.text,
      }}
      stroke={colors.text}
      tickTextFill={colors.text}
      strokeWidth={1}
      tickLength={4}
      numTicks={numTicks}
      tickLabelProps={(val, i) => ({
        dx: "-0.25em",
        dy: "0.25em",
        textAnchor: "middle",
        fontFamily: heavyFont,
        fontSize: 10,
        fill: colors.text,
      })}
      tickFormat={multiFormat}
      {...rest}
    />
  );
};
