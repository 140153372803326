export const CONTAINER_API = "API";
export const CONTAINER_CSV = "CSV";
export const CONTAINER_MANUAL = "MANUAL";

export const containerTypes = [CONTAINER_API, CONTAINER_CSV, CONTAINER_MANUAL];

const t_fake = str => str;

const containerTypesTranslations = {
  [CONTAINER_API]: t_fake("container_type.api"),
  [CONTAINER_CSV]: t_fake("container_type.csv"),
  [CONTAINER_MANUAL]: t_fake("container_type.manual"),
};

export const containerTypesTranslator = val => containerTypesTranslations[val] || "";
