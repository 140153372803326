import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userActions } from "actions/userActions";
import { bindActionCreators } from "redux";
import { alertActions } from "actions/alertActions";
import queryString from "query-string";
import history from "common/history";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import ResetPasswordForm from "containers/Forms/ResetPasswordForm";

import styles from "pages/Pages.module.scss";

const ResetPassword = ({ userActions, location, isAuthenticated }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const validUntil = queryString.parse(location.search).tv;
    if (Math.floor(new Date().getTime() / 1000) > validUntil) {
      alertActions.error(t("alert.error.link_expired"));
      history.push(isAuthenticated ? "/profile" : "/forgot");
    }
  }, [isAuthenticated, location, t]);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const { token } = queryString.parse(location.search);
    const { passwordConfirm, ...rest } = values;
    const result = await userActions.setForgottenPassword(rest, token);
    if (result === false) {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.subpage_form}>
      <Heading level={3} margin="0 auto 1.1rem">
        {t("reset_password.heading")}
      </Heading>
      <ResetPasswordForm onSubmit={(values, formProps) => onSubmit(values, formProps)} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
