import React from "react";
import { Trans } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { colors } from "common/colors";
import { formatThousandsSeparator } from "common/formatters";
import { useDefaultsStore } from "stores/defaultsStore";
import { transactionActions } from "actions/transactionActions";
import Pagination from "./Pagination";

const countOptions = [50, 100, 250];

const format = number => (number ? formatThousandsSeparator(number) : undefined);

const TableFooter = ({ page, count, totalRecords, onChange, isVisible, transactionActions: { changeFilter } }) => {
  const handleSetCount = count => {
    onChange({ count });
    useDefaultsStore.setState({ perPageDefault: count });
    changeFilter({ count: useDefaultsStore.getState().perPageDefault });
  };

  if (!isVisible) return null;

  return (
    <div className="d-md-flex align-items-center justify-content-between">
      <span className="d-flex flex-md-column align-items-md-start mb-4 mb-md-0 justify-content-center justify-content-md-start">
        <span>
          <Trans i18nKey="components.table_footer.pagination">
            Showing records {{ from: format(page * count + 1) }}-
            {{ to: format(page * count + count > totalRecords ? totalRecords : page * count + count) }} out of{" "}
            {{ total: format(totalRecords) }}
          </Trans>
        </span>
        <select
          value={count.toString()}
          className="mt-md-1 ml-3 ml-md-0"
          onChange={e => handleSetCount(Number(e.target.value))}
          style={{ borderRadius: "4px", borderColor: colors.text, color: colors.text }}>
          {countOptions.map(x => (
            <option key={x}>{x}</option>
          ))}
        </select>
      </span>

      <Pagination
        page={page}
        total={Math.ceil(totalRecords / count)}
        onChange={val => onChange({ page: val })}
        isVisible={isVisible}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(TableFooter);
