import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const DeleteOrganizationMember = ({ onConfirm, onCancel, name }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_organization_member.heading")}
      confirmLabel={t("confirmation.delete_organization_member.confirm_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.delete_organization_member.content">
          Are you sure you want to delete member <b>{{ name }}</b> from organization?
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default DeleteOrganizationMember;
