import React, { useMemo } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { validateSubtransaction } from "common/validators";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { transactionService } from "services/transactionService";
import { alertActions } from "actions/alertActions";
import { canTxHaveRebate, FEE, REBATE, transactionTypeTranslator } from "common/constants/transactionType";
import { formatDate, utc } from "common/formatters";
import { bucketActions } from "actions/bucketActions";
import { getCurrencyOptions } from "selectors";
import FeeRebateFormContent from "./FeeRebateFormContent";

const AddFeeRebateTransactionForm = ({
  originalTransaction,
  transactionActions: { getTransactions },
  modalActions: { hideModal },
  bucketActions: { getBuckets },
  user,
  currencyOptions,
  labelOptions,
}) => {
  const { t } = useTranslation();

  const canHaveRebate = canTxHaveRebate(originalTransaction.type);
  const transactionTypeOptions = canHaveRebate
    ? [
        { value: FEE, label: t(`${transactionTypeTranslator(FEE)}`) },
        { value: REBATE, label: t(`${transactionTypeTranslator(REBATE)}`) },
      ]
    : [{ value: FEE, label: t(`${transactionTypeTranslator(FEE)}`) }];

  const { baseCurrency, quoteCurrency } = originalTransaction;

  const currencyOptionsWithTxCurrencies = useMemo(
    () => [
      {
        label: t("form.add_transaction.tx_currencies"),
        options: [
          currencyOptions.find(x => x.value === baseCurrency),
          ...(baseCurrency !== quoteCurrency ? [currencyOptions.find(x => x.value === quoteCurrency)] : []),
        ],
      },
      {
        label: t("form.add_transaction.all_currencies"),
        options: currencyOptions.filter(x => x.value !== baseCurrency && x.value !== quoteCurrency),
      },
    ],
    [baseCurrency, quoteCurrency, currencyOptions, t]
  );

  const onSubmit = async ({ date, timestamp, type, feeRebateCurrency, labels, ...tx }, { setSubmitting }) => {
    const dateMoment = utc(date);

    try {
      await transactionService.createTransaction(
        {
          ...tx,
          type: type.value,
          feeRebateCurrency: feeRebateCurrency.value,
          timestamp: timestamp
            .set({
              year: dateMoment.year(),
              month: dateMoment.month(),
              date: dateMoment.date(),
            })
            .valueOf(),
          mainTransactionId: originalTransaction.id,
          transactionLabels: labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
        },
        originalTransaction.transactionContainerId,
        user
      );

      hideModal();
      alertActions.success(t("alert.success.subtx_added"));
      getTransactions();
      getBuckets();
    } catch (err) {
      alertActions.error(err);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        type: transactionTypeOptions[0],
        feeRebateCurrency: currencyOptionsWithTxCurrencies[0].options[0],
        feeRebate: "",
        date: formatDate(utc(originalTransaction.timestamp)),
        timestamp: utc(originalTransaction.timestamp),
        exchangeName: originalTransaction.exchangeName,
        baseCurrency: originalTransaction.baseCurrency,
        quoteCurrency: originalTransaction.quoteCurrency,
        note: "",
        labels: originalTransaction.transactionLabels?.map(x => labelOptions.find(y => y.value === x.transactionLabelId)) ?? [],
      }}
      validate={validateSubtransaction}
      onSubmit={onSubmit}>
      {props => (
        <FeeRebateFormContent
          {...props}
          transactionTypeOptions={transactionTypeOptions}
          currencyOptionsWithTxCurrencies={currencyOptionsWithTxCurrencies}
        />
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    currencyOptions: getCurrencyOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFeeRebateTransactionForm);
