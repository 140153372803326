import React from "react";
import { WIDE } from "common/constants/modalWidths";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "actions/accountActions";
import ModalEnvelope from "./ModalEnvelope";
import AddAccountAddressForm from "../Forms/AddAccountAddressForm";

const AddAccountAddressModal = ({ onClose, accountDetail, accountActions: { updateAccount } }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope id="add-account-address-modal" heading={t("modal.add_account_address.heading")} width={WIDE}>
      <AddAccountAddressForm onClose={onClose} accountDetail={accountDetail} updateAccount={updateAccount} />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    accountDetail: state.accounts.accountDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAccountAddressModal);
