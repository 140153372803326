import React, { useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as DownIcon } from "img/DownIcon.svg";
import { ReactComponent as ChevronsDown } from "img/menuIcons/chevronsDown.svg";
import Gear from "img/menuIcons/gear.png";
import ExpandableMenu from "components/Common/ExpandableMenu";
import styles from "components/Styles/AppStructure.module.scss";
import Delimiter from "components/Common/Delimiter";
import { getDataTestId, trimStringToMaxChars } from "utils";
import history from "common/history";
import { GhostButton } from "components/Common";
import { useScreenSize } from "hooks";
import TransactionCapacity from "containers/TransactionCapacity";

const expandButtonDisplayMinLimit = 5;

const Organizations = ({ memberships, activeMemberShip, switchOrganization, disableBorder }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isSmall, isMedium } = useScreenSize();

  const organizations = useMemo(
    () => ({
      active: memberships.find(x => x.organizationId === activeMemberShip?.organizationId),
      all: memberships.filter(x => x.organizationId !== activeMemberShip?.organizationId) ?? [],
    }),
    [memberships, activeMemberShip]
  );

  const displayExpand = organizations.all.length > expandButtonDisplayMinLimit;

  const [displayFullHeight, setDisplayFullHeight] = useState(false);
  const [hoveredOrganizationId, setHoveredOrganizationId] = useState();

  const organizationsList = () => (
    <>
      {organizations.all.map(membership => {
        const isHovered = membership.organizationId === hoveredOrganizationId;

        return (
          <li
            key={membership.organizationId}
            className={styles.organization_menu_option}
            onMouseEnter={() => setTimeout(() => setHoveredOrganizationId(membership.organizationId))}
            onMouseLeave={() => setHoveredOrganizationId(undefined)}>
            <div
              onClick={() => {
                if (membership.organizationId !== activeMemberShip?.organizationId) switchOrganization(membership.organizationId);
              }}
              data-testid={getDataTestId("switch-organization", membership.name)}
              className="text-uppercase d-flex align-items-center"
              style={{ maxWidth: isSmall ? "300px" : "350px" }}>
              {membership.name}
            </div>

            {((!isSmall && isHovered) || isSmall) && (
              <GhostButton
                icon={<img src={Gear} alt="" />}
                onClick={e => {
                  e.preventDefault();
                  history.push(`/organizations/${membership.organizationId}/detail`);
                }}
                testId={`organization-detail_${membership.name}`}
                className={styles.organization_detail_button}
              />
            )}
          </li>
        );
      })}
    </>
  );

  return (
    <ExpandableMenu
      label={
        <span
          className={cx(styles.align_center, styles.menu_link, "text-uppercase align-items-center", {
            [styles.active_link]: location.pathname === "/organizations",
          })}>
          {trimStringToMaxChars(activeMemberShip?.name, isMedium ? 15 : 33)}
          <DownIcon className="mr-n1" />
        </span>
      }
      className={cx({ [styles.border_none]: disableBorder })}
      testId={getDataTestId("link-expandable", "organizations")}
      onClose={() => setDisplayFullHeight(false)}
      dataTour="organizations-link"
      listClassName={cx({ [styles.collapsed_organizations_list]: !displayFullHeight && displayExpand })}>
      <li className={cx(styles.organizations_option, styles.align_center)} style={{ marginBottom: "var(--dropdown-padding)" }}>
        <NavLink
          className={styles.organizations_link}
          activeClassName={styles.active_link_nested}
          exact
          to="/organizations"
          data-testid={getDataTestId("link", "all-organizations")}>
          <span className="text-uppercase">{t("header.menu.organizations.header")}</span>&nbsp;
          <span className={styles.manage_organizations_link}>{t("header.menu.organizations.manage")}</span>
        </NavLink>
      </li>

      <Delimiter style={{ marginBottom: "var(--dropdown-padding)" }} />

      {organizations.active && (
        <li className={styles.active_wrapper}>
          <span className="d-flex justify-content-between align-items-center text-start">
            <span>
              <div className={styles.active_bubble}>{t("header.menu.organizations.active")}</div>
              <div
                className="font-weight-bold"
                style={{
                  maxWidth: isSmall ? "250px" : "320px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                }}>
                {organizations.active.name}
              </div>
            </span>

            <GhostButton
              label={t("button.settings")}
              onClick={e => {
                e.preventDefault();
                history.push(`/organizations/${organizations.active.organizationId}/detail`);
              }}
              testId={`organization-detail_${organizations.active.name}`}
              className="ml-0 px-0 mr-n2 mr-md-n1"
              style={{ color: "var(--primary-primary)", textDecoration: "underline", fontWeight: isSmall ? "" : "bold" }}
            />
          </span>

          {!isSmall && <TransactionCapacity style={{ marginTop: "4px", marginLeft: "-3px" }} alwaysVisible />}
        </li>
      )}

      {isSmall && (
        <TransactionCapacity
          alwaysVisible
          innerWrapper={children => (
            <div className="w-100" style={{ padding: "0 var(--mobile-padding) 16px var(--mobile-padding)" }}>
              {children}
            </div>
          )}
        />
      )}

      <Delimiter style={{ margin: "var(--dropdown-padding) 0" }} />

      {isSmall ? (
        <div style={{ maxHeight: displayFullHeight ? "" : "350px", overflowY: "scroll" }}>{organizationsList()}</div>
      ) : (
        <>{organizationsList()}</>
      )}

      {displayExpand && !displayFullHeight && (
        <>
          <li
            className={styles.expand_organizations_list}
            onClick={e => {
              e.stopPropagation();
              setDisplayFullHeight(true);
            }}>
            <ChevronsDown />
          </li>
        </>
      )}
    </ExpandableMenu>
  );
};

export default Organizations;
