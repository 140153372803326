import axios from "axios";
import queryString from "query-string";
import { losslesslyParseJSON, mapTransaction } from "utils";
import { formatDate } from "common/formatters";
import { useDefaultsStore } from "stores/defaultsStore";

const cancelToken = axios.CancelToken;
let getTransactionsCancelToken;

async function getBuckets({ organizationId }) {
  const response = await axios.get(`/organizations/${organizationId}/buckets`, {
    transformResponse: response => losslesslyParseJSON(response),
  });
  return response.data;
}

async function getBucketTransactions(
  { organizationId, vaultId },
  buckets,
  portfolioId,
  page = 0,
  filter,
  count = useDefaultsStore.getState().perPageDefault
) {
  if (getTransactionsCancelToken) getTransactionsCancelToken.cancel();
  getTransactionsCancelToken = cancelToken.source();

  if (buckets.length === 0)
    return {
      data: [],
      meta: {
        page: 0,
        total: 0,
      },
    };

  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/transactions`,
      query: {
        "bucket-id": buckets,
        page: page + 1,
        count,
        "portfolio-id": portfolioId,
        base: filter?.pair?.base,
        quote: filter?.pair?.quote,
        type: filter?.type?.value,
        label: filter?.label?.value,
        currency: filter?.currency?.value,
        amount: filter?.amount !== "" ? filter?.amount : null,
        operator: filter?.operator?.value,
        from: filter?.dateFrom ? formatDate(filter.dateFrom) : null,
        to: filter?.dateTo ? formatDate(filter.dateTo) : null,
      },
    },
    { skipNull: true }
  );

  const response = await axios.get(query, {
    cancelToken: getTransactionsCancelToken.token,
    transformResponse: response => losslesslyParseJSON(response),
  });

  return {
    data: response.data.map(mapTransaction),
    meta: {
      page: Number.parseInt(response.headers["page-number"]) - 1,
      total: Number.parseInt(response.headers["total-records-count"]),
    },
  };
}

async function getBucketTransactionsFilterOptions({ organizationId, vaultId }, bucketIds) {
  const query = queryString.stringifyUrl({
    url: `organizations/${organizationId}/vaults/${vaultId}/transactions-filter-option`,
    query: {
      "bucket-id": bucketIds,
    },
  });

  const response = await axios.get(query);
  return response.data;
}

async function getBucketDetail(bucketId, { organizationId }) {
  const response = await axios.get(`/organizations/${organizationId}/buckets/${bucketId}`, {
    transformResponse: response => losslesslyParseJSON(response),
  });
  return response.data;
}

async function createBucket(data, { organizationId }) {
  const response = await axios.post(`/organizations/${organizationId}/buckets`, data);
  return response.data;
}

async function updateBucket(data, { organizationId }) {
  const response = await axios.post(`/organizations/${organizationId}/buckets`, data);
  return response.data;
}

async function deleteBucket(bucketId, { organizationId }) {
  const response = await axios.delete(`/organizations/${organizationId}/buckets/${bucketId}`);
  return response.data;
}

async function addTransactionsToBucket(bucketId, txIds, { organizationId }) {
  const response = await axios.put(`/organizations/${organizationId}/buckets/${bucketId}/transactions`, txIds);
  return response.data;
}

async function deleteTransactionsFromBucket(bucketId, txIds, { organizationId }) {
  const response = await axios.delete(`/organizations/${organizationId}/buckets/${bucketId}/transactions`, { data: txIds });
  return response.data;
}

export const bucketService = {
  getBuckets,
  getBucketTransactions,
  getBucketTransactionsFilterOptions,
  getBucketDetail,
  createBucket,
  updateBucket,
  deleteBucket,
  addTransactionsToBucket,
  deleteTransactionsFromBucket,
};
