import React, { useCallback } from "react";
import cx from "classnames";

import styles from "../Styles/Tooltips.module.scss";

const TooltipEnvelope = ({ open, divided, bottom, children, updateHeight, fitContent = false, ...rest }) => {
  const measuredRef = useCallback(node => {
    if (node !== null && updateHeight) {
      updateHeight(node.getBoundingClientRect().height);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={measuredRef}
      className={cx(styles.tooltip, { [styles.tooltip_bottom]: bottom, [styles.tooltip_hidden]: !open })}
      {...rest}>
      <div className={cx(styles.tooltip_content, { [styles.tooltip_divided]: divided, [styles.fit_content]: fitContent })}>
        {children}
      </div>
    </div>
  );
};

export default TooltipEnvelope;
