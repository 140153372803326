import React from "react";
import Anycoin from "img/homeLogos/anycoin.svg";
import Aquanow from "img/homeLogos/aquanow.svg";
import Binance from "img/homeLogos/binance.svg";
import Bitfinex from "img/homeLogos/bitfinex.svg";
import Bitflyer from "img/homeLogos/bitflyer.svg";
import Bitmex from "img/homeLogos/bitmex.svg";
import Bitstamp from "img/homeLogos/bitstamp.svg";
import Bittrex from "img/homeLogos/bittrex.svg";
import Coinbase from "img/homeLogos/coinbase.svg";
import CoinbasePro from "img/homeLogos/coinbasepro.svg";
import Coinmate from "img/homeLogos/coinmate.svg";
import Coinsquare from "img/homeLogos/coinsquare.svg";
import DvChain from "img/homeLogos/dvchain.svg";
import Gateio from "img/homeLogos/gateio.svg";
import GeneralBytes from "img/homeLogos/generalbytes.svg";
import HitBTC from "img/homeLogos/hitbtc.svg";
import HuobiGlobal from "img/homeLogos/huobiglobal.svg";
import Kraken from "img/homeLogos/kraken.svg";
import KuCoin from "img/homeLogos/kucoin.svg";
import Localbitcoins from "img/homeLogos/localbitcoins.svg";
import Okx from "img/homeLogos/okx.svg";
import Paxful from "img/homeLogos/paxful.svg";
import Poloniex from "img/homeLogos/poloniex.svg";
import Shakepay from "img/homeLogos/shakepay.svg";
import Simplecoin from "img/homeLogos/simplecoin.svg";
import WhaleBooks from "img/homeLogos/whalebooks.svg";

import { useTranslation } from "react-i18next";
import cx from "classnames";
import history from "common/history";
import { Heading } from "../Common";

import styles from "../Styles/Landing.module.scss";

const listOfExchanges = [
  { img: Anycoin, alt: "AnyCoin" },
  { img: Aquanow, alt: "Aquanow" },
  { img: Binance, alt: "Binance" },
  { img: Bitfinex, alt: "Bitfinex" },
  { img: Bitflyer, alt: "Bitflyer" },
  { img: Bitmex, alt: "Bitmex" },
  { img: Bitstamp, alt: "Bitstamp" },
  { img: Bittrex, alt: "Bittrex" },
  { img: Coinbase, alt: "Coinbase" },
  { img: CoinbasePro, alt: "CoinbasePro" },
  { img: Coinmate, alt: "Coinmate" },
  { img: Coinsquare, alt: "Coinsquare" },
  { img: DvChain, alt: "DvChain" },
  { img: Gateio, alt: "Gate.io" },
  { img: GeneralBytes, alt: "GeneralBytes" },
  { img: HitBTC, alt: "HitBTC" },
  { img: HuobiGlobal, alt: "HuobiGlobal" },
  { img: Kraken, alt: "Kraken" },
  { img: KuCoin, alt: "KuCoin" },
  { img: Localbitcoins, alt: "Localbitcoins" },
  { img: Okx, alt: "Okx" },
  { img: Paxful, alt: "Paxful" },
  { img: Poloniex, alt: "Poloniex" },
  { img: Shakepay, alt: "Shakepay" },
  { img: Simplecoin, alt: "Simplecoin" },
  { img: WhaleBooks, alt: "WhaleBooks" },
];

const Exchange = ({ img, alt }) => (
  <div style={{ cursor: "pointer" }} onClick={() => history.push("/signup")}>
    <img src={img} alt={alt} />
  </div>
);

const Exchanges = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.supported_exchanges, className)}>
      <Heading level={3} textAlign="center" margin="0 auto">
        {t("landing.exchanges.heading")}
      </Heading>
      <div className={styles.note}>{t("landing.exchanges.note")}</div>
      <div className={styles.supported_exchanges_list}>
        {listOfExchanges.map(({ url, img, alt }) => (
          <Exchange url={url} img={img} alt={alt} key={alt} />
        ))}
      </div>
    </div>
  );
};

export default Exchanges;
