import React from "react";
import { connect } from "react-redux";
import { userActions } from "actions/userActions";
import { modalActions } from "actions/modalActions";
import { bindActionCreators } from "redux";
import { NavLink, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { TextFormField, PasswordFormField } from "components/Common/Inputs";
import { useTranslation } from "react-i18next";
import { Heading, PrimaryButton, Form } from "components/Common";
import queryString from "query-string";
import { object, string } from "yup";
import GoogleAuth from "../GoogleAuth";

import styles from "../Styles/Forms.module.scss";

const LoginForm = ({ modalActions, userActions }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const onSubmit = async (values, { setSubmitting }) => {
    const { token } = queryString.parse(search);
    await userActions.loginAndLoad(values, token || null);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={object({
        email: string().email().required(),
        password: string().required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <GoogleAuth googleButtonText={t("form.login.google_login")} isSignUp={false} />
          <Heading level={3}>{t("form.login.or")}</Heading>
          <TextFormField name="email" label={t("form.login.email")} disabled={isSubmitting} autoFocus autoComplete="username" />
          <PasswordFormField
            name="password"
            label={t("form.login.password")}
            disabled={isSubmitting}
            autoComplete="current-password"
          />
          <div className={styles.button_margin}>
            <PrimaryButton label={t("form.login.login")} isLoading={isSubmitting} onClick={handleSubmit} />
          </div>
          <div className={styles.flex_space_between}>
            <NavLink exact to="/forgot" onClick={modalActions.hideModal} className={styles.small_link}>
              {t("form.login.forgot_password")}
            </NavLink>
            <NavLink exact to={`/signup${search}`} onClick={modalActions.hideModal} className={styles.small_link}>
              {t("form.login.sign_up")}
            </NavLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoginForm);
