import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import OrganizationDetailForm from "containers/Forms/OrganizationDetailForm";
import { WIDE } from "common/constants/modalWidths";
import history from "common/history";
import { organizationActions } from "actions/organizationActions";
import ModalEnvelope from "./ModalEnvelope";

const OrganizationDetailModal = ({
  organization,
  modalActions: { hideModal },
  organizationActions: { createOrganization, editOrganizationInfo },
}) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const formattedValues = {
      ...organization,
      ...values,
      taxResidency: values.taxResidency.value,
      taxSubject: values.taxSubject.value,
      plan: values.plan.value,
    };

    if (organization) {
      await editOrganizationInfo(organization.id, formattedValues);
      history.replace(`/organizations/${organization.id}/detail`);
    } else {
      createOrganization(formattedValues);
    }

    hideModal();
  };

  return (
    <ModalEnvelope
      width={WIDE}
      id="organization-detail-modal"
      heading={organization ? t("modal.organization_detail.heading") : t("modal.organization_detail.heading_new")}>
      <OrganizationDetailForm organization={organization} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(OrganizationDetailModal);
