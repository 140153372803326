export const DO_NOT_INCLUDE = "DO_NOT_INCLUDE";
export const INCLUDE = "INCLUDE";

const t_fake = str => str;

const accountingIncludeTranslations = {
  [DO_NOT_INCLUDE]: t_fake("accounting_deposit.do_not_include"),
  [INCLUDE]: t_fake("accounting_deposit.include"),
};

export const accountingIncludeTranslator = val => accountingIncludeTranslations[val] || "";

export const accountingIncludeOptions = t => [
  { label: t(accountingIncludeTranslator(DO_NOT_INCLUDE)), value: DO_NOT_INCLUDE },
  { label: t(accountingIncludeTranslator(INCLUDE)), value: INCLUDE },
];
