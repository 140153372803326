import moment from "moment";
import bigDecimal from "js-big-decimal";
import { DECIMAL_DIGITS } from "components/Common/Inputs/DecimalInput";

export const roundToFixed = (number, precision = DECIMAL_DIGITS) => {
  const pow = Math.pow(10, precision);
  return Math.round(number * pow) / pow;
};

// Rounds large number string
export const roundBigDecimal = (number, precision = DECIMAL_DIGITS) => bigDecimal.round(number, precision);

export const formatCurrency = number => {
  let val = number;
  let suffix = "";
  let fixedDecimalScale = false;
  if (Math.abs(number) < 100000) {
    // nothing - (numbers are already rounded to required decimal length in selectors)
    // Big numbers - https://idlechampions.fandom.com/wiki/Large_number_abbreviations
  } else if (Math.abs(number) < 1000000) {
    val = roundToFixed(number / 1000, 2);
    suffix = " K";
    fixedDecimalScale = true;
  } else if (Math.abs(number) < 1000000000) {
    val = roundToFixed(number / 1000000, 2);
    suffix = " M";
    fixedDecimalScale = true;
  } else if (Math.abs(number) < 1000000000000) {
    // Billion - divide by 9 zeros
    val = roundToFixed(number / 1000000000, 2);
    suffix = " B";
    fixedDecimalScale = true;
  } else if (Math.abs(number) < 1000000000000000) {
    // Trillion - divide by 12 zeros
    val = roundToFixed(number / 1000000000000, 2);
    suffix = " t";
    fixedDecimalScale = true;
  } else if (Math.abs(number) < 1000000000000000000) {
    // Quadrillion - divide by 15 zeros
    val = roundToFixed(number / 1000000000000000, 2);
    suffix = " q";
    fixedDecimalScale = true;
  } else {
    // Quintillion - divide by 18 zeros
    val = roundToFixed(number / 1000000000000000000, 2);
    suffix = " Q";
    fixedDecimalScale = true;
  }
  // TODO: Keep precision for divided

  return fixedDecimalScale
    ? {
        number: val,
        suffix,
        fixedDecimalScale: true,
        decimalScale: 2,
      }
    : {
        number: val,
        suffix,
      };
};

export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "H:mm";

export const timeFormatWithSeconds = "HH:mm:ss";

export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const dateTimeFormatWithSeconds = `${dateFormat} ${timeFormatWithSeconds}`;

export const formatDate = (date, format = dateFormat) => {
  if (date == null) return "";
  return moment(date).format(format);
};

export const formatUtcDate = (date, format = dateFormat) => {
  if (date == null) return "";
  return moment.utc(date).format(format);
};

export const convertLocalToUTCDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date;
};

export const convertUTCToLocalDate = date => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
};

export const formatRegex = regex => regex.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");

export const { utc } = moment;

export default {
  formatCurrency,
  formatDate,
  formatUtcDate,
  formatRegex,
  utc,
};

export const formatThousandsSeparator = (number, commaAsDecimalSeparator = false, keepZeros = false) => {
  if (!number || number === "." || number === ",") return number;
  if (isNaN(Number(number))) return 0;

  const numberString = typeof number === "string" ? number.replace(/\s/g, "") : number.toString();
  const result = bigDecimal.getPrettyValue(keepZeros ? numberString : formatWithoutTrailingZeros(numberString), 3, " ");
  return commaAsDecimalSeparator ? result.replace(".", ",") : result;
};

export const formatWithoutTrailingZeros = number => {
  if (!number) return number;

  if (number.includes(".")) {
    return number.replace(/\.?0+$/, "");
  }

  if (number.includes(",")) {
    return number.replace(/,?0+$/, "");
  }
  return number;
};

export const formatTime = (time, enableSeconds = false) =>
  time
    ? `${String(time.hours()).padStart(2, "0")}:${String(time.minutes()).padStart(2, "0")}${
        enableSeconds && `:${String(time.seconds()).padStart(2, "0")}`
      }`
    : undefined;

export const formatNumberToLocale = (number, commaAsDecimalSeparator) =>
  commaAsDecimalSeparator ? number.toString().replace(".", ",") : number;
