import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Heading, LoaderOverlay } from "components/Common";
import AddTransactionExplanation from "components/Modals/ModalExplanations/AddTransactionExplanation";
import { WIDE } from "common/constants/modalWidths";
import { getLabels, getTransaction } from "queries/transactions";
import { modalActions } from "actions/modalActions";
import AddFeeRebateTransactionForm from "../Forms/Transactions/AddFeeRebateTransactionForm";
import AddTransactionForm from "../Forms/Transactions/AddTransactionForm";
import ModalEnvelope from "./ModalEnvelope";

const AddTransactionModal = ({
  isSubtransaction = false,
  user,
  originalTransaction,
  modalActions: { openEditTransaction },
  ...props
}) => {
  const { t } = useTranslation();

  const labelsQuery = useQuery(getLabels(user));
  const labelOptions = labelsQuery?.data ?? [];

  const mainTransactionQuery = useQuery(
    getTransaction(
      { txId: originalTransaction?.id, containerId: originalTransaction?.transactionContainerId },
      user,
      !!originalTransaction?.id
    )
  );

  const handleOpenEditMainTransaction = () => openEditTransaction(mainTransactionQuery.data);

  return (
    <ModalEnvelope
      id="add-transaction-modal"
      heading={isSubtransaction ? t("modal.add_transaction.heading_subtransaction") : t("modal.add_transaction.heading")}
      description={<AddTransactionExplanation />}
      width={WIDE}>
      <LoaderOverlay isLoading={labelsQuery.isLoading}>
        {isSubtransaction ? (
          <>
            <AddFeeRebateTransactionForm {...props} labelOptions={labelOptions} originalTransaction={originalTransaction} />

            <div
              onClick={handleOpenEditMainTransaction}
              className="w-100 d-flex mt-4 mb-0 mb-md-n3 justify-content-center cursor-pointer"
              style={{ color: "var(--primary-primary)" }}>
              {t("form.add_transaction.display_main_tx")}
            </div>
          </>
        ) : (
          <>
            <Heading level={5} textAlign="center" margin="-1rem auto 1rem auto">
              {props.containerName}
              {props.exchangeName && <span>{` [${props.exchangeName}]`}</span>}
            </Heading>
            <AddTransactionForm {...props} labelOptions={labelOptions} originalTransaction={originalTransaction} />
          </>
        )}
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionModal);
