import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import cx from "classnames";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { colors } from "common/colors";
import styles from "../Styles/Tooltips.module.scss";
import { ReactComponent as TooltipIcon } from "img/tooltip.svg";

const dynamicTooltipRootEl = document.getElementById("tooltip-root");

const DynamicTooltipPortal = ({ children }) => ReactDOM.createPortal(children, dynamicTooltipRootEl);

function DynamicTooltip({
  children,
  walkthroughOn,
  isAbsolute = false,
  className,
  buttonClassName,
  position = "right",
  event = "click",
  clickable = false,
}) {
  const [tooltipId] = useState(uuidv4());
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <button
        data-tip
        data-for={tooltipId}
        data-event={event}
        className={cx(buttonClassName, {
          [styles.tooltip_button_absolute]: isAbsolute,
        })}
        disabled={walkthroughOn}
        type="button"
        onClick={e => e.preventDefault()}
        tabIndex={-1}
        style={{ height: "20px" }}>
        <TooltipIcon />
      </button>

      <DynamicTooltipPortal>
        <ReactTooltip
          backgroundColor={colors.gray4}
          id={tooltipId}
          place={position}
          effect="solid"
          multiline
          className={cx(styles.dynamic_tooltip, className)}
          event={event}
          clickable={clickable}
          globalEventOff={event}
          afterShow={() => setIsActive(true)}
          afterHide={() => setIsActive(false)}>
          {children}
        </ReactTooltip>
      </DynamicTooltipPortal>
    </>
  );
}

const mapStateToProps = state => ({
  walkthroughOn: state.walkthrough.portfolioIsOpen || state.walkthrough.dataManagerIsOpen,
});

export default connect(mapStateToProps)(DynamicTooltip);
