import React, { useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "actions/userActions";
import { bindActionCreators } from "redux";
import { userService } from "services";
import { alertActions } from "actions/alertActions";
import { useTranslation } from "react-i18next";
import { Heading, PrimaryButton } from "components/Common";
import queryString from "query-string";
import SignUpForm from "containers/Forms/SignUpForm";

import styles from "pages/Pages.module.scss";

const SignUp = ({ user, userActions }) => {
  const [isResendLoading, setIsResetLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const onSubmit = async (values, { setSubmitting }) => {
    const { passwordConfirm, ...otherValues } = values;
    await userActions.register(otherValues, params.token || "");
    setSubmitting(false);
  };

  return user.registeredEmail ? (
    <div className={styles.subpage_form}>
      <Heading level={3} margin="0 auto 1.1rem">
        {t("sign_up.verification_sent")}
      </Heading>
      <p className={styles.subpage_paragraph}>
        {t("sign_up.link_sent_to")}
        <b>{` ${user.registeredEmail} `}</b>
      </p>
      <p className={styles.subpage_paragraph}>{t("sign_up.verification_complete")}</p>
      <Heading level={4} margin="1.5rem 0 0 0">
        {t("sign_up.email_did_not_arrive")}
      </Heading>
      <p className={styles.subpage_paragraph}>{t("sign_up.check_span")}</p>
      <PrimaryButton
        disabled={isResendLoading}
        label={t("sign_up.resend_verification")}
        onClick={async () => {
          setIsResetLoading(true);
          try {
            await userService.resendVerification(user.registeredEmail);
            alertActions.success(t("alert.success.verification_resent"));
          } catch (err) {
            alertActions.error(t("alert.error.verification_not_resent"));
          }
          setIsResetLoading(false);
        }}
        collapsed
      />
    </div>
  ) : (
    <div className={styles.subpage_form}>
      <Heading level={3} margin="0 auto 1.1rem">
        {t("sign_up.sign_up")}
      </Heading>
      <SignUpForm onSubmit={onSubmit} />
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
