import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import {
  Currency as CurrencyIcon,
  Download as DownloadIcon,
  Group as UsersIcon,
  Organization as OrganizationIcon,
} from "grommet-icons";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as DownIcon } from "img/DownIcon.svg";

import { useTranslation } from "react-i18next";

import { WB_SUPPORT } from "common/constants/whaleBooksRoles";
import { getIsSpectatorMode } from "selectors";
import Delimiter from "components/Common/Delimiter";
import { useScreenSize } from "hooks";
import { colors } from "common/colors";
import cx from "classnames";
import ExpandableMenu from "components/Common/ExpandableMenu";
import styles from "../../Styles/AppStructure.module.scss";

const Support = ({ userRole, isSpectatorMode, isPresentationMode, modalActions }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();
  const location = useLocation();

  if (!(userRole === WB_SUPPORT && !isSpectatorMode) || isPresentationMode) return null;

  return (
    <>
      {!isSmall && <Delimiter style={{ margin: "var(--dropdown-padding) 0" }} />}
      <ExpandableMenu
        label={
          isSmall ? (
            <span
              style={location.pathname.startsWith("/support") ? { fontWeight: "bold", color: colors.text } : undefined}
              className={cx(styles.align_center, "text-uppercase align-items-center")}>
              {t("header.header_menu.administration")}
              <DownIcon color={colors.black} className="mr-n1" />
            </span>
          ) : (
            <div
              style={location.pathname.startsWith("/support") ? { fontWeight: "bold", color: colors.text } : undefined}
              className={cx("d-flex align-items-center")}>
              {t("header.header_menu.administration")}
              <DownIcon color={colors.black} className="mr-n1" />
            </div>
          )
        }
        expandOnClickOnly
        className={styles.align_center}>
        <li className={styles.align_center}>
          <NavLink
            activeClassName={styles.active_link_nested}
            to="/support/users"
            isActive={(_, location) => location.pathname.startsWith("/support/users")}>
            <UsersIcon size="small" className="mr-2" />
            {t("header.header_menu.users")}
          </NavLink>
        </li>
        <li className={styles.align_center}>
          <NavLink
            activeClassName={styles.active_link_nested}
            to="/support/organizations"
            isActive={(_, location) => location.pathname.startsWith("/support/organizations")}>
            <OrganizationIcon size="small" className="mr-2" />
            {t("header.header_menu.organizations")}
          </NavLink>
        </li>
        <li className={styles.align_center} onClick={modalActions.openDownloadAdminReport}>
          <div>
            <DownloadIcon size="small" className="mr-2" />
            {t("header.header_menu.admin_reports")}
          </div>
        </li>
        <li className={styles.align_center}>
          <NavLink
            activeClassName={styles.active_link_nested}
            to="/support/pairs/error-rates"
            isActive={(_, location) => location.pathname.startsWith("/support/pairs")}>
            <CurrencyIcon size="small" className="mr-2" />
            {t("header.header_menu.configured_pairs")}
          </NavLink>
        </li>
      </ExpandableMenu>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userRole: state.user.userRole,
    isSpectatorMode: getIsSpectatorMode(state),
    isPresentationMode: state.user.isPresentationMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);
