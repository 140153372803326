import React from "react";
import {
  LEAVE_WHALEBOOKS,
  LEAVE_ORGANIZATION,
  SET_PASSWORD,
  DELETE_ORGANIZATION_MEMBER,
  DELETE_PORTFOLIO,
  DELETE_TRANSACTION_CONTAINERS,
  DELETE_TRANSACTIONS,
  JOIN_ORGANIZATION,
  RESEND_INVITATION,
  LOGOUT_TO_JOIN_ORGANIZATION,
  DELETE_ORGANIZATION,
  EDIT_API_CONNECTION,
  RELOAD_TRANSACTIONS,
  DELETE_ACCOUNT_ADDRESS,
  DELETE_ACCOUNT,
  DELETE_BUCKETS,
  DELETE_TRANSACTIONS_FROM_BUCKET,
  ENABLE_EXPERIMENTAL_COMPUTATION,
  DELETE_RULESET,
  DELETE_API_MANAGEMENT_PERMISSION,
  ADD_API_MANAGEMENT_PERMISSION,
  DELETE_ALL_PTF_PAIRS,
  DELETE_ALL_PTF_ACCOUNTS,
  DELETE_ORGANIZATION_REFERENCE,
  CHANGE_PLAN,
} from "actions/confirmationTypes";
import LeaveWhaleBooks from "components/Confirmation/LeaveWhaleBooks";
import SetPassword from "components/Confirmation/SetPassword";
import LeaveOrganization from "components/Confirmation/LeaveOrganization";
import DeleteOrganizationMember from "components/Confirmation/DeleteOrganizationMember";
import DeleteOrganization from "components/Confirmation/DeleteOrganization";
import DeletePortfolio from "components/Confirmation/DeletePortfolio";
import DeleteTransactionContainers from "components/Confirmation/DeleteTransactionContainers";
import DeleteTransactions from "components/Confirmation/DeleteTransactions";
import AcceptInvitation from "components/Confirmation/AcceptInvitation";
import ResendInvitation from "components/Confirmation/ResendInvitation";
import LogoutToAcceptInvitation from "components/Confirmation/LogoutToAcceptInvitation";
import EditApiConnection from "components/Confirmation/EditApiConnection";
import DeleteBuckets from "components/Confirmation/DeleteBuckets";
import DeleteTransactionsFromBucket from "components/Confirmation/DeleteTransactionsFromBucket";
import EnableExperimentalComputation from "components/Confirmation/EnableExperimentalComputation";
import DeleteRuleSet from "components/Confirmation/DeleteRuleSet";
import ReloadTransactions from "components/Confirmation/ReloadTransactions";
import DeleteAccount from "components/Confirmation/DeleteAccount";
import DeleteAccountAddress from "components/Confirmation/DeleteAccountAddress";
import DeleteApiManagementPermission from "components/Confirmation/DeleteApiManagementPermission";
import AddApiManagementPermission from "components/Confirmation/AddApiManagementPermission";
import DeleteAllPortfolioPairs from "components/Confirmation/DeleteAllPortfolioPairs";
import DeleteAllPortfolioAccounts from "components/Confirmation/DeleteAllPortfolioAccounts";
import DeleteOrganizationReference from "components/Confirmation/DeleteOrganizationReference";
import ChangePlan from "components/Confirmation/ChangePlan";

const CONFIRMATION_COMPONENTS = {
  [LEAVE_WHALEBOOKS]: LeaveWhaleBooks,
  [SET_PASSWORD]: SetPassword,
  [LEAVE_ORGANIZATION]: LeaveOrganization,
  [DELETE_ORGANIZATION_MEMBER]: DeleteOrganizationMember,
  [DELETE_PORTFOLIO]: DeletePortfolio,
  [DELETE_TRANSACTION_CONTAINERS]: DeleteTransactionContainers,
  [DELETE_TRANSACTIONS]: DeleteTransactions,
  [JOIN_ORGANIZATION]: AcceptInvitation,
  [RESEND_INVITATION]: ResendInvitation,
  [LOGOUT_TO_JOIN_ORGANIZATION]: LogoutToAcceptInvitation,
  [DELETE_ORGANIZATION]: DeleteOrganization,
  [EDIT_API_CONNECTION]: EditApiConnection,
  [RELOAD_TRANSACTIONS]: ReloadTransactions,
  [DELETE_ACCOUNT_ADDRESS]: DeleteAccountAddress,
  [DELETE_ACCOUNT]: DeleteAccount,
  [DELETE_BUCKETS]: DeleteBuckets,
  [DELETE_TRANSACTIONS_FROM_BUCKET]: DeleteTransactionsFromBucket,
  [ENABLE_EXPERIMENTAL_COMPUTATION]: EnableExperimentalComputation,
  [DELETE_RULESET]: DeleteRuleSet,
  [DELETE_API_MANAGEMENT_PERMISSION]: DeleteApiManagementPermission,
  [ADD_API_MANAGEMENT_PERMISSION]: AddApiManagementPermission,
  [DELETE_ALL_PTF_PAIRS]: DeleteAllPortfolioPairs,
  [DELETE_ALL_PTF_ACCOUNTS]: DeleteAllPortfolioAccounts,
  [DELETE_ORGANIZATION_REFERENCE]: DeleteOrganizationReference,
  [CHANGE_PLAN]: ChangePlan,
};

const ConfirmationDialogContainer = ({ confirmationType, confirmationProps }) => {
  const ConfirmationComponent = CONFIRMATION_COMPONENTS[confirmationType];
  return ConfirmationComponent ? <ConfirmationComponent {...confirmationProps} /> : null;
};

export default ConfirmationDialogContainer;
