import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const EnableExperimentalComputation = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.enable_experimental_computation.heading")}
      confirmLabel={t("confirmation.enable_experimental_computation.confirm_label")}
      cancelLabel={t("confirmation.enable_experimental_computation.cancel_label")}
      critical>
      <div className="d-block">
        <p>{t("confirmation.enable_experimental_computation.p1")}</p>
        <p>{t("confirmation.enable_experimental_computation.p2")}</p>
      </div>
    </ConfirmationEnvelope>
  );
};

export default EnableExperimentalComputation;
