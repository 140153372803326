import React from "react";
import { ResponsiveContext } from "grommet";
import { SMALL, MEDIUM, LARGE } from "common/constants/screenSizes";

export function withScreenSize(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <WrappedComponent
              isSmall={size === SMALL}
              isMedium={size === MEDIUM}
              isLarge={size === LARGE}
              size={size}
              {...this.props}
            />
          )}
        </ResponsiveContext.Consumer>
      );
    }
  };
}
