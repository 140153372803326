import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { reportActions } from "actions/reportActions";
import { ONLINE } from "common/constants/paymentPlans";
import {
  getGlobalFetchingState,
  getActivePortfolioId,
  getOpenedPortfolio,
  getOpenedPortfolioBalances,
  getOpenedPortfolioReportParams,
  getOpenedPortfolioReportParamsFetching,
  getActiveOrganizationDetail,
  getRawOpenedPortfolio,
  getOwnedPortfolios,
  getRuleSets,
  getActiveOrganizationId,
  getTradesAndOtherRewards,
  getStakingAndRewards,
} from "selectors";
import ReportOverview from "components/Reports/ReportOverview";
import DownloadReport from "components/Reports/DownloadReport";
import { Heading, LoaderOverlay } from "components/Common";
import history from "common/history";
import { modalActions } from "actions/modalActions";
import { accountActions } from "actions/accountActions";
import ReportsHeading from "components/Reports/ReportsHeading";
import AddPortfolioButton from "containers/Dashboard/AddPortfolioButton";
import styles from "pages/Pages.module.scss";
import { getFilteredRuleSets } from "utils";

const Reports = ({
  activePortfolioId,
  openedPortfolio,
  openedPortfolioBalances,
  openedPortfolioTradesAndOtherRewards,
  openedPortfolioStakingAndRewards,
  openedPortfolioParams,
  rawOpenedPortfolio,
  ownedPortfolios,
  showLoader,
  location,
  organizationDetail,
  isAccountsStatementReportDownloading,
  isAccountsReportDisabled,
  ruleSets,
  activeOrganizationId,
  reportActions: { selectPortfolio, fetchParams, downloadReport, downloadAccountsStatementReport, downloadAccountingReport },
  dataManagerState: { isFetching, portfolios },
  modalActions: { openDownloadAccountsStatement, openDownloadAccountingReport, openEditPortfolio, openFeedbackForm },
  accountActions: { getAccountStates },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const { portfolioId } = queryString.parse(location.search, { parseNumbers: true });
    selectPortfolio(portfolioId || null);
  }, [location.search, activePortfolioId, selectPortfolio]);

  useEffect(() => {
    if (activePortfolioId) fetchParams(activePortfolioId);
  }, [activePortfolioId, fetchParams, rawOpenedPortfolio]);

  useEffect(() => {
    if (activePortfolioId) getAccountStates(activePortfolioId);
  }, [activePortfolioId, getAccountStates]);

  const hasFreePlan = organizationDetail.plan === ONLINE;

  const portfolioRuleSets = useMemo(
    () => getFilteredRuleSets(ruleSets, openedPortfolioParams?.reportFromDefault, openedPortfolioParams?.reportToDefault),
    [ruleSets, openedPortfolioParams]
  );

  return (
    <div className={styles.app_content_padding}>
      <ReportsHeading
        selectedPortfolio={activePortfolioId}
        onChange={ptfId => history.push(ptfId ? `/reports?portfolioId=${ptfId}` : "/reports")}
        showFilter={portfolios.length > 0 && !isFetching}
        rawOpenedPortfolio={rawOpenedPortfolio}
        ownedPortfolios={ownedPortfolios}
      />

      {portfolios.length > 0 && !isFetching ? (
        <div className={styles.reports_content}>
          <LoaderOverlay isLoading={showLoader}>
            <DownloadReport
              {...openedPortfolioParams}
              upgradeRedirect={hasFreePlan && !showLoader}
              onDownload={(from, to, type) => downloadReport(activePortfolioId, from, to, type)}
              onDownloadAccountsStatement={(from, to, recordGA) =>
                openDownloadAccountsStatement(
                  accIds => downloadAccountsStatementReport(activePortfolioId, from, to, accIds),
                  recordGA
                )
              }
              onDownloadAccountingReport={options => downloadAccountingReport(activePortfolioId, options)}
              portfolio={openedPortfolio}
              isAccountsStatementReportDownloading={isAccountsStatementReportDownloading}
              isAccountsReportDisabled={isAccountsReportDisabled}
              openDownloadAccountingReport={openDownloadAccountingReport}
            />
          </LoaderOverlay>
          <div className={styles.divider} />
          <ReportOverview
            portfolio={openedPortfolio}
            balances={openedPortfolioBalances}
            tradesAndOtherRewards={openedPortfolioTradesAndOtherRewards}
            stakingAndRewards={openedPortfolioStakingAndRewards}
            isReportsParamsFetching={showLoader}
            openEditPortfolio={openEditPortfolio}
            rawOpenedPortfolio={rawOpenedPortfolio}
            openFeedbackForm={openFeedbackForm}
            portfolioRuleSets={portfolioRuleSets}
            activeOrganizationId={activeOrganizationId}
          />
        </div>
      ) : !isFetching ? (
        <div className={styles.icon_header_label}>
          <Heading level={3}>{t("dashboard.no_portfolio_message")}</Heading>
          <AddPortfolioButton />
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    activePortfolioId: getActivePortfolioId(state),
    openedPortfolio: getOpenedPortfolio(state),
    openedPortfolioBalances: getOpenedPortfolioBalances(state),
    openedPortfolioTradesAndOtherRewards: getTradesAndOtherRewards(state),
    openedPortfolioStakingAndRewards: getStakingAndRewards(state),
    openedPortfolioParams: getOpenedPortfolioReportParams(state),

    rawOpenedPortfolio: getRawOpenedPortfolio(state),
    ownedPortfolios: getOwnedPortfolios(state),
    dataManagerState: state.listOfPortfolios,
    showLoader: getOpenedPortfolioReportParamsFetching(state) && !getGlobalFetchingState(state),
    organizationDetail: getActiveOrganizationDetail(state),
    isAccountsStatementReportDownloading: state.reports.accountsStatementReportDownloading,
    isAccountsReportDisabled: state.accounts.isAccountsStateFetching ? undefined : state.accounts.accountStates?.length === 0,
    ruleSets: getRuleSets(state),
    activeOrganizationId: getActiveOrganizationId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
