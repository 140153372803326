import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import { organizationActions } from "actions/organizationActions";
import EditTaxRulesForm from "containers/Forms/EditTaxRulesForm";
import ModalEnvelope from "./ModalEnvelope";

const EditTaxRulesModal = ({ organization, modalActions: { hideModal }, organizationActions: { editOrganizationInfo } }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const formattedValues = {
      ...organization,
      taxResidency: values.taxResidency.value,
      taxSubject: values.taxSubject.value,
    };

    await editOrganizationInfo(organization.id, formattedValues);
    hideModal();
  };

  return (
    <ModalEnvelope id="tax-rules-modal" heading={t("modal.tax_rules.heading")}>
      <div className="mb-4 mx-1">{t("modal.tax_rules.body")}</div>

      <EditTaxRulesForm organization={organization} onSubmit={onSubmit} hideModal={hideModal} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(EditTaxRulesModal);
