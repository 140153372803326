import axios from "axios";
import moment from "moment";
import queryString from "query-string";
import { alertActions } from "actions/alertActions";
import i18n from "i18n";
import { losslesslyParseJSON } from "utils";

const cancelToken = axios.CancelToken;
let getHistoryCancelToken;
let getBalancesCancelToken;
let getBaseQuoteCancelToken;
let getFinalBalancesOptionsCancelToken;
let getFinalBalancesCancelToken;
let toggleSharingCancelToken;

const ONE_DAY_MILISECONDS = 86400000; // 1000 * 60 * 60 * 24;

const mapIncomingHistoryData = data => ({
  ...data,
  graphData: data.graphData.map(o => ({
    ...o,
    timestamp: o.timestamp - ONE_DAY_MILISECONDS,
  })),
});

async function getPortfolioList({ organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/portfolios`);

  response.data.sort((a, b) => a.name.localeCompare(b.name));
  return response.data;
}

// sharingToken => avoid axios middleware solution for fetching portfolio sharing data (as it was designed for having loaded only 1 ptf at a time) (usefull for VR mode)
async function getSharedPortfolio(portfolioId, organizationId, vaultId, sharingToken) {
  let response;
  if (sharingToken) {
    response = await axios.get(`public/organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}`, {
      params: {
        "sharing-token": sharingToken,
      },
    });
  } else {
    response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}`, {
      getPublicUrl: (oId, vId) => `organizations/${oId}/vaults/${vId}/portfolios/${portfolioId}`,
    });
  }

  return response.data;
}

async function getSharedPortfolioLongUrl(sharingCode) {
  const response = await axios.get(`/public/link-shortcuts?short-link=${sharingCode}`);
  return queryString.parseUrl(response.data);
}

async function createPortfolio(portfolio, { organizationId, vaultId }) {
  const response = await axios.post(`organizations/${organizationId}/vaults/${vaultId}/portfolios`, portfolio);
  return response.data;
}

async function updatePortfolio(portfolioId, portfolio, { organizationId, vaultId }) {
  const response = await axios.post(`organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}`, portfolio);
  // return new Promise(resolve => setTimeout(() => resolve(response.data), 10000000))
  return response.data;
}

async function deletePortfolio(portfolioId, { organizationId, vaultId }) {
  const response = await axios.delete(`organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}`);
  return response.data;
}

async function getPortfolioBalances(portfolioId, currency, filter, { organizationId, vaultId }) {
  if (getBalancesCancelToken) getBalancesCancelToken.cancel();
  getBalancesCancelToken = cancelToken.source();
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/computation/state`,
    {
      cancelToken: getBalancesCancelToken.token,
      getPublicUrl: (oId, vId) => `organizations/${oId}/vaults/${vId}/portfolios/${portfolioId}/computation/state`,
      params: {
        currency,
        from: filter.from || undefined,
        to: filter.to || undefined,
      },
      transformResponse: response => losslesslyParseJSON(response),
    }
  );
  return response.data;
}

async function getPortfolioHistory(portfolioId, currency, filter, { organizationId, vaultId }) {
  if (getHistoryCancelToken) getHistoryCancelToken.cancel();
  getHistoryCancelToken = cancelToken.source();

  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/computation/portfolio-graph`,
    {
      cancelToken: getHistoryCancelToken.token,
      getPublicUrl: (oId, vId) => `organizations/${oId}/vaults/${vId}/portfolios/${portfolioId}/computation/portfolio-graph`,
      params: {
        currency,
        from: filter.from || undefined,
        to: filter.to || undefined,
      },
    }
  );

  const {
    data: { shortened, ...history },
  } = response;

  if (shortened)
    alertActions.warning(i18n.t("alert.warning.plan_restriction.assignment_count_exceeded"), {
      linkTo: "/upgrade",
      linkMessage: i18n.t("alert.warning.plan_restriction.only_one_portfolio_link"),
    });
  return mapIncomingHistoryData(history);
}

async function getPortfolioStateAndHistory(portfolioId, currency, filter, { organizationId, vaultId }) {
  if (getBalancesCancelToken) getBalancesCancelToken.cancel();
  getBalancesCancelToken = cancelToken.source();
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/computation/dashboard`,
    {
      cancelToken: getBalancesCancelToken.token,
      getPublicUrl: (oId, vId) => `organizations/${oId}/vaults/${vId}/portfolios/${portfolioId}/computation/dashboard`,
      params: {
        currency,
        from: filter.from || undefined,
        to: filter.to || undefined,
      },
      transformResponse: response => losslesslyParseJSON(response),
    }
  );
  return response.data;
}

async function getPortfolioBaseQuote(portfolioId, base, quote, isSummary, { organizationId, vaultId }) {
  if (getBaseQuoteCancelToken) getBaseQuoteCancelToken.cancel();
  getBaseQuoteCancelToken = cancelToken.source();

  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/computation/pair-graph`,
    {
      cancelToken: getBaseQuoteCancelToken.token,
      getPublicUrl: (oId, vId) =>
        `organizations/${oId}/vaults/${vId}/portfolios/${portfolioId}/computation/${isSummary ? "base-graph" : "pair-graph"}`,
      params: {
        base,
        quote,
      },
    }
  );
  return mapIncomingHistoryData(response.data);
}

async function getPortfolioFinalBalancesDateOptions(portfolioId, { organizationId, vaultId }) {
  if (getFinalBalancesOptionsCancelToken) getFinalBalancesOptionsCancelToken.cancel();
  if (getFinalBalancesCancelToken) getFinalBalancesCancelToken.cancel();

  getFinalBalancesOptionsCancelToken = cancelToken.source();

  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/computation/state-transfer-dates`,
    {
      cancelToken: getFinalBalancesOptionsCancelToken.token,
    }
  );
  return response.data;
}

async function getPortfolioFinalBalances({ portfolioId, dateType, timestamp, collapse }, { organizationId, vaultId }) {
  if (getFinalBalancesCancelToken) getFinalBalancesCancelToken.cancel();
  if (getFinalBalancesOptionsCancelToken) getFinalBalancesOptionsCancelToken.cancel();

  getFinalBalancesCancelToken = cancelToken.source();

  const response = await axios.get(
    queryString.stringifyUrl(
      {
        url: `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/computation/transferable-state`,
        query: {
          "date-type": dateType,
          timestamp: moment.utc(timestamp).startOf("day").valueOf(),
          collapse,
        },
      },
      { skipNull: true }
    )
  );
  return response.data;
}

async function enablePortfolioSharing(portfolioId, { organizationId, vaultId }) {
  if (toggleSharingCancelToken) toggleSharingCancelToken.cancel();
  toggleSharingCancelToken = cancelToken.source();

  const response = await axios.post(`organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/sharing`, {
    cancelToken: toggleSharingCancelToken.token,
  });
  return response.data;
}

async function disablePortfolioSharing(portfolioId, { organizationId, vaultId }) {
  if (toggleSharingCancelToken) toggleSharingCancelToken.cancel();
  toggleSharingCancelToken = cancelToken.source();

  const response = await axios.delete(`organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/sharing`, {
    cancelToken: toggleSharingCancelToken.token,
  });
  return response.data;
}

export const portfolioService = {
  getPortfolioList,
  getSharedPortfolio,
  getSharedPortfolioLongUrl,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
  getPortfolioBalances,
  getPortfolioHistory,
  getPortfolioStateAndHistory,
  getPortfolioBaseQuote,
  getPortfolioFinalBalancesDateOptions,
  getPortfolioFinalBalances,
  enablePortfolioSharing,
  disablePortfolioSharing,
};
