import React from "react";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import cx from "classnames";
import styles from "../Styles/Tooltips.module.scss";

export const LabelWithTooltip = ({ label, tooltip, className, ...rest }) => (
  <div className={cx("d-flex align-items-center gap-8", className)} {...rest}>
    {label}
    <DynamicTooltip className={cx("ml-2", styles.label_with_tooltip)}>{tooltip}</DynamicTooltip>
  </div>
);
