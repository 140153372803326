import React from "react";

const EmptyIcon = props => (
  <svg viewBox="0 0 200 200" {...props}>
    <g>
      <path
        d="M100,3.5C46.8,3.5,3.5,46.8,3.5,100s43.3,96.5,96.5,96.5s96.5-43.3,96.5-96.5S153.2,3.5,100,3.5z M100,186.5
          c-47.7,0-86.5-38.8-86.5-86.5S52.3,13.5,100,13.5s86.5,38.8,86.5,86.5S147.7,186.5,100,186.5z"
      />
      <path
        d="M100,125.5c-2.9,0-5.6,0.7-8.1,2L44.2,74.2c-1.8-2.1-5-2.2-7.1-0.4c-2.1,1.8-2.2,5-0.4,7.1l48,53.6
          c-1.4,2.5-2.2,5.4-2.2,8.5c0,9.6,7.9,17.5,17.5,17.5s17.5-7.9,17.5-17.5S109.6,125.5,100,125.5z M100,150.5c-4.1,0-7.5-3.4-7.5-7.5
          c0-1.5,0.4-2.9,1.2-4c0.4-0.2,0.8-0.4,1.2-0.8c0.5-0.5,0.9-1,1.2-1.6c1.2-0.7,2.5-1.2,4-1.2c4.1,0,7.5,3.4,7.5,7.5
          S104.1,150.5,100,150.5z"
      />
      <path
        d="M116.5,50.5c2.8,0,5-2.2,5-5s-2.2-5-5-5h-23c-2.8,0-5,2.2-5,5v48c0,2.8,2.2,5,5,5h23c2.8,0,5-2.2,5-5s-2.2-5-5-5h-18v-14
          h18c2.8,0,5-2.2,5-5s-2.2-5-5-5h-18v-14H116.5z"
      />
    </g>
  </svg>
);

export default EmptyIcon;
