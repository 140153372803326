import { createSelector } from "reselect";
import { ACCOUNTANT } from "common/constants/userCategories";
import { WB_SUPPORT } from "common/constants/whaleBooksRoles";

export const getUser = state => state.user;

export const getActiveOrganizationId = state => state.user.organizationId;

export const getUserMemberships = state => state.user.memberships || [];

export const getUserOrganizations = state => state.user.organizations || [];

export const getIsAccountant = createSelector([getUser], user => user.userCategories?.find(o => o === ACCOUNTANT) !== undefined);

export const getActiveMembership = createSelector([getActiveOrganizationId, getUserMemberships], (organizationId, memberships) =>
  memberships.find(o => o.organizationId === organizationId)
);

export const getIsSpectatorMode = createSelector(
  [getUser],
  ({ isAuthenticated, userRole, spectatedUserId }) => isAuthenticated && userRole === WB_SUPPORT && !!spectatedUserId
);
