import BigCurrencyDecimal from "components/Formating/BigCurrencyDecimal";
import React from "react";
import { getFiatOptions } from "selectors";
import { connect } from "react-redux";
import { formatToMinDecimals } from "utils";

const FormattedNumber = ({ fiatOptions, number, currency, displayCurrency = false, prefix, suffix, roundToFixedCrypto }) => {
  const isFiat = fiatOptions.some(x => x.value === currency);

  return (
    <>
      {prefix}
      <BigCurrencyDecimal
        number={formatToMinDecimals(number)}
        roundToFixed={isFiat ? 2 : undefined}
        roundToFixedCrypto={roundToFixedCrypto}
        keepZeros={isFiat}
      />
      {currency && displayCurrency ? ` ${currency}` : ""}
      {suffix}
    </>
  );
};

function mapStateToProps(state) {
  return {
    fiatOptions: getFiatOptions(state),
  };
}

export default connect(mapStateToProps)(FormattedNumber);
