import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AccountingReportForm from "containers/Forms/AccountingReportForm";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import DateLabel from "components/Dashboard/DateLabel";
import { Link } from "react-router-dom";
import { getAccountSettings, getActiveOrganizationId, getOpenedPortfolio } from "selectors";
import { organizationActions } from "actions/organizationActions";
import { LoaderOverlay } from "components/Common";
import ModalEnvelope from "./ModalEnvelope";

const DownloadAccountingReportModal = ({
  from,
  to,
  onDownload,
  activeOrganizationId,
  accountSettings,
  openedPortfolio,
  modalActions: { hideModal },
  organizationActions,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    organizationActions.getOrganizationAccountSettings(activeOrganizationId);
  }, [organizationActions, activeOrganizationId]);

  return (
    <ModalEnvelope id="download-accounting-report-modal" heading={t("modal.download_accounting_report.heading")}>
      <LoaderOverlay isLoading={accountSettings?.isFetching}>
        <div className="text-center mb-1">
          {t("title.portfolio")}: {openedPortfolio?.name}
        </div>
        <div className="text-center mb-3">
          {t("modal.download_accounting_report.period")} <DateLabel start={from} end={to} />
        </div>
        <AccountingReportForm
          from={from}
          to={to}
          onClose={hideModal}
          accountSettings={accountSettings?.accountSettings}
          onSubmit={onDownload}
        />

        <div className="text-center mt-3">
          <Link to={`/organizations/${activeOrganizationId}/accounting-software`} rel="noopener noreferrer" target="_blank">
            {t("modal.download_accounting_report.accounting_software_settings")}
          </Link>
        </div>
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    activeOrganizationId: getActiveOrganizationId(state),
    accountSettings: getAccountSettings(state),
    openedPortfolio: getOpenedPortfolio(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAccountingReportModal);
