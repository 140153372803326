import React from "react";
import { useTranslation } from "react-i18next";
import { getYear } from "date-fns";
import {
  ACCOUNTING_REPORT_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX,
  ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES,
  ACCOUNTING_REPORT_REFERENCE_MAPPING,
  ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING,
  ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT,
  ACCOUNTING_SOFTWARE_REPORT_FORMAT,
  accountingSoftwareDataFormatTranslator,
  accountingSoftwareReferenceMappingTranslator,
  accountingSoftwareTypeTranslator,
  POHODA_FORMAT,
  WHALEBOOKS_FORMAT,
  ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION,
  ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION,
  accountingSoftwareMomentOfAcquisitionTranslator,
} from "common/constants/accountSettings";
import { accountingIncludeTranslator } from "common/constants/accountingIncludeOptions";
import { LoaderOverlay } from "components/Common";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import styles from "./Organization.module.scss";

const AccountingSoftwareInfo = ({ accountSettings, isFetching }) => {
  const { t } = useTranslation();

  const yearEnd = getYear(new Date()).toString().substr(2, 2);

  const getConfigValue = name => accountSettings?.config?.find(x => x.name === name)?.value;

  const softwareType = accountSettings?.name ? t(accountingSoftwareTypeTranslator(accountSettings?.name)) : "~";

  // Whalebooks
  const dataFormat = getConfigValue(ACCOUNTING_SOFTWARE_REPORT_FORMAT);
  const includeDeposits = getConfigValue(ACCOUNTING_REPORT_INCLUDE_DEPOSIT);
  const includeWithdrawals = getConfigValue(ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL);
  const referenceMapping = getConfigValue(ACCOUNTING_REPORT_REFERENCE_MAPPING);
  const momentOfAcquisition = getConfigValue(ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION);

  // Pohoda
  const dataFormatPohoda = getConfigValue(ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT);
  const numberOfPlacesOfSubAccounts = getConfigValue(ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES);
  const queuePrefix = getConfigValue(ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX);
  const includeDepositsPohoda = getConfigValue(ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT);
  const includeWithdrawalsPohoda = getConfigValue(ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL);
  const referenceMappingPohoda = getConfigValue(ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING);
  const momentOfAcquisitionPohoda = getConfigValue(ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION);

  return (
    <LoaderOverlay isLoading={isFetching}>
      <div className={styles.organization_info}>
        <div className={styles.organization_tax_rules_grid}>
          <div>{t("organization_detail.transactions.accounting_software")}</div>
          <div>{softwareType}</div>

          {accountSettings?.name === WHALEBOOKS_FORMAT && (
            <>
              <div>{t("organization_detail.transactions.default_data_format")}</div>
              <div>{dataFormat ? t(accountingSoftwareDataFormatTranslator(dataFormat)) : "~"}</div>

              <div>
                {t("form.account_settings.include_deposits")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.include_deposits_tooltip")}</DynamicTooltip>
              </div>
              <div>{includeDeposits ? t(accountingIncludeTranslator(includeDeposits)) : "~"}</div>

              <div>
                {t("form.account_settings.include_withdrawals")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.include_withdrawals_tooltip")}</DynamicTooltip>
              </div>
              <div>{includeWithdrawals ? t(accountingIncludeTranslator(includeWithdrawals)) : "~"}</div>

              <div>
                {t("form.account_settings.reference_mapping")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.reference_mapping_tooltip")}</DynamicTooltip>
              </div>
              <div>{referenceMapping ? t(accountingSoftwareReferenceMappingTranslator(referenceMapping)) : "~"}</div>

              <div>
                {t("form.account_settings.moment_of_acquisition")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.moment_of_acquisition_tooltip")}</DynamicTooltip>
              </div>
              <div>{momentOfAcquisition ? t(accountingSoftwareMomentOfAcquisitionTranslator(momentOfAcquisition)) : "~"}</div>
            </>
          )}

          {accountSettings?.name === POHODA_FORMAT && (
            <>
              <div>{t("organization_detail.transactions.default_data_format")}</div>
              <div>{dataFormatPohoda ? t(accountingSoftwareDataFormatTranslator(dataFormatPohoda)) : "~"}</div>

              <div>{t("organization_detail.transactions.number_of_places_of_subaccounts")}</div>
              <div>{numberOfPlacesOfSubAccounts ?? "~"}</div>

              <div>{t("organization_detail.transactions.queue_prefix")}</div>
              <div>{queuePrefix ? `${yearEnd} ${queuePrefix} 00001` : "~"}</div>

              <div>
                {t("form.account_settings.include_deposits")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.include_deposits_tooltip")}</DynamicTooltip>
              </div>
              <div>{includeDepositsPohoda ? t(accountingIncludeTranslator(includeDepositsPohoda)) : "~"}</div>

              <div>
                {t("form.account_settings.include_withdrawals")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.include_withdrawals_tooltip")}</DynamicTooltip>
              </div>
              <div>{includeWithdrawalsPohoda ? t(accountingIncludeTranslator(includeWithdrawalsPohoda)) : "~"}</div>

              <div>
                {t("form.account_settings.reference_mapping")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.reference_mapping_tooltip")}</DynamicTooltip>
              </div>
              <div>{referenceMappingPohoda ? t(accountingSoftwareReferenceMappingTranslator(referenceMappingPohoda)) : "~"}</div>

              <div>
                {t("form.account_settings.moment_of_acquisition")}{" "}
                <DynamicTooltip className="ml-2">{t("form.account_settings.moment_of_acquisition_tooltip")}</DynamicTooltip>
              </div>
              <div>
                {momentOfAcquisitionPohoda ? t(accountingSoftwareMomentOfAcquisitionTranslator(momentOfAcquisitionPohoda)) : "~"}
              </div>
            </>
          )}
        </div>
      </div>
    </LoaderOverlay>
  );
};

export default AccountingSoftwareInfo;
