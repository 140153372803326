import React from "react";
import { paymentPlans } from "common/constants/paymentPlans";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import Checkout from "../Checkout";

const CheckoutModal = modalProps => {
  const { t } = useTranslation();
  const name = paymentPlans[modalProps.type]?.name || "";
  return (
    <ModalEnvelope id="login-modal" heading={t("modal.checkout.heading", { plan: name })}>
      <Checkout {...modalProps} />
    </ModalEnvelope>
  );
};

export default CheckoutModal;
