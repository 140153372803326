import React from "react";
import { LEFT, RIGHT } from "common/constants/positions";

import styles from "../Styles/Graphs.module.scss";

const GAP = 12;
let overflowChangeTimeout;

class GraphTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipRef = React.createRef();
  }

  componentDidUpdate() {
    const offsetWidth = Math.round(this.tooltipRef.current.offsetWidth);
    const { left, position = LEFT, overflow } = this.props;

    if (position === RIGHT) {
      const parentOffsetWidth = Math.round(this.tooltipRef.current.offsetParent.offsetWidth);
      if (offsetWidth + left + GAP > parentOffsetWidth && overflow === false) this.handleOverflowChange(true);
      if (offsetWidth + left + GAP <= parentOffsetWidth && overflow === true) this.handleOverflowChange(false);
    } else {
      if (offsetWidth + GAP > left && overflow === false) this.handleOverflowChange(true);
      if (offsetWidth + GAP <= left && overflow === true) this.handleOverflowChange(false);
    }
  }

  handleOverflowChange = isOverflow => {
    clearTimeout(overflowChangeTimeout);
    overflowChangeTimeout = setTimeout(() => this.props.notifyOverflow(isOverflow), 5);
  };

  render() {
    const { top, rotateCount, overflow, secondTop, left, position = LEFT, children, className = "", visible = true } = this.props;

    const isRight = (position === RIGHT && !overflow) || (position === LEFT && overflow);
    const useNegativeRotate = (secondTop > top && isRight) || (secondTop < top && !isRight) || (secondTop === top && !overflow);

    const rotateNeeded = rotateCount !== undefined && Math.abs(secondTop - top) < 22;
    const rotateBy = (1 - Math.abs(secondTop - top) / 22) * (25 * rotateCount);

    const style = {
      top: `${top}px`,
      left: `${isRight ? left + GAP : left - GAP}px`,
      transform: `translate(${isRight ? 0 : -100}%, -50%) rotate(${
        rotateNeeded ? (useNegativeRotate ? -rotateBy : rotateBy) : 0
      }deg)`,
      WebkitTransform: `translate(${isRight ? 0 : -100}%, -50%) rotate(${
        rotateNeeded ? (useNegativeRotate ? -rotateBy : rotateBy) : 0
      }deg)`,
      transformOrigin: `${isRight ? -10 : 110}% 50%`,
      WebkitTransformOrigin: `${isRight ? -10 : 110}% 50%`,
      opacity: visible ? 1 : 0,
    };

    return (
      <div ref={this.tooltipRef} className={`${className} ${isRight ? styles.tooltip_right : styles.tooltip_left}`} style={style}>
        <i />
        {children}
      </div>
    );
  }
}

export default GraphTooltip;
