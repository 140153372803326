import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import { organizationActions } from "actions/organizationActions";
import EditRewardsForm from "containers/Forms/EditRewardsForm";
import ModalEnvelope from "./ModalEnvelope";

const EditRewardsModal = ({
  organizationData,
  ruleSet,
  setActiveRuleSet,
  modalActions: { hideModal },
  organizationActions: { updateOrganizationRuleSet },
}) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const formattedValues = {
      ...ruleSet,
      realizationTypes: {
        STAKING_REWARD: values?.STAKING_REWARD?.value,
        REWARD: values?.REWARD?.value,
        AIRDROP: values?.AIRDROP?.value,
        EARNING: values?.EARNING?.value,
        FORK: values?.FORK?.value,
      },
    };

    await updateOrganizationRuleSet(organizationData, formattedValues, ruleSet);

    setActiveRuleSet(formattedValues);
    hideModal();
  };

  return (
    <ModalEnvelope id="edit-rewards-modal" heading={t("modal.edit_rewards.heading")}>
      <EditRewardsForm ruleSet={ruleSet} onSubmit={onSubmit} hideModal={hideModal} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(EditRewardsModal);
