import React from "react";
import { Formik } from "formik";
import { TextFormField } from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import CheckFormField from "components/Common/Inputs/CheckInput";
import styles from "../Styles/Forms.module.scss";

const AccountantDetailForm = ({
  initialValues: {
    organization,
    personContactCity,
    personContactProvince,
    personContactCountry,
    certifiedAccountant,
    internalAccountant,
    // firstName,
    // lastName,
  },
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        organization: organization || "",
        personContactCity: personContactCity || "",
        personContactProvince: personContactProvince || "",
        personContactCountry: personContactCountry || "",
        certifiedAccountant: certifiedAccountant || false,
        internalAccountant: internalAccountant || false,
        // firstName: firstName || '',
        // lastName: lastName || '',
      }}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          {/* <div className={styles.fields_row}>
            <TextFormField
              name='firstName'
              label={t('form.edit_et_user.firstName')}
              disabled={isSubmitting}
              required
            />
            <TextFormField
              name='lastName'
              label={t('form.edit_et_user.lastName')}
              disabled={isSubmitting}
            />
          </div> */}

          <TextFormField name="organization" label={t("form.edit_et_user.organization")} />

          <TextFormField name="personContactCity" label={t("form.edit_et_user.city")} disabled={isSubmitting} />
          <TextFormField name="personContactCountry" label={t("form.edit_et_user.country")} disabled={isSubmitting} />
          <TextFormField name="personContactProvince" label={t("form.edit_et_user.state")} disabled={isSubmitting} />

          <CheckFormField name="internalAccountant" label={t("form.edit_et_user.is_internal_accountant")} />
          <CheckFormField
            name="certifiedAccountant"
            label={t("form.edit_et_user.is_certified_accountant")}
            wrapperClassName="mb-3"
          />

          <PrimaryButton label={t("form.edit_et_user.submit")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default AccountantDetailForm;
