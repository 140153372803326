import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../Common";
import styles from "../Styles/Pricing.module.scss";

const PricingPlans = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex">
        <Heading level={1} textAlign="center" margin="0 auto 1rem">
          {t("pricing.plans.heading")}
        </Heading>
      </div>
      <div id="payment_plans" className={styles.pricing_plan_list}>
        {children}
      </div>
    </>
  );
};

export default PricingPlans;
