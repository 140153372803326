import React from "react";
import { Formik } from "formik";
import { PasswordFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string, ref } from "yup";
import { passwordRegex } from "utils";

const ChangePasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{ previousPassword: "", newPassword: "", newPasswordConfirm: "" }}
      validationSchema={object({
        previousPassword: string().min(8).required(),
        newPassword: string().min(8).max(48).matches(passwordRegex, "validation.password_rules").required(),
        newPasswordConfirm: string()
          .oneOf([ref("newPassword"), null], "validation.password_match")
          .required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <PasswordFormField
            name="previousPassword"
            label={t("form.change_password.old_password")}
            disabled={isSubmitting}
            autoFocus
            autoComplete="current-password"
          />
          <PasswordFormField
            name="newPassword"
            label={t("form.change_password.new_password")}
            disabled={isSubmitting}
            autoComplete="new-password"
          />
          <PasswordFormField
            name="newPasswordConfirm"
            label={t("form.change_password.new_password_confirm")}
            disabled={isSubmitting}
            autoComplete="new-password-confirm"
          />
          <PrimaryButton label={t("form.change_password.change_password")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
