import React from "react";
import { CheckBox } from "grommet";
import { colors } from "common/colors";
import styled from "styled-components";

export default ({ showAssignmentInfo, editMode, isAssigned, isSelected, isHovered }) => {
  const isRemoved = isAssigned && !isSelected && editMode;
  const isAdded = !isAssigned && isSelected && editMode;

  const isChecked = (!editMode && isAssigned) || (editMode && isSelected);

  return (
    <AssignmentInfo
      displayed={showAssignmentInfo}
      isRemoved={isRemoved}
      isAdded={isAdded}
      isEdit={editMode}
      isHovered={isHovered}
      isChecked={isChecked}>
      <CheckBox checked={isChecked} disabled={!editMode} />
    </AssignmentInfo>
  );
};

const AssignmentInfo = styled.div`
  opacity: ${({ isEdit }) => (isEdit ? 1 : 0)};
  height: 26px;
  width: 26px;
  fill: transparent;
  stroke-width: 2px;
  stroke: #e3e9f1;
  padding: 1px;
  margin: 6px 6px 3px 10px;

  label {
    opacity: 1 !important;

    & > div > div {
      transition: border-color 0.3s;
      border-radius: 4px;
      border-width: 2px;
      border-color: ${({ isRemoved, isAdded, isHovered, isChecked, theme, isEdit }) =>
        !isEdit
          ? "transparent"
          : isRemoved
          ? theme.global.colors.red
          : isAdded
          ? theme.global.colors.green
          : isHovered || isChecked
          ? theme.global.colors.blue
          : theme.global.colors.greyBorder};

      path {
        stroke-width: ${props => (props.isEdit ? "3px" : "2px")};
      }
    }
  }
`;

AssignmentInfo.defaultProps = {
  // TODO: Move to Theme  https://styled-components.com/docs/advanced#theming
  theme: {
    red: colors.red,
    green: colors.gradientGreen,
    text: colors.text,
    neonBlue: colors.primary,
    grey: colors.greyBorder,
  },
};
