import React from "react";
import cx from "classnames";
import { getDataTestId } from "utils";
import styles from "./ListSelect.module.scss";

const ListSelect = ({ options, value, onChange, className, ...props }) => (
  <div className={cx(styles.wrapper, className)} {...props}>
    {options.map((option, index) => (
      <div
        className={cx(styles.option, { [styles.active]: value?.value === option.value })}
        key={index}
        onClick={() => onChange(option)}
        data-testid={getDataTestId("rule", option.label)}>
        {option.label}
      </div>
    ))}
  </div>
);

export default ListSelect;
