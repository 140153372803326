import React from "react";
import PricingWhys from "components/Pricing/PricingWhys";
import PricingPlans from "components/Pricing/PricingPlans";
import PricingPlan from "components/Pricing/PricingPlan";
import { BUSINESS, ONLINE, ONLINE_PLUS } from "common/constants/paymentPlans";
import styles from "pages/Pages.module.scss";

const Pricing = () => (
  <div className={styles.app_content_padding}>
    <PricingPlans>
      <PricingPlan type={ONLINE} linkTo="/signup" />
      <PricingPlan type={ONLINE_PLUS} linkTo="/signup" />
      <PricingPlan type={BUSINESS} linkTo="/signup" />
    </PricingPlans>

    <PricingWhys />
  </div>
);

export default Pricing;
