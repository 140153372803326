import React from "react";
import { WIDE } from "common/constants/modalWidths";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import CopyAccountBalancesForm from "../Forms/Portfolio/CopyAccountBalancesForm";

const CopyAccountBalancesModal = ({ targetPortfolio, currentSourcePortfolioId, ...props }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope
      id="select-portfolio-predecessor-modal"
      heading={t("modal.select_accounts_predecessor.heading")}
      width={WIDE}
      onClose={props.onClose}>
      <div className="w-100 text-center mt-n3 mb-4">
        {t("modal.select_avco_predecessor.target_portfolio")} {targetPortfolio}
      </div>

      <CopyAccountBalancesForm currentSourcePortfolioId={currentSourcePortfolioId} {...props} />
    </ModalEnvelope>
  );
};

export default CopyAccountBalancesModal;
