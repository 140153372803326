import { GET_PERMISSIONS, LOGOUT } from "actions/types";

const initialState = {
  isPermissionsFetching: false,
  permissions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PERMISSIONS}_PENDING`:
      return {
        ...state,
        isPermissionsFetching: true,
      };
    case `${GET_PERMISSIONS}_FULFILLED`:
      return {
        ...state,
        isPermissionsFetching: false,
        permissions: action.payload,
      };
    case `${GET_PERMISSIONS}_REJECTED`:
      return {
        ...state,
        isPermissionsFetching: false,
      };
    // =========================================
    case LOGOUT:
      return initialState;
    //= ========================================
    default:
      return state;
  }
};
