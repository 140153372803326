import React from "react";
import styles from "pages/Pages.module.scss";
import { useTranslation, Trans } from "react-i18next";
import { Heading } from "components/Common";
import IntegrationItem from "components/Integrations/IntegrationItem";
import { ReactComponent as FaCloudBolt } from "img/faCloudBolt.svg";
import { ReactComponent as FaCloudDownload } from "img/faCloudDownload.svg";
import { ReactComponent as FaFileForward } from "img/faFileForward.svg";
import history from "common/history";
import { openInNewTab } from "common/utils";

const Integrations = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.app_content_padding}>
      <div className="text-center pb-4">
        <Heading level={1} margin="0 0 1rem 0">
          {t("integrations.heading")}
        </Heading>

        <p>
          <Trans i18nKey="integrations.content">
            Do you run an app that you need to connect to WhaleBooks? <br /> Take advantage of our universal integrations for a
            connection that fits your needs.
          </Trans>
        </p>
      </div>

      <div className="mt-5 pb-4">
        <Heading level={2} margin="0 0 1rem 0">
          {t("integrations.whalebooks_format.heading")}
        </Heading>
        <p className="mb-5 pt-3">
          <Trans i18nKey="integrations.whalebooks_format.content">
            If you need basic transaction data exchange, use our universal formats. <br /> Either as a CSV file or using the
            universal API format.
          </Trans>
        </p>

        <div className="d-flex">
          <IntegrationItem
            className="mr-4"
            label="WhaleBooks CSV"
            icon={<FaFileForward />}
            onClick={() => history.push("/help/csv#whalebooks")}
          />
          <IntegrationItem
            label="WhaleBooks API"
            icon={<FaCloudDownload />}
            onClick={() => history.push("/help/api#whalebooks")}
          />
        </div>
      </div>

      <div className="mt-5 pb-4">
        <Heading level={2} margin="0 0 1rem 0">
          {t("integrations.whalebooks_api.heading")}
        </Heading>
        <p className="mb-5 pt-3">
          <Trans i18nKey="integrations.whalebooks_api.content">
            The API is ideal for third-party applications that aim to record their clients transactions
            <br />
            in WhaleBooks while also retrieving their portfolio results.
          </Trans>
        </p>

        <IntegrationItem
          label={
            <>
              <Trans i18nKey="integrations.whalebooks_api.link">
                WhaleBooks API
                <br />
                for third party access
              </Trans>
            </>
          }
          icon={<FaCloudBolt />}
          onClick={() =>
            openInNewTab(
              "https://generalbytes.atlassian.net/wiki/spaces/ESD/pages/2882764860/WhaleBooks+API+for+third+party+access"
            )
          }
        />
      </div>
    </div>
  );
};

export default Integrations;
