import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import { modalActions } from "actions/modalActions";
import { GhostButton, LoaderOverlay } from "components/Common";
import { getChartOfAccount, getGlobalFetchingState } from "selectors";
import { MEDIUM } from "common/constants/screenSizes";
import { useUrlHelper } from "hooks";
import { organizationActions } from "actions/organizationActions";
import { chartOfAccountNameTranslator } from "common/constants/chartOfAccountNames";
import { currencyTypeTranslator } from "common/constants/currencyTypes";
import { chartOfAccountTypeTranslator } from "common/constants/chartOfAccountTypes";

const AllAccounts = ({
  subAccountsDisplayed,
  isLoading,
  chartOfAccount,
  modalActions: { openEditAssetAccountAccount, openEditAssetAccountSubAccount },
  organizationActions: { getChartOfAccount },
}) => {
  const { t } = useTranslation();
  const { id: organizationId } = useUrlHelper();

  useEffect(() => {
    if (organizationId) getChartOfAccount(organizationId);
  }, [getChartOfAccount, organizationId]);

  return (
    <LoaderOverlay isLoading={isLoading} spinnerSize={MEDIUM}>
      <table>
        <thead>
          <tr>
            <th>{t("organization_chart_of_account.table_header.type")}</th>
            <th>{t("organization_chart_of_account.table_header.currency")}</th>
            <th>{t("organization_chart_of_account.table_header.account_number")}</th>
            <th>{t("organization_chart_of_account.table_header.account_name")}</th>
            <th>{t("organization_chart_of_account.table_header.description")}</th>
            <th className="float-right">{t("organization_chart_of_account.table_header.action")}</th>
          </tr>
        </thead>

        <tbody>
          {chartOfAccount?.map((account, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <b>{t("organization_chart_of_account.table.account")}</b>
                </td>
                <td>
                  <b>{account.currencyTypes?.map(x => t(currencyTypeTranslator(x)))?.join(", ")}</b>
                </td>
                <td>
                  <b>{account.accountNumber}</b>
                </td>
                <td>
                  <b>{t(chartOfAccountNameTranslator(account.accountCode))}</b>
                </td>
                <td>
                  <b>{t(chartOfAccountTypeTranslator(account.accountType))}</b>
                </td>
                <td>
                  <div style={{ textAlign: "right", float: "right" }}>
                    <GhostButton
                      actionButton
                      onClick={() =>
                        openEditAssetAccountAccount(
                          organizationId,
                          account,
                          chartOfAccount.map(x => x.accountNumber)
                        )
                      }
                      icon={<EditIcon />}
                      testId={`action-edit-account_row-${index + 1}`}
                    />
                  </div>
                </td>
              </tr>
              {subAccountsDisplayed &&
                account.subAccounts?.map((subAccount, index) => (
                  <tr key={index}>
                    <td>
                      <span className="pl-4 pr-3">{t("organization_chart_of_account.table.sub_account")}</span>
                    </td>
                    <td>{subAccount.currency}</td>
                    <td>{`${account.accountNumber}.${subAccount.subAccNumber}`}</td>
                    <td>
                      {t(currencyTypeTranslator(subAccount.currencyType))} {subAccount.currency}
                    </td>
                    <td>{t(chartOfAccountTypeTranslator(subAccount.accountType))}</td>
                    <td>
                      <div style={{ textAlign: "right", float: "right" }}>
                        <GhostButton
                          actionButton
                          onClick={() =>
                            openEditAssetAccountSubAccount(
                              organizationId,
                              subAccount,
                              account.subAccounts.map(x => x.subAccNumber)
                            )
                          }
                          icon={<EditIcon />}
                          testId={`action-edit-subaccount_row-${index + 1}`}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    chartOfAccount: getChartOfAccount(state),
    isLoading: state.organizations.chartOfAccountFetching && !getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllAccounts);
