import React, { useState } from "react";
import history from "common/history";
import { colors } from "common/colors";

const BackButton = ({ onClick = () => history.goBack(), isVisible = true, ...props }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      {isVisible ? (
        <span
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className="d-flex align-items-baseline"
          style={{ cursor: "pointer", width: "30px", marginBottom: "-0.6rem" }}
          onClick={onClick}
          {...props}>
          <span className={isHover ? "ml-n2" : "ml-0"} style={{ transition: "margin-left 0.1s ease-in" }}>
            <svg aria-label="Previous" viewBox="0 0 24 24" className="StyledIcon-ofa7kd-0 XEVwZ">
              <polyline
                fill="none"
                stroke={colors.text}
                strokeWidth="4"
                points="7 2 17 12 7 22"
                transform="matrix(-1 0 0 1 24 0)"
              />
            </svg>
          </span>
        </span>
      ) : (
        <span className="mr-5" />
      )}
    </>
  );
};

export default BackButton;
