import history from "common/history";
import { organizationService } from "services";
import i18n from "i18n";
import {
  ADD_ORGANIZATION_USER,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_USER,
  EDIT_ORGANIZATION_INFO,
  EDIT_ORGANIZATION_USER,
  GET_CHART_OF_ACCOUNT,
  GET_CHART_OF_ACCOUNT_ACCOUNTS,
  GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING,
  GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS,
  GET_ORGANIZATION_ACCOUNT_SETTINGS,
  GET_ORGANIZATION_INFO,
  GET_ORGANIZATION_RULE_SETS,
  GET_ORGANIZATION_USERS,
  UPDATE_ORGANIZATION_PLAN,
  UPDATE_TRANSACTIONS_COUNT,
} from "./types";
import { alertActions } from "./alertActions";
import { userActions } from "./userActions";
import { modalActions } from "./modalActions";

function getOrganizationInfo(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_ORGANIZATION_INFO,
      payload: organizationService.getOrganizationInfo(organizationId),
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function createOrganization(organization) {
  return dispatch =>
    dispatch({
      type: CREATE_ORGANIZATION,
      payload: async () => {
        const org = await organizationService.createOrganization(organization);
        await dispatch(userActions.getJoinedOrganizations());
        await dispatch(userActions.getUserOrganizations());
        alertActions.success(i18n.t("alert.success.organization_created"));
        history.replace(`/organizations/${org.id}/detail`);
        return org;
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function editOrganizationInfo(organizationId, organization) {
  return (dispatch, getState) =>
    dispatch({
      type: EDIT_ORGANIZATION_INFO,
      payload: async () => {
        const data = await organizationService.editOrganizationInfo(organizationId, organization);
        alertActions.success(i18n.t("alert.success.organization_updated"));
        dispatch(userActions.getJoinedOrganizations(getState().user.spectatedUserId));
        dispatch(getOrganizationRuleSets({ id: organizationId, ...organization }));
        return data;
      },
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getOrganizationUsers(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_ORGANIZATION_USERS,
      payload: {
        promise: async () => {
          const [members, invites] = await Promise.all([
            organizationService.getOrganizationUsers(organizationId),
            organizationService.getOrganizationInvites(organizationId),
          ]);
          return [...invites, ...members];
        },
      },
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function addOrganizationUser(organizationId, user) {
  return dispatch =>
    dispatch({
      type: ADD_ORGANIZATION_USER,
      payload: async () => {
        const data = await organizationService.addOrganizationUser(organizationId, user);
        alertActions.success(i18n.t("alert.success.invitation_sent"));
        return data;
      },
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function editOrganizationUser(organizationId, userId, user) {
  return dispatch =>
    dispatch({
      type: EDIT_ORGANIZATION_USER,
      payload: async () => {
        const data = await organizationService.editOrganizationUser(organizationId, userId, user);
        alertActions.success(i18n.t("alert.success.request_sent"));
        return data;
      },
      meta: { organizationId, userId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deleteOrganizationUser(organizationId, userId, isInvite) {
  return dispatch =>
    dispatch({
      type: DELETE_ORGANIZATION_USER,
      payload: organizationService.deleteOrganizationUser(organizationId, userId, isInvite),
      meta: { organizationId, userId },
    });
}

function deleteOrganization(organizationId) {
  return dispatch =>
    dispatch({
      type: DELETE_ORGANIZATION,
      payload: organizationService.deleteOrganization(organizationId),
      meta: { organizationId },
    });
}

function updatePaymentPlan(orderId, order, { start, end, plan }, organizationId) {
  return dispatch =>
    dispatch({
      type: UPDATE_ORGANIZATION_PLAN,
      async payload() {
        await order.capture();
        await organizationService.newSubscriptionVerification(orderId);
        history.push(`/organizations/${organizationId}/detail`);
        alertActions.success(i18n.t("alert.success.payment_successful"));
        dispatch(modalActions.hideModal());
        return {
          plan,
          planStart: start,
          planEnd: end,
        };
      },
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
      return false;
    });
}

function updateTransactionsCount() {
  return (dispatch, getState) =>
    dispatch({
      type: UPDATE_TRANSACTIONS_COUNT,
      payload: async () => organizationService.getOrganizationTransactionsCount(getState().user.organizationId),
    }).catch(err => {
      alertActions.error(err);
    });
}

function getChartOfAccount(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_CHART_OF_ACCOUNT,
      payload: async () => organizationService.getOrganizationChartOfAccount(organizationId),
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getChartOfAccountAccounts(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_CHART_OF_ACCOUNT_ACCOUNTS,
      payload: async () => organizationService.getOrganizationChartOfAccountAccounts(organizationId),
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getChartOfAccountSubAccounts(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS,
      payload: async () => organizationService.getOrganizationChartOfAccountSubAccounts(organizationId),
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function editOrganizationChartAccountAccount(organizationId, accountId, accountNumber) {
  return async dispatch => {
    try {
      const response = await organizationService.editOrganizationChartAccountAccount(organizationId, accountId, accountNumber);
      dispatch(getChartOfAccount(organizationId));
      dispatch(getChartOfAccountAccounts(organizationId));
      return response;
    } catch (e) {
      alertActions.error(e);
    }
  };
}

function editOrganizationChartAccountSubAccount(organizationId, subAccountId, accountNumber) {
  return async dispatch => {
    try {
      const response = await organizationService.editOrganizationChartAccountSubAccount(
        organizationId,
        subAccountId,
        accountNumber
      );
      dispatch(getChartOfAccount(organizationId));
      dispatch(getChartOfAccountSubAccounts(organizationId));
      return response;
    } catch (e) {
      alertActions.error(e);
    }
  };
}

function getChartOfAccountPreAccounting(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING,
      payload: async () => organizationService.getOrganizationChartAccountPreAccounting(organizationId),
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getOrganizationRuleSets(organization) {
  return dispatch =>
    dispatch({
      type: GET_ORGANIZATION_RULE_SETS,
      payload: async () => {
        const { id, taxResidency, taxSubject } = organization;
        return organizationService.getOrganizationRuleSets(id, taxResidency, taxSubject);
      },
      meta: { organizationId: organization.id },
    }).catch(err => {
      alertActions.error(err);
    });
}

function addOrganizationRuleSet(organization, ruleSet, refreshRuleSets = true) {
  return async dispatch => {
    const response = await organizationService.createOrganizationRuleSet(organization.id, ruleSet);
    if (refreshRuleSets) dispatch(getOrganizationRuleSets(organization));
    return response;
  };
}

function updateOrganizationRuleSet(organization, updatedRuleSet, originalRuleSet) {
  return async dispatch => {
    // If valid from was changed - only update, else create new rule set and delete the previous one
    let response;
    if (updatedRuleSet.validFrom === originalRuleSet.validFrom) {
      response = await organizationService.updateOrganizationRuleSet(organization.id, updatedRuleSet);
      dispatch(getOrganizationRuleSets(organization));
    } else {
      response = await dispatch(addOrganizationRuleSet(organization, updatedRuleSet, false));
      dispatch(deleteOrganizationRuleSet(organization, originalRuleSet));
    }
    return response;
  };
}

function deleteOrganizationRuleSet(organization, ruleSet) {
  return async dispatch => {
    const response = await organizationService.deleteOrganizationRuleSet(organization, ruleSet);
    dispatch(getOrganizationRuleSets(organization));
    return response;
  };
}

function getOrganizationAccountSettings(organizationId) {
  return dispatch =>
    dispatch({
      type: GET_ORGANIZATION_ACCOUNT_SETTINGS,
      payload: {
        promise: () => organizationService.getOrganizationAccountSettings(organizationId),
      },
      meta: { organizationId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function updateOrganizationAccountSettings(organizationId, values) {
  return async dispatch => {
    const response = await organizationService.updateOrganizationAccountSettings(organizationId, values);
    dispatch(getOrganizationAccountSettings(organizationId));
    return response;
  };
}

export const organizationActions = {
  getOrganizationInfo,
  createOrganization,
  editOrganizationInfo,
  getOrganizationUsers,
  addOrganizationUser,
  editOrganizationUser,
  deleteOrganizationUser,
  deleteOrganization,
  updatePaymentPlan,
  updateTransactionsCount,
  getChartOfAccount,
  getChartOfAccountAccounts,
  getChartOfAccountSubAccounts,
  editOrganizationChartAccountAccount,
  editOrganizationChartAccountSubAccount,
  getChartOfAccountPreAccounting,
  getOrganizationRuleSets,
  addOrganizationRuleSet,
  updateOrganizationRuleSet,
  deleteOrganizationRuleSet,
  getOrganizationAccountSettings,
  updateOrganizationAccountSettings,
};
