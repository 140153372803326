import { OWNER } from "common/constants/userPermission";
import history from "common/history";
import i18n from "i18n";
import { apiManagementActions } from "actions/apiManagementActions";
import { OPEN_MODAL } from "./types";
import { CONFIRMATION_DIALOG_MODAL } from "./modalTypes";
import {
  ADD_API_MANAGEMENT_PERMISSION,
  CHANGE_PLAN,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_ADDRESS,
  DELETE_ALL_PTF_ACCOUNTS,
  DELETE_ALL_PTF_PAIRS,
  DELETE_API_MANAGEMENT_PERMISSION,
  DELETE_BUCKETS,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_MEMBER,
  DELETE_ORGANIZATION_REFERENCE,
  DELETE_PORTFOLIO,
  DELETE_RULESET,
  DELETE_TRANSACTION_CONTAINERS,
  DELETE_TRANSACTIONS,
  DELETE_TRANSACTIONS_FROM_BUCKET,
  EDIT_API_CONNECTION,
  ENABLE_EXPERIMENTAL_COMPUTATION,
  JOIN_ORGANIZATION,
  LEAVE_ORGANIZATION,
  LEAVE_WHALEBOOKS,
  LOGOUT_TO_JOIN_ORGANIZATION,
  RELOAD_TRANSACTIONS,
  RESEND_INVITATION,
  SET_PASSWORD,
} from "./confirmationTypes";
import { userActions } from "./userActions";
import { portfolioActions } from "./portfolioActions";
import { transactionActions } from "./transactionActions";
import { organizationActions } from "./organizationActions";
import { modalActions } from "./modalActions";
import { alertActions } from "./alertActions";
import { accountActions } from "./accountActions";
import { bucketActions } from "./bucketActions";
import { SUBSCRIPTION } from "common/constants/feedbackTypes";
import { emptyAccountInitialBalances, emptyInitialState } from "common/constants/portfolio";

function openLeaveWhaleBooksConfirmation() {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: LEAVE_WHALEBOOKS,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(userActions.deleteProfile());
          },
          onCancel: () => dispatch(modalActions.hideModal()),
        },
      },
    });
}

function openDeleteOrganizationConfirmation({ id, name }) {
  return (dispatch, getState) =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ORGANIZATION,
        confirmationProps: {
          onConfirm: async () => {
            dispatch(modalActions.hideModal());
            try {
              const { organizationId, memberships } = getState().user;

              if (organizationId === id) {
                dispatch(
                  userActions.switchOrganization(
                    memberships.find(o => o.organizationId !== id && o.role === OWNER).organizationId
                  )
                );
              }
              await dispatch(organizationActions.deleteOrganization(id));
              history.push("/organizations");
              alertActions.success(i18n.t("alert.success.organization_deleted"));
            } catch (e) {
              alertActions.error(i18n.t("alert.error.organization_deleted"));
            }
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          name,
        },
      },
    });
}

function openLeaveOrganizationConfirmation({ id, organizationId, organizationDisplayName }) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: LEAVE_ORGANIZATION,
        confirmationProps: {
          onConfirm: async () => {
            dispatch(modalActions.hideModal());
            try {
              await dispatch(userActions.leaveOrganization(organizationId, id));
              alertActions.success(i18n.t("alert.success.organization_left"));
            } catch (e) {
              alertActions.error(i18n.t("alert.error.organization_left"));
            }
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          organization: organizationDisplayName,
        },
      },
    });
}

function openDeleteOrganizationMemberConfirmation(organizationId, { id, email, isInvite }) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ORGANIZATION_MEMBER,
        confirmationProps: {
          onConfirm: async () => {
            dispatch(modalActions.hideModal());
            try {
              await dispatch(organizationActions.deleteOrganizationUser(organizationId, id, isInvite));
              alertActions.success(i18n.t("alert.success.organization_member_deleted"));
            } catch (e) {
              alertActions.error(i18n.t("alert.error.organization_member_deleted"));
            }
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          name: email,
        },
      },
    });
}

function openSetPasswordConfirmation() {
  return (dispatch, getState) =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: SET_PASSWORD,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(userActions.setNewPassword());
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          email: getState().user.email,
        },
      },
    });
}

function openDeletePortfolioConfirmation({ id, name }) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_PORTFOLIO,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(portfolioActions.deletePortfolio(id));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          name,
        },
      },
    });
}

function openDeleteTransactionContainersConfirmation(containerIds, portfolioId) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_TRANSACTION_CONTAINERS,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(transactionActions.deleteContainers(containerIds, portfolioId));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          count: containerIds.length,
        },
      },
    });
}

function openDeleteTransactionsConfirmation(transactions) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_TRANSACTIONS,
        confirmationProps: {
          onConfirm: async () => {
            dispatch(modalActions.hideModal());
            await dispatch(transactionActions.deleteTransactions(transactions.map(o => o.id)));

            if (transactions?.length !== 1) {
              dispatch(transactionActions.changeFilterAndFetch({ page: 0 }));
            } else {
              dispatch(transactionActions.getTransactions());
            }
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          count: transactions.length,
        },
      },
    });
}

function openAcceptInvitationConfirmation(organization, token) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: JOIN_ORGANIZATION,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(userActions.joinOrganization(token));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          name: organization,
        },
      },
    });
}

function logoutToAcceptInvitationConfirmation(search, email) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: LOGOUT_TO_JOIN_ORGANIZATION,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(userActions.logout());
            history.replace(`/login${search}`);
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          email,
        },
      },
    });
}

function openResendInvitationConfirmation(organizationId, { id, email, role, isInvite, displayName, position }) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: RESEND_INVITATION,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(organizationActions.editOrganizationUser(organizationId, id, { displayName, role, isInvite, position }));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          email,
        },
      },
    });
}

function openEditApiConnectionConfirmation(onConfirm, onClose) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: EDIT_API_CONNECTION,
        confirmationProps: {
          onConfirm: async () => {
            dispatch(modalActions.hideModal());
            dispatch(transactionActions.setIsDataManagerLoading(true));
            await onConfirm();
            dispatch(transactionActions.setIsDataManagerLoading(false));
            dispatch(transactionActions.getTransactions());
          },
          onCancel: () => (onClose ? onClose() : dispatch(modalActions.hideModal())),
        },
      },
    });
}

function openReloadContainerTransactionsConfirmation(containerId, onClose) {
  return (dispatch, getState) =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: RELOAD_TRANSACTIONS,
        confirmationProps: {
          onConfirm: async () => {
            dispatch(modalActions.hideModal());
            dispatch(
              transactionActions.setActiveManualContainerSyncIds(
                getState().dataManager.activeManualContainerSyncIds.add(containerId)
              )
            );
            if (onClose) onClose();
            try {
              await dispatch(transactionActions.reloadAllContainerTransactions(containerId));
              dispatch(transactionActions.getTransactions());
            } catch (e) {
              alertActions.error(i18n.t("alert.error.reload_transactions_failed"));
            } finally {
              dispatch(
                transactionActions.setActiveManualContainerSyncIds(
                  getState().dataManager.activeManualContainerSyncIds.delete(containerId)
                )
              );
            }
          },
          onCancel: onClose ?? (() => dispatch(modalActions.hideModal())),
        },
      },
    });
}

function openDeleteAccountAddressConfirmation(accountData) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ACCOUNT_ADDRESS,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(accountActions.updateAccount(accountData));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
        },
      },
    });
}

function openDeleteAccountConfirmation({ accountId, accountName }, options) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ACCOUNT,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(accountActions.deleteAccount(accountId, options));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          accountName,
          accountId,
        },
      },
    });
}

function openDeleteBucketsConfirmation(bucketIds) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_BUCKETS,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(bucketActions.deleteBuckets(bucketIds));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          count: bucketIds.length,
        },
      },
    });
}

function openDeleteTransactionsFromBucketConfirmation(bucketId, txIds) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_TRANSACTIONS_FROM_BUCKET,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(bucketActions.deleteTransactionsFromBucket(bucketId, txIds));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          count: txIds.length,
        },
      },
    });
}

function openEnableExperimentalComputation(initialValues, reopenValues, isEdit, experimentalComputationValue) {
  const openPortfolioCallback = isEdit ? modalActions.openEditPortfolio : modalActions.openAddPortfolio;

  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: ENABLE_EXPERIMENTAL_COMPUTATION,
        confirmationProps: {
          onConfirm: () => {
            dispatch(
              openPortfolioCallback(initialValues, { ...reopenValues, experimentalComputation: experimentalComputationValue })
            );
          },
          onCancel: () => {
            dispatch(
              openPortfolioCallback(initialValues, { ...reopenValues, experimentalComputation: !experimentalComputationValue })
            );
          },
        },
      },
    });
}

function openDeleteRuleSetModal(organization, ruleSet, setActiveRuleSet) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_RULESET,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(organizationActions.deleteOrganizationRuleSet(organization, ruleSet));
            setActiveRuleSet(undefined);
          },
          onCancel: () => dispatch(modalActions.hideModal()),
        },
      },
    });
}

function openDeleteApiManagementPermissionConfirmation(permission) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_API_MANAGEMENT_PERMISSION,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(apiManagementActions.deletePermission(permission?.id));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
          permission,
        },
      },
    });
}

function openAddApiManagementPermissionConfirmation(permission, apiAccessId, requestedEmail) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: ADD_API_MANAGEMENT_PERMISSION,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(apiManagementActions.acceptPermission(apiAccessId, requestedEmail));
            history.push("/api-management");
          },
          onCancel: () => {
            dispatch(modalActions.hideModal());
            history.push("/api-management");
          },
          name: permission.name,
        },
      },
    });
}

function openDeleteAllPortfolioPairsConfirmation(initialValues, reopenValues, isEdit, clearValue = emptyInitialState) {
  const openPortfolioCallback = isEdit ? modalActions.openEditPortfolio : modalActions.openAddPortfolio;

  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ALL_PTF_PAIRS,
        confirmationProps: {
          onConfirm: () => {
            dispatch(openPortfolioCallback(initialValues, { ...reopenValues, initialState: clearValue }));
          },
          onCancel: () => {
            dispatch(openPortfolioCallback(initialValues, reopenValues));
          },
        },
      },
    });
}

function openDeleteAllPortfolioAccountsConfirmation(initialValues, reopenValues, isEdit) {
  const openPortfolioCallback = isEdit ? modalActions.openEditPortfolio : modalActions.openAddPortfolio;

  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ALL_PTF_ACCOUNTS,
        confirmationProps: {
          onConfirm: () => {
            dispatch(
              openPortfolioCallback(initialValues, { ...reopenValues, accountInitialBalances: emptyAccountInitialBalances }, 0, 1)
            );
          },
          onCancel: () => {
            dispatch(openPortfolioCallback(initialValues, reopenValues, 1));
          },
        },
      },
    });
}

function openDeleteOrganizationReferenceConfirmation(onConfirm) {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: DELETE_ORGANIZATION_REFERENCE,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            onConfirm();
          },
          onCancel: () => dispatch(modalActions.hideModal()),
        },
      },
    });
}

function openChangePlanConfirmation() {
  return dispatch =>
    dispatch({
      type: OPEN_MODAL,
      modalType: CONFIRMATION_DIALOG_MODAL,
      modalProps: {
        confirmationType: CHANGE_PLAN,
        confirmationProps: {
          onConfirm: () => {
            dispatch(modalActions.hideModal());
            dispatch(modalActions.openFeedbackForm({ type: SUBSCRIPTION }));
          },
          onCancel: () => dispatch(modalActions.hideModal()),
        },
      },
    });
}

export const confirmationActions = {
  openLeaveWhaleBooksConfirmation,
  openLeaveOrganizationConfirmation,
  openSetPasswordConfirmation,
  openDeleteOrganizationMemberConfirmation,
  openDeleteOrganizationConfirmation,
  openDeletePortfolioConfirmation,
  openDeleteTransactionContainersConfirmation,
  openDeleteTransactionsConfirmation,
  openAcceptInvitationConfirmation,
  openResendInvitationConfirmation,
  logoutToAcceptInvitationConfirmation,
  openEditApiConnectionConfirmation,
  openReloadContainerTransactionsConfirmation,
  openDeleteAccountAddressConfirmation,
  openDeleteAccountConfirmation,
  openDeleteBucketsConfirmation,
  openDeleteTransactionsFromBucketConfirmation,
  openEnableExperimentalComputation,
  openDeleteRuleSetModal,
  openDeleteApiManagementPermissionConfirmation,
  openAddApiManagementPermissionConfirmation,
  openDeleteAllPortfolioPairsConfirmation,
  openDeleteAllPortfolioAccountsConfirmation,
  openDeleteOrganizationReferenceConfirmation,
  openChangePlanConfirmation,
};
