import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { alertActions } from "actions/alertActions";
import { userActions } from "actions/userActions";
import { useLocation } from "react-router-dom";
// import { FormNextLink as FormNextLinkIcon, Google as PlatformGoogleIcon } from "grommet-icons";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
// import styles from "components/Common/Buttons/Buttons.module.scss";
import { GoogleLogin } from "@react-oauth/google";

const COOKIES_DISABLED_MESSAGE = "Cookies are not enabled in current environment.";

const GoogleAuth = ({ isSignUp, userActions, cookiesDisabled, googleButtonText }) => {
  const { search } = useLocation();
  const validateGoogleUser = async response => {
    const { credential } = response;
    const { token } = queryString.parse(search);
    await userActions.googleAuthenticationAndLoad(credential, token, isSignUp);
  };

  const { t, i18n } = useTranslation();
  const cookieErrorSignup = t("alert.error.allow_cookies_signup");
  const cookieErrorLogin = t("alert.error.allow_cookies_login");

  useEffect(() => {
    if (cookiesDisabled) {
      alertActions.error(isSignUp ? cookieErrorSignup : cookieErrorLogin);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="d-flex justify-content-center">
      <GoogleLogin
        onSuccess={response => validateGoogleUser(response)}
        onError={response => {
          if (response && response.details === COOKIES_DISABLED_MESSAGE) {
            userActions.cookiesDisabled();
            alertActions.error(isSignUp ? cookieErrorSignup : cookieErrorLogin);
          } else {
            alertActions.error(t("alert.error.google_authentification_failed"));
          }
        }}
        size="large"
        shape="pill"
        width="300px"
        locale={i18n.language}
      />
    </div>

    /* <button className={styles.custom_google_button} onClick={login}>
      <div className={styles.label}>
        <PlatformGoogleIcon />
        <div>{googleButtonText}</div>
      </div>
      <FormNextLinkIcon size="medium" />
    </button> */
  );
};

const mapStateToProps = state => ({
  cookiesDisabled: state.user.cookiesDisabled,
});

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuth);
