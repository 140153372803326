import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import { modalActions } from "actions/modalActions";
import { GhostButton, LoaderOverlay } from "components/Common";
import { getChartOfAccountPreAccounting, getGlobalFetchingState } from "selectors";
import { MEDIUM } from "common/constants/screenSizes";
import { useUrlHelper } from "hooks";
import { organizationActions } from "actions/organizationActions";
import { currencyTypeTranslator } from "common/constants/currencyTypes";

const PreAccounting = ({ isLoading, preAccounting, organizationActions: { getChartOfAccountPreAccounting } }) => {
  const { t } = useTranslation();
  const { id: organizationId } = useUrlHelper();

  useEffect(() => {
    if (organizationId) getChartOfAccountPreAccounting(organizationId);
  }, [getChartOfAccountPreAccounting, organizationId]);

  return (
    <LoaderOverlay isLoading={isLoading} spinnerSize={MEDIUM}>
      <table>
        <thead>
          <tr>
            <th>{t("organization_chart_of_account.table_header.type")}</th>
            <th>{t("organization_chart_of_account.table_header.pre_accounting_name")}</th>
            <th>{t("organization_chart_of_account.table_header.transaction")}</th>
            <th>{t("organization_chart_of_account.table_header.debit")}</th>
            <th>{t("organization_chart_of_account.table_header.credit")}</th>
            <th>{t("organization_chart_of_account.table_header.description")}</th>
            <th className="float-right">{t("organization_chart_of_account.table_header.action")}</th>
          </tr>
        </thead>

        <tbody>
          {preAccounting?.map((item, index) => (
            <tr key={index}>
              <td>{t("organization_detail.transactions.chart_of_account.pre_accounting")}</td>
              <td>{item.shortname}</td>
              <td>{item.accountingTransactionType}</td>
              <td>{item.debitAccount}</td>
              <td>{item.creditAccount}</td>
              <td>{item.currencyTypes?.map(x => t(currencyTypeTranslator(x)))?.join(", ")}</td>
              <td>
                <div style={{ textAlign: "right", float: "right" }}>
                  <GhostButton disabled icon={<EditIcon />} actionButton />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    preAccounting: getChartOfAccountPreAccounting(state),
    isLoading: state.organizations.chartOfAccountPreAccountingFetching && !getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreAccounting);
