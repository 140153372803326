import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { SelectFormField, TextFormField } from "components/Common/Inputs";
import { Form, GhostButton, PrimaryButton } from "components/Common";
import { components } from "react-select";
import styles from "containers/Styles/Forms.module.scss";
import history from "common/history";

const CsvContainerForm = ({ onSubmit, referenceOptions, activeOrganizationId, onClose }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ name: "", source: "", references: [] }}
      validationSchema={object({
        name: string().required(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ ...values, references: values.references?.map(x => x.reference) });
        setSubmitting(false);
      }}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <TextFormField name="name" label={t("form.create_file.title")} required disabled={isSubmitting} autoFocus />
          <TextFormField name="source" label={t("form.create_api.source")} disabled={isSubmitting} />
          <SelectFormField
            name="references"
            label={t("form.reference_detail.name")}
            options={referenceOptions}
            disabled={isSubmitting}
            isClearable
            isMulti
            components={{
              Menu: props => (
                <components.Menu {...props}>
                  {props.children}
                  <div className="my-1">
                    <GhostButton
                      labelClassName={styles.request_more_currencies_label}
                      label={`+ ${t("form.container_detail.add_new_reference")}`}
                      onClick={() => {
                        onClose();
                        history.push(`/organizations/${activeOrganizationId}/lists?add=true`);
                      }}
                    />
                  </div>
                </components.Menu>
              ),
            }}
          />

          <PrimaryButton
            label={t("form.create_file.create_blank")}
            isLoading={isSubmitting}
            onClick={handleSubmit}
            testId="create-container"
          />
        </Form>
      )}
    </Formik>
  );
};

export default CsvContainerForm;
