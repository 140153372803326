import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useScreenSize } from "hooks";
import Header from "containers/Header";
import Routes from "routes";
import SpecialOffers from "containers/SpecialOffers";
import cx from "classnames";
import { getLocalStorageValue, setLocalStorageValue, SHOW_RELEASE_NOTES } from "common/localStorageManager";
import { getActiveOrganizationData, getIsSpectatorMode } from "selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { SET_TAX_RESIDENCY } from "actions/modalTypes";
import { organizationActions } from "actions/organizationActions";
import InfoBar from "./InfoBar";
import Footer from "./Footer";
import styles from "../Styles/AppStructure.module.scss";
import ErrorBoundary from "containers/ErrorBoundary";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { useDefaultsStore } from "stores/defaultsStore";

const barHeight = "42px";
let taxResidencyModalTimeout;

const AppStructure = ({
  isSpectatorMode,
  isNewUser,
  organizationData,
  isPresentationMode,
  modalActions: { openSetTaxResidency, hideModal },
  organizationActions: { getOrganizationRuleSets },
}) => {
  const { pathname } = useLocation();
  const { isSmall } = useScreenSize();
  const location = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);

  // Used only on small screen
  const [profileExpanded, setProfileExpanded] = useState(false);
  const [isInfoBarDisplayed, setIsInfoBarDisplayed] = useState(getLocalStorageValue(SHOW_RELEASE_NOTES) === null);

  const topSpace = useMemo(() => {
    const isSpectator = !isPresentationMode && isSpectatorMode;
    if (isInfoBarDisplayed && isSpectator) return `calc(71px + ${barHeight} + ${barHeight})`;
    if (isInfoBarDisplayed || isSpectator) return `calc(71px + ${barHeight})`;
  }, [isInfoBarDisplayed, isSpectatorMode, isPresentationMode]);

  useEffect(() => {
    setMenuExpanded(false);
    setProfileExpanded(false);
  }, [pathname, isSmall]);

  useEffect(() => {
    if (organizationData) {
      const { id, taxResidency, taxSubject } = organizationData;
      if (id && taxResidency && taxSubject) {
        getOrganizationRuleSets(organizationData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData?.id]);

  useEffect(() => {
    clearTimeout(taxResidencyModalTimeout);

    if (!isNewUser) {
      taxResidencyModalTimeout = setTimeout(() => {
        if (
          organizationData &&
          !location.pathname.endsWith("/tax-rules") &&
          (!organizationData.taxResidency || !organizationData.taxSubject)
        ) {
          openSetTaxResidency(organizationData);
        }

        if (organizationData && organizationData.taxResidency && organizationData.taxSubject) {
          hideModal(SET_TAX_RESIDENCY);
        }
      }, 100);
    }
  }, [location.pathname, organizationData, openSetTaxResidency, hideModal, isNewUser]);

  const [portfolioIdParam] = useQueryParam("portfolioId", NumberParam);
  const { setActivePortfolioId } = useDefaultsStore();
  useEffect(() => {
    if (portfolioIdParam) {
      setActivePortfolioId(portfolioIdParam);
    }
  }, [portfolioIdParam]);

  return (
    <div className={styles.main_content}>
      <Header
        isMenuExpanded={menuExpanded}
        setIsMenuExpanded={setMenuExpanded}
        isProfileExpanded={profileExpanded}
        setIsProfileExpanded={setProfileExpanded}
        topSpace={topSpace}>
        {isInfoBarDisplayed && (
          <InfoBar
            onClose={() => {
              setLocalStorageValue(SHOW_RELEASE_NOTES, false);
              setIsInfoBarDisplayed(false);
            }}
          />
        )}
      </Header>
      <div
        className={cx(styles.body_wrapper, {
          [styles.body_overlay]: menuExpanded || (isSmall && profileExpanded),
        })}
        style={{ paddingTop: topSpace }}
        onClick={() => {
          if (menuExpanded) setMenuExpanded(false);
        }}
        id="body_wrapper">
        <ErrorBoundary>
          <SpecialOffers />
          <Routes />
        </ErrorBoundary>
        <Footer />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isSpectatorMode: getIsSpectatorMode(state),
    isNewUser: state.user.newUser,
    organizationData: getActiveOrganizationData(state),
    isPresentationMode: state.user.isPresentationMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppStructure);
