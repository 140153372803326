import React from "react";
import { NavLink } from "react-router-dom";
import LandingBanner from "img/landing_banner.svg";
import { useTranslation } from "react-i18next";
import { PrimaryButtonSize } from "components/Common/Buttons/PrimaryButton";
import { useScreenSize } from "hooks";
import { Heading, PrimaryButton } from "../Common";
import styles from "../Styles/Landing.module.scss";

export default () => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <div className={styles.landing_banner}>
      {isSmall && (
        <div className={styles.about_image}>
          <img src={LandingBanner} alt="" />
        </div>
      )}
      <div className={styles.about_info}>
        <Heading level={1} margin="0 0 2rem 0">
          {t("landing.banner.heading")}
        </Heading>
        <Heading level={4} style={isSmall ? undefined : { maxWidth: "80%" }}>
          {t("landing.banner.subheader")}
        </Heading>
        <NavLink exact to="/signup">
          <PrimaryButton size={PrimaryButtonSize.ExtraLarge} label={t("landing.common.get_started_now")} collapsed />
        </NavLink>
      </div>
      {!isSmall && (
        <div className={styles.about_image}>
          <img src={LandingBanner} alt="" />
        </div>
      )}
    </div>
  );
};
