import React from "react";
import MyPortfoliosHelp from "components/Metrics/MyPortfoliosHelp";
import DataManagerHelp from "components/Metrics/DataManagerHelp";
import ApiConnectionHelp from "components/Metrics/ApiConnectionHelp";
import CsvDownload from "components/Metrics/CsvDownload";
import ReportsHelp from "components/Metrics/ReportsHelp";
import { Tabs, Tab, Heading } from "components/Common";
import Customization from "components/Metrics/Customization";
import Faq from "components/Metrics/Faq";
import { useTranslation } from "react-i18next";
import history from "common/history";

import styles from "pages/Pages.module.scss";

const pageIndexes = ["portfolio", "datamanager", "csv", "api", "reports", "customization", "faq"];

const Help = ({ match }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.app_content_padding}>
      <Heading level={2} margin="0 0 1.5rem 0">
        {t("metrics.heading")}
      </Heading>
      <Tabs
        defaultTabIndex={Math.max(
          pageIndexes.findIndex(o => o === match.params.tab),
          0
        )}
        onTabClick={index => history.push(`/help/${pageIndexes[index] || ""}`)}>
        <Tab title={t("metrics.tabs.portfolios_detail")}>
          <MyPortfoliosHelp />
        </Tab>
        <Tab title={t("metrics.tabs.datamanager_detail")}>
          <DataManagerHelp />
        </Tab>
        <Tab title={t("metrics.tabs.csv_download")}>
          <CsvDownload />
        </Tab>
        <Tab title={t("metrics.tabs.api_connection")}>
          <ApiConnectionHelp />
        </Tab>
        <Tab title={t("metrics.tabs.reports_help")}>
          <ReportsHelp />
        </Tab>
        <Tab title={t("metrics.tabs.customization_help")}>
          <Customization />
        </Tab>
        <Tab title={t("metrics.tabs.faq")}>
          <Faq />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Help;
