import React from "react";
import { connect } from "react-redux";
import {
  ACCOUNTANT_DETAIL,
  ADD_ACCOUNT_ADDRESS_MODAL,
  ADD_TRANSACTION,
  ADD_TRANSACTIONS_TO_BUCKET,
  APPLICATION_PREFERENCES,
  ASSIGN_CONTAINERS,
  BASE_QUOTE_MODAL,
  BUCKET_DETAIL_MODAL,
  BULK_TX_SET_LABEL,
  BULK_TX_SET_PRICE,
  CHANGE_PASSWORD_MODAL,
  CHECKOUT,
  CONFIRMATION_DIALOG_MODAL,
  CONNECTION_CREDENTIALS,
  CONTAINER_DETAIL_MODAL,
  COPY_ACCOUNT_BALANCES_MODAL,
  COUNTER_TRANSACTIONS_MODAL,
  CREATE_ACCOUNT_MODAL,
  CREATE_CONTAINER,
  DELETE_TRANSACTION_FROM_BUCKET,
  DOWNLOAD_ACCOUNTS_STATEMENT,
  DOWNLOAD_ADMIN_REPORT,
  EDIT_ASSET_ACCOUNT_MODAL,
  EDIT_CHART_ACCOUNT_ACCOUNT,
  EDIT_CHART_ACCOUNT_SUB_ACCOUNT,
  EDIT_CONTAINER_DETAIL_MODAL,
  EDIT_REWARDS_MODAL,
  EDIT_TAX_RULES_MODAL,
  EDIT_TRANSACTION,
  EMAIL_SUBSCRIPTION,
  FEEDBACK_FORM_MODAL,
  LOGIN_MODAL,
  EDIT_ACCOUNT_SETTINGS_MODAL,
  OPEN_ORGANIZATION_DETAIL,
  OPEN_PORTFOLIO_DETAIL,
  OPEN_RULE_SET_DETAIL,
  ORGANIZATION_TRIAL_PERIOD,
  ORGANIZATION_USER,
  REBRANDING_WELCOME_MODAL,
  SEARCH_FOR_ACCOUNTANT,
  SELECT_PORTFOLIO_AVCO_PREDECESSOR,
  SELECT_PORTFOLIO_FIFO_PREDECESSOR,
  SELECT_USER_CATEGORY,
  DOWNLOAD_ACCOUNTING_REPORT,
  SET_ADDRESS_FOR_TRANSACTIONS,
  SET_TAX_RESIDENCY,
  SHARE_PORTFOLIO,
  WALKTHROUGH,
  REFERENCE_DETAIL_MODAL,
  WALKTHROUGH_START_MODAL,
} from "actions/modalTypes";
import OrganizationDetailModal from "containers/Modals/OrganizationDetailModal";
import EditChartAccountAccountModal from "containers/Modals/EditChartAccountAccountModal";
import LoginModal from "containers/Modals/LoginModal";
import ChangePasswordModal from "containers/Modals/ChangePasswordModal";
import ConfirmationDialogContainer from "containers/Modals/ConfirmationDialogContainer";
import AddTransactionModal from "containers/Modals/AddTransactionModal";
import EditTransactionModal from "containers/Modals/EditTransactionModal";
import PortfolioDetailModal from "containers/Modals/PortfolioDetailModal";
import ContainerDetailModal from "containers/Modals/ContainerDetailModal";
import CreateDataContainerModal from "containers/Modals/CreateDataContainerModal";
import FeedbackFormModal from "containers/Modals/FeedbackFormModal";
import PortfolioFifoPredecessorModal from "containers/Modals/PortfolioFifoPredecessorModal";
import PortfolioAvcoPredecessorModal from "containers/Modals/PortfolioAvcoPredecessorModal";
import WalkthroughModal from "containers/Modals/WalkthroughModal";
import BaseQuoteModal from "containers/Modals/BaseQuoteModal";
import CheckoutModal from "containers/Modals/CheckoutModal";
import MailSubscription from "containers/Modals/MailSubscription";
import AssignContainersModal from "containers/Modals/AssignContainersModal";
import SharePortfolioModal from "containers/Modals/SharePortfolioModal";
import ApiCredentialsModal from "containers/Modals/ApiCredentialsModal";
import OrganizationUser from "containers/Modals/OrganizationUserModal";
import ApplicationPreferencesModal from "containers/Modals/ApplicationPreferencesModal";
import SelectUserCategory from "containers/Modals/SelectUserCategoryModal";
import SearchForAccountantModal from "containers/Modals/SearchForAccountantModal";
import OrganizationTrialPeriodModal from "containers/Modals/OrganizationTrialPeriodModal";
import BulkTxSetPriceModal from "containers/Modals/BulkTransaction/SetPriceModal";
import BulkTxSetLabelModal from "containers/Modals/BulkTransaction/SetLabelModal";
import AccountantDetailModal from "containers/Modals/AccountantDetailModal";
import DownloadAdminReportModal from "containers/Modals/DownloadAdminReportModal";
import EditContainerDetailModal from "containers/Modals/EditContainerDetailModal";
import CreateAccountModal from "containers/Modals/CreateAccountModal";
import EditAssetAccountModal from "containers/Modals/EditAssetAccountModal";
import AddAccountAddressModal from "containers/Modals/AddAccountAddressModal";
import CopyAccountBalancesModal from "containers/Modals/CopyAccountBalancesModal";
import AddTransactionsToBucketModal from "containers/Modals/AddTransactionsToBucketModal";
import BucketDetailModal from "containers/Modals/BucketDetailModal";
import DeleteTransactionFromBucketModal from "containers/Modals/DeleteTransactionsFromBucketModal";
import CounterTransactionsModal from "containers/Modals/CounterTransactionsModal";
import SetAddressForTransactionsModal from "containers/Modals/SetAddressForTransactionsModal";
import DownloadAccountsStatementModal from "containers/Modals/DownloadAccountsStatementModal";
import EditChartAccountSubAccountModal from "containers/Modals/EditChartAccountSubAccountModal";
import DownloadAccountingReportModal from "containers/Modals/DownloadAccountingReportModal";
import SetTaxResidencyModal from "containers/Modals/SetTaxResidencyModal";
import EditTaxRulesModal from "containers/Modals/EditTaxRulesModal";
import RuleSetDetailModal from "containers/Modals/RuleSetDetailModal";
import RebrandingWelcomeModal from "containers/Modals/RebrandingWelcomeModal";
import EditRewardsModal from "containers/Modals/EditRewardsModal";
import AccountSettingsModal from "containers/Modals/AccountSettingsModal";
import ReferenceDetailModal from "containers/Modals/ReferenceDetailModal";
import WalkthroughStartModal from "containers/Modals/WalkthroughStartModal";

const MODAL_COMPONENTS = {
  [LOGIN_MODAL]: LoginModal,
  [CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
  [OPEN_PORTFOLIO_DETAIL]: PortfolioDetailModal,
  [ADD_TRANSACTION]: AddTransactionModal,
  [EDIT_TRANSACTION]: EditTransactionModal,
  [CREATE_CONTAINER]: CreateDataContainerModal,
  [CONTAINER_DETAIL_MODAL]: ContainerDetailModal,
  [EDIT_CONTAINER_DETAIL_MODAL]: EditContainerDetailModal,
  [FEEDBACK_FORM_MODAL]: FeedbackFormModal,
  [SELECT_PORTFOLIO_AVCO_PREDECESSOR]: PortfolioAvcoPredecessorModal,
  [SELECT_PORTFOLIO_FIFO_PREDECESSOR]: PortfolioFifoPredecessorModal,
  [COPY_ACCOUNT_BALANCES_MODAL]: CopyAccountBalancesModal,
  [WALKTHROUGH]: WalkthroughModal,
  [BASE_QUOTE_MODAL]: BaseQuoteModal,
  [CHECKOUT]: CheckoutModal,
  [EMAIL_SUBSCRIPTION]: MailSubscription,
  [ASSIGN_CONTAINERS]: AssignContainersModal,
  [SHARE_PORTFOLIO]: SharePortfolioModal,
  [CONNECTION_CREDENTIALS]: ApiCredentialsModal,
  [ORGANIZATION_USER]: OrganizationUser,
  [APPLICATION_PREFERENCES]: ApplicationPreferencesModal,
  [SELECT_USER_CATEGORY]: SelectUserCategory,
  [ORGANIZATION_TRIAL_PERIOD]: OrganizationTrialPeriodModal,
  [BULK_TX_SET_PRICE]: BulkTxSetPriceModal,
  [BULK_TX_SET_LABEL]: BulkTxSetLabelModal,
  [ACCOUNTANT_DETAIL]: AccountantDetailModal,
  [SEARCH_FOR_ACCOUNTANT]: SearchForAccountantModal,
  [DOWNLOAD_ADMIN_REPORT]: DownloadAdminReportModal,
  [CREATE_ACCOUNT_MODAL]: CreateAccountModal,
  [EDIT_ASSET_ACCOUNT_MODAL]: EditAssetAccountModal,
  [ADD_ACCOUNT_ADDRESS_MODAL]: AddAccountAddressModal,
  [ADD_TRANSACTIONS_TO_BUCKET]: AddTransactionsToBucketModal,
  [DELETE_TRANSACTION_FROM_BUCKET]: DeleteTransactionFromBucketModal,
  [BUCKET_DETAIL_MODAL]: BucketDetailModal,
  [COUNTER_TRANSACTIONS_MODAL]: CounterTransactionsModal,
  [SET_ADDRESS_FOR_TRANSACTIONS]: SetAddressForTransactionsModal,
  [DOWNLOAD_ACCOUNTS_STATEMENT]: DownloadAccountsStatementModal,
  [OPEN_ORGANIZATION_DETAIL]: OrganizationDetailModal,
  [EDIT_CHART_ACCOUNT_ACCOUNT]: EditChartAccountAccountModal,
  [EDIT_CHART_ACCOUNT_SUB_ACCOUNT]: EditChartAccountSubAccountModal,
  [DOWNLOAD_ACCOUNTING_REPORT]: DownloadAccountingReportModal,
  [SET_TAX_RESIDENCY]: SetTaxResidencyModal,
  [EDIT_TAX_RULES_MODAL]: EditTaxRulesModal,
  [OPEN_RULE_SET_DETAIL]: RuleSetDetailModal,
  [REBRANDING_WELCOME_MODAL]: RebrandingWelcomeModal,
  [EDIT_REWARDS_MODAL]: EditRewardsModal,
  [EDIT_ACCOUNT_SETTINGS_MODAL]: AccountSettingsModal,
  [REFERENCE_DETAIL_MODAL]: ReferenceDetailModal,
  [WALKTHROUGH_START_MODAL]: WalkthroughStartModal,

  [CONFIRMATION_DIALOG_MODAL]: ConfirmationDialogContainer,
};

const ModalContainer = ({ modalType, modalProps }) => {
  if (modalType) {
    const Modal = MODAL_COMPONENTS[modalType];
    return Modal ? <Modal {...modalProps} /> : null;
  }
  return null;
};

export default connect(state => state.modal)(ModalContainer);
