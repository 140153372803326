import React, { useMemo } from "react";
import { Edit as EditIcon } from "grommet-icons/icons";
import { useTranslation } from "react-i18next";
import { dateTimeFormat, formatDate } from "common/formatters";
import styles from "components/Styles/DataManager.module.scss";
import { GhostButton, Tooltip } from "../../Common";
import { LabelWithTooltip } from "components/Tooltips/LabelWithTooltip";

const Header = ({ container, portfolios, assignments, openEditContainerDetail, isSyncActive }) => {
  const { t } = useTranslation();
  const { name, exchangeName, createdAt, source, references } = container;

  const assignedToPortfolios = useMemo(
    () => portfolios.filter(o => assignments[o.id]).map(o => o.name),
    [portfolios, assignments]
  );

  return (
    <div className={styles.container_detail}>
      <span>
        <b>{t("data_manager.container_detail.container")}</b>
      </span>
      <span style={{ marginLeft: "-15px" }}>
        <Tooltip tooltipContent={t("data_manager.container_detail.edit")}>
          <GhostButton icon={<EditIcon />} onClick={openEditContainerDetail} disabled={isSyncActive} />
        </Tooltip>
      </span>
      <span>{t("data_manager.container_detail.title")}</span>
      <span>{name}</span>
      <span>{t("data_manager.container_detail.exchange")}</span>
      <span>{exchangeName}</span>

      <span>{t("data_manager.container_detail.source")}</span>
      <span>{source || "~"}</span>

      <span>
        <LabelWithTooltip
          label={t("form.reference_detail.name")}
          tooltip={t("data_manager.container_detail.reference.tooltip")}
        />
      </span>
      <span>{references?.length > 0 ? references.map(x => x.name).join(", ") : "~"}</span>

      <span>{t("data_manager.container_detail.uploaded")}</span>
      <span>{formatDate(createdAt, dateTimeFormat)}</span>
      <span className="mb-4">{t("data_manager.container_detail.assigned_to")}</span>
      <span>{assignedToPortfolios && assignedToPortfolios.length > 0 ? assignedToPortfolios.join(", ") : "~"}</span>
    </div>
  );
};

export default Header;
