import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../Common";

import styles from "../Styles/Landing.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.qualities}>
      <Heading level={3} textAlign="center" margin="0 auto 2rem">
        {t("landing.qualities.heading")}
      </Heading>
      <div className={styles.qualities_list}>
        <div>
          <Heading level={4} textAlign="center" margin="0 auto 1rem">
            {t("landing.qualities.ease_of_use.heading")}
          </Heading>
          <div>{t("landing.qualities.ease_of_use.content")}</div>
        </div>
        <div>
          <Heading level={4} textAlign="center" margin="0 auto 1rem">
            {t("landing.qualities.privacy.heading")}
          </Heading>
          <div>{t("landing.qualities.privacy.content")}</div>
        </div>
        <div>
          <Heading level={4} textAlign="center" margin="0 auto 1rem">
            {t("landing.qualities.clean_design.heading")}
          </Heading>
          <div>{t("landing.qualities.clean_design.content")}</div>
        </div>
      </div>
    </div>
  );
};
