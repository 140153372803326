import React from "react";
import { useTranslation } from "react-i18next";
import DynamicTooltip from "./DynamicTooltip";

export default props => {
  const { t } = useTranslation();
  return (
    <DynamicTooltip {...props} className="py-3" buttonClassName="mr-n3" position="left">
      <div>
        <h5 style={{ marginBottom: "4px" }}>{t("tooltip.dashboard.transactions.accounting_currency.heading")}</h5>
        {t("tooltip.dashboard.transactions.accounting_currency.label")}
      </div>
      <div className="mt-3">
        <h5 style={{ marginBottom: "4px" }}>{t("tooltip.dashboard.transactions.trade_currency.heading")}</h5>
        {t("tooltip.dashboard.transactions.trade_currency.label")}
      </div>
      <div className="mt-3">
        <h5 style={{ marginBottom: "4px" }}>{t("tooltip.dashboard.transactions.open_positions.heading")}</h5>
        {t("tooltip.dashboard.transactions.open_positions.label")}
      </div>
      <div className="mt-3">
        <h5 style={{ marginBottom: "4px" }}>{t("tooltip.dashboard.transactions.closed_positions.heading")}</h5>
        {t("tooltip.dashboard.transactions.closed_positions.label")}
      </div>
    </DynamicTooltip>
  );
};
