import React from "react";
import { Formik } from "formik";
import { PasswordFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";
import { passwordRegex } from "utils";

const ResetPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={object({
        password: string().min(8).max(48).matches(passwordRegex, "validation.password_rules").required(),
        passwordConfirm: string()
          .oneOf([ref("password"), null], "validation.password_match")
          .required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <PasswordFormField name="password" label={t("form.reset_password.password")} disabled={isSubmitting} autoFocus />
          <PasswordFormField
            name="passwordConfirm"
            label={t("form.signup.password_confirm")}
            required
            disabled={isSubmitting}
            autoComplete="new-password-confirm"
          />
          <PrimaryButton label={t("form.reset_password.change_password")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
