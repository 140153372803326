import React from "react";
import { Area, Bar, Line, LinePath } from "@vx/shape";
import { curveStepAfter } from "@vx/curve";
import { Group } from "@vx/group";
import { scaleLinear, scaleUtc } from "@vx/scale";
import { withTooltip, Tooltip } from "@vx/tooltip";
import { localPoint } from "@vx/event";
import { bisector } from "d3-array";
import { colors } from "common/colors";
import { withScreenSize } from "hoc";
import { formatDate, formatCurrency } from "common/formatters";
import { RIGHT, LEFT } from "common/constants/positions";
import { withTranslation } from "react-i18next";
import { endOfToday, endOfTomorrow } from "date-fns";
import moment from "moment";
import AxisRight from "./AxisRight";
import AxisLeft from "./AxisLeft";
import AxisBottom from "./AxisBottom";
import Min from "./Min";
import Max from "./Max";
import GraphTooltip from "./GraphTooltip";
import CurrencyDecimal from "../Formating/CurrencyDecimal";

import styles from "../Styles/Graphs.module.scss";

const BASE = "BASE";
const QUOTE = "QUOTE";
const BOTH = "BOTH";

const xDate = d => d.timestamp.valueOf();
const yBase = d => d.base;
const yQuote = d => d.quote;
const yQuoteMin = d => d.quoteMin;
const yQuoteMax = d => d.quoteMax;
const yBaseMin = d => d.baseMin;
const yBaseMax = d => d.baseMax;
const bisectDate = bisector(d => d.timestamp).left;

class BaseQuoteGraph extends React.Component {
  constructor(props) {
    super(props);
    this.handleTooltip = this.handleTooltip.bind(this);
    this.state = {
      height: 400,
      tooltipData: {}, // values
      tooltipLeft: null, // cursor position x
      baseTooltipOverflow: false,
      quoteTooltipOverflow: false,
      hovered: null,
    };
  }

  handleTooltip({ event, data, xScale, middleOffset, left }) {
    if (data.length < 2) return;
    const { x } = localPoint(event);
    const actualX = x - left;
    const x0 = xScale.invert(actualX);
    const li = bisectDate(data, x0, 1, data.length);

    this.setState({
      tooltipData: data[li - 1],
      tooltipLeft: xScale(data[li - 1].timestamp) + left + middleOffset,
    });
  }

  render() {
    const {
      data: {
        history,
        domains: {
          xDomain,
          yDomain: { baseDomain, quoteDomain },
        },
        minMaxPositions: { baseMinX, baseMaxX, quoteMinX, quoteMaxX },
        isExample,
        isSummary,
        baseCurrency,
        quoteCurrency,
      },
      width,
      isSmall,
      displayed,
      t,
      openedPortfolioEndDate,
    } = this.props;

    const {
      height,
      tooltipData: { timestamp, base, baseMax, baseMin, quote, quoteMax, quoteMin },
      tooltipLeft,
      baseTooltipOverflow,
      quoteTooltipOverflow,
      hovered,
    } = this.state;

    const top = isSmall ? 15 : 25;
    const bottom = isSmall ? 0 : 25;
    const left = isSmall ? 0 : 50;
    const right = isSmall ? 0 : 50;

    if (history.length <= 0) return null;
    const xMax = width - left - right;
    const yMax = height - top - bottom;
    const baseDomainMax = Math.max(...baseDomain.map(Math.abs));
    const quoteDomainMax = Math.max(...quoteDomain.map(Math.abs));

    const xScale = scaleUtc({
      range: [0, xMax],
      domain: xDomain,
    });

    const yBaseScale = scaleLinear({
      range: [yMax, 0],
      domain: [-Math.abs(baseDomainMax), Math.abs(baseDomainMax)],
      nice: true,
    });

    const yQuoteScale = scaleLinear({
      range: [yMax, 0],
      domain: [-Math.abs(quoteDomainMax), Math.abs(quoteDomainMax)],
      nice: true,
    });

    const middleOffset = xMax / (history.length - 1) / 2;

    const displayedType = displayed === BOTH && hovered ? hovered : displayed;

    // Grey out future part of graph from tomorrow to portfolio end date
    const portfolioEndDate = openedPortfolioEndDate
      ? moment.utc(openedPortfolioEndDate).add("1", "day").endOf("day").utc().valueOf()
      : undefined;
    const historyUntilToday = portfolioEndDate ? history.filter(x => x.timestamp <= endOfTomorrow().valueOf()) : history;
    const historyFromTomorrow = portfolioEndDate
      ? history.filter(x => x.timestamp >= endOfToday().valueOf() && x.timestamp <= portfolioEndDate)
      : [];

    const renderBase = isSum => (
      <>
        {!isSum && (
          <Area
            data={historyUntilToday}
            xScale={xScale}
            yScale={yBaseScale}
            x={xDate}
            y0={yBaseMin}
            y1={yBaseMax}
            stroke={displayedType === BOTH ? colors.greyBorder : displayedType === BASE ? "rgba(0,0,0,0.35)" : "rgba(0,0,0,0.05)"}
            strokeWidth={1}
            fill={`rgba(133, 255, 176, ${displayedType === BASE ? 1 : 0.25})`}
            curve={curveStepAfter}
            style={{ pointerEvents: "all", transition: "fill 0.2s, stroke-color 0.2s" }}
          />
        )}
        <LinePath
          data={historyUntilToday}
          xScale={xScale}
          yScale={yBaseScale}
          x={xDate}
          y={yBase}
          stroke={displayedType === BOTH ? "rgba(0, 201, 16)" : displayedType === BASE ? colors.text : colors.greyBorder}
          strokeWidth={1}
          curve={curveStepAfter}
          style={{ pointerEvents: "none", transition: "stroke 0.2s" }}
        />
        {!isSum && (
          <Area
            data={historyFromTomorrow}
            xScale={xScale}
            yScale={yBaseScale}
            x={xDate}
            y0={yBaseMin}
            y1={yBaseMax}
            stroke={displayedType === BOTH ? colors.greyBorder : displayedType === BASE ? "rgba(0,0,0,0.35)" : "rgba(0,0,0,0.05)"}
            strokeWidth={1}
            fill={`rgba(190, 190, 190, ${displayedType === BASE ? 1 : 0.25})`}
            curve={curveStepAfter}
            style={{ pointerEvents: "all", transition: "fill 0.2s, stroke-color 0.2s" }}
          />
        )}
        <LinePath
          data={historyFromTomorrow}
          xScale={xScale}
          yScale={yBaseScale}
          x={xDate}
          y={yBase}
          stroke={displayedType === BOTH ? "rgb(122,133,122)" : displayedType === BASE ? colors.text : colors.greyBorder}
          strokeWidth={1}
          curve={curveStepAfter}
          style={{ pointerEvents: "none", transition: "stroke 0.2s" }}
        />
      </>
    );
    const renderQuote = isSum => (
      <>
        {!isSum && (
          <Area
            data={historyUntilToday}
            xScale={xScale}
            yScale={yQuoteScale}
            x={xDate}
            y0={yQuoteMin}
            y1={yQuoteMax}
            stroke={
              displayedType === BOTH ? colors.greyBorder : displayedType === QUOTE ? "rgba(0,0,0,0.35)" : "rgba(0,0,0,0.05)"
            }
            strokeWidth={1}
            fill={`rgba(179, 243, 255, ${displayedType === QUOTE ? 1 : 0.25})`}
            curve={curveStepAfter}
            style={{ pointerEvents: "all", transition: "fill 0.2s, stroke-color 0.2s" }}
          />
        )}
        <LinePath
          data={historyUntilToday}
          xScale={xScale}
          yScale={yQuoteScale}
          x={xDate}
          y={yQuote}
          stroke={displayedType === BOTH ? colors.gradientBlue : displayedType === QUOTE ? colors.text : colors.greyBorder}
          strokeWidth={1}
          curve={curveStepAfter}
          style={{ pointerEvents: "none", transition: "stroke 0.2s" }}
        />

        {!isSum && (
          <Area
            data={historyFromTomorrow}
            xScale={xScale}
            yScale={yQuoteScale}
            x={xDate}
            y0={yQuoteMin}
            y1={yQuoteMax}
            stroke={
              displayedType === BOTH ? colors.greyBorder : displayedType === QUOTE ? "rgba(0,0,0,0.35)" : "rgba(0,0,0,0.05)"
            }
            strokeWidth={1}
            fill={`rgba(190, 190, 190, ${displayedType === QUOTE ? 1 : 0.25})`}
            curve={curveStepAfter}
            style={{ pointerEvents: "all", transition: "fill 0.2s, stroke-color 0.2s" }}
          />
        )}
        <LinePath
          data={historyFromTomorrow}
          xScale={xScale}
          yScale={yQuoteScale}
          x={xDate}
          y={yQuote}
          stroke={displayedType === BOTH ? "rgb(122,133,122)" : displayedType === QUOTE ? colors.text : colors.greyBorder}
          strokeWidth={1}
          curve={curveStepAfter}
          style={{ pointerEvents: "none", transition: "stroke 0.2s" }}
        />
      </>
    );

    return (
      <div className={isExample ? styles.history_graph_example : styles.history_graph}>
        <svg width={width} height={height}>
          <Group left={left}>
            {renderBase(isSummary)}
            {renderQuote(isSummary)}
            <Bar
              x={0}
              y={0}
              width={xMax}
              height={yMax}
              fill="transparent"
              data={history}
              onTouchStart={data => event => this.handleTooltip({ event, data, xScale, middleOffset, left })}
              onTouchMove={data => event => this.handleTooltip({ event, data, xScale, middleOffset, left })}
              onMouseMove={data => event => this.handleTooltip({ event, data, xScale, middleOffset, left })}
              onMouseLeave={() => () => this.setState({ tooltipData: {}, tooltipLeft: null })}
            />
            {!isSmall && (
              <>
                <AxisLeft scale={yBaseScale} label={baseCurrency} labelOffset={32} />
                <AxisRight scale={yQuoteScale} left={xMax} label={quoteCurrency} labelOffset={32} fill="rgba(50,200,50,0.2)" />
              </>
            )}
            <AxisBottom
              scale={xScale}
              top={yMax + bottom}
              label={isSmall ? "" : t("graph.base_quote.date")}
              numTicks={Math.min(isSmall ? 6 : 11, history.length)}
            />
            <Line
              from={{ x: 0, y: yBaseScale(0) }}
              to={{ x: xMax, y: yBaseScale(0) }}
              style={{ pointerEvents: "none" }}
              stroke={colors.text}
              strokeWidth={1}
            />
            <Min
              xMax={xMax}
              y={displayedType === BASE ? yBaseScale(baseDomain[0]) : yQuoteScale(quoteDomain[0])}
              val={
                displayedType === BASE ? (
                  <CurrencyDecimal currencyPrefix={baseCurrency} {...formatCurrency(baseDomain[0])} useSpan={false} />
                ) : (
                  <CurrencyDecimal currencyPrefix={quoteCurrency} {...formatCurrency(quoteDomain[0])} useSpan={false} />
                )
              }
              x={displayedType === BASE ? xScale(baseMinX) : xScale(quoteMinX)}
              visible={displayedType !== BOTH}
              position={displayedType === BASE ? LEFT : RIGHT}
            />
            <Max
              xMax={xMax}
              y={displayedType === BASE ? yBaseScale(baseDomain[1]) : yQuoteScale(quoteDomain[1])}
              val={
                displayedType === BASE ? (
                  <CurrencyDecimal currencyPrefix={baseCurrency} {...formatCurrency(baseDomain[1])} useSpan={false} />
                ) : (
                  <CurrencyDecimal currencyPrefix={quoteCurrency} {...formatCurrency(quoteDomain[1])} useSpan={false} />
                )
              }
              x={displayedType === BASE ? xScale(baseMaxX) : xScale(quoteMaxX)}
              visible={displayedType !== BOTH}
              position={displayedType === BASE ? LEFT : RIGHT}
            />
          </Group>
          {tooltipLeft && (
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax + bottom }}
              style={{ pointerEvents: "none" }}
              stroke={colors.text}
              strokeDasharray="3"
              strokeWidth={1.5}
            />
          )}
          <Bar // Axis hover
            x={0}
            y={0}
            width={left}
            height={yMax}
            fill="transparent"
            onMouseEnter={() => () => this.setState({ hovered: BASE })}
            onMouseLeave={() => () => this.setState({ hovered: false })}
          />
          <Bar // Axis hover
            x={xMax + left}
            y={0}
            width={right}
            height={yMax}
            fill="transparent"
            onMouseEnter={() => () => this.setState({ hovered: QUOTE })}
            onMouseLeave={() => () => this.setState({ hovered: false })}
          />
        </svg>
        {tooltipLeft && (
          <>
            <Tooltip
              top={yMax + bottom - 25}
              left={tooltipLeft}
              className={styles.tooltip_date}
              style={{ transform: "translateX(-50%)", WebkitTransform: "translateX(-50%)", zIndex: 10 }}>
              {formatDate(timestamp)}
            </Tooltip>
            <GraphTooltip
              top={yBaseScale(base)}
              secondTop={yQuoteScale(quote)}
              left={tooltipLeft}
              position={LEFT}
              notifyOverflow={val => this.setState({ baseTooltipOverflow: val })}
              rotate={false}
              overflow={baseTooltipOverflow}
              className={styles.bq_green_tooltip}
              visible={displayedType !== QUOTE}>
              <div className={styles.multiline_tooltip}>
                <span>{baseCurrency}</span>
                <div>
                  {!isSummary && <CurrencyDecimal currencyPrefix="Max:" {...formatCurrency(baseMax)} />}
                  <CurrencyDecimal currencyPrefix={t("graph.base_quote.close_label")} {...formatCurrency(base)} />
                  {!isSummary && <CurrencyDecimal currencyPrefix="Min:" {...formatCurrency(baseMin)} />}
                </div>
              </div>
            </GraphTooltip>
            <GraphTooltip
              top={yQuoteScale(quote)}
              secondTop={yBaseScale(base)}
              left={tooltipLeft}
              position={RIGHT}
              notifyOverflow={val => this.setState({ quoteTooltipOverflow: val })}
              rotate={false}
              overflow={quoteTooltipOverflow}
              className={styles.bq_blue_tooltip}
              visible={displayedType !== BASE}>
              <div className={styles.multiline_tooltip}>
                <span>{quoteCurrency}</span>
                <div>
                  {!isSummary && <CurrencyDecimal currencyPrefix="Max:" {...formatCurrency(quoteMax)} />}
                  <CurrencyDecimal currencyPrefix={t("graph.base_quote.close_label")} {...formatCurrency(quote)} />
                  {!isSummary && <CurrencyDecimal currencyPrefix="Min:" {...formatCurrency(quoteMin)} />}
                </div>
              </div>
            </GraphTooltip>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(withTooltip(withScreenSize(BaseQuoteGraph)));
