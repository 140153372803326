import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { WB_SUPPORT } from "common/constants/whaleBooksRoles";
import Organizations from "pages/Organizations";
import AccountDetail from "pages/AccountDetail";
import Help from "pages/Help";
import Landing from "pages/Landing";
import Pricing from "pages/Pricing";
import Dashboard from "pages/Dashboard";
import Reports from "pages/Reports";
import LegalPrivacy from "pages/LegalPrivacy";
import About from "pages/About";
import Profile from "pages/Profile";
import Upgrade from "pages/Upgrade";
import DataManager from "pages/DataManager";
import LogIn from "pages/Login";
import SignUp from "pages/SignUp";
import Forgot from "pages/Forgot";
import AdminUsers from "pages/Support/AdminUsers";
import AdminOrganizations from "pages/Support/AdminOrganizations";
import SharedPortfolio from "pages/SharedPortfolio";
import ResetPassword from "pages/ResetPassword";
import VerifyEmail from "pages/VerifyEmail";
import OrganizationDetail from "pages/OrganizationDetail";
import Invitation from "pages/Invitation";
import NotFound from "pages/NotFound";
import MissingPermission from "pages/MissingPermission";
import styles from "containers/Styles/App.module.scss";
import ConfiguredPairs from "pages/Support/ConfiguredPairs";
import Accounts from "pages/Accounts";
import ApiManagement from "pages/ApiManagement";
import ApiAccept from "pages/ApiAccept";
import Integrations from "pages/Integrations";
import OrganizationAccountingSettings from "pages/OrganizationAccountingSettings";

const VirtualReality = lazy(() => import("pages/VirtualReality")); // to lazy load all the aframe libraries when they are needed

const PublicRoute = ({ component: Component, user, ...rest }) => {
  const { isAuthenticated } = user;

  return (
    <Route
      {...rest}
      render={props => (!isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/dashboard" }} />)}
    />
  );
};

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const { isAuthenticated } = user;

  return (
    <Route {...rest} render={props => (!isAuthenticated ? <Redirect to={{ pathname: "/" }} /> : <Component {...props} />)} />
  );
};

const AdminRoute = ({ component: Component, user, ...rest }) => {
  const { isAuthenticated, userRole } = user;

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Redirect to={{ pathname: "/" }} />
        ) : userRole === WB_SUPPORT ? (
          <Component {...props} />
        ) : (
          <MissingPermission />
        )
      }
    />
  );
};

const AllRoutes = ({ user }) => (
  <div className={styles.body}>
    <Suspense fallback={null}>
      <Switch>
        <Route path="/about" component={About} />
        <Route path="/legal" component={LegalPrivacy} />
        <Route path="/forgottenpassword" component={ResetPassword} />
        <Route path="/portfolio/:pid" component={SharedPortfolio} />
        <Route path="/help/:tab?" component={Help} />

        <Route path="/invitation" component={Invitation} />
        <Route path="/vr/:shortLink?" user={user} component={VirtualReality} />

        <PrivateRoute path="/dashboard/:id?" user={user} component={Dashboard} />

        <PrivateRoute path="/datamanager/:tab" user={user} component={DataManager} />

        <PrivateRoute exact path="/asset-accounts" user={user} component={Accounts} />
        <PrivateRoute path="/asset-accounts/:id/:tab" user={user} component={AccountDetail} />

        <PrivateRoute path="/reports" user={user} component={Reports} />

        <PrivateRoute path="/api-management" user={user} component={ApiManagement} />
        <Route path="/allow-api" component={ApiAccept} />

        <PrivateRoute path="/profile" user={user} component={Profile} />

        <PrivateRoute exact path="/organizations" user={user} component={Organizations} />
        <PrivateRoute exact path="/organizations/:id/upgrade" user={user} component={Upgrade} />
        <PrivateRoute exact path="/organizations/:id/:tab" user={user} component={OrganizationDetail} />

        <PrivateRoute
          exact
          path="/organization-accounting-settings/:id/:tab"
          user={user}
          component={OrganizationAccountingSettings}
        />
        <PrivateRoute
          exact
          path="/organization-accounting-settings/:id/:tab/:subTab"
          user={user}
          component={OrganizationAccountingSettings}
        />

        <PublicRoute exact path="/" user={user} component={Landing} />
        <PublicRoute path="/login" user={user} component={LogIn} />
        <PublicRoute path="/pricing" user={user} component={Pricing} />
        <PublicRoute path="/integrations" user={user} component={Integrations} />
        <PublicRoute path="/signup" user={user} component={SignUp} />
        <PublicRoute path="/forgot" user={user} component={Forgot} />
        <PublicRoute path="/verify" user={user} component={VerifyEmail} />

        <AdminRoute path="/support/users" user={user} component={AdminUsers} />
        <AdminRoute path="/support/organizations" user={user} component={AdminOrganizations} />
        <AdminRoute path="/support/pairs/:tab/:id?" user={user} component={ConfiguredPairs} />

        <Route path="/notfound" component={NotFound} />
        <Redirect to="/notfound" />
      </Switch>
    </Suspense>
  </div>
);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps)(AllRoutes));
