import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationEnvelope from "components/Confirmation/ConfirmationEnvelope";
import { bindActionCreators } from "redux";
import { accountActions } from "actions/accountActions";
import { transactionActions } from "actions/transactionActions";
import { connect } from "react-redux";
import { isAccountDetailLoading } from "selectors";

const DeleteAccount = ({
  onConfirm,
  onCancel,
  accountName,
  accountId,
  accountDetail,
  isLoading,
  accountActions: { loadAccountDetail },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (accountId) loadAccountDetail(accountId);
  }, [accountId, loadAccountDetail]);

  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_account.heading")}
      confirmLabel={t("confirmation.delete_account.confirm_label")}
      critical
      isLoading={isLoading}>
      <p>
        <Trans i18nKey="confirmation.delete_account.content">Action will permanently delete account {{ accountName }}.</Trans>

        {accountDetail && !isLoading && (
          <div className="mt-3 font-weight-bold">
            <div>{t("confirmation.delete_account.connections")}</div>
            <div>
              {t("account_detail.general_data.connected_containers")}:{" "}
              {accountDetail.containerRelations.length > 0
                ? accountDetail.containerRelations.map(x => x.containerName).join(", ")
                : "~"}
            </div>
            <div>
              {t("account_detail.general_data.connected_portfolios")}:{" "}
              {accountDetail.portfolioRelations.length > 0
                ? accountDetail.portfolioRelations.map(x => x.portfolioName).join(", ")
                : "~"}
            </div>
          </div>
        )}
      </p>
    </ConfirmationEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    accountDetail: state.accounts.accountDetail,
    isLoading: isAccountDetailLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
