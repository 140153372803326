export const ACCOUNTING_SOFTWARE_REPORT_FORMAT = "ACCOUNTING_SOFTWARE_REPORT_FORMAT";
export const ACCOUNTING_REPORT_INCLUDE_DEPOSIT = "ACCOUNTING_REPORT_INCLUDE_DEPOSIT";
export const ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL = "ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL";
export const ACCOUNTING_REPORT_REFERENCE_MAPPING = "ACCOUNTING_REPORT_REFERENCE_MAPPING";
export const ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION = "ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION";

export const ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT = "ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT";
export const ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES = "ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES";
export const ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX = "ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX";
export const ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT = "ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT";
export const ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL = "ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL";
export const ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING = "ACCOUNTING_SOFTWARE_POHODA_REFERENCE_MAPPING";
export const ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION = "ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION";

const t_fake = str => str;

export const WHALEBOOKS_FORMAT = "WHALEBOOKS_FORMAT";
export const POHODA_FORMAT = "POHODA_FORMAT";

const accountingSoftwareTypeTranslations = {
  [WHALEBOOKS_FORMAT]: "WhaleBooks (universal)",
  [POHODA_FORMAT]: "Pohoda - Stormware",
};

export const accountingSoftwareTypeTranslator = val => accountingSoftwareTypeTranslations[val] || "";

export const DATA_FORMAT_XLSX = "XLSX";

const accountingSoftwareDataFormatTranslations = {
  [DATA_FORMAT_XLSX]: t_fake("accounting_software.data_format.xlsx"),
};

export const accountingSoftwareDataFormatTranslator = val => accountingSoftwareDataFormatTranslations[val] || "";

export const REFERENCE = "REFERENCE";
export const COST_CENTER = "COST_CENTER";

const accountingSoftwareReferenceMappingTranslations = {
  [REFERENCE]: t_fake("accounting_software.reference_mapping.reference"),
  [COST_CENTER]: t_fake("accounting_software.reference_mapping.cost_center"),
};

export const accountingSoftwareReferenceMappingTranslator = val => accountingSoftwareReferenceMappingTranslations[val] || "";

export const IMMEDIATELY = "IMMEDIATELY";
export const MOMENT_OF_SALE = "MOMENT_OF_SALE";

const accountingSoftwareMomentOfAcquisitionTranslations = {
  [IMMEDIATELY]: t_fake("accounting_software.moment_of_acquisition.immediately"),
  [MOMENT_OF_SALE]: t_fake("accounting_software.moment_of_acquisition.moment_of_sale"),
};

export const accountingSoftwareMomentOfAcquisitionTranslator = val =>
  accountingSoftwareMomentOfAcquisitionTranslations[val] || "";
