import React, { useRef, forwardRef } from "react";
import { useField } from "formik";
import NumberFormat from "react-number-format";
import cx from "classnames";
import useDecimalSeparator from "hooks/useDecimalSeparator";
import TestInput from "components/Common/TestInput";
import { InputWrapper, LabelRow } from "./Shared";

import styles from "./Inputs.module.scss";

export const INTEGER_DIGITS = 20;
export const DECIMAL_DIGITS = 20;
export const DECIMAL_PLACES = 17;

const DecimalInput = ({
  onChange,
  label,
  error,
  required,
  isInline,
  wrapperClassName,
  placeholder,
  children,
  testId,
  ...props
}) => {
  const inputRef = useRef();
  const commaAsDecimalSeparator = useDecimalSeparator();

  return (
    <InputWrapper
      className={cx(styles.text_input, wrapperClassName)}
      disabled={props.disabled}
      inline={isInline}
      error={error}
      onClick={() => inputRef.current?.focus()}>
      <LabelRow label={label} error={error} required={required} />

      {testId && <TestInput name={testId} value={props.value} />}

      <div className={styles.flex_space_between} style={{ gap: "2px" }}>
        <NumberFormat
          getInputRef={inputRef}
          // value={value === '' ? '' : value.toFixed(MAX_PRECISION).replace(/\.?0+$/,"")}
          thousandSeparator=" "
          isNumericString
          decimalScale={INTEGER_DIGITS}
          placeholder={placeholder ?? (props.disabled ? "" : "0")}
          onValueChange={values => onChange(values.value || "")}
          disabled={props.disabled}
          allowedDecimalSeparators={commaAsDecimalSeparator ? [".", ","] : ["."]}
          decimalSeparator={commaAsDecimalSeparator ? "," : "."}
          autoComplete="off"
          {...props}
          isAllowed={values => {
            const { value } = values;
            if (!value) return true;

            const splitValue = value.split(".");

            if (splitValue.length === 2 && (splitValue[0].length > DECIMAL_DIGITS || splitValue[1].length > DECIMAL_PLACES)) {
              return false;
            }
            if (splitValue.length === 1 && splitValue[0].length > DECIMAL_DIGITS) {
              return false;
            }

            return true;
          }}
        />
        {children}
      </div>
    </InputWrapper>
  );
};

export const DecimalFormField = forwardRef(({ name, onChange, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return (
    <DecimalInput
      ref={ref}
      error={meta.touched ? meta.error : ""}
      {...field}
      onChange={val => {
        if (onChange && typeof onChange === "function") onChange(val);
        helpers.setValue(val);
      }}
      {...props}
    />
  );
});

export default DecimalInput;
