import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "components/Styles/DataManager.module.scss";
import cx from "classnames";
import { SelectInput } from "components/Common/Inputs";
import { transactionService } from "services";
import { GhostButton, LoaderOverlay } from "components/Common";
import { SMALL } from "common/constants/screenSizes";
import {
  getActivePortfolioId,
  getActivePortfolioIdSearchUrl,
  getContainerDetail,
  getCurrencyOptions,
  getExtendedContainerDetail,
  getFiatOptions,
} from "selectors";
import { bindActionCreators } from "redux";
import { transactionActions } from "actions/transactionActions";
import { connect } from "react-redux";
import { accountActions } from "actions/accountActions";
import { getCurrencyIconProps } from "utils/currencyIcon";
import { sortBy } from "lodash";
import { components } from "react-select";
import history from "common/history";
import { modalActions } from "actions/modalActions";
import { RotateRight } from "grommet-icons";
import { currencyTypeTranslator } from "common/constants/currencyTypes";
import { useScreenSize } from "hooks";
import { CONTAINER_API } from "common/constants/containerTypes";

// NOTE - component is re-rendered on every container detail refresh!
const Accounts = ({
  containerDetail: { id, ...container },
  extendedContainerDetail,
  activePortfolioId,
  allAccounts,
  isAccountsFetching,
  user,
  fiatOptions,
  portfolioIdSearch,
  currencyOptions,
  transactionActions: { setExtendedContainerDetail, updateContainer },
  accountActions: { getAccounts },
  modalActions: { hideModal, openCreateAccount, openContainerDetail },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const isLoading = extendedContainerDetail.currencyAccountsLoading;
  const currencyAccounts = extendedContainerDetail.id === id ? extendedContainerDetail.currencyAccounts : null;

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const loadCurrencyAccounts = async () => {
    setExtendedContainerDetail(id, { currencyAccountsLoading: true });
    const response = await transactionService.getContainerDetailCurrencyAccounts(id, user);
    setExtendedContainerDetail(id, { currencyAccounts: response, currencyAccountsLoading: false });
  };

  useEffect(() => {
    if (id && user && !currencyAccounts && !isLoading) {
      loadCurrencyAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user, currencyAccounts, isLoading]);

  const getAccountOptionsForCurrency = currency =>
    allAccounts.filter(x => x.currency === currency).map(x => ({ label: `${x.name} (${x.currency})`, value: x.id }));

  const assignedAccountsByCurrencies = currencyAccounts
    ? Object.fromEntries(
        currencyAccounts.map(x => [
          x.currency,
          x.assignedAcc ? { label: x.assignedAcc.name, value: x.assignedAcc.id } : undefined,
        ])
      )
    : {};

  const [inputsDisabled, setInputsDisabled] = useState(false);
  const handleUpdateContainer = async (currency, accountId) => {
    setInputsDisabled(true);

    setExtendedContainerDetail(id, { currencyAccountsLoading: true });
    await updateContainer(
      {
        id,
        ...container,
        accountIds: [
          ...currencyAccounts.filter(x => x.assignedAcc?.id && x.assignedAcc.currency !== currency).map(x => x.assignedAcc.id),
          accountId,
        ].filter(x => x),
      },
      activePortfolioId,
      true,
      false
    );

    await loadCurrencyAccounts();
    setInputsDisabled(false);
  };

  const sortedCurrenciesOverview = useMemo(() => {
    const currencyAccountsWithCurrencyType = currencyAccounts?.map(x => ({
      ...x,
      // Fiat first, then crypto
      currencyType: fiatOptions.some(y => y.value === x.currency) ? "FIAT" : "KRYPTO",
      description: currencyOptions.find(y => y.value === x.currency)?.description,
      type: fiatOptions.some(y => y.value === x.currency) ? "FIAT" : "CRYPTO",
    }));

    return sortBy(currencyAccountsWithCurrencyType, x => [x.currencyType, x.currency]);
  }, [currencyAccounts, fiatOptions, currencyOptions]);

  const refreshCurrencyAccounts = () => {
    getAccounts();
    loadCurrencyAccounts();
  };

  return (
    <LoaderOverlay
      isLoading={(isLoading || (isAccountsFetching && allAccounts.length === 0)) && !inputsDisabled}
      spinnerSize={SMALL}>
      <div className={cx(styles.accounts_list, "mt-3")}>
        <span className="font-weight-bold mb-2">{t("data_manager.container_detail.currency")}</span>
        <span className="font-weight-bold mb-2 d-flex justify-content-between">
          {t("data_manager.container_detail.currency_assigned_account")}
          <GhostButton onClick={refreshCurrencyAccounts} icon={<RotateRight />} actionButton className="mx-0 px-0 mr-n2" />
        </span>
        {sortedCurrenciesOverview?.map((x, index) => (
          <React.Fragment key={index}>
            <span className="mb-1">
              <div className={styles.currency_image}>
                <img {...getCurrencyIconProps(x.currency)} alt={x.currency} />
              </div>

              <div className="d-flex align-items-start" style={{ gap: "0.35rem" }}>
                {x.currency}
                {!isSmall && (
                  <span style={{ fontSize: "12px", lineHeight: "26px" }}>
                    {x.description} ({t(currencyTypeTranslator(x.type))})
                  </span>
                )}
              </div>
            </span>
            <span className="mb-1">
              <SelectInput
                value={assignedAccountsByCurrencies[x.currency]}
                options={getAccountOptionsForCurrency(x.currency)}
                placeholder={t("data_manager.container_detail.unassigned_currency")}
                onChange={value => handleUpdateContainer(x.currency, value?.value)}
                isClearable
                disabled={inputsDisabled}
                components={{
                  Menu: props => (
                    <components.Menu {...props}>
                      {props.children}
                      <div className="my-1">
                        <GhostButton
                          labelClassName={styles.request_more_currencies_label}
                          label={`+ ${t("data_manager.container_detail.add_accounts")}`}
                          onClick={() => {
                            hideModal();
                            openCreateAccount(() =>
                              openContainerDetail(id, container.type, container.type === CONTAINER_API ? 2 : 0)
                            );
                          }}
                        />
                      </div>
                    </components.Menu>
                  ),
                }}
              />
            </span>
          </React.Fragment>
        ))}
      </div>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    containerDetail: getContainerDetail(state),
    extendedContainerDetail: getExtendedContainerDetail(state),
    activePortfolioId: getActivePortfolioId(state),
    allAccounts: state.accounts.accounts,
    isAccountsFetching: state.accounts.isAccountsFetching,
    user: state.user,
    fiatOptions: getFiatOptions(state),
    portfolioIdSearch: getActivePortfolioIdSearchUrl(state),
    currencyOptions: getCurrencyOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
