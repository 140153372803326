import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon, Trash as TrashIcon, View as ViewIcon } from "grommet-icons/icons";
import { sortBy } from "lodash";
import history from "common/history";
import AccountsTooltip from "components/Tooltips/AccountsTooltip";
import FormattedNumber from "components/Common/FormattedNumber";
import { accountTypesTranslator } from "common/constants/accountTypes";
import { getCurrencyIconProps } from "utils/currencyIcon";
import { Heading, LoaderOverlay } from "../Common";
import styles from "../Styles/Dashboard.module.scss";
import GhostButton from "../Common/Buttons/GhostButton";
import cx from "classnames";
import { ActionMenu } from "components/Common/ActionMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faGear } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import { accountActions } from "actions/accountActions";
import { connect } from "react-redux";
import ExportAccount from "containers/Accounts/ExportAccount";
import { confirmationActions } from "actions/confirmationActions";
import { useScreenSize } from "hooks";
import { modalActions } from "actions/modalActions";

const AssetAccountsTable = ({
  accountStates,
  isLoading,
  portfolioIdSearch,
  fiatOptions,
  confirmationActions: { openDeleteAccountConfirmation },
  modalActions: { openEditAssetAccount },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const sortedAccountStates = useMemo(() => {
    const accountsWithCurrencyType = accountStates.map(x => ({
      ...x,
      // Fiat first, then crypto
      currencyType: fiatOptions.some(y => y.value === x.currency) ? "FIAT" : "KRYPTO",
      lowerCaseName: x.accountName.toLowerCase(),
    }));

    return sortBy(accountsWithCurrencyType, x => [x.lowerCaseName, x.currencyType, x.currency]);
  }, [accountStates, fiatOptions]);

  const hasAccounts = sortedAccountStates.length > 0;

  return (
    <div style={{ paddingTop: "2rem" }}>
      <div className={styles.flex_space_between}>
        <Heading level={3}>{t("accounts.heading")}</Heading>
        <AccountsTooltip />
      </div>

      <LoaderOverlay isLoading={isLoading} style={isLoading ? { minHeight: "200px" } : undefined}>
        <div style={{ overflowX: hasAccounts ? "auto" : undefined }}>
          <table className="my-2">
            <thead>
              <tr>
                <th>{t("accounts.table_header.currency")}</th>
                <th>{t("accounts.table_header.account_name")}</th>
                <th>{t("accounts.table_header.type")}</th>
                <th>
                  <div style={{ textAlign: "right" }}>{t("accounts.table_header.amount")}</div>
                </th>
                <th>
                  <div style={{ textAlign: "right" }}>{t("dashboard.portfolio_summary.market_value")}</div>
                </th>
                <th className="sticky-table-col ml-1">
                  <div className="ml-1" style={{ textAlign: "right" }}>
                    {t("accounts.table_header.action")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedAccountStates.map((x, index) => {
                const { currency, accountName, type, amount, amountAtPtfCurrency, ptfCurrency, id } = x;
                const hasWiderBorder =
                  index < sortedAccountStates.length - 1 && x.accountName !== sortedAccountStates[index + 1].accountName;

                return (
                  <tr key={index} style={{ borderWidth: hasWiderBorder ? "2px" : undefined }}>
                    <td>
                      <div className={styles.currency_image}>
                        <img {...getCurrencyIconProps(currency)} alt={currency} /> <h4 className="m-0 mr-3">{currency}</h4>
                      </div>
                    </td>
                    <td>
                      <h4 className="m-0">{accountName}</h4>
                    </td>
                    <td>
                      <h4 className="m-0">{t(accountTypesTranslator(type))}</h4>
                    </td>
                    <td>
                      <div style={{ textAlign: "right" }}>
                        <h4 className={cx("m-0", { "negative-value": amount < 0 })}>
                          <FormattedNumber number={amount} currency={currency} displayCurrency />
                        </h4>
                      </div>
                    </td>
                    <td>
                      <div style={{ textAlign: "right" }}>
                        <h4 className={cx("m-0", { "negative-value": amountAtPtfCurrency < 0 })}>
                          <FormattedNumber number={amountAtPtfCurrency} currency={ptfCurrency} displayCurrency />
                        </h4>
                      </div>
                    </td>
                    <td className="sticky-table-col">
                      <div className="d-flex justify-content-end" style={{ overflow: "hidden" }}>
                        {!isSmall && (
                          <GhostButton
                            icon={<ViewIcon />}
                            onClick={() => history.push(`/asset-accounts/${id}/transactions${portfolioIdSearch}`)}
                            actionButton
                            testId={`asset-account-detail_${accountName}`}
                          />
                        )}

                        <ActionMenu testId="asset-accounts-menu">
                          <ActionMenu.ItemGroup key={1}>
                            <ActionMenu.Item
                              icon={<ViewIcon />}
                              label={t("account_detail.transactions.heading")}
                              onClick={() => history.push(`/asset-accounts/${id}/transactions${portfolioIdSearch}`)}
                              testId={`action-account-transactions_row-${index}`}
                            />

                            <ActionMenu.Item
                              icon={<EditIcon />}
                              label={t("account_detail.general_data.edit_button")}
                              onClick={() => openEditAssetAccount(id)}
                              testId={`action-account-edit_row-${index}`}
                            />

                            <ActionMenu.Item
                              icon={<FontAwesomeIcon icon={faAddressBook} />}
                              label={t("account_detail.addresses.heading")}
                              onClick={() => history.push(`/asset-accounts/${id}/addresses${portfolioIdSearch}`)}
                              testId={`action-account-addresses_row-${index}`}
                            />

                            <ActionMenu.Item
                              icon={<FontAwesomeIcon icon={faGear} />}
                              label={t("account_detail.general_data.heading")}
                              onClick={() => history.push(`/asset-accounts/${id}/general${portfolioIdSearch}`)}
                              testId={`action-account-settings_row-${index}`}
                            />

                            <ExportAccount accountId={id} testId={`action-account-export_row-${index}`} />
                          </ActionMenu.ItemGroup>

                          <ActionMenu.ItemGroup hasSeparator key={2}>
                            <ActionMenu.Item
                              icon={<TrashIcon />}
                              label={t("button.delete")}
                              critical
                              onClick={() =>
                                openDeleteAccountConfirmation(
                                  { accountId: id, accountName: accountName },
                                  { refetchDashboardAccounts: true }
                                )
                              }
                              testId={`action-account-delete_row-${index}`}
                            />
                          </ActionMenu.ItemGroup>
                        </ActionMenu>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </LoaderOverlay>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(AssetAccountsTable);
