import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import "components/Common/Components.module.scss";
import {
  getActiveOrganizationId,
  getActiveOrganizationPlan,
  getActiveOrganizationTransactionsCount,
  getActiveOrganizationTransactionsLimit,
} from "selectors";
import { ONLINE, paymentPlans } from "common/constants/paymentPlans";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { LoaderOverlay } from "components/Common";
import { SMALL } from "common/constants/screenSizes";
import { useIsMounted } from "hooks/useIsMounted";
import { formatThousandsSeparator } from "common/formatters";
import UpgradeButton from "containers/UpgradeButton";
import cx from "classnames";
import { useScreenSize } from "hooks";

const TransactionCapacity = ({
  transactionsCount,
  transactionsCountFetching,
  transactionsLimit,
  organizationPlan,
  organizationId,
  organizationActions: { updateTransactionsCount },
  innerWrapper = undefined,
  styles,
  alwaysVisible,
  className,
  ...rest
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { isSmall } = useScreenSize();

  useEffect(() => {
    updateTransactionsCount();
  }, [updateTransactionsCount]);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(
    () => () => {
      setIsLoading(true);
      setTimeout(() => {
        if (isMounted.current) setIsLoading(false);
      }, 200);
    },
    [transactionsCount, isMounted]
  );

  const isUnderLimit = transactionsCount < transactionsLimit;

  const body = (
    <div style={{ ...styles, cursor: "default !important" }} className={cx(className, "text-start")} {...rest}>
      <div style={{ marginBottom: "12px" }} className="d-flex justify-content-between align-items-center">
        <div style={{ width: "min-content" }}>
          {t("organization.current_plan")}: <b>{paymentPlans[organizationPlan]?.name || ""}</b>
        </div>
        <UpgradeButton activeOrganizationId={organizationId} style={{ justifySelf: "end", width: "auto" }} />
      </div>

      <LoaderOverlay isLoading={(isLoading || transactionsCountFetching) && !isSmall} spinnerSize={SMALL}>
        <div style={{ textTransform: "initial" }}>
          {t("transaction_capacity.tx_count")}
          {alwaysVisible && !transactionsLimit && transactionsCount ? (
            <b>: {formatThousandsSeparator(transactionsCount)} Tx</b>
          ) : (
            ""
          )}
          {transactionsLimit && (
            <b>
              :{" "}
              {isUnderLimit ? (
                <Trans i18nKey="transaction_capacity.used_capacity">
                  {{ transactionsCount: formatThousandsSeparator(transactionsCount) }} Tx from{" "}
                  {{ transactionsLimit: formatThousandsSeparator(transactionsLimit) }} Tx
                </Trans>
              ) : (
                <Trans i18nKey="transaction_capacity.over_limit">Limit {{ transactionsLimit }} Tx has been reached.</Trans>
              )}
            </b>
          )}
        </div>
        {transactionsLimit && (
          <div className="progress mt-1" style={{ height: "8px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax={transactionsLimit}
              style={{
                width: `${(transactionsCount / transactionsLimit) * 100}%`,
                backgroundColor: isUnderLimit ? "var(--green-4)" : "rgb(253,86,51)",
                borderRadius: "0 .25rem .25rem 0",
                minWidth: "9px",
              }}
            />
          </div>
        )}
      </LoaderOverlay>
    </div>
  );

  return (
    <>
      {((organizationPlan === ONLINE && transactionsCount >= 0 && transactionsLimit) || alwaysVisible) &&
        (innerWrapper ? innerWrapper(body) : body)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    transactionsCount: getActiveOrganizationTransactionsCount(state),
    transactionsLimit: getActiveOrganizationTransactionsLimit(state),
    organizationPlan: getActiveOrganizationPlan(state),
    organizationId: getActiveOrganizationId(state),
    transactionsCountFetching: state.organizations.transactionsCountFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionCapacity);
