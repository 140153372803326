import React from "react";
import NumberFormat from "react-number-format";
import useDecimalSeparator from "hooks/useDecimalSeparator";

export default ({ number, useSpan = true, ...props }) => {
  const commaAsDecimalSeparator = useDecimalSeparator();

  return (
    <NumberFormat
      value={number}
      displayType="text"
      thousandSeparator=" "
      decimalSeparator={commaAsDecimalSeparator ? "," : undefined}
      suffix="%"
      decimalScale={2}
      fixedDecimalScale
      renderText={value => (useSpan ? <span>{value}</span> : value)}
      {...props}
    />
  );
};
