import React from "react";
import { Formik } from "formik";
import { DateFormField, SelectFormField } from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { ONLINE, ONLINE_PLUS, paymentPlans, TRIAL } from "common/constants/paymentPlans";

const paymentPlanOptions = [
  { value: ONLINE, label: paymentPlans[ONLINE].name },
  { value: ONLINE_PLUS, label: paymentPlans[ONLINE_PLUS].name },
  { value: TRIAL, label: paymentPlans[TRIAL].name },
];

const OrganizationTrialPeriodForm = ({ organization, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        plan: paymentPlanOptions.find(x => x.value === organization.plan) ?? paymentPlanOptions[0],
        timestamp: organization.trialEnd || "",
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        plan: object().required(),
        timestamp: string().when("plan", {
          is: plan => plan?.value !== ONLINE,
          then: string().required(),
        }),
      })}>
      {({ values, isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <SelectFormField name="plan" options={paymentPlanOptions} label={t("et_organizations.table_header.plan")} />
          {values.plan?.value !== ONLINE && (
            <DateFormField
              name="timestamp"
              label={t("form.organization_trial_period.timestamp")}
              disabled={isSubmitting}
              required
            />
          )}
          <PrimaryButton label={t("form.organization_trial_period.save")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationTrialPeriodForm;
