import React, { useEffect, useLayoutEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { organizationActions } from "actions/organizationActions";
import { Heading, Tab, Tabs } from "components/Common";
import { useTranslation } from "react-i18next";
import { getRawOrganizationDetails } from "selectors";
import { useUrlHelper } from "hooks";
import styles from "pages/Pages.module.scss";
import history from "common/history";
import { useRouteMatch } from "react-router-dom";
import ChartOfAccount, {
  chartOfAccountCategories,
  chartOfAccountUrl,
} from "containers/Organizations/ChartOfAccount/ChartOfAccount";
import { Col, Row } from "react-bootstrap";
import TaxRules from "containers/Organizations/TaxRules/TaxRules";
import cx from "classnames";
import { modalActions } from "actions/modalActions";
import AccountingSoftware from "containers/Organizations/AccountingSoftware";
import Lists from "containers/Organizations/Lists/Lists";

export const organizationAccountingSettingsCategories = ["tax-rules", chartOfAccountUrl, "accounting-software", "lists"];

const OrganizationAccountingSettings = ({ organizationDetails, organizationActions, modalActions: { openSetTaxResidency } }) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();
  const match = useRouteMatch();

  useEffect(() => {
    if (match.params.tab === chartOfAccountUrl && !match.params.subTab) {
      history.push(`/organization-accounting-settings/${id}/${chartOfAccountUrl}/${chartOfAccountCategories[0]}`);
    }
  }, [match, id]);

  useLayoutEffect(() => {
    organizationActions.getOrganizationInfo(id);
    organizationActions.getOrganizationAccountSettings(id);
  }, [id, organizationActions]);

  const { organizationData } = organizationDetails[id] || {};

  useEffect(() => {
    if (
      match.params.tab === chartOfAccountUrl &&
      organizationData &&
      (!organizationData?.taxResidency || !organizationData?.taxSubject)
    ) {
      openSetTaxResidency(organizationData, false);
    }
  }, [organizationData, openSetTaxResidency, match.params]);

  return (
    <div className={styles.app_content_padding}>
      <Tabs
        tabWrapperClassName={styles.organization_detail_tab}
        listClassName={cx(styles.organization_detail_tabs, styles.modal_tabs)}
        HeaderComponent={({ children }) => (
          <Row className="mb-3 align-items-center" style={{ rowGap: "1rem" }}>
            <Col xs={12} md="auto">
              <Heading level={2}>{t("organization_detail.accounting_settings")}</Heading>
            </Col>
            <Col xs="auto" className="ml-auto">
              {children}
            </Col>
          </Row>
        )}
        activeIndex={organizationAccountingSettingsCategories.indexOf(match.params.tab)}
        onTabClick={index =>
          history.push(`/organization-accounting-settings/${id}/${organizationAccountingSettingsCategories[index] || ""}`)
        }
        defaultTabIndex={Math.max(
          organizationAccountingSettingsCategories.findIndex(o => o === match.params.tab),
          0
        )}>
        <Tab title={t("organization_detail.transactions.tax_rules")}>
          <TaxRules />
        </Tab>
        <Tab title={t("organization_detail.transactions.chart_of_account.all")}>
          <ChartOfAccount />
        </Tab>
        <Tab title={t("organization_detail.transactions.accounting_software")}>
          <AccountingSoftware />
        </Tab>
        <Tab title={t("organization_detail.transactions.lists.all")}>
          <Lists />
        </Tab>
      </Tabs>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizationDetails: getRawOrganizationDetails(state),
    userRole: state.user.userRole,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAccountingSettings);
