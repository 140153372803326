import { apiManagementService } from "services/apiManagementService";
import { GET_PERMISSIONS } from "./types";
import { alertActions } from "./alertActions";

function getPermissions() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PERMISSIONS,
      payload: {
        promise: apiManagementService.getApiPermissions(getState().user),
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function acceptPermission(apiAccessId, requestedEmail) {
  return async (dispatch, getState) => {
    await apiManagementService.acceptApiPermission(getState().user, apiAccessId, requestedEmail);
    dispatch(getPermissions());
  };
}

function deletePermission(permissionId) {
  return async (dispatch, getState) => {
    await apiManagementService.deleteApiPermission(getState().user, permissionId);
    dispatch(getPermissions());
  };
}

export const apiManagementActions = {
  getPermissions,
  acceptPermission,
  deletePermission,
};
