import React, { useMemo } from "react";
import { PermissionCheck, PrimaryButton } from "components/Common";
import { OWNER, READ } from "common/constants/userPermission";
import { Link } from "react-router-dom";
import { PrimaryButtonSize } from "components/Common/Buttons/PrimaryButton";
import { useUrlHelper } from "hooks";
import { getActiveOrganizationId, getUserMemberships } from "selectors";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import SubscriptionPlanCheck from "containers/SubscriptionPlanCheck";

const UpgradeButton = ({ activeOrganizationId, userMemberships, className, isVisiblePaidPlan, ...rest }) => {
  const { t } = useTranslation();
  const { id: urlId } = useUrlHelper();

  const id = urlId || activeOrganizationId;

  const currentMembership = useMemo(
    () => userMemberships.find(o => o.organizationId === activeOrganizationId),
    [activeOrganizationId, userMemberships]
  );

  const currentUserRole = useMemo(() => currentMembership?.role || READ, [currentMembership]);

  return (
    <SubscriptionPlanCheck isVisiblePaidPlan={isVisiblePaidPlan}>
      {id && (
        <PermissionCheck activeRole={currentUserRole} allowFor={[OWNER]}>
          <Link to={`/organizations/${id}/upgrade`} className={cx("no-underline", className)} {...rest}>
            <PrimaryButton collapsed noIcon size={PrimaryButtonSize.Small} className="ml-3" label={t("button.upgrade")} />
          </Link>
        </PermissionCheck>
      )}
    </SubscriptionPlanCheck>
  );
};

function mapStateToProps(state) {
  return {
    activeOrganizationId: getActiveOrganizationId(state),
    userMemberships: getUserMemberships(state),
  };
}

export default connect(mapStateToProps)(UpgradeButton);
