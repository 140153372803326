import React, { useState } from "react";
import { FieldArray } from "formik";
import {
  Add as AddIcon,
  Alert as AlertIcon,
  Duplicate as DuplicateIcon,
  Edit as EditIcon,
  Trash as TrashIcon,
} from "grommet-icons";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { DecimalFormField } from "components/Common/Inputs";
import { GhostButton, Text, Tooltip } from "components/Common";
import CurrencyDecimal from "components/Formating/CurrencyDecimal";
import { dateTimeFormatWithSeconds, formatDate } from "common/formatters";
import { AVCO } from "common/constants/computationTypes";
import { useScreenSize } from "hooks";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../Styles/Forms.module.scss";
import Delimiter from "components/Common/Delimiter";
import { ActionMenu } from "components/Common/ActionMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faArrowUpRightFromSquare, faCheck } from "@fortawesome/free-solid-svg-icons";
import { openInNewTab } from "common/utils";

const validateRow = async (values, index, validate, setTouched) => {
  const result = await validate();
  if (result.initialState) {
    setTouched(`initialState[${index}].pair.base`);
    setTouched(`initialState[${index}].pair.quote`);
    setTouched(`initialState[${index}].basePosition.segments.TRADE`);
    setTouched(`initialState[${index}].average.segments.TRADE`);
    setTouched(`initialState[${index}].openingFee.segments.TRADE`);
    return false;
  }
  return true;
};

const PairsInitialState = ({
  currencyOptions,
  values,
  errors,
  isSubmitting,
  formOpenedIndex,
  setFormOpenedIndex,
  setFieldValue,
  setFieldTouched,
  validateForm,
  modalActions,
  onReopen,
  enhancedSelectStyles,
  isEdit,
  openDeleteAllPairs,
  openDeleteSourcePtf,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const isAvco = values.computationType?.value === AVCO;
  const isEmpty =
    (isAvco && values.initialState?.pairStates?.length === 0) || (!isAvco && !values.initialState?.sourcePortfolioId);

  // Preserve original value of row while editing to enable undo
  const [tempRowValues, setTempRowValues] = useState(null);

  const editSourcePtf = () =>
    isAvco
      ? modalActions.openSelectPortfolioAvcoPredecessor(onReopen, isEdit, values.name, values.initialState?.sourcePortfolioId)
      : modalActions.openSelectPortfolioFifoPredecessor(
          onReopen,
          isEdit,
          values.initialState,
          values.computationType?.value,
          values.name
        );

  const sourcePortfolioId = values.initialState?.sourcePortfolioId;

  return (
    <FieldArray
      name="initialState.pairStates"
      render={arrayHelpers => {
        const addAvcoBalance = async () => {
          if (await validateRow(values, formOpenedIndex, validateForm, setFieldTouched)) {
            setFormOpenedIndex(values.initialState.pairStates.length);
            arrayHelpers.push({
              pair: { base: "", quote: "" },
              basePosition: { total: 0, segments: { TRADE: "" } },
              average: { total: 0, segments: { TRADE: "" } },
              openingFee: { total: 0, segments: { TRADE: "" } },
              newRecord: true,
            });
          }
        };

        return (
          <div className={styles.initial_balances}>
            <>
              <div className="d-flex justify-content-between mb-3">
                <b>{t("form.portfolio_detail.source_portfolio")}</b>

                <div className="d-flex align-items-center">
                  <GhostButton onClick={editSourcePtf} icon={<EditIcon />} actionButton />

                  <ActionMenu testId="pairs-menu">
                    <ActionMenu.ItemGroup key={1}>
                      <ActionMenu.Item
                        icon={<EditIcon />}
                        label={t("form.portfolio_detail.edit_portfolio")}
                        onClick={editSourcePtf}
                        testId="action-edit-source-ptf"
                      />

                      <ActionMenu.Item
                        icon={<AddIcon />}
                        label={t("form.portfolio_detail.add_balance")}
                        disabled={isSubmitting || formOpenedIndex !== null}
                        onClick={addAvcoBalance}
                        testId="action-add-initial-balance"
                        hidden={!isAvco}
                      />

                      <ActionMenu.Item
                        icon={<FontAwesomeIcon icon={faArrowsRotate} />}
                        label={t("form.portfolio_detail.refresh_balances")}
                        disabled
                        testId="action-refresh-balances"
                      />

                      <ActionMenu.Item
                        icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                        label={t("form.portfolio_detail.source_portfolio")}
                        onClick={() => openInNewTab(`/dashboard/${sourcePortfolioId}`)}
                        testId="action-source-ptf"
                        hidden={!sourcePortfolioId}
                      />
                    </ActionMenu.ItemGroup>
                    <ActionMenu.ItemGroup key={2} hasSeparator>
                      <ActionMenu.Item
                        icon={<TrashIcon />}
                        label={t("button.delete_all")}
                        disabled={
                          isSubmitting ||
                          (!sourcePortfolioId &&
                            (!values.initialState?.pairStates || values.initialState?.pairStates.length === 0))
                        }
                        critical
                        onClick={() => {
                          if (isAvco) {
                            openDeleteAllPairs();
                          } else {
                            openDeleteSourcePtf();
                          }
                        }}
                        testId="action-delete-all-pair-balances"
                      />
                    </ActionMenu.ItemGroup>
                  </ActionMenu>
                </div>
              </div>

              <div className="mb-4 mt-2">
                {values.initialState?.portfolio ? (
                  <>
                    {values.initialState?.portfolio?.label || ""}
                    {values.initialState?.timestamp ? ` (${formatDate(values.initialState.timestamp)})` : ""}
                    {values.initialState?.updatedAt && (
                      <>
                        , {isSmall && <br />}
                        {t("form.portfolio_detail.update")}:{" "}
                        {formatDate(values.initialState.updatedAt, dateTimeFormatWithSeconds)}
                      </>
                    )}
                  </>
                ) : isEmpty ? (
                  <GhostButton
                    label={
                      isAvco
                        ? t("form.portfolio_detail.load_from_portfolio_avco")
                        : `${t("form.portfolio_detail.load_from_portfolio_fifo")} ${
                            values.computationType?.value ? `(${values.computationType.value})` : ""
                          }`
                    }
                    onClick={() =>
                      isAvco
                        ? modalActions.openSelectPortfolioAvcoPredecessor(onReopen, isEdit, values.name)
                        : modalActions.openSelectPortfolioFifoPredecessor(
                            onReopen,
                            isEdit,
                            undefined,
                            values.computationType?.value,
                            values.name
                          )
                    }
                    icon={<DuplicateIcon />}
                    className="px-0 mx-0 ml-n1"
                  />
                ) : (
                  "~"
                )}
              </div>
            </>

            {isAvco && !isEmpty ? (
              <>
                <div className={styles.initial_balances_header}>
                  <b>{t("form.portfolio_detail.initial_balances_avco")}</b>
                </div>

                <div
                  className="position-relative"
                  style={{ overflowX: isSmall ? "scroll" : "visible", overflowY: isSmall ? "hidden" : "visible" }}>
                  <div className={styles.initial_balances_grid}>
                    <span className={styles.header} style={{ whiteSpace: "nowrap" }}>
                      {t("form.portfolio_detail.base_quote_column")}
                    </span>
                    <span />
                    <span className={styles.header} style={{ textAlign: "right" }}>
                      {t("form.portfolio_detail.base_amount_column")}
                    </span>
                    <span className={styles.header} style={{ textAlign: "right" }}>
                      {t("form.portfolio_detail.average_cost_column")}
                    </span>
                    <span className={styles.header} style={{ textAlign: "right" }}>
                      {t("form.portfolio_detail.opening_fee_column")}
                    </span>
                    <span className={styles.header} />

                    <Delimiter className={styles.pairs_delimiter} />

                    {values.initialState.pairStates.map((o, i) => {
                      const isRowOpened = formOpenedIndex === i;

                      return (
                        <React.Fragment key={`initialState.pairStates[${i}]`}>
                          {isRowOpened ? (
                            <>
                              <span className="d-flex align-items-center">
                                <CurrencySelectFormField
                                  name={`initialState.pairStates[${i}].pair.base`}
                                  options={currencyOptions}
                                  required
                                  disabled={isSubmitting}
                                  wrapperClassName={cx(styles.small_form_field, styles.small_form_field_currency)}
                                  styles={enhancedSelectStyles}
                                  maxMenuHeight={245}
                                />
                                {!isSmall && <span style={{ marginLeft: "2px" }}>/</span>}
                              </span>
                              <CurrencySelectFormField
                                name={`initialState.pairStates[${i}].pair.quote`}
                                options={currencyOptions}
                                required
                                disabled={isSubmitting}
                                wrapperClassName={cx(styles.small_form_field, styles.small_form_field_currency)}
                                styles={enhancedSelectStyles}
                                maxMenuHeight={245}
                              />
                              <DecimalFormField
                                name={`initialState.pairStates[${i}].basePosition.segments.TRADE`}
                                required
                                disabled={isSubmitting}
                                wrapperClassName={styles.small_form_field}>
                                <span className={styles.post_label}>{o.pair?.base?.value}</span>
                              </DecimalFormField>
                              <DecimalFormField
                                name={`initialState.pairStates[${i}].average.segments.TRADE`}
                                required
                                disabled={isSubmitting}
                                wrapperClassName={styles.small_form_field}>
                                <span className={styles.post_label}>{o.pair?.quote?.value}</span>
                              </DecimalFormField>
                              <DecimalFormField
                                name={`initialState.pairStates[${i}].openingFee.segments.TRADE`}
                                label="test"
                                required
                                disabled={isSubmitting}
                                wrapperClassName={styles.small_form_field}>
                                <span className={styles.post_label}>{o.pair?.quote?.value}</span>
                              </DecimalFormField>
                            </>
                          ) : (
                            <>
                              <div className={styles.label}>
                                <span>{`${o.pair?.base?.value}/${o.pair?.quote?.value}`}</span>
                              </div>
                              <div />
                              <Tooltip tooltipContent={errors.initialState?.pairStates?.[i]?.basePosition?.segments?.TRADE}>
                                <div
                                  className={cx(styles.label, styles.ptf_pair_balances, {
                                    [styles.error_label]: !!errors.initialState?.pairStates?.[i]?.basePosition?.segments?.TRADE,
                                    "negative-value": o.basePosition?.segments?.TRADE < 0,
                                  })}>
                                  <CurrencyDecimal number={parseFloat(o.basePosition.segments?.TRADE)} />
                                  &nbsp;
                                  <div className={cx("pl-1", styles.small_text)}>{o.pair.base?.value}</div>
                                </div>
                              </Tooltip>
                              <Tooltip tooltipContent={errors.initialState?.pairStates?.[i]?.average?.segments?.TRADE}>
                                <div
                                  className={cx(styles.label, styles.ptf_pair_balances, {
                                    [styles.error_label]: !!errors.initialState?.pairStates?.[i]?.average?.segments?.TRADE,
                                    "negative-value": o.average?.segments?.TRADE < 0,
                                  })}>
                                  <CurrencyDecimal number={parseFloat(o.average.segments?.TRADE)} />
                                  &nbsp;
                                  <div className={cx("pl-1", styles.small_text)}>{o.pair.quote?.value}</div>
                                </div>
                              </Tooltip>
                              <Tooltip tooltipContent={errors.initialState?.pairStates?.[i]?.openingFee?.segments?.TRADE}>
                                <div
                                  className={cx(styles.label, styles.ptf_pair_balances, {
                                    [styles.error_label]: !!errors.initialState?.pairStates?.[i]?.openingFee?.segments?.TRADE,
                                    "negative-value": o.openingFee?.segments?.TRADE < 0,
                                  })}>
                                  <CurrencyDecimal number={parseFloat(o.openingFee.segments?.TRADE)} />
                                  &nbsp;
                                  <div className={cx("pl-1", styles.small_text)}>{o.pair.quote?.value}</div>
                                </div>
                              </Tooltip>
                            </>
                          )}

                          <ActionMenu testId={`pair-menu_row-${i}`}>
                            <ActionMenu.ItemGroup key={1}>
                              <ActionMenu.Item
                                icon={<FontAwesomeIcon icon={faCheck} />}
                                label={t("button.save")}
                                onClick={async () => {
                                  if (await validateRow(values, formOpenedIndex, validateForm, setFieldTouched)) {
                                    setFormOpenedIndex(null);
                                  }
                                  const { newRecord, ...rowValues } = values.initialState.pairStates[i];
                                  setFieldValue(`initialState.pairStates[${i}]`, rowValues);
                                }}
                                testId={`save-balance_row-${i}`}
                                hidden={!isRowOpened}
                              />

                              <ActionMenu.Item
                                icon={<EditIcon />}
                                label={t("button.edit")}
                                disabled={formOpenedIndex !== null || isSubmitting}
                                onClick={() => {
                                  setTempRowValues(values.initialState.pairStates[i]);
                                  setFormOpenedIndex(i);
                                }}
                                testId={`edit-balance_row-${i}`}
                                hidden={isRowOpened}
                              />

                              <ActionMenu.Item
                                icon={<AddIcon />}
                                label={t("button.add")}
                                disabled={isSubmitting || formOpenedIndex !== null}
                                onClick={addAvcoBalance}
                                testId={`add-balance_row-${i}`}
                              />
                            </ActionMenu.ItemGroup>
                            <ActionMenu.ItemGroup key={2} hasSeparator>
                              <ActionMenu.Item
                                icon={<TrashIcon />}
                                label={t("button.delete")}
                                critical
                                onClick={() => {
                                  if (values.initialState.pairStates[i].newRecord) {
                                    arrayHelpers.remove(i);
                                  } else {
                                    setFieldValue(`initialState.pairStates[${i}]`, tempRowValues);
                                  }
                                  setTempRowValues(null);
                                  setFormOpenedIndex(null);
                                }}
                                testId={`delete-balance_row-${i}`}
                                hidden={!isRowOpened}
                              />

                              <ActionMenu.Item
                                disabled={formOpenedIndex !== null || isSubmitting}
                                icon={<TrashIcon />}
                                label={t("button.delete")}
                                critical
                                onClick={() => {
                                  if (formOpenedIndex === i) {
                                    setFormOpenedIndex(null);
                                    arrayHelpers.remove(i);
                                  } else {
                                    arrayHelpers.remove(i);
                                    if (formOpenedIndex > i) setFormOpenedIndex(formOpenedIndex - 1);
                                  }
                                }}
                                testId={`delete-balance_row-${i}`}
                                hidden={isRowOpened}
                              />
                            </ActionMenu.ItemGroup>
                          </ActionMenu>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                {errors.hasFifoInitialStateStartDateError && (
                  <Text size="small" color="red">
                    <AlertIcon size="small" color="red" />
                    {!values.range?.from
                      ? t("form.portfolio_detail.fifo_initial_state_error_no_date")
                      : t("form.portfolio_detail.fifo_initial_state_error")}
                  </Text>
                )}
              </>
            )}
          </div>
        );
      }}
    />
  );
};
export default PairsInitialState;
