import React from "react";
import { AxisRight } from "@vx/axis";
import { colors } from "common/colors";
import GraphTick from "./GraphTick";

const heavyFont = "archivoheavy";

export default ({ scale, left, label, ...rest }) => (
  <AxisRight
    scale={scale}
    top={0}
    left={left}
    labelOffset={40}
    label={`[${label}]`}
    labelProps={{
      textAnchor: "middle",
      fontFamily: heavyFont,
      fontSize: 11,
      fill: colors.text,
    }}
    stroke={colors.text}
    hideAxisLine
    hideTicks
    numTicks={5}
    tickTextFill={colors.text}
    tickFormat={number => number}
    tickComponent={GraphTick}
    tickLabelProps={(val, i) => ({
      dx: "-0.25em",
      dy: "0.25em",
      textAnchor: "start",
      fontFamily: heavyFont,
      fontSize: 10,
      fill: colors.text,
    })}
    {...rest}
  />
);
