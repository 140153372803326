import React from "react";
import { Link } from "react-router-dom";
import { Checkmark as CheckIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { paymentPlans, ONLINE_PLUS } from "common/constants/paymentPlans";
import InDev from "../Landing/InDev";
import { Heading, PrimaryButton } from "../Common";
import styles from "../Styles/Pricing.module.scss";

export default ({ type, linkTo, isDisabled, isCurrent, onClick }) => {
  const { t, i18n } = useTranslation();
  const paymentTypeInfo = paymentPlans[type];

  if (!paymentTypeInfo) return null;

  const Button = (
    <PrimaryButton
      isLoading={false}
      label={
        paymentTypeInfo.buttonLabel ? t(`${paymentTypeInfo.buttonLabel}`) : t("pricing.get_plan", { plan: paymentTypeInfo.name })
      }
      disabled={isDisabled}
      onClick={linkTo ? null : onClick}
    />
  );

  return (
    <div className={type === ONLINE_PLUS ? styles.plan_middle : styles.plan}>
      {isCurrent && <InDev message={t("pricing.current_plan")} className={styles.current_plan} />}
      <div className={styles.plan_header}>
        <Heading level={4}>{paymentTypeInfo.name}</Heading>
        <Heading level={2}>
          {i18n.exists(paymentTypeInfo.price) ? t(`${paymentTypeInfo.price}`) : paymentTypeInfo.price}
          <span>{t(`${paymentTypeInfo.priceNote}`)}</span>
        </Heading>
      </div>
      <table className={styles.plan_table}>
        <tbody>
          {paymentTypeInfo.lines.map(({ content, disabled }, i) => (
            <tr key={`${type}_${i}`} className={disabled ? styles.disabled_row : ""}>
              <td width="7%">
                <CheckIcon size="small" />
              </td>
              <td width="93%">{t(`${content}`)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {paymentTypeInfo.special_note && <div className={styles.plan_note}>{t(`${paymentTypeInfo.special_note}`)}</div>}
      <div className={styles.plan_button}>
        {linkTo ? (
          <Link
            to={linkTo}
            onClick={e => {
              if (isDisabled || onClick) e.preventDefault();
              if (onClick) onClick();
            }}>
            {Button}
          </Link>
        ) : (
          Button
        )}
      </div>
    </div>
  );
};
