import React from "react";
import { Close as CloseIcon } from "grommet-icons";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { colors } from "common/colors";

import styles from "./Inputs.module.scss";

export const InputWrapper = ({
  disabled = false,
  inline = false,
  plain = false,
  error = null,
  readOnly = false,
  focused = false,
  disableShadow = false,
  className,
  children,
  ...rest
}) => (
  <div
    className={cx(className, {
      [styles.disabled_input]: disabled,
      [styles.readonly_input]: readOnly,
      [styles.inline_input]: inline,
      [styles.error_input]: !!error,
      [styles.input]: !plain,
      [styles.focused]: focused && !plain,
      [styles.disable_shadow]: disableShadow,
    })}
    tabIndex={-1}
    {...rest}>
    {children}
  </div>
);

export const LabelRow = ({ label, error, required }) => {
  const { t, i18n } = useTranslation();

  // possible input examples for error:
  // <label>blabla</label> (react node - not string)
  // validation.required (i18n key)
  // {i18nKey: 'validation.string_min', i18nValues: {min: 6}} (objects with i18n key and values - default translations from /src/common/yup.js)
  // Agreement required (string that is already translated)
  // true (error indication without label)
  const errorLabel = error?.i18nKey // yup localization with parameters
    ? t(error.i18nKey, error.i18nValues)
    : error && typeof error === "string" && i18n.exists(error)
    ? t(error)
    : typeof error === "string"
    ? error
    : null;

  return (
    <div className={styles.label_row} tabIndex={-1}>
      {React.isValidElement(label) && typeof (label !== "string") ? label : <label>{label}</label>}
      {error ? (
        <label className={styles.error}>{errorLabel}</label>
      ) : (
        <label style={{ color: colors.primary }}>{required ? "*" : ""}</label>
      )}
    </div>
  );
};

export const IndicatorContainer = ({ ghost, children }) => (
  <div
    className={cx(styles.indicator_container, {
      [styles.indicator_ghost]: ghost,
    })}>
    {children}
  </div>
);

export const ClearButton = ({ disabled, onClick }) => (
  <span
    onClick={() => {
      if (!disabled) onClick();
    }}
    className={styles.flex_center}>
    <CloseIcon size="smallMedium" className={styles.active_indicator_icon} />
  </span>
);
