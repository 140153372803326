import { bucketService } from "services/bucketService";
import i18n from "i18n";
import { getBucketTransactionsFilter, getRawBucketSelection } from "selectors/bucketSelectors";
import { useDefaultsStore } from "stores/defaultsStore";
import {
  CHANGE_BUCKET_FILTER,
  CLICK_BUCKET,
  DELETE_BUCKET,
  GET_BUCKET_TRANSACTIONS,
  GET_BUCKETS,
  GET_BUCKETS_FILTER_OPTIONS,
  SELECT_MULTIPLE_BUCKETS,
  SET_BUCKET_TRANSACTIONS_FILTER,
  SET_BUCKETS_IN_DELETION,
  SET_COUNTER_TRANSACTIONS,
  SET_LAST_USED_BUCKET,
  UNSELECT_ALL_BUCKETS,
} from "./types";
import { alertActions } from "./alertActions";
import { transactionActions } from "./transactionActions";

function getBuckets() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_BUCKETS,
      payload: {
        promise: bucketService.getBuckets(getState().user),
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function getBucketTransactions() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      buckets: {
        filters: { page, count, portfolioFiltersApplied },
      },
      dataManager: { selectedPortfolio },
      listOfPortfolios: { activePortfolio },
      user,
    } = state;

    // selected buckets selector not used -> If bucketSelection requests is faster than get buckets, then selectedBuckets returns []
    const bucketSelections = getRawBucketSelection(state);
    const selectedBucketIds = Object.keys(bucketSelections)
      .filter(o => bucketSelections[o])
      .map(Number);

    const portfolioId = selectedPortfolio && portfolioFiltersApplied ? activePortfolio : null;
    const filter = getBucketTransactionsFilter(state);
    dispatch({
      type: GET_BUCKET_TRANSACTIONS,
      payload: bucketService.getBucketTransactions(user, selectedBucketIds, portfolioId, page, filter, count),
    }).catch(err => {
      // axiosMiddleware provides isCancelled flag
      if (err && !err.isCancelled) alertActions.error(err);
    });

    dispatch(getFilterOptions());
  };
}

function getFilterOptions() {
  return (dispatch, getState) => {
    const bucketSelections = getRawBucketSelection(getState());
    const selectedBucketIds = Object.keys(bucketSelections)
      .filter(o => bucketSelections[o])
      .map(Number);

    if (selectedBucketIds?.length > 0) {
      dispatch({
        type: GET_BUCKETS_FILTER_OPTIONS,
        payload: bucketService.getBucketTransactionsFilterOptions(getState().user, selectedBucketIds),
      });
    }
  };
}

function changeFilterAndFetch(changes) {
  return dispatch => {
    dispatch(changeFilter(changes));
    dispatch(getBucketTransactions());
  };
}

function changeFilter(changes) {
  return {
    type: CHANGE_BUCKET_FILTER,
    payload: changes,
  };
}

function setBucketTransactionsFilter(filteredBy) {
  return {
    type: SET_BUCKET_TRANSACTIONS_FILTER,
    payload: filteredBy,
  };
}

function clickOnBucket(buckets, bucketId, ctrl = false, shift = false) {
  return dispatch => {
    dispatch(changeFilter({ page: 0, count: useDefaultsStore.getState().perPageDefault }));
    dispatch({
      type: CLICK_BUCKET,
      payload: {
        buckets,
        bucketId,
        ctrlDown: ctrl,
        shiftDown: shift,
      },
    });
  };
}

function selectMultipleBuckets(bucketIds) {
  return {
    type: SELECT_MULTIPLE_BUCKETS,
    payload: bucketIds,
  };
}

function unselectAllBuckets() {
  return dispatch => {
    dispatch({ type: UNSELECT_ALL_BUCKETS });
    dispatch(getBucketTransactions());
  };
}

function setBucketsInDeletion(bucketIds) {
  return {
    type: SET_BUCKETS_IN_DELETION,
    payload: bucketIds,
  };
}

function deleteBucket(bucketId) {
  return (dispatch, getState) =>
    dispatch({
      type: DELETE_BUCKET,
      payload: async () => {
        dispatch(setBucketsInDeletion(getState().buckets.bucketsInDeletion.add(bucketId)));
        const data = await bucketService.deleteBucket(bucketId, getState().user);
        dispatch(setBucketsInDeletion(getState().buckets.bucketsInDeletion.delete(bucketId)));
        return data;
      },
      meta: { bucketId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deleteBuckets(bucketIds) {
  return async dispatch => {
    await bucketIds.forEachAsync(async bucketId => {
      await dispatch(deleteBucket(bucketId));
    });
    alertActions.success(i18n.t("alert.success.buckets_deleted"));
    dispatch(getBuckets());
  };
}

function deleteTransactionsFromBucket(bucketId, txIds, async = false) {
  return async (dispatch, getState) => {
    await bucketService.deleteTransactionsFromBucket(bucketId, txIds, getState().user);
    if (!async) {
      dispatch(getBuckets());
      dispatch(transactionActions.getTransactions());
    }
  };
}

function setCounterTransactions(transactions) {
  return {
    type: SET_COUNTER_TRANSACTIONS,
    payload: transactions,
  };
}

function setLastUsedBucket(bucketId) {
  return {
    type: SET_LAST_USED_BUCKET,
    payload: bucketId,
  };
}

export const bucketActions = {
  getBuckets,
  getBucketTransactions,
  clickOnBucket,
  selectMultipleBuckets,
  unselectAllBuckets,
  setBucketsInDeletion,
  deleteBucket,
  deleteBuckets,
  deleteTransactionsFromBucket,
  setCounterTransactions,
  setLastUsedBucket,
  changeFilterAndFetch,
  setBucketTransactionsFilter,
};
