// --- CONTAINERS ACTIONS ---
export const GET_CONTAINERS = "GET_CONTAINERS";
export const GET_CONTAINERS_ASYNCHRONOUS = "GET_CONTAINERS_ASYNCHRONOUS";
export const GET_CONTAINER_DETAIL = "GET_CONTAINER_DETAIL";
export const GET_CONTAINER_DETAIL_ASYNCHRONOUS = "GET_CONTAINER_DETAIL_ASYNCHRONOUS";
export const SET_EXTENDED_CONTAINER_DETAIL = "SET_EXTENDED_CONTAINER_DETAIL";
export const CONTAINER_UPLOAD_INITIAL_REQUEST = "CONTAINER_UPLOAD_INITIAL_REQUEST";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const EXPORT_CONTAINER_CSV = "EXPORT_CONTAINER_CSV";
export const LABEL_TRANSACTIONS = "LABEL_TRANSACTIONS";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const PORTFOLIO_CONTAINER_ASSIGNMENT = "PORTFOLIO_CONTAINER_ASSIGNMENT";
export const CLEAR_ERROR_CONTAINERS = "CLEAR_ERROR_CONTAINERS";
export const CREATE_CONTAINER = "CREATE_CONTAINER";
export const DELETE_CONTAINER = "DELETE_CONTAINER";
export const UPDATE_CONNECTION = "UPDATE_CONNECTION";
export const SET_IS_DATA_MANAGER_LOADING = "SET_IS_DATA_MANAGER_LOADING";
export const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";
export const SET_TRANSACTIONS_FILTER = "SET_TRANSACTIONS_FILTER";
export const SET_IS_CONTAINER_SYNC_ACTIVE = "SET_IS_CONTAINER_SYNC_ACTIVE";
export const SET_ACTIVE_MANUAL_CONTAINER_SYNC_IDS = "SET_ACTIVE_MANUAL_CONTAINER_SYNC_IDS";
export const SET_CONTAINERS_IN_DELETION = "SET_CONTAINERS_IN_DELETION";
export const SET_LAST_USED_CURRENCIES = "SET_LAST_USED_CURRENCIES";

export const CLICK_CONTAINER = "CLICK_CONTAINER";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SELECT_MULTIPLE_CONTAINERS = "SELECT_MULTIPLE_CONTAINERS";
export const UNSELECT_ALL_CONTAINERS = "UNSELECT_ALL_CONTAINERS";
export const CONTAINER_UPLOADED = "CONTAINER_UPLOADED";
export const CONTAINER_EMPHASIZED = "CONTAINER_EMPHASIZED";
export const CONTAINER_UPLOADED_FAILED = "CONTAINER_UPLOADED_FAILED";
export const CHANGE_DATAMANAGER_FILTER = "CHANGE_DATAMANAGER_FILTER";

// --- PORTFOLIO ACTIONS ---
export const GET_PORTFOLIOS = "GET_PORTFOLIOS";
export const GET_SHARED_PORTFOLIO = "GET_SHARED_PORTFOLIO";
export const UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS = "UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS";
export const CREATE_PORTFOLIO = "CREATE_PORTFOLIO";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const GET_PORTFOLIO_HISTORY = "GET_PORTFOLIO_HISTORY";
export const HIDE_TRANSACTIONS = "HIDE_TRANSACTIONS";

export const SWITCH_PORTFOLIO = "SWITCH_PORTFOLIO";
export const SET_SHARED_PORTFOLIO = "SET_SHARED_PORTFOLIO";

export const FETCH_PORTFOLIO_HISTORY = "FETCH_PORTFOLIO_HISTORY";
export const FETCH_PORTFOLIO_TRANSACTIONS = "FETCH_PORTFOLIO_TRANSACTIONS";
export const FETCH_PORTFOLIO_SUMMARY = "FETCH_PORTFOLIO_SUMMARY";
export const GET_PORTFOLIO_BALANCES = "GET_PORTFOLIO_BALANCES";
export const GET_BASE_QUOTE = "GET_BASE_QUOTE";

export const SET_VR_MODE = "SET_VR_MODE";

// --- REPORT ACTIONS ---
export const GET_REPORT_PARAMS = "GET_REPORT_PARAMS";
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const DOWNLOAD_ACCOUNTS_STATEMENT_REPORT = "DOWNLOAD_ACCOUNTS_STATEMENT_REPORT";
export const DOWNLOAD_ACCOUNTING_REPORT = "DOWNLOAD_ACCOUNTING_REPORT";

// --- ORGANIZATION ACTIONS ---
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const GET_ORGANIZATION_INFO = "GET_ORGANIZATION_INFO";
export const EDIT_ORGANIZATION_INFO = "EDIT_ORGANIZATION_INFO";
export const UPDATE_ORGANIZATION_PLAN = "UPDATE_ORGANIZATION_PLAN";
export const UPDATE_TRANSACTIONS_COUNT = "UPDATE_TRANSACTIONS_COUNT";
export const GET_CHART_OF_ACCOUNT = "GET_CHART_OF_ACCOUNT";
export const GET_CHART_OF_ACCOUNT_ACCOUNTS = "GET_CHART_OF_ACCOUNT_ACCOUNTS";
export const GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS = "GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS";
export const GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING = "GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING";
export const GET_ORGANIZATION_RULE_SETS = "GET_ORGANIZATION_RULE_SETS";
export const GET_ORGANIZATION_ACCOUNT_SETTINGS = "GET_ORGANIZATION_ACCOUNT_SETTINGS";

export const GET_ORGANIZATION_USERS = "GET_ORGANIZATION_USERS";
export const ADD_ORGANIZATION_USER = "ADD_ORGANIZATION_USER";
export const EDIT_ORGANIZATION_USER = "EDIT_ORGANIZATION_USER";
export const DELETE_ORGANIZATION_USER = "DELETE_ORGANIZATION_USER";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";

// --- JOBS ACTIONS ---
export const UPDATE_UPLOAD_INFO = "UPDATE_UPLOAD_INFO";
export const GET_CONTAINER_JOB = "GET_CONTAINER_JOB";
export const DELETE_CONTAINER_JOB = "DELETE_CONTAINER_JOB";
export const SET_CONTAINER_JOB_FETCHING = "SET_CONTAINER_JOB_FETCHING";

// --- USER ACTIONS ---
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_FORGOTTEN_PASSWORD = "SET_FORGOTTEN_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const COOKIES_DISABLED = "COOKIES_DISABLED";
export const SET_APPLICATION_PREFERENCES = "SET_APPLICATION_PREFERENCES";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const SWITCH_ORGANIZATION = "SWITCH_ORGANIZATION";
export const GET_USER_MEMBERSHIPS = "GET_USER_MEMBERSHIPS";
export const GET_USER_ORGANIZATIONS = "GET_USER_ORGANIZATIONS";
export const JOIN_ORGANIZATION = "JOIN_ORGANIZATION";
export const LEAVE_ORGANIZATION = "LEAVE_ORGANIZATION";
export const SET_PRESENTATION_MODE = "SET_PRESENTATION_MODE";

// --- MARKET DATA ACTIONS ---
export const GET_EXCHANGES_DATA = "GET_EXCHANGES_DATA";
export const GET_CONNECTORS = "GET_CONNECTORS";
export const GET_GEOGRAPHICS = "GET_GEOGRAPHICS";

// --- GLOBAL EVENTS --- //TODO
export const CONTAINER_ENTER = "CONTAINER_ENTER";
export const CONTAINER_LEAVE = "CONTAINER_LEAVE";
export const CTRL_HIT = "CTRL_HIT";

// --- MODAL ACTIONS ---
export const OPEN_MODAL = "OPEN_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const TOGGLE_TOOLTIPS = "TOGGLE_TOOLTIPS";

// --- WS ACTIONS ---
export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT";
export const WEBSOCKET_SEND = "WEBSOCKET_SEND";
export const WEBSOCKET_OPENED = "WEBSOCKET_OPENED";
export const WEBSOCKET_CLOSED = "WEBSOCKET_CLOSED";
export const WEBSOCKET_PRICE_MESSAGE = "WEBSOCKET_PRICE_MESSAGE";

// --- WALKTHROUGH ACTIONS ---
export const WALKTHROUGH_SETUP = "WALKTHROUGH_SETUP";
export const WALKTHROUGH_START = "WALKTHROUGH_START";
export const WALKTHROUGH_DATAMANAGER_START = "WALKTHROUGH_DATAMANAGER_START";
export const WALKTHROUGH_REPORTS_START = "WALKTHROUGH_REPORTS_START";
export const WALKTHROUGH_ORGANIZATION_START = "WALKTHROUGH_ORGANIZATION_START";
export const WALKTHROUGH_END = "WALKTHROUGH_END";
export const WALKTHROUGH_UPDATE_STEP = "WALKTHROUGH_UPDATE_STEP";

// --- SUPPORT ACTIONS ---
export const SUPPORT_GET_USERS = "SUPPORT_GET_USERS";
export const SUPPORT_GET_ORGANIZATIONS = "SUPPORT_GET_ORGANIZATIONS";
export const SET_TRIAL_PERIOD = "SET_TRIAL_PERIOD";
export const EDIT_ET_USER_DETAIL = "EDIT_ET_USER_DETAIL";

// --- ACCOUNT ACTIONS ---
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL";
export const GET_ACCOUNT_TRANSACTIONS = "GET_ACCOUNT_TRANSACTIONS";
export const OPEN_ACCOUNT_TRANSACTION_DETAIL = "OPEN_ACCOUNT_TRANSACTION_DETAIL";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const SET_IS_DETAIL_FETCHING = "SET_IS_DETAIL_FETCHING";
export const GET_ACCOUNT_STATES = "GET_ACCOUNT_STATES";

// --- BUCKET ACTIONS ---
export const GET_BUCKETS = "GET_BUCKETS";
export const GET_BUCKET_TRANSACTIONS = "GET_BUCKET_TRANSACTIONS";
export const GET_BUCKETS_FILTER_OPTIONS = "GET_BUCKETS_FILTER_OPTIONS";
export const CHANGE_BUCKET_FILTER = "CHANGE_BUCKET_FILTER";
export const SET_BUCKET_TRANSACTIONS_FILTER = "SET_BUCKET_TRANSACTIONS_FILTER";
export const CLICK_BUCKET = "CLICK_BUCKET";
export const SELECT_MULTIPLE_BUCKETS = "SELECT_MULTIPLE_BUCKETS";
export const UNSELECT_ALL_BUCKETS = "UNSELECT_ALL_BUCKETS";
export const SET_BUCKETS_IN_DELETION = "SET_BUCKETS_IN_DELETION";
export const DELETE_BUCKET = "DELETE_BUCKET";
export const SET_COUNTER_TRANSACTIONS = "SET_COUNTER_TRANSACTIONS";
export const SET_LAST_USED_BUCKET = "SET_LAST_USED_BUCKET";

// --- API MANAGEMENT ACTIONS ---
export const GET_PERMISSIONS = "GET_PERMISSIONS";
