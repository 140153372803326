import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { TextFormField, PasswordFormField, CheckBoxFormField } from "components/Common/Inputs";
import { useTranslation, Trans } from "react-i18next";
import { Heading, PrimaryButton, Form } from "components/Common";
import { object, string, boolean, ref } from "yup";
import { passwordRegex } from "utils";
import GoogleAuth from "../GoogleAuth";

import styles from "../Styles/Forms.module.scss";

const SignUpForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        passwordConfirm: "",
        terms_agreed: false,
      }}
      validationSchema={object({
        email: string().email().required(),
        password: string().min(8).max(48).matches(passwordRegex, "validation.password_rules").required(),
        passwordConfirm: string()
          .oneOf([ref("password"), null], "validation.password_match")
          .required(),
        terms_agreed: boolean().oneOf([true], "validation.agreement_required"),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <GoogleAuth googleButtonText={t("form.signup.google_signup")} isSignUp />
          <Heading level={3}>{t("form.signup.or")}</Heading>
          <TextFormField
            name="email"
            label={t("form.signup.email")}
            disabled={isSubmitting}
            required
            autoFocus
            autoComplete="username"
          />
          <PasswordFormField
            name="password"
            label={t("form.signup.password")}
            required
            disabled={isSubmitting}
            autoComplete="new-password"
          />
          <PasswordFormField
            name="passwordConfirm"
            label={t("form.signup.password_confirm")}
            required
            disabled={isSubmitting}
            autoComplete="new-password-confirm"
          />
          <CheckBoxFormField
            name="terms_agreed"
            label={
              isSubmitting ? (
                <span>{t("form.signup.terms_submiting")}</span>
              ) : (
                <span>
                  {/* prettier-ignore */}
                  <Trans i18nKey='form.signup.terms_not_submiting'>I agree to the <Link tabIndex="-1" to='/legal' className={styles.black_link}>Legal &amp; Privacy</Link></Trans>
                </span>
              )
            }
            disabled={isSubmitting}
            required
            wrapperClassName="pb-3 pt-0"
          />
          <div className={styles.button_margin}>
            <PrimaryButton label={t("form.signup.signup")} isLoading={isSubmitting} onClick={handleSubmit} />
          </div>
          <div className={styles.flex_space_between}>
            <Link to={`/login${search}`} className={styles.small_link}>
              {t("form.signup.login")}
            </Link>
            <Link to="/legal" className={styles.small_link}>
              {t("form.signup.legal_privacy")}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
