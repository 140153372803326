import React from "react";
import { Link } from "react-router-dom";
import Anycoin from "img/helpLogos/anycoin.svg";
import Aquanow from "img/helpLogos/aquanow.svg";
import Binance from "img/helpLogos/binance.svg";
import Bitfinex from "img/helpLogos/bitfinex.svg";
import Bitflyer from "img/helpLogos/bitflyer.svg";
import Bitmex from "img/helpLogos/bitmex.svg";
import Bitstamp from "img/helpLogos/bitstamp.svg";
import Bittrex from "img/helpLogos/bittrex.svg";
import Coinbase from "img/helpLogos/coinbase.svg";
import CoinbasePro from "img/helpLogos/coinbasepro.svg";
import Coinmate from "img/helpLogos/coinmate.svg";
import Coinsquare from "img/helpLogos/coinsquare.svg";
import DvChain from "img/helpLogos/dvChain.svg";
import Gateio from "img/helpLogos/gateio.svg";
import GeneralBytes from "img/helpLogos/generalbytes.svg";
import HitBTC from "img/helpLogos/hitbtc.svg";
import HuobiGlobal from "img/helpLogos/huobiglobal.svg";
import Kraken from "img/helpLogos/kraken.svg";
import KuCoin from "img/helpLogos/kucoin.svg";
import Localbitcoins from "img/helpLogos/localbitcoins.svg";
import Okx from "img/helpLogos/okx.svg";
import OpenNode from "img/helpLogos/opennode.svg";
import Paxful from "img/helpLogos/paxful.svg";
import Poloniex from "img/helpLogos/poloniex.svg";
import Shakepay from "img/helpLogos/shakepay.svg";
import Simplecoin from "img/helpLogos/simplecoin.svg";
import Whalebooks from "img/helpLogos/whalebooks.svg";

import GBtxDownload1 from "img/gbTxDownload1.png";
import GBtxDownload2 from "img/gbTxDownload2.png";
import krakenTrades1 from "img/krakenTrades1.png";
import anycoinOrders from "img/anycoinOrders.png";
import binanceTradeHistory1 from "img/binanceTradeHistory1.png";
import binanceTradeHistory2 from "img/binanceTradeHistory2.png";
import binanceTradeHistory3 from "img/binanceTradeHistory3.png";
import bitfinexTrades1 from "img/bitfinexTrades1.png";
import bitfinexTrades2 from "img/bitfinexTrades2.png";
import bitfinexTrades3 from "img/bitfinexTrades3.png";
import bitstampTrades1 from "img/bitstampTrades1.png";
import bitstampTrades2 from "img/bitstampTrades2.png";
import bittrexTrades1 from "img/bittrexTrades1.png";
import bittrexTrades2 from "img/bittrexTrades2.png";
import bitmexTrades1 from "img/bitmexTrades1.png";
import bitflyerTrades1 from "img/bitflyerTrades1.png";
import coinbaseTrades1 from "img/coinbaseTrades1.png";
import coinbaseTrades2 from "img/coinbaseTrades2.png";
import coinbaseproTrades1 from "img/coinbaseproTrades1.png";
import coinbaseproTrades2 from "img/coinbaseproTrades2.png";
import coinbaseproTrades3 from "img/coinbaseproTrades3.png";
import coinsquareTrades1 from "img/coinsquareTrades1.png";
import coinsquareTrades2 from "img/coinsquareTrades2.png";
import huobiGlobalTrades1 from "img/huobiGlobalTrades1.png";
import huobiGlobalTrades2 from "img/huobiGlobalTrades2.png";
import localbitcoinsTrades1 from "img/localbitcoinsTrades1.png";
import localbitcoinsTrades2 from "img/localbitcoinsTrades2.png";
import okxTrades1 from "img/okxTrades1.png";
import openNodeTrades1 from "img/opennodeTrades1.png";
import openNodeTrades2 from "img/opennodeTrades2.png";
import openNodeTrades3 from "img/opennodeTrades3.png";
import openNodeTrades4 from "img/opennodeTrades4.png";
import poloniexTrades1 from "img/poloniexTrades1.png";
import poloniexTrades2 from "img/poloniexTrades2.png";
import paxfulTrades from "img/paxfulTrades.png";
import shakepayTrades from "img/shakepayTrades.png";
import whalebooksFormat321 from "img/whalebooksFormat321.png";
import hitbtcTrades1 from "img/hitbtcTrades1.png";
import coinmateTrades1_EN from "img/coinmateTrades1_EN.png";
import coinmateTrades1_CS from "img/coinmateTrades1_CS.png";
import kuCoinTrades1 from "img/kuCoinTrades1.png";
import kuCoinTrades2 from "img/kuCoinTrades2.png";
import kuCoinTrades3 from "img/kuCoinTrades3.png";
import { Trans, useTranslation } from "react-i18next";
import { Download as DownloadIcon } from "grommet-icons/icons";
import sampleFile from "common/assets/WB-v321-sample.csv";
import FileSaver from "file-saver";
import { CZECH, SLOVAK } from "common/constants/languages";
import { useAnchors } from "hooks/useAnchors";
import { GhostButton, Heading } from "../Common";
import styles from "../Styles/Metrics.module.scss";

const Name = ({ children }) => <span className={styles.exchange_name}>{children}</span>;

export default () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const isCzSk = i18n.language === CZECH || i18n.language === SLOVAK;

  useAnchors();

  return (
    <div className={styles.metrics_tab_content}>
      <div className={styles.supported_exchanges}>
        <div className={styles.supported_exchanges_list}>
          <Link to="/help/csv#anycoin">
            <div>
              <img src={Anycoin} alt="AnyCoin" />
            </div>
            <Name>Anycoin</Name>
          </Link>
          <Link to="/help/csv">
            <div>
              <img src={Aquanow} alt="Aquanow" />
            </div>
            <Name>Aquanow</Name>
          </Link>
          <Link to="/help/csv#binance">
            <div>
              <img src={Binance} alt="Binance" />
            </div>
            <Name>Binance</Name>
          </Link>
          <Link to="/help/csv#bitfinex">
            <div>
              <img src={Bitfinex} alt="Bitfinex" />
            </div>
            <Name>Bitfinex</Name>
          </Link>
          <Link to="/help/csv#bitflyer">
            <div>
              <img src={Bitflyer} alt="Bitflyer" />
            </div>
            <Name>bitFlyer</Name>
          </Link>
          <Link to="/help/csv#bitmex">
            <div>
              <img src={Bitmex} alt="Bitmex" />
            </div>
            <Name>BitMEX</Name>
          </Link>
          <Link to="/help/csv#bitstamp">
            <div>
              <img src={Bitstamp} alt="Bitstamp" />
            </div>
            <Name>Bitstamp</Name>
          </Link>
          <Link to="/help/csv#bittrex">
            <div>
              <img src={Bittrex} alt="Bittrex" />
            </div>
            <Name>Bittrex</Name>
          </Link>
          <Link to="/help/csv#coinbase">
            <div>
              <img src={Coinbase} alt="Coinbase" />
            </div>
            <Name>Coinbase</Name>
          </Link>
          <Link to="/help/csv#coinbasepro">
            <div>
              <img src={CoinbasePro} alt="CoinbasePro" />
            </div>
            <Name>Coinbase Pro</Name>
          </Link>
          <Link to="/help/csv#coinmate">
            <div>
              <img src={Coinmate} alt="Coinmate" />
            </div>
            <Name>Coinmate</Name>
          </Link>
          <Link to="/help/csv#coinsquare">
            <div>
              <img src={Coinsquare} alt="Coinsquare" />
            </div>
            <Name>Coinsquare</Name>
          </Link>
          <Link to="/help/csv">
            <div>
              <img src={DvChain} alt="DvChain" />
            </div>
            <Name>DV Chain</Name>
          </Link>
          <Link to="/help/csv">
            <div>
              <img src={Gateio} alt="Gate.io" />
            </div>
            <Name>Gate.io</Name>
          </Link>
          <Link to="/help/csv#generalbytes">
            <div>
              <img src={GeneralBytes} alt="GeneralBytes" />
            </div>
            <Name>General Bytes CAS</Name>
          </Link>
          <Link to="/help/csv#hitbtc">
            <div>
              <img src={HitBTC} alt="HitBTC" />
            </div>
            <Name>HitBTC</Name>
          </Link>
          <Link to="/help/csv#huobi">
            <div>
              <img src={HuobiGlobal} alt="HuobiGlobal" />
            </div>
            <Name>Huobi Global</Name>
          </Link>
          <Link to="/help/csv#kraken">
            <div>
              <img src={Kraken} alt="Kraken" />
            </div>
            <Name>Kraken</Name>
          </Link>
          <Link to="/help/csv#kucoin">
            <div>
              <img src={KuCoin} alt="KuCoin" />
            </div>
            <Name>KuCoin</Name>
          </Link>
          <Link to="/help/csv#localbitcoins">
            <div>
              <img src={Localbitcoins} alt="Localbitcoins" />
            </div>
            <Name>LocalBitcoins</Name>
          </Link>
          <Link to="/help/csv#okx">
            <div>
              <img src={Okx} alt="Okx" />
            </div>
            <Name>OKX</Name>
          </Link>
          <Link to="/help/csv#opennode">
            <div>
              <img src={OpenNode} alt="OpenNode" />
            </div>
            <Name>OpenNode</Name>
          </Link>
          <Link to="/help/csv#paxful">
            <div>
              <img src={Paxful} alt="Paxful" />
            </div>
            <Name>Paxful</Name>
          </Link>
          <Link to="/help/csv#poloniex">
            <div>
              <img src={Poloniex} alt="Poloniex" />
            </div>
            <Name>Poloniex</Name>
          </Link>
          <Link to="/help/csv#shakepay">
            <div>
              <img src={Shakepay} alt="Shakepay" />
            </div>
            <Name>Shakepay</Name>
          </Link>
          <Link to="/help/csv#simplecoin">
            <div>
              <img src={Simplecoin} alt="Simplecoin" />
            </div>
            <Name>Simplecoin</Name>
          </Link>
          <Link to="/help/csv#whalebooks">
            <div>
              <img src={Whalebooks} alt="WhaleBooks" />
            </div>
            <Name>WhaleBooks</Name>
          </Link>
        </div>
      </div>
      <div id="anycoin">
        <Heading level={3}>AnyCoin</Heading>
        <p>{t("metrics.csv_download.anycoin.p1")}</p>
        <img src={anycoinOrders} alt="" />
      </div>
      <div id="binance">
        <Heading level={3}>Binance</Heading>
        <p>{t("metrics.csv_download.binance.p1")}</p>
        <img src={binanceTradeHistory1} alt="" />
        <p>{t("metrics.csv_download.binance.p2")}</p>
        <img src={binanceTradeHistory2} alt="" />
        <p>{t("metrics.csv_download.binance.p3")}</p>
        <img src={binanceTradeHistory3} alt="" />
      </div>
      <div id="bitfinex">
        <Heading level={3}>Bitfinex</Heading>
        <p>{t("metrics.csv_download.bitfinex.p1")}</p>
        <img src={bitfinexTrades1} alt="" />
        <p>{t("metrics.csv_download.bitfinex.p2")}</p>
        <img src={bitfinexTrades2} alt="" />
        <p>{t("metrics.csv_download.bitfinex.p3")}</p>
        <img src={bitfinexTrades3} alt="" />
      </div>
      <div id="bitflyer">
        <Heading level={3}>Bitflyer</Heading>
        <p>{t("metrics.csv_download.bitflyer.p1")}</p>
        <img src={bitflyerTrades1} alt="" />
      </div>
      <div id="bitmex">
        <Heading level={3}>Bitmex</Heading>
        <p>{t("metrics.csv_download.bitmex.p1")}</p>
        <img src={bitmexTrades1} alt="" />
      </div>
      <div id="bitstamp">
        <Heading level={3}>Bitstamp</Heading>
        <p>{t("metrics.csv_download.bitstamp.p1")}</p>
        <img src={bitstampTrades1} alt="" />
        <p>{t("metrics.csv_download.bitstamp.p2")}</p>
        <img src={bitstampTrades2} alt="" />
      </div>
      <div id="bittrex">
        <Heading level={3}>Bittrex</Heading>
        <p>{t("metrics.csv_download.bittrex.p1")}</p>
        <img src={bittrexTrades1} alt="" />
        <p>{t("metrics.csv_download.bittrex.p2")}</p>
        <img src={bittrexTrades2} alt="" />
      </div>
      <div id="coinbase">
        <Heading level={3}>Coinbase</Heading>
        <p>{t("metrics.csv_download.coinbase.p1")}</p>
        <img src={coinbaseTrades1} alt="" />
        <p>{t("metrics.csv_download.coinbase.p2")}</p>
        <img src={coinbaseTrades2} alt="" />
      </div>
      <div id="coinbasepro">
        <Heading level={3}>CoinbasePro</Heading>
        <p>{t("metrics.csv_download.coinbasepro.p1")}</p>
        <img src={coinbaseproTrades1} alt="" />
        <p>{t("metrics.csv_download.coinbasepro.p2")}</p>
        <img src={coinbaseproTrades2} alt="" />
        <p>{t("metrics.csv_download.coinbasepro.p3")}</p>
        <img src={coinbaseproTrades3} alt="" />
      </div>
      <div id="coinmate">
        <Heading level={3}>Coinmate</Heading>
        <p>
          <Trans i18nKey="metrics.csv_download.coinmate">
            {/* eslint-disable-next-line */}
            From the top menu of the{" "}
            <a href={isCzSk ? "https://coinmate.io/cs" : "https://coinmate.io/en"} rel="noopener noreferrer" target="_blank">
              Coinmate
            </a>{" "}
            exchange, go to Resources and select History. To export transactions, click CSV All.
          </Trans>
        </p>
        <img src={isCzSk ? coinmateTrades1_CS : coinmateTrades1_EN} alt="" />
      </div>
      <div id="coinsquare">
        <Heading level={3}>Coinsquare</Heading>
        <p>{t("metrics.csv_download.coinsquare.p1")}</p>
        <img src={coinsquareTrades1} alt="" />
        <p>{t("metrics.csv_download.coinsquare.p2")}</p>
        <img src={coinsquareTrades2} alt="" />
      </div>
      <div id="whalebooks">
        <Heading level={3}>WhaleBooks</Heading>
        <p>{t("metrics.csv_download.whalebooks.heading")}</p>
        <div>
          <p>{t("metrics.csv_download.whalebooks.p31")}</p>
          <p>
            <GhostButton
              onClick={() => FileSaver.saveAs(sampleFile, "WB-v32-sample.csv")}
              label={t("metrics.csv_download.whalebooks.p31_sample")}
              icon={<DownloadIcon />}
              className="m-0 ml-n2"
            />
          </p>
          <img src={whalebooksFormat321} alt="" />
          <p dangerouslySetInnerHTML={{ __html: t("metrics.csv_download.whalebooks.p31_description") }} />
        </div>
      </div>
      <div id="generalbytes">
        <Heading level={3}>General Bytes</Heading>
        <p>{t("metrics.csv_download.general_bytes.p1")}</p>
        <img src={GBtxDownload1} alt="" />
        <p>{t("metrics.csv_download.general_bytes.p2")}</p>
        <img src={GBtxDownload2} alt="" />
      </div>
      <div id="hitbtc">
        <Heading level={3}>HitBTC</Heading>
        <p>{t("metrics.csv_download.hitbtc")}</p>
        <img src={hitbtcTrades1} alt="" />
      </div>
      <div id="huobi">
        <Heading level={3}>HuobiGlobal</Heading>
        <p>{t("metrics.csv_download.huobiglobal.p1")}</p>
        <img src={huobiGlobalTrades1} alt="" />
        <p>{t("metrics.csv_download.huobiglobal.p2")}</p>
        <img src={huobiGlobalTrades2} alt="" />
      </div>
      <div id="kraken">
        <Heading level={3}>Kraken</Heading>
        <p dangerouslySetInnerHTML={{ __html: t("metrics.csv_download.kraken.p1") }} />
        <img src={krakenTrades1} alt="" />
      </div>
      <div id="kucoin">
        <Heading level={3}>KuCoin</Heading>
        <img src={kuCoinTrades1} alt="" className="mb-3" />
        <img src={kuCoinTrades2} alt="" className="mb-3" />
        <img src={kuCoinTrades3} alt="" />
      </div>
      <div id="localbitcoins">
        <Heading level={3}>Localbitcoins</Heading>
        <p>{t("metrics.csv_download.localbitcoins.p1")}</p>
        <img src={localbitcoinsTrades1} alt="" />
        <p>{t("metrics.csv_download.localbitcoins.p2")}</p>
        <img src={localbitcoinsTrades2} alt="" />
      </div>
      <div id="okx">
        <Heading level={3}>Okx</Heading>
        <p>{t("metrics.csv_download.okx")}</p>
        <img src={okxTrades1} alt="" />
      </div>
      <div id="opennode">
        <Heading level={3}>OpenNode</Heading>
        <p>{t("metrics.csv_download.opennode.p1")}</p>
        <img src={openNodeTrades1} alt="" />
        <p>{t("metrics.csv_download.opennode.p2")}</p>
        <img src={openNodeTrades2} alt="" />
        <p>{t("metrics.csv_download.opennode.p3")}</p>
        <img src={openNodeTrades3} alt="" />
        <p>{t("metrics.csv_download.opennode.p4")}</p>
        <img src={openNodeTrades4} alt="" />
        <p>{t("metrics.csv_download.opennode.p5")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("metrics.csv_download.opennode.p6") }} />
      </div>
      <div id="paxful">
        <Heading level={3}>Paxful</Heading>
        <p>{t("metrics.csv_download.paxful")}</p>
        <img src={paxfulTrades} alt="" />
      </div>
      <div id="poloniex">
        <Heading level={3}>Poloniex</Heading>
        <p>{t("metrics.csv_download.poloniex.p1")}</p>
        <img src={poloniexTrades1} alt="" />
        <p>{t("metrics.csv_download.poloniex.p2")}</p>
        <img src={poloniexTrades2} alt="" />
      </div>
      <div id="shakepay">
        <Heading level={3}>Shakepay</Heading>
        <p>{t("metrics.csv_download.shakepay")}</p>
        <img src={shakepayTrades} alt="" />
      </div>
    </div>
  );
};
