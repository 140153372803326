import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "components/Styles/Dashboard.module.scss";
import { GhostButton, LoaderOverlay, Table } from "components/Common";
import { Trash as TrashIcon } from "grommet-icons/icons";
import { dateTimeFormat, formatDate } from "common/formatters";
import { getActiveOrganizationData, getGlobalFetchingState } from "selectors";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { apiManagementActions } from "actions/apiManagementActions";
import { confirmationActions } from "actions/confirmationActions";

const Permissions = ({
  permissions,
  isLoading,
  apiManagementActions: { getPermissions },
  confirmationActions: { openDeleteApiManagementPermissionConfirmation },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const columns = [
    {
      Header: t("api_management.table_header.authorised_object"),
      accessor: "name",
    },
    {
      Header: t("api_management.table_header.permissions"),
      Cell: () => "Read & Write",
      id: "permissions",
    },
    {
      Header: t("api_management.table_header.valid_from"),
      Cell: ({ value }) => formatDate(value, dateTimeFormat),
      accessor: "createdAt",
    },
    {
      Header: () => <div style={{ textAlign: "right" }}>{t("api_management.table_header.actions")}</div>,
      Cell: ({ row: { original } }) => (
        <div style={{ textAlign: "right", float: "right", overflow: "hidden" }}>
          <GhostButton
            icon={<TrashIcon />}
            onClick={() => openDeleteApiManagementPermissionConfirmation(original)}
            actionButton
          />
        </div>
      ),
      id: "action",
    },
  ];

  return (
    <LoaderOverlay isLoading={isLoading}>
      <Table data={permissions} columns={columns} getRowId={row => row.id} />
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.apiManagement.permissions,
    isLoading: state.apiManagement.isPermissionsFetching && !getGlobalFetchingState(state),
    organizationData: getActiveOrganizationData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    apiManagementActions: bindActionCreators(apiManagementActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
