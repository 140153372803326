import {
  GET_REPORT_PARAMS,
  DOWNLOAD_REPORT,
  DOWNLOAD_ACCOUNTS_STATEMENT_REPORT,
  DOWNLOAD_ACCOUNTING_REPORT,
} from "actions/types";

const initialState = {
  params: {},
  paramsFetching: {},
  reportsDownloading: {},
  accountsStatementReportDownloading: false,
  accountingReportDownloading: false,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_REPORT_PARAMS}_PENDING`:
      return {
        ...state,
        paramsFetching: {
          ...state.paramsFetching,
          [action.meta.portfolioId]: true,
        },
      };
    case `${GET_REPORT_PARAMS}_FULFILLED`:
      return {
        ...state,
        paramsFetching: {
          ...state.paramsFetching,
          [action.meta.portfolioId]: false,
        },
        params: {
          ...state.params,
          [action.meta.portfolioId]: action.payload,
        },
      };
    case `${GET_REPORT_PARAMS}_REJECTED`:
      return {
        ...state,
        paramsFetching: {
          ...state.paramsFetching,
          [action.meta.portfolioId]: false,
        },
      };
    //= ========================================
    case `${DOWNLOAD_REPORT}_PENDING`:
      return {
        ...state,
        reportsDownloading: {
          ...state.reportsDownloading,
          [action.meta.portfolioId]: {
            ...state.reportsDownloading[action.meta.portfolioId],
            [action.meta.type]: true,
          },
        },
      };
    case `${DOWNLOAD_REPORT}_FULFILLED`:
      return {
        ...state,
        reportsDownloading: {
          ...state.reportsDownloading,
          [action.meta.portfolioId]: {
            ...state.reportsDownloading[action.meta.portfolioId],
            [action.meta.type]: false,
          },
        },
      };
    case `${DOWNLOAD_REPORT}_REJECTED`:
      return {
        ...state,
        reportsDownloading: {
          ...state.reportsDownloading,
          [action.meta.portfolioId]: {
            ...state.reportsDownloading[action.meta.portfolioId],
            [action.meta.type]: false,
          },
        },
      };
    //= ========================================
    case `${DOWNLOAD_ACCOUNTS_STATEMENT_REPORT}_PENDING`:
      return {
        ...state,
        accountsStatementReportDownloading: true,
      };
    case `${DOWNLOAD_ACCOUNTS_STATEMENT_REPORT}_FULFILLED`:
      return {
        ...state,
        accountsStatementReportDownloading: false,
      };
    case `${DOWNLOAD_ACCOUNTS_STATEMENT_REPORT}_REJECTED`:
      return {
        ...state,
        accountsStatementReportDownloading: false,
      };
    //= ========================================
    case `${DOWNLOAD_ACCOUNTING_REPORT}_PENDING`:
      return {
        ...state,
        accountingReportDownloading: true,
      };
    case `${DOWNLOAD_ACCOUNTING_REPORT}_FULFILLED`:
      return {
        ...state,
        accountingReportDownloading: false,
      };
    case `${DOWNLOAD_ACCOUNTING_REPORT}_REJECTED`:
      return {
        ...state,
        accountingReportDownloading: false,
      };
    //= ========================================
    default:
      return state;
  }
}
