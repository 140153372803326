import React, { useEffect } from "react";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { userActions } from "actions/userActions";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import HeaderMenu from "components/AppStructure/Header/HeaderMenu";
import BurgerButton from "components/AppStructure/Header/BurgerButton";
import cx from "classnames";
import { getActiveMembership, getIsAccountant, getIsSpectatorMode, getUserMemberships } from "selectors";
import SpectatorBar from "components/AppStructure/SpectatorBar";
import ProfileIcon from "components/AppStructure/Header/ProfileIcon";
import { ReactComponent as WbLogo } from "img/wb-logo.svg";
import { ReactComponent as WbLogoNoText } from "img/wb-logo-notext.svg";
import styles from "./Styles/Header.module.scss";
import { useScreenSize } from "hooks";

const Header = ({
  children = null, // infobar
  user,
  memberships,
  activeMemberShip,
  className,
  activePortfolio,
  modalActions,
  userActions,
  setIsMenuExpanded,
  isMenuExpanded,
  isProfileExpanded,
  setIsProfileExpanded,
  isLanding,
  isSpectatorMode,
  topSpace,
}) => {
  const { isSmall, isMedium } = useScreenSize();

  // Disable body scroll, when menu is open
  useEffect(() => {
    if (isSmall && (isMenuExpanded || isProfileExpanded)) {
      document.documentElement.classList.add("disable_scroll");
    } else {
      document.documentElement.classList.remove("disable_scroll");
    }
  }, [isSmall, isMenuExpanded, isProfileExpanded]);

  const HeaderMenuComponent = () => (
    <HeaderMenu
      isLanding={isLanding}
      isMenuExpanded={isMenuExpanded}
      setIsMenuExpanded={setIsMenuExpanded}
      isProfileExpanded={isProfileExpanded}
      setIsProfileExpanded={setIsProfileExpanded}
      user={user}
      userActions={userActions}
      modalActions={modalActions}
      activePortfolio={activePortfolio}
      memberships={memberships}
      activeMemberShip={activeMemberShip}
      topSpace={topSpace}
    />
  );

  return (
    <div className={cx(isLanding ? styles.header_landing : styles.header, className)}>
      {children}
      {isSpectatorMode && <SpectatorBar />}
      <div className={cx(styles.header_content, { [styles.unauthenticated]: isLanding })} id="navbar-header">
        <Link to={user.isAuthenticated ? `/dashboard/${activePortfolio}` : "/"}>
          {isMedium ? (
            <WbLogoNoText className={styles.header_logo_notext} />
          ) : (
            <WbLogo className={cx(styles.header_logo, { [styles.header_logo_small]: isSmall })} />
          )}
        </Link>
        {isSmall && (
          <span className="d-flex justify-content-end align-items-center">
            <BurgerButton
              toggle={() => {
                setIsProfileExpanded(false);
                setIsMenuExpanded(prevState => !prevState);
              }}
              expanded={isMenuExpanded}
              isLanding={isLanding}
            />
            {!isLanding && (
              <ProfileIcon
                className={styles.profile_icon}
                onClick={() => {
                  setIsMenuExpanded(false);
                  setIsProfileExpanded(prevState => !prevState);
                }}
                isActive={isProfileExpanded}
              />
            )}
          </span>
        )}
        {!isSmall && <HeaderMenuComponent />}
      </div>

      {isSmall && <HeaderMenuComponent />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    activePortfolio: state.listOfPortfolios.activePortfolio,
    memberships: getUserMemberships(state),
    activeMemberShip: getActiveMembership(state),
    isAccountant: getIsAccountant(state),
    isSpectatorMode: getIsSpectatorMode(state),
    isLanding: !state.user.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
