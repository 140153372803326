import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

const NEW_URL_PARAM = "new";

export const useUrlHelper = () => {
  const { state } = useLocation(); // isEdit
  const { id } = useParams();

  return useMemo(() => {
    const isNew = id === NEW_URL_PARAM;
    const isInitialEdit = state?.isEdit || isNew;
    const numId = Number.parseInt(id);

    return {
      isNew,
      isInitialEdit,
      id: numId,
      state,
    };
  }, [state, id]);
};
