import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { ReactComponent as CloseIcon } from "img/CloseIcon.svg";
import ModalTopButton from "components/Modals/ModalTopButton";
import { withScreenSize } from "hoc";
import { NORMAL, WIDE, ULTRAWIDE, WIDER } from "common/constants/modalWidths";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Heading } from "components/Common";
import cx from "classnames";
import { ReactComponent as QuestionIcon } from "img/QuestionIcon.svg";

import styles from "../Styles/Modals.module.scss";

const widthClassMapper = {
  [NORMAL]: styles.modal_content,
  [WIDE]: styles.modal_content_wide,
  [WIDER]: styles.modal_content_wider,
  [ULTRAWIDE]: styles.modal_content_ultrawide,
};

class ModalEnvelope extends React.Component {
  componentDidMount() {
    document.addEventListener("keydown", this.escHit, false);
    disableBodyScroll();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escHit, false);
    enableBodyScroll();
  }

  escHit = e => {
    const {
      modalActions: { hideModal },
      onClose,
      disableClose,
    } = this.props;

    if (disableClose) return;

    if (e.keyCode === 27) {
      hideModal();
      if (onClose && typeof onClose === "function") onClose();
    }
  };

  render() {
    const {
      children,
      modalActions: { hideModal, toggleTooltips },
      id,
      heading,
      closeIcon = true,
      width = NORMAL,
      onClose,
      tooltipDisplayed,
      description,
      className,
      // hoc withScreemSize prop
      isLarge,
      isMedium,
      isSmall,
      size,
      disableClose = false,
      disableFocus = false,
      ...rest
    } = this.props;

    const hasDescription = React.isValidElement(description);

    return (
      <div id={id} className={styles.modal}>
        <div className={styles.modal_content_wrapper}>
          <div className={cx(widthClassMapper[width], className)} {...rest}>
            <div>
              {closeIcon && (
                <div className="w-100 d-flex justify-content-between">
                  <ModalTopButton
                    icon={<QuestionIcon />}
                    isDisplayed={hasDescription && isLarge}
                    isActive={tooltipDisplayed}
                    onClick={toggleTooltips}
                  />
                  {!disableClose && (
                    <ModalTopButton
                      icon={<CloseIcon />}
                      onClick={() => {
                        hideModal();
                        if (onClose && typeof onClose === "function") onClose();
                      }}
                      isClose
                    />
                  )}
                </div>
              )}
              {heading && (
                <div className={styles.modal_heading_row}>
                  <Heading level={3} margin="0 0.75rem" textAlign="center">
                    {heading}
                  </Heading>
                </div>
              )}

              {/* Focus inside modal component on open */}
              {!disableFocus && <input className={styles.focus_input} autoFocus />}

              {children}
            </div>
            {hasDescription && isLarge && (
              <div className={cx(styles.explanation_wrapper, { [styles.tooltip_active]: tooltipDisplayed })}>{description}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tooltipDisplayed: state.modal.tooltipsDisplayed,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withScreenSize(ModalEnvelope));
