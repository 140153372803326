export const TRADE_CONFIRMATION = "TRADE_CONFIRMATION";
export const TRADING_STATEMENT = "TRADING_STATEMENT";
export const ACCOUNTING_CURRENCY = "ACCOUNTING_CURRENCY";
export const ACTIVITY_STATEMENT = "ACTIVITY_STATEMENT";
export const ACCOUNTS_STATEMENT = "ACCOUNTS_STATEMENT";
export const TAX_TRADING_STATEMENT = "TAX_TRADING_STATEMENT";
export const ACCOUNTING_REPORT = "ACCOUNTING_REPORT";
export const POHODA_ACCOUNTING_REPORT = "POHODA_ACCOUNTING_REPORT";
export const COMPUTATION_DEBUG = "COMPUTATION_DEBUG";

export const reportTypes = [
  TAX_TRADING_STATEMENT,
  TRADE_CONFIRMATION,
  TRADING_STATEMENT,
  ACCOUNTING_CURRENCY,
  ACTIVITY_STATEMENT,
  ACCOUNTS_STATEMENT,
  ACCOUNTING_REPORT,
  COMPUTATION_DEBUG,
];

const t_fake = str => str;

const reportTypesTranslations = {
  [TRADE_CONFIRMATION]: t_fake("reports.report_type.trade_confirmation"),
  [TRADING_STATEMENT]: t_fake("reports.report_type.trading_statement"),
  [ACCOUNTING_CURRENCY]: t_fake("reports.report_type.accounting_currency"),
  [ACTIVITY_STATEMENT]: t_fake("reports.report_type.activity_statement"),
  [ACCOUNTS_STATEMENT]: t_fake("reports.report_type.accounts_statement"),
  [TAX_TRADING_STATEMENT]: t_fake("reports.report_type.tax_trading_statement"),
  [ACCOUNTING_REPORT]: t_fake("reports.report_type.accounting_report"),
  [COMPUTATION_DEBUG]: t_fake("reports.report_type.portfolio_computation"),
};

const reportTypesDescriptions = {
  [TRADE_CONFIRMATION]: t_fake("reports.report_type_description.trade_confirmation"),
  [TRADING_STATEMENT]: t_fake("reports.report_type_description.trading_statement"),
  [ACCOUNTING_CURRENCY]: t_fake("reports.report_type_description.accounting_currency"),
  [ACTIVITY_STATEMENT]: t_fake("reports.report_type_description.activity_statement"),
  [ACCOUNTS_STATEMENT]: t_fake("reports.report_type_description.accounts_statement"),
  [TAX_TRADING_STATEMENT]: t_fake("reports.report_type_description.tax_trading_statement"),
  [ACCOUNTING_REPORT]: t_fake("reports.report_type_description.accounting_report"),
  [COMPUTATION_DEBUG]: t_fake("reports.report_type_description.portfolio_computation"),
};

export const reportTypesTranslator = val => reportTypesTranslations[val] || "";

export const reportDescriptionTranslator = val => reportTypesDescriptions[val] || "";
