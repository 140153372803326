import React from "react";
import styles from "components/Styles/AppStructure.module.scss";
import { ReactComponent as UserIcon } from "img/user-solid.svg";
import cx from "classnames";
import { useScreenSize } from "hooks";

const ProfileIcon = ({ className, isActive, ...props }) => {
  const { isSmall } = useScreenSize();

  return (
    <span
      className={cx(styles.profile_icon, className, {
        [styles.profile_icon_active]: isActive,
        [styles.profile_icon_hover]: !isSmall,
      })}
      {...props}>
      <UserIcon />
    </span>
  );
};

export default ProfileIcon;
