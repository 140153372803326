import React from "react";
import cx from "classnames";
import styles from "pages/Pages.module.scss";
import { Col, Row } from "react-bootstrap";
import { Heading } from "components/Common";
import { Trans, useTranslation } from "react-i18next";
import Delimiter from "components/Common/Delimiter";

const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="mt-3" noGutters>
        <Col xs={12} lg={6} className={cx(styles.border_right, "pr-lg-3")}>
          <Heading level={3}>{t("api_management.settings.api_synchronization.header")}</Heading>
          <Delimiter className="mt-3" />
          <p>
            <Trans
              i18nKey="api_management.settings.api_synchronization.info"
              components={[
                <a
                  key={0}
                  href="https://generalbytes.atlassian.net/wiki/spaces/ESD/pages/2882764860/WhaleBooks+API+for+third+party+access"
                  rel="noopener noreferrer"
                  target="_blank">
                  documentation
                </a>,
              ]}>
              The API is used for bi-directional data exchange with a third-party application. You can use it, for example, if you
              are the developer of such an application. For more information about the API, see the developer documentation.
            </Trans>
          </p>
        </Col>
        <Col xs={12} lg={6} className="mt-5 mt-lg-0 pl-lg-3">
          <Heading level={3}>{t("api_management.settings.api_settings.header")}</Heading>
          <Delimiter className="mt-3" />
          <p>{t("api_management.settings.api_settings.info")}</p>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
