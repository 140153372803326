import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import Delimiter from "components/Common/Delimiter";
import { timeTestTranslator } from "common/constants/timeTestTypes";
import { formatDate } from "common/formatters";
import ListSelect from "components/Common/Inputs/ListSelect";
import cx from "classnames";
import { GhostButton, Heading, LoaderOverlay, PermissionCheck } from "components/Common";
import styles from "components/Organization/Organization.module.scss";
import { getGlobalFetchingState } from "selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { cryptoCryptoTaxationTranslator } from "common/constants/cryptoCryptoTaxationTypes";
import { Add as AddIcon, Edit as EditIcon, Trash as TrashIcon } from "grommet-icons/icons";
import { modalActions } from "actions/modalActions";
import { rewardTimingTranslator } from "common/constants/rewardTimingOptions";
import { formatRowValue, getFilteredRuleSets } from "utils";
import { ADMIN, OWNER } from "common/constants/userPermission";
import { LabelWithTooltip } from "components/Tooltips/LabelWithTooltip";
import { confirmationActions } from "actions/confirmationActions";

const RuleSets = ({
  organizationData,
  activeRuleSet,
  setActiveRuleSet,
  ruleSets,
  isFetching,
  currentUserRole,
  isOtherTaxResidency,
  noRuleSets,
  isActiveRuleSetDefaultOther,
  organizationActions: { getOrganizationRuleSets },
  modalActions: { openEditRewardsModal, openRuleSetDetailModal },
  confirmationActions: { openDeleteRuleSetModal },
}) => {
  const { t } = useTranslation();
  const { id, taxResidency, taxSubject } = organizationData;

  useEffect(() => {
    if (id && taxResidency && taxSubject) {
      getOrganizationRuleSets(organizationData);
    }
    setActiveRuleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // Set initial rule set - valid today, if not available, choose first one
    if (!activeRuleSet) {
      const ruleSetsValidToday = getFilteredRuleSets(ruleSets, new Date(), new Date());
      if (ruleSetsValidToday?.length > 0) {
        setActiveRuleSet(ruleSetsValidToday[ruleSetsValidToday.length - 1]);
      } else if (ruleSets.length > 0) {
        setActiveRuleSet(ruleSets[0]);
      }
      // Reset rule set if residency or subject does not match
    } else if (activeRuleSet) {
      if (activeRuleSet.taxResidency !== taxResidency || activeRuleSet.taxSubject !== taxSubject) {
        setActiveRuleSet(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleSets, activeRuleSet]);

  return (
    <LoaderOverlay isLoading={isFetching}>
      <div data-tour="organization-rule-sets">
        <div className="d-flex justify-content-between">
          <Heading level={3}>{t("organization.rule_set")}</Heading>

          <div className="d-flex">
            <PermissionCheck activeRole={currentUserRole} allowFor={[ADMIN, OWNER]}>
              <GhostButton
                disabled={!isOtherTaxResidency}
                icon={<AddIcon />}
                onClick={() => openRuleSetDetailModal(organizationData, undefined, setActiveRuleSet)}
                actionButton
              />
              <GhostButton
                disabled={!isOtherTaxResidency || noRuleSets || isActiveRuleSetDefaultOther}
                icon={<EditIcon />}
                onClick={() => openRuleSetDetailModal(organizationData, activeRuleSet, setActiveRuleSet)}
                actionButton
              />
              <GhostButton
                disabled={!isOtherTaxResidency || noRuleSets || isActiveRuleSetDefaultOther}
                icon={<TrashIcon />}
                onClick={() => openDeleteRuleSetModal(organizationData, activeRuleSet, setActiveRuleSet)}
                actionButton
              />
            </PermissionCheck>
          </div>
        </div>

        <Delimiter className="mt-3" />
        <div className={styles.organization_info}>
          <div className={cx(styles.organization_tax_rules_grid, styles.align_first)}>
            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.tax_rule_sets")}</div>}
              tooltip={t("organization_tax_rules.tooltips.tax_rule_sets")}
              className="mt-1"
            />
            <div>
              <ListSelect
                value={activeRuleSet ? { label: activeRuleSet?.name, value: activeRuleSet?.name } : undefined}
                onChange={value => setActiveRuleSet(ruleSets.find(x => x.name === value.value))}
                options={ruleSets.map(x => ({ label: x.name, value: x.name }))}
              />
            </div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.effective_from")}</div>}
              tooltip={t("organization_tax_rules.tooltips.effective_from")}
            />
            <div style={{ marginLeft: "8px" }}>{activeRuleSet?.validFrom && formatDate(activeRuleSet?.validFrom)}</div>
          </div>
        </div>

        <Heading level={3} className="mt-5">
          {t("organization.rules")}
        </Heading>
        <Delimiter className="mt-3" />
        <div className={styles.organization_info}>
          <div className={styles.organization_tax_rules_grid}>
            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.accounting_currency")}</div>}
              tooltip={t("organization_tax_rules.tooltips.accounting_currency")}
              style={{ marginBottom: "-14px" }}
            />
            <div style={{ marginLeft: "8px", marginBottom: "-14px" }}>{activeRuleSet?.accountingCurrencies.join(", ")}</div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.cost_basis_method")}</div>}
              tooltip={t("organization_tax_rules.tooltips.cost_basis_method")}
            />
            <div style={{ marginLeft: "8px" }}>{activeRuleSet?.computationMethods.join(", ")}</div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.taxed_crypto")}</div>}
              tooltip={t("organization_tax_rules.tooltips.taxed_crypto")}
            />
            <div style={{ marginLeft: "8px" }}>{t(cryptoCryptoTaxationTranslator(activeRuleSet?.cryptoCryptoTaxation))}</div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.time_test")}</div>}
              tooltip={t("organization_tax_rules.tooltips.time_test")}
            />
            <div style={{ marginLeft: "8px" }}>{t(timeTestTranslator(activeRuleSet?.timeTest))}</div>
          </div>
        </div>
      </div>

      <div data-tour="organization-rewards">
        <div className="d-flex align-items-baseline justify-content-between mt-5">
          <Heading level={3}>{t("organization.rewards.header")}</Heading>
          <GhostButton
            icon={<EditIcon />}
            label={t("organization.edit")}
            onClick={() => openEditRewardsModal(organizationData, activeRuleSet, setActiveRuleSet)}
            className="mr-n2 pr-0"
          />
        </div>
        <Delimiter className="mt-3" />

        <div className="mt-4 mb-2">{t("organization.rewards.annotation")}</div>

        <div className={styles.organization_info}>
          <div className={styles.organization_tax_rules_grid}>
            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.stake_reward")}</div>}
              tooltip={t("organization_tax_rules.tooltips.stake_reward")}
              style={{ marginBottom: "-14px" }}
            />
            <div style={{ marginLeft: "8px", marginBottom: "-14px" }}>
              {formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.STAKING_REWARD)))}
            </div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.reward")}</div>}
              tooltip={t("organization_tax_rules.tooltips.reward")}
            />
            <div style={{ marginLeft: "8px" }}>
              {formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.REWARD)))}
            </div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.airdrop")}</div>}
              tooltip={t("organization_tax_rules.tooltips.airdrop")}
            />
            <div style={{ marginLeft: "8px" }}>
              {formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.AIRDROP)))}
            </div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.earn")}</div>}
              tooltip={t("organization_tax_rules.tooltips.earn")}
            />
            <div style={{ marginLeft: "8px" }}>
              {formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.EARNING)))}
            </div>

            <LabelWithTooltip
              label={<div className={styles.column_name}>{t("organization.fork")}</div>}
              tooltip={t("organization_tax_rules.tooltips.fork")}
            />
            <div style={{ marginLeft: "8px" }}>
              {formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.FORK)))}
            </div>
          </div>
        </div>
      </div>
    </LoaderOverlay>
  );
};

function mapStateToProps(state, { isOrganizationDataFetching }) {
  return {
    isFetching: !getGlobalFetchingState(state) && (isOrganizationDataFetching || state.organizations.ruleSetsFetching),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RuleSets);
