import { createSelector } from "reselect";
import { getActivePortfolioId } from "./portfoliosSelectors";

export const getParams = state => state.reports.params;

export const getParamsFetching = state => state.reports.paramsFetching;

export const getReportsDownloading = state => state.reports.reportsDownloading;

export const getOpenedPortfolioReportParams = createSelector(
  [getParams, getActivePortfolioId, getReportsDownloading],
  (originalParams, portfolioId, reportsDownloading) => {
    const params = originalParams[portfolioId];
    if (!params) return {};

    return {
      ...params,
      reportTypes: params.reportTypes.reduce((res, val) => {
        res[val.name] = {
          visible: val.visible,
          available: val.available,
          isDownloading: reportsDownloading[portfolioId] ? reportsDownloading[portfolioId][val.name] : false,
        };
        return res;
      }, {}),
    };
  }
);

export const getOpenedPortfolioReportParamsFetching = createSelector(
  [getParamsFetching, getActivePortfolioId],
  (paramsFetching, portfolioId) => paramsFetching[portfolioId]
);
