import React from "react";
import { useTranslation } from "react-i18next";
import DynamicTooltip from "./DynamicTooltip";

const FiatsInventoryTooltip = props => {
  const { t } = useTranslation();
  return <DynamicTooltip {...props}>{t("tooltip.dashboard.transactions.fiat_invetory.label")}</DynamicTooltip>;
};

export default FiatsInventoryTooltip;
