import React, { useMemo } from "react";
import { Formik } from "formik";
import { userPermissions, READ, OWNER, userPermissionsTranslator } from "common/constants/userPermission";
import { TextFormField, SelectFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { memberPositionsOptions } from "common/constants/memberPositions";

const OrganizationUserForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const allPermissions = userPermissions.map(o => ({
      value: o,
      label: t(userPermissionsTranslator(o)),
    }));

    return {
      allPermissions,
      permissionOptions: allPermissions.filter(o => o.value !== OWNER),
      positionOptions: memberPositionsOptions(t),
    };
  }, [t]);

  return (
    <Formik
      initialValues={{
        email: "",
        ...initialValues,
        role: options.allPermissions.find(o => o.value === (initialValues?.role || READ)),
        position: options.positionOptions.find(x => x.value === initialValues?.position),
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        email: string().email().required(),
        role: object({
          value: string().required(),
        }),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {!initialValues && (
            <TextFormField name="email" required autoFocus label={t("form.organization_user.email")} disabled={isSubmitting} />
          )}
          <SelectFormField
            name="role"
            label={t("form.organization_user.permission")}
            disabled={isSubmitting || initialValues?.role === OWNER}
            options={options.permissionOptions}
            required
          />
          <SelectFormField
            name="position"
            label={t("form.organization_user.position")}
            disabled={isSubmitting}
            options={options.positionOptions}
          />
          <PrimaryButton
            label={initialValues ? t("form.organization_user.edit") : t("form.organization_user.invite")}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationUserForm;
