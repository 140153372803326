import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import storage from "redux-persist/lib/storage";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import { configureStore } from "@reduxjs/toolkit";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { portfolioActions } from "actions/portfolioActions";
import { userActions } from "actions/userActions";
import { marketDataActions } from "actions/marketDataActions";
import { accountActions } from "actions/accountActions";
import { setAxios } from "middleware/axiosMiddleware";
import { setYupLocales } from "common/yup";
import { organizationActions } from "actions/organizationActions";
import { isProduction } from "utils";
import { transactionActions } from "actions/transactionActions";
import cancellableRequestsMiddleware from "./middleware/cancellableRequestsMiddleware";
import rootReducer from "./reducers";
import App from "./containers/App";
import "./index.scss";
import "./i18n";
import history from "./common/history";

const blacklistUserIsFetching = createTransform((inboundState, key) => {
  if (key === "user") {
    const newUserState = {
      ...inboundState,
      isFetching: false,
      isMembershipsFetching: false,
      unauthorizedCatched: false,
      cookiesDisabled: false,
      setNewPasswordFetching: false,
      registeredEmail: null,
    };

    if (newUserState.organisationId || newUserState.memberships?.some(o => o.organisationId)) {
      // translates organisationId => organizationId & name => name for cached persisted data
      newUserState.organizationId = newUserState.organisationId;
      newUserState.memberships = newUserState.memberships.map(o => ({
        ...o,
        organizationId: o.organisationId,
        name: o.name,
      }));
    }

    return newUserState;
  }
  return inboundState;
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["user"],
  transforms: [blacklistUserIsFetching], // When reloaded at wrong time, isFetching attribute got stuck at 'true'
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
      thunkMiddleware,
      promiseMiddleware,
      cancellableRequestsMiddleware
    ),
  devTools: { trace: true },
});

setAxios(store); // Axios middleware && defaults
setYupLocales(); // Default validation messages localization

if (module.hot) {
  module.hot.accept();
}

document.paypalId =
  document.location.origin === "https://whalebooks.com"
    ? "AXYJxbpXynRuxdFxpvB3rdeJDs4Fw4GnB_VHtURilCKeve_duDeqvnY-rENJ2-Vz5A6_iGEkByLZ9Wj-"
    : "AbIE4jW_tm19JSMywKByfbi9aNbRdwHWW1Y90fJQBcuFMhTCq538oC-Lu104oPIzSCdarlrM4JPsFH4M";

document.mailChimpUserId = "0bbed2eb0e6cb0c55eeb94964";
document.mailChimpAudienceId = "73c8c14560";

// Called on load/reload (not on login)
const persistor = persistStore(store, null, () => {
  const {
    user: { isAuthenticated, organizationId, spectatedUserId },
  } = store.getState();

  if (isAuthenticated) {
    // first actions after load
    // when modifying, also update getTokenAndLoad() in /actions/userActions.js
    store.dispatch(userActions.checkNewUser());
    store.dispatch(marketDataActions.getGeographicInfo());
    store.dispatch(marketDataActions.getExchangeData());
    store.dispatch(portfolioActions.getPortfolioList());
    store.dispatch(organizationActions.getOrganizationInfo(organizationId));
    store.dispatch(organizationActions.updateTransactionsCount());
    store.dispatch(accountActions.getAccounts());
    store.dispatch(transactionActions.getContainers());

    if (!spectatedUserId) {
      // otherwise spectated user's memberships are overwritten
      store.dispatch(userActions.getJoinedOrganizations());
    }
  }
});

// Prevent search engines from indexing non-production environments
if (!isProduction) {
  const meta = document.createElement("meta");
  meta.name = "robots";
  meta.content = "noindex, nofollow";
  document.getElementsByTagName("head")[0].appendChild(meta);
}

// Scroll to designated anchor
history.listen(location => {
  // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
  const { hash } = location;
  if (hash !== "") {
    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset - 40; // Navbar offset
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 0);
  }
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 90000, // data will be cached for 90 seconds by default
    },
  },
});

let container = null;

document.addEventListener("DOMContentLoaded", event => {
  if (!container) {
    container = document.getElementById("root");
    const root = ReactDOM.createRoot(container);
    root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={document.googleClientId}>
            <Router history={history}>
              <QueryClientProvider client={queryClient}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <App />
                </QueryParamProvider>
              </QueryClientProvider>
            </Router>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    );
  }
});
