import React from "react";
import { Link } from "react-router-dom";
import BlockchainBtc from "img/helpLogos/blockchainBtc.svg";
import BlockchainEth from "img/helpLogos/blockchainEth.svg";
import BlockchainLtc from "img/helpLogos/blockchainLtc.svg";
import Binance from "img/helpLogos/binance.svg";
import Bitfinex from "img/helpLogos/bitfinex.svg";
import Bitmex from "img/helpLogos/bitmex.svg";
import Bitstamp from "img/helpLogos/bitstamp.svg";
import Bittrex from "img/helpLogos/bittrex.svg";
import Coinbase from "img/helpLogos/coinbase.svg";
import CoinbasePro from "img/helpLogos/coinbasepro.svg";
import Coinmate from "img/helpLogos/coinmate.svg";
import Gateio from "img/helpLogos/gateio.svg";
import GeneralBytes from "img/helpLogos/generalbytes.svg";
import HuobiGlobal from "img/helpLogos/huobiglobal.svg";
import Kraken from "img/helpLogos/kraken.svg";
import KuCoin from "img/helpLogos/kucoin.svg";
import Okx from "img/helpLogos/okx.svg";
import WhaleBooks from "img/helpLogos/whalebooks.svg";

import gbApi1 from "img/gbApi1.png";
import gbApi2 from "img/gbApi2.png";
import krakenApi1 from "img/krakenApi1.png";
import krakenApi2 from "img/krakenApi2.png";
import krakenApi3 from "img/krakenApi3.png";
import kuCoinApi1 from "img/kuCoinApi1.png";
import kuCoinApi2 from "img/kuCoinApi2.png";
import kuCoinApi3 from "img/kuCoinApi3.png";
import kuCoinApi4 from "img/kuCoinApi4.png";
import kuCoinApi5 from "img/kuCoinApi5.png";
import kuCoinApi6 from "img/kuCoinApi6.png";
import kuCoinApi7 from "img/kuCoinApi7.png";
import coinmateApi1_EN from "img/coinmateApi1_EN.png";
import coinmateApi1_CS from "img/coinmateApi1_CS.png";
import bitstampApi1 from "img/bitstampApi1.png";
import binanceApi1 from "img/binanceApi1.png";
import bitfinexApi1 from "img/bitfinexApi1.png";
import bittrexApi1 from "img/bittrexApi1.png";
import bitmexApi1 from "img/bitmexApi1.png";
import okxApi1 from "img/okxApi1.png";
import huobiglobalApi1 from "img/huobiglobalApi1.png";
import coinbaseproApi1 from "img/coinbaseproApi1.png";
import coinbaseApi1 from "img/coinbaseApi1.png";
import whalebooksApi1 from "img/whalebooksApi1.png";

import { Trans, useTranslation } from "react-i18next";
import { CZECH, SLOVAK } from "common/constants/languages";
import { useAnchors } from "hooks/useAnchors";
import InDev from "../Landing/InDev";

import { Heading } from "../Common";

import styles from "../Styles/Metrics.module.scss";

const ApiLink = ({ disabled, children, name, ...props }) => (
  <Link
    {...props}
    className={disabled ? styles.in_progress : ""}
    onClick={e => {
      if (disabled) e.preventDefault();
    }}>
    <div>
      {disabled && <InDev className={styles.in_progress_label} message="Comming soon" />}
      {children}
    </div>

    <span className={styles.exchange_name}>{name}</span>
  </Link>
);

export default () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const isCzSk = i18n.language === CZECH || i18n.language === SLOVAK;

  useAnchors();

  return (
    <div className={styles.metrics_tab_content}>
      <div className={styles.supported_exchanges}>
        <div className={styles.supported_exchanges_list}>
          <ApiLink to="/help/api" name="BTC Blockchain">
            <img src={BlockchainBtc} alt="BlockchainBtc" />
          </ApiLink>
          <ApiLink to="/help/api" name="ETH Blockchain">
            <img src={BlockchainEth} alt="BlockchainEth" />
          </ApiLink>
          <ApiLink to="/help/api" name="LTC Blockchain">
            <img src={BlockchainLtc} alt="BlockchainLtc" />
          </ApiLink>
          <ApiLink to="/help/api#binance" name="Binance">
            <img src={Binance} alt="Binance" />
          </ApiLink>
          <ApiLink to="/help/api#bitfinex" name="Bitfinex">
            <img src={Bitfinex} alt="Bitfinex" />
          </ApiLink>
          <ApiLink to="/help/api#bitmex" name="BitMEX">
            <img src={Bitmex} alt="Bitmex" />
          </ApiLink>
          <ApiLink to="/help/api#bitstamp" name="Bitstamp">
            <img src={Bitstamp} alt="Bitstamp" />
          </ApiLink>
          <ApiLink to="/help/api#bittrex" name="Bittrex">
            <img src={Bittrex} alt="Bittrex" />
          </ApiLink>
          <ApiLink to="/help/api#coinbase" name="Coinbase">
            <img src={Coinbase} alt="Coinbase" />
          </ApiLink>
          <ApiLink to="/help/api#coinbasepro" name="Coinbase Pro">
            <img src={CoinbasePro} alt="CoinbasePro" />
          </ApiLink>
          <ApiLink to="/help/api#coinmate" name="Coinmate">
            <img src={Coinmate} alt="Coinmate" />
          </ApiLink>
          <ApiLink to="/help/api#gateio" name="Gate.io">
            <img src={Gateio} alt="Gate.io" />
          </ApiLink>
          <ApiLink to="/help/api#generalbytes" name="General Bytes CAS">
            <img src={GeneralBytes} alt="GeneralBytes" />
          </ApiLink>
          <ApiLink to="/help/api#huobiglobal" name="Huobi Global">
            <img src={HuobiGlobal} alt="HuobiGlobal" />
          </ApiLink>
          <ApiLink to="/help/api#kraken" name="Kraken">
            <img src={Kraken} alt="Kraken" />
          </ApiLink>
          <ApiLink to="/help/api#kucoin" name="KuCoin">
            <img src={KuCoin} alt="KuCoin" />
          </ApiLink>
          <ApiLink to="/help/api#okx" name="OKX">
            <img src={Okx} alt="Okx" />
          </ApiLink>
          <ApiLink to="/help/api#whalebooks" name="WhaleBooks">
            <img src={WhaleBooks} alt="WhaleBooks" />
          </ApiLink>
        </div>
      </div>
      <div id="binance">
        <Heading level={3}>Binance</Heading>
        <p>{t("metrics.api_connection.binance.p1")}</p>
        <img src={binanceApi1} alt="" />
      </div>
      <div id="bitfinex">
        <Heading level={3}>Bitfinex</Heading>
        <p>{t("metrics.api_connection.bitfinex.p1")}</p>
        <img src={bitfinexApi1} alt="" />
      </div>
      <div id="bitmex">
        <Heading level={3}>Bitmex</Heading>
        <p>{t("metrics.api_connection.bitmex.p1")}</p>
        <img src={bitmexApi1} alt="" />
      </div>
      <div id="bitstamp">
        <Heading level={3}>Bitstamp</Heading>
        <p>{t("metrics.api_connection.bitstamp.p1")}</p>
        <img src={bitstampApi1} alt="" />
      </div>
      <div id="bittrex">
        <Heading level={3}>Bittrex</Heading>
        <p>{t("metrics.api_connection.bittrex.p1")}</p>
        <img src={bittrexApi1} alt="" />
      </div>
      <div id="coinbase">
        <Heading level={3}>Coinbase</Heading>
        <p>{t("metrics.api_connection.coinbase.p1")}</p>
        <img src={coinbaseApi1} alt="" />
      </div>
      <div id="coinbasepro">
        <Heading level={3}>CoinbasePro</Heading>
        <p>{t("metrics.api_connection.coinbasepro.p1")}</p>
        <img src={coinbaseproApi1} alt="" />
      </div>
      <div id="coinmate">
        <Heading level={3}>Coinmate</Heading>
        <p>
          <Trans i18nKey="metrics.api_connection.coinmate.p1">
            {/* eslint-disable-next-line */}
            In your{" "}
            <a href={isCzSk ? "https://coinmate.io/cs" : "https://coinmate.io/en"} rel="noopener noreferrer" target="_blank">
              Coinmate
            </a>{" "}
            account, go to the drop-down menu in your user profile and select API. Set API permissions for Account Information (A)
            only. In WhaleBooks, create a new Coinbase API connection. Use your kliet ID as your username. Use the Public Key as
            the API Key and the Private Key as the API Secret.
          </Trans>
        </p>
        <img src={isCzSk ? coinmateApi1_CS : coinmateApi1_EN} alt="" />
      </div>
      <div id="whalebooks">
        <Heading level={3}>WhaleBooks</Heading>
        <p>{t("metrics.api_connection.whalebooks.p1")}</p>
        <img src={whalebooksApi1} alt="" />

        <div>
          <p className="mb-1">Connection method to your address</p>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>http method</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>path</td>
                <td>{`<URL>/transactions/query`}</td>
              </tr>
              <tr>
                <td>accepting</td>
                <td>application/x-www-form-urlencoded</td>
              </tr>
              <tr>
                <td>producing</td>
                <td>application/json</td>
              </tr>
            </tbody>
          </table>

          <p className="mb-1">Endpoint should accept and verify these headers:</p>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>API-Key</td>
                <td>users api key</td>
              </tr>
              <tr>
                <td>API-Sign</td>
                <td>{`HMAC_SHA_512("USER_SECRET",  "HTTP_METHOD URL<OPTIONAL_REQUEST_BODY>")`}</td>
              </tr>
            </tbody>
          </table>

          <p className="mb-1">Endpoint should accept these form parameters:</p>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>fromTransaction</td>
                <td>for paging</td>
                <td>from lastTransactionId</td>
              </tr>
              <tr>
                <td>limit</td>
                <td>limits number of results</td>
                <td />
              </tr>
            </tbody>
          </table>

          <p className="mb-1">Response should look like this:</p>
          <pre>
            <code>
              {`"transactions": [
\t\t\t{
\t\t\t\t"uid": "ID",\t\t\t // transaction ID
\t\t\t\t"timestamp": 1642595604,\t // unix timestamp
\t\t\t\t"base": "BTC",\t\t\t // base currency of pair
\t\t\t\t"quote": "USD",\t\t\t // (required only for transaction type - BUY, SELL)
\t\t\t\t"action": "BUY",\t\t // one of BUY, SELL, FEE, REBATE, DEPOSIT, WITHDRAWAL, STAKE, UNSTAKE, STAKING_REWARD, REWARD, AIRDROP, EARNING, FORK
\t\t\t\t"quantity": "1.6551351155",\t // base quantity (BTC here)
\t\t\t\t"volume": "10.1151315187", \t // required only for transaction type - BUY, SELL (in all other cases send "null") quote volume (USD here)
\t\t\t\t"fee": "1",\t\t\t // (optional) fee volume if any
\t\t\t\t"feeCurrency": "USD",\t\t // (optional) fee currency if any
\t\t\t\t"rebate": "0",\t\t\t // (optional) rebate volume if any
\t\t\t\t"rebateCurrency": "USD",\t // (optional) rebate currency if any
\t\t\t\t"addressFrom": "1KZ4...",\t // (optional) address for DEPOSIT and WITHDRAWAL transactions
\t\t\t\t"addressTo": "bc1q...",\t\t // (optional) address for DEPOSIT and WITHDRAWAL transactions
\t\t\t\t"note": "bc1q...",\t\t // (optional) your note
\t\t\t\t"labels": "myFirstLabel:mySecondLabel",\t\t // (optional) multi-label for filtering (colon as separator)
\t\t\t},
\t\t...`}
            </code>
          </pre>
        </div>

        <a
          href="https://github.com/everytrade-io/everytrade-plugins/blob/master/plugin-base/src/main/java/io/everytrade/server/plugin/impl/everytrade/EveryTradeConnector.java "
          target="_blank"
          rel="noreferrer"
          className="pt-3">
          Current specification on GitHub (Java)
        </a>
      </div>
      <div id="generalbytes">
        <Heading level={3}>General Bytes</Heading>
        <p>{t("metrics.api_connection.gb.p1")}</p>
        <img src={gbApi1} alt="" />
        <p>{t("metrics.api_connection.gb.p2")}</p>
        <img src={gbApi2} alt="" />
        <p>{t("metrics.api_connection.gb.p3")}</p>
      </div>
      <div id="huobiglobal">
        <h3>
          <span>Huobi Global</span>
        </h3>
        <p>{t("metrics.api_connection.huobiglobal.p1")}</p>
        <img src={huobiglobalApi1} alt="" />
      </div>
      <div id="kraken">
        <Heading level={3}>Kraken</Heading>
        <p dangerouslySetInnerHTML={{ __html: t("metrics.api_connection.kraken.p1") }} />
        <img src={krakenApi1} alt="" />
        <p>{t("metrics.api_connection.kraken.p2")}</p>
        <img src={krakenApi2} alt="" />
        <p>{t("metrics.api_connection.kraken.p3")}</p>
        <img src={krakenApi3} alt="" />
      </div>
      <div id="kucoin">
        <Heading level={3}>KuCoin</Heading>
        <img src={kuCoinApi1} alt="" className="mb-3" />
        <img src={kuCoinApi2} alt="" className="mb-3" />
        <img src={kuCoinApi3} alt="" className="mb-3" />
        <img src={kuCoinApi4} alt="" className="mb-3" />
        <img src={kuCoinApi5} alt="" className="mb-3" />
        <img src={kuCoinApi6} alt="" className="mb-3" />
        <img src={kuCoinApi7} alt="" />
      </div>
      <div id="okx">
        <Heading level={3}>Okx</Heading>
        <p>{t("metrics.api_connection.okx.p1")}</p>
        <img src={okxApi1} alt="" />
      </div>
    </div>
  );
};
