import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "../Styles/Landing.module.scss";

export default ({ message, className }) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.in_development, className)}>
      <div>{message || t("components.in_development")}</div>
    </div>
  );
};
