import React, { useMemo } from "react";
import {
  CustomFormField,
  DateInput,
  DecimalFormField,
  SelectFormField,
  TextFormField,
  TimeInput,
} from "components/Common/Inputs";
import { LoaderOverlay } from "components/Common";
import { formatThousandsSeparator, formatTime, utc } from "common/formatters";
import { baseQuoteTypes, BUY, isTxSingleCurrency, transactionTypeTranslator } from "common/constants/transactionType";
import { useTranslation } from "react-i18next";
import { disabledDays, isNullOrUndefined } from "utils";
import BigCurrencyDecimal from "components/Formating/BigCurrencyDecimal";
import useDecimalSeparator from "hooks/useDecimalSeparator";
import { SubtransactionFeeRebateFields } from "containers/Forms/Transactions/TransactionTypes/TransactionFormFields";
import { SMALL } from "common/constants/screenSizes";
import { Refresh as RefreshIcon } from "grommet-icons/icons";
import { colors } from "common/colors";
import bigDecimal from "js-big-decimal";
import { DECIMAL_DIGITS, DECIMAL_PLACES } from "components/Common/Inputs/DecimalInput";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../../Styles/Forms.module.scss";
import cx from "classnames";

const BuySellTransactionForm = ({
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
  transactionTypeOptions,
  currencyOptions,
  currencyOptionsWithLastUsed,
  subtransactionType,
  setSubtransactionType,
  rateLoading,
  getRate,
  txType,
  areFurtherInputsDisabled,
  submitButton,
  labelOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();
  const commaAsDecimalSeparator = useDecimalSeparator();

  const unitPricePlaceholder = bigDecimal.round(
    isNullOrUndefined(values.unitPrice) &&
      !isNaN(values.quoteQuantity) &&
      !isNaN(values.baseQuantity) &&
      Number(values.baseQuantity) !== 0
      ? bigDecimal.divide(values.quoteQuantity, values.baseQuantity, DECIMAL_DIGITS)
      : 1,
    DECIMAL_PLACES
  );
  const baseQuantityPlaceholder = bigDecimal.round(
    isNullOrUndefined(values.baseQuantity) &&
      !isNaN(values.quoteQuantity) &&
      !isNaN(values.unitPrice) &&
      Number(values.unitPrice) !== 0
      ? bigDecimal.divide(values.quoteQuantity, values.unitPrice, DECIMAL_DIGITS)
      : 1,
    DECIMAL_PLACES
  );
  const quoteQuantityPlaceholder = bigDecimal.round(
    !values.quoteQuantity && !isNaN(values.unitPrice)
      ? !isNaN(values.baseQuantity)
        ? bigDecimal.multiply(values.baseQuantity, values.unitPrice)
        : bigDecimal.multiply(baseQuantityPlaceholder, values.unitPrice)
      : 1,
    DECIMAL_PLACES
  );

  const baseOptions = useMemo(() => {
    if (currencyOptionsWithLastUsed?.[0].options && currencyOptionsWithLastUsed.length === 2) {
      return [
        {
          ...currencyOptionsWithLastUsed[0],
          options: currencyOptionsWithLastUsed[0].options.filter(x => x.value !== values.quoteCurrency?.value),
        },
        {
          ...currencyOptionsWithLastUsed[1],
          options: currencyOptionsWithLastUsed[1].options.filter(x => x.value !== values.quoteCurrency?.value),
        },
      ];
    }
    return currencyOptionsWithLastUsed.filter(x => x.value !== values.quoteCurrency?.value);
  }, [currencyOptionsWithLastUsed, values.quoteCurrency]);

  const quoteOptions = useMemo(() => {
    if (currencyOptionsWithLastUsed?.[0].options && currencyOptionsWithLastUsed.length === 2) {
      return [
        {
          ...currencyOptionsWithLastUsed[0],
          options: currencyOptionsWithLastUsed[0].options.filter(x => x.value !== values.baseCurrency?.value),
        },
        {
          ...currencyOptionsWithLastUsed[1],
          options: currencyOptionsWithLastUsed[1].options.filter(x => x.value !== values.baseCurrency?.value),
        },
      ];
    }
    return currencyOptionsWithLastUsed.filter(x => x.value !== values.baseCurrency?.value);
  }, [currencyOptionsWithLastUsed, values.baseCurrency]);

  return (
    <>
      <div className={styles.fields_row}>
        <SelectFormField
          name="type"
          options={transactionTypeOptions}
          label={t("form.add_transaction.trade_type")}
          required
          disabled={isSubmitting}
          isClearable
          onSelect={value => {
            const newType = value?.value;

            if (newType && !txType) {
              return;
            }

            if (baseQuoteTypes.includes(newType) && baseQuoteTypes.includes(txType)) {
              return;
            }

            if (isTxSingleCurrency(newType) && isTxSingleCurrency(txType)) {
              return;
            }

            setFieldValue("baseCurrency", "");
            setFieldValue("quoteCurrency", "");
          }}
        />
        <div className={cx(styles.fields_row, styles.base_quote_mobile)}>
          <div className="d-flex align-items-center">
            <CurrencySelectFormField
              name="baseCurrency"
              options={baseOptions}
              label={t("base")}
              required
              disabled={isSubmitting}
              onSelect={val => {
                if (!areFurtherInputsDisabled && values.quoteCurrency) {
                  getRate({ date: values.date, base: val.value, quote: values.quoteCurrency.value });
                }
              }}
            />
            <div
              className="mb-3 ml-1 mr-1 mr-md-0 cursor-pointer p-1"
              style={{ fontSize: "20px" }}
              onClick={() => {
                const { baseCurrency, quoteCurrency, baseQuantity, quoteQuantity } = values;

                if (baseCurrency && quoteCurrency) {
                  setFieldValue("baseCurrency", quoteCurrency);
                  setFieldValue("quoteCurrency", baseCurrency);
                  getRate({ date: values.date, base: quoteCurrency?.value, quote: baseCurrency.value });

                  setFieldValue("baseQuantity", quoteQuantity);
                  setFieldValue("quoteQuantity", baseQuantity);
                }
              }}>
              /
            </div>
          </div>
          <CurrencySelectFormField
            name="quoteCurrency"
            options={quoteOptions}
            label={t("quote")}
            required
            disabled={isSubmitting}
            onSelect={val => {
              if (!areFurtherInputsDisabled && values.baseCurrency) {
                getRate({ date: values.date, base: values.baseCurrency.value, quote: val.value });
              }
            }}
          />
        </div>
      </div>
      <CustomFormField
        name="timestamp"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.add_transaction.date")}
        required
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.date}
            disabled={isSubmitting}
            isPlain
            isCustomField
            minDate={disabledDays.minDate}
            maxDate={disabledDays.maxDate}
            onBlur={() => setFieldTouched("timestamp", true)}
            onChange={value => {
              setFieldValue("date", value);
              if (values.baseCurrency && values.quoteCurrency && !areFurtherInputsDisabled) {
                getRate({ date: value, base: values.baseCurrency.value, quote: values.quoteCurrency.value });
              }

              if (!values.timestamp) {
                setFieldValue(
                  "timestamp",
                  utc(values.timestamp ?? undefined).set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                );
              }
            }}
            isClearable
            onClear={() => {
              setFieldValue("date", null);
              setFieldValue("timestamp", null);
            }}
          />
          <TimeInput
            value={formatTime(values.timestamp, true)}
            isPlain
            isCustomField
            enableSeconds
            disabled={isSubmitting}
            className={styles.time}
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestamp",
                utc(values.timestamp ?? undefined).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>

      <div className={styles.fields_row}>
        <LoaderOverlay isLoading={rateLoading} className={styles.price} spinnerSize={SMALL}>
          <DecimalFormField
            name="unitPrice"
            label={
              <span className="d-flex align-items-center">
                <span className="mr-1">{t("form.add_transaction.price")}</span>
                <span title={t("form.add_transaction.refresh_price")} style={{ height: "18px" }}>
                  {values.baseCurrency && values.quoteCurrency && !areFurtherInputsDisabled && (
                    <RefreshIcon
                      color={isSubmitting ? colors.gray3 : undefined}
                      title="Refresh price"
                      style={{ height: "18px" }}
                      onClick={() =>
                        getRate({
                          base: values.baseCurrency.value,
                          quote: values.quoteCurrency.value,
                          date: values.date,
                        })
                      }
                    />
                  )}
                </span>
              </span>
            }
            required
            disabled={isSubmitting || areFurtherInputsDisabled}
            placeholder={
              areFurtherInputsDisabled ? undefined : formatThousandsSeparator(unitPricePlaceholder, commaAsDecimalSeparator)
            }
            onChange={val => {
              if (val !== "" && values.baseQuantity !== "" && values.quoteQuantity !== "") setFieldValue("quoteQuantity", "");
            }}>
            <span className={styles.small_text}>{values.quoteCurrency?.value || ""}</span>
          </DecimalFormField>
        </LoaderOverlay>
        <DecimalFormField
          name="baseQuantity"
          label={t("form.add_transaction.quantity_base")}
          required
          disabled={isSubmitting || areFurtherInputsDisabled}
          placeholder={
            areFurtherInputsDisabled ? undefined : formatThousandsSeparator(baseQuantityPlaceholder, commaAsDecimalSeparator)
          }
          onChange={() => {
            if (values.quoteQuantity !== "" && values.unitPrice !== "") setFieldValue("unitPrice", "");
          }}>
          <span className={styles.small_text}>{values.baseCurrency?.value || ""}</span>
        </DecimalFormField>
      </div>
      <div className={styles.fields_row}>
        <DecimalFormField
          name="quoteQuantity"
          label={t("form.add_transaction.volume")}
          required
          disabled={isSubmitting || areFurtherInputsDisabled}
          placeholder={
            areFurtherInputsDisabled ? undefined : formatThousandsSeparator(quoteQuantityPlaceholder, commaAsDecimalSeparator)
          }
          onChange={val => {
            if (val !== "" && values.baseQuantity !== "" && values.unitPrice !== "") setFieldValue("unitPrice", "");
          }}>
          <span className={styles.small_text}>{values.quoteCurrency?.value || ""}</span>
        </DecimalFormField>
        <TextFormField
          name="note"
          label={t("form.add_transaction.note_tx")}
          disabled={isSubmitting || areFurtherInputsDisabled}
          autoFocus={modalOptions?.focusNote}
        />
      </div>

      <SelectFormField
        name="labels"
        options={labelOptions}
        label={t("form.add_transaction.labels")}
        disabled={isSubmitting || areFurtherInputsDisabled}
        isClearable
        isMulti
        autoFocus={modalOptions?.focusLabels}
      />

      <SubtransactionFeeRebateFields
        values={values}
        txType={txType}
        areFurtherInputsDisabled={areFurtherInputsDisabled}
        isSubmitting={isSubmitting}
        subtransactionType={subtransactionType}
        setSubtransactionType={setSubtransactionType}
        currencyOptions={currencyOptions}
        enableRebate
      />

      <div
        className={
          values.type && values.baseCurrency && values.quoteCurrency && !areFurtherInputsDisabled
            ? styles.tx_info_label
            : styles.form_label_hidden
        }>
        <div>
          <div>
            {`${values.type?.label}: `}
            <BigCurrencyDecimal
              number={values.baseQuantity || baseQuantityPlaceholder}
              roundToFixedCrypto={10}
              currency={values.baseCurrency?.value}
            />
            {` ${values.baseCurrency?.value} @ `}
            <BigCurrencyDecimal
              number={values.unitPrice || unitPricePlaceholder}
              roundToFixedCrypto={10}
              currency={values.quoteCurrency?.value}
            />{" "}
            {values.quoteCurrency?.value}
            {values.type?.value === BUY ? (
              <span className={styles.arrow}>&larr;</span>
            ) : (
              <span className={styles.arrow}>&rarr;</span>
            )}
            <BigCurrencyDecimal
              number={
                values.type?.value === BUY
                  ? values.quoteQuantity || quoteQuantityPlaceholder
                  : values.quoteQuantity || quoteQuantityPlaceholder
              }
              roundToFixedCrypto={10}
              currency={values.quoteCurrency?.value}
            />{" "}
            {values.quoteCurrency?.value}
          </div>
          {subtransactionType && (
            <div>
              {`${t(transactionTypeTranslator(subtransactionType))}: `}
              <BigCurrencyDecimal
                number={values.feeRebate || 0}
                roundToFixedCrypto={10}
                currency={values.feeRebateCurrency?.value}
              />
              {` ${values.feeRebateCurrency?.value || ""}`}
            </div>
          )}
        </div>
      </div>

      {submitButton}
    </>
  );
};

export default BuySellTransactionForm;
