import { createSelector } from "reselect";
import { taxSubjectTranslator } from "common/constants/taxSubjects";
import i18n from "i18n";
import { taxResidencyTranslator } from "common/constants/taxResidencies";
import { getActiveOrganizationId } from "./userSelectors";

export const getRawOrganizationDetails = state => state.organizations.organizationDetails;

export const getRawOrganizationUsers = state => state.organizations.organizationUsers;

export const getRawOrganizationInvoices = state => state.organizations.organizationInvoices;

export const getActiveOrganizationTransactionsCount = state => state.organizations.transactionsCount;
export const getActiveOrganizationTransactionsLimit = state => state.organizations.transactionsLimit;

export const getChartOfAccount = state => state.organizations.chartOfAccount;
export const getChartOfAccountAccounts = state => state.organizations.chartOfAccountAccounts;
export const getChartOfAccountSubAccounts = state => state.organizations.chartOfAccountSubAccounts;
export const getChartOfAccountPreAccounting = state => state.organizations.chartOfAccountPreAccounting;

export const getAllRuleSets = state => state.organizations.ruleSets;

export const getAllAccountSettings = state => state.organizations.accountSettings;

// Rule sets of active organization
export const getRuleSets = createSelector(
  [getAllRuleSets, getActiveOrganizationId],
  (allRuleSets, activeOrganizationId) => allRuleSets[activeOrganizationId] ?? []
);

export const getActiveOrganizationDetail = createSelector(
  [getActiveOrganizationId, getRawOrganizationDetails],
  (organizationId, organizationDetails) => organizationDetails[organizationId] || {}
);

export const getActiveOrganizationData = createSelector(
  [getActiveOrganizationDetail],
  organizationDetail => organizationDetail?.organizationData
);

export const getActiveOrganizationPlan = createSelector(
  [getActiveOrganizationDetail],
  organizationDetail => organizationDetail.organizationData?.plan
);

export const getActiveOrganizationTaxResidency = createSelector([getActiveOrganizationData], organizationData =>
  organizationData?.taxResidency ? i18n.t(taxResidencyTranslator(organizationData.taxResidency)) : "~"
);

export const getActiveOrganizationTaxSubject = createSelector([getActiveOrganizationData], organizationData =>
  organizationData?.taxSubject ? i18n.t(taxSubjectTranslator(organizationData.taxSubject)) : "~"
);

export const getAccountSettings = createSelector(
  [getAllAccountSettings, getActiveOrganizationId],
  (allAccountSettings, activeOrganizationId) => allAccountSettings?.[activeOrganizationId]
);

export const getAccountSettingsConfigValue = (state, configName) => {
  const accountSettings = getAccountSettings(state);
  return accountSettings?.accountSettings?.config?.find(x => x.name === configName)?.value;
};
