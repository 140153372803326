import React from "react";
import { useTranslation } from "react-i18next";
import report1 from "img/helpReports/helpReport1.png";
import report2 from "img/helpReports/helpReport2.png";
import report3_1 from "img/helpReports/helpReport3_1.png";
import report3_2 from "img/helpReports/helpReport3_2.png";
import report3_3 from "img/helpReports/helpReport3_3.png";
import report3_4 from "img/helpReports/helpReport3_4.png";
import report3_5 from "img/helpReports/helpReport3_5.png";
import report4 from "img/helpReports/helpReport4.png";
import report5 from "img/helpReports/helpReport5.png";
import cx from "classnames";
import { Heading } from "../Common";

import styles from "../Styles/Metrics.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.metrics_tab_content}>
      <div>
        <Heading level={3}>
          <span>{t("metrics.reports_help_1.heading")}</span>
        </Heading>
        <p>{t("metrics.reports_help_1.p1")}</p>
        <p>{t("metrics.reports_help_1.p2")}</p>
        <p>{t("metrics.reports_help_1.p3")}</p>
        <p>{t("metrics.reports_help_1.p4")}</p>
        <p>{t("metrics.reports_help_1.p5")}</p>
        <div className={styles.reports_img}>
          <img src={report1} alt="" />
        </div>
      </div>
      <div>
        <Heading level={3}>
          <span>{t("metrics.reports_help_2.heading")}</span>
        </Heading>
        <p>{t("metrics.reports_help_2.p1")}</p>
        <div className={styles.reports_img}>
          <img src={report2} alt="" />
        </div>
      </div>
      <div>
        <Heading level={3}>
          <span>{t("metrics.reports_help_3.heading")}</span>
        </Heading>
        <p>{t("metrics.reports_help_3.p1")}</p>
        <p>{t("metrics.reports_help_3.p2")}</p>
        <p>{t("metrics.reports_help_3.p3")}</p>
        <p>{t("metrics.reports_help_3.p4")}</p>
        <p>{t("metrics.reports_help_3.p5")}</p>
        <div className={cx(styles.reports_img, "mb-3")}>
          <img src={report3_1} alt="" />
          <img src={report3_2} alt="" />
        </div>
        <div className={cx(styles.reports_img, "mb-3")}>
          <img src={report3_3} alt="" />
          <img src={report3_4} alt="" />
        </div>
        <div className={styles.reports_img}>
          <img src={report3_5} alt="" />
        </div>
      </div>
      <div>
        <Heading level={3}>
          <span>{t("metrics.reports_help_4.heading")}</span>
        </Heading>
        <p>{t("metrics.reports_help_4.p1")}</p>
        <div className={styles.reports_img}>
          <img src={report4} alt="" />
        </div>
      </div>
      <div>
        <Heading level={3}>
          <span>{t("metrics.reports_help_5.heading")}</span>
        </Heading>
        <p>{t("metrics.reports_help_5.p1")}</p>
        <div className={styles.reports_img}>
          <img src={report5} alt="" />
        </div>
      </div>
    </div>
  );
};
