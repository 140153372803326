import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Select, { components, createFilter } from "react-select";
import Async from "react-select/async";
import CreatableSelect from "react-select/creatable";
import { useField } from "formik";
import { Close as CloseIcon, Down as DownIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { matchSorter } from "match-sorter";
import { customSelectStyles } from "common/styles/selectStyles";
import { InputWrapper, LabelRow } from "./Shared";
import styles from "./Inputs.module.scss";
import TestInput from "../TestInput";

const defaultAsyncProps = {
  cacheOptions: true,
  defaultOptions: true,
};

const SelectInput = forwardRef(
  (
    {
      label,
      error,
      required,
      components,
      isAsync,
      isCreatable,
      isPlain,
      wrapperClassName,
      isInline,
      onClick,
      disabled,
      maxMenuHeight,
      options: defaultOptions,
      onChange,
      onClear,
      isMulti = false,
      styles,
      name,
      ...props
    },
    ref
  ) => {
    const SelectComponent = isAsync ? Async : isCreatable ? CreatableSelect : Select; // TODO: Memo

    const { t } = useTranslation();
    const inputRef = useRef();
    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current.focus(),
    }));

    const [options, setOptions] = useState(defaultOptions);
    useEffect(() => {
      setOptions(defaultOptions);
    }, [defaultOptions]);

    const isPairSelect = useMemo(() => (options?.length > 0 ? !!(options[0].base && options[0].quote) : false), [options]);
    const onInputChange = isPairSelect
      ? inputValue =>
          setOptions(
            matchSorter(defaultOptions, inputValue.replace("/", ""), {
              keys: ["base", "quote"],
              threshold: matchSorter.rankings.NO_MATCH,
            })
          )
      : undefined;

    // To enable close of menu on click, when already opened by clicking outside of input
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const testInputValue = useMemo(
      () =>
        isMulti
          ? `[${props.value?.map(x => x?.code ?? x?.label)?.join(",")}]`
          : props.getOptionLabel && props.value
          ? props.getOptionLabel(props.value)
          : props.value?.code ?? props.value?.label,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isMulti, props.value, props.code, props.getOptionLabel]
    );

    return (
      <InputWrapper
        className={wrapperClassName}
        disabled={disabled}
        inline={isInline}
        plain={isPlain}
        error={error}
        onClick={e => {
          if (!menuIsOpen && !inputRef.current?.state?.menuIsOpen) {
            setMenuIsOpen(true);
            // Otherwise menu stays open after select (on mobile device)
            inputRef.current.focus();
          } else {
            setMenuIsOpen(false);
          }

          if (onClick && typeof onClick === "function") onClick(e);
        }}>
        {(label || error || required) && <LabelRow label={label} error={error} required={required} />}

        <TestInput name={`${name}_select-value`} value={testInputValue} />

        <SelectComponent
          placeholder={t("components.select.placeholder")}
          styles={{ ...customSelectStyles, ...styles }}
          components={{
            DropdownIndicator,
            ClearIndicator,
            IndicatorSeparator,
            MultiValueRemove,
            Option,
            ...components,
          }}
          maxMenuHeight={maxMenuHeight}
          ref={inputRef}
          isClearable={false}
          openMenuOnFocus
          {...(isAsync ? defaultAsyncProps : {})} // TODO: Causes rerenders?
          isDisabled={disabled}
          closeMenuOnSelect={!isMulti}
          noOptionsMessage={() => t("components.select.no_options")}
          options={options}
          onInputChange={onInputChange}
          filterOption={createFilter({ ignoreAccents: false })}
          windowThreshold={80}
          isMulti={isMulti}
          // menuIsOpen -> to keep dropdown opened
          // autoBlur
          // blurInputOnSelect={true}
          // menuIsOpen={isReadOnly ? false : undefined}
          onChange={(selectedOption, triggeredAction) => {
            if (triggeredAction.action === "clear" && onClear) {
              onClear();
            }
            onChange(selectedOption);
          }}
          id={name}
          tabSelectsValue={false}
          {...props}
        />
      </InputWrapper>
    );
  }
);

export const SelectFormField = forwardRef(({ name, onSelect, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return (
    <SelectInput
      ref={ref}
      {...field}
      onChange={val => {
        if (onSelect && typeof onSelect === "function") onSelect(val);
        helpers.setValue(val);
      }}
      error={meta.touched ? meta.error : ""}
      {...props}
    />
  );
});

const DropdownIndicator = ({ ...props }) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <DownIcon size="smallMedium" className={styles.indicator_icon} />
    </components.DropdownIndicator>
  );

const ClearIndicator = props =>
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <CloseIcon size="smallMedium" className={styles.active_indicator_icon} />
    </components.ClearIndicator>
  );

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <CloseIcon size="smallMedium" className={styles.active_indicator_icon} />
  </components.MultiValueRemove>
);

const IndicatorSeparator = props => null;

const Option = ({ children, ...props }) => {
  const optionLabel = props.data?.code ?? props.data?.label;

  const { isFocused, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: { ...rest, "data-testid": `option_${optionLabel}` } };

  return <components.Option {...newProps}>{children}</components.Option>;
};

export default SelectInput;
