import React from "react";
import AboutBanner from "components/About/AboutBanner";
import AboutTouch from "components/About/AboutTouch";
import styles from "pages/Pages.module.scss";

const About = () => (
  <div>
    <div className={styles.app_content_padding}>
      <AboutBanner />
    </div>
    <AboutTouch />
  </div>
);

export default About;
