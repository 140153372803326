import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Delimiter from "components/Common/Delimiter";
import { useScreenSize } from "hooks";
import Support from "components/AppStructure/Header/Support";
import { getDataTestId } from "utils";
import styles from "../../Styles/AppStructure.module.scss";

const Profile = ({ email, openFeedbackForm, setExpanded, logoutAction, mobileChildren }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <>
      <li className={styles.align_center}>
        <div className={styles.user_email}>{email}</div>
      </li>
      <Support />
      {isSmall && mobileChildren}
      <Delimiter style={{ margin: "var(--dropdown-padding) 0" }} />
      <li className={styles.align_center}>
        <NavLink
          activeClassName={styles.active_link_nested}
          exact
          to="/api-management"
          data-testid={getDataTestId("link", "api-management")}>
          {t("header.menu.api_management")}
        </NavLink>
      </li>
      <Delimiter style={{ margin: "var(--dropdown-padding) 0" }} />
      <li className={styles.align_center}>
        <NavLink activeClassName={styles.active_link_nested} exact to="/profile" data-testid={getDataTestId("link", "profile")}>
          {t("header.menu.profile_settings")}
        </NavLink>
      </li>
      <li className={styles.align_center}>
        <NavLink activeClassName={styles.active_link_nested} exact to="/help" data-testid={getDataTestId("link", "help")}>
          <span className="text-uppercase">{t("header.menu.help")}</span>
        </NavLink>
      </li>
      <li className={styles.align_center}>
        <div
          onClick={() => {
            openFeedbackForm();
            setExpanded(false);
          }}
          data-testid={getDataTestId("link", "contact-us")}>
          {t("header.menu.contact_us")}
        </div>
      </li>
      <Delimiter style={{ margin: "var(--dropdown-padding) 0" }} />
      <li className={styles.align_center}>
        <div onClick={logoutAction} data-testid={getDataTestId("link", "logout")}>
          {t("header.menu.log_out")}
        </div>
      </li>
    </>
  );
};

export default Profile;
