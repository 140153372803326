import React from "react";
import { connect } from "react-redux";
import { Spinner } from "components/Common";
import { getGlobalFetchingState } from "selectors/globalSelectors";
import { LARGE } from "common/constants/screenSizes";
import cx from "classnames";

import styles from "../Styles/Modals.module.scss";

const Loader = ({ isFetching }) =>
  isFetching ? (
    <div id="loader-modal" className={cx(styles.modal_content_wrapper, styles.loader)}>
      <Spinner size={LARGE} />
    </div>
  ) : null;

function mapStateToProps(state) {
  return {
    isFetching: getGlobalFetchingState(state),
  };
}

export default connect(mapStateToProps)(Loader);
