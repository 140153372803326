import React from "react";
import { OWNER } from "common/constants/userPermission";
import { Alert as AlertIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import Delimiter from "components/Common/Delimiter";
import { Trash as TrashIcon } from "grommet-icons/icons";
import { formatDate } from "common/formatters";
import { GhostButton, Heading, LoaderOverlay, Text } from "../Common";

import styles from "./Organization.module.scss";

const OrganizationInfo = ({
  organizationData,
  isFetching,
  currentUserRole,
  userMemberships,
  openDeleteOrganizationConfirmation,
}) => {
  const { t } = useTranslation();

  const ownedOrganizations = userMemberships.filter(o => o.role === OWNER);

  return (
    <LoaderOverlay isLoading={isFetching}>
      <div className={styles.organization_info}>
        <div className={styles.organization_tax_rules_grid}>
          <div>{t("organization.name")}</div>
          <div>{organizationData.name}</div>
          <div>{t("organizations.table_header.created_at")}</div>
          <div>{organizationData.createdAt ? formatDate(organizationData.createdAt) : "~"}</div>
        </div>
        {currentUserRole === OWNER && (
          <>
            <Delimiter />
            <div className="d-flex justify-content-between">
              <Heading level={4}>{t("organization.delete_org")}</Heading>
              <GhostButton
                critical
                label={
                  <div className="d-flex align-items-center">
                    <TrashIcon /> <span className="ml-1">{t("organization.delete")}</span>
                  </div>
                }
                onClick={() => openDeleteOrganizationConfirmation(organizationData)}
                disabled={!organizationData.id || ownedOrganizations.length === 1} // not yet fetched or last owner org
                className="mr-n2 pr-0"
              />
            </div>
          </>
        )}
        {ownedOrganizations.length === 1 && (
          <div>
            <AlertIcon size="small" color="red" />
            <Text margin="xsmall" textAlign="end" size="small">
              {t("organization.last_org")}
            </Text>
          </div>
        )}
      </div>
    </LoaderOverlay>
  );
};

export default OrganizationInfo;
