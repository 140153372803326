import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import styles from "../Styles/Integrations.module.scss";

const IntegrationItem = ({ label, icon, onClick, className }) => (
  <div className={cx(styles.wrapper, className)} onClick={onClick}>
    <div style={{ width: "54px" }}>{icon}</div>
    <div className="my-2 text-center">{label}</div>
    <div className={styles.chevron}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  </div>
);

export default IntegrationItem;
