import React from "react";

import styles from "../Styles/Landing.module.scss";

export default ({ name, link, href, picture, review }) => (
  <div className={styles.customer_review}>
    <img src={picture} alt={name} />
    <div className={styles.name}>{name}</div>
    <a className={styles.black_link} href={href} rel="noopener noreferrer" target="_blank">
      {link}
    </a>
    <div className={styles.review}>{review}</div>
  </div>
);
