import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GhostButton, Heading, PermissionCheck } from "components/Common";
import { ADMIN, OWNER, READ } from "common/constants/userPermission";
import { useUrlHelper } from "hooks";
import { getAllRuleSets, getGlobalFetchingState, getRawOrganizationDetails, getUserMemberships } from "selectors";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import styles from "pages/Pages.module.scss";
import Delimiter from "components/Common/Delimiter";
import cx from "classnames";
import TaxRulesInfo from "components/Organization/TaxRulesInfo";
import RuleSets from "containers/Organizations/TaxRules/RuleSets";
import { OTHER } from "common/constants/taxResidencies";

const TaxRules = ({
  userMemberships,
  organizationDetails,
  globalFetchingState,
  allRuleSets,
  modalActions: { openEditTaxRulesModal },
}) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  const ruleSets = allRuleSets[id] ?? [];
  const noRuleSets = ruleSets.length === 0;

  const currentUserRole = useMemo(() => userMemberships.find(o => o.organizationId === id)?.role || READ, [id, userMemberships]);
  const { organizationData = {}, isOrganizationDataFetching } = organizationDetails[id] || {};

  const isOtherTaxResidency = organizationData.taxResidency === OTHER;

  const [activeRuleSet, setActiveRuleSet] = useState();
  const isActiveRuleSetDefaultOther =
    activeRuleSet?.taxResidency === OTHER &&
    (activeRuleSet?.name === "OT-LP-2000/1/1" || activeRuleSet?.name === "OT-NP-2000/1/1");

  return (
    <Row className="mt-3" noGutters>
      <Col xs={12} lg={6} className={cx(styles.border_right, "pr-lg-3")}>
        <div data-tour="organization-tax-rules">
          <div className="d-flex justify-content-between">
            <Heading level={3}>{t("organization.tax_rules")}</Heading>
            <PermissionCheck activeRole={currentUserRole} allowFor={[ADMIN, OWNER]}>
              <GhostButton
                icon={<EditIcon />}
                label={t("organization.edit")}
                onClick={() => openEditTaxRulesModal(organizationData)}
                className="mr-n2 pr-0"
              />
            </PermissionCheck>
          </div>
          <Delimiter className="mt-3" />
          <TaxRulesInfo organizationData={organizationData} isFetching={isOrganizationDataFetching && !globalFetchingState} />
        </div>
      </Col>

      <Col xs={12} lg={6} className="mt-5 mt-lg-0 pl-lg-3">
        <RuleSets
          organizationData={organizationData}
          isOrganizationDataFetching={isOrganizationDataFetching && !globalFetchingState}
          activeRuleSet={activeRuleSet}
          setActiveRuleSet={setActiveRuleSet}
          ruleSets={ruleSets}
          currentUserRole={currentUserRole}
          isOtherTaxResidency={isOtherTaxResidency}
          noRuleSets={noRuleSets}
          isActiveRuleSetDefaultOther={isActiveRuleSetDefaultOther}
        />
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    userMemberships: getUserMemberships(state),
    organizationDetails: getRawOrganizationDetails(state),
    globalFetchingState: getGlobalFetchingState(state),
    allRuleSets: getAllRuleSets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxRules);
