import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationEnvelope from "components/Confirmation/ConfirmationEnvelope";

const ReloadTransactions = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.reload_transactions.heading")}
      confirmLabel={t("confirmation.reload_transactions.confirm_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.reload_transactions.content" />
      </p>
    </ConfirmationEnvelope>
  );
};

export default ReloadTransactions;
