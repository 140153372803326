import React from "react";
import { GhostButton, Heading, Tooltip } from "components/Common";
import { useTranslation } from "react-i18next";
import { faFolderMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { FormDown as FormDownIcon, FormUp as FormUpIcon } from "grommet-icons/icons";
import { CheckBox } from "grommet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { bucketActions } from "actions/bucketActions";
import { getIsOpenedPortfolioEmpty, getRawOpenedPortfolio } from "selectors";
import styles from "../../Styles/DataManager.module.scss";

const BucketTransactionsHeading = ({
  selectedBucket,
  selectedTransactions,
  openDeleteTransactionsFromBucketConfirmation,
  filter: { isFilterVisible, setIsFilterVisible },
  selectedBuckets,
  buckets: {
    filters: { portfolioFiltersApplied },
  },
  selectedPortfolio,
  openedPortfolio,
  isOpenedPortfolioEmpty,
  bucketActions: { changeFilterAndFetch },
}) => {
  const { t } = useTranslation();

  const filtersApplied = portfolioFiltersApplied && selectedPortfolio && openedPortfolio?.hasFilters && !isOpenedPortfolioEmpty;
  const filtersDisabled = !selectedPortfolio || isOpenedPortfolioEmpty || (selectedPortfolio && !openedPortfolio?.hasFilters);

  return (
    <>
      <Heading level={3}>{t("data_manager.transaction_filter.heading")}</Heading>

      <div className={cx(styles.bucket_tx_heading_grid)}>
        <div className={styles.button_group}>
          {isFilterVisible ? (
            <GhostButton
              label={t("data_manager.transaction_filter.hide")}
              icon={<FormUpIcon />}
              className={styles.filter_button}
              onClick={() => setIsFilterVisible(false)}
              iconRight
            />
          ) : (
            <GhostButton
              label={t("data_manager.transaction_filter.show")}
              icon={<FormDownIcon />}
              onClick={() => setIsFilterVisible(true)}
              className={styles.filter_button}
              disabled={selectedBuckets.length === 0}
              iconRight
            />
          )}
        </div>

        <div className={cx(styles.checkbox, { [styles.checkbox_disabled]: filtersDisabled || selectedBuckets.length === 0 })}>
          <CheckBox
            label={t("data_manager.transaction_filter.apply_ptf_filters")}
            disabled={filtersDisabled}
            checked={filtersApplied ?? false} // possibly undefined
            onChange={e => changeFilterAndFetch({ portfolioFiltersApplied: e.target.checked })}
            className="mb-0"
          />
        </div>

        {selectedTransactions.length >= 1 && (
          <Tooltip tooltipContent={t("data_manager.transaction_filter.delete_from_bucket")}>
            <GhostButton
              icon={<FontAwesomeIcon icon={faFolderMinus} />}
              onClick={() =>
                openDeleteTransactionsFromBucketConfirmation(
                  selectedBucket.id,
                  selectedTransactions.map(x => x.id)
                )
              }
              className={styles.fa_icon}
            />
          </Tooltip>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    buckets: state.buckets,
    selectedPortfolio: state.dataManager.selectedPortfolio,
    openedPortfolio: getRawOpenedPortfolio(state),
    isOpenedPortfolioEmpty: getIsOpenedPortfolioEmpty(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketTransactionsHeading);
