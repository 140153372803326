import React from "react";
import { Tooltip as TooltipComponent, Whisper } from "rsuite";
import "./rsuite-tooltipmodule.scss";

const Tooltip = ({ tooltipContent, children, placement = "top", disabled, ...rest }) =>
  disabled || !tooltipContent ? (
    children
  ) : (
    <Whisper placement={placement} delayShow={200} speaker={<TooltipComponent>{tooltipContent}</TooltipComponent>} {...rest}>
      {children}
    </Whisper>
  );

export default Tooltip;
