import axios from "axios";
import { formatDate } from "common/formatters";

const mapInvite = invite => ({ ...invite, isInvite: true });

async function getOrganizationVaults({ organizationId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults`);
  return { vaultId: response.data.length > 0 ? response.data[0].id : null };
}

async function getOrganizationInfo(organizationId) {
  const response = await axios.get(`organizations/${organizationId}`);
  return response.data;
}

async function createOrganization(organization) {
  const response = await axios.post(`organizations`, organization);
  return response.data;
}

async function editOrganizationInfo(organizationId, organization) {
  const response = await axios.put(`organizations/${organizationId}`, organization);
  return response.data;
}

async function getOrganizationUsers(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/memberships`);
  return response.data;
}

async function getOrganizationInvites(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/invitations?unclaimed-only=true`);
  return response.data.map(mapInvite);
}

async function getOrganizationInvoices(organizationId) {
  return [];
  // const response = await axios.get(
  //   `users`
  // )
  // const response = await new Promise(resolve => setTimeout(() => resolve({data: dummyInvoices}), 1500))
  // return response.data.map(mapIncomingOrganizationInvoice);
}

async function getOrganizationTransactionsCount(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/transactions-count`);
  return response.data;
}

async function addOrganizationUser(organizationId, user) {
  const response = await axios.post(`organizations/${organizationId}/invitations`, user);
  return mapInvite(response.data);
}

async function editOrganizationUser(organizationId, userId, { isInvite, ...user }) {
  const response = await axios.put(`organizations/${organizationId}/${isInvite ? "invitations" : "memberships"}/${userId}`, user);
  return response.data;
}

async function deleteOrganizationUser(organizationId, userId, isInvite) {
  await axios.delete(`organizations/${organizationId}/${isInvite ? "invitations" : "memberships"}/${userId}`);
}

async function deleteOrganization(organizationId) {
  await axios.delete(`organizations/${organizationId}`);
}

async function newSubscription(date, paymentPlan, organizationId) {
  const response = await axios.post(`organizations/${organizationId}/subscriptions`, {
    start: formatDate(date),
    plan: paymentPlan,
  });
  return response.data;
}

async function newSubscriptionVerification(orderId) {
  const response = await axios.post("payments/paypal", orderId, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
  return response.data;
}

async function getOrganizationChartOfAccount(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/accountingaccount/chart-of-accounts`);
  return response.data;
}

async function getOrganizationChartOfAccountAccounts(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/accountingaccount`);
  return response.data;
}

async function getOrganizationChartOfAccountSubAccounts(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/accountingaccount/subaccounts`);
  return response.data;
}

async function editOrganizationChartAccountAccount(organizationId, accountId, accountNumber) {
  const response = await axios.put(`organizations/${organizationId}/accountingaccount/${accountId}`, { accountNumber });
  return response.data;
}

async function editOrganizationChartAccountSubAccount(organizationId, subAccountId, accountNumber) {
  const response = await axios.put(`organizations/${organizationId}/accountingaccount/subaccounts/${subAccountId}`, {
    accountNumber,
  });
  return response.data;
}

async function getOrganizationChartAccountPreAccounting(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/accountingaccount/pre-accounting`);
  return response.data;
}

async function getOrganizationTaxRules(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/tax-rules`);
  return response.data;
}

async function getOrganizationRuleSets(organizationId, taxResidency, taxSubject) {
  const response = await axios.get(`organizations/${organizationId}/computation-parameters/rule-sets`, {
    params: { "tax-residency": taxResidency, "tax-subject": taxSubject },
  });
  return response.data;
}

async function createOrganizationRuleSet(organizationId, values) {
  const response = await axios.post(`organizations/${organizationId}/computation-parameters/rule-sets`, values);
  return response.data;
}

async function updateOrganizationRuleSet(organizationId, values) {
  const response = await axios.put(`organizations/${organizationId}/computation-parameters/rule-sets`, values);
  return response.data;
}

async function deleteOrganizationRuleSet({ id, taxResidency, taxSubject }, { validFrom }) {
  const response = await axios.delete(`organizations/${id}/computation-parameters/rule-sets`, {
    params: {
      "tax-residency": taxResidency,
      "tax-subject": taxSubject,
      "valid-from": validFrom,
    },
  });
  return response.data;
}

async function getOrganizationAccountSettings(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/settings`, {});
  return response.data;
}

async function updateOrganizationAccountSettings(organizationId, values) {
  const response = await axios.put(`organizations/${organizationId}/settings`, values);
  return response.data;
}

async function getOrganizationReferences(organizationId) {
  const response = await axios.get(`organizations/${organizationId}/references`);
  return response.data;
}

async function createOrganizationReference(organizationId, values) {
  const response = await axios.put(`organizations/${organizationId}/references`, { ...values, id: values.id ?? null });
  return response.data;
}

async function updateOrganizationReference(organizationId, values) {
  const response = await axios.post(`organizations/${organizationId}/references`, values);
  return response.data;
}

async function deleteOrganizationReference(organizationId, referenceId) {
  const response = await axios.delete(`organizations/${organizationId}/references/${referenceId}`);
  return response.data;
}

export const organizationService = {
  getOrganizationVaults,
  createOrganization,
  getOrganizationInfo,
  editOrganizationInfo,
  getOrganizationUsers,
  getOrganizationInvites,
  getOrganizationInvoices,
  addOrganizationUser,
  editOrganizationUser,
  deleteOrganizationUser,
  deleteOrganization,
  newSubscription,
  newSubscriptionVerification,
  getOrganizationTransactionsCount,
  getOrganizationChartOfAccount,
  getOrganizationChartOfAccountAccounts,
  getOrganizationChartOfAccountSubAccounts,
  editOrganizationChartAccountAccount,
  editOrganizationChartAccountSubAccount,
  getOrganizationChartAccountPreAccounting,
  getOrganizationTaxRules,
  getOrganizationRuleSets,
  createOrganizationRuleSet,
  updateOrganizationRuleSet,
  deleteOrganizationRuleSet,
  getOrganizationAccountSettings,
  updateOrganizationAccountSettings,
  getOrganizationReferences,
  createOrganizationReference,
  updateOrganizationReference,
  deleteOrganizationReference,
};
