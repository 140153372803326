import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../Common";

import styles from "../Styles/About.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.about_touch}>
      <Heading level={3} margin="0 auto 2rem">
        {t("about_us.get_in_touch")}
      </Heading>
      <div className={styles.paragraph}>
        {t("about_us.send_us_email")}{" "}
        <a href="mailto:support@whalebooks.com" className={styles.black_link}>
          support@whalebooks.com
        </a>
      </div>
    </div>
  );
};
