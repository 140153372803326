import React from "react";
import { formatThousandsSeparator } from "common/formatters";
import useDecimalSeparator from "hooks/useDecimalSeparator";
import bigDecimal from "js-big-decimal";
import { getFiatOptions } from "selectors";
import { connect } from "react-redux";
import { formatToMinDecimals } from "utils";

const BigCurrencyDecimal = ({ number, currency, keepZeros = false, roundToFixedCrypto, roundToFixed, fiatOptions }) => {
  const commaAsDecimalSeparator = useDecimalSeparator();

  const isFiat = fiatOptions.some(x => x.value === currency);

  return number === null || number === undefined || number === "-" ? null : (
    <>
      {formatThousandsSeparator(
        roundToFixed || isFiat
          ? formatToMinDecimals(bigDecimal.round(number, 2))
          : !isFiat && roundToFixedCrypto
          ? bigDecimal.round(number, roundToFixedCrypto)
          : number,
        commaAsDecimalSeparator,
        isFiat || keepZeros
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    fiatOptions: getFiatOptions(state),
  };
}

export default connect(mapStateToProps)(BigCurrencyDecimal);
