import axios from "axios";
import { modalActions } from "actions/modalActions";
import { userActions } from "actions/userActions";
import i18n from "i18n";
import { handleProductVersion } from "utils/version";

export const setAxios = store => {
  axios.defaults.headers.common.Accept = "application/json";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.baseURL = `${document.location.origin}/api/v1/`;

  console.log(`environment: ${process.env.NODE_ENV}`);
  console.log(`endpoint: ${axios.defaults.baseURL}`);

  axios.interceptors.request.use(config => {
    // Checks if the endpoint is used for shared portfolio and adjust the path accordingly
    if (config.getPublicUrl && typeof config.getPublicUrl === "function") {
      const { sharedPortfolioId, sharedPortfolioToken, sharedPortfolioOrganizationId, sharedPortfolioVaultId } =
        store.getState().listOfPortfolios;

      if (sharedPortfolioId !== null) {
        config.url = `public/${
          config.getPublicUrl ? config.getPublicUrl(sharedPortfolioOrganizationId, sharedPortfolioVaultId) : config.url
        }`;
        config.params = {
          ...config.params,
          "sharing-token": sharedPortfolioToken,
        };
      }
    }
    return config;
  });

  axios.interceptors.response.use(
    response => {
      handleProductVersion(response.headers.product);
      return response;
    },
    error => {
      if (axios.isCancel(error) || !error?.response) {
        return Promise.reject({ isCancelled: true });
      }

      if (error?.response.status === 502) {
        return Promise.reject(i18n.t("alert.error.connection_lost"));
      }

      if (error?.response.status === 401) {
        const { unauthorizedCatched } = store.getState().user;
        if (!unauthorizedCatched) {
          store.dispatch(modalActions.hideModal());
          store.dispatch(userActions.logout(true));
          return Promise.reject(i18n.t("alert.error.session_expired"));
        }
        return Promise.reject("");
      }

      if (error?.response.status === 500) {
        return Promise.reject(i18n.t("alert.error.unknown_error"));
      }

      const errorIdentifier = `be.error.${error?.response.data.errorTypeIdentifier || ""}${
        error.response.data.errorParameters?.operation ? `_${error.response.data.errorParameters?.operation}` : ""
      }`;
      return Promise.reject(i18n.exists(errorIdentifier) ? i18n.t(errorIdentifier) : error.response.data.errorMessage);
    }
  );
};
