import React, { useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { HotKeys } from "react-hotkeys";
import AddItemButton from "components/DataManager/AddItemButton";
import { useScreenSize } from "hooks";
import { Trash as TrashIcon } from "grommet-icons";
import { GhostButton, PermissionCheck, LoaderOverlay } from "components/Common";
import { useTranslation } from "react-i18next";
import { Menu, Item, useContextMenu, animation } from "react-contexify";
import { Edit as EditIcon } from "grommet-icons/icons";
import "../../Styles/ContextMenu.css";
import { bucketActions } from "actions/bucketActions";
import { confirmationActions } from "actions/confirmationActions";
import { isBucketsConsoleLoading } from "selectors";
import styles from "../../Styles/DataManager.module.scss";
import Bucket from "./Bucket";
import Delimiter from "components/Common/Delimiter";

const MENU_ID = "buckets_context_menu";

const BucketsWrapper = ({
  buckets,
  selectedBuckets,
  showLoader,
  onBucketClick,
  bucketsInDeletion,
  modalActions: { openBucketDetail },
  bucketActions: { selectMultipleBuckets, unselectAllBuckets, getBucketTransactions },
  confirmationActions: { openDeleteBucketsConfirmation },
}) => {
  const { t } = useTranslation();

  const bucketRef = useRef(null);

  const { isSmall } = useScreenSize();

  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });

  const onRightClick = (e, id) => {
    e.preventDefault();
    const isSelected = selectedBuckets.find(o => o.id === id);
    if (!isSelected) onBucketClick(id);
    show(e, { props: { id } });
  };

  const handleEditBucket = () => openBucketDetail(selectedBuckets[0], true);
  const handleDeleteBuckets = () => openDeleteBucketsConfirmation(selectedBuckets.map(o => o.id));

  return (
    <>
      <LoaderOverlay isLoading={showLoader} className={styles.dropzone_container} data-tour="datamanager-buckets-console">
        <div className={styles.containers_console}>
          <div className={styles.controls_top}>
            <div className={styles.container_type_selector}>
              <span>{t("data_manager.buckets_console.bucket")}</span>
            </div>
            <div className={styles.button_group}>
              <PermissionCheck>
                {selectedBuckets.length === 1 && <GhostButton icon={<EditIcon />} onClick={handleEditBucket} />}
                {selectedBuckets.length >= 1 && <GhostButton icon={<TrashIcon />} onClick={handleDeleteBuckets} />}
              </PermissionCheck>
            </div>
          </div>
          <HotKeys
            keyMap={{
              SELECT_ALL: "command+a",
              DELETE: ["del", "backspace"],
            }}
            handlers={{
              SELECT_ALL: e => {
                e.preventDefault();
                selectMultipleBuckets(buckets.map(o => o.id));
                getBucketTransactions();
              },
              DELETE: e => {
                e.preventDefault();
                handleDeleteBuckets();
              },
            }}
            tabIndex="3"
            className={styles.containers_wrapper}
            onClick={unselectAllBuckets}>
            <div ref={bucketRef}>
              <div className={styles.create_bucket}>
                <PermissionCheck>
                  <AddItemButton onClick={openBucketDetail} label={t("data_manager.buckets_console.create_bucket")} />
                </PermissionCheck>
              </div>
              <div className={styles.buckets}>
                {buckets.map(o => (
                  <Bucket
                    key={o.id}
                    clickAction={onBucketClick}
                    doubleClickAction={() =>
                      openBucketDetail(
                        buckets.find(x => x.id === o.id),
                        true
                      )
                    }
                    bucket={o}
                    width={isSmall ? 70 : 100}
                    onRightClick={e => onRightClick(e, o.id)}
                    hideContextMenu={hideAll}
                    isInDeletion={bucketsInDeletion.has(o.id)}
                  />
                ))}
              </div>
            </div>
          </HotKeys>
          <div className={styles.controls_bottom} />
        </div>
      </LoaderOverlay>
      <Menu id={MENU_ID} animation={animation.fade} className={styles.console_menu}>
        <Item onClick={handleEditBucket} hidden={selectedBuckets.length !== 1}>
          <GhostButton label={t("data_manager.buckets_console.edit")} icon={<EditIcon />} />
        </Item>

        <Delimiter style={{ borderBottom: "2px solid rgba(9, 30, 66, 0.08)", margin: "6px 0" }} />

        <Item onClick={handleDeleteBuckets}>
          <GhostButton label={t("data_manager.buckets_console.delete")} icon={<TrashIcon />} />
        </Item>
      </Menu>
    </>
  );
};

function mapStateToProps(state) {
  return {
    showLoader: isBucketsConsoleLoading(state),
    bucketsInDeletion: state.buckets.bucketsInDeletion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketsWrapper);
