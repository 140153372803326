import React from "react";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import CreateBucketForm from "../Forms/BucketDetailForm";

const BucketDetailModal = ({ bucket, isEdit = false }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope
      id="create-bucket-modal"
      heading={isEdit ? t("modal.bucket_detail.update_heading") : t("modal.bucket_detail.create_heading")}>
      <CreateBucketForm bucket={bucket} isEdit={isEdit} />
    </ModalEnvelope>
  );
};

export default BucketDetailModal;
