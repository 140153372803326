import React, { useState } from "react";
import { spring, TransitionMotion } from "react-motion";
import cx from "classnames";

import { getDataTestId } from "utils";
import styles from "./Components.module.scss";

const willEnter = () => ({
  opacity: 0,
});

const willLeave = () => ({
  opacity: spring(0),
  height: 0,
});

const Tabs = ({
  children,
  tabsProps = {},
  onTabClick,
  tabWrapperProps = {}, // TODO - merge with tabWrapperClassName
  tabWrapperClassName,
  listClassName,
  defaultTabIndex = 0,
  activeIndex,
  HeaderComponent,
  headerComponentProps,
  disableAnimation = false,
  ...rest
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);
  const usedIndex = activeIndex || activeTabIndex;

  const getStyles = () =>
    children
      .filter(x => x)
      .map((o, i) => ({
        key: `${i}`, // needs to be a string
        style: {
          opacity: spring(1),
        },
        data: { el: o.props.children },
      }))
      .filter(({ key }) => key === `${usedIndex}`);

  const header = (
    <ul className={cx(styles.tabs, listClassName)} {...tabsProps}>
      {React.Children.map(
        children,
        (child, index) =>
          child &&
          React.cloneElement(child, {
            key: index,
            onClickAction: () => {
              setActiveTabIndex(index);
              if (onTabClick && typeof onTabClick === "function") onTabClick(index);
              if (child.props.onClickAction && typeof child.props.onClickAction === "function") child.props.onClickAction();
            },
            isActive: index === usedIndex,
          })
      )}
    </ul>
  );

  return (
    <div {...rest}>
      {HeaderComponent ? <HeaderComponent {...headerComponentProps}>{header}</HeaderComponent> : header}
      {disableAnimation ? (
        <>
          {getStyles().map(({ data, index }) => (
            <React.Fragment key={`index${index}`}>{data.el}</React.Fragment>
          ))}
        </>
      ) : (
        <TransitionMotion styles={getStyles()} willEnter={willEnter} willLeave={willLeave}>
          {interpolated => (
            <div className={cx(styles.tabs_content_wrapper, tabWrapperClassName)} {...tabWrapperProps}>
              {interpolated.map(({ key, style, data }) => (
                <div
                  key={`${key}-transition`}
                  style={{
                    position: style.height === 0 ? "absolute" : "relative",
                    opacity: style.opacity,
                    overflow: "visible",
                  }}>
                  {data.el}
                </div>
              ))}
            </div>
          )}
        </TransitionMotion>
      )}
    </div>
  );
};

export const Tab = ({ onClickAction, title, isActive, isHidden = false, id }) =>
  isHidden ? null : (
    <li
      className={cx(isActive ? styles.active_tab : styles.tab, "mt-1")}
      data-testid={getDataTestId("tab", title)}
      onClick={onClickAction}
      role="tab"
      id={id}>
      {typeof title === "string" ? <label>{title}</label> : title}
    </li>
  );

export default Tabs;
