export const usedCurrenciesCrypto = [
  "BTC",
  "ETH",
  "USDT",
  "BNB",
  "SOL",
  "XRP",
  "USDC",
  "ADA",
  "AVAX",
  "DOGE",
  "DOT",
  "TRX",
  "MATIC",
  "LINK",
  "TON",
  "SHIB",
  "ICP",
  "LTC",
  "BCH",
  "DAI",
  "ETC",
  "UNI",
  "OP",
  "ATOM",
  "LEO",
  "NEAR",
  "XLM",
  "LDO",
  "OKB",
  "INJ",
  "FIL",
  "ARB",
  "IMX",
  "APT",
  "XMR",
  "HBAR",
  "KAS",
  "TIA",
  "MNT",
  "STX",
  "VET",
  "CRO",
  "FDUSD",
  "MKR",
  "BSV",
  "GRT",
  "RUNE",
  "SEI",
  "AAVE",
];

export const usedCurrenciesFiat = [
  "AUD",
  "BRL",
  "BGN",
  "CNY",
  "DKK",
  "EUR",
  "PHP",
  "HKD",
  "INR",
  "IDR",
  "ISK",
  "ILS",
  "JPY",
  "ZAR",
  "CAD",
  "KRW",
  "HUF",
  "MYR",
  "MXN",
  "XDR",
  "NOK",
  "NZD",
  "PLN",
  "RON",
  "SGD",
  "SEK",
  "CHF",
  "THB",
  "TRY",
  "USD",
  "GBP",
];
