import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from "react";
import { TextInput as Input } from "grommet";
import { useField } from "formik";
import cx from "classnames";
import { FormView as HideIcon, FormLock as RevealIcon } from "grommet-icons";
import TestInput from "components/Common/TestInput";
import { LabelRow, InputWrapper } from "./Shared";

import styles from "./Inputs.module.scss";

const TextInput = forwardRef(
  (
    {
      onChange,
      label,
      error,
      required,
      isInline,
      wrapperClassName,
      wrapperStyle,
      onWrapperClick,
      children,
      isReadOnly,
      isPlain,
      testId,
      regex,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef();
    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current.focus(),
      select: () => inputRef.current.select(),
    }));

    const onChangeInternal = e => {
      const newValue = e.target.value;
      if (regex && !!newValue && !newValue.match(regex)) return;
      onChange(newValue);
    };

    return (
      <InputWrapper
        className={cx(styles.text_input, wrapperClassName)}
        style={wrapperStyle}
        disabled={props.disabled}
        inline={isInline}
        error={error}
        plain={isPlain}
        onClick={() => {
          if (onWrapperClick && typeof onWrapperClick === "function") onWrapperClick();
          inputRef.current.focus();
        }}>
        <LabelRow label={label} error={error} required={required} />

        {testId && <TestInput name={testId} value={props.value} />}

        {children ? (
          <div className={styles.flex_space_between}>
            <Input focusIndicator={false} ref={inputRef} onChange={onChangeInternal} plain {...props} />
            {children}
          </div>
        ) : (
          <Input focusIndicator={false} ref={inputRef} onChange={onChangeInternal} plain {...props} />
        )}
      </InputWrapper>
    );
  }
);

export const TextFormField = forwardRef(({ name, onChange, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return (
    <TextInput
      ref={ref}
      error={meta.touched ? meta.error : ""}
      {...field}
      onChange={val => {
        if (onChange && typeof onChange === "function") onChange(val);
        helpers.setValue(val);
      }}
      {...props}
    />
  );
});

export const PasswordInput = forwardRef((props, ref) => {
  const [revealed, setRevealed] = useState(false);

  useEffect(() => {
    setRevealed(false);
  }, [props.disabled]);

  return (
    <TextInput ref={ref} {...props} type={revealed ? "text" : "password"}>
      <button
        tabIndex="-1"
        type="button"
        disabled={props.disabled}
        className={styles.reveal_button}
        onClick={() => setRevealed(!revealed)}>
        {revealed ? <HideIcon size="medium" /> : <RevealIcon size="medium" />}
      </button>
    </TextInput>
  );
});

export const PasswordFormField = forwardRef(({ name, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return <PasswordInput ref={ref} error={meta.touched ? meta.error : ""} {...field} onChange={helpers.setValue} {...props} />;
});

export default TextInput;
