import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFiatOptionsWithIcon } from "selectors";
import { transactionActions } from "actions/transactionActions";
import { confirmationActions } from "actions/confirmationActions";
import ModalEnvelope from "./ModalEnvelope";
import ApiCredentialsForm from "../Forms/ApiCredentialsForm";

const ApiCredentialsModal = ({
  dataContainerId,
  connection,
  connector: { name, parameters },
  onClose,
  transactionActions: { updateConnection, validateApiContainerConnectionChanged },
  fiatOptions,
  confirmationActions: { openEditApiConnectionConfirmation },
}) => {
  const onSubmit = async connectionData => {
    const hasConnectionChanged = await validateApiContainerConnectionChanged(
      { ...connection, ...connectionData },
      dataContainerId
    );

    if (hasConnectionChanged) {
      return openEditApiConnectionConfirmation(() => {
        updateConnection({ ...connection, ...connectionData }, dataContainerId);
        if (onClose) onClose();
      }, onClose);
    }
    if (onClose) onClose();
  };

  return (
    <ModalEnvelope id="connection-credentials-modal" heading={name} onClose={onClose}>
      <ApiCredentialsForm parameters={parameters} connection={connection} fiatOptions={fiatOptions} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    fiatOptions: getFiatOptionsWithIcon(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ApiCredentialsModal);
