import {
  CHANGE_DATAMANAGER_FILTER,
  CLICK_CONTAINER,
  CREATE_CONTAINER,
  DELETE_CONTAINER,
  DELETE_TRANSACTIONS,
  EXPORT_CONTAINER_CSV,
  GET_CONTAINER_DETAIL,
  GET_CONTAINER_DETAIL_ASYNCHRONOUS,
  GET_CONTAINERS,
  GET_CONTAINERS_ASYNCHRONOUS,
  GET_FILTER_OPTIONS,
  GET_TRANSACTIONS,
  HIDE_TRANSACTIONS,
  LABEL_TRANSACTIONS,
  PORTFOLIO_CONTAINER_ASSIGNMENT,
  SELECT_MULTIPLE_CONTAINERS,
  SET_ACTIVE_MANUAL_CONTAINER_SYNC_IDS,
  SET_CONTAINERS_IN_DELETION,
  SET_EDIT_MODE,
  SET_EXTENDED_CONTAINER_DETAIL,
  SET_IS_CONTAINER_SYNC_ACTIVE,
  SET_IS_DATA_MANAGER_LOADING,
  SET_LAST_USED_CURRENCIES,
  SET_TRANSACTIONS_FILTER,
  SWITCH_PORTFOLIO,
  UNSELECT_ALL_CONTAINERS,
  UPDATE_CONNECTION,
} from "actions/types";
import { transactionService } from "services";
import { getLastUsedCurrencies, getRawContainerSelection, getTransactionsManualFilter } from "selectors";
import { CONTAINER_API, CONTAINER_CSV, CONTAINER_MANUAL } from "common/constants/containerTypes";
import i18n from "i18n";
import history from "common/history";
import { JOB_PROCESSING } from "common/constants/jobStatusType";
import { useDefaultsStore } from "stores/defaultsStore";
import { defaultExtendedContainerDetail } from "common/constants/defaultExtendedContainerDetail";
import { alertActions } from "./alertActions";
import { modalActions } from "./modalActions";
import { portfolioActions } from "./portfolioActions";
import { uploadBigContainer } from "./transactionActions_containerUpload";
import { jobActions } from "./jobActions";

function getContainers(onFetchCallback, asynchronous = false) {
  return async (dispatch, getState) => {
    if (asynchronous && getState().dataManager.isContainersAsyncFetching) {
      return;
    }

    await dispatch({
      type: asynchronous ? GET_CONTAINERS_ASYNCHRONOUS : GET_CONTAINERS,
      payload: {
        promise: async () => {
          const containers = await transactionService.getContainers(getState().user);
          containers
            .filter(o => o.type === CONTAINER_CSV && o.fileImport?.jobStatus === JOB_PROCESSING)
            .forEach(o => {
              dispatch(jobActions.monitorJobProgress(o.fileImport));
            });
          return containers;
        },
      },
    }).catch(err => {
      alertActions.error(err);
    });
    if (onFetchCallback) onFetchCallback();
  };
}

function getContainerDetail(containerId, asynchronous = false) {
  return (dispatch, getState) => {
    if (getState().dataManager.containerDetailFetching[containerId]) {
      return;
    }

    return dispatch({
      type: asynchronous ? GET_CONTAINER_DETAIL_ASYNCHRONOUS : GET_CONTAINER_DETAIL,
      payload: transactionService.getContainerDetail(containerId, getState().user),
      meta: { containerId },
    });
  };
}

function setExtendedContainerDetail(containerId, data = defaultExtendedContainerDetail) {
  return dispatch =>
    dispatch({
      type: SET_EXTENDED_CONTAINER_DETAIL,
      payload: data,
      meta: { containerId },
    });
}

function updateContainer(container, portfolioId, preserveState = false, refreshContainers = true) {
  return async (dispatch, getState) => {
    if (!preserveState) dispatch(setExtendedContainerDetail());
    const response = await transactionService.updateContainer(container, portfolioId, getState().user);

    if (refreshContainers) {
      await dispatch(getContainers());
      dispatch(getContainerDetail(container.id));
    }

    dispatch(getTransactions());

    return response;
  };
}

function selectPortfolio(portfolioId) {
  return {
    type: SWITCH_PORTFOLIO,
    payload: portfolioId,
  };
}

function fetchPortfolioContainerInfo(portfolioId) {
  return async dispatch => {
    dispatch(setEditMode(false, portfolioId));
    if (portfolioId) {
      dispatch(unselectAll());
      await dispatch(getPortfolioContainerAssignmentInfo(portfolioId));
      dispatch(getTransactions());
    } else {
      dispatch(getTransactions());
    }
  };
}

function getPortfolioContainerAssignmentInfo(portfolioId) {
  return (dispatch, getState) =>
    dispatch({
      type: PORTFOLIO_CONTAINER_ASSIGNMENT,
      payload: {
        promise: async () => {
          const containers = await transactionService.getContainersAssignmentInfo(getState().user, portfolioId);
          return containers.map(o => o.id);
        },
      },
      meta: { portfolioId, isEdit: false },
    }).catch(err => {
      alertActions.error(err);
    });
}

function setPortfolioContainerAssignments(portfolioId, transactionContainerIds, showAlert = true) {
  return (dispatch, getState) =>
    dispatch({
      type: PORTFOLIO_CONTAINER_ASSIGNMENT,
      payload: {
        promise: async () => {
          const res = await transactionService.setPortfolioContainerAssignmentInfo(
            portfolioId,
            transactionContainerIds,
            getState().user
          );
          if (showAlert) alertActions.success(i18n.t("alert.success.containers_assigned"));
          dispatch(getContainers()); // To update container assignments info TODO: Fetch only in detail for specific container
          return res;
        },
      },
      meta: { portfolioId, isEdit: true },
    }).catch(err => {
      alertActions.error(err);
    });
}

function assignContainers(sourcePortfolioId, destinationPortfolioId, transactionContainerIds, unassign) {
  return async (dispatch, getState) => {
    const { user } = getState();
    try {
      if (unassign) {
        const [destinationAssignedContainers, sourceAssignedContainers] = await Promise.all([
          transactionService.getContainersAssignmentInfo(user, destinationPortfolioId),
          transactionService.getContainersAssignmentInfo(user, sourcePortfolioId),
        ]);
        const destinationAssignments = destinationAssignedContainers.map(o => o.id);
        const sourceAssignments = sourceAssignedContainers.map(o => o.id);
        const newDestinationAssignments = [...new Set(destinationAssignments.concat(transactionContainerIds))];
        const newSourceAssignments = sourceAssignments.filter(o => !transactionContainerIds.includes(o));

        await Promise.all([
          dispatch(setPortfolioContainerAssignments(destinationPortfolioId, newDestinationAssignments, false)),
          dispatch(setPortfolioContainerAssignments(sourcePortfolioId, newSourceAssignments, false)),
        ]);
      } else {
        const destinationAssignedContainers = await transactionService.getContainersAssignmentInfo(user, destinationPortfolioId);
        const destinationAssignments = destinationAssignedContainers.map(o => o.id);
        const newDestinationAssignments = [...new Set(destinationAssignments.concat(transactionContainerIds))];
        await dispatch(setPortfolioContainerAssignments(destinationPortfolioId, newDestinationAssignments, false));
      }
      history.push(`/datamanager/containers?portfolioId=${destinationPortfolioId}`);

      alertActions.success(i18n.t("alert.success.containers_assigned"));
      dispatch(modalActions.hideModal());
    } catch (err) {
      alertActions.error(i18n.t("alert.error.container_assignment_failed"));
    }
  };
}

function getTransactions() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      dataManager: {
        filters: { page, count, portfolioFiltersApplied },
        selectedPortfolio,
      },
      listOfPortfolios: { activePortfolio },
      user,
    } = state;

    // selected containers selector not used -> If containerSelection requests is faster than get containers, then selectedContainers returns []
    const containerSelections = getRawContainerSelection(state);
    const selectedContainerIds = Object.keys(containerSelections)
      .filter(o => containerSelections[o])
      .map(Number);

    const portfolioId = selectedPortfolio && portfolioFiltersApplied ? activePortfolio : null;
    const filter = getTransactionsManualFilter(state);

    dispatch({
      type: GET_TRANSACTIONS,
      payload: transactionService.getTransactions(user, selectedContainerIds, portfolioId, page, filter, count),
    }).catch(err => {
      // axiosMiddleware provides isCancelled flag
      if (err && !err.isCancelled) alertActions.error(err);
    });

    dispatch(getFilterOptions());
  };
}

function exportContainerTransactions(containerId) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      dataManager: {
        filters: { portfolioFiltersApplied },
        selectedPortfolio,
      },
      listOfPortfolios: { activePortfolio },
      user,
    } = state;

    const selectedContainerIds = [containerId];

    const portfolioId = selectedPortfolio && portfolioFiltersApplied ? activePortfolio : null;
    dispatch({
      type: EXPORT_CONTAINER_CSV,
      payload: transactionService.exportContainersCsv(user, selectedContainerIds, portfolioId),
    }).catch(err => {
      // axiosMiddleware provides isCancelled flag
      if (err && !err.isCancelled) alertActions.error(err);
    });
  };
}

function labelTransactions(transactionIds, transactionLabel) {
  return (dispatch, getState) =>
    dispatch({
      type: LABEL_TRANSACTIONS,
      payload: async () => {
        const data = await transactionService.labelTransactions(transactionIds, transactionLabel, getState().user);
        alertActions.success(i18n.t("alert.success.transactions_label"));
        dispatch(getTransactions());
        return data;
      },
      meta: { transactionIds, label: transactionLabel },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deleteTransactionLabels(transactionIds, transactionLabel) {
  return (dispatch, getState) =>
    dispatch({
      type: LABEL_TRANSACTIONS,
      payload: async () => {
        const data = await transactionService.deleteTransactionLabels(transactionIds, transactionLabel, getState().user);
        alertActions.success(i18n.t("alert.success.transactions_label"));
        dispatch(getTransactions());
        return data;
      },
      meta: { transactionIds, label: transactionLabel },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deleteTransactions(transactionIds) {
  return (dispatch, getState) =>
    dispatch({
      type: DELETE_TRANSACTIONS,
      payload: async () => {
        await transactionService.deleteTransactions(transactionIds, getState().user);
        alertActions.success(i18n.t("alert.success.transactions_deleted"));
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function createNewContainer(container, portfolioId) {
  return (dispatch, getState) =>
    dispatch({
      type: CREATE_CONTAINER,
      payload: {
        promise: async () => {
          const response = await transactionService.createNewContainer(
            { ...container, type: CONTAINER_MANUAL },
            portfolioId,
            getState().user
          );
          dispatch(modalActions.hideModal());
          alertActions.success(i18n.t("alert.success.manual_container_created"));
          dispatch(getContainers());

          return response;
        },
      },
      meta: { portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function createNewConnection({ name, ...connection }, portfolioId) {
  return (dispatch, getState) =>
    dispatch({
      type: CREATE_CONTAINER,
      payload: {
        promise: async () => {
          const { user } = getState();
          const containerResponse = await transactionService.createNewContainer(
            { name, type: CONTAINER_API, references: connection.references },
            portfolioId,
            user,
            connection.connectorId,
            connection.source
          );
          const { transactionContainer, ...connectionRes } = await transactionService.putConnection(
            connection,
            containerResponse.id,
            user
          );
          dispatch(modalActions.hideModal());
          alertActions.success(i18n.t("alert.success.api_connection_created"));
          dispatch(getContainers());
          dispatch(getTransactions());

          return {
            ...transactionContainer,
            ...connectionRes,
          };
        },
      },
      meta: { portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function updateConnection(connectionInput, containerId) {
  return (dispatch, getState) =>
    dispatch({
      type: UPDATE_CONNECTION,
      payload: {
        promise: async () => {
          dispatch(setActiveManualContainerSyncIds(getState().dataManager.activeManualContainerSyncIds.add(containerId)));
          try {
            const { transactionContainer, connection } = await transactionService.putConnection(
              connectionInput,
              containerId,
              getState().user
            );
            return {
              ...transactionContainer,
              connection,
            };
          } catch (e) {
          } finally {
            dispatch(setActiveManualContainerSyncIds(getState().dataManager.activeManualContainerSyncIds.delete(containerId)));
          }
        },
      },
      meta: { containerId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deleteContainers(containerIds, portfolioId) {
  return async dispatch => {
    transactionService.toggleGetContainers(false);
    await containerIds.forEachAsync(async containerId => {
      await dispatch(deleteContainer(containerId, portfolioId, false));
    });
    alertActions.success(i18n.t("alert.success.containers_deleted"));
    transactionService.toggleGetContainers(true);
    dispatch(getContainers(undefined, true));
    dispatch(getTransactions());
    dispatch(portfolioActions.updatePortfolioAssignmentCounts());
  };
}

function deleteContainer(containerId, portfolioId, showSuccess = true) {
  return (dispatch, getState) =>
    dispatch({
      type: DELETE_CONTAINER,
      payload: async () => {
        dispatch(setContainersInDeletion(getState().dataManager.containersInDeletion.add(containerId)));
        const data = await transactionService.deleteContainer(containerId, getState().user);
        if (showSuccess) alertActions.success(i18n.t("alert.success.container_deleted"));
        dispatch(setContainersInDeletion(getState().dataManager.containersInDeletion.delete(containerId)));
        return data;
      },
      meta: { containerId, portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function selectMultipleContainers(containerIds) {
  return {
    type: SELECT_MULTIPLE_CONTAINERS,
    payload: containerIds,
  };
}

function unselectAll() {
  return dispatch => {
    dispatch({ type: UNSELECT_ALL_CONTAINERS });
    dispatch(getTransactions());
  };
}

function changeFilterAndFetch(changes) {
  return dispatch => {
    dispatch(changeFilter(changes));
    dispatch(getTransactions());
  };
}

function setEditMode(val, portfolioId) {
  return {
    type: SET_EDIT_MODE,
    payload: val,
    meta: { portfolioId },
  };
}

function clickOnContainer(visibleContainers, containerId, ctrl = false, shift = false) {
  return dispatch => {
    dispatch(changeFilter({ page: 0, count: useDefaultsStore.getState().perPageDefault }));
    dispatch({
      type: CLICK_CONTAINER,
      payload: {
        visibleContainers,
        containerId,
        ctrlDown: ctrl,
        shiftDown: shift,
      },
    });
  };
}

function changeFilter(changes) {
  return {
    type: CHANGE_DATAMANAGER_FILTER,
    payload: changes,
  };
}

function hideTransactions() {
  return {
    type: HIDE_TRANSACTIONS,
  };
}

function setIsDataManagerLoading(isDataManagerLoading) {
  return {
    type: SET_IS_DATA_MANAGER_LOADING,
    payload: isDataManagerLoading,
  };
}

function getFilterOptions() {
  return (dispatch, getState) => {
    const containerSelections = getRawContainerSelection(getState());
    const selectedContainerIds = Object.keys(containerSelections)
      .filter(o => containerSelections[o])
      .map(Number);

    if (selectedContainerIds?.length > 0) {
      dispatch({
        type: GET_FILTER_OPTIONS,
        payload: transactionService.getTransactionsFilterOptions(getState().user, selectedContainerIds),
      });
    }
  };
}

function setTransactionsFilter(filteredBy) {
  return {
    type: SET_TRANSACTIONS_FILTER,
    payload: filteredBy,
  };
}

function reloadAllContainerTransactions(containerId) {
  return (dispatch, getState) => transactionService.reloadAllContainerTransactions(getState().user, containerId);
}

function fetchNewContainerTransactions(containerId) {
  return async (dispatch, getState) => {
    dispatch(setActiveManualContainerSyncIds(getState().dataManager.activeManualContainerSyncIds.add(containerId)));

    try {
      await transactionService.fetchNewContainerTransactions(getState().user, containerId);
    } catch (e) {
    } finally {
      dispatch(setActiveManualContainerSyncIds(getState().dataManager.activeManualContainerSyncIds.delete(containerId)));
    }
  };
}

function setIsContainerSyncActive(isSyncActive) {
  return {
    type: SET_IS_CONTAINER_SYNC_ACTIVE,
    payload: isSyncActive,
  };
}

function setActiveManualContainerSyncIds(containerIds) {
  return {
    type: SET_ACTIVE_MANUAL_CONTAINER_SYNC_IDS,
    payload: containerIds,
  };
}

function setContainersInDeletion(containerIds) {
  return {
    type: SET_CONTAINERS_IN_DELETION,
    payload: containerIds,
  };
}

function validateApiContainerConnectionChanged(connectionInput, containerId) {
  return async (dispatch, getState) =>
    transactionService.validateApiContainerConnectionChanged(connectionInput, containerId, getState().user);
}

function setLastUsedCurrencies(currencies = []) {
  return (dispatch, getState) => {
    const previousLastUsedCurrencies = getLastUsedCurrencies(getState());

    let updatedLastUsedCurrencies = [...previousLastUsedCurrencies];
    currencies.forEach(currency => {
      if (!updatedLastUsedCurrencies.includes(currency)) {
        updatedLastUsedCurrencies.push(currency);
      }
    });

    // Keep 3 most recently used currencies only
    updatedLastUsedCurrencies =
      updatedLastUsedCurrencies.length > 3 ? updatedLastUsedCurrencies.slice(-3) : updatedLastUsedCurrencies;

    dispatch({
      type: SET_LAST_USED_CURRENCIES,
      payload: updatedLastUsedCurrencies,
    });
  };
}

export const transactionActions = {
  selectPortfolio,
  setEditMode,
  fetchPortfolioContainerInfo,
  getContainers,
  getContainerDetail,
  setExtendedContainerDetail,
  updateContainer,
  uploadBigContainer,
  createNewContainer,
  createNewConnection,
  updateConnection,
  deleteContainers,
  getTransactions,
  hideTransactions,
  exportContainerTransactions,
  labelTransactions,
  deleteTransactionLabels,
  deleteTransactions,
  getFilterOptions,
  getPortfolioContainerAssignmentInfo,
  setPortfolioContainerAssignments,
  assignContainers,
  clickOnContainer,
  selectMultipleContainers,
  unselectAll,
  changeFilterAndFetch,
  changeFilter,
  setIsDataManagerLoading,
  setTransactionsFilter,
  reloadAllContainerTransactions,
  fetchNewContainerTransactions,
  setIsContainerSyncActive,
  setContainersInDeletion,
  setActiveManualContainerSyncIds,
  validateApiContainerConnectionChanged,
  setLastUsedCurrencies,
};
