import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View as ViewIcon } from "grommet-icons/icons";
import cx from "classnames";
import { useRouteMatch } from "react-router-dom";
import { supportService } from "services/supportService";
import { FilterableCell, GhostButton, Heading, LoaderOverlay, SortableCell, Table, TableFooter } from "components/Common";
import { SelectInput } from "components/Common/Inputs";
import { dateTimeFormat, formatDate } from "common/formatters";
import { SMALL } from "common/constants/screenSizes";
import styles from "pages/Pages.module.scss";
import history from "common/history";
import { useDefaultsStore } from "stores/defaultsStore";

const initialFilterValues = {
  pair: null,
  problemType: null,
};

const ErrorRates = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const [pagination, setPagination] = useState({ page: 0, count: useDefaultsStore.getState().perPageDefault });
  const [filterOptions, setFilterOptions] = useState([]);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [sortedBy, setSortedBy] = useState({ id: "rateDate", order: "desc" });

  const [filteredBy, setFilteredBy] = useState(initialFilterValues);
  const pairFilterRef = useRef(null);
  const problemTypeFilterRef = useRef(null);

  const handleLoadData = useCallback(
    pagination => {
      setIsLoading(true);
      supportService
        .getErrorRates(pagination.page, pagination.count, { ...filteredBy, sortOrder: sortedBy.order.toUpperCase() })
        .then(response => {
          setData(response.result);
          setTotalRecords(response.total);
          setIsLoading(false);
        });
    },
    [filteredBy, sortedBy, setIsLoading, setData]
  );

  const handlePagingChange = value => {
    if (value.count) setPagination(prevState => ({ ...prevState, count: value.count }));
    if (value.page || value.page === 0) setPagination(prevState => ({ ...prevState, page: value.page }));
  };

  useEffect(() => {
    setIsFilterLoading(true);
    supportService.getErrorRatesFilterOptions().then(response => {
      setFilterOptions(response);
      setIsFilterLoading(false);
    });
  }, []);

  useEffect(() => {
    handleLoadData(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedBy, pagination]);

  useEffect(() => {
    setPagination(prevState => ({ ...prevState, page: 0 }));
    handleLoadData({ ...pagination, page: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBy]);

  const errorRatesColumns = useMemo(
    () => [
      {
        Header: props => (
          <FilterableCell
            {...props}
            onClick={() => {
              pairFilterRef.current.focus();
            }}>
            {t("et_configured_pairs.table_header.currency_pair")}
          </FilterableCell>
        ),
        accessor: "pair",
        Cell: ({ row }) => {
          const { base, quote } = row.original;
          return `${base} / ${quote}`;
        },
        maxWidth: 150,
        width: 120,
      },
      {
        Header: props => <SortableCell {...props}>{t("et_configured_pairs.table_header.date")}</SortableCell>,
        accessor: "rateDate",
        Cell: ({ value }) => formatDate(value, dateTimeFormat),
      },
      {
        Header: t("et_configured_pairs.table_header.rate"),
        Cell: () => "-",
      },
      {
        Header: t("et_configured_pairs.table_header.source"),
        accessor: "source",
      },
      {
        Header: props => (
          <FilterableCell
            {...props}
            onClick={() => {
              problemTypeFilterRef.current.focus();
            }}>
            {t("et_configured_pairs.table_header.problem_type")}
          </FilterableCell>
        ),
        accessor: "problems",
        Cell: ({ value }) => value.map(problem => problem.type).join(", "),
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("et_configured_pairs.table_header.action")}</div>,
        id: "action",
        Cell: ({ row }) => (
          <div style={{ textAlign: "right", float: "right" }}>
            <GhostButton icon={<ViewIcon />} onClick={() => history.push(`${match.url}/${row.original.id}`)} actionButton />
          </div>
        ),
        className: styles.narrow_row,
      },
    ],
    [t, match]
  );

  return (
    <>
      <div className={styles.heading_block}>
        <LoaderOverlay isLoading={isFilterLoading} spinnerSize={SMALL}>
          <div className={styles.filters}>
            <SelectInput
              value={filteredBy.pair ?? ""}
              onChange={value => setFilteredBy(prevState => ({ ...prevState, pair: value }))}
              label={t("et_configured_pairs.table_filter.pair")}
              options={filterOptions?.pairs}
              isInline
              isClearable
              ref={pairFilterRef}
              getOptionValue={({ base, quote }) => `${base}_${quote}`}
              getOptionLabel={({ base, quote }) => `${base} / ${quote}`}
              maxMenuHeight={200}
              wrapperClassName="ml-0"
            />

            <SelectInput
              value={filteredBy.problemType ?? ""}
              onChange={value => setFilteredBy(prevState => ({ ...prevState, problemType: value }))}
              label={t("et_configured_pairs.table_filter.problem_type")}
              options={filterOptions?.problemTypes?.map(x => ({ label: x, value: x }))}
              isInline
              isClearable
              ref={problemTypeFilterRef}
              maxMenuHeight={200}
            />
          </div>
        </LoaderOverlay>
      </div>

      <LoaderOverlay isLoading={isLoading}>
        <Table
          data={data}
          columns={errorRatesColumns}
          filteredBy={filteredBy}
          setFilteredBy={setFilteredBy}
          getRowId={row => row.id}
          sortedBy={sortedBy}
          setSortedBy={setSortedBy}
          className="mb-4"
        />

        <TableFooter
          page={pagination.page}
          count={pagination.count}
          totalRecords={totalRecords}
          onChange={handlePagingChange}
          isVisible={data.length > 0}
        />

        {data.length === 0 && (
          <div className={styles.empty_table_row}>
            <Heading level={4} margin="1rem 0 0" color="gray3">
              {t("et_configured_pairs.pairs_table.no_records_message")}
            </Heading>
          </div>
        )}
      </LoaderOverlay>
    </>
  );
};

export default ErrorRates;
