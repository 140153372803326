import queryString from "query-string";
import { REF_CODE, setLocalStorageValue } from "common/localStorageManager";

export default {
  initializeAndDetect: (id, search) => {
    const script = document.createElement("script");
    script.src = "https://script.tapfiliate.com/tapfiliate.js";
    script.async = true;

    const onScriptLoad = () => {
      window.tap("create", id, { integration: "javascript" });
      const { ref } = queryString.parse(search);
      if (ref) {
        window.tap("detect", {
          referral_code: ref,
        });
        setLocalStorageValue(REF_CODE, ref);
      }
    };

    const onScriptError = () => {
      console.log("Affiliate code load failed");
    };

    script.addEventListener("load", onScriptLoad);
    script.addEventListener("error", onScriptError);
    document.body.appendChild(script);
  },
};
