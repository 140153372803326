import i18n from "i18n";
import { ON_ACQUISITION } from "common/constants/rewardTimingOptions";
import { formatDate, utc } from "./formatters";
import { STRING } from "./constants/dynamicFieldTypes";
import { AVCO } from "./constants/computationTypes";
import {
  isTxTypeBuySell,
  isTxTypeDepositWithdrawal,
  isTxTypeFeeRebate,
  isTxTypeReward,
  isTxTypeStake,
} from "./constants/transactionType";

const VARCHAR_MAX_LENGTH = 255;

export const validateEmail = value => {
  if (!value) return i18n.t("validation.required");
  return validateEmailFormat(value);
};

export const validateEmailFormat = value => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return i18n.t("validation.invalid_email");
  if (value.length > VARCHAR_MAX_LENGTH) return i18n.t("validation.too_long");
  return null;
};

export const validateRequiredField = value => {
  if (!value) return i18n.t("validation.required");
  return null;
};

export const validateRequiredTextField = (value, maxLength) => {
  if (!value) return i18n.t("validation.required");
  if (value.length > maxLength) return i18n.t("validation.too_long");
  return null;
};

export const validateTextField = (value, maxLength) => {
  if (value?.length > maxLength) return i18n.t("validation.too_long");
  return null;
};

export const validateRequiredNumber = value => {
  if (value === "" || value == null) return i18n.t("validation.required");
  const num = Number(value);
  if (isNaN(num)) return i18n.t("validation.invalid_number");
  return null;
};

export const validateRequiredNotNegativeNumber = value => {
  if (value === "" || value == null) return i18n.t("validation.required");
  const num = Number(value);
  if (isNaN(num)) return i18n.t("validation.required");
  if (value < 0) return i18n.t("validation.not_negative");
  return null;
};

export const validateRequiredPositiveNumber = value => {
  if (value === "" || value == null) return i18n.t("validation.required");
  const num = Number(value);
  if (isNaN(num)) return i18n.t("validation.required");
  if (value <= 0) return i18n.t("validation.positive");
  return null;
};

export const validateRequiredDate = value => {
  if (value === "") return i18n.t("validation.required");
  if (value === null) return i18n.t("validation.invalid_date");
  if (value instanceof Date && !isNaN(value.valueOf())) return null;
  return null;
};

export const validateRequiredDateTime = (timestamp, date) => {
  if (!timestamp || !date) return i18n.t("validation.required");

  const dateMoment = utc(date);
  const timeMoment = utc(timestamp);
  let timestampError;
  if (!dateMoment.isValid()) {
    timestampError = i18n.t("validation.invalid_date");
  } else {
    timeMoment.set({
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
    });
    timestampError = validateTransactionDate(timestamp);
  }
  return timestampError;
};

export const validateDate = value => {
  if (value === "") return null;
  if (value === undefined) return i18n.t("validation.invalid_date");
  if (value instanceof Date && !isNaN(value.valueOf())) return null;
  if (value.isValid && value.isValid()) return null; // moment object
  return i18n.t("validation.invalid_date");
};

export const validateTransactionDate = value => {
  const requiredError = validateRequiredDate(value);
  if (requiredError) return requiredError;
  const date = utc(value);
  const minDate = utc(new Date(2009, 0, 3));
  const lastDate = utc(new Date()).endOf("d");
  if (date.isBefore(minDate)) return i18n.t("validation.must_be_after", { date: formatDate(minDate) });
  if (date.isAfter(lastDate)) return i18n.t("validation.must_be_before", { date: formatDate(lastDate.add(1, "d")) });
  return null;
};

export const validateRequiredSelectField = value => {
  if (!value || !value.value) return i18n.t("validation.required");
  return null;
};

export const validateRequiredCustomSelectField = value => {
  if (value === "") return i18n.t("validation.required");
  return null;
};

export const validatePair = (first, second, pairs) => {
  const market = pairs.filter(o => o === `${first.value}_${second.value}` || o === `${second.value}_${first.value}`);
  if (market.length !== 1) return i18n.t("validation.invalid_pair");
};

export const validatePortfolio = (values, isSubmitted) => {
  const errors = {};

  const isAvco = values.computationType?.value === AVCO;

  let hasInitialStateError = false;
  let hasRatesError = false;
  let hasAccountInitialBalancesError = false;

  const nameError = validateRequiredTextField(values.name, VARCHAR_MAX_LENGTH);
  const noteError = validateTextField(values.note, 180);
  const computationTypeError = validateRequiredField(values.computationType);
  const currencyError = validateRequiredField(values.currency);
  const feeApplicationTypeError = validateRequiredField(values.feeApplicationType);
  let initialStateError;

  if (isAvco) {
    initialStateError = values.initialState?.pairStates?.map(o => {
      const baseError = validateRequiredCustomSelectField(o.pair.base);
      const quoteError = validateRequiredCustomSelectField(o.pair.quote);
      const basePositionError = validateRequiredNumber(o.basePosition?.segments?.TRADE);
      const averageError = validateRequiredNumber(o.average?.segments?.TRADE);
      const openingFeeError = validateRequiredNotNegativeNumber(o.openingFee?.segments?.TRADE);
      if (baseError || quoteError || basePositionError || averageError || openingFeeError) hasInitialStateError = true;
      return {
        pair: {
          base: baseError,
          quote: quoteError,
        },
        basePosition: {
          segments: {
            TRADE: basePositionError,
          },
        },
        average: {
          segments: {
            TRADE: averageError,
          },
        },
        openingFee: {
          segments: {
            TRADE: openingFeeError,
          },
        },
      };
    });
  }

  let hasFifoInitialStateStartDateError;
  if (!isAvco && values.initialState?.timestamp) {
    if (!values.range?.from) {
      hasFifoInitialStateStartDateError = true;
    }
    const source = utc(values.initialState.timestamp);
    const ptfStart = utc(values.range.from);
    if (source.isAfter(ptfStart)) {
      hasFifoInitialStateStartDateError = true;
    }
  }

  const ratesError = values.rates.map(o => {
    const baseError = validateRequiredCustomSelectField(o.base);
    const quoteError = validateRequiredCustomSelectField(o.quote);
    const rateError = validateRequiredPositiveNumber(o.rate);
    if (baseError || quoteError || rateError) hasRatesError = true;
    return {
      base: baseError,
      quote: quoteError,
      rate: rateError,
    };
  });

  const accountInitialBalancesError = values.accountInitialBalances?.balances?.map(o => {
    const accountError = validateRequiredCustomSelectField(o.account);
    const initialBalanceError = validateRequiredNumber(o.initialBalance);
    if (accountError || initialBalanceError) hasAccountInitialBalancesError = true;
    return {
      account: accountError,
      initialBalance: initialBalanceError,
    };
  });

  if (nameError) errors.name = nameError;
  if (noteError) errors.note = noteError;
  if (computationTypeError) errors.computationType = computationTypeError;
  if (currencyError) errors.currency = currencyError;
  if (feeApplicationTypeError) errors.feeApplicationType = feeApplicationTypeError;
  if (hasInitialStateError) errors.initialState = { pairStates: initialStateError };
  if (hasRatesError) errors.rates = ratesError;
  if (hasAccountInitialBalancesError) errors.accountInitialBalances = accountInitialBalancesError;
  if (hasFifoInitialStateStartDateError) errors.hasFifoInitialStateStartDateError = true;

  if (isSubmitted && Object.keys(errors).length > 0) {
    console.log(`Validation errors: ${JSON.stringify(errors)}`);
  }
  return errors;
};

export const validateTransaction = (values, subtransactionType, latestRuleSet) => {
  const errors = {};

  const type = values.type?.value;

  const typeError = validateRequiredSelectField(values.type);
  const timestampError = validateRequiredDateTime(values.timestamp, values.date);

  if (typeError) errors.type = typeError;
  if (timestampError) errors.timestamp = timestampError;

  if (isTxTypeBuySell(type) || !type) {
    const baseCurrencyError = validateRequiredField(values.baseCurrency);
    const quoteCurrencyError = validateRequiredField(values.quoteCurrency);

    if (baseCurrencyError) errors.baseCurrency = baseCurrencyError;
    if (quoteCurrencyError) errors.quoteCurrency = quoteCurrencyError;
  }

  // Validations based on tx type
  if (isTxTypeBuySell(type)) {
    let baseQuantityError = null;
    let quoteQuantityError = null;
    let unitPriceError = null;

    if (!values.quoteQuantity || !values.unitPrice) {
      baseQuantityError = validateRequiredPositiveNumber(values.baseQuantity);
    }
    if (values.quoteQuantity || !values.unitPrice) {
      quoteQuantityError = validateRequiredPositiveNumber(values.quoteQuantity);
    }
    if (values.unitPrice) {
      unitPriceError = validateRequiredPositiveNumber(values.unitPrice);
    }

    if (baseQuantityError) errors.baseQuantity = baseQuantityError;
    if (quoteQuantityError) errors.quoteQuantity = quoteQuantityError;
    if (unitPriceError) errors.unitPrice = unitPriceError;
  }
  if (isTxTypeFeeRebate(type)) {
    const baseCurrencyError = validateRequiredField(values.baseCurrency);
    if (baseCurrencyError) errors.baseCurrency = baseCurrencyError;

    const feeRebateError = validateRequiredPositiveNumber(values.feeRebate);
    if (feeRebateError) errors.feeRebate = feeRebateError;
  }
  if (isTxTypeDepositWithdrawal(type) || isTxTypeStake(type)) {
    const baseCurrencyError = validateRequiredField(values.baseCurrency);
    if (baseCurrencyError) errors.baseCurrency = baseCurrencyError;

    const volumeError = validateRequiredPositiveNumber(values.volume);
    if (volumeError) errors.volume = volumeError;
  }
  if (isTxTypeReward(type)) {
    const baseCurrencyError = validateRequiredField(values.baseCurrency);
    if (baseCurrencyError) errors.baseCurrency = baseCurrencyError;

    let acquisitionPriceError = null;
    let volumeError = null;
    let unitPriceError = null;

    if (latestRuleSet?.realizationTypes?.[type] === ON_ACQUISITION) {
      if (!values.volume || !values.unitPrice) {
        acquisitionPriceError = validateRequiredPositiveNumber(values.acquisitionPrice);
      }
      if (values.volume || !values.unitPrice) {
        volumeError = validateRequiredPositiveNumber(values.volume);
      }
      if (values.unitPrice) {
        unitPriceError = validateRequiredPositiveNumber(values.unitPrice);
      }

      if (acquisitionPriceError) errors.acquisitionPrice = acquisitionPriceError;
      if (volumeError) errors.volume = volumeError;
      if (unitPriceError) errors.unitPrice = unitPriceError;
    } else {
      volumeError = validateRequiredPositiveNumber(values.volume);
      if (volumeError) errors.volume = volumeError;
    }
  }

  if (subtransactionType) {
    const feeRebateCurrencyError = validateRequiredCustomSelectField(values.feeRebateCurrency);
    const feeRebateError = validateRequiredPositiveNumber(values.feeRebate);

    if (feeRebateCurrencyError) errors.feeRebateCurrency = feeRebateCurrencyError;
    if (feeRebateError) errors.feeRebate = feeRebateError;
  }

  return errors;
};

export const validateSubtransaction = values => {
  const errors = {};

  const typeError = validateRequiredSelectField(values.type);
  const timestampError = validateRequiredDateTime(values.timestamp, values.date);
  const feeRebateCurrencyError = validateRequiredField(values.feeRebateCurrency);
  const feeRebateError = validateRequiredPositiveNumber(values.feeRebate);

  if (typeError) errors.type = typeError;
  if (timestampError) errors.timestamp = timestampError;
  if (feeRebateCurrencyError) errors.feeRebateCurrency = feeRebateCurrencyError;
  if (feeRebateError) errors.feeRebate = feeRebateError;

  return errors;
};

export const validateMessage = values => {
  const errors = {};
  const messageError = validateRequiredField(values.message);
  const typeError = validateRequiredSelectField(values.type);

  if (messageError) errors.message = messageError;
  if (typeError) errors.type = typeError;
  return errors;
};

export const validateAssignContainers = values => {
  const errors = {};
  const portfolioError = validateRequiredSelectField(values.portfolio);
  if (portfolioError) errors.portfolio = portfolioError;
  return errors;
};

export const validatePortfolioPredecessor = values => {
  const errors = {};
  const portfolioError = validateRequiredSelectField(values.portfolio);
  if (portfolioError) errors.portfolio = portfolioError;
  return errors;
};

export const validateApiConnection = values => {
  const errors = {};

  const nameError = validateRequiredTextField(values.name, VARCHAR_MAX_LENGTH);
  const connectorTypeError = validateRequiredSelectField(values.connectorType);
  if (!connectorTypeError) {
    const parameters = Object.keys(values.parameters);
    parameters.forEach(p => {
      const err = validateRequiredField(values.parameters[p]);
      if (err) {
        if (!errors.parameters) errors.parameters = {};
        errors.parameters[p] = err;
      }
    });
  }
  if (nameError) errors.name = nameError;
  if (connectorTypeError) errors.connectorType = connectorTypeError;

  return errors;
};

export const validateApiCredentials = (values, parameters, validateSecrets) => {
  const errors = {};

  parameters.forEach(p => {
    if (p.type === STRING || validateSecrets) {
      const err = validateRequiredField(values.parameters[p.id]);
      if (err) {
        if (!errors.parameters) errors.parameters = {};
        errors.parameters[p.id] = err;
      }
    }
  });
  return errors;
};

export const validateEmailSubscription = values => {
  const errors = {};

  const emailError = validateEmail(values.EMAIL);
  if (emailError) errors.EMAIL = emailError;
  return errors;
};
