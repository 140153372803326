import React, { useState, useEffect, memo } from "react";
import { Area, Line, LinePath } from "@vx/shape";
import { Group } from "@vx/group";
import { curveStepAfter } from "@vx/curve";
import { colors } from "common/colors";

import styles from "../Styles/Graphs.module.scss";

const xDate = d => d.timestamp.valueOf();

// https://egghead.io/lessons/react-prevent-unnecessary-component-rerenders-with-react-memo
const GraphArea = memo(props => {
  // useWhyDidYouUpdate('GraphArea', props);
  const { xScale, yScale, data, isDisplayed, xMax, isLoading, isExample, isLoader } = props;

  const [visible, setVisible] = useState(false);
  // const [topOffset, setTopOffset] = useState(prevZero - zero);

  useEffect(() => {
    const animationId = requestAnimationFrame(() => {
      // https://muffinman.io/react-rerender-in-component-did-mount/
      // requestAnimationFrame(() => {
      setVisible(isDisplayed);
      // setTopOffset(isDisplayed ? 0 : (zero - prevZero))
      // })
    });
    return () => cancelAnimationFrame(animationId);
  }, [isDisplayed]);

  return (
    <Group
      // top={topOffset}
      className={styles.graph_area_wrapper}>
      <Area
        data={data}
        xScale={xScale}
        yScale={yScale}
        x={xDate}
        y0={d => (d.fullY < 0 ? 0 : visible ? d.fullY : isLoader ? 0 : d.fullY * 0.7)}
        y1={() => 0}
        strokeWidth={1.5}
        curve={curveStepAfter}
        className={visible ? styles.graph_area : styles.graph_area_hidden}
        stroke={colors.graphGradient1}
        fill="url(#main-graph-gradient)"
        clipPath={!isLoading ? "url(#coloured-graph)" : "none"}
      />
      <Area
        data={data}
        xScale={xScale}
        yScale={yScale}
        x={xDate}
        y0={d => (d.fullY >= 0 ? 0 : visible ? d.fullY : isLoader ? 0 : d.fullY * 0.7)}
        y1={() => 0}
        strokeWidth={1.5}
        curve={curveStepAfter}
        className={visible ? styles.graph_area : styles.graph_area_hidden}
        stroke="var(--yellow-3)"
        fill="url(#main-graph-negative-gradient)"
        clipPath={!isLoading ? "url(#coloured-graph)" : "none"}
      />
      {!isLoading && (
        <>
          <Area
            data={data}
            xScale={xScale}
            yScale={yScale}
            x={xDate}
            y0={d => (visible ? d.fullY : isLoader ? 0 : d.fullY * 0.7)}
            y1={() => 0}
            stroke="url(#main-graph-grey-gradient)"
            fill="url(#main-graph-grey-gradient)"
            className={visible ? styles.graph_area : styles.graph_area_hidden}
            strokeWidth={2}
            curve={curveStepAfter}
            clipPath="url(#grey-graph)"
          />
          <Area
            data={data}
            xScale={xScale}
            yScale={yScale}
            x={xDate}
            y0={d => (visible ? d.fullY : isLoader ? 0 : d.fullY * 0.7)}
            y1={() => 0}
            stroke="url(#main-graph-purple-gradient)"
            fill="url(#main-graph-purple-gradient)"
            className={visible ? styles.graph_area : styles.graph_area_hidden}
            strokeWidth={2}
            curve={curveStepAfter}
            clipPath="url(#purple-graph)"
          />
          <Area
            data={data}
            xScale={xScale}
            yScale={yScale}
            x={xDate}
            y0={d => {
              const stakeY = d.stakeY ?? 0;
              return visible ? stakeY : isLoader ? 0 : stakeY * 0.7;
            }}
            y1={() => 0}
            stroke={colors.primary}
            fill="url(#main-graph-blue-gradient)"
            className={visible ? styles.graph_area : styles.graph_area_hidden}
            strokeWidth={1.5}
            curve={curveStepAfter}
            clipPath="none"
          />
        </>
      )}
      <LinePath
        data={data}
        xScale={xScale}
        yScale={yScale}
        x={xDate}
        y={d => (visible ? d.dottedY : isLoader ? 0 : d.dottedY * 0.7)}
        stroke={isExample ? "rgba(0, 0, 0, 0.5)" : colors.red}
        className={visible ? styles.graph_area : styles.graph_area_hidden}
        strokeWidth={1.5}
        strokeDasharray="5"
        curve={curveStepAfter}
      />
      <Line
        from={{ x: 0, y: yScale(0) }}
        to={{ x: xMax, y: yScale(0) }}
        className={isLoading || isLoader || !isDisplayed ? styles.zero_line_hidden : styles.zero_line}
      />
    </Group>
  );
});

export default GraphArea;
