import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Edit as EditIcon, ShareOption as ShareIcon, Trash as TrashIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import { getFiatOptions, getOwnedPortfolios, getPortfoliosAreFetching, getRawOpenedPortfolio } from "selectors";
import PortfolioSelect from "components/Dashboard/PortfolioSelect";
import DateLabel from "components/Dashboard/DateLabel";
import { GhostButton, Heading, PermissionCheck, PrimaryButton, Text } from "components/Common";
import { AVCO, FIFO } from "common/constants/computationTypes";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import { useScreenSize } from "hooks";
import { getCurrencyIconProps } from "utils/currencyIcon";
import AddPortfolioButton from "./AddPortfolioButton";
import styles from "../Styles/Dashboard.module.scss";
import { PrimaryButtonVariant } from "components/Common/Buttons/PrimaryButton";
import { ActionMenu } from "components/Common/ActionMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScaleBalanced, faSection } from "@fortawesome/free-solid-svg-icons";
import { Zap } from "react-feather";

const DashboardHeading = props => {
  const {
    portfolios,
    isFetching,
    rawOpened,
    modalActions: { openEditPortfolio, openSharePortfolio },
    confirmationActions: { openDeletePortfolioConfirmation },
    noPortfolio,
    fiatOptions = [],
    taxRuleSetName,
  } = props;

  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const currency = rawOpened ? fiatOptions.find(o => o.value === rawOpened.currency) || "" : "";
  const isAvco = rawOpened && rawOpened.computationType === AVCO;
  const sourcePtf = useMemo(() => {
    if (!rawOpened || rawOpened.computationType !== FIFO || rawOpened.initialState === null) return null;
    return portfolios.find(o => o.id === rawOpened.initialState.sourcePortfolioId) || null;
  }, [rawOpened, portfolios]);

  const hasAvcoInitialState = isAvco && rawOpened?.initialState.pairStates.length > 0;
  const hasFifoInitialState = !isAvco && sourcePtf;

  // If no initial state & no date - put AVCO + ptf currency on one line
  const movePtfCurrency = !hasAvcoInitialState && !hasFifoInitialState && !rawOpened?.end;

  const pairsCount = isAvco ? rawOpened?.initialState?.pairStates?.length : rawOpened?.initialState?.sourcePortfolioId ? 1 : 0;
  const accountsCount = rawOpened?.accountInitialBalances?.balances?.length ?? 0;

  return (
    <div className="mb-24">
      <div className={styles.dashboard_header_main}>
        {isFetching || noPortfolio ? (
          <Heading level={2}>{t("dashboard.dashboard_header.heading")}</Heading>
        ) : (
          <div className={styles.dashboard_title} data-tour="select-portfolio">
            <PortfolioSelect portfolios={portfolios} activePortfolio={rawOpened || ""} isFetching={isFetching} />
          </div>
        )}
        <PermissionCheck>
          <div className={cx("d-flex align-items-center justify-content-end w-100", { "gap-24": !isSmall, "gap-8": isSmall })}>
            {!(noPortfolio || isFetching) && (
              <>
                <PermissionCheck>
                  <div data-tour="edit-portfolio">
                    <ActionMenu
                      testId="portfolio-actions-menu"
                      trigger={
                        <PrimaryButton
                          label={
                            <span className="d-flex align-items-center gap-16">
                              {!isSmall && `${t("api_management.table_header.actions")} `}
                              <Zap />
                            </span>
                          }
                          variant={PrimaryButtonVariant.OutlineBlue}
                          noIcon
                          className="w-auto"
                        />
                      }>
                      <ActionMenu.ItemGroup key={1}>
                        <ActionMenu.Item
                          label={t("dashboard.dashboard_header.edit")}
                          icon={<EditIcon />}
                          onClick={() => openEditPortfolio(rawOpened)}
                          testId="edit-portfolio"
                        />

                        <ActionMenu.Item
                          label={t("dashboard.dashboard_header.balances")}
                          icon={<FontAwesomeIcon icon={faScaleBalanced} />}
                          onClick={() => openEditPortfolio(rawOpened, undefined, 0, 0)}
                          testId="portfolio-balances"
                        />

                        <ActionMenu.Item
                          label={t("dashboard.dashboard_header.tax_rules")}
                          icon={<FontAwesomeIcon icon={faSection} />}
                          onClick={() => openEditPortfolio(rawOpened, undefined, 3)}
                          testId="portfolio-tax-rules"
                        />

                        <ActionMenu.Item
                          label={t("dashboard.dashboard_header.share")}
                          icon={<ShareIcon />}
                          onClick={() => openSharePortfolio(rawOpened.id)}
                          testId="share-portfolio"
                        />
                      </ActionMenu.ItemGroup>
                      <ActionMenu.ItemGroup hasSeparator key={2}>
                        <ActionMenu.Item
                          label={t("button.delete")}
                          icon={<TrashIcon />}
                          onClick={() => openDeletePortfolioConfirmation(rawOpened)}
                          testId="delete-portfolio"
                          critical
                        />
                      </ActionMenu.ItemGroup>
                    </ActionMenu>
                  </div>
                </PermissionCheck>

                <AddPortfolioButton className={styles.loading_button_responsive} data-tour="new-portfolio" />
              </>
            )}
          </div>
        </PermissionCheck>
      </div>
      {rawOpened && (
        <>
          <div className={styles.new_header}>
            <div>
              <div className={styles.portfolio_info}>
                {(rawOpened.start || rawOpened.end) && (
                  <span className="order-0">
                    <DateLabel start={rawOpened.start} end={rawOpened.end} className={styles.dates_label} />
                  </span>
                )}

                <div className="d-flex align-items-center order-2 order-md-1">
                  <Text>
                    <strong>{rawOpened.computationType}</strong>
                  </Text>
                  <span className="mx-2">•</span>
                  <div className={cx(styles.dashboard_currency_wrapper, { [styles.shifted_currency_wrapper]: movePtfCurrency })}>
                    <div
                      className={cx(styles.dashboard_currency, styles.grid_display)}
                      style={{ gridTemplateColumns: "auto auto" }}>
                      <img className={styles.dashboard_currency_select} {...getCurrencyIconProps(currency?.value)} alt="" />{" "}
                      <strong>{currency?.label}</strong>
                    </div>
                  </div>
                  <span className="mx-2">•</span>
                  <Text style={{ maxWidth: isSmall ? "min-content" : "unset" }}>
                    <strong style={{ whiteSpace: "nowrap" }}>{taxRuleSetName}</strong>
                  </Text>
                </div>

                <div className={cx(styles.grid_display, "order-1 order-md-2")}>
                  <span>{t("dashboard.dashboard_header.convert_pairs.header")}:</span>
                  <strong>
                    {rawOpened.experimentalComputation
                      ? t("dashboard.dashboard_header.convert_pairs.on")
                      : t("dashboard.dashboard_header.convert_pairs.off")}
                  </strong>
                  <DynamicTooltip className="w-auto" position={isSmall ? "right" : "bottom"}>
                    <span
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{ __html: t("dashboard.convert_pairs_help") }}
                    />
                  </DynamicTooltip>
                </div>

                <div className={cx(styles.initial_state_info, "order-3")}>
                  <div>
                    {t("dashboard.dashboard_header.ptf_initial_balances")}:{" "}
                    <GhostButton
                      onClick={() => openEditPortfolio(rawOpened, undefined, 0, 0)}
                      className="d-inline mx-0 px-0"
                      labelClassName="mx-n1 px-0 pr-1"
                      critical={pairsCount === 0}
                      label={
                        <span style={isSmall ? { fontSize: "1rem" } : undefined}>
                          {isAvco ? (
                            <>
                              {pairsCount !== 0 ? `${pairsCount} ` : ""}
                              {pairsCount === 0
                                ? t("dashboard.dashboard_header.pairs_0")
                                : pairsCount === 1
                                ? t("dashboard.dashboard_header.pairs_1")
                                : pairsCount < 5
                                ? t("dashboard.dashboard_header.pairs_2")
                                : t("dashboard.dashboard_header.pairs_5")}
                            </>
                          ) : pairsCount !== 0 ? (
                            t("dashboard.dashboard_header.pairs_copied")
                          ) : (
                            t("dashboard.dashboard_header.pairs_0")
                          )}
                          ,
                        </span>
                      }
                    />
                    <GhostButton
                      onClick={() => openEditPortfolio(rawOpened, undefined, 0, 1)}
                      className="d-inline mx-0 px-0"
                      labelClassName="mx-n1 px-0"
                      critical={accountsCount === 0}
                      label={
                        <span style={isSmall ? { fontSize: "1rem" } : undefined}>
                          {accountsCount !== 0 ? `${accountsCount} ` : ""}
                          {accountsCount === 0
                            ? t("dashboard.dashboard_header.accounts_0")
                            : accountsCount === 1
                            ? t("dashboard.dashboard_header.accounts_1")
                            : accountsCount < 5
                            ? t("dashboard.dashboard_header.accounts_2")
                            : t("dashboard.dashboard_header.accounts_5")}
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {rawOpened?.note && <div className={styles.portfolio_note}>{rawOpened.note}</div>}
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isFetching: getPortfoliosAreFetching(state),
    portfolios: getOwnedPortfolios(state),
    fiatOptions: getFiatOptions(state),
    rawOpened: getRawOpenedPortfolio(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeading);
