import React from "react";
import { connect } from "react-redux";
import FiatsInventoryTable from "components/Dashboard/FiatsInventoryTable";
import { getFiatInventory, getGlobalFetchingState, getOpenedPortfolioBalances } from "selectors";
import { formatDate } from "common/formatters";
import { useTranslation } from "react-i18next";
import { Heading, LoaderOverlay } from "components/Common";
import FiatsInventoryTooltip from "components/Tooltips/FiatsInventoryTooltip";
import cx from "classnames";
import styles from "../Styles/Dashboard.module.scss";

const FiatsInventoryContainer = ({ isBalancesFetching = true, fiatsInventory, selectedDate }) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.master_table)} style={{ paddingTop: "2rem" }}>
      <div className={styles.flex_space_between}>
        <Heading level={3}>
          {t("dashboard.fiats_inventory.table_header")}{" "}
          {selectedDate && <span className={styles.small}>{`(${formatDate(selectedDate)})`}</span>}
        </Heading>
        <FiatsInventoryTooltip />
      </div>
      <LoaderOverlay isLoading={isBalancesFetching}>
        <FiatsInventoryTable {...fiatsInventory} />
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  const { timestamp } = getOpenedPortfolioBalances(state);
  const { isBalancesFetching, ...fiatsInventory } = getFiatInventory(state);

  return {
    fiatsInventory,
    selectedDate: timestamp,
    isBalancesFetching: isBalancesFetching === true && !getGlobalFetchingState(state),
  };
}

export default connect(mapStateToProps)(FiatsInventoryContainer);
