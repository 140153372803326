import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { ReactComponent as TooltipIcon } from "img/tooltip.svg";
import styles from "../Styles/Tooltips.module.scss";

const TooltipButton = ({ onClick, isAbsolute = false, walkthroughOn }) => (
  <button className={cx({ [styles.tooltip_button_absolute]: isAbsolute })} onClick={onClick} disabled={walkthroughOn}>
    <TooltipIcon />
  </button>
);

const mapStateToProps = state => ({
  walkthroughOn: state.walkthrough.portfolioIsOpen || state.walkthrough.dataManagerIsOpen,
});

export default connect(mapStateToProps)(TooltipButton);
