import React from "react";
import { FieldArray, Formik } from "formik";
import { Form, GhostButton, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { array, lazy, object, string } from "yup";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { Add as AddIcon, Close as CloseIcon } from "grommet-icons/icons";
import cx from "classnames";
import { TextFormField } from "components/Common/Inputs";
import { transactionService } from "services";
import styles from "../Styles/Forms.module.scss";

const SetAddressForTransactionsForm = ({
  transactions,
  user,
  modalActions: { hideModal },
  transactionActions: { getTransactions },
}) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    await transactions.forEachAsync(async transaction => {
      const { addressesStr, id, linkedTxs, transactionContainerId, transfer, quoteQuantity, ...rest } = transaction;

      if (linkedTxs?.length > 0) {
        rest.linkedTxIds = linkedTxs.map(x => x.portfolioTransaction?.id);
      }
      rest.addresses = values.addresses;

      await transactionService.editTransaction(rest, id, transactionContainerId, user);
    });

    hideModal();
    getTransactions();
  };

  return (
    <Formik
      initialValues={{
        addresses: [""],
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        addresses: lazy(val => (Array.isArray(val) ? array().of(string().required()) : string().required())),
      })}>
      {({ values, isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FieldArray
            name="addresses"
            render={arrayHelpers => (
              <>
                {values.addresses.map((address, index) => (
                  <div key={index}>
                    <TextFormField
                      name={`addresses.${index}`}
                      label={t("form.set_address_for_transactions.address")}
                      disabled={isSubmitting}>
                      {index > 0 && (
                        <span className="mt-n3 mr-2" onClick={() => arrayHelpers.remove(index)}>
                          <CloseIcon size="medium" />
                        </span>
                      )}
                    </TextFormField>
                    {index === values.addresses.length - 1 && (
                      <div className={cx(styles.buttons_row, "mt-n3")}>
                        <GhostButton
                          onClick={() => arrayHelpers.push("")}
                          label={t("form.set_address_for_transactions.add_address_button")}
                          disabled={isSubmitting}
                          icon={<AddIcon />}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          />
          <PrimaryButton label={t("form.set_address_for_transactions.submit")} onClick={handleSubmit} isLoading={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetAddressForTransactionsForm);
