import {
  GET_CONTAINER_JOB,
  DELETE_CONTAINER_JOB,
  UPDATE_UPLOAD_INFO,
  CONTAINER_UPLOADED,
  CONTAINER_UPLOADED_FAILED,
  CONTAINER_UPLOAD_INITIAL_REQUEST,
  LOGOUT,
  SET_CONTAINER_JOB_FETCHING,
} from "actions/types";

import { JOB_PROCESSING, JOB_ERROR } from "common/constants/jobStatusType";

import { CONTAINER_UPLOADING, CONTAINER_PROCESSING } from "common/constants/jobType";

const initialState = {
  containerJobs: {},
  isFetching: false,
  jobsFetching: {},
};

export default function jobReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_CONTAINER_JOB}_PENDING`:
      if (action.meta.initialJob && !state.containerJobs[action.meta.transactionContainerId]) {
        return {
          ...state,
          containerJobs: {
            ...state.containerJobs,
            [action.meta.transactionContainerId]: action.meta.initialJob,
          },
        };
      }
      return state;
    case `${GET_CONTAINER_JOB}_FULFILLED`:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.meta.transactionContainerId]: action.payload,
        },
      };
    case `${GET_CONTAINER_JOB}_REJECTED`:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.meta.transactionContainerId]: {
            ...(state.containerJobs[action.meta.transactionContainerId] || {}),
            jobStatus: JOB_ERROR,
          },
        },
      };
    // ====================================
    case SET_CONTAINER_JOB_FETCHING:
      return {
        ...state,
        jobsFetching: {
          ...state.jobsFetching,
          [action.meta.containerId]: action.meta.isFetching,
        },
      };
    // ====================================
    case UPDATE_UPLOAD_INFO:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.payload.transactionContainerId]: {
            ...state.containerJobs[action.payload.transactionContainerId],
            jobPercentage: action.payload.percentUploaded,
          },
        },
      };
    // ====================================
    case `${CONTAINER_UPLOAD_INITIAL_REQUEST}_FULFILLED`:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.payload.id]: {
            jobType: CONTAINER_UPLOADING,
            jobStatus: JOB_PROCESSING,
            jobPercentage: 0,
          },
        },
      };
    // ====================================
    case CONTAINER_UPLOADED:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.payload.container.id]: {
            jobType: CONTAINER_PROCESSING,
            jobStatus: JOB_PROCESSING,
            jobPercentage: 0,
          },
          [action.payload.oldContainerId]: null,
        },
      };
    // ====================================
    case CONTAINER_UPLOADED_FAILED:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.payload]: { ...state.containerJobs[action.payload], jobStatus: JOB_ERROR },
        },
      };
    // ====================================
    case DELETE_CONTAINER_JOB:
      return {
        ...state,
        containerJobs: {
          ...state.containerJobs,
          [action.payload]: null,
        },
      };
    //= ========================================
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
