import { createSelector } from "reselect";

export const getGlobalFetchingState = state =>
  state.user.isFetching ||
  state.user.isMembershipsFetching ||
  state.organizations.isFetching ||
  state.listOfPortfolios.isFetching ||
  state.marketData.isFetching ||
  state.jobs.isFetching ||
  state.marketData.isGeographicsFetching;

const shouldContainersConsoleLoading = state =>
  state.dataManager.isContainersFetching ||
  state.dataManager.isPortfolioContainerAssignmentFetching ||
  state.dataManager.isExportContainerCsvFetching;

export const isContainersConsoleLoading = createSelector(
  [shouldContainersConsoleLoading, getGlobalFetchingState],
  (containersConsole, global) => containersConsole && !global
);

const shouldContactsTableLoading = state => state.contacts.isContactsFetching;

export const isContactsTableLoading = createSelector(
  [shouldContactsTableLoading, getGlobalFetchingState],
  (contactsTable, global) => contactsTable && !global
);

const shouldAccountsTableLoading = state => state.accounts.isAccountsFetching;

export const isAccountsTableLoading = createSelector(
  [shouldAccountsTableLoading, getGlobalFetchingState],
  (accountsTable, global) => accountsTable && !global
);

const shouldAccountDetailLoading = state =>
  state.accounts.isDetailFetching || state.accounts.isAccountTransactionsFetching || state.dataManager.isContainersFetching;

export const isAccountDetailLoading = createSelector(
  [shouldAccountDetailLoading, getGlobalFetchingState],
  (accountDetail, global) => accountDetail && !global
);

const shouldBucketsConsoleLoading = state => state.buckets.isBucketsFetching;

export const isBucketsConsoleLoading = createSelector(
  [shouldBucketsConsoleLoading, getGlobalFetchingState],
  (bucketsConsole, global) => bucketsConsole && !global
);

const shouldOrganizationsLoading = state => state.user.isOrganizationsFetching;

export const isOrganizationsLoading = createSelector(
  [shouldOrganizationsLoading, getGlobalFetchingState],
  (organizations, global) => organizations && !global
);
