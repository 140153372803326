import React from "react";
import { useTranslation } from "react-i18next";
import { paymentPlans } from "common/constants/paymentPlans";
import TransactionCapacityGrid from "containers/TransactionCapacityGrid";
import styles from "./Organization.module.scss";
import { DateLabelEnd } from "../Dashboard/DateLabel";
import UpgradeButton from "containers/UpgradeButton";

const Subscription = ({ organization }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.organization_info}>
      <div className={styles.organization_tax_rules_grid}>
        <span>{t("organization.current_plan")}</span>
        <span className="d-flex align-items-center">
          {paymentPlans[organization.plan]?.name || ""} <UpgradeButton />
        </span>
        <span>{t("organizations.table_header.valid_to")}</span>
        <DateLabelEnd end={organization.planEnd} planName={organization.plan} noDatesLabel="~" />
        <TransactionCapacityGrid organization={organization} />
      </div>
    </div>
  );
};

export default Subscription;
