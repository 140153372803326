import React from "react";
import { Line, Bar } from "@vx/shape";
import { localPoint } from "@vx/event";
import { bisector } from "d3-array";
import { colors } from "common/colors";

const getIndex = ({ event, data, xScale, left }) => {
  const { x } = localPoint(event);
  const x0 = xScale.invert(x - left);
  const li = bisectDate(data, x0, 1, data.length);

  return li;
};

const bisectDate = bisector(d => d.timestamp).left;

const TooltipBar = ({
  setTooltipData,
  selectedDatePosition,
  tooltipData,
  history,
  xScale,
  fetchBalances,
  xMax,
  yMax,
  left,
  dayWidth,
}) => {
  const handleTooltip = ({ event, data, xScale, left }) => {
    if (history.length < 2) return;
    const li = getIndex({ event, data, xScale, left });

    setTooltipData({
      middle: xScale(data[li - 1].timestamp) + dayWidth / 2, // half day
      data: history[li - 1],
    });
  };

  const getHistoryData = ({ event, data, xScale, left }) => {
    if (history.length < 2) return;
    const li = getIndex({ event, data, xScale, left });

    if (history[li - 1]) {
      if (li === history.length - 1) fetchBalances(null);
      // Last day clicked -> same as View all history
      else fetchBalances(history[li - 1].timestamp.valueOf());
    }
  };

  return (
    <>
      <Bar
        x={0}
        y={0}
        width={xMax}
        height={yMax}
        fill="transparent"
        data={history}
        onTouchStart={data => event => handleTooltip({ event, data, xScale, left })}
        onTouchMove={data => event => handleTooltip({ event, data, xScale, left })}
        onMouseMove={data => event => handleTooltip({ event, data, xScale, left })}
        onMouseLeave={data => event => setTooltipData(null)}
        onClick={data => event => {
          getHistoryData({ event, data, xScale, left });
          // this.setState({fullOverflow: false, dottedOverflow: false});
        }}
      />
      {tooltipData && (
        <Line
          from={{ x: tooltipData.middle, y: 0 }}
          to={{ x: tooltipData.middle, y: yMax }}
          style={{ pointerEvents: "none" }}
          stroke={colors.text}
          strokeDasharray="3"
          strokeWidth={1.5}
        />
      )}
      {selectedDatePosition && (
        <Line
          from={{ x: selectedDatePosition, y: 0 }}
          to={{ x: selectedDatePosition, y: yMax }}
          style={{ pointerEvents: "none" }}
          stroke={colors.text}
          strokeWidth={1.5}
        />
      )}
    </>
  );
};

export default TooltipBar;
