import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationEnvelope from "components/Confirmation/ConfirmationEnvelope";

const DeleteAccount = ({ onConfirm, onCancel, accountName }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.change_plan.heading")}
      confirmLabel={t("confirmation.change_plan.confirm_label")}
      cancelLabel={t("button.leave")}
      critical>
      <p>{t("confirmation.change_plan.content")}</p>
    </ConfirmationEnvelope>
  );
};

export default DeleteAccount;
