import React from "react";
import { formatDate } from "common/formatters";
import { BUSINESS } from "common/constants/paymentPlans";
import { useTranslation } from "react-i18next";
import { Text } from "../Common";

const formatValidTo = (validTo, planName, t) =>
  validTo === "3000-01-01" && planName === BUSINESS
    ? t("organization.current_plan_forever")
    : validTo
    ? formatDate(validTo)
    : null;

const DateLabel = ({ start, end, planName, noDatesLabel = "", ...rest }) => {
  const { t } = useTranslation();
  const formattedEnd = formatValidTo(end, planName, t);
  const text = start && formattedEnd ? `${formatDate(start)} ~ ${formattedEnd}` : noDatesLabel;
  return <span {...rest}>{text}</span>;
};

export default DateLabel;

const DateLabelEnd = ({ end, planName, noDatesLabel = "", ...rest }) => {
  const { t } = useTranslation();
  const formattedEnd = formatValidTo(end, planName, t);
  const text = formattedEnd ?? noDatesLabel;
  return <Text {...rest}>{text}</Text>;
};

export { DateLabelEnd };
