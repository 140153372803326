export const REF_CODE = "REF_CODE";
export const MAIL_SUBSCRIBED = "mail_subscribed";
export const REBRANDING_DISPLAYED = "rebranding_displayed";

export const SHOW_RELEASE_NOTES = "SHOW_RELEASE_NOTES_8";

export const getLocalStorageValue = identifier => localStorage.getItem(identifier);

export const setLocalStorageValue = (identifier, value) => {
  localStorage.setItem(identifier, value);
};

export const resetLocalStorage = () => {
  localStorage.removeItem("persist:root");
};
