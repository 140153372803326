import { isEqual } from "lodash";
import { Set } from "immutable";
import {
  CHANGE_DATAMANAGER_FILTER,
  CLICK_CONTAINER,
  CONTAINER_EMPHASIZED,
  CONTAINER_UPLOAD_INITIAL_REQUEST,
  CONTAINER_UPLOADED,
  CONTAINER_UPLOADED_FAILED,
  CREATE_CONTAINER,
  DELETE_CONTAINER,
  DELETE_TRANSACTIONS,
  EXPORT_CONTAINER_CSV,
  GET_CONTAINER_DETAIL,
  GET_CONTAINER_DETAIL_ASYNCHRONOUS,
  GET_CONTAINERS,
  GET_CONTAINERS_ASYNCHRONOUS,
  GET_FILTER_OPTIONS,
  GET_TRANSACTIONS,
  HIDE_TRANSACTIONS,
  LABEL_TRANSACTIONS,
  LOGOUT,
  PORTFOLIO_CONTAINER_ASSIGNMENT,
  SELECT_MULTIPLE_CONTAINERS,
  SET_ACTIVE_MANUAL_CONTAINER_SYNC_IDS,
  SET_CONTAINERS_IN_DELETION,
  SET_EDIT_MODE,
  SET_EXTENDED_CONTAINER_DETAIL,
  SET_IS_CONTAINER_SYNC_ACTIVE,
  SET_IS_DATA_MANAGER_LOADING,
  SET_LAST_USED_CURRENCIES,
  SET_TRANSACTIONS_FILTER,
  SWITCH_PORTFOLIO,
  UNSELECT_ALL_CONTAINERS,
  UPDATE_CONNECTION,
} from "actions/types";
import { CONTAINER_CSV, containerTypes } from "common/constants/containerTypes";
import { useDefaultsStore } from "stores/defaultsStore";
import { reduceIntArray } from "utils";
import { defaultExtendedContainerDetail } from "common/constants/defaultExtendedContainerDetail";

const initialState = {
  transactions: [],
  containers: [],
  totalTransactions: 0,
  selectedPortfolio: false,
  isEditMode: false,
  filters: {
    containerType: containerTypes[0],
    containerName: "",
    page: 0,
    count: useDefaultsStore.getState().perPageDefault,
    portfolioFiltersApplied: true,
  },
  containerSelection: {},
  lastSelectedContainer: null, // for container selection using shift
  portfolioContainerAssignments: {},
  isPortfolioContainerAssignmentFetching: false,
  isContainersFetching: false,
  isContainersAsyncFetching: false,
  containerDetailFetching: {},
  containerDetail: {},
  extendedContainerDetail: defaultExtendedContainerDetail, // additional data fetched only, when according tabs are opened
  isTransactionsFetching: false,
  isDataManagerLoading: false,
  filterOptions: null,
  transactionsFilter: null,
  isContainerSyncActive: false,
  activeManualContainerSyncIds: new Set(), // for waiting for backend calls, when sync status is still OK or ERROR
  containersInDeletion: new Set(),
  lastUsedCurrencies: [],
  isExportContainerCsvFetching: false,
};

const mapContainer = ({ exchangeId, portfolioIds, displayedFilename, ...rest }) => ({
  ...rest,
  exchangeName: exchangeId,
  assignedPortfolios: portfolioIds,
});

export default function dataManagerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_DATA_MANAGER_LOADING:
      return {
        ...state,
        isDataManagerLoading: action.payload,
      };
    // ====================================
    case `${GET_CONTAINERS}_PENDING`:
      return {
        ...state,
        isContainersFetching: true,
      };
    case `${GET_CONTAINERS}_FULFILLED`: {
      const uploading = state.containers.filter(o => o.isUploading);
      return {
        ...state,
        isContainersFetching: false,
        containers: action.payload.map(mapContainer).concat(uploading),
      };
    }
    case `${GET_CONTAINERS}_REJECTED`:
      return {
        ...state,
        isContainersFetching: false,
      };
    // ====================================
    case `${GET_CONTAINERS_ASYNCHRONOUS}_PENDING`:
      return {
        ...state,
        isContainersAsyncFetching: true,
      };
    case `${GET_CONTAINERS_ASYNCHRONOUS}_FULFILLED`: {
      const uploading = state.containers.filter(o => o.isUploading);
      const updatedContainers = action.payload // preserve tmpIds on background reload to avoid duplicate display of containers
        .map(o => {
          const oldContainer = state.containers.find(x => x.id === o.id);
          return {
            ...mapContainer(o),
            ...(oldContainer?.tmpId && { tmpId: oldContainer.tmpId }),
          };
        })
        .concat(uploading);

      return {
        ...state,
        isContainersFetching: false,
        isContainersAsyncFetching: false,
        // Only update containers reference if status of some changes
        ...(!isEqual(state.containers, updatedContainers) && { containers: updatedContainers }),
      };
    }
    case `${GET_CONTAINERS_ASYNCHRONOUS}_REJECTED`:
      return {
        ...state,
        isContainersAsyncFetching: false,
      };
    // ====================================
    case `${GET_FILTER_OPTIONS}_FULFILLED`:
      return {
        ...state,
        filterOptions: action.payload,
      };
    // ====================================
    case SET_TRANSACTIONS_FILTER:
      return {
        ...state,
        transactionsFilter: action.payload,
      };
    // ====================================
    case SET_IS_CONTAINER_SYNC_ACTIVE:
      return {
        ...state,
        isContainerSyncActive: action.payload,
      };
    case SET_ACTIVE_MANUAL_CONTAINER_SYNC_IDS:
      return {
        ...state,
        activeManualContainerSyncIds: action.payload,
      };
    // ====================================
    case `${GET_CONTAINER_DETAIL}_PENDING`:
      return {
        ...state,
        containerDetailFetching: {
          ...state.containerDetailFetching,
          [action.meta.containerId]: true,
        },
      };
    case `${GET_CONTAINER_DETAIL}_FULFILLED`:
      return {
        ...state,
        containerDetail: { ...state.containers.find(x => x.id === action.payload.id), ...action.payload },
        containerDetailFetching: {
          ...state.containerDetailFetching,
          [action.meta.containerId]: false,
        },
      };
    case `${GET_CONTAINER_DETAIL}_REJECTED`:
      return {
        ...state,
        containerDetailFetching: {
          ...state.containerDetailFetching,
          [action.meta.containerId]: false,
        },
      };
    case `${GET_CONTAINER_DETAIL_ASYNCHRONOUS}_FULFILLED`:
      return {
        ...state,
        containerDetail: { ...state.containers.find(x => x.id === action.payload.id), ...action.payload },
      };
    // ====================================
    case SET_EXTENDED_CONTAINER_DETAIL:
      return {
        ...state,
        extendedContainerDetail:
          state.extendedContainerDetail.id === action.meta.containerId
            ? { ...state.extendedContainerDetail, ...action.payload }
            : { ...defaultExtendedContainerDetail, id: action.meta.containerId, ...action.payload },
      };
    // ====================================
    case SWITCH_PORTFOLIO:
      return {
        ...state,
        selectedPortfolio: !!action.payload,
        filters: {
          ...state.filters,
          portfolioFiltersApplied: true,
          page: 0,
        },
      };
    // ====================================
    case `${CREATE_CONTAINER}_PENDING`:
      return {
        ...state,
        portfolioContainerAssignments:
          [action.meta.portfolioId] && !state.portfolioContainerAssignments[action.meta.portfolioId]
            ? {
                ...state.portfolioContainerAssignments,
                [action.meta.portfolioId]: [],
              }
            : state.portfolioContainerAssignments, // Create empty array if doesn't exist
      };
    case `${CREATE_CONTAINER}_FULFILLED`:
      return {
        ...state,
        containers: [...state.containers, mapContainer(action.payload)],
        portfolioContainerAssignments: [action.meta.portfolioId]
          ? {
              ...state.portfolioContainerAssignments,
              [action.meta.portfolioId]: [...state.portfolioContainerAssignments[action.meta.portfolioId], action.payload.id],
            }
          : state.portfolioContainerAssignments,
      };
    // ====================================
    case `${UPDATE_CONNECTION}_FULFILLED`:
      return {
        ...state,
        containers: state.containers.map(o =>
          o.id === action.meta.containerId
            ? {
                ...o,
                ...action.payload,
              }
            : o
        ),
      };
    // ====================================
    case `${DELETE_CONTAINER}_FULFILLED`:
      return {
        ...state,
        containers: state.containers.filter(o => o.id !== action.meta.containerId),
        containerSelection: { ...state.containerSelection, [action.meta.containerId]: false },
      };
    // ====================================
    case `${SET_CONTAINERS_IN_DELETION}`:
      return {
        ...state,
        containersInDeletion: action.payload,
      };
    // ====================================
    case `${GET_TRANSACTIONS}_PENDING`:
      return {
        ...state,
        isTransactionsFetching: true,
        filters: {
          ...state.filters,
          page: 0,
        },
      };
    case `${GET_TRANSACTIONS}_FULFILLED`:
      return {
        ...state,
        isTransactionsFetching: false,
        filters: {
          ...state.filters,
          page: action.payload.meta.page,
        },
        totalTransactions: action.payload.meta.total,
        transactions: action.payload.data,
      };
    case `${GET_TRANSACTIONS}_REJECTED`:
      return {
        ...state,
        isTransactionsFetching: false,
      };
    // ====================================
    case `${PORTFOLIO_CONTAINER_ASSIGNMENT}_PENDING`:
      return {
        ...state,
        isPortfolioContainerAssignmentFetching: true,
      };
    case `${PORTFOLIO_CONTAINER_ASSIGNMENT}_FULFILLED`:
      return {
        ...state,
        isPortfolioContainerAssignmentFetching: false,
        portfolioContainerAssignments: {
          ...state.portfolioContainerAssignments,
          [action.meta.portfolioId]: action.payload,
        },
        containerSelection: reduceIntArray(action.payload.filter(o => o.type === state.filters.containerType).map(o => o.id)), // Select assigned containers right away
        isEditMode: action.meta.isEdit ? false : state.isEditMode, // end edit mode in case of successfull update
      };
    case `${PORTFOLIO_CONTAINER_ASSIGNMENT}_REJECTED`:
      return {
        ...state,
        isPortfolioContainerAssignmentFetching: false,
      };
    // ====================================
    case `${CONTAINER_UPLOAD_INITIAL_REQUEST}_PENDING`:
      return {
        ...state,
        isContainersFetching: true,
      };
    case `${CONTAINER_UPLOAD_INITIAL_REQUEST}_FULFILLED`:
      return {
        ...state,
        containers: [
          ...state.containers,
          {
            id: action.payload.id,
            name: action.payload.containerName,
            isUploading: true,
            type: CONTAINER_CSV,
          },
        ],
        isContainersFetching: false,
        portfolioContainerAssignments: [action.meta.portfolioId]
          ? {
              // assign with tmpId
              ...state.portfolioContainerAssignments,
              [action.meta.portfolioId]: [
                ...(state.portfolioContainerAssignments[action.meta.portfolioId] || []),
                action.payload.id,
              ],
            }
          : state.portfolioContainerAssignments,
      };
    case `${CONTAINER_UPLOAD_INITIAL_REQUEST}_REJECTED`:
      return {
        ...state,
        isContainersFetching: false,
      };

    // ====================================
    case `${LABEL_TRANSACTIONS}_PENDING`:
      return {
        ...state,
        isTransactionsFetching: true,
      };
    case `${LABEL_TRANSACTIONS}_FULFILLED`:
      return {
        ...state,
        isTransactionsFetching: false,
      };
    case `${LABEL_TRANSACTIONS}_REJECTED`:
      return {
        ...state,
        isTransactionsFetching: false,
      };
    // ====================================
    case `${DELETE_TRANSACTIONS}_PENDING`:
      return {
        ...state,
        isTransactionsFetching: true,
      };
    case `${DELETE_TRANSACTIONS}_FULFILLED`:
      return {
        ...state,
        isTransactionsFetching: false,
      };
    case `${DELETE_TRANSACTIONS}_REJECTED`:
      return {
        ...state,
        isTransactionsFetching: false,
      };
    // ====================================
    case SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload,
        containerSelection: action.meta.portfolioId
          ? reduceIntArray(state.portfolioContainerAssignments[action.meta.portfolioId] || [])
          : state.isEditMode && !action.payload
          ? {} // Delete ptf filter while edit mode on
          : state.containerSelection,
        filters: action.payload
          ? state.filters
          : {
              ...state.filters,
              portfolioFiltersApplied: true,
            },
      };
    // ====================================
    case CONTAINER_UPLOADED: // replaces tmp container object with the real one with all the properties
      return {
        ...state,
        containers: state.containers.map(o =>
          o.id === action.payload.oldContainerId
            ? {
                ...mapContainer(action.payload.container),
                tmpId: action.payload.oldContainerId, // To avoid unmount & mount of container Component
              }
            : o
        ),
        portfolioContainerAssignments: [action.meta.portfolioId]
          ? {
              // assign with tmpId
              ...state.portfolioContainerAssignments,
              [action.meta.portfolioId]: [
                ...state.portfolioContainerAssignments[action.meta.portfolioId].filter(o => o !== action.payload.id),
                action.payload.container.id,
              ], // remove tmpId and add new Id
            }
          : state.portfolioContainerAssignments,
      };
    // ====================================
    case CONTAINER_EMPHASIZED:
      return {
        ...state,
        containers: state.containers.map(o =>
          o.id === action.payload.id
            ? {
                ...o,
                emphasized: action.payload.emphasized,
              }
            : o
        ),
      };
    // ====================================
    case CONTAINER_UPLOADED_FAILED:
      return {
        ...state,
        containers: state.containers.filter(o => o.id !== action.payload),
      };
    // ====================================
    case CLICK_CONTAINER:
      if (action.payload.shiftDown && state.lastSelectedContainer) {
        const newSelection = { ...state.containerSelection };
        let isApplied = false;

        action.payload.visibleContainers.forEach(o => {
          if (!isApplied && o.id !== action.payload.containerId && o.id !== state.lastSelectedContainer) return;
          if (o.id === action.payload.containerId || o.id === state.lastSelectedContainer) isApplied = !isApplied;
          newSelection[o.id] = true;
        });

        return {
          ...state,
          lastSelectedContainer: action.payload.containerId,
          containerSelection: newSelection,
        };
      }
      return action.payload.ctrlDown
        ? {
            ...state,
            containerSelection: {
              ...state.containerSelection,
              [action.payload.containerId]: !state.containerSelection[action.payload.containerId],
            },
            lastSelectedContainer: action.payload.containerId,
          }
        : {
            ...state,
            containerSelection: { [action.payload.containerId]: true },
            lastSelectedContainer: action.payload.containerId,
          };
    // ====================================
    case SELECT_MULTIPLE_CONTAINERS:
      return {
        ...state,
        containerSelection: reduceIntArray(action.payload),
      };
    // ====================================
    case UNSELECT_ALL_CONTAINERS:
      return {
        ...state,
        containerSelection: {},
      };
    // ====================================
    case CHANGE_DATAMANAGER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
          page: action.payload.page === undefined ? 0 : action.payload.page,
        },
      };
    // ====================================
    case SET_LAST_USED_CURRENCIES:
      return {
        ...state,
        lastUsedCurrencies: action.payload,
      };
    // ====================================
    case HIDE_TRANSACTIONS:
      return {
        ...state,
        transactions: [],
        totalTransactions: 0,
        isTransactionsFetching: false,
        filters: {
          ...state.filters,
          page: 0,
        },
      };
    case `${EXPORT_CONTAINER_CSV}_PENDING`:
      return {
        ...state,
        isExportContainerCsvFetching: true,
      };
    case `${EXPORT_CONTAINER_CSV}_FULFILLED`:
      return {
        ...state,
        isExportContainerCsvFetching: false,
      };
    case `${EXPORT_CONTAINER_CSV}_REJECTED`:
      return {
        ...state,
        isExportContainerCsvFetching: false,
      };
    // ====================================
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
