import React from "react";
import { LARGE, MEDIUM, SMALL } from "common/constants/screenSizes";

import styles from "./Components.module.scss";

const classMapper = {
  [LARGE]: styles.spinner_big,
  [MEDIUM]: styles.spinner_medium,
  [SMALL]: styles.spinner_small,
};

const Spinner = ({ size = SMALL }) => <div className={classMapper[size]} />;

export default Spinner;
