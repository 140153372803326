import React from "react";
import { useTranslation } from "react-i18next";
import { WIDE } from "common/constants/modalWidths";
import ModalEnvelope from "./ModalEnvelope";
import SetAddressesForTransactionsForm from "../Forms/SetAddressForTransactionsForm";

const SetAddressForTransactionsModal = ({ transactions }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope width={WIDE} id="set-address-for-tx-modal" heading={t("modal.set_address_for_transactions.heading")}>
      <SetAddressesForTransactionsForm transactions={transactions} />
    </ModalEnvelope>
  );
};

export default SetAddressForTransactionsModal;
