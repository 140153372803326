import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Alert as WarningIcon } from "grommet-icons";
import { mixed, object, string } from "yup";
import { CheckBoxFormField, PasswordFormField, SelectFormField, TextFormField } from "components/Common/Inputs";
import { Form, GhostButton, PrimaryButton, Text } from "components/Common";
import { BOOLEAN, FIAT_CURRENCY, SECRET, STRING } from "common/constants/dynamicFieldTypes";
import { apiConnectionInputsTranslator } from "common/constants/apiConnectionInputs";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../Styles/Forms.module.scss";
import { components } from "react-select";
import history from "common/history";

const simpleFieldTypes = {
  [STRING]: TextFormField,
  [SECRET]: PasswordFormField,
};

const ApiContainerForm = ({
  connectors,
  isConnectorsFetching,
  onSubmit,
  fiatOptions,
  referenceOptions,
  activeOrganizationId,
  onClose,
}) => {
  const { t } = useTranslation();

  const connectorOptions = useMemo(
    () =>
      connectors.map(o => ({
        value: o.name,
        label: (
          <span className="d-flex align-items-center">
            {o.image ? (
              <img style={{ borderRadius: "4px" }} src={o.image} alt="" height="20" width="20" className="mr-2" />
            ) : (
              <span className="mr-4 ml-1" />
            )}
            {o.name}
          </span>
        ),
        id: o.id,
        parameters: o.parameters,
        note: o.note,
      })),
    [connectors]
  );
  const [selectedConnectorParameters, setSelectedConnectorParameters] = useState([]);
  const [connectorNote, setConnectorNote] = useState("");

  const getSelectedConnectorParametersValidationSchema = () =>
    object({
      name: string().required(),
      connectorType: object().required(),
      parameters: object(
        Object.fromEntries(selectedConnectorParameters.filter(x => x.type !== BOOLEAN).map(x => [x.id, mixed().required()]))
      ),
    });

  return (
    <Formik
      initialValues={{ name: "", connectorType: "", source: "", parameters: {}, references: [] }}
      validationSchema={getSelectedConnectorParametersValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit({ ...values, automaticSynchronization: true, references: values.references?.map(x => x.reference) });
        setSubmitting(false);
      }}>
      {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <TextFormField name="name" label={t("form.create_api.title")} required disabled={isSubmitting} autoFocus />
          <SelectFormField
            name="connectorType"
            options={connectorOptions}
            label={t("form.create_api.connector")}
            required
            disabled={isSubmitting}
            onSelect={val => {
              setSelectedConnectorParameters(val.parameters);
              setConnectorNote(val.note);
              const params = val.parameters.reduce((res, val) => {
                res[val.id] = val.type === BOOLEAN ? !!val.selectedByDefault : "";
                return res;
              }, {});
              setFieldValue("parameters", params);
            }}
            isLoading={isConnectorsFetching}
          />

          {values.connectorType && (
            <>
              <TextFormField name="source" label={t("form.create_api.source")} disabled={isSubmitting} />
              <SelectFormField
                name="references"
                label={t("form.reference_detail.name")}
                options={referenceOptions}
                disabled={isSubmitting}
                isClearable
                isMulti
                components={{
                  Menu: props => (
                    <components.Menu {...props}>
                      {props.children}
                      <div className="my-1">
                        <GhostButton
                          labelClassName={styles.request_more_currencies_label}
                          label={`+ ${t("form.container_detail.add_new_reference")}`}
                          onClick={() => {
                            onClose();
                            history.push(`/organizations/${activeOrganizationId}/lists?add=true`);
                          }}
                        />
                      </div>
                    </components.Menu>
                  ),
                }}
              />
            </>
          )}

          {connectorNote && (
            <div className={styles.field_note}>
              <WarningIcon size="small" color="yellow" />
              <Text size="small">{t(connectorNote)}</Text>
            </div>
          )}
          {selectedConnectorParameters.map((o, index) => {
            const props = {
              key: o.id,
              name: `parameters.${o.id}`,
              label: t(apiConnectionInputsTranslator(o.label)),
              disabled: isSubmitting,
              required: o.type !== BOOLEAN,
            };

            if (o.type === FIAT_CURRENCY) {
              return <CurrencySelectFormField key={index} {...props} options={fiatOptions} disableOptimized />;
            }
            if (o.type === BOOLEAN) {
              return <CheckBoxFormField key={index} {...props} wrapperClassName={styles.api_container_form_checkbox} />;
            }
            const Field = simpleFieldTypes[o.type];
            return Field ? <Field key={index} {...props} /> : null;
          })}
          <PrimaryButton label={t("form.create_api.submit")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default ApiContainerForm;
