import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { accountActions } from "actions/accountActions";
import { reportActions } from "actions/reportActions";
import { connect } from "react-redux";
import moment from "moment";
import { getActivePortfolioId, getOpenedPortfolioReportParams } from "selectors";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { colors } from "common/colors";
import { PrimaryButton } from "components/Common";
import { useScreenSize } from "hooks";
import { useTranslation } from "react-i18next";
import GhostButton from "components/Common/Buttons/GhostButton";
import { ActionMenu } from "components/Common/ActionMenu";

const ExportAccount = ({
  isGhostButton = false,
  isPrimaryButton,
  testId,
  accountId,
  activePortfolioId,
  openedPortfolioParams,
  isLoading,
  reportActions: { downloadAccountsStatementReport },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const { reportFromDefault, reportToDefault } = openedPortfolioParams;

  const [from, setFrom] = useState(reportFromDefault ? moment.utc(reportFromDefault).valueOf() : null);
  const [to, setTo] = useState(reportToDefault ? moment(reportToDefault).valueOf() : null);

  useEffect(() => {
    setFrom(reportFromDefault ? moment.utc(reportFromDefault).valueOf() : null);
    setTo(reportToDefault ? moment.utc(reportToDefault).valueOf() : null);
  }, [reportFromDefault, reportToDefault]);

  const handleOnClick = () => {
    downloadAccountsStatementReport(activePortfolioId, from, to, [accountId]);
  };

  return isPrimaryButton ? (
    <PrimaryButton
      icon={<FontAwesomeIcon icon={faDownload} color={colors.white} />}
      onClick={handleOnClick}
      testId={testId}
      label={t("account_detail.transactions.export")}
      className={cx("ml-lg-3 ml-1 mt-3 mt-lg-0", { "w-auto": isSmall })}
      isLoading={isLoading}
    />
  ) : (
    <ActionMenu.Item
      onClick={handleOnClick}
      testId={testId}
      icon={<FontAwesomeIcon icon={faDownload} />}
      label={t("account_detail.transactions.export")}
      className="mx-0 px-0"
      disabled={isLoading}>
      <GhostButton />
    </ActionMenu.Item>
  );
};

function mapStateToProps(state) {
  return {
    activePortfolioId: getActivePortfolioId(state),
    openedPortfolioParams: getOpenedPortfolioReportParams(state),
    isLoading: state.reports.accountsStatementReportDownloading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportAccount);
