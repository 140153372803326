import React from "react";
import { Formik } from "formik";
import { TextFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { alertActions } from "actions/alertActions";
import { bindActionCreators } from "redux";
import { validateEmailSubscription } from "common/validators";
import { useTranslation } from "react-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const EmailSubscriptionForm = ({
  // TODO: Use JsonP instead of MailchimpSubscribe
  modalActions,
  onClose,
}) => {
  const url = `https://generalbytes.us11.list-manage.com/subscribe/post?u=${document.mailChimpUserId}&id=${document.mailChimpAudienceId}`;
  const { t } = useTranslation();
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe }) => (
        <div>
          <Formik
            initialValues={{ EMAIL: "", FNAME: "" }}
            validate={values => validateEmailSubscription(values)}
            onSubmit={async values => {
              await subscribe(values);
              onClose();
              modalActions.hideModal();
              alertActions.success(t("alert.success.email_subscribed"));
            }}>
            {({ isSubmitting, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <TextFormField
                  name="EMAIL"
                  label={t("form.email_subscribtion.email")}
                  disabled={isSubmitting}
                  required
                  autoFocus
                  autoComplete="email"
                />
                <TextFormField
                  name="FNAME"
                  label={t("form.email_subscribtion.name")}
                  disabled={isSubmitting}
                  autoComplete="firstName"
                />
                <PrimaryButton label={t("form.email_subscribtion.subscribe")} isLoading={isSubmitting} onClick={handleSubmit} />
              </Form>
            )}
          </Formik>
        </div>
      )}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(EmailSubscriptionForm);
