import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationEnvelope from "components/Confirmation/ConfirmationEnvelope";

const DeleteRuleSet = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("modal.rule_set.heading_delete")}
      confirmLabel={t("button.delete")}
      critical>
      <p>
        <Trans i18nKey="modal.rule_set.delete.body" />
      </p>
    </ConfirmationEnvelope>
  );
};

export default DeleteRuleSet;
