import React from "react";
import { connect } from "react-redux";
import { userActions } from "actions/userActions";
import { modalActions } from "actions/modalActions";
import { bindActionCreators } from "redux";
import { Text } from "components/Common";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import UserCategoryForm from "../Forms/UserCategoryForm";

const SelectUserCategoryModal = ({ userActions, ...props }) => {
  const { t } = useTranslation();

  const onSubmit = async (values, { setSubmitting }) => {
    await userActions.editProfile(values);
    setSubmitting(false);
  };

  return (
    <ModalEnvelope id="user-category-modal" heading={t("modal.user_category.heading")}>
      <Text>{t("modal.user_category.message")}</Text>
      <UserCategoryForm {...props} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SelectUserCategoryModal);
