import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Add as AddIcon, Edit as EditIcon, Trash as TrashIcon, View as ViewIcon } from "grommet-icons/icons";
import { useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sortBy } from "lodash";
import { Heading, LoaderOverlay, PrimaryButton, Table } from "components/Common";
import styles from "pages/Pages.module.scss";
import history from "common/history";
import { modalActions } from "actions/modalActions";
import { accountTypesTranslator } from "common/constants/accountTypes";
import { accountActions } from "actions/accountActions";
import {
  getActivePortfolioId,
  getActivePortfolioIdSearchUrl,
  getFiatOptions,
  getGlobalFetchingState,
  getOpenedPortfolioReportParamsFetching,
  isAccountsTableLoading,
} from "selectors";
import GhostButton from "components/Common/Buttons/GhostButton";
import { getCurrencyIconProps } from "utils/currencyIcon";
import { useAddItem } from "hooks/useAddItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faGear } from "@fortawesome/free-solid-svg-icons";
import ExportAccount from "containers/Accounts/ExportAccount";
import { ActionMenu } from "components/Common/ActionMenu";
import { confirmationActions } from "actions/confirmationActions";
import { useScreenSize } from "hooks";
import { reportActions } from "actions/reportActions";

const Accounts = ({
  accounts,
  isLoading,
  modalActions,
  portfolioIdSearch,
  fiatOptions,
  activePortfolioId,
  modalActions: { openEditAssetAccount, openCreateAccount },
  accountActions: { getAccounts },
  confirmationActions: { openDeleteAccountConfirmation },
  reportActions: { fetchParams },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();
  const match = useRouteMatch();

  useAddItem(openCreateAccount);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  // Needed for account export (from, to)
  useEffect(() => {
    if (activePortfolioId) fetchParams(activePortfolioId);
  }, [activePortfolioId, fetchParams]);

  const sortedAccounts = useMemo(() => {
    const accountsWithCurrencyType = accounts.map((x, index) => ({
      ...x,
      // Fiat first, then crypto
      currencyType: fiatOptions.some(y => y.value === x.currency) ? "FIAT" : "KRYPTO",
      lowerCaseName: x.name.toLowerCase(),
    }));

    return sortBy(accountsWithCurrencyType, x => [x.lowerCaseName, x.currencyType, x.currency]);
  }, [accounts, fiatOptions]);

  const columns = useMemo(
    () => [
      {
        Header: t("accounts.table_header.currency"),
        accessor: "currency",
        Cell: ({ value }) => (
          <div className={styles.currency_image}>
            <img {...getCurrencyIconProps(value)} alt={value} /> {value}
          </div>
        ),
        width: 65,
      },
      {
        Header: t("accounts.table_header.account_name"),
        accessor: "name",
        width: 150,
      },
      {
        Header: t("accounts.table_header.type"),
        accessor: "type",
        Cell: ({ value }) => t(accountTypesTranslator(value)),
        width: 80,
      },
      {
        Header: t("accounts.table_header.admin"),
        accessor: "administeredBy",
        width: 80,
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("account_detail.table_header.action")}</div>,
        id: "action",
        sticky: true,
        width: isSmall ? 30 : 60,
        Cell: ({ row }) => {
          const { original, index } = row;
          const rowIndex = index + 1;
          const { id, name } = original;

          return (
            <div className={styles.action_cell}>
              <GhostButton
                icon={<ViewIcon />}
                onClick={() => history.push(`${match.url}/${id}/transactions${portfolioIdSearch}`)}
                actionButton
                testId={`detail_${name}`}
              />

              <ActionMenu testId="asset-accounts-menu">
                <ActionMenu.ItemGroup key={1}>
                  <ActionMenu.Item
                    icon={<ViewIcon />}
                    label={t("account_detail.transactions.heading")}
                    onClick={() => history.push(`/asset-accounts/${id}/transactions${portfolioIdSearch}`)}
                    testId={`action-account-transactions_row-${rowIndex}`}
                  />

                  <ActionMenu.Item
                    icon={<EditIcon />}
                    label={t("account_detail.general_data.edit_button")}
                    onClick={() => openEditAssetAccount(id)}
                    testId={`action-account-edit_row-${rowIndex}`}
                  />

                  <ActionMenu.Item
                    icon={<FontAwesomeIcon icon={faAddressBook} />}
                    label={t("account_detail.addresses.heading")}
                    onClick={() => history.push(`/asset-accounts/${id}/addresses${portfolioIdSearch}`)}
                    testId={`action-account-addresses_row-${rowIndex}`}
                  />

                  <ActionMenu.Item
                    icon={<FontAwesomeIcon icon={faGear} />}
                    label={t("account_detail.general_data.heading")}
                    onClick={() => history.push(`/asset-accounts/${id}/general${portfolioIdSearch}`)}
                    testId={`action-account-settings_row-${rowIndex}`}
                  />

                  <ExportAccount accountId={id} testId={`action-account-export_row-${rowIndex}`} />
                </ActionMenu.ItemGroup>

                <ActionMenu.ItemGroup hasSeparator key={2}>
                  <ActionMenu.Item
                    icon={<TrashIcon />}
                    critical
                    label={t("button.delete")}
                    onClick={() => openDeleteAccountConfirmation({ accountId: id, accountName: name })}
                    testId={`action-account-delete_row-${rowIndex}`}
                  />
                </ActionMenu.ItemGroup>
              </ActionMenu>
            </div>
          );
        },
      },
    ],
    [t, portfolioIdSearch, openDeleteAccountConfirmation, isSmall]
  );

  return (
    <div className={styles.app_content_padding}>
      <div className="w-100 d-flex justify-content-between align-items-center mb-3 flex-column flex-md-row align-items-baseline align-items-md-center">
        <Heading level={2} className="mb-2 mb-md-0">
          {t("accounts.heading")}
        </Heading>

        <PrimaryButton
          onClick={openCreateAccount}
          label={t("accounts.create_account_button")}
          icon={<AddIcon color="white" />}
          style={{ width: "fit-content" }}
        />
      </div>

      <LoaderOverlay isLoading={isLoading}>
        <Table
          data={sortedAccounts}
          columns={columns}
          stickyHeader
          minWidth={800}
          calculateWiderBorder={(row, rowIndex) => {
            const hasWiderBorder = rowIndex < sortedAccounts.length - 1 && row.name !== sortedAccounts[rowIndex + 1].name;
            return hasWiderBorder ? "2px" : undefined;
          }}
        />

        {accounts?.length === 0 && (
          <div className={styles.empty_table_row}>
            <Heading level={4} margin="1rem 0 0" color="gray3">
              {t("accounts.accounts_table.no_records_message")}
            </Heading>
          </div>
        )}
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    accounts: state.accounts.accounts,
    isLoading: (isAccountsTableLoading(state) || getOpenedPortfolioReportParamsFetching(state)) && !getGlobalFetchingState(state),
    activePortfolioId: getActivePortfolioId(state),
    portfolioIdSearch: getActivePortfolioIdSearchUrl(state),
    fiatOptions: getFiatOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
