import React, { useEffect, useState } from "react";
import { FormDown as DownIcon } from "grommet-icons";
import cx from "classnames";

import { useScreenSize } from "hooks";
import styles from "./Components.module.scss";

const Menu = ({ label, children, className, listClassname, disabled, onOpenChanged }) => {
  const { isSmall } = useScreenSize();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (onOpenChanged) onOpenChanged(expanded);
  }, [expanded, onOpenChanged]);

  return (
    <div
      onMouseEnter={() => !isSmall && setExpanded(true)}
      onMouseLeave={() => !isSmall && setExpanded(false)}
      onClick={() => setExpanded(prevState => !prevState)}
      className={cx(styles.menu, className, { [styles.menu_disabled]: disabled })}>
      <span>
        {label}
        <DownIcon />
      </span>
      {expanded && !disabled && (
        <div onClick={() => setExpanded(false)} className={cx(styles.content, listClassname)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Menu;
