import React from "react";
import { useTranslation } from "react-i18next";
import DynamicTooltip from "./DynamicTooltip";

export default props => {
  const { t } = useTranslation();
  return (
    <DynamicTooltip {...props} className="py-3">
      <div>
        <h5>{t("tooltip.dashboard.main_graph.portfolio_history.heading")}</h5>
        {t("tooltip.dashboard.main_graph.portfolio_history.label")}
      </div>
      <div className="mt-3">
        <h5>{t("tooltip.dashboard.main_graph.profit_history.heading")}</h5>
        {t("tooltip.dashboard.main_graph.profit_history.label")}
      </div>
      <div className="mt-3">
        <h5>{t("tooltip.dashboard.main_graph.time_period.heading")}</h5>
        {t("tooltip.dashboard.main_graph.time_period.label")}
      </div>
    </DynamicTooltip>
  );
};
