import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import NewPortfolioExplanation from "components/Modals/ModalExplanations/NewPortfolioExplanation";
import { portfolioActions } from "actions/portfolioActions";
import { modalActions } from "actions/modalActions";
import { WIDER } from "common/constants/modalWidths";
import { AVCO } from "common/constants/computationTypes";
import { formatDate } from "common/formatters";
import { getActiveOrganizationDetail, getActiveOrganizationId, getRuleSets } from "selectors";
import { useScreenSize } from "hooks";
import { LoaderOverlay } from "components/Common";
import { getLabels } from "queries/transactions";
import PortfolioDetailForm from "../Forms/PortfolioDetailForm";
import ModalEnvelope from "./ModalEnvelope";

const portfolioValuesTransform = ({
  name,
  note,
  currency,
  range,
  initialState,
  computationType,
  feeApplicationType,
  rates,
  labels,
  labelFilterType,
  accountInitialBalances,
  experimentalComputation,
  labelFilterExclRelatedTxs,
}) => ({
  name,
  note,
  computationType: computationType.value,
  feeApplicationType: feeApplicationType.value,
  currency: currency.value,
  start: range && range.from ? formatDate(range.from) : null,
  end: range && range.to ? formatDate(range.to) : null,
  labelFilterType,
  labels: labels?.map(x => x.value),
  initialState:
    computationType.value !== AVCO && !initialState.portfolio
      ? null
      : {
          pairStates: (initialState.pairStates || []).map(({ pair, ...rest }) => ({
            base: pair.base.value,
            quote: pair.quote.value,
            ...rest,
          })),
          dateType: initialState.dateType,
          sourcePortfolioId: initialState.portfolio?.value,
          timestamp: initialState.timestamp,
          updatedAt: initialState.updatedAt,
        },
  rates: (rates || []).map(({ base, quote, rate }) => ({
    base: base.value,
    quote: quote.value,
    rate,
  })),
  accountInitialBalances: {
    balances: (accountInitialBalances.balances || []).map(({ account, initialBalance }) => ({
      accountId: account.value?.id,
      accountName: account.value?.name,
      initialValue: initialBalance,
    })),
    sourcePortfolioId: accountInitialBalances.portfolio?.value,
    timestamp: accountInitialBalances.timestamp,
    updatedAt: accountInitialBalances.updatedAt,
  },
  experimentalComputation,
  labelFilterExclRelatedTxs,
});

const PortfolioDetailModal = ({
  isEdit,
  user,
  initialValues,
  reopenValues,
  activeOrganizationId,
  ruleSets,
  portfolioActions,
  modalActions,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const editPortfolio = async (values, { setSubmitting }) => {
    await portfolioActions.updatePortfolio(initialValues.id, portfolioValuesTransform({ ...rest, ...values }));
    setSubmitting(false);
  };

  const createPortfolio = async (values, { setSubmitting }) => {
    await portfolioActions.createPortfolio(portfolioValuesTransform(values), !isEdit);
    setSubmitting(false);
  };

  const labelsQuery = useQuery(getLabels(user, initialValues?.id || null, false, !!initialValues?.id));
  const labelOptions = useMemo(() => {
    const uniqueValues = new Set(labelsQuery?.data?.map(x => x.label) ?? []);
    return [...uniqueValues];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsQuery?.data]);

  const allowedComputationTypes = useMemo(() => ruleSets?.map(x => x.computationMethods).flat(), [ruleSets]);

  return (
    <ModalEnvelope
      id="portfolio-detail-modal"
      heading={isEdit ? t("modal.portfolio_detail.heading.edit") : t("modal.portfolio_detail.heading.create")}
      width={WIDER}
      description={<NewPortfolioExplanation />}>
      <LoaderOverlay isLoading={isLoading}>
        <PortfolioDetailForm
          onSubmit={isEdit ? editPortfolio : createPortfolio}
          {...rest}
          reOpen={isEdit ? modalActions.openEditPortfolio : modalActions.openAddPortfolio}
          portfolioValuesTransform={portfolioValuesTransform}
          initialValues={initialValues}
          reopenValues={reopenValues}
          isSmall={isSmall}
          isEdit={isEdit}
          labelOptions={labelOptions}
          allowedComputationTypes={allowedComputationTypes}
        />
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    activeOrganizationId: getActiveOrganizationId(state),
    ruleSets: getRuleSets(state),
    isLoading: getActiveOrganizationDetail(state)?.isOrganizationDataFetching || state.organizations.ruleSetsFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioDetailModal);
