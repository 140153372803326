import React from "react";
import { DocumentDownload as DocumentDownloadIcon, Resources as ResourcesIcon, Chat as ChatIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import Delimiter from "components/Common/Delimiter";
import { CZECH, SLOVAK } from "common/constants/languages";
import { openInNewTab } from "common/utils";
import { PrimaryButton, Heading } from "../Common";
import styles from "../Styles/Pricing.module.scss";

const PricingWhys = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const isCzSk = i18n.language === CZECH || i18n.language === SLOVAK;

  return (
    <>
      <div className="mb-4 text-center">
        <Heading level={3} textAlign="center" margin="3rem auto 0.5rem">
          {t("pricing.get_offer_heading")}
        </Heading>
        <p className="mb-4 mt-3">{t("pricing.get_offer_content")}</p>
        <PrimaryButton
          label={t("pricing.get_offer")}
          collapsed
          className="mb-4"
          onClick={() => openInNewTab(isCzSk ? "https://forms.gle/TKFSBz2yC8oUvuNQ9" : "https://forms.gle/gkAyxh82xQM3rEh96")}
        />
      </div>

      <Delimiter />
      <div className={styles.pricing_whys_wrapper}>
        <div className={styles.pricing_whys}>
          <div>
            <DocumentDownloadIcon size="xlarge" />
            <Heading level={4}>{t("pricing.whys.reports.heading")}</Heading>
            <p>{t("pricing.whys.reports.content_p1")}</p>
          </div>
          <div>
            <ResourcesIcon size="xlarge" />
            <Heading level={4}>{t("pricing.whys.general_bytes.heading")}</Heading>
            <p>{t("pricing.whys.general_bytes.content")}</p>
          </div>
          <div>
            <ChatIcon size="xlarge" />
            <Heading level={4}>{t("pricing.whys.custom_features.heading")}</Heading>
            <p>{t("pricing.whys.custom_features.content")}</p>
          </div>
        </div>
        <Heading level={3} textAlign="center" margin="0 auto 1rem">
          {t("pricing.whys.heading")}
        </Heading>
      </div>
    </>
  );
};

export default PricingWhys;
