import React from "react";
import styled from "styled-components";

export default ({ label, smaller = false, ...props }) => (
  <CurrencySpan smaller={smaller} {...props}>
    {label}
  </CurrencySpan>
);

const CurrencySpan = styled.span`
  font-size: ${props => (props.smaller ? "11px" : "14px")};
  padding-right: 3px;
`;
