import React from "react";
import { useTranslation } from "react-i18next";
import KryptoMagazin from "img/kryptomagazin.png";
import Alza from "img/alzacz.png";
import BtcTip from "img/btctip.png";
import cx from "classnames";
import { Heading } from "../Common";

import styles from "../Styles/Landing.module.scss";

export default ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.media_mentions, className)}>
      <Heading level={3} textAlign="center" margin="0 auto 1rem">
        {t("landing.media_mentions.heading")}
      </Heading>
      <div className={styles.media_mentions_list}>
        <a href="https://kryptomagazin.cz/everytrade-io-rozhovor-se-zakladatelem/" rel="noopener noreferrer" target="_blank">
          <img src={KryptoMagazin} alt="Kryptomagazin" className={styles.header_logo} />
        </a>
        <a href="https://btctip.cz/dane-a-kryptomeny-everytrade-io-vam-s-tim-pomuze/" rel="noopener noreferrer" target="_blank">
          <img src={BtcTip} alt="Btc Tip" className={styles.header_logo} />
        </a>
        <a href="https://www.alza.cz/jak-zdanit-prijmy-z-kryptomen" rel="noopener noreferrer" target="_blank">
          <img src={Alza} alt="Alza" className={styles.header_logo} />
        </a>
      </div>
    </div>
  );
};
