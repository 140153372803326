export const FIAT = "FIAT";
export const CRYPTO = "CRYPTO";

export const currencyTypeOptions = [FIAT, CRYPTO];

const t_fake = str => str;

const currencyTypeTranslations = {
  [FIAT]: t_fake("constant.currency_type.fiat"),
  [CRYPTO]: t_fake("constant.currency_type.crypto"),
};

export const currencyTypeTranslator = val => currencyTypeTranslations[val] || "";
