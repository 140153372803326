import React, { useMemo, useState } from "react";
import { Group } from "@vx/group";
import { Grid } from "@vx/grid";
import { scaleLinear, scaleUtc } from "@vx/scale";
import { colors } from "common/colors";
import { spring, TransitionMotion } from "react-motion";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { startOfTomorrow } from "date-fns";
import AxisLeft from "./AxisLeft";
import AxisBottom from "./AxisBottom";
import MainGraphDefs from "./MainGraphDefs";
import MainGraphLegend from "./MainGraphLegend";
import GraphArea from "./GraphArea";
import TooltipBar from "./TooltipBar";
import Tooltips from "./Tooltips";

import styles from "../Styles/Graphs.module.scss";

const HEIGHT = 400;

const willEnter = () => ({
  opacity: 0,
  willLeave: 1,
});

const willLeave = () => ({
  opacity: spring(0),
  willLeave: 0,
});

const MainGraph = ({
  portfolioId,
  data,
  fetchBalances,
  selectedDate,
  isSmall,
  isExample,
  isLoading,
  width,
  openedPortfolioEndDate,
  dottedLegendLabel,
  positiveLegendLabel,
  negativeLegendLabel,
  lockedLegendLabel,
  currencySymbol,
}) => {
  const { t } = useTranslation();

  const left = isSmall ? 0 : 60;
  const top = 50;

  const xMax = width - left;
  const yMax = HEIGHT - top;

  // memo to reduce graphArea rerenders
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: data.domains.yDomain,
        nice: true,
      }),
    [data, yMax]
  );

  const xScale = useMemo(
    () =>
      scaleUtc({
        range: [0, xMax],
        domain: data.domains.xDomain,
      }),
    [data, xMax]
  );

  const [tooltipData, setTooltipData] = useState(null);

  const selectedDatePosition = Math.max(xScale(selectedDate), 0);
  const dayWidth = useMemo(() => (data.history.length - 1 > 0 ? xMax / (data.history.length - 1) : 0), [data, xMax]);

  // Grey out part of the graph - from tomorrow to portfolio end date
  const portfolioEndDate = useMemo(
    () => (openedPortfolioEndDate ? moment.utc(openedPortfolioEndDate).add("1", "day").endOf("day").utc().valueOf() : undefined),
    [openedPortfolioEndDate]
  );
  const tomorrowDatePosition = useMemo(() => Number(Math.max(xScale(startOfTomorrow().valueOf()), 0)), [xScale]);

  const hasNegativeValue = useMemo(() => data.history?.some(x => x.fullY < 0), [data.history]);

  return (
    <div className={isExample ? styles.history_graph_example : styles.history_graph} data-tour="main-graph">
      <svg width={width} height={HEIGHT}>
        <MainGraphDefs
          width={xMax}
          height={yMax}
          markerXGray={portfolioEndDate ? tomorrowDatePosition + dayWidth / 2 : xMax}
          markerXPurple={selectedDate ? selectedDatePosition + dayWidth / 2 : xMax}
        />
        <Group left={left}>
          <TransitionMotion
            styles={
              isLoading
                ? []
                : [
                    {
                      // always one element displayed
                      key: isExample && isLoading ? "ptf_loader" : `${portfolioId}`,
                      style: { opacity: 1, willLeave: 1 },
                      data: {
                        xScale,
                        yScale,
                        history: data.history,
                        isLoader: isExample && isLoading,
                      },
                    },
                  ]
            }
            willEnter={willEnter}
            willLeave={willLeave}>
            {interpolated => (
              <Group>
                {interpolated.map(o => (
                  <GraphArea
                    key={o.key}
                    xScale={o.data.xScale}
                    yScale={o.data.yScale}
                    data={o.data.history}
                    xMax={xMax}
                    isDisplayed={o.style.willLeave !== 0}
                    isLoader={o.data.isLoader}
                    isLoading={isLoading}
                    isExample={isExample}
                  />
                ))}
              </Group>
            )}
          </TransitionMotion>
          <Grid numTicksRows={5} scale={yScale} width={xMax} strokeDasharray="3" strokeWidth={1} stroke={colors.greyBorder} />
          {!isSmall && <AxisLeft scale={yScale} label={currencySymbol} />}
          <AxisBottom
            scale={xScale}
            top={yMax}
            label={isSmall ? "" : t("graph.main_graph.date")}
            numTicks={Math.min(isSmall ? 6 : 11, data.history.length)}
          />
          <TooltipBar
            setTooltipData={setTooltipData}
            selectedDatePosition={selectedDate ? selectedDatePosition + dayWidth / 2 : null}
            tooltipData={tooltipData}
            history={data.history}
            xScale={xScale}
            fetchBalances={val => {
              if (!isExample) fetchBalances(val);
            }}
            xMax={xMax}
            yMax={yMax}
            left={left}
            dayWidth={dayWidth}
          />
        </Group>
      </svg>
      <Tooltips
        selectedDate={selectedDate}
        selectedDatePosition={selectedDatePosition + left + dayWidth / 2}
        tooltipData={tooltipData}
        yMax={yMax}
        yScale={yScale}
        left={left}
        currencySymbol={currencySymbol}
      />
      <MainGraphLegend
        dottedLegendLabel={dottedLegendLabel}
        positiveLegendLabel={positiveLegendLabel}
        negativeLegendLabel={negativeLegendLabel}
        lockedLegendLabel={lockedLegendLabel}
        displayNegativeLabel={hasNegativeValue}
      />
    </div>
  );
};

export default MainGraph;
