import React from "react";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import DeleteTransactionsFromBucketForm from "../Forms/DeleteTransactionsFromBucketForm";

const DeleteTransactionsFromBucketModal = ({ transactionIds }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope id="delete-bucket-tx-modal" heading={t("modal.delete_tx_from_bucket.heading")}>
      <DeleteTransactionsFromBucketForm transactionIds={transactionIds} />
    </ModalEnvelope>
  );
};

export default DeleteTransactionsFromBucketModal;
