import {
  LOGIN,
  REGISTER,
  LOGOUT,
  COOKIES_DISABLED,
  SET_NEW_PASSWORD,
  SET_FORGOTTEN_PASSWORD,
  SET_APPLICATION_PREFERENCES,
  GET_USER_MEMBERSHIPS,
  SWITCH_ORGANIZATION,
  JOIN_ORGANIZATION,
  LEAVE_ORGANIZATION,
  DELETE_PROFILE,
  GET_ORGANIZATION_INFO,
  EDIT_ORGANIZATION_INFO,
  DELETE_ORGANIZATION,
  EDIT_PROFILE,
  GET_USER_ORGANIZATIONS,
  SET_PRESENTATION_MODE,
} from "actions/types";

const initialState = {
  email: "",
  userId: null,
  organizationId: null,
  vaultId: null,
  isAuthenticated: false, // Value given by persistor
  isFetching: false,
  isMembershipsFetching: false,
  unauthorizedCatched: false, // to prevent multiple error toasts on 401
  cookiesDisabled: false,
  setNewPasswordFetching: false,
  localIdentity: false,
  googleIdentity: false,
  registeredEmail: null, // TODO: Needed?
  loginHistory: [],
  memberships: [],
  preferences: {},
  spectatedUserId: null,
  isOrganizationsFetching: false,
  organizations: [],
  isPresentationMode: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case `${LOGIN}_PENDING`:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };
    case `${LOGIN}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        unauthorizedCatched: false,
        ...action.payload,
      };
    case `${LOGIN}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    // ====================================
    case `${REGISTER}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${REGISTER}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        registeredEmail: action.payload,
      };
    case `${REGISTER}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    // ====================================
    case `${SET_NEW_PASSWORD}_PENDING`:
      return {
        ...state,
        setNewPasswordFetching: true,
      };
    case `${SET_NEW_PASSWORD}_FULFILLED`:
      return {
        ...state,
        setNewPasswordFetching: false,
      };
    case `${SET_NEW_PASSWORD}_REJECTED`:
      return {
        ...state,
        setNewPasswordFetching: false,
      };
    // ====================================
    case `${SWITCH_ORGANIZATION}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${SWITCH_ORGANIZATION}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
        spectatedUserId: action.meta.spectatedUserId === undefined ? state.spectatedUserId : action.meta.spectatedUserId,
      };
    case `${SWITCH_ORGANIZATION}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    // ====================================
    case `${GET_USER_MEMBERSHIPS}_PENDING`:
      return {
        ...state,
        isMembershipsFetching: action.meta.loader === undefined ? state.isMembershipsFetching : action.meta.loader,
      };
    case `${GET_USER_MEMBERSHIPS}_FULFILLED`:
      return {
        ...state,
        memberships: action.payload,
        isMembershipsFetching: false,
      };
    case `${GET_USER_MEMBERSHIPS}_REJECTED`:
      return {
        ...state,
        isMembershipsFetching: false,
      };
    // ====================================
    case `${GET_USER_ORGANIZATIONS}_PENDING`:
      return {
        ...state,
        isOrganizationsFetching: true,
      };
    case `${GET_USER_ORGANIZATIONS}_FULFILLED`:
      return {
        ...state,
        organizations: action.payload,
        isOrganizationsFetching: false,
      };
    case `${GET_USER_ORGANIZATIONS}_REJECTED`:
      return {
        ...state,
        isOrganizationsFetching: false,
      };
    // ====================================
    case `${JOIN_ORGANIZATION}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${JOIN_ORGANIZATION}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        memberships: [...state.memberships, action.payload],
      };
    case `${JOIN_ORGANIZATION}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    // ====================================
    case `${LEAVE_ORGANIZATION}_PENDING`:
      return {
        ...state,
        memberships: state.memberships.filter(o => o.organizationId !== action.meta.organizationId),
      };
    // ====================================
    case `${EDIT_PROFILE}_FULFILLED`:
      return {
        ...state,
        otherUserCategory: action.payload.otherUserCategory || null,
        userCategories: action.payload.userCategories,
        newUser: action.payload.newUser,
      };
    // ====================================
    case `${DELETE_PROFILE}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${DELETE_PROFILE}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
      };
    case `${DELETE_PROFILE}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };

    // ====================================
    case `${SET_APPLICATION_PREFERENCES}_PENDING`:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
        },
      };
    // ====================================
    case `${SET_FORGOTTEN_PASSWORD}_FULFILLED`:
      return {
        ...state,
        localIdentity: true,
      };
    // ====================================
    case `${EDIT_ORGANIZATION_INFO}_FULFILLED`:
      return {
        ...state,
        memberships: state.memberships.map(o =>
          o.organizationId === action.meta.organizationId ? { ...o, organizationDisplayName: action.payload.displayName } : o
        ),
      };
    // ====================================
    case `${GET_ORGANIZATION_INFO}_FULFILLED`:
      return {
        ...state,
        memberships: state.memberships.map(o =>
          o.organizationId === action.meta.organizationId ? { ...o, organizationDisplayName: action.payload.displayName } : o
        ),
      };
    // ====================================
    case `${DELETE_ORGANIZATION}_FULFILLED`:
      return {
        ...state,
        memberships: state.memberships.filter(o => o.organizationId !== action.meta.organizationId),
      };
    // ====================================
    case SET_PRESENTATION_MODE:
      return {
        ...state,
        isPresentationMode: action.payload,
      };
    // ====================================
    case COOKIES_DISABLED:
      return {
        ...state,
        cookiesDisabled: true,
      };
    // ====================================
    case LOGOUT:
      return {
        ...initialState,
        isAuthenticated: false,
        unauthorizedCatched: action.payload.unauthorized,
      };
    // ====================================
    default:
      return state;
  }
}
