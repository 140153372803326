import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import { organizationActions } from "actions/organizationActions";
import { PrimaryButton, Heading } from "components/Common";
import { WIDER } from "common/constants/modalWidths";
import Delimiter from "components/Common/Delimiter";
import { ReactComponent as EtLogo } from "img/et-logo.svg";
import { ReactComponent as ArrowLeft } from "img/arrow-left.svg";
import { ReactComponent as WbLogo } from "img/wb-logo-cropped.svg";
import styles from "containers/Styles/Modals.module.scss";
import { useScreenSize } from "hooks";
import ModalEnvelope from "./ModalEnvelope";

const RebrandingWelcomeModal = ({ onClose, modalActions: { hideModal } }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const handleOnClose = () => {
    if (onClose) onClose();
    hideModal();
  };

  return (
    <ModalEnvelope
      width={WIDER}
      id="rebranding-welcome-modal"
      heading={<div className="mt-2 mt-md-0">{t("modal.rebranding_welcome.heading")}</div>}
      disableClose
      closeIcon={false}>
      <Delimiter className="mb-3 my-md-2" />

      {isSmall ? (
        <div className="mt-4 mb-3 mx-3 text-center">
          <EtLogo title="" /> <ArrowLeft title="" className="ml-3" /> <br />{" "}
          <WbLogo style={{ height: "42px" }} className="ml-n4" title="" />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between my-5 mx-3">
          <EtLogo title="" /> <ArrowLeft title="" /> <WbLogo style={{ height: "42px" }} className="ml-n4" title="" />
        </div>
      )}

      {!isSmall && <Delimiter className="my-3" />}

      <div className="mb-5 mx-5 text-center">{t("modal.rebranding_welcome.p1")}</div>

      <Heading level={3} textAlign="center" className="mb-3">
        {t("modal.rebranding_welcome.p2")}
      </Heading>

      <div className="mb-4 mx-5 text-center">{t("modal.rebranding_welcome.p3")}</div>

      <div className={styles.rebranding_welcome_accountant}>
        <span dangerouslySetInnerHTML={{ __html: t("modal.rebranding_welcome.p4") }} />
      </div>

      <div className="mt-4 mb-2 mx-4 text-center">{t("modal.rebranding_welcome.p5")}</div>

      <div className="mb-4 text-center">
        <b>{t("modal.rebranding_welcome.p6")}</b>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <PrimaryButton label={t("modal.rebranding_welcome.confirm")} onClick={handleOnClose} collapsed noIcon />
      </div>
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RebrandingWelcomeModal);
