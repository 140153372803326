import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "components/Styles/DataManager.module.scss";
import cx from "classnames";
import { transactionService } from "services";
import { SMALL } from "common/constants/screenSizes";
import { GhostButton, LoaderOverlay } from "components/Common";
import { getContainerDetail, getExtendedContainerDetail } from "selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { transactionActions } from "actions/transactionActions";
import { copyToClipboard, sortArray } from "utils";
import { ReactComponent as ClipboardIcon } from "img/clipboard-solid.svg";

const Addresses = ({
  containerDetail: { id },
  extendedContainerDetail,
  user,
  transactionActions: { setExtendedContainerDetail },
}) => {
  const { t } = useTranslation();
  const isLoading = extendedContainerDetail.assetAccountsLoading;
  const assetAccounts = extendedContainerDetail.id === id ? extendedContainerDetail.assetAccounts : null;

  const loadAssetAccounts = async () => {
    setExtendedContainerDetail(id, { assetAccountsLoading: true });
    const response = await transactionService.getContainerDetailAccounts(id, user);
    setExtendedContainerDetail(id, { assetAccounts: response, assetAccountsLoading: false });
  };

  useEffect(() => {
    if (id && user && !assetAccounts && !isLoading) {
      loadAssetAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user, assetAccounts]);

  const addressesWithAccounts = useMemo(() => {
    const result = [];

    if (assetAccounts) {
      assetAccounts.forEach(account => {
        if (account.addresses) {
          account.addresses.forEach(address =>
            result.push({ address: address.address, account: `${account.name} (${account.currency})` })
          );
        }
      });
    }

    return result;
  }, [assetAccounts]);

  const sortedAddressesWithAccounts = useMemo(
    () => sortArray(addressesWithAccounts, ["account", "address"]),
    [addressesWithAccounts]
  );

  return (
    <LoaderOverlay isLoading={isLoading} spinnerSize={SMALL}>
      <div className={cx(styles.container_detail, styles.addresses, "mt-3")}>
        <span className="font-weight-bold mb-2">{t("data_manager.container_detail.account")}</span>
        <span className="font-weight-bold mb-2 d-flex align-items-center">
          {t("data_manager.container_detail.address")}{" "}
          <GhostButton
            icon={<ClipboardIcon />}
            className="mx-0"
            style={{ marginBottom: "1px" }}
            onClick={() => copyToClipboard(sortedAddressesWithAccounts?.map(x => x.address).join("\n"))}
          />
        </span>
        {sortedAddressesWithAccounts?.map((x, index) => (
          <React.Fragment key={index}>
            <span>{x.account}</span>
            <span>{x.address}</span>
          </React.Fragment>
        ))}
        {addressesWithAccounts?.length === 0 && t("data_manager.container_detail.no_addresses")}
      </div>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    containerDetail: getContainerDetail(state),
    extendedContainerDetail: getExtendedContainerDetail(state),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
