import { colors } from "../colors";

export const menuDefaults = {
  borderRadius: 6,
  overflow: "hidden",
  padding: 0,
  zIndex: 6,
  marginTop: 4,
  boxShadow: "0 1px 12px 0 rgba(51,64,85,0.24), 0 1px 3px 0 rgba(51,64,85,0.09)",
  border: `1px solid ${colors.gray1}`,
  minWidth: "145px", // TODO maybe will cause problems?
  left: "50%",
  WebkitTransform: "translateX(-50%)",
  transform: "translateX(-50%)",
  "> div": {
    "::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
};

export const controlDefaults = {
  boxShadow: "none",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
};

export const inputDefaults = {
  border: "none",
  // width: '100%',
  // margin: 0,
  // padding: 0,
  color: colors.text,
  minWidth: 140,
};

export const containerDefaults = {
  width: "100%",
};

export const placeholderDefaults = {
  color: colors.gray2,
  fontWeight: "normal",
  fontSize: "1rem",
  paddingLeft: "1px",
};

export const defaultOptionStyles = (styles, { isFocused, isSelected, isDisabled }) => ({
  ...styles,
  fontSize: "1rem",
  ":active": {
    ...styles[":active"],
    backgroundColor: isDisabled ? colors.white : colors.primary,
    color: colors.white,
  },
  backgroundColor: isSelected ? colors.primary : isDisabled ? "transparent" : isFocused ? colors.lightBlue : "transparent",
  color: isSelected ? colors.white : isDisabled ? colors.greyBorder : colors.text,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",
});

export const customSelectStyles = {
  input: base => ({
    ...base,
    ...inputDefaults,
  }),
  container: base => ({
    ...base,
    ...containerDefaults,
  }),
  control: base => ({
    ...base,
    ...controlDefaults,
  }),
  valueContainer: (base, { isDisabled }) => ({
    ...base,
    padding: 0,
    // height: '100%',
  }),

  menu: base => ({
    ...base,
    ...menuDefaults,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    overflowX: "hidden",
    // boxShadow: `0 2px 5px -1px ${colors.greyBorder}`,
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 0,
  }),
  placeholder: base => ({
    ...base,
    ...placeholderDefaults,
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? colors.gray3 : colors.text,
  }),
  group: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0, // '0.25rem',
    "&:not(:last-child)": {
      borderBottom: `1px solid ${colors.gray3}`,
    },
  }),
  option: defaultOptionStyles,
  multiValue: (base, { isDisabled }) => ({
    ...base,
    borderRadius: "26px",
    padding: "0 6px 0 6px",
    backgroundColor: colors.lightBlue,
    alignItems: "center",
    fontSize: "16px !important",
    marginRight: "1px",
    height: "24px",
    opacity: isDisabled ? 0.5 : 1,
  }),
  multiValueRemove: base => ({
    ...base,
    fill: colors.black,
    stroke: colors.black,
    zoom: "0.6",
    height: "5px",
    padding: "0 !important",
    ":hover": {
      backgroundColor: "transparent",
    },
  }),
  clearIndicator: base => ({
    ...base,
    padding: 0,
    marginRight: "0.25rem",
  }),
  groupHeading: base => ({
    ...base,
    paddingTop: "0.25rem",
    color: colors.gray3,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none",
  }),
};

export const customBoldSelectStyles = {
  ...customSelectStyles,
  singleValue: (base, state) => ({
    ...customSelectStyles.singleValue(base, state),
    fontFamily: "archivoheavy",
    fontWeight: "unset",
  }),
  input: base => ({
    ...base,
    ...inputDefaults,
    fontFamily: "archivoheavy",
    "& input": {
      // https://stackoverflow.com/questions/51545344/setting-the-font-family-of-the-input-field-of-a-react-select
      font: "inherit",
    },
  }),
};

export const currencyDropdownStyles = {
  menu: base => ({
    ...base,
    ...menuDefaults,
    minWidth: "300px !important",
    width: "auto !important",
    maxWidth: "90vw !important",
  }),
  valueContainer: (base, { isDisabled }) => ({
    ...base,
    padding: 0,
    opacity: isDisabled ? 0.5 : 1,
  }),
};

export default customSelectStyles;
