import DefaultIcon from "img/defaultCurrencyIcon.svg";

export const getCurrencyIcon = currency =>
  currency ? `https://whalebooks.com/static/img/currencyIcons/PNG/${currency?.toLowerCase()}.png` : "";

export const getCurrencyIconProps = currency => ({
  src: getCurrencyIcon(currency),
  onError: ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = DefaultIcon;
  },
});
