import React from "react";
import { Link } from "react-router-dom";
import GbWhaleBooks from "img/gb_wb.png";
import { useTranslation } from "react-i18next";
import { Heading, PrimaryButton } from "../Common";

import styles from "../Styles/About.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.about_banner}>
      <div className={styles.banner}>
        <Heading level={1} margin="0 0 2rem 0">
          {t("about_us.heading")}
        </Heading>
        <p className={styles.paragraph}>{t("about_us.content")}</p>
        <Link to="/signup">
          <PrimaryButton isLoading={false} label={t("about_us.get_started")} collapsed />
        </Link>
      </div>
      <div className={styles.image_wrapper}>
        <img src={GbWhaleBooks} alt="" />
      </div>
    </div>
  );
};
