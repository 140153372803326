import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  isWithinInterval,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { CalendarContainer } from "react-datepicker";
import cx from "classnames";
import React from "react";
import { GhostButton } from "components/Common/index";
import styles from "components/Common/Inputs/Inputs.module.scss";
import { formatDate } from "common/formatters";

const RangeOptionsContext = React.createContext(null);

const RangeOption = ({ label, start, end }) => {
  const { thisPortfolioRange, setRange, hideOutOfRangeOptions } = React.useContext(RangeOptionsContext);

  const isOptionVisible = (start, end) =>
    !hideOutOfRangeOptions ||
    !thisPortfolioRange ||
    (isWithinInterval(start, { start: thisPortfolioRange.from, end: thisPortfolioRange.to }) &&
      isWithinInterval(end, { start: thisPortfolioRange.from, end: thisPortfolioRange.to }));

  return isOptionVisible(start, end) ? (
    <GhostButton
      label={label}
      className="m-0 mb-1"
      labelClassName={styles.range_button_label}
      onClick={() => setRange([start, end])}
    />
  ) : null;
};

export const DateInputContainer =
  (setRange, thisPortfolioRange, t, hideOutOfRangeOptions) =>
  ({ className, children }) => {
    const portfolioHasRange = !!(thisPortfolioRange?.from && thisPortfolioRange?.to);

    return (
      <CalendarContainer className={cx(className, styles.show_range_buttons)}>
        <div className={styles.range_buttons_wrapper}>
          <div className={styles.range_buttons_wrapper_inner}>
            <RangeOptionsContext.Provider value={{ thisPortfolioRange, setRange, hideOutOfRangeOptions }}>
              <RangeOption label={t("components.date_input.today")} start={startOfToday()} end={startOfToday()} />
              <RangeOption label={t("components.date_input.yesterday")} start={startOfYesterday()} end={startOfYesterday()} />
              <RangeOption
                label={t("components.date_input.this_week")}
                start={startOfWeek(startOfToday(), { weekStartsOn: 1 })}
                end={endOfWeek(startOfToday(), { weekStartsOn: 1 })}
              />
              <RangeOption
                label={t("components.date_input.last_week")}
                start={subDays(startOfWeek(startOfToday(), { weekStartsOn: 1 }), 7)}
                end={subDays(endOfWeek(startOfToday(), { weekStartsOn: 1 }), 7)}
              />
              <RangeOption
                label={t("components.date_input.this_month")}
                start={startOfMonth(startOfToday())}
                end={endOfMonth(startOfToday())}
              />
              <RangeOption
                label={t("components.date_input.last_month")}
                start={subMonths(startOfMonth(startOfToday()), 1)}
                end={subMonths(endOfMonth(startOfToday()), 1)}
              />
              <RangeOption
                label={t("components.date_input.this_year")}
                start={startOfYear(startOfToday())}
                end={endOfYear(startOfToday())}
              />
              <RangeOption
                label={t("components.date_input.last_year")}
                start={subYears(startOfYear(startOfToday()), 1)}
                end={subYears(endOfYear(startOfToday()), 1)}
              />
            </RangeOptionsContext.Provider>

            {portfolioHasRange && (
              <GhostButton
                label={t("components.date_input.this_portfolio")}
                className="m-0 mb-1"
                onClick={() =>
                  setRange([formatDate(new Date(thisPortfolioRange.from)), formatDate(new Date(thisPortfolioRange.to))])
                }
              />
            )}
          </div>
        </div>
        <div style={{ position: "relative", padding: "0 10px" }}>{children}</div>
      </CalendarContainer>
    );
  };
