import { useContext } from "react";
import { ResponsiveContext } from "grommet";
import { SMALL, MEDIUM, LARGE } from "common/constants/screenSizes";

// to update breakpoints, edit /common/styles/grommetStyles.js
export function useScreenSize() {
  const size = useContext(ResponsiveContext);
  return {
    isSmall: size === SMALL,
    isMedium: size === MEDIUM,
    isLarge: size === LARGE,
  };
}
