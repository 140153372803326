import React from "react";
import { ReactComponent as DownloadIcon } from "img/download-icon.svg";
import styles from "components/Styles/DataManager.module.scss";
import Switch from "components/Common/Inputs/Switch";
import { dateFormat, formatDate } from "common/formatters";
import { GhostButton } from "components/Common";
import { IN_PROGRESS, synchronizationStatusTypeTranslator } from "common/constants/synchronizationStatusTypes";
import { transactionService } from "services";
import { useTranslation } from "react-i18next";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import cx from "classnames";
import SyncStatus from "../SyncStatus";

const Synchronization = ({
  container,
  containerId,
  connection,
  isSyncActive,
  transactionActions,
  openReloadContainerTransactionsConfirmation,
  openDetailCallback,
  synchronizationStatus,
  setIsLogFetching,
  user,
  isLogFetching,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.container_detail, "mt-1")}>
      <span className={cx(styles.sync_row_label, "mt-3")}>
        {t("data_manager.container_detail.automatic_sync")}{" "}
        <DynamicTooltip position="top" buttonClassName="ml-1">
          <div style={{ maxWidth: "300px" }}>{t("data_manager.container_detail.automatic_sync_tooltip")}</div>
        </DynamicTooltip>
      </span>
      <span className={`${styles.grid_row} mt-3`}>
        {connection && (
          <Switch
            checked={connection?.automaticSynchronization}
            onChange={() => {
              // disabled prop does not prevent onChange
              if (!isSyncActive) {
                return transactionActions.updateConnection(
                  { ...connection, automaticSynchronization: !connection?.automaticSynchronization },
                  containerId
                );
              }
            }}
            disabled={isSyncActive}
          />
        )}{" "}
        {formatDate(container.lastAutoSynchronizationTime, `${dateFormat} HH:mm`)}
      </span>

      {!connection?.automaticSynchronization && (
        <>
          <span className={styles.sync_row_label}>
            {t("data_manager.container_detail.manual_update")}{" "}
            <DynamicTooltip position="top" buttonClassName="ml-1">
              <div style={{ maxWidth: "300px" }}>{t("data_manager.container_detail.manual_sync_tooltip")}</div>
            </DynamicTooltip>
          </span>
          <span className={styles.grid_row}>
            <GhostButton
              onClick={() => transactionActions.fetchNewContainerTransactions(containerId)}
              label={t("data_manager.container_detail.manual_update_button")}
              disabled={isSyncActive}
            />{" "}
            {formatDate(container.lastManualSynchronizationTime, `${dateFormat} HH:mm`)}
          </span>
        </>
      )}

      <span className={styles.sync_row_label}>
        {t("data_manager.container_detail.manual_resync")}{" "}
        <DynamicTooltip position="top" buttonClassName="ml-1">
          <div style={{ maxWidth: "300px" }}>{t("data_manager.container_detail.manual_resync_tooltip")}</div>
        </DynamicTooltip>
      </span>
      <span className={styles.grid_row}>
        <GhostButton
          onClick={() => openReloadContainerTransactionsConfirmation(containerId, openDetailCallback)}
          label={t("data_manager.container_detail.manual_resync_button")}
          disabled={isSyncActive}
        />{" "}
        {formatDate(container.lastFullResyncSynchronizationTime, `${dateFormat} HH:mm`)}
      </span>

      <span>{t("data_manager.container_detail.sync_state")}</span>
      <span className="d-flex align-items-center">
        {synchronizationStatus && (
          <>
            <SyncStatus statusType={isSyncActive ? IN_PROGRESS : synchronizationStatus} />
            {t(synchronizationStatusTypeTranslator(isSyncActive ? IN_PROGRESS : synchronizationStatus))}
          </>
        )}
      </span>

      <span>{t("data_manager.container_detail.sync_log")}</span>
      <span className={styles.grid_row}>
        <GhostButton
          icon={<DownloadIcon />}
          onClick={async () => {
            setIsLogFetching(true);
            await transactionService.downloadTransactionContainerSyncLog(containerId, user);
            setIsLogFetching(false);
          }}
          label={t("data_manager.container_detail.download_sync_log")}
          disabled={isSyncActive || isLogFetching}
        />
      </span>
    </div>
  );
};

export default Synchronization;
