import { create } from "zustand";
import { persist } from "zustand/middleware";
import { initialRange } from "common/constants/initialDashboardRange";

const initialValues = {
  perPageDefault: 50,
  activePortfolioId: undefined,
  portfolioGraphDateRange: initialRange,
  portfolioGraphSelection: initialRange,
  showPositionsDetail: true,
};

export const useDefaultsStore = create()(
  persist(
    (set, get) => ({
      ...initialValues,
      setPerPageDefault: value => set({ perPageDefault: value }),
      setActivePortfolioId: value => set({ activePortfolioId: value }),
      setPortfolioGraphDateRange: value => set({ portfolioGraphDateRange: value }),
      setPortfolioGraphSelection: value => set({ portfolioGraphSelection: value }),
      resetPortfolioGraph: () => set({ portfolioGraphDateRange: initialRange, portfolioGraphSelection: initialRange }),
      setShowPositionsDetail: value => set({ showPositionsDetail: value }),

      resetStore: () => set({ ...initialValues, activePortfolioId: get().activePortfolioId }),
    }),
    { name: "defaults-storage", partialize: state => state }
  )
);
