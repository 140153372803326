export const USA = "USA";
export const CZ = "CZ";
export const PL = "PL";
export const DE = "DE";
export const SK = "SK";
export const OTHER = "OTHER";

const t_fake = str => str;

const taxResidencyTranslations = {
  [USA]: t_fake("tax_residency.usa"),
  [CZ]: t_fake("tax_residency.cz"),
  [SK]: t_fake("tax_residency.sk"),
  [PL]: t_fake("tax_residency.pl"),
  [DE]: t_fake("tax_residency.de"),
  [OTHER]: t_fake("tax_residency.other"),
};

export const taxResidencyTranslator = val => taxResidencyTranslations[val] || "";

export const taxResidencyOptions = t => {
  const options = [
    { label: t(taxResidencyTranslator(USA)), value: USA },
    { label: t(taxResidencyTranslator(CZ)), value: CZ },
    { label: t(taxResidencyTranslator(SK)), value: SK },
    { label: t(taxResidencyTranslator(PL)), value: PL },
    { label: t(taxResidencyTranslator(DE)), value: DE },
  ];

  return [...options.sort((a, b) => a.label.localeCompare(b.label)), { label: t(taxResidencyTranslator(OTHER)), value: OTHER }];
};
