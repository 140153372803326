import { Set } from "immutable";
import {
  CHANGE_BUCKET_FILTER,
  CLICK_BUCKET,
  DELETE_BUCKET,
  GET_BUCKET_TRANSACTIONS,
  GET_BUCKETS,
  GET_BUCKETS_FILTER_OPTIONS,
  LOGOUT,
  SELECT_MULTIPLE_BUCKETS,
  SET_BUCKET_TRANSACTIONS_FILTER,
  SET_BUCKETS_IN_DELETION,
  SET_COUNTER_TRANSACTIONS,
  SET_LAST_USED_BUCKET,
  SWITCH_PORTFOLIO,
  UNSELECT_ALL_BUCKETS,
} from "actions/types";
import { reduceIntArray } from "utils";
import { useDefaultsStore } from "stores/defaultsStore";

const initialState = {
  isBucketsFetching: false,
  buckets: [],
  bucketSelection: {},
  lastSelectedBucket: null, // for bucket selection using shift
  bucketsInDeletion: new Set(),
  counterTransactions: [],
  lastUsedBucketId: null, // for modal that adds txs to bucket

  filters: {
    bucketName: "", // ??
    page: 0,
    count: useDefaultsStore.getState().perPageDefault,
    portfolioFiltersApplied: true,
  },
  transactionsFilter: null,
  filterOptions: null,
  transactions: [],
  totalTransactions: 0,
  isTransactionsFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_BUCKETS}_PENDING`:
      return {
        ...state,
        isBucketsFetching: true,
      };
    case `${GET_BUCKETS}_FULFILLED`:
      return {
        ...state,
        isBucketsFetching: false,
        buckets: action.payload,
      };
    case `${GET_BUCKETS}_REJECTED`:
      return {
        ...state,
        isBucketsFetching: false,
      };
    // ====================================
    case `${GET_BUCKETS_FILTER_OPTIONS}_FULFILLED`:
      return {
        ...state,
        filterOptions: action.payload,
      };
    // ====================================
    case `${GET_BUCKET_TRANSACTIONS}_PENDING`:
      return {
        ...state,
        isTransactionsFetching: true,
        filters: {
          ...state.filters,
          page: 0,
        },
      };
    case `${GET_BUCKET_TRANSACTIONS}_FULFILLED`:
      return {
        ...state,
        isTransactionsFetching: false,
        filters: {
          ...state.filters,
          page: action.payload.meta.page,
        },
        totalTransactions: action.payload.meta.total,
        transactions: action.payload.data,
      };
    case `${GET_BUCKET_TRANSACTIONS}_REJECTED`:
      return {
        ...state,
        isTransactionsFetching: false,
      };
    // ====================================
    case CHANGE_BUCKET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
          page: action.payload.page === undefined ? 0 : action.payload.page,
        },
      };
    case SET_BUCKET_TRANSACTIONS_FILTER:
      return {
        ...state,
        transactionsFilter: action.payload,
      };
    // ====================================
    case SWITCH_PORTFOLIO:
      return {
        ...state,
        filters: {
          ...state.filters,
          portfolioFiltersApplied: true,
          page: 0,
        },
      };
    // ====================================
    case CLICK_BUCKET:
      if (action.payload.shiftDown && state.lastSelectedBucket) {
        const newSelection = { ...state.bucketSelection };
        let isApplied = false;

        action.payload.buckets.forEach(o => {
          if (!isApplied && o.id !== action.payload.bucketId && o.id !== state.lastSelectedBucket) return;
          if (o.id === action.payload.bucketId || o.id === state.lastSelectedBucket) isApplied = !isApplied;
          newSelection[o.id] = true;
        });

        return {
          ...state,
          lastSelectedBucket: action.payload.bucketId,
          bucketSelection: newSelection,
        };
      }
      return action.payload.ctrlDown
        ? {
            ...state,
            bucketSelection: {
              ...state.bucketSelection,
              [action.payload.bucketId]: !state.bucketSelection[action.payload.bucketId],
            },
            lastSelectedBucket: action.payload.bucketId,
          }
        : {
            ...state,
            bucketSelection: { [action.payload.bucketId]: true },
            lastSelectedBucket: action.payload.bucketId,
          };
    // ====================================
    case SELECT_MULTIPLE_BUCKETS:
      return {
        ...state,
        bucketSelection: reduceIntArray(action.payload),
      };
    // ====================================
    case UNSELECT_ALL_BUCKETS:
      return {
        ...state,
        bucketSelection: {},
      };
    // ====================================
    case `${DELETE_BUCKET}_FULFILLED`:
      return {
        ...state,
        buckets: state.buckets.filter(o => o.id !== action.meta.bucketId),
        bucketSelection: { ...state.bucketSelection, [action.meta.bucketId]: false },
      };
    // ====================================
    case `${SET_BUCKETS_IN_DELETION}`:
      return {
        ...state,
        bucketsInDeletion: action.payload,
      };
    // ====================================
    case `${SET_COUNTER_TRANSACTIONS}`:
      return {
        ...state,
        counterTransactions: action.payload,
      };
    //= ========================================
    case SET_LAST_USED_BUCKET:
      return {
        ...state,
        lastUsedBucketId: action.payload,
      };
    //= ========================================
    case LOGOUT:
      return initialState;
    //= ========================================
    default:
      return state;
  }
};
