export const OK = "OK";
export const ERROR = "ERROR";
export const IN_PROGRESS = "IN_PROGRESS";

const t_fake = str => str;

const synchronizationStatusTypeTranslations = {
  [OK]: t_fake("constant.synchronization_status_type.ok"),
  [ERROR]: t_fake("constant.synchronization_status_type.error"),
  [IN_PROGRESS]: t_fake("constant.synchronization_status_type.in_progress"),
};

export const synchronizationStatusTypeTranslator = val => synchronizationStatusTypeTranslations[val] || "";
