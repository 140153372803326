import { accountService } from "services/accountService";
import history from "common/history";
import { transactionService } from "services";
import { getActivePortfolioId, getActivePortfolioIdSearchUrl, getOpenedPortfolioBalances } from "selectors";
import { initialDashboardFilter } from "common/constants/dashboardFilter";
import {
  DELETE_ACCOUNT,
  GET_ACCOUNT_DETAIL,
  GET_ACCOUNT_STATES,
  GET_ACCOUNT_TRANSACTIONS,
  GET_ACCOUNTS,
  OPEN_ACCOUNT_TRANSACTION_DETAIL,
  SET_IS_DETAIL_FETCHING,
  UPDATE_ACCOUNT,
} from "./types";
import { alertActions } from "./alertActions";
import { modalActions } from "./modalActions";

function getAccounts() {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ACCOUNTS,
      payload: {
        promise: accountService.getAccounts(getState().user),
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function loadAccountDetail(accountId) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ACCOUNT_DETAIL,
      payload: {
        promise: accountService.getAccountDetail(accountId, getState().user),
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function loadAccountTransactions(page, count, filteredBy, accountId) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ACCOUNT_TRANSACTIONS,
      payload: {
        promise: accountService.getAccountTransactions(page, count, filteredBy, accountId, getState().user),
      },
    });
  };
}

function openAccountTransactionDetail(transaction, onClose) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_ACCOUNT_TRANSACTION_DETAIL,
      payload: {
        promise: async () => {
          const response = await transactionService.getTransaction(transaction, getState().user);
          dispatch(modalActions.openEditTransaction(response, onClose));
        },
      },
    });
  };
}

function updateAccount(data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: {
        promise: async () => {
          dispatch(setIsDetailFetching(true));
          dispatch(modalActions.hideModal());

          const { containerRelations, portfolioRelations, ...rest } = data;
          const response = await accountService.updateAccount(rest, getState().user);

          dispatch(loadAccountDetail(getState().accounts.accountDetail.id));
          dispatch(getAccounts());
          dispatch(setIsDetailFetching(false));

          const { filter } = getOpenedPortfolioBalances(getState());
          const activePortfolioId = getActivePortfolioId(getState());
          dispatch(getAccountStates(activePortfolioId, filter));

          return response;
        },
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function setIsDetailFetching(isFetching) {
  return dispatch => {
    dispatch({
      type: SET_IS_DETAIL_FETCHING,
      payload: isFetching,
    });
  };
}

function deleteAccount(accountId, options) {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_ACCOUNT,
      payload: {
        promise: async () => {
          dispatch(setIsDetailFetching(true));
          const response = await accountService.deleteAccount(accountId, getState().user);
          dispatch(setIsDetailFetching(false));

          if (options?.refetchDashboardAccounts) {
            const activePortfolioId = getActivePortfolioId(getState());
            const { filter } = getOpenedPortfolioBalances(getState());
            dispatch(getAccountStates(activePortfolioId, filter));
          } else {
            const portfolioIdSearch = getActivePortfolioIdSearchUrl(getState());
            history.push(`/asset-accounts${portfolioIdSearch}`);
            dispatch(getAccounts());
          }

          return response;
        },
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function getAccountStates(portfolioId, filter = initialDashboardFilter) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ACCOUNT_STATES,
      payload: {
        promise: accountService.getAccountStates(portfolioId, filter, getState().user),
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

export const accountActions = {
  getAccounts,
  loadAccountDetail,
  loadAccountTransactions,
  openAccountTransactionDetail,
  updateAccount,
  setIsDetailFetching,
  deleteAccount,
  getAccountStates,
};
