import { organizationService, userService } from "services";
import history from "common/history";
import i18n from "i18n";
import { getLocalStorageValue, REF_CODE, resetLocalStorage } from "common/localStorageManager";
import {
  CHANGE_PASSWORD,
  COOKIES_DISABLED,
  DELETE_PROFILE,
  EDIT_PROFILE,
  GET_USER_MEMBERSHIPS,
  GET_USER_ORGANIZATIONS,
  JOIN_ORGANIZATION,
  LEAVE_ORGANIZATION,
  LOGIN,
  LOGOUT,
  REGISTER,
  SET_APPLICATION_PREFERENCES,
  SET_FORGOTTEN_PASSWORD,
  SET_NEW_PASSWORD,
  SET_PRESENTATION_MODE,
  SWITCH_ORGANIZATION,
} from "./types";

import { portfolioActions } from "./portfolioActions";
import { marketDataActions } from "./marketDataActions";
import { alertActions } from "./alertActions";
import { modalActions } from "./modalActions";
import { organizationActions } from "./organizationActions";
import { transactionActions } from "./transactionActions";
import { accountActions } from "./accountActions";
import { bucketActions } from "./bucketActions";
import { useDefaultsStore } from "stores/defaultsStore";

function logout(unauthorized = false) {
  resetLocalStorage();
  useDefaultsStore.getState().resetStore();

  return {
    type: LOGOUT,
    payload: {
      unauthorized,
    },
  };
}

function checkUserCategory() {
  return (dispatch, getState) => {
    const { user } = getState();
    if (!user.userCategories || user.userCategories.length === 0) {
      dispatch(modalActions.openSelectUserCategory());
    }
  };
}

function checkNewUser() {
  return (dispatch, getState) => {
    if (getState().user.newUser) {
      dispatch(modalActions.openWalkthrough());
    }
  };
}

function setNewPassword() {
  return (dispatch, getState) =>
    dispatch({
      type: SET_NEW_PASSWORD,
      async payload() {
        await userService.setPassword(getState().user.email);
        alertActions.success(i18n.t("alert.success.email_sent"));
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function changePassword(values) {
  return (dispatch, getState) =>
    dispatch({
      type: CHANGE_PASSWORD,
      async payload() {
        await userService.changePassword(values, getState().user);
        dispatch(modalActions.hideModal());
        alertActions.success(i18n.t("alert.success.password_changed"));
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

const googleLoginAction = (googleToken, inviteToken, isSignUp) => ({
  type: LOGIN,
  async payload() {
    const { redirectTo, ...userData } = await userService.fetchGoogleIdentity(googleToken, inviteToken, isSignUp);
    if (redirectTo) {
      history.push(`${redirectTo}${history.location.search}`);
      alertActions.warning(i18n.t("alert.warning.account_does_not_exist"));
      return Promise.reject("CLOSE_MODAL");
    }
    const vaultData = await getVaults(userData);
    return { ...userData, ...vaultData };
  },
});

const loginAction = (values, token) => ({
  type: LOGIN,
  async payload() {
    const userData = await userService.authenticate(values, token);
    const vaultData = await getVaults(userData);
    return { ...userData, ...vaultData };
  },
});

const getVaults = async user => organizationService.getOrganizationVaults(user);

function getTokenAndLoad(action) {
  return async (dispatch, getState) => {
    try {
      await dispatch(action);
      dispatch(modalActions.hideModal());

      const { userId, newUser, organizationId } = getState().user;

      // Called on login
      const loginPromises = [
        dispatch(marketDataActions.getExchangeData()),
        dispatch(marketDataActions.getGeographicInfo()),
        dispatch(organizationActions.getOrganizationInfo(organizationId)),
        dispatch(organizationActions.updateTransactionsCount()),
        dispatch(accountActions.getAccounts()),
        dispatch(transactionActions.getContainers()),
      ];

      // Add check for 'userRole === ET_SUPPORT' for special admin redirect
      history.push("/dashboard");

      if (newUser) {
        dispatch(checkNewUser());
        const refId = getLocalStorageValue(REF_CODE);
        if (refId && typeof window.tap === "function") {
          window.tap("conversion", userId, 0, {}, "freeplan");
        }
      } else {
        loginPromises.push(dispatch(portfolioActions.getPortfolioList()));
        await Promise.all(loginPromises);
        dispatch(checkUserCategory());
      }
    } catch (err) {
      if (err === "CLOSE_MODAL") dispatch(modalActions.hideModal());
      // TODO: Change - this gets called if login with google && account doesn't exist
      else if (err) {
        alertActions.error(err);
      }
    }
  };
}

function loginAndLoad(values, token) {
  const action = loginAction(values, token);
  return getTokenAndLoad(action);
}

function googleAuthenticationAndLoad(token, inviteToken, isSignUp) {
  const action = googleLoginAction(token, inviteToken, isSignUp);
  return getTokenAndLoad(action);
}

function register(values, token) {
  return dispatch =>
    dispatch({
      type: REGISTER,
      async payload() {
        await userService.register(values, token);
        return values.email;
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function setForgottenPassword(password, token) {
  return dispatch =>
    dispatch({
      type: SET_FORGOTTEN_PASSWORD,
      async payload() {
        await userService.setForgottenPassword(password, token);
        history.replace("/login");
        alertActions.success(i18n.t("alert.success.password_changed"));
      },
    }).catch(err => {
      alertActions.error(err);
      return false;
    });
}

function editUserPreferences(preferences) {
  return (dispatch, getState) =>
    dispatch({
      type: SET_APPLICATION_PREFERENCES,
      payload: {
        promise: () => {
          i18n.changeLanguage(preferences.language.value);
          return userService.editUserPreferences(preferences, getState().user);
        },
        data: preferences,
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getJoinedOrganizations(userId, loader) {
  // userId passed in case of support spectate mode
  return (dispatch, getState) =>
    dispatch({
      type: GET_USER_MEMBERSHIPS,
      payload: userService.getJoinedOrganizations(userId || getState().user.userId),
      meta: { loader },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getUserOrganizations() {
  return (dispatch, getState) =>
    dispatch({
      type: GET_USER_ORGANIZATIONS,
      payload: userService.getUserOrganizations(getState().user.spectatedUserId || getState().user.userId),
    }).catch(err => {
      alertActions.error(err);
    });
}

function switchOrganization(organizationId, spectatedUserId, redirect) {
  return async dispatch => {
    await dispatch({
      type: SWITCH_ORGANIZATION,
      payload: {
        promise: async () => {
          const vault = await organizationService.getOrganizationVaults({ organizationId });
          history.push(redirect ?? "/dashboard");
          return {
            organizationId,
            ...vault,
          };
        },
      },
      meta: {
        spectatedUserId,
      },
    });

    dispatch(portfolioActions.setActivePortfolio(null));
    dispatch(portfolioActions.getPortfolioList());
    dispatch(organizationActions.getOrganizationInfo(organizationId));
    dispatch(organizationActions.updateTransactionsCount());
    dispatch(transactionActions.getContainers());
    dispatch(bucketActions.getBuckets());
    dispatch(accountActions.getAccounts());
  };
}

function editProfile(profile) {
  return (dispatch, getState) =>
    dispatch({
      type: EDIT_PROFILE,
      payload: {
        promise: async () => {
          const res = await userService.editProfile(profile, getState().user);
          dispatch(modalActions.hideModal());
          alertActions.success(i18n.t("alert.success.profile_edited"));
          return res;
        },
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deleteProfile() {
  return (dispatch, getState) =>
    dispatch({
      type: DELETE_PROFILE,
      payload: {
        promise: async () => {
          await userService.deleteProfile(getState().user);
          dispatch(logout());
          alertActions.success(i18n.t("alert.success.profile_deleted"));
        },
      },
    });
}

function joinOrganization(token) {
  return dispatch =>
    dispatch({
      type: JOIN_ORGANIZATION,
      payload: userService.joinOrganization(token),
    });
}

function leaveOrganization(organizationId, membershipId) {
  return (dispatch, getState) =>
    dispatch({
      type: LEAVE_ORGANIZATION,
      payload: {
        promise: async () => {
          const { user } = getState();
          await organizationService.deleteOrganizationUser(organizationId, membershipId);
          if (organizationId === user.organizationId) {
            dispatch(switchOrganization(user.memberships.find(o => o.organizationId !== organizationId)));
          }
        },
      },
      meta: { organizationId },
    });
}

function cookiesDisabled() {
  return {
    type: COOKIES_DISABLED,
  };
}

function setPresentationMode(enabled) {
  return dispatch =>
    dispatch({
      type: SET_PRESENTATION_MODE,
      payload: enabled,
    });
}

export const userActions = {
  logout,
  setNewPassword,
  changePassword,
  setForgottenPassword,
  googleAuthenticationAndLoad,
  register,
  loginAndLoad,
  cookiesDisabled,
  editUserPreferences,
  editProfile,
  deleteProfile,
  leaveOrganization,
  switchOrganization,
  getJoinedOrganizations,
  getUserOrganizations,
  joinOrganization,
  checkUserCategory,
  checkNewUser,
  setPresentationMode,
};
