import React from "react";
import { StatusCritical as ErrorIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";

import styles from "pages/Pages.module.scss";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.icon_header_label}>
      <ErrorIcon />
      <Heading level={3}>{t("page_not_found")}</Heading>
    </div>
  );
};

export default NotFound;
