export const FREE = "FREE";
export const TRIAL = "FREE_UNLIMITED";
export const ONLINE_PLUS = "ONLINE_PLUS"; // PRO
export const BUSINESS = "BUSINESS";

const t_fake = str => str;

const planTranslations = {
  [FREE]: t_fake("plan.free"),
  [TRIAL]: "Pro Trial",
  [ONLINE_PLUS]: "Pro",
  [BUSINESS]: "Enterprise",
};

export const planTranslator = val => planTranslations[val] || "";

export const planOptions = t => [{ label: t(planTranslator(FREE)), value: FREE }];
