import React from "react";
import { Table } from "grommet";
import { useTranslation } from "react-i18next";
import OpenRow from "./OpenRow";
import CloseRow from "./CloseRow";

import styles from "../Styles/MasterTable.module.scss";

const MasterTable = ({ open, close, openBaseQuote, isWalkthroughEnabled }) => {
  const { t } = useTranslation();

  return (
    <Table className={styles.master_table} style={isWalkthroughEnabled ? undefined : { overflowX: "scroll" }}>
      <tbody data-tour="master-table-open" tabIndex="15">
        <tr className={styles.master_table_header}>
          <th colSpan="2" className="pb-2 mt-n1">
            <div>
              {open.length > 0 ? t("dashboard.master_table.open_subhead") : t("dashboard.master_table.open_empty_subhead")}
            </div>
          </th>
        </tr>
        {open.length > 0 && (
          <tr className={styles.master_table_heading}>
            <th>{t("dashboard.master_table.base_quote_column")}</th>
            <th>{t("dashboard.master_table.amounts_quote_column")}</th>
            <th>{t("dashboard.master_table.acquisition_cost_column")}</th>
            <th>{t("dashboard.master_table.market_value_column")}</th>
            <th>{t("dashboard.master_table.unrealized_profit_column")}</th>
            <th>{t("dashboard.master_table.action_column")}</th>
          </tr>
        )}
        {open.map(o => (
          <OpenRow key={`${o.base}_${o.quote}_open`} {...o} openBaseQuote={openBaseQuote} />
        ))}
        <tr>
          <td colSpan="5">
            <div className={styles.table_divider} />
          </td>
        </tr>
      </tbody>

      <tbody data-tour="master-table-close" tabIndex="15">
        <tr className={styles.master_table_header}>
          <th colSpan="2" className="pb-2">
            <div>
              {close.length > 0 ? t("dashboard.master_table.close_subhead") : t("dashboard.master_table.close_empty_subhead")}
            </div>
          </th>
        </tr>
        {close.length > 0 && (
          <tr className={styles.master_table_heading}>
            <th>{t("dashboard.master_table.base_quote_column")}</th>
            <th />
            <th>{t("dashboard.master_table.acquisition_cost_column")}</th>
            <th>{t("dashboard.master_table.revenue_column")}</th>
            <th>{t("dashboard.master_table.realized_profit_column")}</th>
            <th>{t("dashboard.master_table.action_column")}</th>
          </tr>
        )}
        {close.map(o => (
          <CloseRow key={`${o.base}_${o.quote}_close`} {...o} openBaseQuote={openBaseQuote} />
        ))}
      </tbody>
    </Table>
  );
};

export default MasterTable;
