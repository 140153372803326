import React, { useCallback, useMemo } from "react";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import { connect } from "react-redux";
import {
  ActionCell,
  GhostButton,
  Heading,
  LoaderOverlay,
  PermissionCheck,
  Table,
  Tooltip,
  PrimaryButton,
} from "components/Common";
import { Add as AddIcon, Close as DeleteIcon, Cycle as ResendIcon, Edit as EditIcon } from "grommet-icons";
import { getGlobalFetchingState, getRawOrganizationUsers, getUserMemberships } from "selectors";
import { useTranslation } from "react-i18next";
import { ADMIN, OWNER, READ, userPermissionsTranslator } from "common/constants/userPermission";
import { MEDIUM } from "common/constants/screenSizes";
import { colors } from "common/colors";

import styles from "containers/Styles/Account.module.scss";
import { useUrlHelper } from "hooks";
import { memberPositionsTranslator } from "common/constants/memberPositions";

const Members = ({
  userMemberships,
  organizationUsers,
  globalFetchingState,
  modalActions: { openOrganizationUser },
  confirmationActions: { openDeleteOrganizationMemberConfirmation, openResendInvitationConfirmation },
}) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  const currentUserRole = useMemo(() => userMemberships.find(o => o.organizationId === id)?.role || READ, [id, userMemberships]);

  const organizationUsersTableColumns = useMemo(
    () => [
      {
        Header: t("organization_members.table_header.email"),
        accessor: "email",
      },
      {
        Header: t("organization_members.table_header.permission"),
        accessor: "role",
        Cell: ({ value }) => t(userPermissionsTranslator(value)),
      },
      {
        Header: t("organization_members.table_header.position"),
        accessor: "position",
        Cell: ({ value }) => t(memberPositionsTranslator(value)),
      },
      {
        Header: t("organization_members.table_header.joined"),
        accessor: "joined",
        Cell: ({ value }) => value ?? "Pending",
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("accounts.table_header.action")}</div>,
        accessor: "id",
        Cell: ({ row }) => (
          <ActionCell activeRole={currentUserRole} allowFor={[OWNER, ADMIN]}>
            {row.original.isInvite && (
              <Tooltip tooltipContent={t("organization_members.table_header.resend")}>
                <GhostButton
                  onClick={() => openResendInvitationConfirmation(id, row.original)}
                  icon={<ResendIcon />}
                  actionButton
                />
              </Tooltip>
            )}
            <Tooltip tooltipContent={t("organization_members.table_header.edit")}>
              <GhostButton
                onClick={() => openOrganizationUser({ initialValues: row.original, organizationId: id })}
                icon={<EditIcon />}
                actionButton
              />
            </Tooltip>
            {row.original.role !== OWNER && (
              <Tooltip tooltipContent={t("organization_members.table_header.delete")}>
                <GhostButton
                  onClick={() => openDeleteOrganizationMemberConfirmation(id, row.original)}
                  icon={<DeleteIcon />}
                  actionButton
                />
              </Tooltip>
            )}
          </ActionCell>
        ),
      },
    ],
    [t, id, openOrganizationUser, openDeleteOrganizationMemberConfirmation, openResendInvitationConfirmation, currentUserRole]
  );

  const { isOrganizationUsersFetching, users = [] } = organizationUsers[id] || {};

  const getRowId = useCallback(row => `${row.isInvite ? "i_" : "m_"}${row.id}`, []);
  const sortedUsers = useMemo(() => users.sort((a, b) => a.email.localeCompare(b.email)), [users]);

  return (
    <div className={styles.organization_members} data-tour="organization-members">
      <div className="d-flex justify-content-between">
        <Heading level={3}>{t("organization_members.heading")}</Heading>
        <PermissionCheck activeRole={currentUserRole} allowFor={[OWNER, ADMIN]}>
          <PrimaryButton
            label={t("organization_members.invite_member")}
            icon={<AddIcon color={colors.white} />}
            isLoading={isOrganizationUsersFetching}
            onClick={() => openOrganizationUser({ organizationId: id })}
            className="w-auto"
          />
        </PermissionCheck>
      </div>
      <LoaderOverlay isLoading={isOrganizationUsersFetching && !globalFetchingState} spinnerSize={MEDIUM}>
        <Table
          columns={organizationUsersTableColumns}
          data={sortedUsers}
          getRowId={getRowId}
          className={styles.organization_members_table}
        />
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userMemberships: getUserMemberships(state),
    organizationUsers: getRawOrganizationUsers(state),
    globalFetchingState: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
