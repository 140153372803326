export default class StateSet {
  values = new Set();

  constructor(values = new Set()) {
    this.values = values;
  }

  add = newValue => new StateSet(new Set([...this.values, newValue]));

  addMany = newValues => new StateSet(new Set([...this.values, ...newValues]));

  delete = valueToDelete => new StateSet(new Set([...this.values].filter(x => x !== valueToDelete)));

  toggle = value => (this.has(value) ? this.delete(value) : this.add(value));

  has = value => this.values.has(value);

  getArray = () => Array.from(this.values);
}
