import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import cx from "classnames";
import { sortBy } from "lodash";
import { Link } from "react-router-dom";
import { GhostButton, Heading, LoaderOverlay, Spinner, Table, Text } from "components/Common";
import styles from "components/Styles/Dashboard.module.scss";
import StakingRewardsTooltip from "components/Tooltips/StakingRewardsTooltip";
import { getActiveOrganizationId, getOpenedPortfolio, getStakingAndRewards } from "selectors";
import { CurrencyDecimal } from "components/Formating";
import { formatCurrency } from "common/formatters";
import GraphIcon from "img/GraphIcon.svg";
import { rewardTimingTranslator } from "common/constants/rewardTimingOptions";
import { colors } from "common/colors";
import { useScreenSize } from "hooks";
import FormattedNumber from "components/Common/FormattedNumber";

import { getCurrencyIconProps } from "utils/currencyIcon";

const getRowStyle = (isSmall, marginRight) =>
  isSmall ? { textAlign: "right" } : { textAlign: "right", marginRight: `${marginRight}px` };

const CurrencySpan = ({ label, className }) => <span className={cx(styles.small_currency, className)}>{label}</span>;
const SpinnerWrapper = () => (
  <div className={styles.flex_center}>
    <Spinner />
  </div>
);

const StakingRewardsContainer = ({ data, portfolio, activeOrganizationId, currentDateRuleSet }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const {
    isLoading,
    currency,
    rewardsMarketValue,
    rewardsAcquisitionCost,
    rewardsAcquisitionCostFee,
    rewardsUnrealizedProfit,
    stakingRewardRealizedProfit,
    stakingRewardRealizedProfitTaxable,
    positions,
  } = data;

  const formattedPositions = useMemo(
    () =>
      sortBy(
        positions.map((x, index) => ({ ...x, id: index })),
        x => x.currency
      ),
    [positions]
  );

  const rowProps = useMemo(() => ({ style: getRowStyle(isSmall, 120) }), [isSmall]);

  const columns = [
    {
      Header: t("dashboard.staking_rewards.table_header.currency"),
      accessor: "currency",
      Cell: ({ value }) => (
        <div className={styles.currency_image}>
          <img {...getCurrencyIconProps(value)} alt={value} /> <h4 className="m-0">{value}</h4>
        </div>
      ),
    },
    {
      Header: <div {...rowProps}>{t("dashboard.staking_rewards.table_header.staked")}</div>,
      Cell: ({ value }) => (
        <div {...rowProps}>
          <h4 className="m-0">
            <FormattedNumber number={value} />
          </h4>
        </div>
      ),
      accessor: "stakedAmount",
    },
    {
      Header: <div {...rowProps}>{t("dashboard.staking_rewards.table_header.open_rewards")}</div>,
      Cell: ({ value }) => (
        <div {...rowProps}>
          <h4 className="m-0">
            <FormattedNumber number={value} />
          </h4>
        </div>
      ),
      accessor: "openReward",
    },
    {
      Header: <div {...rowProps}>{t("dashboard.staking_rewards.table_header.closed_rewards")}</div>,
      Cell: ({ value }) => (
        <div {...rowProps}>
          <h4 className="m-0">
            <FormattedNumber number={value} />
          </h4>
        </div>
      ),
      accessor: "closedReward",
    },
    {
      Header: () => <div style={{ textAlign: "right" }}>{t("dashboard.staking_rewards.table_header.action")}</div>,
      Cell: () => (
        <div style={{ textAlign: "right", float: "right" }}>
          <GhostButton icon={<img src={GraphIcon} alt="" style={{ maxWidth: "unset" }} />} actionButton disabled />
        </div>
      ),
      id: "action",
    },
  ];

  const realizationType = currentDateRuleSet
    ? currentDateRuleSet.realizationTypes?.STAKING_REWARD
    : portfolio?.latestRuleSet?.realizationTypes?.STAKING_REWARD;

  const isWidgetVisible = useMemo(
    () =>
      (positions && positions.length > 0) ||
      rewardsAcquisitionCost > 0 ||
      rewardsMarketValue > 0 ||
      stakingRewardRealizedProfit > 0 ||
      rewardsUnrealizedProfit > 0,
    [positions, rewardsAcquisitionCost, rewardsMarketValue, stakingRewardRealizedProfit, rewardsUnrealizedProfit]
  );

  return !isWidgetVisible ? null : (
    <div style={{ paddingTop: "2rem" }}>
      <div className={cx(styles.master_table_header, "mb-32")}>
        <Heading level={3} className="mb-md-n2">
          {t("dashboard.staking_rewards.heading")}
        </Heading>

        <div className="mt-1 mt-md-0 d-flex align-items-center">
          <span className="mr-1">{t("dashboard.staking_rewards.taxation_of_rewards")}</span>
          <Link
            to={`/organizations/${activeOrganizationId}/tax-rules`}
            className="ml-2 mr-2"
            style={{ color: colors.primary, textDecoration: "none" }}>
            {t(rewardTimingTranslator(realizationType))}
          </Link>
          <StakingRewardsTooltip />
        </div>
      </div>

      <div className={styles.staking_rewards}>
        <div>
          <Heading level={4} margin="0.5rem 0">
            {t("dashboard.staking_rewards.acquisition_cost")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <>
              <Heading level={3}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(rewardsAcquisitionCost)} removeZeros />
              </Heading>
              <div className={styles.portfolio_summary_box_detail}>
                <Text size="small">
                  <CurrencyDecimal
                    currencyPrefix={`${t("dashboard.portfolio_summary.acquisition_cost_fee")} ${currency} `}
                    {...formatCurrency(rewardsAcquisitionCostFee)}
                    removeZeros
                  />
                </Text>
              </div>
            </>
          )}
        </div>

        <div>
          <Heading level={4} margin="0.5rem 0">
            {t("dashboard.staking_rewards.market_value")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <Heading level={3}>
              <CurrencySpan label={currency} />
              <CurrencyDecimal {...formatCurrency(rewardsMarketValue)} removeZeros />
            </Heading>
          )}
        </div>

        <div>
          <Heading level={4} margin="0.5rem 0">
            {t("dashboard.staking_rewards.realized_profit")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <>
              <Heading level={3}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(stakingRewardRealizedProfit)} removeZeros />
              </Heading>
              <div className={styles.portfolio_summary_box_detail}>
                <Text size="small">
                  <CurrencyDecimal
                    currencyPrefix={`${t("dashboard.staking_rewards.tax_base")} ${currency} `}
                    {...formatCurrency(stakingRewardRealizedProfitTaxable)}
                    removeZeros
                  />
                </Text>
              </div>
            </>
          )}
        </div>

        <div>
          <Heading level={4} margin="0.5rem 0">
            {t("dashboard.portfolio_summary.unrealized_profit")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <>
              <Heading level={3}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(rewardsUnrealizedProfit)} removeZeros />
              </Heading>
            </>
          )}
        </div>
      </div>

      <LoaderOverlay isLoading={isLoading}>
        <Table data={formattedPositions} columns={columns} />
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    data: getStakingAndRewards(state),
    portfolio: getOpenedPortfolio(state),
    activeOrganizationId: getActiveOrganizationId(state),
  };
}

export default connect(mapStateToProps)(StakingRewardsContainer);
