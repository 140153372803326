import axios from "axios";

async function getApiPermissions({ userId }) {
  const response = await axios.get(`/user/${userId}/api-access/allowed`);
  return response.data;
}

async function getApiPermission({ userId }, apiAccessId) {
  const response = await axios.get(`/user/${userId}/api-access/${apiAccessId}`);
  return response.data;
}

async function acceptApiPermission({ userId }, apiAccessId, requestedEmail) {
  const response = await axios.post(`/user/${userId}/api-access/allowed`, { apiAccessId, requestedEmail });
  return response.data;
}

async function deleteApiPermission({ userId }, permissionId) {
  const response = await axios.delete(`/user/${userId}/api-access/allowed/${permissionId}`);
  return response.data;
}

export const apiManagementService = {
  getApiPermissions,
  getApiPermission,
  acceptApiPermission,
  deleteApiPermission,
};
