import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { GhostButton, Heading, PermissionCheck } from "components/Common";
import { ADMIN, OWNER, READ } from "common/constants/userPermission";
import Subscription from "components/Organization/Subscription";
import OrganizationInfo from "components/Organization/OrganizationInfo";
import { useUrlHelper } from "hooks";
import { getGlobalFetchingState, getRawOrganizationDetails, getUserMemberships } from "selectors";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import styles from "pages/Pages.module.scss";
import Delimiter from "components/Common/Delimiter";
import cx from "classnames";
import history from "common/history";

const Detail = ({
  userMemberships,
  organizationDetails,
  globalFetchingState,
  modalActions,
  confirmationActions: { openDeleteOrganizationConfirmation },
}) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  const currentUserRole = useMemo(() => userMemberships.find(o => o.organizationId === id)?.role || READ, [id, userMemberships]);
  const { organizationData = {}, isOrganizationDataFetching } = organizationDetails[id] || {};

  return (
    <Row className="mt-3" noGutters>
      <Col xs={12} lg={6} className={cx(styles.border_right, "pr-lg-3")}>
        <div className="d-flex justify-content-between">
          <Heading level={3}>{t("organization.settings_header")}</Heading>

          <PermissionCheck activeRole={currentUserRole} allowFor={[ADMIN, OWNER]}>
            <GhostButton
              icon={<EditIcon />}
              label={t("organization.edit")}
              onClick={() => modalActions.openOrganizationDetail(organizationData)}
              className="mr-n2 pr-0"
            />
          </PermissionCheck>
        </div>

        <Delimiter className="mt-3" />
        <OrganizationInfo
          organizationData={organizationData}
          isFetching={isOrganizationDataFetching && !globalFetchingState}
          userMemberships={userMemberships}
          currentUserRole={currentUserRole}
          openDeleteOrganizationConfirmation={openDeleteOrganizationConfirmation}
        />
      </Col>
      <Col xs={12} lg={6} className="mt-5 mt-lg-0 pl-lg-3">
        <div className="d-flex justify-content-between">
          <Heading level={3}>{t("organization.subscription_header")}</Heading>
          <PermissionCheck activeRole={currentUserRole} allowFor={[OWNER]}>
            <GhostButton
              icon={<EditIcon />}
              label={t("profile.settings.change_plan")}
              onClick={() => history.push(`/organizations/${id}/upgrade`)}
              className="mr-n2 pr-0"
            />
          </PermissionCheck>
        </div>

        <Delimiter className="mt-3" />
        <Subscription organization={organizationData} />
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    userMemberships: getUserMemberships(state),
    organizationDetails: getRawOrganizationDetails(state),
    globalFetchingState: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
