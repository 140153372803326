import React, { useState } from "react";
import SwitchInner from "react-bootstrap/Switch";
import { v4 as uuidv4 } from "uuid";

function Switch(props) {
  const [switchId] = useState(uuidv4());

  return <SwitchInner id={switchId} className="d-inline" {...props} />;
}

export default Switch;
