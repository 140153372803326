import React from "react";
import { Formik } from "formik";
import { TextFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { bucketService } from "services/bucketService";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { bucketActions } from "actions/bucketActions";

const BucketDetailForm = ({ bucket, isEdit, user, bucketActions: { getBuckets }, modalActions }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    if (isEdit) {
      await bucketService.updateBucket(
        { id: bucket.id, name: values.name, txIds: bucket.transactions.map(x => x.portfolioTransaction.id) },
        user
      );
    } else {
      await bucketService.createBucket(values, user);
    }
    modalActions.hideModal();
    getBuckets();
  };

  return (
    <Formik
      initialValues={{
        name: isEdit ? bucket.name : "",
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        name: string().required(),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <TextFormField name="name" label={t("form.bucket_detail.name")} disabled={isSubmitting} required autoFocus />
          <PrimaryButton
            label={isEdit ? t("form.bucket_detail.update_bucket") : t("form.bucket_detail.create_bucket")}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bucketActions: bindActionCreators(bucketActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketDetailForm);
