import React from "react";
import { createSelector } from "reselect";
import i18n from "i18n";
import { getLastUsedCurrencies } from "selectors/dataManagerSelectors";

import { getCurrencyIconProps } from "utils/currencyIcon";

export const getRawConnectors = state => state.marketData.connectors;
export const getRawConnectorsFetching = state => state.marketData.isConnectorsFetching;

export const getRawCountries = state => state.marketData.geographics.countries || [];
export const getRawLanguages = state => state.marketData.geographics.languages || [];
export const getRawCryptocurrencies = state => state.marketData.geographics.cryptocurrencies || [];
export const getRawCurrencies = state => state.marketData.currencies;

export const getRawCurrenciesSorted = createSelector([getRawCurrencies], currencies =>
  currencies.sort((a, b) => a.code.localeCompare(b.code))
);

export const getCurrencies = createSelector([getRawCurrencies], currencies =>
  currencies.map(x => x.code).sort((a, b) => a.localeCompare(b))
);

export const getCurrencyOptions = createSelector([getRawCurrenciesSorted], currencies =>
  currencies.map(x => ({
    ...x,
    label: (
      <span className="d-flex align-items-center" key={x.code}>
        <img {...getCurrencyIconProps(x.code)} alt={x.code} style={{ width: "20px", height: "20px" }} className="mr-1" />{" "}
        <span className="ml-1">
          {x.code}{" "}
          <span style={{ fontSize: "12px" }} className="ml-1">{`${x.description} (${
            x.fiat ? "FIAT" : i18n.t("constant.currency_type.crypto")
          })`}</span>
        </span>
      </span>
    ),
    value: x.code,
    shortcut: (
      <span className="d-flex align-items-center" key={x.code}>
        <img {...getCurrencyIconProps(x.code)} alt={x.code} style={{ width: "20px", height: "20px" }} className="mr-1" />{" "}
        <span className="ml-1">{x.code} </span>
      </span>
    ),
    search: `${x.code.toLowerCase()} ${x.description.toLowerCase()}`,
  }))
);

export const getCurrencyOptionsWithLastUsed = createSelector(
  [getCurrencyOptions, getLastUsedCurrencies],
  (currencyOptions, lastUsedCurrencies) => [
    {
      label: i18n.t("form.add_transaction.last_used_currencies"),
      options: currencyOptions.filter(x => lastUsedCurrencies.includes(x.value)),
    },
    {
      label: i18n.t("form.add_transaction.all_currencies"),
      options: currencyOptions.filter(x => !lastUsedCurrencies.includes(x.value)),
    },
  ]
);

export const getFiats = createSelector([getRawCurrencies], currencies => currencies.filter(x => x.fiat).map(x => x.code));

// TODO remove when only getFiatOptionsWithIcon will be used
export const getFiatOptions = createSelector([getFiats], fiats => fiats.map(x => ({ label: x, value: x })));

export const getFiatOptionsWithIcon = createSelector([getCurrencyOptions], currencyOptions =>
  currencyOptions.filter(x => x.fiat)
);

export const getConnectors = createSelector([getRawConnectors, getRawConnectorsFetching], (connectors, isConnectorsFetching) => {
  connectors.sort((a, b) => a.name.localeCompare(b.name));
  return {
    connectors,
    isConnectorsFetching,
  };
});
