export const AT_PROFIT_REALIZATION = "AT_PROFIT_REALIZATION";
export const IMMEDIATE = "IMMEDIATE";

const t_fake = str => str;

const feeApplicationTypesTranslations = {
  [AT_PROFIT_REALIZATION]: t_fake("fee_application_type.at_profit"),
  [IMMEDIATE]: t_fake("fee_application_type.immediate"),
};

export const feeApplicationTypesTranslator = val => feeApplicationTypesTranslations[val] || "";
