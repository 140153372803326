import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { transactionActions } from "actions/transactionActions";
import AssignContainersExplanation from "components/Modals/ModalExplanations/AssignContainersExplanation";
import { getPortfoliosToSelect } from "selectors";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import AssignContainersForm from "../Forms/AssignContainersForm";

const AssignContainersModal = ({
  transactionContainerIds,
  portfolioId,
  portfolios,
  isPortfolioSelected,
  transactionActions: { assignContainers },
}) => {
  const { t } = useTranslation();
  return (
    <ModalEnvelope
      id="assign-containers-modal"
      heading={t("modal.assign_containers.heading")}
      description={<AssignContainersExplanation />}>
      <AssignContainersForm
        transactionContainerIds={transactionContainerIds}
        portfolioId={portfolioId}
        portfolios={portfolios}
        assignContainers={assignContainers}
        isPortfolioSelected={isPortfolioSelected}
      />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    portfolios: getPortfoliosToSelect(state),
    isPortfolioSelected: state.dataManager.selectedPortfolio,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignContainersModal);
