import React, { useEffect, useMemo, useState } from "react";
import { Next as NextIcon, Previous as PreviousIcon } from "grommet-icons";
import ReactPaginate from "react-paginate";
import { formatThousandsSeparator } from "common/formatters";
import styles from "./Components.module.scss";
import { PrimaryButton } from "components/Common/index";
import cx from "classnames";
import inputStyle from "./Inputs/Inputs.module.scss";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useScreenSize } from "hooks";

const Pagination = ({ page, onChange, total, isVisible }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const [activePage, setActivePage] = useState(page);

  // Manual thousand separator formatting - react-paginate does not support building custom labels
  useEffect(() => {
    setTimeout(() => {
      const pageNumberListItems = document.getElementsByClassName("page-number");
      for (let i = 0; i < pageNumberListItems.length; i++) {
        const listItem = pageNumberListItems[i];
        listItem.firstElementChild.textContent = formatThousandsSeparator(listItem.firstElementChild.innerHTML);
      }
    });
  }, [page, isVisible]);

  const [pageInput, setPageInput] = useState("");

  const isManualChangeDisabled = useMemo(
    () => !(pageInput !== "" && pageInput > 0 && pageInput <= total) || Number(pageInput) - 1 === page,
    [pageInput, total, page]
  );
  const handlePageChanged = value => {
    setActivePage(value);
    onChange(value);
  };

  const handleManualPageChange = () => {
    if (!isManualChangeDisabled) {
      handlePageChanged(Number(pageInput) - 1);
      setPageInput("");
      document.activeElement.blur();
    }
  };

  return (
    <div
      className={cx("d-md-flex align-items-center", {
        [styles.pagination_disabled]: total === 0,
        [styles.pagination]: total !== 0,
      })}>
      <ReactPaginate
        nextLabel={<NextIcon />}
        previousLabel={<PreviousIcon />}
        pageCount={total}
        pageRangeDisplayed={activePage < 2 ? 3 : 6}
        marginPagesDisplayed={1}
        activeClassName={styles.selected}
        previousClassName={styles.prev}
        nextClassName={styles.next}
        disabledClassName={styles.disabled}
        onPageChange={val => handlePageChanged(val.selected)}
        forcePage={page}
        pageClassName="page-number"
      />

      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-2 ml-md-4 mt-4 mt-md-0">
        <span className="mr-3 text-nowrap">{t("paging.go_to_page")}</span>
        <NumberFormat
          value={pageInput}
          className={cx("mr-3", inputStyle.input)}
          style={{ height: "38px", width: "60px", paddingBottom: isSmall ? undefined : "6px" }}
          onValueChange={values => setPageInput(values.value ?? "")}
          allowNegative={false}
          decimalScale={0}
          onKeyDown={e => {
            if (e.code === "Enter") {
              handleManualPageChange();
            }
          }}
        />
        <PrimaryButton
          label={t("button.go")}
          disabled={isManualChangeDisabled}
          onClick={handleManualPageChange}
          noIcon
          style={{ maxWidth: "80px" }}
        />
      </div>
    </div>
  );
};

export default Pagination;
