import React, { useState } from "react";
import { formatCurrency } from "common/formatters";
import { withScreenSize, withTooltip } from "hoc";
import { useTranslation } from "react-i18next";
import { colors } from "common/colors";
import cx from "classnames";
import { ChangeIcon, CurrencyDecimal, PercentDecimal } from "../Formating";
import TooltipButton from "../Tooltips/TooltipButton";
import AcquisitionCostTooltip from "../Tooltips/AcquisitionCostTooltip";
import MarketValueTooltip from "../Tooltips/MarketValueTooltip";
import RealizedProfitTooltip from "../Tooltips/RealizedProfitTooltip";
import UnrealizedProfitTooltip from "../Tooltips/UnrealizedProfitTooltip";
import { Heading, Spinner, Text } from "../Common";
import styles from "../Styles/Dashboard.module.scss";

const CurrencySpan = ({ label, className }) => <span className={cx(styles.small_currency, className)}>{label}</span>;
const SpinnerWrapper = () => (
  <div className={styles.flex_center}>
    <Spinner />
  </div>
);

const PortfolioSummary = props => {
  const [acHeight, setAcHeight] = useState(null);
  const [mvHeight, setMvHeight] = useState(null);
  const [rpHeight, setRpHeight] = useState(null);
  const [upHeight, setUpHeight] = useState(null);

  const { t } = useTranslation();

  const getMaxHeight = () =>
    acHeight && mvHeight && rpHeight && upHeight
      ? {
          style: { height: `${Math.max(...[acHeight, mvHeight, rpHeight, upHeight])}px`, display: "flex", alignItems: "center" },
        }
      : {};

  const {
    marketValue,
    acquisitionCost,
    acquisitionCostFee,
    acquisitionCostRewards,
    unrealizedProfit,
    realizedProfit,
    realizedProfitTaxable,
    profitPercentage,
    currency,
    isLoading,
    toggleTooltip,
    tooltipOpened,
    isSmall,
  } = props;

  return (
    <div data-tour="portfolio-summary" className="mb-48">
      <div className="d-flex justify-content-between align-items-center mb-32">
        <Heading level={3}>{t("dashboard.portfolio_summary.heading")}</Heading>

        <TooltipButton isActive={tooltipOpened} onClick={toggleTooltip} />
      </div>

      <div className={styles.portfolio_summary_wrapper}>
        <div className={styles.portfolio_summary}>
          <div data-tour="portfolio-summary_ac">
            <AcquisitionCostTooltip open={tooltipOpened} updateHeight={height => setAcHeight(height)} {...getMaxHeight()} />
            <Heading level={4} margin="-5px 0 0.5rem 0">
              {t("dashboard.portfolio_summary.acquisition_cost")}
            </Heading>
            {isLoading ? (
              <SpinnerWrapper />
            ) : (
              <>
                <Heading level={3} className={cx({ "negative-value": acquisitionCost < 0 })}>
                  <CurrencySpan label={currency} />
                  <CurrencyDecimal {...formatCurrency(acquisitionCost)} />
                </Heading>
                <div className={styles.portfolio_summary_box_detail}>
                  <Text size="small">
                    {isSmall ? (
                      <>
                        <div>{t("dashboard.portfolio_summary.acquisition_cost_fee")}</div>
                        <CurrencyDecimal currencyPrefix={currency} {...formatCurrency(acquisitionCostFee)} removeZeros />
                      </>
                    ) : (
                      <CurrencyDecimal
                        currencyPrefix={`${t("dashboard.portfolio_summary.acquisition_cost_fee")} ${currency} `}
                        {...formatCurrency(acquisitionCostFee)}
                        removeZeros
                      />
                    )}
                  </Text>
                </div>
                <div className={styles.portfolio_summary_box_detail}>
                  <Text size="small">
                    {isSmall ? (
                      <>
                        <div>{t("dashboard.portfolio_summary.acquisition_cost_rewards")}</div>
                        <CurrencyDecimal currencyPrefix={currency} {...formatCurrency(acquisitionCostRewards)} removeZeros />
                      </>
                    ) : (
                      <CurrencyDecimal
                        currencyPrefix={`${t("dashboard.portfolio_summary.acquisition_cost_rewards")} ${currency} `}
                        {...formatCurrency(acquisitionCostRewards)}
                        removeZeros
                      />
                    )}
                  </Text>
                </div>
              </>
            )}
          </div>

          <div data-tour="portfolio-summary_mv">
            <MarketValueTooltip open={tooltipOpened} updateHeight={height => setMvHeight(height)} {...getMaxHeight()} />
            <Heading level={4} margin="-5px 0 0.5rem 0">
              {t("dashboard.portfolio_summary.market_value")}
            </Heading>
            {isLoading ? (
              <SpinnerWrapper />
            ) : (
              <Heading level={3} className={cx({ "negative-value": marketValue < 0 })}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(marketValue)} />
              </Heading>
            )}
          </div>
          <div data-tour="portfolio-summary_rpl">
            <RealizedProfitTooltip
              open={tooltipOpened}
              updateHeight={height => setRpHeight(height)}
              bottom={isSmall}
              {...getMaxHeight()}
            />
            <Heading level={4} margin="-5px 0 0.5rem 0">
              {t("dashboard.portfolio_summary.realized_profit")}
            </Heading>
            {isLoading ? (
              <SpinnerWrapper />
            ) : (
              <>
                <Heading level={3}>
                  <CurrencySpan label={currency} />
                  <CurrencyDecimal {...formatCurrency(realizedProfit)} />
                </Heading>
                <div className={styles.portfolio_summary_box_detail}>
                  <Text size="small">
                    {isSmall ? (
                      <>
                        <div>{t("dashboard.portfolio_summary.tax_base")}</div>
                        <CurrencyDecimal currencyPrefix={currency} {...formatCurrency(realizedProfitTaxable)} />
                      </>
                    ) : (
                      <CurrencyDecimal
                        currencyPrefix={`${t("dashboard.portfolio_summary.tax_base")} ${currency} `}
                        {...formatCurrency(realizedProfitTaxable)}
                      />
                    )}
                  </Text>
                </div>
              </>
            )}
          </div>
          <div data-tour="portfolio-summary_upl">
            <UnrealizedProfitTooltip
              open={tooltipOpened}
              updateHeight={height => setUpHeight(height)}
              bottom={isSmall}
              {...getMaxHeight()}
            />
            <Heading level={4} margin="-5px 0 0.5rem 0">
              {t("dashboard.portfolio_summary.unrealized_profit")}
            </Heading>
            {isLoading ? (
              <SpinnerWrapper />
            ) : (
              <>
                <Heading level={3}>
                  <CurrencySpan label={currency} />
                  <CurrencyDecimal {...formatCurrency(unrealizedProfit)} />
                </Heading>
                <div className={cx(styles.portfolio_summary_box_detail, "ml-n1")}>
                  <ChangeIcon val={profitPercentage} />
                  <Text color={profitPercentage < 0 ? colors.red : ""} size="small">
                    <PercentDecimal number={profitPercentage} useSpan={false} />
                  </Text>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withScreenSize(withTooltip(PortfolioSummary));
