import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const DeleteAccountOrganization = ({ onConfirm, onCancel, name }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_organization.heading")}
      confirmLabel={t("confirmation.delete_organization.confirm_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.delete_organization.content">
          Action will permanently delete organization <b>{{ name }}</b>.
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default DeleteAccountOrganization;
