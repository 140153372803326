export const SOURCE_PORTFOLIO_END = "SOURCE_PORTFOLIO_END";
export const SOURCE_PORTFOLIO_LAST_TRANSACTION = "SOURCE_PORTFOLIO_LAST_TRANSACTION";
export const USER_SPECIFIED = "USER_SPECIFIED";

export const dateSelectOptionValues = [SOURCE_PORTFOLIO_END, SOURCE_PORTFOLIO_LAST_TRANSACTION, USER_SPECIFIED];

const t_fake = str => str;

const dateSelectOptionTranslations = {
  [SOURCE_PORTFOLIO_END]: t_fake("form.portfolio_predecessor.date_strategy.end_date"),
  [SOURCE_PORTFOLIO_LAST_TRANSACTION]: t_fake("form.portfolio_predecessor.date_strategy.last_transaction"),
  [USER_SPECIFIED]: t_fake("form.portfolio_predecessor.date_strategy.user_specified"),
};

export const dateSelectOptionTranslator = val => dateSelectOptionTranslations[val] || "";
