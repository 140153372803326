import React, { useMemo, useRef, useState } from "react";
import { CheckBox } from "grommet";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import { faArrowTurnUp, faFolderMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatUtcDate, timeFormat } from "common/formatters";
import {
  BUY,
  isTxTypeDepositWithdrawal,
  isTxTypeFeeRebate,
  isTxTypeReward,
  isTxTypeStake,
  transactionTypeTranslator,
} from "common/constants/transactionType";
import { colors } from "common/colors";
import RateAndTotalLabel from "containers/DataManager/RateAndTotalLabel";
import { FilterableCell, GhostButton, Heading, PermissionCheck, Table, Tooltip } from "../Common";
import styles from "../Styles/DataManager.module.scss";
import TransactionsTableFilter, { initialTransactionTableFilterValues } from "./TransactionsTableFilter";

const BucketTransactionsTable = ({
  transactions: rawTransactions,
  editTransaction,
  transactionSelection,
  setTransactionSelection,
  selectedBucket,
  openDeleteTransactionsFromBucketConfirmation,
  filter: { isFilterVisible, setIsFilterVisible },
  getBucketTransactions,
  filterOptions,
  setBucketTransactionsFilter,
}) => {
  const { t } = useTranslation();

  const transactions = useMemo(() => rawTransactions.sort((a, b) => b.timestamp - a.timestamp), [rawTransactions]);

  const toggleCheck = id => {
    setTransactionSelection({
      ...transactionSelection,
      [id]: !transactionSelection[id],
    });
  };

  const selectAll = e => {
    setTransactionSelection(
      transactions.reduce((res, val) => {
        res[val.id] = e.target.checked;
        return res;
      }, {})
    );
  };

  const allSelected = useMemo(() => {
    const txIds = transactions.map(o => o.id);
    return transactions.length !== 0 && txIds.find(o => transactionSelection[o] !== true) === undefined;
  }, [transactionSelection, transactions]);

  const handleFilterColumnClicked = () => {
    if (!isFilterVisible) setIsFilterVisible(true);
  };

  const columns = [
    {
      Header: () => <CheckBox className="mb-1" disabled={transactions.length === 0} checked={allSelected} onChange={selectAll} />,
      Cell: ({ row }) => <CheckBox checked={transactionSelection[row.id]} onChange={() => toggleCheck(row.id)} />,
      id: "check-row",
    },
    {
      Header: props => (
        <FilterableCell
          {...props}
          onClick={() => {
            handleFilterColumnClicked();
            pairFilterRef.current.focus();
          }}>
          {t("data_manager.transaction_table.currency_pair_column")}
        </FilterableCell>
      ),
      Cell: ({ row }) => {
        const { type, baseCurrency, quoteCurrency, feeRebateCurrency } = row.original;

        if (isTxTypeDepositWithdrawal(type) || isTxTypeStake(type) || isTxTypeReward(type)) {
          return baseCurrency;
        }
        if (isTxTypeFeeRebate(type)) {
          return feeRebateCurrency;
        }
        if (type === BUY) {
          return (
            <>
              {`${baseCurrency}`} / {`${quoteCurrency}`}
            </>
          );
        }
        return (
          <>
            {`${baseCurrency}`} / {`${quoteCurrency}`}
          </>
        );
      },
      id: "currency",
    },
    {
      Header: t("data_manager.transaction_table.source"),
      accessor: "exchangeName",
    },
    {
      Header: t("data_manager.transaction_table.date_column"),
      Cell: ({ value }) => (
        <>
          {formatUtcDate(value)}
          <span className={styles.time_small}>{formatUtcDate(value, timeFormat)}</span>
        </>
      ),
      accessor: "timestamp",
    },
    {
      Cell: ({ row }) => {
        const isSubtransaction = !!row.original.mainTransactionId;
        return isSubtransaction && <FontAwesomeIcon icon={faArrowTurnUp} className={styles.subtransaction_cell} />;
      },
      id: "subTransaction",
    },
    {
      Header: props => (
        <FilterableCell
          {...props}
          onClick={() => {
            handleFilterColumnClicked();
            typeFilterRef.current.focus();
          }}>
          {t("data_manager.transaction_table.type_column")}
        </FilterableCell>
      ),
      Cell: ({ row: { original } }) => t(`${transactionTypeTranslator(original.type, original.transfer)}`),
      accessor: "type",
    },
    {
      Header: t("data_manager.transaction_table.quantity_price_total_column"),
      Cell: ({ row: { original } }) => <RateAndTotalLabel {...original} />,
      id: "price-total",
    },
    {
      Header: t("data_manager.transaction_table.note"),
      Cell: ({ value }) => <span className={styles.note}>{value}</span>,
      accessor: "note",
    },
    {
      Header: props => (
        <FilterableCell
          {...props}
          onClick={() => {
            handleFilterColumnClicked();
            labelFilterRef.current.focus();
          }}>
          {t("data_manager.transaction_table.tx_label")}
        </FilterableCell>
      ),
      Cell: ({ value }) => <span className={styles.label}>{value}</span>,
      accessor: "label",
    },
    {
      Header: () => (
        <div style={{ textAlign: "right" }} className="mr-3">
          {t("data_manager.transaction_table.action")}
        </div>
      ),
      Cell: ({ row }) => {
        const { original, index } = row;
        const rowIndex = index + 1;

        return (
          <div className={styles.action_cell}>
            <PermissionCheck>
              <Tooltip tooltipContent={t("data_manager.transaction_table.remove_from_bucket")}>
                <GhostButton
                  icon={<FontAwesomeIcon icon={faFolderMinus} color={selectedBucket ? colors.primary : colors.gray3} />}
                  onClick={() => openDeleteTransactionsFromBucketConfirmation(selectedBucket.id, [original.id])}
                  disabled={!selectedBucket}
                  actionButton
                  testId={`action-delete_row-${rowIndex}`}
                />
              </Tooltip>
            </PermissionCheck>
            <PermissionCheck>
              <Tooltip tooltipContent={t("data_manager.transaction_table.edit_transaction")}>
                <GhostButton
                  icon={<EditIcon />}
                  onClick={() => editTransaction(original)}
                  actionButton
                  testId={`action-add_row-${rowIndex}`}
                />
              </Tooltip>
            </PermissionCheck>
          </div>
        );
      },
      id: "actions",
    },
  ];

  const [filteredBy, setFilteredBy] = useState(initialTransactionTableFilterValues);
  const pairFilterRef = useRef(null);
  const typeFilterRef = useRef(null);
  const labelFilterRef = useRef(null);

  return (
    <div className={styles.styled_table_wrapper} data-tour="buckets-transaction-console">
      <TransactionsTableFilter
        filteredBy={filteredBy}
        setFilteredBy={setFilteredBy}
        getTransactions={getBucketTransactions}
        filterOptions={filterOptions}
        setTransactionsFilter={setBucketTransactionsFilter}
        isVisible={isFilterVisible}
        refs={{
          pairFilterRef,
          typeFilterRef,
          labelFilterRef,
        }}
      />

      <Table
        data={transactions}
        columns={columns}
        getRowId={row => row.id}
        filteredBy={filteredBy}
        setFilteredBy={setFilteredBy}
      />
      {transactions.length === 0 && (
        <div className={styles.empty_table_row}>
          <Heading level={4} margin="1rem 0 0" color="gray3">
            {selectedBucket
              ? t("data_manager.transaction_table.no_transactions_message")
              : t("data_manager.transaction_table.select_bucket_message")}
          </Heading>
        </div>
      )}
    </div>
  );
};

export default BucketTransactionsTable;
