import React from "react";
import { connect } from "react-redux";
import { getOpenedPortfolioBaseQuotes, getOpenedPortfolioEndDate } from "selectors";
import { withParentSize } from "@vx/responsive";
import { LoaderOverlay } from "components/Common";
import { MEDIUM } from "common/constants/screenSizes";
import BaseQuoteGraph from "components/Graphs/BaseQuoteGraph";

import styles from "../Styles/Graphs.module.scss";

const BaseQuoteGraphContainer = ({ baseQuote, openedPortfolioEndDate, parentWidth, baseQuoteHistory, displayed }) => (
  <div className={styles.base_quote_chart_wrapper}>
    <LoaderOverlay isLoading={baseQuote.isBQFetching} spinnerSize={MEDIUM}>
      <BaseQuoteGraph
        data={baseQuoteHistory}
        width={parentWidth}
        selectedDate={null}
        displayed={displayed}
        openedPortfolioEndDate={openedPortfolioEndDate}
      />
    </LoaderOverlay>
  </div>
);

const mapStateToProps = state => ({
  baseQuote: getOpenedPortfolioBaseQuotes(state),
  openedPortfolioEndDate: getOpenedPortfolioEndDate(state),
});

export default withParentSize(connect(mapStateToProps)(BaseQuoteGraphContainer));
