import React from "react";
import { Formik } from "formik";
import { SelectFormField, TextFormField } from "components/Common/Inputs";
import { Form, LoaderOverlay, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";

import { object, string } from "yup";
import { accountTypesTranslator, EXCHANGE, WALLET } from "common/constants/accountTypes";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../Styles/Forms.module.scss";

const EditAssetAccountForm = ({ onClose, accountDetail, currencyOptions, updateAccount }) => {
  const { t } = useTranslation();

  const typeOptions = [
    { label: t(accountTypesTranslator(EXCHANGE)), value: EXCHANGE },
    { label: t(accountTypesTranslator(WALLET)), value: WALLET },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const formattedValues = {
      ...values,
      currency: values.currency.value,
      type: values.type.value,
      addresses: accountDetail.addresses,
      id: accountDetail.id,
    };

    const response = await updateAccount(formattedValues);
    setSubmitting(false);
    if (onClose) onClose();
    return response;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: accountDetail?.name,
        currency: currencyOptions.find(x => x.value === accountDetail?.currency),
        administeredBy: accountDetail?.administeredBy,
        description: accountDetail?.description ?? "",
        type: typeOptions.find(x => x.value === accountDetail?.type),
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        name: string().required(),
        currency: object().required(),
        administeredBy: string().required(),
        type: object().required(),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <LoaderOverlay isLoading={isSubmitting}>
          <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
            <TextFormField name="name" label={t("form.account_detail.name")} disabled={isSubmitting} required autoFocus />
            <div className={styles.fields_row}>
              <CurrencySelectFormField
                name="currency"
                label={t("form.account_detail.currency")}
                options={currencyOptions}
                disabled={isSubmitting}
                required
              />
              <SelectFormField
                name="type"
                label={t("form.account_detail.type")}
                options={typeOptions}
                disabled={isSubmitting}
                required
              />
            </div>
            <div className={styles.fields_row}>
              <TextFormField
                name="administeredBy"
                label={t("form.account_detail.administered_by")}
                disabled={isSubmitting}
                required
              />
              <TextFormField name="description" label={t("form.account_detail.description")} disabled={isSubmitting} />
            </div>

            <PrimaryButton noIcon label={t("form.account_detail.save_changes")} isLoading={isSubmitting} onClick={handleSubmit} />
          </Form>
        </LoaderOverlay>
      )}
    </Formik>
  );
};

export default EditAssetAccountForm;
