export const LEAVE_WHALEBOOKS = "LEAVE_WHALEBOOKS";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const LEAVE_ORGANIZATION = "LEAVE_ORGANIZATION";
export const SET_PASSWORD = "SET_PASSWORD";
export const DELETE_ORGANIZATION_MEMBER = "DELETE_ORGANIZATION_MEMBER";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const DELETE_TRANSACTION_CONTAINERS = "DELETE_TRANSACTION_CONTAINERS";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const JOIN_ORGANIZATION = "JOIN_ORGANIZATION";
export const RESEND_INVITATION = "RESEND_INVITATION";
export const LOGOUT_TO_JOIN_ORGANIZATION = "LOGOUT_TO_JOIN_ORGANIZATION";
export const EDIT_API_CONNECTION = "EDIT_API_CONNECTION";
export const RELOAD_TRANSACTIONS = "RELOAD_TRANSACTIONS";
export const DELETE_ACCOUNT_ADDRESS = "DELETE_ACCOUNT_ADDRESS";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_BUCKETS = "DELETE_BUCKETS";
export const DELETE_TRANSACTIONS_FROM_BUCKET = "DELETE_TRANSACTIONS_FROM_BUCKET";
export const ENABLE_EXPERIMENTAL_COMPUTATION = "ENABLE_EXPERIMENTAL_COMPUTATION";
export const DELETE_RULESET = "DELETE_RULESET";
export const DELETE_API_MANAGEMENT_PERMISSION = "DELETE_API_MANAGEMENT_PERMISSION";
export const ADD_API_MANAGEMENT_PERMISSION = "ADD_API_MANAGEMENT_PERMISSION";
export const DELETE_ALL_PTF_PAIRS = "DELETE_ALL_PTF_PAIRS";
export const DELETE_ALL_PTF_ACCOUNTS = "DELETE_ALL_PTF_ACCOUNTS";
export const DELETE_ORGANIZATION_REFERENCE = "DELETE_ORGANIZATION_REFERENCE";
export const CHANGE_PLAN = "CHANGE_PLAN";
