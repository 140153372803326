import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { walkthroughActions } from "actions/walkthroughActions";
import { modalActions } from "actions/modalActions";
import { getActivePortfolioId } from "selectors";
import { Trans, useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import Tour from "reactour";
import history from "common/history";
import WalkThroughWizard from "./WalkThroughWizard";
import styles from "../Styles/Walkthrough.module.scss";
import { useScreenSize } from "hooks";
import ImagePortfolio from "img/walkthrough/initial/portfolio.webp";
import ImagePortfolioMobile from "img/walkthrough/portfolio.webp";

// If pragmatically started - redirection to /dashboard is needed first
const PortfolioWalkthrough = ({
  walkthrough: { portfolioIsOpen, portfolioStep },
  activePortfolioId,
  walkthroughActions: { startDataManagerWalkthrough, endWalkthrough, updateStep },
  enableBodyScroll,
  disableBodyScroll,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const steps = [
    {
      selector: isSmall ? '[data-tour="burger-button"]' : '[data-tour="portfolio-link"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.portfolio_link.heading")}</Heading>
          <div>{t("walkthrough.portfolio.portfolio_link.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="new-portfolio"]',
      position: "left",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.new_portfolio.heading")}</Heading>
          <div>{t("walkthrough.portfolio.new_portfolio.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="select-portfolio"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.select_portfolio.heading")}</Heading>
          <div>{t("walkthrough.portfolio.select_portfolio.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="edit-portfolio"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.edit_portfolio.heading")}</Heading>
          <div>{t("walkthrough.portfolio.edit_portfolio.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="portfolio-summary"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.summary.heading")}</Heading>
          <div>{t("walkthrough.portfolio.summary.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="portfolio-summary_ac"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.summary.aq.heading")}</Heading>
          <div>{t("walkthrough.portfolio.summary.aq.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="portfolio-summary_mv"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.summary.mv.heading")}</Heading>
          <div>{t("walkthrough.portfolio.summary.mv.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="portfolio-summary_rpl"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.summary.rpl.heading")}</Heading>
          <Trans i18nKey="walkthrough.portfolio.summary.rpl.content" components={{ br: <br /> }} />
        </div>
      ),
    },
    {
      selector: '[data-tour="portfolio-summary_upl"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.summary.upl.heading")}</Heading>
          <div>{t("walkthrough.portfolio.summary.upl.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="main-graph"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.main_graph.heading")}</Heading>
          <Trans i18nKey="walkthrough.portfolio.main_graph.content" components={{ br: <br /> }} />
        </div>
      ),
    },
    {
      selector: '[data-tour="main-graph-interval"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.main_graph.interval.heading")}</Heading>
          <div>{t("walkthrough.portfolio.main_graph.interval.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="ptf-asset-accounts"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.ptf_asset_accounts.heading")}</Heading>
          <div>{t("walkthrough.portfolio.ptf_asset_accounts.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="master-table-open"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.master_table_open.heading")}</Heading>
          <Trans i18nKey="walkthrough.portfolio.master_table_open.content" components={{ br: <br /> }} />
        </div>
      ),
    },
    {
      selector: '[data-tour="master-table-close"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.master_table_close.heading")}</Heading>
          <Trans i18nKey="walkthrough.portfolio.master_table_close.content" components={{ br: <br /> }} />
        </div>
      ),
    },
  ];

  const [updateTrigger, setUpdateTrigger] = useState(portfolioStep.toString());
  useEffect(() => {
    setTimeout(() => setUpdateTrigger(portfolioStep.toString()), 100);
  }, [portfolioStep]);

  return (
    <Tour
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
      steps={steps}
      onRequestClose={endWalkthrough}
      closeWithMask={false}
      disableInteraction
      rounded={8}
      startAt={0}
      isOpen={portfolioIsOpen}
      goToStep={portfolioStep}
      getCurrentStep={step => updateStep(step)}
      update={updateTrigger}
      updateDelay={500}
      maskClassName="tour-mask"
      CustomHelper={props => (
        <WalkThroughWizard
          {...props}
          onLastStepClick={() => {
            history.push(`/datamanager/containers?portfolioId=${activePortfolioId}`);
            startDataManagerWalkthrough();
          }}
          InitialStepImage={ImagePortfolio}
          InitialStepImageMobile={ImagePortfolioMobile}
        />
      )}
    />
  );
};

const mapStateToProps = state => ({
  walkthrough: state.walkthrough,
  activePortfolioId: getActivePortfolioId(state),
});

const mapDispatchToProps = dispatch => ({
  walkthroughActions: bindActionCreators(walkthroughActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioWalkthrough);
