import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { formatDate, formatWithoutTrailingZeros, utc } from "common/formatters";
import { validateTransaction } from "common/validators";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { transactionService } from "services/transactionService";
import { alertActions } from "actions/alertActions";
import { bindActionCreators } from "redux";
import {
  FEE,
  isTxTypeDepositWithdrawal,
  isTxTypeReward,
  isTxTypeStake,
  REBATE,
  transactionTypeOptions,
  transactionTypes,
} from "common/constants/transactionType";
import {
  getActiveOrganizationId,
  getCurrencyOptions,
  getCurrencyOptionsWithLastUsed,
  getOpenedPortfolio,
  getRawContainers,
  getRuleSets,
} from "selectors";
import { useTranslation } from "react-i18next";
import { formatAddresses } from "utils";
import { bucketActions } from "actions/bucketActions";
import TransactionFormContent from "./TransactionFormContent";

const EditTransactionForm = ({
  transactionContainerId,
  transactionId,
  labelOptions,
  transaction,
  currencyOptions,
  currencyOptionsWithLastUsed,
  openedPortfolio,
  transactionActions: { getTransactions, setLastUsedCurrencies },
  modalActions: { openFeedbackForm, hideModal, openSelectCounterTransactions, openEditTransaction },
  bucketActions: { getBuckets, setCounterTransactions, deleteTransactionsFromBucket },
  user,
  transfer,
  fullTransaction,
  containers,
  counterTransactions,
  activeOrganizationId,
  ruleSets,
  modalOptions,
}) => {
  const { t } = useTranslation();

  const [isTransfer, setIsTransfer] = useState(transfer ?? transaction.transfer);

  const typeOptions = useMemo(() => transactionTypeOptions(t, ...transactionTypes.filter(x => x !== FEE && x !== REBATE)), [t]);
  const rewardTxUnitPriceCurrency = transaction.quoteCurrency ?? openedPortfolio?.currency ?? "USD";

  const [subtransactionType, setSubtransactionType] = useState(null);

  const onSubmit = async (values, { setSubmitting }) => {
    const isDepositWithdrawal = isTxTypeDepositWithdrawal(values.type.value);
    const isStake = isTxTypeStake(values.type.value);
    const isReward = isTxTypeReward(values.type.value);

    try {
      const dateMoment = utc(values.date);

      const editedTransaction = {
        baseCurrency: values.baseCurrency.value,
        ...(isReward
          ? {
              quoteCurrency: rewardTxUnitPriceCurrency,
            }
          : {
              quoteCurrency: values.quoteCurrency.value,
            }),
        type: values.type.value,
        timestamp: values.timestamp
          .set({
            year: dateMoment.year(),
            month: dateMoment.month(),
            date: dateMoment.date(),
          })
          .valueOf(),
        note: values.note,
        addresses: formatAddresses(values.addresses, true),
        ...(isDepositWithdrawal || isStake
          ? {
              volume: values.volume,
            }
          : isReward
          ? {
              unitPrice: values.unitPrice || undefined,
              volume: values.volume,
              acquisitionPrice: values.acquisitionPrice || undefined,
            }
          : {
              baseQuantity: values.baseQuantity || undefined,
              quoteQuantity: values.quoteQuantity || undefined,
              unitPrice: values.unitPrice || undefined,
            }),
        ...(isDepositWithdrawal && { linkedTxIds: counterTransactions.map(x => x.id) }),
        transactionLabels: values.labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
      };

      if (subtransactionType) {
        const newSubtransaction = {
          type: subtransactionType,
          feeRebateCurrency: values.feeRebateCurrency.value,
          feeRebate: values.feeRebate,
          note: values.feeRebateNote,
          baseCurrency: editedTransaction.baseCurrency,
          quoteCurrency: editedTransaction.quoteCurrency,
          timestamp: editedTransaction.timestamp,
          mainTransactionId: transactionId,
          transactionLabels: values.labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
        };
        await transactionService.createTransaction(newSubtransaction, transactionContainerId, user);
      }

      await transactionService.editTransaction(editedTransaction, transactionId, transactionContainerId, user);

      // Remove newly added bucket transactions from respective buckets
      await counterTransactions.forEachAsync(async transaction => {
        if (transaction.counterBucketId) {
          await deleteTransactionsFromBucket(transaction.counterBucketId, [transaction.id], true);
        }
      });

      hideModal();
      alertActions.success(t("alert.success.transaction_edited"));
      setCounterTransactions([]);
      getTransactions();
      getBuckets();
    } catch (err) {
      alertActions.error(err);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        ...transaction,
        baseCurrency: currencyOptions.find(x => x.value === transaction.baseCurrency) ?? "",
        quoteCurrency:
          currencyOptions.find(
            x => x.value === (isTxTypeReward(transaction.type) ? transaction.baseCurrency : transaction.quoteCurrency)
          ) ?? "",
        unitPrice: formatWithoutTrailingZeros(transaction.unitPrice),
        baseQuantity: formatWithoutTrailingZeros(transaction.baseQuantity),
        type: typeOptions.find(o => o.value === transaction.type) ?? typeOptions[0],
        date: formatDate(utc(transaction.timestamp)),
        timestamp: utc(transaction.timestamp),
        note: transaction.note ?? "",
        addresses: transaction.addresses?.length > 0 ? transaction.addresses : [""],
        quoteQuantity: "", // only 2 our of 3 numbers should be present
        feeRebate: "",
        feeRebateCurrency: "",
        feeRebateNote: "",
        volume: formatWithoutTrailingZeros(transaction.volume),
        acquisitionPrice: formatWithoutTrailingZeros(transaction.acquisitionPrice),
        labels: transaction.transactionLabels?.map(x => labelOptions.find(y => y.value === x.transactionLabelId)) ?? [],
      }}
      enableReinitialize
      validate={values => validateTransaction(values, subtransactionType, openedPortfolio?.latestRuleSet)}
      onSubmit={onSubmit}>
      {props => (
        <TransactionFormContent
          {...props}
          transactionTypeOptions={typeOptions}
          openFeedbackForm={openFeedbackForm}
          isEdit
          setSubtransactionType={setSubtransactionType}
          subtransactionType={subtransactionType}
          isTransfer={isTransfer}
          setIsTransfer={setIsTransfer}
          counterTransactions={counterTransactions}
          containers={containers}
          openSelectCounterTransactions={() =>
            openSelectCounterTransactions(
              () => openEditTransaction(fullTransaction, undefined, isTransfer, true),
              fullTransaction
            )
          }
          setCounterTransactions={setCounterTransactions}
          rewardTxUnitPriceCurrency={rewardTxUnitPriceCurrency}
          activeOrganizationId={activeOrganizationId}
          ruleSets={ruleSets}
          hideModal={hideModal}
          currencyOptions={currencyOptions}
          currencyOptionsWithLastUsed={currencyOptionsWithLastUsed}
          setLastUsedCurrencies={setLastUsedCurrencies}
          labelOptions={labelOptions}
          modalOptions={modalOptions}
        />
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    currencyOptions: getCurrencyOptions(state),
    currencyOptionsWithLastUsed: getCurrencyOptionsWithLastUsed(state),
    user: state.user,
    counterTransactions: state.buckets.counterTransactions,
    containers: getRawContainers(state),
    openedPortfolio: getOpenedPortfolio(state),
    activeOrganizationId: getActiveOrganizationId(state),
    ruleSets: getRuleSets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionForm);
