import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
import cs from "./translations/cs.json";
import sk from "./translations/sk.json";
import es from "./translations/es.json";
import de from "./translations/de.json";

i18n
  .use(LanguageDetector) // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    fallbackLng: "en",
    // debug: document.location.origin.startsWith('http://localhost'),
    load: "languageOnly",
    whitelist: ["en", "cs", "sk", "es", "de"],
    resources: {
      en: {
        translation: en,
      },
      cs: {
        translation: cs,
      },
      sk: {
        translation: sk,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
