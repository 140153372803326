import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { modalActions } from "actions/modalActions";
import GBIcon from "img/footer-generalbytes.svg";
import { ReactComponent as SupportIcon } from "img/footer-support.svg";
import { ReactComponent as MailIcon } from "img/footer-email.svg";
import { ReactComponent as TelegramIcon } from "img/footer-telegram.svg";
import { ReactComponent as XIcon } from "img/footer-x.svg";
import { ReactComponent as FacebookIcon } from "img/footer-facebook.svg";
import { ReactComponent as LinkedInIcon } from "img/footer-linkedin.svg";
import { ReactComponent as GithubIcon } from "img/footer-github.svg";
import { changelogLink } from "common/constants/changelogLink";
import { getRawOpenedPortfolio } from "selectors";
import { userActions } from "actions/userActions";
import { WB_SUPPORT } from "common/constants/whaleBooksRoles";
import styles from "../Styles/AppStructure.module.scss";

const Footer = ({ isAuthenticated, activePortfolio, userRole, isPresentationMode, modalActions, userActions }) => {
  const { t } = useTranslation();

  const isSupport = userRole === WB_SUPPORT;

  return (
    <div className={styles.footer_wrapper}>
      <div className={styles.content_footer}>
        <Link to="/about">{t("footer.about_us")}</Link>
        <Link to="/legal">{t("footer.terms_conditions")}</Link>
        <a href={changelogLink} rel="noopener noreferrer" target="_blank">
          {t("footer.changelog")}
        </a>
        {!isAuthenticated && <button onClick={modalActions.openMailSubscription}>{t("footer.subscribe")}</button>}
        {isAuthenticated && (
          <Link to={`/vr${activePortfolio?.shortLink ? `/${activePortfolio.shortLink.substr(1)}` : ""}`}>VR</Link>
        )}
      </div>
      <div className={styles.contacts_footer}>
        <div className={styles.contact_links}>
          <Row>
            <Col>
              <div className={styles.social_media}>
                {isAuthenticated && (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      modalActions.openFeedbackForm();
                    }}>
                    <SupportIcon />
                  </a>
                )}
                <a href="mailto:support@whalebooks.com">
                  <MailIcon />
                </a>
                <a href="https://t.me/whalebooks" rel="noopener noreferrer" target="_blank">
                  <TelegramIcon />
                </a>
                <a href="https://twitter.com/thewhalebooks" rel="noopener noreferrer" target="_blank">
                  <XIcon />
                </a>
                <a href="https://www.facebook.com/thewhalebooks" rel="noopener noreferrer" target="_blank">
                  <FacebookIcon />
                </a>
                <a href="https://www.linkedin.com/company/whalebooks/" rel="noopener noreferrer" target="_blank">
                  <LinkedInIcon />
                </a>
                <a href="https://github.com/everytrade-io" rel="noopener noreferrer" target="_blank">
                  <GithubIcon />
                </a>
                {isSupport && (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => userActions.setPresentationMode(!isPresentationMode)}
                    title="Presentation Mode">
                    <FontAwesomeIcon icon={isPresentationMode ? faEyeSlash : faEye} style={{ height: "22px", width: "22px" }} />
                  </a>
                )}
              </div>
            </Col>
            <Col xs={12} md="auto" className="mx-auto d-flex justify-content-center py-3 py-md-0">
              <span className={styles.gb}>
                <div style={{ whiteSpace: "nowrap" }}>{t("footer.powered_by")}</div>
                <a href="https://www.generalbytes.com/" rel="noopener noreferrer" target="_blank">
                  <img style={{ height: "20px" }} src={GBIcon} alt="General Bytes" />
                </a>
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2 mb-4">Copyright © {new Date().getFullYear()}, GENERAL BYTES s.r.o.</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
    activePortfolio: getRawOpenedPortfolio(state),
    userRole: state.user.userRole,
    isPresentationMode: state.user.isPresentationMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
