import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { SelectFormField, TextFormField } from "components/Common/Inputs";
import { Form, GhostButton, LoaderOverlay, PrimaryButton } from "components/Common";
import history from "common/history";
import styles from "../Styles/Forms.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getReferenceOptions } from "queries/organizations";
import { getActiveOrganizationId } from "selectors";
import { connect } from "react-redux";
import { components } from "react-select";

const ContainerDetailForm = ({ container, onClose, updateContainer, activePortfolioId, organizationId }) => {
  const { t } = useTranslation();

  const onSubmit = async (values, { setSubmitting }) => {
    const updatedContainerDetail = {
      ...container,
      name: values.name,
      source: values.source,
      references: values.references?.map(x => x.reference),
    };
    await updateContainer(updatedContainerDetail, activePortfolioId, true);

    setSubmitting(false);
    onClose();
  };

  const { data, isLoading } = useQuery(getReferenceOptions(organizationId));
  const referenceOptions = data ?? [];

  return (
    <LoaderOverlay isLoading={isLoading}>
      <Formik
        enableReinitialize
        initialValues={{
          name: container?.name ?? "",
          source: container?.source ?? "",
          references: container?.references?.map(x => referenceOptions.find(y => y.value === x.id)) ?? [],
        }}
        onSubmit={onSubmit}
        validationSchema={object({
          name: string().required(),
        })}>
        {({ isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
            <TextFormField name="name" label={t("form.container_detail.name")} disabled={isSubmitting} required autoFocus />
            <TextFormField name="source" label={t("form.container_detail.source")} disabled={isSubmitting} />
            <SelectFormField
              name="references"
              label={t("form.reference_detail.name")}
              options={referenceOptions}
              disabled={isSubmitting}
              isClearable
              isMulti
              components={{
                Menu: props => (
                  <components.Menu {...props}>
                    {props.children}
                    <div className="my-1">
                      <GhostButton
                        labelClassName={styles.request_more_currencies_label}
                        label={`+ ${t("form.container_detail.add_new_reference")}`}
                        onClick={() => {
                          onClose();
                          history.push(`/organizations/${organizationId}/lists?add=true`);
                        }}
                      />
                    </div>
                  </components.Menu>
                ),
              }}
            />

            <PrimaryButton label={t("form.container_detail.save")} isLoading={isSubmitting} onClick={handleSubmit} />
          </Form>
        )}
      </Formik>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    organizationId: getActiveOrganizationId(state),
  };
}

export default connect(mapStateToProps)(ContainerDetailForm);
