import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Heading, PrimaryButton } from "../Common";

import styles from "../Styles/Landing.module.scss";

const t_fake = str => str;

const customersData = [
  {
    heading: t_fake("landing.customers.heading.investors"),
    customers: [
      t_fake("landing.customers.list.smallInvestors"),
      t_fake("landing.customers.list.professionalTraders"),
      t_fake("landing.customers.list.investmentCompanies"),
    ],
  },
  {
    heading: t_fake("landing.customers.heading.accountants"),
    customers: [
      t_fake("landing.customers.list.cpas"),
      t_fake("landing.customers.list.accountants"),
      t_fake("landing.customers.list.taxConsultants"),
    ],
  },
  {
    heading: t_fake("landing.customers.heading.funds"),
    customers: [
      t_fake("landing.customers.list.funds"),
      t_fake("landing.customers.list.cryptofunds"),
      t_fake("landing.customers.list.fundAdministrators"),
    ],
  },
  {
    heading: t_fake("landing.customers.heading.blockchainCommunity"),
    customers: [
      t_fake("landing.customers.list.generalBytesOperators"),
      t_fake("landing.customers.list.blockchainBusinesses"),
      t_fake("landing.customers.list.cryptoMiners"),
    ],
  },
];

const Customers = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.customers}>
      <Heading level={3} textAlign="center" margin="0 auto 1.5rem">
        {t("landing.customers.who_do_we_serve")}
      </Heading>
      <div className={cx(styles.customers_list, className)}>
        {customersData.map(o => (
          <div key={o.heading}>
            <Heading level={4}>{t(`${o.heading}`)}</Heading>
            <ul className="my-3">
              {o.customers.map(p => (
                <li key={p}>{t(`${p}`)}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className={styles.open_account}>
        <Heading level={2} margin="1rem 0 2rem">
          {t("landing.customers.open_account")}
        </Heading>
        <NavLink exact to="/signup">
          <PrimaryButton isLoading={false} label={t("landing.common.get_started_now")} />
        </NavLink>
      </div>
    </div>
  );
};

export default Customers;
