import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { WIDE } from "common/constants/modalWidths";
import { accountActions } from "actions/accountActions";
import { getActivePortfolioId, getCurrencyOptions } from "selectors";
import { LoaderOverlay } from "components/Common";
import { modalActions } from "actions/modalActions";
import ModalEnvelope from "./ModalEnvelope";
import EditAssetAccountForm from "../Forms/EditAssetAccountForm";

const EditAssetAccountModal = ({
  accountId,
  onClose,
  accountDetail,
  currencyOptions,
  activePortfolioId,
  accountActions: { loadAccountDetail, updateAccount },
  modalActions: { hideModal },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (accountDetail?.id !== accountId) loadAccountDetail(accountId);
  }, [accountId, accountDetail, loadAccountDetail]);

  return (
    <ModalEnvelope id="edit-asset-account-modal" heading={t("modal.edit_account.heading")} width={WIDE}>
      <LoaderOverlay isLoading={accountDetail?.id !== accountId}>
        <EditAssetAccountForm
          onClose={onClose}
          accountDetail={accountDetail}
          currencyOptions={currencyOptions}
          updateAccount={updateAccount}
        />
      </LoaderOverlay>

      <div className="w-100 d-flex mt-4 mb-n3 justify-content-center">
        <Link to={`/asset-accounts/${accountId}/transactions?portfolioId=${activePortfolioId}`} onClick={hideModal}>
          {t("account_detail.general_data.header")}
        </Link>
      </div>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    accountDetail: state.accounts.accountDetail,
    currencyOptions: getCurrencyOptions(state),
    activePortfolioId: getActivePortfolioId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAssetAccountModal);
