import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import ModalEnvelope from "./ModalEnvelope";
import ReferenceDetailForm from "containers/Forms/ReferenceDetailForm";

const ReferenceDetailModal = ({ referenceDetail, onClose, modalActions: { hideModal } }) => {
  const { t } = useTranslation();

  const isEdit = !!referenceDetail;

  const onSubmit = async (values, { setSubmitting }) => {
    await onClose(values);
    setSubmitting(false);
    hideModal();
  };

  return (
    <ModalEnvelope
      id="reference-detail-modal"
      heading={`${isEdit ? t("modal.reference_detail.edit") : t("modal.reference_detail.add")}`}>
      <ReferenceDetailForm referenceDetail={referenceDetail} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(ReferenceDetailModal);
