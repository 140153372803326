import React from "react";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import AddTransactionsToBucketForm from "../Forms/AddTransactionsToBucketForm";

const AddTransactionsToBucketModal = ({ transactionIds }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope id="add-bucket-tx-modal" heading={t("modal.add_tx_to_bucket.add")}>
      <AddTransactionsToBucketForm transactionIds={transactionIds} />
    </ModalEnvelope>
  );
};

export default AddTransactionsToBucketModal;
