import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField, TextFormField } from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";

import { taxResidencyOptions } from "common/constants/taxResidencies";
import { planOptions } from "common/constants/plans";
import { object, string } from "yup";
import { taxSubjectOptions } from "common/constants/taxSubjects";
import styles from "../Styles/Forms.module.scss";

const OrganizationDetailForm = ({ organization, onSubmit }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => ({
      taxResidencyOptions: taxResidencyOptions(t),
      taxSubjectOptions: taxSubjectOptions(t),
      planOptions: planOptions(t),
    }),
    [t]
  );

  return (
    <Formik
      initialValues={{
        name: organization?.name ?? "",
        taxResidency: options.taxResidencyOptions.find(x => x.value === organization?.taxResidency) ?? "",
        taxSubject: options.taxSubjectOptions.find(x => x.value === organization?.taxSubject) ?? "",
        plan: options.planOptions.find(x => x.value === organization?.plan) ?? options.planOptions[0],
      }}
      validationSchema={
        !organization
          ? object({
              name: string().required(),
              taxResidency: object().required(),
              taxSubject: object().required(),
              plan: object().required(),
            })
          : object({
              name: string().required(),
            })
      }
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          <TextFormField name="name" label={t("form.organization_detail.name")} required disabled={isSubmitting} autoFocus />
          {!organization && (
            <>
              <SelectFormField
                options={options.taxResidencyOptions}
                name="taxResidency"
                label={t("form.organization_detail.tax_residency")}
                required
                disabled={isSubmitting}
              />
              <SelectFormField
                name="taxSubject"
                options={options.taxSubjectOptions}
                label={t("form.tax_residency.tax_subject")}
                disabled={isSubmitting}
                required
              />
              <SelectFormField
                options={options.planOptions}
                name="plan"
                label={t("form.organization_detail.select_plan")}
                required
                disabled={isSubmitting}
              />
            </>
          )}
          <PrimaryButton
            label={organization?.id ? t("form.organization_detail.submit") : t("form.organization_detail.submit_new")}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationDetailForm;
