import React, { useState } from "react";
import { StatusWarning as WarningIcon } from "grommet-icons/icons";
import { useTranslation } from "react-i18next";
import { transactionService } from "services";
import { GhostButton, Tooltip } from "components/Common";
import styles from "components/Styles/DataManager.module.scss";
import DateLabel from "components/Dashboard/DateLabel";
import cx from "classnames";
import { formatThousandsSeparator } from "common/formatters";
import { ReactComponent as DownloadIcon } from "img/download-icon.svg";

const Transactions = ({
  container: {
    id,
    firstTransaction,
    lastTransaction,
    transactionsCount,
    ignoredFeeTransactionsCount,
    failedFeeTransactionsCount,
    ignoredTransactionsCount,
    errorTransactionsCount,
  },
  user,
  transactionActions: { exportContainerTransactions },
}) => {
  const { t } = useTranslation();
  const [downloadFetching, setDownloadFetching] = useState(false);

  return (
    <div className={cx(styles.container_detail, "mb-4")}>
      <span>
        <b>{t("data_manager.container_detail.transactions")}</b>
      </span>
      <span className={styles.grid_row}>
        <GhostButton icon={<DownloadIcon />} onClick={() => exportContainerTransactions(id)} className="m-0 p-0 ml-n1" />
      </span>
      <span>{t("data_manager.container_detail.tx_range")}</span>
      <DateLabel start={firstTransaction} end={lastTransaction} />
      <span>{t("data_manager.container_detail.tx_count")}</span>
      <span>{formatThousandsSeparator(transactionsCount)}</span>

      {ignoredFeeTransactionsCount > 0 && (
        <>
          <span>{t("data_manager.container_detail.ignored_fee_tx_count")}</span>
          <div className={styles.flex_space_between}>
            <span>{formatThousandsSeparator(ignoredFeeTransactionsCount)}</span>
            <div className={`${styles.flex_end} align-items-center`}>
              <WarningIcon size="smallMedium" className={styles.info} />
              <div className={styles.transaction_buttons}>
                <Tooltip tooltipContent={t("data_manager.container_detail.download_log")}>
                  <GhostButton
                    icon={<DownloadIcon />}
                    disabled={downloadFetching}
                    onClick={async () => {
                      try {
                        setDownloadFetching(true);
                        transactionService.downloadIgnoredFeesLog(id, user);
                      } catch (e) {}
                      setDownloadFetching(false);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}

      {failedFeeTransactionsCount > 0 && (
        <>
          <span>{t("data_manager.container_detail.failed_fee_tx_count")}</span>
          <div className={styles.flex_space_between}>
            <span>{formatThousandsSeparator(failedFeeTransactionsCount)}</span>
            <div className={`${styles.flex_end} align-items-center`}>
              <WarningIcon size="smallMedium" className={styles.info} />
              <div className={styles.transaction_buttons}>
                <Tooltip tooltipContent={t("data_manager.container_detail.download_log")}>
                  <GhostButton
                    icon={<DownloadIcon />}
                    disabled={downloadFetching}
                    onClick={async () => {
                      try {
                        setDownloadFetching(true);
                        transactionService.downloadFailedFeesLog(id, user);
                      } catch (e) {}
                      setDownloadFetching(false);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}

      {ignoredTransactionsCount > 0 && (
        <>
          <span>{t("data_manager.container_detail.ignored_tx_count")}</span>
          <div className={styles.flex_space_between}>
            <span>{formatThousandsSeparator(ignoredTransactionsCount)}</span>
            <div className={`${styles.flex_end} align-items-center`}>
              <WarningIcon size="smallMedium" className={styles.info} />
              <div className={styles.transaction_buttons}>
                <Tooltip tooltipContent={t("data_manager.container_detail.download_log")}>
                  <GhostButton
                    icon={<DownloadIcon />}
                    disabled={downloadFetching}
                    onClick={async () => {
                      try {
                        setDownloadFetching(true);
                        transactionService.downloadIgnoredTransactionsLog(id, user);
                      } catch (e) {}
                      setDownloadFetching(false);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}

      {errorTransactionsCount > 0 && (
        <>
          <span style={{ marginTop: "-2px" }}>{t("data_manager.container_detail.err_tx_count")}</span>
          <div className={styles.flex_space_between} style={{ marginTop: "-2px" }}>
            <span>{formatThousandsSeparator(errorTransactionsCount)}</span>
            <div className={styles.flex_end}>
              <WarningIcon size="smallMedium" className={styles.error} />
              <div className={styles.transaction_buttons}>
                <Tooltip tooltipContent={t("data_manager.container_detail.download_errors")}>
                  <GhostButton
                    icon={<DownloadIcon />}
                    disabled={downloadFetching}
                    onClick={async () => {
                      try {
                        setDownloadFetching(true);
                        transactionService.downloadTransactionContainerErrorLog(id, user);
                      } catch (e) {}
                      setDownloadFetching(false);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Transactions;
