import { useEffect } from "react";
import { withRouter, matchPath, useLocation } from "react-router-dom";
import { usePrevious } from "hooks";

const getDashboardPid = path =>
  matchPath(path, {
    path: "/dashboard/:pid",
    exact: true,
    strict: false,
  });

const onlyIdChangedCheck = (pathMatcher, prevPath, currentPath) => {
  const prevPid = pathMatcher(prevPath);
  const currentPid = pathMatcher(currentPath);
  return prevPid !== null && currentPid !== null;
};

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    const onlyIdChanged = onlyIdChangedCheck(getDashboardPid, prevLocation?.pathname, location.pathname);
    if (location.pathname !== prevLocation?.pathname && !onlyIdChanged) {
      window.scrollTo(0, 0);
    }
  }, [location, prevLocation]);

  return children;
};

export default withRouter(ScrollToTop);
