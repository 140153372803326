export const ASSETS = "ASSETS";
export const TAX_DEDUCTIBLE = "TAX_DEDUCTIBLE";

export const chartOfAccountTypes = [ASSETS, TAX_DEDUCTIBLE];

const t_fake = str => str;

const chartOfAccountTypeTranslations = {
  [ASSETS]: t_fake("chart_of_account_type.assets"),
  [TAX_DEDUCTIBLE]: t_fake("chart_of_account_type.tax_deductible"),
};

export const chartOfAccountTypeTranslator = val => chartOfAccountTypeTranslations[val] || "";
