import React from "react";
import { connect } from "react-redux";
import DistributionGraph from "components/Graphs/DistributionGraph";
import { getActivePortfolioId, getDistribution } from "selectors";
import { getGlobalFetchingState } from "selectors/globalSelectors";
import { LoaderOverlay } from "components/Common";
import { MEDIUM } from "common/constants/screenSizes";
import DistributionChartTooltip from "components/Tooltips/DistributionChartTooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "../Styles/Graphs.module.scss";

const MainGraphContainer = ({ distributionData: { distribution, isEmpty }, isLoading, activePortfolioId }) => {
  const { t } = useTranslation();

  // Hidden based on ETD-867
  if (true) {
    return null;
  }

  return (
    <div className={styles.distribution_chart_wrapper}>
      <>
        <DistributionChartTooltip isAbsolute />
        <LoaderOverlay isLoading={isLoading} spinnerSize={MEDIUM}>
          <DistributionGraph
            distribution={distribution}
            message={
              isEmpty ? (
                <>
                  {t("dashboard.distribution_chart.no_transactions_p1")}{" "}
                  <Link className="link" to={`/datamanager/containers?portfolioId=${activePortfolioId}`}>
                    {t("dashboard.distribution_chart.no_transactions_p2")}
                  </Link>
                  .
                </>
              ) : distribution.length === 0 && !isLoading ? (
                t("dashboard.distribution_chart.no_assets")
              ) : (
                ""
              )
            }
          />
        </LoaderOverlay>
      </>
    </div>
  );
};

const mapStateToProps = state => {
  const { isBalancesFetching, ...distributionData } = getDistribution(state);
  return {
    distributionData,
    isLoading: isBalancesFetching === true && !getGlobalFetchingState(state),
    activePortfolioId: getActivePortfolioId(state),
  };
};

export default connect(mapStateToProps)(MainGraphContainer);
