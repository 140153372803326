import * as contentDisposition from "content-disposition";
import axios from "axios";
import queryString from "query-string";
import FileSaver from "file-saver";
import { formatDate } from "common/formatters";
import i18n from "i18n";

async function getReportParameters(portfolioId, { vaultId, organizationId }) {
  const response = await axios.get(
    `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/report/computation/parameters`
  );
  return response.data;
}

async function downloadReport(portfolioId, from, to, type, language, { vaultId, organizationId }) {
  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/report/computation`,
      query: {
        from: formatDate(from),
        to: formatDate(to),
        type,
        locale: language,
      },
    },
    {
      skipNull: true,
    }
  );

  const response = await axios.get(query, {
    responseType: "blob",
  });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);

  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_report.pdf");
}

async function downloadAccountsStatementReport(portfolioId, from, to, accIds, language, { vaultId, organizationId }) {
  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/report/account`,
      query: {
        from: formatDate(from),
        to: formatDate(to),
        locale: language,
        accIds,
      },
    },
    {
      skipNull: true,
    }
  );

  const response = await axios.get(query, {
    responseType: "blob",
  });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);

  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_report.pdf");
}

async function downloadAccountingReport(portfolioId, { vaultId, organizationId }, options) {
  const query = queryString.stringifyUrl(
    {
      url: `organizations/${organizationId}/vaults/${vaultId}/portfolios/${portfolioId}/report/accounting-report`,
      query: {
        from: formatDate(options.from),
        to: formatDate(options.to),
        locale: i18n.language,
        type: options.type,
        "data-format": options.dataFormat,
        "first-report-number": options.firstReportNumber,
        "include-deposits": options.includeDeposits,
        "include-withdrawals": options.includeWithdrawals,
      },
    },
    {
      skipNull: true,
    }
  );

  const response = await axios.get(query, {
    responseType: "blob",
  });

  const disposition = contentDisposition.parse(response.headers["content-disposition"]);

  FileSaver.saveAs(response.data, disposition?.parameters?.filename || "WhaleBooks_report.pdf");
}

export const reportService = {
  getReportParameters,
  downloadReport,
  downloadAccountsStatementReport,
  downloadAccountingReport,
};
