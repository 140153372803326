import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "components/Styles/DataManager.module.scss";
import { GhostButton, Tooltip } from "components/Common";
import { apiConnectionInputsTranslator } from "common/constants/apiConnectionInputs";
import { BOOLEAN, FIAT_CURRENCY, SECRET, STRING } from "common/constants/dynamicFieldTypes";
import cx from "classnames";
import { ReactComponent as ClipboardIcon } from "img/clipboard-solid.svg";
import { copyToClipboard } from "utils";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Settings = ({
  isConnectorsFetching,
  isSyncActive,
  modalActions: { openConnectionCredentialsModal },
  containerId,
  connection,
  containerConnection,
  openDetailCallback,
  openConnectorSettings,
}) => {
  const { t } = useTranslation();

  const openSettings = useCallback(
    (reopenContainerDetail = true) => {
      if (containerId && connection && containerConnection) {
        openConnectionCredentialsModal(
          containerId,
          connection,
          containerConnection,
          reopenContainerDetail ? openDetailCallback : undefined
        );
      }
    },
    [containerId, connection, containerConnection, openDetailCallback, openConnectionCredentialsModal]
  );

  // Used to open connector settings directly after opening container detail by openConnectorSettings flag
  useEffect(() => {
    if (openConnectorSettings && !isConnectorsFetching) {
      openSettings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConnectorSettings, isConnectorsFetching]);

  return (
    <div className={cx(styles.container_detail, "mt-3")}>
      <span>
        <b>{t("data_manager.container_detail.connection")}</b>
      </span>
      <div className={styles.button_row}>
        <Tooltip tooltipContent={t("modal.bach_detail.edit_credentials")}>
          <GhostButton
            icon={<FontAwesomeIcon icon={faGear} />}
            disabled={isConnectorsFetching || isSyncActive}
            onClick={openSettings}
          />
        </Tooltip>
      </div>
      <span>{t("data_manager.container_detail.connection_name")}</span>
      <span>{containerConnection?.name || ""}</span>
      {containerConnection?.parameters.map(o => {
        const isAddressRow = apiConnectionInputsTranslator(o.label).includes("wallet_address");

        return (
          <React.Fragment key={o.id}>
            <span>
              {t(
                apiConnectionInputsTranslator(o.label) === "api_connection.currency_pairs"
                  ? "api_connection.general_currency_pairs"
                  : apiConnectionInputsTranslator(o.label)
              )}
            </span>
            <span className="mx-0">
              {isAddressRow && (
                <div className="d-flex align-items-center justify-content-between" style={{ overflowWrap: "anywhere" }}>
                  {connection.parameters?.[o.id]}{" "}
                  <GhostButton
                    className={cx("mx-0 mb-1", styles.input_button)}
                    icon={<ClipboardIcon />}
                    onClick={() => copyToClipboard(connection.parameters?.[o.id])}
                  />
                </div>
              )}
              {((!isAddressRow && o.type === STRING) || o.type === FIAT_CURRENCY) && connection.parameters?.[o.id]}
              {o.type === SECRET && "*****"}
              {o.type === BOOLEAN && (connection.parameters?.[o.id] === "true" ? t("general.yes") : t("general.no"))}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Settings;
