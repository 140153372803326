import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import EditChartAccountAccountForm from "containers/Forms/EditChartAccountAccountForm";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { modalActions } from "actions/modalActions";
import { getAccountSettingsConfigValue } from "selectors";
import { ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES } from "common/constants/accountSettings";
import ModalEnvelope from "./ModalEnvelope";

const EditChartAccountAccountModal = ({
  organizationId,
  account,
  allAccountNumbers,
  settingAccountPlaces,
  organizationActions: { editOrganizationChartAccountAccount },
  modalActions: { hideModal },
}) => {
  const { t } = useTranslation();

  const onSubmit = async (values, { setSubmitting }) => {
    await editOrganizationChartAccountAccount(organizationId, account.accountingAccountId, values.accountNumber);
    setSubmitting(false);
    hideModal();
  };

  return (
    <ModalEnvelope id="edit-chart-account-modal" heading={t("modal.edit_chart_account.heading")}>
      <EditChartAccountAccountForm
        account={account}
        allAccountNumbers={allAccountNumbers}
        settingAccountPlaces={settingAccountPlaces}
        onSubmit={onSubmit}
        onClose={hideModal}
      />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    settingAccountPlaces: getAccountSettingsConfigValue(state, ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditChartAccountAccountModal);
