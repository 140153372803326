import React, { useMemo } from "react";
import { bindActionCreators } from "redux";
import { confirmationActions } from "actions/confirmationActions";
import { connect } from "react-redux";
import { ActionCell, GhostButton, PrimaryButton, Table, Tooltip } from "components/Common";
import { useTranslation } from "react-i18next";
import history from "common/history";
import { Logout as LogoutIcon } from "grommet-icons";
import { getUserOrganizations } from "selectors";
import { ADMIN, READ, userPermissionsTranslator, WRITE } from "common/constants/userPermission";
import { Col, Row } from "react-bootstrap";
import { ONLINE } from "common/constants/paymentPlans";
import { DateLabelEnd } from "components/Dashboard/DateLabel";
import styles from "containers/Styles/Account.module.scss";
import { View as ViewIcon } from "grommet-icons/icons";
import { planTranslator } from "common/constants/plans";
import { taxResidencyTranslator } from "common/constants/taxResidencies";
import { PrimaryButtonSize } from "components/Common/Buttons/PrimaryButton";

const OrganizationsTable = ({ userOrganizations, confirmationActions: { openLeaveOrganizationConfirmation } }) => {
  const { t } = useTranslation();

  const userWorkspacesTableColumns = useMemo(
    () => [
      {
        Header: t("organizations.table_header.name"),
        accessor: "name",
      },
      {
        Header: t("organizations.table_header.tax_residence"),
        accessor: "taxResidency",
        Cell: ({ value }) => (value ? t(taxResidencyTranslator(value)) : "~"),
      },
      {
        Header: t("organizations.table_header.plan"),
        accessor: "subscriptionPlan",
        Cell: ({ value, row }) => (
          <Row>
            <Col className="d-flex align-items-center">
              <span className="mr-4">{t(planTranslator(value))}</span>
              {value === ONLINE && (
                <PrimaryButton
                  size={PrimaryButtonSize.Small}
                  collapsed
                  noIcon
                  onClick={() => history.push(`/organizations/${row.original.id}/upgrade`)}
                  label={t("button.upgrade")}
                />
              )}
            </Col>
          </Row>
        ),
      },
      {
        Header: t("organizations.table_header.valid_to"),
        accessor: "subscriptionEnd",
        Cell: ({ value, row }) => <DateLabelEnd end={value} planName={row.original.subscriptionPlan} noDatesLabel="~" />,
      },
      {
        Header: t("organizations.table_header.permissions"),
        accessor: "role",
        Cell: ({ value }) => t(userPermissionsTranslator(value)),
      },
      {
        Header: t("organizations.table_header.created_at"),
        accessor: "createdAt",
        Cell: ({ value }) => value ?? "~",
      },
      {
        Header: <div style={{ textAlign: "right" }}>{t("organizations.table_header.action")}</div>,
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <span className="d-flex justify-content-end align-items-center" style={{ overflow: "hidden" }}>
              <ActionCell allowFor={[READ, WRITE, ADMIN]} activeRole={original.role}>
                <Tooltip tooltipContent={t("organizations.table.leave_organization")}>
                  <GhostButton
                    icon={<LogoutIcon />}
                    onClick={e => {
                      e.stopPropagation();
                      openLeaveOrganizationConfirmation(original);
                    }}
                    actionButton
                  />
                </Tooltip>
              </ActionCell>
              <GhostButton
                actionButton
                icon={<ViewIcon />}
                onClick={() => history.push(`/organizations/${original.id}/detail`, { displayName: original.name })}
              />
            </span>
          );
        },
        id: "action",
        className: styles.narrow_row,
      },
    ],
    [t, openLeaveOrganizationConfirmation]
  );

  return <Table data={userOrganizations} columns={userWorkspacesTableColumns} getRowId={row => row.id} />;
};

function mapStateToProps(state) {
  return {
    userOrganizations: getUserOrganizations(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsTable);
