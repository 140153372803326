import React from "react";
import {
  BUY,
  isTxTypeDepositWithdrawal,
  isTxTypeFeeRebate,
  isTxTypeReward,
  isTxTypeStake,
} from "common/constants/transactionType";
import { getRuleSets } from "selectors";
import { connect } from "react-redux";
import { ON_ACQUISITION } from "common/constants/rewardTimingOptions";
import { getTransactionRealizationType } from "utils";
import { formatDate, utc } from "common/formatters";
import FormattedNumberComponent from "components/Common/FormattedNumber";

const FormattedNumber = ({ number, currency }) => (
  <FormattedNumberComponent number={number} currency={currency} displayCurrency roundToFixedCrypto={10} />
);

const RateAndTotalLabel = ({
  type,
  quoteQuantity,
  unitPrice,
  baseQuantity,
  baseCurrency,
  quoteCurrency,
  feeRebate,
  feeRebateCurrency,
  volume,
  acquisitionPrice,
  timestamp,
  ruleSets,
}) => {
  if (isTxTypeDepositWithdrawal(type) || isTxTypeStake(type)) {
    return (
      <span>
        <FormattedNumber number={volume ?? 0} currency={baseCurrency} />
      </span>
    );
  }

  if (isTxTypeReward(type)) {
    // ON_ACQUISITION / ON_SALE
    const txRealizationType = getTransactionRealizationType(type, formatDate(utc(timestamp)), ruleSets);
    return txRealizationType === ON_ACQUISITION ? (
      <>
        <FormattedNumber number={volume ?? 0} currency={baseCurrency} /> @{" "}
        <FormattedNumber number={unitPrice ?? 0} currency={quoteCurrency} /> <>&larr;</>{" "}
        <FormattedNumber number={acquisitionPrice ?? 0} currency={quoteCurrency} />
      </>
    ) : (
      <FormattedNumber number={volume ?? 0} currency={baseCurrency} />
    );
  }

  if (isTxTypeFeeRebate(type)) {
    return (
      <span>
        <FormattedNumber number={feeRebate ?? 0} currency={feeRebateCurrency} />
      </span>
    );
  }

  return (
    <>
      <span>
        <FormattedNumber number={baseQuantity ?? 0} currency={baseCurrency} /> @{" "}
        <FormattedNumber number={unitPrice ?? 0} currency={quoteCurrency} />
      </span>{" "}
      {type === BUY ? <>&larr;</> : <>&rarr;</>}{" "}
      <span>
        <FormattedNumber number={quoteQuantity ?? 0} currency={quoteCurrency} />
      </span>
    </>
  );
};

function mapStateToProps(state) {
  return {
    ruleSets: getRuleSets(state),
  };
}

export default connect(mapStateToProps)(RateAndTotalLabel);
