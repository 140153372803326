import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { GhostButton, Heading, PermissionCheck, Text } from "components/Common";
import { Col, Row } from "react-bootstrap";
import styles from "pages/Pages.module.scss";
import cx from "classnames";
import Delimiter from "components/Common/Delimiter";
import { Edit as EditIcon, Trash as TrashIcon } from "grommet-icons/icons";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import Subscription from "components/Organization/Subscription";
import { OWNER, READ } from "common/constants/userPermission";
import { getActiveOrganizationId, getRawOrganizationDetails, getUserMemberships } from "selectors";
import history from "common/history";
import { languages } from "common/constants/languages";
import LanguageIcon from "components/Common/LanguageIcon";
import { OTHER, userCategoriesTranslator } from "common/constants/userCategories";

const Profile = ({
  email,
  organizationId,
  userMemberships,
  organizationDetails,
  user: { localIdentity, setNewPasswordFetching, userCategories, otherUserCategory },
  modalActions: { openApplicationPreferences, openChangePasswordModal, openWalkthrough, openSelectUserCategory },
  confirmationActions: { openLeaveWhaleBooksConfirmation, openSetPasswordConfirmation },
}) => {
  const { t, i18n } = useTranslation();

  const currentMembership = useMemo(
    () => userMemberships.find(o => o.organizationId === organizationId),
    [organizationId, userMemberships]
  );

  const currentUserRole = useMemo(() => currentMembership?.role || READ, [currentMembership]);
  const { organizationData = {} } = organizationDetails[organizationId] || {};

  const selectedLanguage = languages.find(o => o.value === i18n.language || o.value.startsWith(i18n.language)) || languages[0];

  return (
    <div className={styles.app_content_padding}>
      <Heading level={2} className="mb-4">
        {t("profile.settings.header")}
      </Heading>

      <Row className="mt-3" noGutters>
        <Col xs={12} lg={6} className={cx(styles.border_right, "pr-lg-3")}>
          <div className="d-flex justify-content-between">
            <Heading level={3}>{t("page.profile.settings")}</Heading>
            <GhostButton
              icon={<EditIcon />}
              disabled={setNewPasswordFetching}
              label={t("profile.settings.change_password")}
              onClick={localIdentity ? openChangePasswordModal : openSetPasswordConfirmation}
              className="mr-n2 pr-0"
            />
          </div>

          <Delimiter className="mt-3" />

          <div className="detail-card">
            <div>
              <div>{t("profile.settings.account_name")}</div>
              <div>{email}</div>
              <div>{t("organizations.table_header.created_at")}</div>
              <div>{currentMembership?.joined}</div>
              <div>{t("profile.settings.password")}</div>
              <div>*******</div>
            </div>

            <Delimiter />

            <div className="d-flex justify-content-between">
              <Heading level={4}>{t("profile.settings.leave_whalebooks")}</Heading>
              <GhostButton
                critical
                icon={<TrashIcon />}
                label={t("profile.settings.delete_profile")}
                onClick={openLeaveWhaleBooksConfirmation}
                className="mr-n2 pr-0"
              />
            </div>
          </div>
        </Col>

        <Col xs={12} lg={6} className="mt-5 mt-lg-0 pl-lg-3">
          <div className="d-flex justify-content-between">
            <Heading level={3}>{t("organization.subscription_header")}</Heading>
            <PermissionCheck activeRole={currentUserRole} allowFor={[OWNER]}>
              <GhostButton
                icon={<EditIcon />}
                label={t("profile.settings.change_plan")}
                onClick={() => history.push(`/organizations/${organizationId}/upgrade`)}
                className="mr-n2 pr-0"
              />
            </PermissionCheck>
          </div>
          <Delimiter className="mt-3" />
          <Subscription organization={organizationData} />

          <div className="d-flex justify-content-between mt-5">
            <Heading level={3}>{t("profile.settings.application_heading")}</Heading>
            <GhostButton
              icon={<EditIcon />}
              label={t("button.edit")}
              onClick={() =>
                openApplicationPreferences({
                  initialValues: {
                    language: selectedLanguage,
                  },
                })
              }
              className="mr-n2 pr-0"
            />
          </div>
          <Delimiter className="mt-3" />
          <div className="detail-card">
            <div>
              <div>{t("profile.settings.language")}</div>
              <div>
                <LanguageIcon lang={selectedLanguage.lang} /> {selectedLanguage?.label ? t(selectedLanguage.label) : ""}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-5">
            <Heading level={3}>{t("profile.settings.interests")}</Heading>
            <GhostButton
              icon={<EditIcon />}
              label={t("button.edit")}
              onClick={() =>
                openSelectUserCategory({
                  initialValues: {
                    userCategories,
                    otherUserCategory,
                  },
                })
              }
              className="mr-n2 pr-0"
            />
          </div>
          <Delimiter className="mt-3" />
          <div className="detail-card">
            <div>
              <div>{t("profile.settings.interest_category")}</div>
              <div>
                {userCategories ? (
                  userCategories.map((o, index) => (
                    <Text key={o}>
                      {`${t(`${userCategoriesTranslator(o)}`)}${o === OTHER ? ` (${otherUserCategory})` : ""}`}
                      {index < userCategories.length - 1 ? ", " : ""}
                    </Text>
                  ))
                ) : (
                  <Text>{t("profile.settings.no_categories")}</Text>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    email: state.user.email,
    user: state.user,
    organizationId: getActiveOrganizationId(state),
    userMemberships: getUserMemberships(state),
    organizationDetails: getRawOrganizationDetails(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
