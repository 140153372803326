// When action is cancelled 'PENDING' actions gets called before 'REJECTED' ->
// it leeds to setting fetching flag to false even though there is an ongoing action waiting to be resolved.
// This middleware prevents 'REJECTED' action to be fired in case request got cancelled;

import { GET_CONTAINERS } from "actions/types";

// action GET_CONTAINERS needs to be performed as usual, even if request was cancelled
export default () => next => action => {
  if (action.type.endsWith("_REJECTED") && !action.type.includes(GET_CONTAINERS) && action.payload?.isCancelled) return;
  return next(action);
};
