import React from "react";
import { Heading, Tab, Tabs } from "components/Common";
import { useTranslation } from "react-i18next";
import history from "common/history";

import styles from "pages/Pages.module.scss";
import Settings from "components/ApiManagement/Settings";
import Permissions from "components/ApiManagement/Permissions";
import { useScreenSize } from "hooks";

const pageIndexes = ["permissions", "settings"];

const ApiManagement = ({ match }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <div className={styles.app_content_padding}>
      <Tabs
        HeaderComponent={({ children }) => (
          <>
            {isSmall ? (
              <div className="my-2">{children}</div>
            ) : (
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <Heading level={2}>{t("api_management.heading")}</Heading> {children}
              </div>
            )}
          </>
        )}
        defaultTabIndex={Math.max(
          pageIndexes.findIndex(o => o === match.params.tab),
          0
        )}
        onTabClick={index => history.push(`/api-management/${pageIndexes[index] || ""}`)}>
        <Tab title={t("api_management.tabs.permissions")}>
          <Permissions />
        </Tab>
        <Tab title={t("api_management.tabs.settings")}>
          <Settings />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ApiManagement;
