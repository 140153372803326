import React from "react";
import { Formik } from "formik";
import { Form, PrimaryButton, Text } from "components/Common";
import { useTranslation } from "react-i18next";
import { utc } from "common/formatters";
import { transactionTypeOptions } from "common/constants/transactionType";
import { array, lazy, object } from "yup";
import { SelectFormField } from "components/Common/Inputs";
import BulkFilterFormContent from "./BulkFilterFormContent";

const SetLabelForm = ({ onSubmit, containers, labelOptions }) => {
  const { t } = useTranslation();

  const containerNames = containers.map(o => o.name).join(", ");

  return (
    <Formik
      initialValues={{
        type: "",
        baseCurrency: "",
        quoteCurrency: "",
        dateFrom: "",
        timestampFrom: utc().startOf("day"),
        dateTo: "",
        timestampTo: utc().endOf("day"),
        labels: [],
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        labels: array().min(1, t("validation.required")).required(t("validation.required")),
        baseCurrency: lazy(() =>
          object().when("quoteCurrency", {
            is: quoteCurrency => !!quoteCurrency,
            then: object().required(t("validation.required")),
          })
        ),
        quoteCurrency: lazy(() =>
          object().when("baseCurrency", {
            is: baseCurrency => !!baseCurrency,
            then: object().required(t("validation.required")),
          })
        ),
      })}>
      {formikProps => (
        <Form onSubmit={formikProps.handleSubmit}>
          <BulkFilterFormContent {...formikProps} transactionTypeOptions={transactionTypeOptions(t)} isTypeOptional />

          <SelectFormField
            name="labels"
            options={labelOptions}
            label={t("form.set_bulk_label.label")}
            required
            isSearchable={false}
            disabled={formikProps.isSubmitting}
            isClearable
            isMulti
          />

          <Text as="div" margin="0 0 1.1rem" size="small">
            {t("form.set_bulk_label.selected_containers", { containers: containerNames })}
          </Text>

          <PrimaryButton
            label={t("form.set_bulk_label.set")}
            isLoading={formikProps.isSubmitting}
            onClick={formikProps.handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SetLabelForm;
