import React, { useEffect, useState } from "react";
import { alertActions } from "actions/alertActions";
import ReactDOM from "react-dom";
import { useScript } from "hooks";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { useTranslation } from "react-i18next";
import DateLabel from "components/Dashboard/DateLabel";
import { LoaderOverlay, Heading } from "components/Common";
import { organizationService } from "services";
import { getGlobalFetchingState } from "selectors";

import styles from "./Styles/Checkout.module.scss";

const Checkout = ({ organizationActions, globalLoader, type, organizationId }) => {
  const { t } = useTranslation();
  const [loaded, error] = useScript(
    `https://www.paypal.com/sdk/js?client-id=${document.paypalId}&disable-funding=sofort,credit,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24`
  );
  const [planData, setPlanData] = useState(null);
  const [planDataLoading, setPlanDataLoading] = useState(null);

  useEffect(() => {
    const getUuid = async () => {
      setPlanDataLoading(true);
      try {
        const planBeData = await organizationService.newSubscription(new Date(), type, organizationId);
        setPlanData(planBeData);
      } catch (err) {
        if (err) alertActions.error(err);
      }
      setPlanDataLoading(false);
    };
    getUuid();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createOrder = async (data, actions) => {
    const { uuid } = planData;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "1199.0",
            currency_code: "USD",
          },
          custom_id: uuid,
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  const onApprove = async (data, actions) => {
    try {
      const result = await organizationActions.updatePaymentPlan(data.orderID, actions.order, planData, organizationId);
      const affiliate = window.tap;

      if (result?.action.type.endsWith("_FULFILLED") && affiliate) {
        affiliate("conversion", data.orderID, 1199);
      } else {
        console.log("Affiliate failed");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const PayPalButton = loaded && !error ? window.paypal.Buttons.driver("react", { React, ReactDOM }) : null;

  return (
    <LoaderOverlay isLoading={(!loaded || planDataLoading) && !globalLoader} className={styles.checkout}>
      {PayPalButton && planData && (
        <div>
          <DateLabel textAlign="center" as="div" start={planData.start} end={planData.end} />
          <Heading level={4} textAlign="center" margin="0.5rem 0 1rem">
            {t("checkout.total_price", { price: 1199 })}
          </Heading>
          <div className={styles.paypal_buttons}>
            <PayPalButton
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
              onError={error => console.error(error)}
              style={{
                color: "white",
                shape: "pill",
                label: "paypal",
              }}
            />
          </div>
        </div>
      )}
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    globalLoader: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
