import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRouteMatch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { Col, Row } from "react-bootstrap";
import styles from "pages/Pages.module.scss";
import { Heading, LoaderOverlay, Tab, Tabs } from "components/Common";
import history from "common/history";
import { accountActions } from "actions/accountActions";
import { useUrlHelper } from "hooks";
import BackButton from "components/Common/BackButton";
import { getActivePortfolioIdSearchUrl, getFiatOptions, isAccountDetailLoading } from "selectors";
import { transactionActions } from "actions/transactionActions";
import { SelectInput } from "components/Common/Inputs";
import { customBoldSelectStyles } from "common/styles/selectStyles";
import GeneralData from "containers/Accounts/GeneralData";
import Addresses from "containers/Accounts/Addresses";
import Transactions from "containers/Accounts/Transactions";
import { sortBy } from "lodash";

const pageIndexes = ["transactions", "addresses", "general"];

function AccountDetail({
  accountDetail,
  isLoading,
  portfolioIdSearch,
  accounts,
  fiatOptions,
  accountActions: { loadAccountDetail, getAccounts },
  transactionActions: { selectPortfolio },
}) {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { id } = useUrlHelper();
  const location = useLocation();

  useEffect(() => {
    if (id) loadAccountDetail(id);
  }, [id, loadAccountDetail]);

  useEffect(() => {
    if (accounts.length === 0) getAccounts();
  }, [getAccounts, accounts]);

  useEffect(() => {
    const { portfolioId } = queryString.parse(location.search, { parseNumbers: true });
    selectPortfolio(portfolioId || null);
  }, [location.search, selectPortfolio]);

  const sortedAccounts = useMemo(() => {
    const accountsWithCurrencyType = accounts.map((x, index) => ({
      ...x,
      // Fiat first, then crypto
      currencyType: fiatOptions.some(y => y.value === x.currency) ? "FIAT" : "KRYPTO",
      lowerCaseName: x.name.toLowerCase(),
    }));

    return sortBy(accountsWithCurrencyType, x => [x.lowerCaseName, x.currencyType, x.currency]);
  }, [accounts, fiatOptions]);

  const accountOptions = useMemo(
    () => sortedAccounts.map(x => ({ label: `${x.name} (${x.currency})`, value: x.id })),
    [accounts]
  );

  return (
    <LoaderOverlay isLoading={isLoading}>
      <div className={styles.app_content_padding}>
        <Tabs
          HeaderComponent={({ children }) => (
            <Row className="mb-3 align-items-center" style={{ rowGap: "1rem" }}>
              {accountDetail && (
                <Col xs={12} md="auto">
                  <Heading level={2} className="d-flex align-items-center">
                    <BackButton onClick={() => history.push(`/asset-accounts${portfolioIdSearch}`)} className="mr-1 mb-0" />

                    <SelectInput
                      value={accountOptions.find(x => x.value === id)}
                      onChange={val => history.push(`/asset-accounts/${val.value}/transactions${portfolioIdSearch}`)}
                      options={accountOptions}
                      wrapperClassName={styles.account_detail_select}
                      styles={customBoldSelectStyles}
                      isInline
                      name="active-account"
                    />
                  </Heading>
                </Col>
              )}
              <Col xs="auto" className="ml-auto">
                {children}
              </Col>
            </Row>
          )}
          onTabClick={index => history.push(`/asset-accounts/${id}/${pageIndexes[index] || ""}${portfolioIdSearch}`)}
          defaultTabIndex={Math.max(
            pageIndexes.findIndex(o => o === match.params.tab),
            0
          )}>
          <Tab title={t("account_detail.transactions.heading")}>
            <Transactions />
          </Tab>
          <Tab title={t("account_detail.addresses.heading")}>
            <Addresses />
          </Tab>
          <Tab title={t("account_detail.general_data.heading")}>
            <GeneralData />
          </Tab>
        </Tabs>
      </div>
    </LoaderOverlay>
  );
}

function mapStateToProps(state) {
  return {
    accountDetail: state.accounts.accountDetail,
    isLoading: isAccountDetailLoading(state),
    portfolioIdSearch: getActivePortfolioIdSearchUrl(state),
    accounts: state.accounts.accounts,
    fiatOptions: getFiatOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail);
