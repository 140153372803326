export const ACCOUNTING_QUESTION = "ACCOUNTING_QUESTION";
export const BUG_REPORT = "BUG_REPORT";
export const FEATURE_REQUEST = "FEATURE_REQUEST";
export const FEEDBACK = "FEEDBACK";
export const GENERAL_INQUIRY = "GENERAL_INQUIRY";
export const BECOME_ACCOUNTANT = "BECOME_ACCOUNTANT";
export const PARTNERSHIP = "PARTNERSHIP";
export const SUBSCRIPTION = "SUBSCRIPTION";

export const FEATURE_EXCHANGE = "FEATURE_EXCHANGE";
export const FEATURE_COIN_PAIR = "FEATURE_COIN_PAIR";
export const OTHER = "OTHER";

const t_fake = str => str;

export const feedbackTypes = [
  ACCOUNTING_QUESTION,
  BUG_REPORT,
  FEATURE_REQUEST,
  FEEDBACK,
  GENERAL_INQUIRY,
  BECOME_ACCOUNTANT,
  PARTNERSHIP,
  SUBSCRIPTION,
];

export const featureTypes = [FEATURE_EXCHANGE, FEATURE_COIN_PAIR, OTHER];

const feedbackTypeTranslations = {
  [ACCOUNTING_QUESTION]: t_fake("constant.feedback_type.accounting_question"),
  [BUG_REPORT]: t_fake("constant.feedback_type.bug_report"),
  [FEATURE_REQUEST]: t_fake("constant.feedback_type.feature_request"),
  [FEEDBACK]: t_fake("constant.feedback_type.feedback"),
  [GENERAL_INQUIRY]: t_fake("constant.feedback_type.general_inquiry"),
  [BECOME_ACCOUNTANT]: t_fake("constant.feedback_type.become_accountant"),
  [PARTNERSHIP]: t_fake("constant.feedback_type.partnership"),
  [SUBSCRIPTION]: t_fake("constant.feedback_type.subscription"),
};

export const feedbackTypeTranslator = val => feedbackTypeTranslations[val] || "";

const featureTypeTranslations = {
  [FEATURE_EXCHANGE]: t_fake("constant.feature_type.exchange"),
  [FEATURE_COIN_PAIR]: t_fake("constant.feature_type.coin_pair"),
  [OTHER]: t_fake("constant.feature_type.other"),
};

export const featureTypeTranslator = val => featureTypeTranslations[val] || "";

// MESSAGES
export const BECOME_ACCOUNTANT_MESSAGE = t_fake("messages.become_certified_accountant");
