import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const DeleteApiManagementPermission = ({ onConfirm, onCancel, permission }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_api_permission.heading")}
      confirmLabel={t("confirmation.delete_api_permission.confirm_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.delete_api_permission.content">
          Do you want to revoke API access permission for a third-party application? The removal is irreversible. However, you can
          recreate them later.
          <br />
          <p>
            Authorised object
            <br />
            {{ name: permission?.name }}
          </p>
          <p>
            Permissions
            <br />
            Read & Write
          </p>
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default DeleteApiManagementPermission;
