import React from "react";
import {
  CustomFormField,
  DateInput,
  DecimalFormField,
  SelectFormField,
  TextFormField,
  TimeInput,
} from "components/Common/Inputs";
import { formatTime, utc } from "common/formatters";
import { baseQuoteTypes, DEPOSIT, isTxSingleCurrency, transactionTypeTranslator } from "common/constants/transactionType";
import { useTranslation } from "react-i18next";
import { disabledDays } from "utils";
import BigCurrencyDecimal from "components/Formating/BigCurrencyDecimal";
import { SubtransactionFeeRebateFields } from "containers/Forms/Transactions/TransactionTypes/TransactionFormFields";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../../Styles/Forms.module.scss";

const StakeTransactionForm = ({
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
  transactionTypeOptions,
  currencyOptions,
  currencyOptionsWithLastUsed,
  subtransactionType,
  setSubtransactionType,
  isTransfer,
  getRate,
  txType,
  areFurtherInputsDisabled,
  submitButton,
  labelOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.fields_row}>
        <SelectFormField
          name="type"
          options={transactionTypeOptions}
          label={t("form.add_transaction.trade_type")}
          required
          disabled={isSubmitting}
          isClearable
          onSelect={value => {
            const newType = value?.value;

            if (newType && !txType) {
              return;
            }

            if (baseQuoteTypes.includes(newType) && baseQuoteTypes.includes(txType)) {
              return;
            }

            if (isTxSingleCurrency(newType) && isTxSingleCurrency(txType)) {
              return;
            }

            setFieldValue("quoteCurrency", "");
            if (values.volume) {
              setFieldValue("baseQuantity", values.volume);
            }
          }}
        />

        <CurrencySelectFormField
          name="baseCurrency"
          options={currencyOptionsWithLastUsed}
          label={t("form.add_transaction.currency")}
          required
          disabled={isSubmitting}
          isClearable
          onSelect={val => setFieldValue("quoteCurrency", val)}
        />
      </div>
      <CustomFormField
        name="timestamp"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.add_transaction.date")}
        required
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.date}
            disabled={isSubmitting}
            isPlain
            isCustomField
            minDate={disabledDays.minDate}
            maxDate={disabledDays.maxDate}
            onBlur={() => setFieldTouched("timestamp", true)}
            onChange={value => {
              setFieldValue("date", value);
              if (values.baseCurrency && !areFurtherInputsDisabled) {
                getRate({ date: value, base: values.baseCurrency.value, quote: values.baseCurrency.value });
              }

              if (!values.timestamp) {
                setFieldValue(
                  "timestamp",
                  utc(values.timestamp ?? undefined).set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                );
              }
            }}
            isClearable
            onClear={() => {
              setFieldValue("date", null);
              setFieldValue("timestamp", null);
            }}
          />
          <TimeInput
            value={formatTime(values.timestamp, true)}
            isPlain
            isCustomField
            enableSeconds
            disabled={isSubmitting}
            className={styles.time}
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestamp",
                utc(values.timestamp ?? undefined).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>

      <div className={styles.fields_row}>
        <DecimalFormField
          name="volume"
          label={t("form.add_transaction.basic_volume")}
          disabled={isSubmitting || areFurtherInputsDisabled}
          required>
          <span className={styles.small_text}>{values.baseCurrency?.value || ""}</span>
        </DecimalFormField>
        <TextFormField
          name="note"
          label={t("form.add_transaction.note")}
          disabled={isSubmitting || areFurtherInputsDisabled}
          autoFocus={modalOptions?.focusNote}
        />
      </div>

      <SelectFormField
        name="labels"
        options={labelOptions}
        label={t("form.add_transaction.labels")}
        disabled={isSubmitting || areFurtherInputsDisabled}
        isClearable
        isMulti
        autoFocus={modalOptions?.focusLabels}
      />

      <SubtransactionFeeRebateFields
        values={values}
        txType={txType}
        areFurtherInputsDisabled={areFurtherInputsDisabled}
        isSubmitting={isSubmitting}
        subtransactionType={subtransactionType}
        setSubtransactionType={setSubtransactionType}
        currencyOptions={currencyOptions}
      />

      <div
        className={
          values.type && values.baseCurrency && !areFurtherInputsDisabled ? styles.tx_info_label : styles.form_label_hidden
        }>
        <div>
          <div>
            {`${t(transactionTypeTranslator(txType, isTransfer))}: `}
            <BigCurrencyDecimal number={values.volume || 0} roundToFixedCrypto={10} currency={values.baseCurrency?.value} />{" "}
            {values.baseCurrency?.value}
          </div>
          {subtransactionType && (
            <div>
              {`${t(transactionTypeTranslator(subtransactionType))}: `}
              <BigCurrencyDecimal
                number={values.feeRebate || 0}
                roundToFixedCrypto={10}
                currency={values.feeRebateCurrency?.value}
              />
              {` ${values.feeRebateCurrency?.value || ""}`}
            </div>
          )}
          {values.addresses.length > 0 && !!values.addresses[0] && (
            <div style={{ overflowWrap: "anywhere" }}>
              {`${txType === DEPOSIT ? t("form.add_transaction.from_address") : t("form.add_transaction.to_address")}: `}
              {values.addresses.map((x, index) => (index === 0 ? x : `, ${x}`))}
            </div>
          )}
        </div>
      </div>

      {submitButton}
    </>
  );
};

export default StakeTransactionForm;
