import React from "react";
import { useTranslation } from "react-i18next";
import Delimiter from "components/Common/Delimiter";
import { Link } from "react-router-dom";
import { useUrlHelper } from "hooks";
import { chartOfAccountCategories, chartOfAccountUrl } from "containers/Organizations/ChartOfAccount/ChartOfAccount";
import { Heading } from "../Common";

import styles from "./Organization.module.scss";

const AccountingSoftwareHelp = () => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  return (
    <>
      <Heading level={3}>{t("organization_detail.accounting_software.setup_whalebooks.header")}</Heading>
      <Delimiter className="mt-3" />
      <div className={styles.organization_info}>
        <div>
          1) {t("organization_detail.accounting_software.set_your")}{" "}
          <Link to={`/organizations/${id}/${chartOfAccountUrl}`} rel="noopener noreferrer" target="_blank">
            {t("organization_detail.accounting_software.setup_whalebooks.chart_of_account")}
          </Link>
          .
        </div>
        <div>
          2) {t("organization_detail.accounting_software.set_your")}{" "}
          <Link
            to={`/organizations/${id}/${chartOfAccountUrl}/${chartOfAccountCategories[2]}`}
            rel="noopener noreferrer"
            target="_blank">
            {t("organization_detail.accounting_software.setup_whalebooks.sub_accounts")}
          </Link>
          .
        </div>
        <div>3) {t("organization_detail.accounting_software.export_your_chart_of_account")}.</div>
        <div>4) {t("organization_detail.accounting_software.export_your_pre_accounting")}.</div>
        <div>
          5) {t("organization_detail.accounting_software.export_your")}{" "}
          <Link to="/reports" rel="noopener noreferrer" target="_blank">
            {t("organization_detail.accounting_software.setup_whalebooks.accounting_report")}
          </Link>
          .
        </div>
      </div>

      <Heading level={3} className="mt-5">
        {t("organization_detail.accounting_software.setup_accounting.header")}
      </Heading>
      <Delimiter className="mt-3" />
      <div className={styles.organization_info}>
        <div>1) {t("organization_detail.accounting_software.setup_accounting.row1")}</div>
        <div>2) {t("organization_detail.accounting_software.setup_accounting.row2")}</div>
        <div>3) {t("organization_detail.accounting_software.setup_accounting.row3")}</div>
        <div>4) {t("organization_detail.accounting_software.setup_accounting.row4")}</div>
      </div>
    </>
  );
};

export default AccountingSoftwareHelp;
