import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { walkthroughActions } from "actions/walkthroughActions";
import { userActions } from "actions/userActions";
import { modalActions } from "actions/modalActions";
import { organizationActions } from "actions/organizationActions";
import { useTranslation } from "react-i18next";
import WalkthroughForm from "containers/Forms/WalkthroughForm";
import { getGlobalFetchingState, getRawOrganizationDetails } from "selectors";
import ModalEnvelope from "./ModalEnvelope";

const WalkthroughModal = ({
  user: { organizationId, email, ...user },
  organizationDetails,
  globalFetching,
  walkthroughActions: { setWalkthrough },
  modalActions: { hideModal },
  userActions: { editUserPreferences, editProfile },
  organizationActions: { getOrganizationInfo, editOrganizationInfo },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getOrganizationInfo(organizationId);
  }, [organizationId, getOrganizationInfo]);

  const { organizationData = {}, isOrganizationDataFetching } = organizationDetails[organizationId] || {};

  const handleSubmit = async (values, { setSubmitting }) => {
    const organizationValues = {
      ...organizationData,
      taxResidency: values.taxResidency.value,
      taxSubject: values.taxSubject.value,
      plan: organizationData.plan,
    };
    await editOrganizationInfo(organizationId, organizationValues);

    const profileValues = {
      userCategories: user.userCategories,
      otherUserCategory: user.otherUserCategory,
      newUser: false,
    };
    await editProfile(profileValues);

    await setWalkthrough();
    setSubmitting(false);
    hideModal();
  };

  return (
    <ModalEnvelope id="walkthrough-modal" heading={t("walkthrough.modal_heading")} closeIcon={false} disableClose>
      <div className="mt-3 mb-4">{t("walkthrough.title")}</div>
      <WalkthroughForm
        email={email}
        organizationData={organizationData}
        onSubmit={handleSubmit}
        editUserPreferences={editUserPreferences}
        isLoading={isOrganizationDataFetching && !globalFetching}
      />
      <div className="mt-4 text-center">{t("walkthrough.footer")}</div>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    organizationDetails: getRawOrganizationDetails(state),
    globalFetching: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    walkthroughActions: bindActionCreators(walkthroughActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalkthroughModal);
