import React, { useState } from "react";
import { Formik } from "formik";
import { CheckBox } from "grommet";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { CheckBoxFormField, PasswordFormField, TextFormField } from "components/Common/Inputs";
import { validateApiCredentials } from "common/validators";
import { Form, GhostButton, PrimaryButton } from "components/Common";
import { BOOLEAN, FIAT_CURRENCY, SECRET, STRING } from "common/constants/dynamicFieldTypes";
import { ReactComponent as ClipboardIcon } from "img/clipboard-solid.svg";
import { copyToClipboard } from "utils";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../Styles/Forms.module.scss";

const ApiCredentialsForm = ({ connection, parameters, onSubmit, fiatOptions }) => {
  const { t } = useTranslation();
  const [secretsEditEnabled, setSecretsEditEnabled] = useState(false);
  const hasSecrets = parameters.some(o => o.type === SECRET);

  return (
    <Formik
      initialValues={{
        parameters: parameters.reduce((res, val) => {
          if (val.type === FIAT_CURRENCY) {
            res[val.id] = fiatOptions.find(o => o.value === connection.parameters[val.id]) || "";
          } else if (val.type === BOOLEAN) {
            res[val.id] = connection.parameters[val.id] === "true";
          } else {
            res[val.id] = connection.parameters[val.id] || "";
          }
          return res;
        }, {}),
      }}
      validate={values => validateApiCredentials(values, parameters, secretsEditEnabled)}
      onSubmit={async (values, { setSubmitting }) => {
        const neededValues = parameters
          .filter(o => secretsEditEnabled || o.type !== SECRET)
          .reduce((res, val) => {
            if (val.type === FIAT_CURRENCY) {
              res[val.id] = values.parameters[val.id].value;
            } else {
              res[val.id] = values.parameters[val.id];
            }
            return res;
          }, {}); // Filters SECRETS out if their edit is not enabled

        await onSubmit({ parameters: neededValues });
        setSubmitting(false);
      }}>
      {({ isSubmitting, handleSubmit, values }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          {parameters
            .filter(o => o.type !== SECRET)
            .map((o, index) => {
              const props = {
                key: o.id,
                name: `parameters.${o.id}`,
                label: t(o.label),
                disabled: isSubmitting,
              };
              if (o.type === STRING) {
                return (
                  <TextFormField key={index} {...props}>
                    <GhostButton
                      className={cx("mx-0 mt-0", styles.input_button)}
                      icon={<ClipboardIcon />}
                      onClick={() => copyToClipboard(values.parameters[o.id])}
                    />
                  </TextFormField>
                );
              }
              if (o.type === BOOLEAN)
                return <CheckBoxFormField key={index} {...props} wrapperClassName={styles.api_container_form_checkbox} />;
              if (o.type === FIAT_CURRENCY) {
                return <CurrencySelectFormField key={index} {...props} options={fiatOptions} disableOptimized />;
              }
              return null;
            })}
          {hasSecrets && (
            <>
              <div className="mb-3">
                <CheckBox
                  checked={secretsEditEnabled}
                  label={t("form.api_credentials.new_secrets")}
                  onChange={e => setSecretsEditEnabled(e.target.checked)}
                />
              </div>
              {secretsEditEnabled &&
                parameters
                  .filter(o => o.type === SECRET)
                  .map(o => (
                    <PasswordFormField
                      key={o.id}
                      name={`parameters.${o.id}`}
                      label={t(o.label)}
                      disabled={isSubmitting || !secretsEditEnabled}
                    />
                  ))}
            </>
          )}
          <PrimaryButton label={t("form.api_credentials.submit")} isLoading={isSubmitting} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};

export default ApiCredentialsForm;
