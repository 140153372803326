import React from "react";
import { Formik } from "formik";
import { TextFormField } from "components/Common/Inputs";
import { Form, LoaderOverlay, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { passwordRegex, referenceRegex } from "utils";

const ReferenceDetailForm = ({ referenceDetail, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...referenceDetail,
        name: referenceDetail?.name ?? "",
        description: referenceDetail?.description ?? "",
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        name: string().required(),
        description: string().required(),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <LoaderOverlay isLoading={isSubmitting}>
          <Form onSubmit={handleSubmit}>
            <TextFormField
              name="name"
              label={t("form.reference_detail.name")}
              disabled={isSubmitting}
              required
              autoFocus
              maxLength={10}
              regex={referenceRegex}
            />
            <TextFormField name="description" label={t("form.reference_detail.description")} disabled={isSubmitting} required />

            <PrimaryButton noIcon label={t("form.account_detail.save_changes")} isLoading={isSubmitting} onClick={handleSubmit} />
          </Form>
        </LoaderOverlay>
      )}
    </Formik>
  );
};

export default ReferenceDetailForm;
