import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { LoaderOverlay, TableFooter } from "components/Common";
import ContainerTransactionsTable from "components/DataManager/ContainerTransactionsTable";
import { getFilterOptions, getSelectedContainers, getTransactionFilter, getTransactions } from "selectors";
import { initialTransactionTableFilterValues } from "components/DataManager/TransactionsTableFilter";
import { useQuery } from "@tanstack/react-query";
import { getLabels } from "queries/transactions";
import ContainerTransactionsHeading from "./ContainerTransactionsHeading";

const ContainerTransactions = ({
  dataManager: { isTransactionsFetching, totalTransactions, isEditMode },
  transactionActions: { changeFilterAndFetch, setTransactionsFilter },
  modalActions: { openAddSubtransaction, openEditTransaction, openAddTransactionsToBucket, openDeleteTransactionsFromBucket },
  transactions,
  containerSelected,
  filters: { page, count },
  filterOptions,
  user,
}) => {
  const [transactionSelection, setTransactionSelection] = useState({});

  const labelsQuery = useQuery(getLabels(user));

  useEffect(() => {
    // Reset selection when transactions change
    setTransactionSelection({});
  }, [transactions]);

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  // Reset filter on DataManager leave
  useEffect(
    () => () => {
      setTransactionsFilter(initialTransactionTableFilterValues);
    },
    [setTransactionsFilter]
  );

  return (
    <>
      <ContainerTransactionsHeading
        transactions={transactions}
        transactionSelection={transactionSelection}
        setTransactionSelection={setTransactionSelection}
        labels={labelsQuery.data}
        filter={{ isFilterVisible, setIsFilterVisible }}
      />
      <LoaderOverlay isLoading={isTransactionsFetching || labelsQuery.isLoading}>
        <ContainerTransactionsTable
          transactions={transactions}
          addTransaction={openAddSubtransaction}
          editTransaction={openEditTransaction}
          transactionSelection={transactionSelection}
          setTransactionSelection={setTransactionSelection}
          containerSelected={containerSelected}
          editMode={isEditMode}
          filter={{ isFilterVisible, setIsFilterVisible }}
          filterOptions={filterOptions}
          setTransactionsFilter={setTransactionsFilter}
          openAddTransactionsToBucket={openAddTransactionsToBucket}
          openDeleteTransactionsFromBucket={openDeleteTransactionsFromBucket}
          changeFilterAndFetch={changeFilterAndFetch}
        />
        <TableFooter
          page={page}
          count={count}
          totalRecords={totalTransactions}
          onChange={changeFilterAndFetch}
          isVisible={transactions.length > 0}
        />
      </LoaderOverlay>
    </>
  );
};

function mapStateToProps(state) {
  return {
    transactions: getTransactions(state),
    filters: getTransactionFilter(state),
    containerSelected: getSelectedContainers(state).length > 0,
    dataManager: state.dataManager,
    user: state.user,
    filterOptions: getFilterOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTransactions);
