import React from "react";
import { CustomFormField, DateInput, SelectFormField, TimeInput } from "components/Common/Inputs";
import { useTranslation } from "react-i18next";
import { formatTime, utc } from "common/formatters";
import { isTxSingleCurrency } from "common/constants/transactionType";
import { getCurrencyOptionsWithLastUsed } from "selectors";
import { connect } from "react-redux";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../Styles/Forms.module.scss";

const BulkFilterFormContent = ({
  currencyOptionsWithLastUsed,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  values,
  transactionTypeOptions,
  isTypeOptional,
  isRequired,
}) => {
  const { t } = useTranslation();

  const isCurrency = isTxSingleCurrency(values.type.value);

  return (
    <>
      <div className={styles.fields_row}>
        <SelectFormField
          name="type"
          options={transactionTypeOptions}
          label={t("form.set_bulk.tx_type")}
          required={!isTypeOptional}
          disabled={isSubmitting}
          isSearchable={false}
          isClearable
        />
        {isCurrency ? (
          <SelectFormField
            name="baseCurrency"
            options={currencyOptionsWithLastUsed}
            label={t("form.set_bulk.currency")}
            disabled={isSubmitting}
            isClearable
          />
        ) : (
          <div className={styles.fields_row}>
            <div className="d-flex align-items-center">
              <CurrencySelectFormField
                name="baseCurrency"
                options={currencyOptionsWithLastUsed}
                label={t("base")}
                disabled={isSubmitting}
                required={isRequired}
              />
              <div
                className="mb-3 ml-1 cursor-pointer p-1"
                style={{ fontSize: "20px" }}
                onClick={() => {
                  const { baseCurrency, quoteCurrency } = values;

                  if (baseCurrency || quoteCurrency) {
                    setFieldValue("baseCurrency", quoteCurrency);
                    setFieldValue("quoteCurrency", baseCurrency);
                  }
                }}>
                /
              </div>
            </div>
            <CurrencySelectFormField
              name="quoteCurrency"
              options={currencyOptionsWithLastUsed}
              label={t("quote")}
              disabled={isSubmitting}
              required={isRequired}
            />
          </div>
        )}
      </div>
      <CustomFormField
        name="timestampFrom"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.set_bulk.date_from")}
        required={false}
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.dateFrom}
            disabled={isSubmitting}
            isPlain
            isCustomField
            onBlur={() => setFieldTouched("timestampFrom", true)}
            onChange={value => setFieldValue("dateFrom", value)}
            maxDate={values.dateTo}
            isClearable
            onClear={() => setFieldValue("timestampFrom", utc().startOf("day"))}
          />
          <TimeInput
            className={styles.time}
            value={formatTime(values.timestampFrom, true)}
            isPlain
            isCustomField
            disabled={isSubmitting}
            enableSeconds
            onChange={(hours, minutes, seconds) =>
              setFieldValue(
                "timestampFrom",
                utc(values.timestampFrom).set({
                  hours,
                  minutes,
                  seconds,
                })
              )
            }
          />
        </div>
      </CustomFormField>
      <CustomFormField
        name="timestampTo"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.set_bulk.date_to")}
        required={false}
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.dateTo}
            disabled={isSubmitting}
            isPlain
            isCustomField
            onBlur={() => setFieldTouched("timestampTo", true)}
            onChange={value => {
              setFieldValue("dateTo", value);
            }}
            minDate={values.dateFrom}
            isClearable
            onClear={() => setFieldValue("timestampTo", utc().endOf("day"))}
          />
          <TimeInput
            className={styles.time}
            value={formatTime(values.timestampTo, true)}
            isPlain
            isCustomField
            disabled={isSubmitting}
            enableSeconds
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestampTo",
                utc(values.timestampTo).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currencyOptionsWithLastUsed: getCurrencyOptionsWithLastUsed(state),
  };
}

export default connect(mapStateToProps)(BulkFilterFormContent);
