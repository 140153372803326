import React from "react";
import { Formik } from "formik";
import { SelectFormField, TextAreaFormField, CustomFormField, RadioButtonsFormField } from "components/Common/Inputs";
import { GhostButton, PrimaryButton, CloseButton, Form, Text } from "components/Common";
import Dropzone from "react-dropzone";
import { RangeInput } from "grommet";
import { validateMessage } from "common/validators";
import { alertActions } from "actions/alertActions";
import { Add as AddIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import {
  feedbackTypes,
  feedbackTypeTranslator,
  featureTypes,
  featureTypeTranslator,
  FEEDBACK,
  FEATURE_REQUEST,
  OTHER,
} from "common/constants/feedbackTypes";

import "components/Common/Inputs/RangeInput.module.scss";
import styles from "../Styles/Forms.module.scss";

const acceptedExtensions = [
  ".bmp",
  ".gif",
  ".jpeg",
  ".png",
  "tiff",
  ".webp",
  ".pdf",
  ".csv",
  ".xls",
  ".log",
  ".zip",
  ".gzip",
  ".rar",
];

const FeedbackForm = ({ handleSubmit, initialValues, isBusinessAccountRequest }) => {
  const { t } = useTranslation();

  const rangeTranslator = value => {
    let experienceLabel = "";
    if (value <= 200) experienceLabel = t("form.feedback.experience.terrible");
    if (value > 200) experienceLabel = t("form.feedback.experience.bad");
    if (value > 400) experienceLabel = t("form.feedback.experience.normal");
    if (value > 600) experienceLabel = t("form.feedback.experience.good");
    if (value > 800) experienceLabel = t("form.feedback.experience.great");
    return experienceLabel;
  };

  const changeFormValues = values => ({
    ...values,
    overallExperience:
      values.type.value === FEEDBACK ? `${values.overallExperience}/1000 - ${rangeTranslator(values.overallExperience)}` : null,
    type: values.type.value,
    subtype: values.type.value === FEATURE_REQUEST ? values.subtype : null,
  });

  const setBusinessRequestValues = values => ({
    ...values,
    type: "BUSINESS_ACCOUNT_REQUEST",
    subtype: null,
    overallExperience: null,
  });

  const messageTypeOptions = feedbackTypes.map(o => ({ value: o, label: t(`${feedbackTypeTranslator(o)}`) }));

  const featureTypeOptions = featureTypes.map(o => ({ value: o, label: t(`${featureTypeTranslator(o)}`) }));

  return (
    <Formik
      initialValues={{
        message: "",
        subtype: OTHER,
        overallExperience: 500,
        file: null,
        ...initialValues,
        type: initialValues ? messageTypeOptions.find(o => o.value === initialValues.type) || "" : "",
      }}
      validate={values => (isBusinessAccountRequest ? {} : validateMessage(values))}
      onSubmit={(values, formProps) =>
        isBusinessAccountRequest
          ? handleSubmit(setBusinessRequestValues(values), formProps)
          : handleSubmit(changeFormValues(values), formProps)
      }>
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <Dropzone
          noKeyboard
          noClick
          maxSize={5 * 1024 * 1024} // 5 MB
          onDropAccepted={files => {
            if (acceptedExtensions.some(extension => files[0].name.endsWith(extension))) {
              setFieldValue("file", files[0]);
            } else {
              alertActions.error(t("alert.error.not_supported_file_format"));
            }
          }}
          onDropRejected={files => {
            if (files.length > 1) {
              alertActions.error(t("alert.error.only_one_file"));
            } else {
              alertActions.error(t("alert.error.max_file_size_5MB"));
            }
          }}
          multiple={false}>
          {({ isDragActive, getRootProps, getInputProps, open }) => (
            <>
              <div {...getRootProps()}>
                {/* disabled set to false bacause of https://github.com/react-dropzone/react-dropzone/issues/853 */}
                <input {...getInputProps({ disabled: false })} />
                <Form onSubmit={handleSubmit} className={styles.feedback_form}>
                  {!isBusinessAccountRequest && (
                    <SelectFormField
                      name="type"
                      options={messageTypeOptions}
                      label={t("form.feedback.message_type_label")}
                      required
                      disabled={isSubmitting}
                    />
                  )}
                  <div
                    className={
                      values.type && values.type.value === FEEDBACK ? styles.hideable_field : styles.hideable_field_hidden
                    }>
                    <CustomFormField
                      name="overallExperience"
                      label={`${t("form.feedback.experience_label.label")}: ${rangeTranslator(values.overallExperience, t)}`}
                      disabled={isSubmitting}>
                      <RangeInput onChange={event => setFieldValue("overallExperience", event.target.value)} min={1} max={1000} />
                      <div className={styles.range_input_labels}>
                        <label>{t("form.feedback.experience_label.bad")}</label>
                        <label>{t("form.feedback.experience_label.good")}</label>
                      </div>
                    </CustomFormField>
                  </div>
                  <div
                    className={
                      values.type && values.type.value === FEATURE_REQUEST ? styles.hideable_field : styles.hideable_field_hidden
                    }>
                    <RadioButtonsFormField
                      name="subtype"
                      label={t("form.feedback.feature")}
                      disabled={isSubmitting}
                      className={styles.radio_buttons_group}
                      options={featureTypeOptions}
                    />
                  </div>
                  <TextAreaFormField
                    name="message"
                    label={t("form.feedback.message")}
                    required={!isBusinessAccountRequest}
                    disabled={isSubmitting}
                    autoFocus={isBusinessAccountRequest}
                    placeholder={t("form.feedback.message_placeholder")}
                    resize={false}
                  />
                  <div className={styles.feedback_file}>
                    {values.file ? (
                      <div className={styles.attached_file}>
                        <Text size="small">File: </Text>
                        <Text size="small" weight="bold" truncate className={styles.container_name}>
                          {values.file.name}
                        </Text>
                        <CloseButton onClick={() => setFieldValue("file", null)} disabled={isSubmitting} />
                      </div>
                    ) : (
                      <GhostButton
                        disabled={isSubmitting}
                        label={t("form.feedback.attach_file")}
                        onClick={() => open()}
                        icon={<AddIcon />}
                      />
                    )}
                  </div>
                  <PrimaryButton
                    label={isBusinessAccountRequest ? t("form.feedback.business_plan.send") : t("form.feedback.regular.send")}
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Form>
              </div>
              <div className={isDragActive ? styles.drop_wrapper_active : styles.drop_wrapper}>
                <div />
              </div>
            </>
          )}
        </Dropzone>
      )}
    </Formik>
  );
};

export default FeedbackForm;
