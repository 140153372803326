import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { alertActions } from "actions/alertActions";
import { transactionService } from "services/transactionService";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import { utc } from "common/formatters";
import { WIDE } from "common/constants/modalWidths";
import { useQuery } from "@tanstack/react-query";
import { getLabels } from "queries/transactions";
import { LoaderOverlay } from "components/Common";
import ModalEnvelope from "../ModalEnvelope";
import SetPriceForm from "../../Forms/BulkTransaction/SetPriceForm";

const SetPriceModal = ({ transactionActions, modalActions, containers, user }) => {
  const labelsQuery = useQuery(getLabels(user));

  const onSubmit = async values => {
    try {
      const fromMoment = utc(values.dateFrom);
      const toMoment = utc(values.dateTo);

      const isPairSet = !!(values.baseCurrency && values.quoteCurrency);

      const res = await transactionService.setBulkPrice(
        {
          transactionContainerIds: containers.map(o => o.id),
          totalPrice: values.price,
          type: values.type.value,
          baseCurrency: isPairSet ? values.baseCurrency.value : null,
          quoteCurrency: isPairSet ? values.quoteCurrency.value : null,
          firstTransaction: values.dateFrom
            ? values.timestampFrom
                .set({
                  year: fromMoment.year(),
                  month: fromMoment.month(),
                  date: fromMoment.date(),
                })
                .valueOf()
            : null,
          lastTransaction: values.dateTo
            ? values.timestampTo
                .set({
                  year: toMoment.year(),
                  month: toMoment.month(),
                  date: toMoment.date(),
                })
                .valueOf()
            : null,
          transactionLabels: values.labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
        },
        user
      );

      alertActions.success(t("alert.success.bulk_price_set", { count: res.updatedTransactionsCount }));
    } catch (err) {
      alertActions.error(err);
    }
    transactionActions.getTransactions();
    modalActions.hideModal();
  };
  const { t } = useTranslation();

  return (
    <ModalEnvelope id="set-bulk-price-modal" heading={t("modal.set_bulk_price.heading")} width={WIDE}>
      <LoaderOverlay isLoading={labelsQuery.isLoading}>
        <SetPriceForm onSubmit={onSubmit} containers={containers} labelOptions={labelsQuery.data} />
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPriceModal);
