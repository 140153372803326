import React from "react";
import { useTranslation } from "react-i18next";
import { taxResidencyTranslator } from "common/constants/taxResidencies";
import { taxSubjectTranslator } from "common/constants/taxSubjects";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import { LoaderOverlay } from "../Common";

import styles from "./Organization.module.scss";

const TaxRulesInfo = ({ organizationData, isFetching }) => {
  const { t } = useTranslation();

  return (
    <LoaderOverlay isLoading={isFetching}>
      <div className={styles.organization_info}>
        <div className={styles.organization_tax_rules_grid}>
          <div className="d-flex align-items-center">
            <div>{t("organization.tax_residency")}</div>
            <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.tax_residency")}</DynamicTooltip>
          </div>
          <div>{t(taxResidencyTranslator(organizationData.taxResidency))}</div>
          <div className="d-flex align-items-center">
            <div>{t("organization.tax_subject")}</div>
            <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.tax_subject")}</DynamicTooltip>
          </div>
          <div>{t(taxSubjectTranslator(organizationData.taxSubject))}</div>
        </div>
      </div>
    </LoaderOverlay>
  );
};

export default TaxRulesInfo;
