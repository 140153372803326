import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { walkthroughActions } from "actions/walkthroughActions";
import { modalActions } from "actions/modalActions";
import Tour from "reactour";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import WalkThroughWizard from "./WalkThroughWizard";
import styles from "../Styles/Walkthrough.module.scss";
import history from "common/history";
import { getActiveOrganizationId } from "selectors";
import { useScreenSize } from "hooks";
import ImageReports from "img/walkthrough/initial/reports.webp";
import ImageReportsMobile from "img/walkthrough/reports.webp";

const ReportsWalkthrough = ({
  walkthrough: { reportsIsOpen, reportsStep },
  walkthroughActions: { updateStep, endWalkthrough, startOrganizationWalkthrough },
  enableBodyScroll,
  disableBodyScroll,
  activeOrganizationId,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const steps = [
    {
      selector: isSmall ? '[data-tour="burger-button"]' : '[data-tour="reports-link"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.data_manager.reports_link.heading")}</Heading>
          <div>{t("walkthrough.data_manager.reports_link.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-select-portfolio"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.select_portfolio.heading")}</Heading>
          <div>{t("walkthrough.reports.select_portfolio.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-period"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.period.heading")}</Heading>
          <div>{t("walkthrough.reports.period.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-portfolio-overview"]',
      position: "left",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.portfolio_overview.heading")}</Heading>
          <div>{t("walkthrough.reports.portfolio_overview.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-tax-rules"]',
      position: "left",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.tax_rules.heading")}</Heading>
          <div>{t("walkthrough.reports.tax_rules.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-tax-statement"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.tax_statement.heading")}</Heading>
          <div>{t("walkthrough.reports.tax_statement.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-accounts-statement"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.accounts_statement.heading")}</Heading>
          <div>{t("walkthrough.reports.accounts_statement.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="reports-accounting-report"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.accounting_report.heading")}</Heading>
          <div>{t("walkthrough.reports.accounting_report.content")}</div>
        </div>
      ),
    },
  ];

  return (
    <Tour
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
      steps={steps}
      onRequestClose={endWalkthrough}
      closeWithMask={false}
      disableInteraction
      rounded={8}
      startAt={0}
      isOpen={reportsIsOpen}
      goToStep={reportsStep}
      getCurrentStep={step => updateStep(step)}
      maskClassName="tour-mask"
      CustomHelper={props => (
        <WalkThroughWizard
          {...props}
          onLastStepClick={() => {
            history.push(`/organizations/${activeOrganizationId}/tax-rules`);
            startOrganizationWalkthrough();
          }}
          InitialStepImage={ImageReports}
          InitialStepImageMobile={ImageReportsMobile}
        />
      )}
    />
  );
};

const mapStateToProps = state => ({
  walkthrough: state.walkthrough,
  activeOrganizationId: getActiveOrganizationId(state),
});

const mapDispatchToProps = dispatch => ({
  walkthroughActions: bindActionCreators(walkthroughActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsWalkthrough);
