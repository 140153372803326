import React from "react";
import { Motion, spring, presets } from "react-motion";
import styles from "../../Styles/AppStructure.module.scss";
import cx from "classnames";

const BurgerButton = ({ toggle, expanded, isLanding }) => (
  <svg
    viewBox="0 0 96 96"
    onClick={toggle}
    className={cx(isLanding ? styles.burger_landing : styles.burger, { "mr-1": !isLanding })}
    data-tour="burger-button">
    <Motion
      style={{
        x: spring(expanded ? 1 : 0, presets.noWobble),
        y: spring(expanded ? 0 : 1, presets.noWobble),
      }}>
      {({ x, y }) => (
        <g>
          <line transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`} x1="7" y1="26" x2="89" y2="26" />
          <line transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`} x1="7" y1="70" x2="89" y2="70" />
          <line transform={`translate(${x * -96})`} opacity={y} x1="7" y1="48" x2="89" y2="48" />
        </g>
      )}
    </Motion>
  </svg>
);

export default BurgerButton;
