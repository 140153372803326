import React from "react";
import StakingRewardsContainer from "containers/Dashboard/StakingRewardsContainer";
import PositionsContainer from "containers/Dashboard/PositionsContainer";
import PortfolioSummaryContainer from "./PortfolioSummaryContainer";
import MainGraphContainer from "../Graphs/MainGraphContainer";
import DistributionChart from "../Graphs/DistributionChartContainer";
import FiatsInventoryContainer from "./FiatsInventoryContainer";
import AssetAccountsContainer from "./AssetAccountsContainer";

const DashboardContainer = ({ dateRange, setDateRange, currentDateRuleSet }) => (
  <>
    <PortfolioSummaryContainer />
    <DistributionChart />
    <MainGraphContainer dateRange={dateRange} setDateRange={setDateRange} />
    <AssetAccountsContainer />
    <PositionsContainer currentDateRuleSet={currentDateRuleSet} />
    <StakingRewardsContainer currentDateRuleSet={currentDateRuleSet} />
    <FiatsInventoryContainer />
  </>
);

export default DashboardContainer;
