import React, { useMemo, useState } from "react";
import { GhostButton, Heading, Menu, PrimaryButton, Table } from "components/Common";
import { useTranslation } from "react-i18next";
import { CheckBox } from "grommet";
import { Add as AddIcon, Trash as TrashIcon } from "grommet-icons/icons";
import cx from "classnames";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { confirmationActions } from "actions/confirmationActions";
import StateSet from "utils/stateSet";
import styles from "pages/Pages.module.scss";
import { useScreenSize } from "hooks";
import { ReactComponent as ClipboardIcon } from "img/clipboard-solid.svg";
import { copyToClipboard } from "utils";
import { ActionMenu } from "components/Common/ActionMenu";

const Addresses = ({
  accountDetail,
  modalActions: { openAddAccountAddress },
  confirmationActions: { openDeleteAccountAddressConfirmation },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const { addresses } = accountDetail ?? { addresses: [] };

  const [selectedAddresses, setSelectedAddresses] = useState(new StateSet());
  const toggleCheck = id => setSelectedAddresses(prevState => prevState.toggle(id));
  const selectAll = () =>
    setSelectedAddresses(prevState =>
      selectedAddresses.values.size === addresses.length ? new StateSet() : prevState.addMany(addresses.map(x => x.id))
    );
  const isAllSelected = useMemo(() => {
    const txIds = addresses.map(o => o.id);
    return addresses.length !== 0 && txIds.find(o => selectedAddresses.has(o) !== true) === undefined;
  }, [selectedAddresses, addresses]);

  const columns = [
    {
      Header: () => <CheckBox disabled={addresses.length === 0} checked={isAllSelected} onChange={selectAll} />,
      Cell: ({ value }) => <CheckBox checked={selectedAddresses.has(value)} onChange={() => toggleCheck(value)} />,
      accessor: "id",
      className: styles.check_row,
      width: 30,
    },
    {
      Header: t("account_detail.table_header.address"),
      accessor: "address",
    },
    {
      Header: () => <div style={{ textAlign: "right" }}>{t("account_detail.table_header.action")}</div>,
      Cell: ({ row }) => {
        const { original, index } = row;
        const rowIndex = index + 1;

        const { id, address } = original;
        return (
          <div className={styles.action_cell}>
            <GhostButton icon={<ClipboardIcon />} onClick={() => copyToClipboard(address)} actionButton />

            <ActionMenu testId="address-menu">
              <ActionMenu.ItemGroup key={1}>
                <ActionMenu.Item
                  icon={<ClipboardIcon />}
                  label={t("button.copy")}
                  onClick={() => copyToClipboard(address)}
                  testId={`action-address-copy_row-${rowIndex}`}
                />
              </ActionMenu.ItemGroup>
              <ActionMenu.ItemGroup key={2} hasSeparator>
                <ActionMenu.Item
                  icon={<TrashIcon />}
                  label={t("button.delete")}
                  critical
                  onClick={() =>
                    openDeleteAccountAddressConfirmation({
                      ...accountDetail,
                      addresses: accountDetail.addresses.filter(x => x.id !== id),
                    })
                  }
                  testId={`action-address-delete_row-${rowIndex}`}
                />
              </ActionMenu.ItemGroup>
            </ActionMenu>
          </div>
        );
      },
      id: "action",
      className: styles.narrow_row,
    },
  ];

  const BulkActions = () => (
    <Menu className="mt-2" label={t("account_detail.bulk_actions_menu")} disabled={selectedAddresses.values.size === 0}>
      <GhostButton
        icon={<ClipboardIcon className={styles.bulk_icon} />}
        label={t("account_detail.bulk_copy")}
        onClick={async () => {
          await copyToClipboard(addresses?.map(x => x.address)?.join("\n"));
          setSelectedAddresses(new StateSet());
        }}
      />
      <GhostButton
        icon={<TrashIcon />}
        label={t("account_detail.bulk_delete")}
        onClick={() => {
          openDeleteAccountAddressConfirmation({
            ...accountDetail,
            addresses: accountDetail.addresses.filter(x => !selectedAddresses.has(x.id)),
          });
          setSelectedAddresses(new StateSet());
        }}
      />
    </Menu>
  );

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
        <div className="d-flex flex-column flex-md-row">
          <Heading level={3} className="mr-4 mb-0">
            {t("account_detail.addresses.header")}
          </Heading>
          {!isSmall && <BulkActions />}
        </div>

        <PrimaryButton
          onClick={openAddAccountAddress}
          collapsed
          icon={<AddIcon color="white" />}
          label={t("account_detail.addresses.add_address_button")}
          className="mt-3 mb-2 my-md-0"
        />
      </div>
      {isSmall && <BulkActions />}

      <Table data={addresses} columns={columns} getRowId={row => row.id} className="mb-4" fixedLayout />

      {addresses?.length === 0 && (
        <div className={styles.empty_table_row}>
          <Heading level={4} margin="1rem 0 0" color="gray3">
            {t("accounts.accounts_table.no_records_message")}
          </Heading>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    accountDetail: state.accounts.accountDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
