export const DATA_CHANGED = "DATA_CHANGED";

const t_fake = str => str;

const apiConnectionInputsTranslations = {
  "Trade currency pairs (e.g. BTC/USDT,LTC/ETH)": t_fake("api_connection.currency_pairs"),
  "User Name": t_fake("api_connection.user_name"),
  "Wallet address": t_fake("api_connection.wallet_address"),
  "Wallet address / xpub key": t_fake("api_connection.wallet_address_xpub"),
  "Fiat currency": t_fake("api_connection.fiat_currency"),
  "Import deposits as BUY transactions": t_fake("api_connection.deposits_as_buy_tx"),
  "Import withdrawals as SELL transactions": t_fake("api_connection.withdrawals_as_sell_tx"),
  "Import deposit mining fee as BUY FEE": t_fake("api_connection.deposit_mining_fee_as_buy"),
  "Import withdrawal mining fee as SELL FEE": t_fake("api_connection.withdrawal_mining_fee_as_sell"),
  "Wallet address / ltub key": t_fake("api_connection.wallet_address_ltub"),
  "Trade currency pairs (e.g. LTC/EUR,LTC/BTC)": t_fake("api_connection.currency_pairs"),
  passphrase: t_fake("api_connection.passphrase"),
  "Trade currency pairs (e.g. LTC/USDT,LTC/BTC)": t_fake("api_connection.currency_pairs"),
  Passphrase: t_fake("api_connection.passphrase"),
  "Trade currency pairs (e.g. LTC/USDT,XRP/USD)": t_fake("api_connection.currency_pairs"),
  "Only order-limit is supported.": t_fake("api_connection.connector_note.huobi"),
  "api_connection.general_currency_pairs": t_fake("api_connection.general_currency_pairs"),
};

export const apiConnectionInputsTranslator = val => apiConnectionInputsTranslations[val] ?? val;
