import React, { useState } from "react";
import { StatusWarning as WarningIcon } from "grommet-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import ContainerIcon from "components/DataManager/ContainerIcon";
import JobIndicator from "components/DataManager/JobIndicator";
import AssignmentInfo from "components/DataManager/AssignmentInfo";
import { CONTAINER_API } from "common/constants/containerTypes";
import { colors } from "common/colors";
import SyncStatus from "components/DataManager/SyncStatus";
import { LoaderOverlay } from "components/Common";
import { getDataTestId } from "utils";
import styles from "../../Styles/DataManager.module.scss";

const Container = ({
  container,
  editMode,
  isAssigned,
  clickAction,
  doubleClickAction,
  width,
  showAssignmentInfo,
  isInDeletion,
}) => {
  const {
    name,
    id,
    isSelected,
    ignoredFeeTransactionsCount,
    failedFeeTransactionsCount,
    ignoredTransactionsCount,
    errorTransactionsCount,
    job: { jobPercentage, isOngoing, isUploading, isDone },
    exchangeName,
    source,
    emphasized,
    type,
    connection,
  } = container;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <LoaderOverlay isLoading={isInDeletion}>
      <div
        data-testid={getDataTestId("container", `${name}-${source || exchangeName}`)}
        className={isSelected && !editMode ? styles.container_selected : styles.container}
        onClick={e => {
          e.stopPropagation();
          if (!isUploading) {
            clickAction(id, e.ctrlKey || e.metaKey || editMode, e.shiftKey && !editMode); // in edit mode it behaves like a checkbox
          }
          if (e.ctrlKey || e.metaKey || e.shiftKey) window.getSelection().removeAllRanges();
        }}
        onDoubleClick={e => {
          if (!isUploading) {
            doubleClickAction();
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div className={styles.icon_bar}>
          <AssignmentInfo
            showAssignmentInfo={showAssignmentInfo && !isOngoing}
            editMode={editMode}
            isAssigned={isAssigned}
            isSelected={isSelected}
            isHovered={isHovered}
          />
          {type === CONTAINER_API && (
            <>
              <FontAwesomeIcon
                className="ml-n1 mr-2"
                icon={faExchangeAlt}
                color={connection?.automaticSynchronization ? colors.statusOk : "gray"}
              />
              <SyncStatus statusType={connection?.status} />
            </>
          )}

          {ignoredFeeTransactionsCount > 0 ||
          ignoredTransactionsCount > 0 ||
          errorTransactionsCount > 0 ||
          failedFeeTransactionsCount > 0 ? (
            <WarningIcon className={styles.warning_icon} />
          ) : (
            <span className={styles.warning_icon} />
          )}
        </div>
        <div style={{ height: `${width}px`, width: `${width}px`, position: "relative" }}>
          <div className={isOngoing || isDone ? styles.indicator : styles.indicator_hidden}>
            <JobIndicator jobPercentage={jobPercentage} radius={width / 2} emphasized={emphasized} isUploading={isUploading} />
          </div>
          <div className={isOngoing ? styles.container_icon_faded : styles.container_icon}>
            <ContainerIcon name={name} />
          </div>
        </div>
        <div className={styles.container_label}>
          <div className={styles.container_name}>{name}</div>
          {!isUploading && (source || exchangeName) && <div style={{ lineHeight: "1rem" }}>[{source || exchangeName}]</div>}
        </div>
      </div>
    </LoaderOverlay>
  );
};

export default Container;
