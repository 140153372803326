export const LOGIN_MODAL = "LOGIN_MODAL";
export const CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL";
export const CONFIRMATION_DIALOG_MODAL = "CONFIRMATION_DIALOG_MODAL";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const OPEN_PORTFOLIO_DETAIL = "OPEN_PORTFOLIO_DETAIL";
export const CREATE_CONTAINER = "CREATE_CONTAINER";
export const CONNECTION_CREDENTIALS = "CONNECTION_CREDENTIALS";
export const CONTAINER_DETAIL_MODAL = "CONTAINER_DETAIL_MODAL";
export const EDIT_CONTAINER_DETAIL_MODAL = "EDIT_CONTAINER_DETAIL_MODAL";
export const FEEDBACK_FORM_MODAL = "FEEDBACK_FORM_MODAL";
export const SELECT_PORTFOLIO_AVCO_PREDECESSOR = "SELECT_PORTFOLIO_AVCO_PREDECESSOR";
export const SELECT_PORTFOLIO_FIFO_PREDECESSOR = "SELECT_PORTFOLIO_FIFO_PREDECESSOR";
export const COPY_ACCOUNT_BALANCES_MODAL = "COPY_ACCOUNT_BALANCES_MODAL";
export const WALKTHROUGH = "WALKTHROUGH";
export const BASE_QUOTE_MODAL = "BASE_QUOTE_MODAL";
export const CHECKOUT = "CHECKOUT";
export const EMAIL_SUBSCRIPTION = "EMAIL_SUBSCRIPTION";
export const ASSIGN_CONTAINERS = "ASSIGN_CONTAINERS";
export const SHARE_PORTFOLIO = "SHARE_PORTFOLIO";
export const ORGANIZATION_USER = "ORGANIZATION_USER";
export const APPLICATION_PREFERENCES = "APPLICATION_PREFERENCES";
export const SELECT_USER_CATEGORY = "SELECT_USER_CATEGORY";
export const ORGANIZATION_TRIAL_PERIOD = "ORGANIZATION_TRIAL_PERIOD";
export const ACCOUNTANT_DETAIL = "ACCOUNTANT_DETAIL";
export const SEARCH_FOR_ACCOUNTANT = "SEARCH_FOR_ACCOUNTANT";
export const DOWNLOAD_ADMIN_REPORT = "DOWNLOAD_ADMIN_REPORT";
export const CREATE_ACCOUNT_MODAL = "CREATE_ACCOUNT_MODAL";
export const EDIT_ASSET_ACCOUNT_MODAL = "EDIT_ASSET_ACCOUNT_MODAL";
export const ADD_ACCOUNT_ADDRESS_MODAL = "ADD_ACCOUNT_ADDRESS_MODAL";
export const ADD_TRANSACTIONS_TO_BUCKET = "ADD_TRANSACTIONS_TO_BUCKET";
export const DELETE_TRANSACTION_FROM_BUCKET = "DELETE_TRANSACTION_FROM_BUCKET";
export const BUCKET_DETAIL_MODAL = "BUCKET_DETAIL_MODAL";
export const COUNTER_TRANSACTIONS_MODAL = "COUNTER_TRANSACTIONS_MODAL";
export const SET_ADDRESS_FOR_TRANSACTIONS = "SET_ADDRESS_FOR_TRANSACTIONS";
export const DOWNLOAD_ACCOUNTS_STATEMENT = "DOWNLOAD_ACCOUNTS_STATEMENT";
export const OPEN_ORGANIZATION_DETAIL = "OPEN_ORGANIZATION_DETAIL";
export const EDIT_CHART_ACCOUNT_ACCOUNT = "EDIT_CHART_ACCOUNT_ACCOUNT";
export const EDIT_CHART_ACCOUNT_SUB_ACCOUNT = "EDIT_CHART_ACCOUNT_SUB_ACCOUNT";
export const DOWNLOAD_ACCOUNTING_REPORT = "DOWNLOAD_ACCOUNTING_REPORT";
export const SET_TAX_RESIDENCY = "SET_TAX_RESIDENCY";
export const EDIT_TAX_RULES_MODAL = "EDIT_TAX_RULES_MODAL";
export const OPEN_RULE_SET_DETAIL = "OPEN_RULE_SET_DETAIL";
export const REBRANDING_WELCOME_MODAL = "REBRANDING_WELCOME_MODAL";
export const EDIT_REWARDS_MODAL = "EDIT_REWARDS_MODAL";
export const EDIT_ACCOUNT_SETTINGS_MODAL = "EDIT_ACCOUNT_SETTINGS_MODAL";
export const REFERENCE_DETAIL_MODAL = "REFERENCE_DETAIL_MODAL";
export const WALKTHROUGH_START_MODAL = "WALKTHROUGH_START_MODAL";

// BULK TX MODALS
export const BULK_TX_SET_PRICE = "BULK_TX_SET_PRICE";
export const BULK_TX_SET_LABEL = "BULK_TX_SET_LABEL";
