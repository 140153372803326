import React from "react";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import LoginForm from "../Forms/LoginForm";

const LoginModal = () => {
  const { t } = useTranslation();
  return (
    <ModalEnvelope id="login-modal" heading={t("modal.login.heading")}>
      <LoginForm />
    </ModalEnvelope>
  );
};

export default LoginModal;
