import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tag as LabelIcon } from "grommet-icons";
import { components } from "react-select";
import { customSelectStyles, menuDefaults, controlDefaults, containerDefaults } from "common/styles/selectStyles";
import { colors } from "common/colors";
import { SelectInput } from "../Common/Inputs";
import { GhostButton } from "../Common";

import styles from "../Styles/DataManager.module.scss";

const enhancedSelectStyles = {
  ...customSelectStyles,
  menu: base => ({
    ...base,
    ...menuDefaults,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    boxShadow: "none",
    border: "none",
    margin: "8px 0 0 0",
    borderRadius: 0,
    borderTop: `1px solid ${colors.greyBorder}`,
  }),
  control: base => ({
    ...base,
    ...controlDefaults,
    padding: "8px 12px 0",
  }),
  container: base => ({
    ...base,
    ...containerDefaults,
    display: "flex",
    flexDirection: "column",
  }),
};

const LabelSelector = ({ labels, onApply, disabled, showRemoveLabels }) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const labelButton = useRef(null);
  const [maxMenuHeight, setMaxMenuHeight] = useState(350);

  useEffect(() => {
    // TODO: Create hook
    const closeMenu = () => setExpanded(false);
    if (expanded) document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, [expanded]);

  const setMaxHeightAndExpanded = expanded => {
    if (expanded) {
      const pageRect = document.getElementById("root").getBoundingClientRect();
      const { bottom } = labelButton.current.getBoundingClientRect();
      setMaxMenuHeight(pageRect.height - bottom - window.pageYOffset - 10);
    }
    setExpanded(expanded);
  };

  return (
    <div
      className={styles.label_selector}
      onClick={e => e.nativeEvent.stopImmediatePropagation()} // To prevent menu close
      ref={labelButton}>
      <GhostButton icon={<LabelIcon />} disabled={disabled} onClick={() => setMaxHeightAndExpanded(!expanded)} />
      {expanded && (
        <div style={{ maxHeight: `${maxMenuHeight}px` }}>
          <SelectInput
            isCreatable
            isPlain
            components={{
              DropdownIndicator: props => (
                <GhostButton
                  className={styles.apply_labels}
                  disabled={!value}
                  onClick={() => onApply(value)}
                  label={t("components.label_selector.apply")}
                />
              ),
              ClearIndicator: props => null,
              Option: props => {
                const { label, value, __isNew__ } = props.data;
                return (
                  <components.Option {...props}>
                    {__isNew__ ? <span>{t("components.label_selector.create", { value })}</span> : <span>{label}</span>}
                  </components.Option>
                );
              },
              Menu: props => (
                <components.Menu {...props}>
                  {props.children}
                  {showRemoveLabels && (
                    <div className={styles.flex_end}>
                      <GhostButton label={t("components.label_selector.remove_label")} onClick={() => onApply(value, true)} />
                    </div>
                  )}
                </components.Menu>
              ),
            }}
            autoFocus
            value={value}
            options={labels}
            styles={enhancedSelectStyles}
            isSearchable
            isClearable
            menuIsOpen
            className={styles.select}
            wrapperClassName={styles.select_wrapper}
            onChange={val => setValue(val || "")}
            onCreateOption={val => {
              onApply({ label: val, value: "" });
              setExpanded(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LabelSelector;
