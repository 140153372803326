import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withParentSize } from "@vx/responsive";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Next as RightIcon, Previous as LeftIcon } from "grommet-icons";
import { Col, Row } from "react-bootstrap";
import cx from "classnames";
import MainGraph from "components/Graphs/MainGraph";
import { getOpenedPortfolio, getOpenedPortfolioBalances, getOpenedPortfolioEndDate, getPortfolioHistory } from "selectors";
import { GhostButton, LoaderOverlay, Tab, Tabs } from "components/Common";
import { getGlobalFetchingState } from "selectors/globalSelectors";
import { portfolioActions } from "actions/portfolioActions";
import { formatDate } from "common/formatters";
import MainGraphTooltip from "components/Tooltips/MainGraphTooltip";
import { withScreenSize } from "hoc";
import { DateInput } from "components/Common/Inputs";
import { moveDateRange } from "utils";
import { initialRange } from "common/constants/initialDashboardRange";
import styles from "../Styles/Graphs.module.scss";
import { useDefaultsStore } from "stores/defaultsStore";

const MainGraphContainer = ({
  historyData,
  parentWidth,
  isHistoryFetching,
  portfolio,
  openedPortfolioEndDate,
  filter = initialRange,
  isBalancesFetching,
  portfolioActions: { getPortfolioBalances, getPortfolioBalancesAndHistory },
  isSmall,
  dateRange,
  setDateRange,
}) => {
  const { portfolioGraphSelection, setPortfolioGraphSelection, resetPortfolioGraph } = useDefaultsStore();

  const { t } = useTranslation();

  const { currency, marketHistory, portfolioHistory, isExample } = historyData;

  const fetchBalances = useCallback(
    fetchFilter => {
      getPortfolioBalances(portfolio, fetchFilter);
    },
    [portfolio, getPortfolioBalances]
  );
  const fetchBalancesAndGraphData = useCallback(
    fetchFilter => {
      getPortfolioBalancesAndHistory(portfolio, fetchFilter);
    },
    [portfolio, getPortfolioBalancesAndHistory]
  );

  useEffect(() => {
    fetchBalancesAndGraphData(dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const moveRange = (forward = true, numberOfDays = null) => setDateRange(moveDateRange(dateRange, forward, numberOfDays));

  const selectedDate = !filter.from && filter.to ? moment.utc(filter.to).valueOf() : null;

  // Read-only range - displayed, when user clicks on graph
  const clickSelectionRange = useMemo(
    () => selectedDate && { from: formatDate(new Date(portfolioHistory?.domains.xDomain[0])), to: formatDate(selectedDate) },
    [selectedDate, portfolioHistory]
  );

  const isRangeActive = dateRange?.from || dateRange?.to;

  useEffect(() => {
    if (!!portfolioGraphSelection?.to && !isHistoryFetching) {
      fetchBalances({ from: null, to: formatDate(portfolioGraphSelection.to) });
    }
  }, [portfolioGraphSelection, isHistoryFetching]);

  const clearRange = () => {
    if (!clickSelectionRange) {
      setDateRange(initialRange);
      resetPortfolioGraph();
    } else {
      setPortfolioGraphSelection(initialRange);
    }
    fetchBalances(initialRange);
  };

  return (
    <div className={styles.main_graph_wrapper}>
      <LoaderOverlay isLoading={isHistoryFetching}>
        <Tabs
          HeaderComponent={({ children }) => (
            <Row>
              <Col xs={12} md="auto" className="d-flex justify-content-between">
                {children}

                <MainGraphTooltip buttonClassName="d-inline-block d-md-none" />
              </Col>
              <Col xs={12} md="auto" className="ml-auto">
                <Row noGutters className="d-flex align-items-center" data-tour="main-graph-interval">
                  <Col xs={12} md="auto order-1 order-md-0 mt-2 mt-md-0">
                    {!isExample && (
                      <GhostButton
                        label={t("dashboard.main_graph.view_all_history")}
                        onClick={clearRange}
                        disabled={isBalancesFetching || !clickSelectionRange || !dateRange}
                        className="ml-n1"
                      />
                    )}
                  </Col>
                  <Col xs={12} md="auto" className="d-flex order-0 order-md-1 mt-3 mt-md-0">
                    <DateInput
                      value={clickSelectionRange ?? dateRange}
                      onChange={setDateRange}
                      wrapperClassName="mx-0 mr-3"
                      wrapperStyle={{
                        width: isSmall ? "inherit" : "300px",
                        boxShadow: isRangeActive || clickSelectionRange ? "0 1px 6px 0 #49c87b" : undefined,
                        borderColor: isRangeActive || clickSelectionRange ? "#49c87b" : undefined,
                      }}
                      isInline
                      isClearable
                      isRangeSelect
                      showRangeButtons
                      thisPortfolioRange={{
                        from: portfolio?.start,
                        to: portfolio?.end,
                      }}
                      name="graph-date-range"
                      onClear={clearRange}
                      customClear
                    />
                    <GhostButton
                      icon={<LeftIcon />}
                      className={cx(styles.range_arrow_button, styles.left)}
                      disabled={!dateRange?.from}
                      onClick={() => moveRange(false)}
                      testId="graph-date-range-left"
                    />
                    <GhostButton
                      icon={<RightIcon />}
                      className={cx(styles.range_arrow_button, styles.right)}
                      disabled={!dateRange?.to}
                      onClick={moveRange}
                      testId="graph-date-range-right"
                    />
                    <MainGraphTooltip buttonClassName="d-none d-md-inline ml-2" />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          tabWrapperClassName={styles.graph_tab_wrapper}>
          <Tab title={t("dashboard.main_graph.portfolio_history")}>
            <MainGraph
              portfolioId={portfolio?.id}
              data={portfolioHistory}
              width={parentWidth}
              isSmall={isSmall}
              isExample={isExample}
              fetchBalances={selectedTimestamp => setPortfolioGraphSelection({ from: null, to: selectedTimestamp })}
              currencySymbol={currency}
              selectedDate={selectedDate}
              isLoading={isHistoryFetching}
              openedPortfolioEndDate={openedPortfolioEndDate}
              dottedLegendLabel={t("dashboard.main_graph.portfolio_dotted_label")}
              positiveLegendLabel={t("dashboard.main_graph.portfolio_full_label")}
              negativeLegendLabel={t("dashboard.main_graph.portfolio_negative_label")}
              lockedLegendLabel={t("dashboard.main_graph.portfolio_locked_label")}
            />
          </Tab>
          <Tab title={t("dashboard.main_graph.profit_history")}>
            <MainGraph
              portfolioId={portfolio?.id}
              data={marketHistory}
              width={parentWidth}
              isSmall={isSmall}
              isExample={isExample}
              fetchBalances={selectedTimestamp => fetchBalances({ from: null, to: formatDate(selectedTimestamp) })}
              currencySymbol={currency}
              selectedDate={selectedDate}
              isLoading={isHistoryFetching}
              openedPortfolioEndDate={openedPortfolioEndDate}
              dottedLegendLabel={t("dashboard.main_graph.profit_dotted_label")}
              positiveLegendLabel={t("dashboard.main_graph.profit_full_label")}
              negativeLegendLabel={t("dashboard.main_graph.portfolio_negative_label")}
              lockedLegendLabel={t("dashboard.main_graph.portfolio_locked_label")}
            />
          </Tab>
        </Tabs>
      </LoaderOverlay>
    </div>
  );
};

const mapStateToProps = state => {
  const { isHistoryFetching, ...historyData } = getPortfolioHistory(state);
  const { isBalancesFetching, filter } = getOpenedPortfolioBalances(state);

  return {
    portfolio: getOpenedPortfolio(state),
    openedPortfolioEndDate: getOpenedPortfolioEndDate(state),
    historyData,
    filter,
    isBalancesFetching,
    isHistoryFetching: isHistoryFetching === true && !getGlobalFetchingState(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
  };
}

export default withParentSize(connect(mapStateToProps, mapDispatchToProps)(withScreenSize(MainGraphContainer)));
