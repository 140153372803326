import React, { forwardRef, useRef } from "react";
import { useField } from "formik";
import { CheckBox } from "grommet";
import { InputWrapper, LabelRow } from "components/Common/Inputs/Shared";
import cx from "classnames";
import styles from "components/Common/Inputs/Inputs.module.scss";

const CheckInput = ({ onChange, value, error, required, isInline, wrapperClassName, ...props }) => {
  const inputRef = useRef();

  return (
    <InputWrapper
      className={cx(styles.checkbox_input, wrapperClassName)}
      disabled={props.disabled}
      style={{ padding: "7px 16px" }}
      inline={isInline}
      error={error}
      plain
      onClick={() => inputRef.current?.focus()}>
      <LabelRow error={error} required={required} />
      <div className={styles.checkbox}>
        <CheckBox ref={inputRef} checked={value} onChange={e => onChange(e.target.checked)} {...props} />
      </div>
    </InputWrapper>
  );
};

export const CheckFormField = forwardRef(({ name, onChange, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return (
    <CheckInput
      ref={ref}
      error={meta.touched ? meta.error : ""}
      {...field}
      onChange={val => {
        if (onChange && typeof onChange === "function") onChange(val);
        helpers.setValue(val);
      }}
      {...props}
    />
  );
});

export default CheckFormField;
