import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "components/Styles/Toasts.module.scss";
import { StatusGood, StatusWarning, StatusCritical, StatusInfo } from "grommet-icons";

const getToastComponent = (icon, message, to, link, onLinkClick, closeOnLinkClick, onMouseEnter, onMouseLeave) => (
  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={styles.toast_body}>
    {icon}
    <div>
      <div>{message}</div>
      {link && to && (
        <Link
          to={to}
          onClick={e => {
            if (closeOnLinkClick) e.stopPropagation();
            if (onLinkClick) onLinkClick();
          }}
          className={styles.toast_link}>
          {link}
        </Link>
      )}
    </div>
  </div>
);

function success(
  message,
  { linkTo, linkMessage, autoClose = 5000, onLinkClick, closeOnLinkClick = true, onMouseEnter, onMouseLeave, onClose } = {}
) {
  if (message && typeof message !== "object") {
    toast.success(
      getToastComponent(
        <StatusGood size="large" />,
        message,
        linkTo,
        linkMessage,
        onLinkClick,
        closeOnLinkClick,
        onMouseEnter,
        onMouseLeave
      ),
      { className: styles.success_toast, autoClose, onClose }
    );
  }
}

function error(message, linkTo, linkMessage) {
  if (message && typeof message !== "object") {
    toast.error(getToastComponent(<StatusCritical size="large" />, message, linkTo, linkMessage), {
      className: styles.error_toast,
    });
  }
}

function info(message, linkTo, linkMessage) {
  if (message && typeof message !== "object") {
    toast(getToastComponent(<StatusInfo size="large" />, message, linkTo, linkMessage), { className: styles.info_toast });
  }
}

function warning(message, { linkTo, linkMessage, onLinkClick } = {}) {
  if (message && typeof message !== "object") {
    toast(getToastComponent(<StatusWarning size="large" />, message, linkTo, linkMessage, onLinkClick), {
      className: styles.warning_toast,
    });
  }
}

function clear(id) {
  if (id !== undefined) toast.dismiss(id);
  else toast.dismiss();
}

export const alertActions = {
  error,
  success,
  info,
  warning,
  clear,
};
