import { connect } from "react-redux";
import { getUser } from "selectors";
import { WB_SUPPORT } from "common/constants/whaleBooksRoles";

const AdminPermission = ({ children, userRole }) => (userRole === WB_SUPPORT ? children : null);

function mapStateToProps(state) {
  return {
    userRole: getUser(state).userRole,
  };
}

export default connect(mapStateToProps)(AdminPermission);
