import { createSelector } from "reselect";
import { getContainerJobs } from "./jobSelectors";
import { getActivePortfolioId } from "./portfoliosSelectors";

export const getRawContainers = state => state.dataManager.containers;

export const getRawContainerSelection = state => state.dataManager.containerSelection;

export const getTransactionFilter = state => state.dataManager.filters;

export const getRawTransactions = state => state.dataManager.transactions;

export const getRawPortfolioContainerAssignments = state => state.dataManager.portfolioContainerAssignments;

export const getIsPortfolioSelected = state => state.dataManager.selectedPortfolio;

export const getContainers = createSelector(
  [getRawContainers, getRawContainerSelection, getContainerJobs],
  (containers, selection, containerJobs) =>
    containers.map(o => ({
      ...o,
      job: containerJobs[o.id] ? containerJobs[o.id] : {},
      isSelected: selection[o.id] === true,
    }))
);

export const getContainerDetail = state => state.dataManager.containerDetail;

export const getExtendedContainerDetail = state => state.dataManager.extendedContainerDetail;

export const getTransactions = createSelector([getRawTransactions], transactions => transactions);

export const getSelectedContainers = createSelector([getContainers], containers => containers.filter(o => o.isSelected));

export const getSelectedContainerIds = createSelector([getRawContainerSelection], selection =>
  Object.keys(selection)
    .map(containerId => (selection[containerId] ? Number(containerId) : undefined))
    .filter(x => x)
);

export const getPortfolioContainerAssignments = createSelector(
  [getRawPortfolioContainerAssignments, getActivePortfolioId],
  (portfolioContainerAssignments, portfolioId) => {
    const assignedContainerIds = portfolioContainerAssignments[portfolioId] || [];
    return assignedContainerIds.reduce((res, val) => {
      res[val] = true;
      return res;
    }, {});
  }
);

export const getIsDataManagerLoading = state => state.dataManager.isDataManagerLoading;

export const getFilterOptions = state => state.dataManager.filterOptions;

export const getTransactionsManualFilter = state => state.dataManager.transactionsFilter;

export const getLastUsedCurrencies = state => state.dataManager.lastUsedCurrencies;
