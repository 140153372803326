import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { Form, GhostButton, Heading, PrimaryButton, Table, Tooltip } from "components/Common";
import { useTranslation } from "react-i18next";
import { object } from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ULTRAWIDE } from "common/constants/modalWidths";
import { CheckBox } from "grommet";
import { formatUtcDate, timeFormat } from "common/formatters";
import styles from "components/Styles/DataManager.module.scss";
import { mapTransaction } from "utils";
import { DEPOSIT, transactionTypeTranslator, WITHDRAWAL } from "common/constants/transactionType";
import RateAndTotalLabel from "containers/DataManager/RateAndTotalLabel";
import { Add as AddIcon, Close as CloseIcon } from "grommet-icons";
import { bucketActions } from "actions/bucketActions";
import { getRawContainers } from "selectors";

const CounterTransactionsForm = ({
  onClose,
  mainTransaction,
  setModalWidth,
  buckets,
  counterTransactions,
  containers,
  setHeading,
  bucketActions: { setCounterTransactions },
}) => {
  const { t } = useTranslation();

  const bucketOptions = useMemo(() => buckets.map(x => ({ label: x.name, value: x })), [buckets]);

  const [selectedBucket, setSelectedBucket] = useState();

  // Transactions for selection - filter out already selected tx, main tx, transactions with different base/quote than main tx
  // Converting deposit - allow only WITHDRAWAL & TRANSFER_OUT types
  // Converting withdrawal - allow only DEPOSIT & TRANSFER_IN types
  const transactionsOfSelectedBucket = useMemo(
    () =>
      (selectedBucket?.transactions ? selectedBucket.transactions.map(x => mapTransaction(x)) : [])
        .filter(x => !counterTransactions.some(y => y.id === x.id))
        .filter(x => x.id !== mainTransaction.id)
        .filter(x => (mainTransaction.type === DEPOSIT ? x.type === WITHDRAWAL : x.type === DEPOSIT))
        .filter(x => x.base === mainTransaction.base && x.quote === mainTransaction.quote)
        .map(x => {
          x.counterBucketId = selectedBucket.id;
          return x;
        }),
    [selectedBucket, counterTransactions, mainTransaction]
  );

  const [transactionSelection, setTransactionSelection] = useState({});
  const toggleCheck = id => {
    setTransactionSelection({
      ...transactionSelection,
      [id]: !transactionSelection[id],
    });
  };
  const selectAll = e => {
    setTransactionSelection(
      transactionsOfSelectedBucket.reduce((res, val) => {
        res[val.id] = e.target.checked;
        return res;
      }, {})
    );
  };
  const allSelected = useMemo(() => {
    const txIds = transactionsOfSelectedBucket.map(o => o.id);
    return transactionsOfSelectedBucket.length !== 0 && txIds.find(o => transactionSelection[o] !== true) === undefined;
  }, [transactionSelection, transactionsOfSelectedBucket]);

  const isSubmitDisabled = useMemo(
    () => Object.keys(transactionSelection).length === 0 || Object.values(transactionSelection).every(x => !x),
    [transactionSelection]
  );

  const tableColumns = [
    {
      Header: () => <CheckBox disabled={transactionsOfSelectedBucket.length === 0} checked={allSelected} onChange={selectAll} />,
      Cell: ({ row }) => <CheckBox checked={transactionSelection[row.id]} onChange={() => toggleCheck(row.id)} />,
      id: "check-row",
    },
    {
      Header: t("form.counter_transactions.container_name"),
      Cell: ({ row: { original } }) => containers.find(x => x.id === original.transactionContainerId)?.name,
      id: "containerName",
    },
    {
      Header: t("form.counter_transactions.date"),
      Cell: ({ value }) => (
        <>
          {formatUtcDate(value)}
          <span className={styles.time_small}>{formatUtcDate(value, timeFormat)}</span>
        </>
      ),
      accessor: "timestamp",
    },
    {
      Header: t("form.counter_transactions.type"),
      Cell: ({ row: { original } }) => t(`${transactionTypeTranslator(original.type, original.transfer)}`),
      accessor: "type",
    },
    {
      Header: t("form.counter_transactions.amount"),
      Cell: ({ row: { original } }) => <RateAndTotalLabel {...original} />,
      id: "price-total",
    },
    {
      Header: () => (
        <div style={{ textAlign: "right" }} className="mr-3">
          {t("form.counter_transactions.action")}
        </div>
      ),
      Cell: ({ row }) => (
        <div className={styles.action_cell}>
          {transactionSelection[row.id] ? (
            <Tooltip tooltipContent={t("form.counter_transactions.unselect_transaction")}>
              <GhostButton icon={<CloseIcon />} onClick={() => toggleCheck(row.id)} />
            </Tooltip>
          ) : (
            <Tooltip tooltipContent={t("form.counter_transactions.select_transaction")}>
              <GhostButton icon={<AddIcon />} onClick={() => toggleCheck(row.id)} />
            </Tooltip>
          )}
        </div>
      ),
      id: "actions",
    },
  ];

  return (
    <>
      {!selectedBucket ? (
        <Formik
          initialValues={{
            bucket: bucketOptions.length > 0 ? bucketOptions[0] : "",
          }}
          onSubmit={values => {
            setSelectedBucket(values.bucket.value);
            setModalWidth(ULTRAWIDE);
            setHeading(prevState => `${prevState} [${values.bucket.value.name}]`);
          }}
          validationSchema={object({
            bucket: object().required(),
          })}>
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <SelectFormField
                name="bucket"
                label={t("form.counter_transactions.bucket")}
                disabled={isSubmitting}
                required
                options={bucketOptions}
              />
              <PrimaryButton
                label={t("form.counter_transactions.select_bucket")}
                onClick={handleSubmit}
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Table data={transactionsOfSelectedBucket} columns={tableColumns} getRowId={row => row.id} />
          {transactionsOfSelectedBucket.length === 0 && (
            <div className={styles.empty_table_row}>
              <Heading level={4} margin="1rem 0 0" color="gray3">
                {t("form.counter_transactions.no_transactions_message")}
              </Heading>
            </div>
          )}

          <div className="w-100 d-flex align-items-center justify-content-end mt-4">
            <GhostButton onClick={onClose} className="mr-4" label={t("form.counter_transactions.cancel")} />
            <PrimaryButton
              onClick={() => {
                const newTransactions = transactionsOfSelectedBucket.filter(o => transactionSelection[o.id]);
                setCounterTransactions([
                  ...counterTransactions,
                  ...newTransactions.filter(x => !counterTransactions.some(y => y.id === x.id)),
                ]);
                onClose();
              }}
              collapsed
              label={t("form.counter_transactions.connect")}
              disabled={isSubmitDisabled}
            />
          </div>
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    buckets: state.buckets.buckets,
    counterTransactions: state.buckets.counterTransactions,
    containers: getRawContainers(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CounterTransactionsForm);
