import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import LoginForm from "containers/Forms/LoginForm";

import styles from "pages/Pages.module.scss";

const LogIn = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.subpage_form}>
      <Heading level={3} margin="0 auto 1.1rem">
        {t("login.log_in")}
      </Heading>
      <LoginForm />
    </div>
  );
};

export default LogIn;
