import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { FieldArray, Formik } from "formik";
import { CheckBox } from "grommet";
import { bindActionCreators } from "redux";
import { Add as AddIcon, Checkmark as CheckmarkIcon, Close as DeleteIcon, Edit as EditIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { isAfter } from "date-fns";
import { sortBy } from "lodash";
import {
  CheckBoxFormField,
  DateFormField,
  DecimalFormField,
  SelectFormField,
  SelectInput,
  TextFormField,
} from "components/Common/Inputs";
import { Form, GhostButton, Heading, PrimaryButton, Tab, Tabs } from "components/Common";
import { validatePortfolio } from "common/validators";
import CurrencyDecimal from "components/Formating/CurrencyDecimal";
import { controlDefaults, customSelectStyles } from "common/styles/selectStyles";
import { marketDataService } from "services";
import { BLACKLIST, WHITELIST } from "common/constants/labelFilterType";
import { AVCO, computationTypes } from "common/constants/computationTypes";
import {
  getActiveOrganizationTaxResidency,
  getActiveOrganizationTaxSubject,
  getActivePortfolioIdSearchUrl,
  getCurrencyOptions,
  getFiatOptionsWithIcon,
  getPortfoliosToSelect,
  getRuleSets,
} from "selectors";
import { modalActions } from "actions/modalActions";
import { AT_PROFIT_REALIZATION, feeApplicationTypesTranslator, IMMEDIATE } from "common/constants/feeApplicationTypes";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import TaxRules from "containers/Portfolio/TaxRules";
import { confirmationActions } from "actions/confirmationActions";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../Styles/Forms.module.scss";
import PairsInitialState from "./Portfolio/PairsInitialState";
import AccountsInitialState from "./Portfolio/AccountsInitialState";
import { getFilteredRuleSets } from "utils";
import { emptyAccountInitialBalances, emptyInitialState } from "common/constants/portfolio";

const enhancedSelectStyles = {
  ...customSelectStyles,
  control: base => ({
    ...base,
    ...controlDefaults,
    height: "100%",
    minHeight: "unset",
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    height: "100%",
  }),
  indicatorsContainer: base => ({
    ...base,
    height: "100%",
  }),
};

const validateFixedRateRow = async (values, index, validate, setTouched) => {
  const result = await validate();
  if (result.rates) {
    setTouched(`rates[${index}].pair`);
    setTouched(`rates[${index}].rate`);
    return false;
  }
  return true;
};

const PortfolioDetailForm = ({
  currencyOptions,
  fiatOptions,
  portfolioOptions,
  initialValues = {},
  reopenValues,
  openedIndex,
  onSubmit,
  reOpen,
  isEdit,
  isSmall,
  portfolioValuesTransform,
  labelOptions,
  accounts,
  portfolioIdSearch,
  activeTab,
  activeSubTab,
  allowedComputationTypes,
  taxResidency,
  taxSubject,
  ruleSets,
  modalActions,
  confirmationActions: {
    openEnableExperimentalComputation,
    openDeleteAllPortfolioPairsConfirmation,
    openDeleteAllPortfolioAccountsConfirmation,
  },
}) => {
  const { t } = useTranslation();

  const [formOpenedIndex, setFormOpenedIndex] = useState(openedIndex == null ? null : openedIndex);
  const [fixedRateOpenedIndex, setFixedRateOpenedIndex] = useState(null);
  const [accountInitialBalanceOpenedIndex, setAccountInitialBalanceOpenedIndex] = useState(null);
  const [fetchedRate, setFetchedRate] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab ?? undefined);
  const [activeSubTabIndex, setActiveSubTabIndex] = useState(activeSubTab ?? undefined);

  const feeApplicationTypes = [
    { value: AT_PROFIT_REALIZATION, label: t(feeApplicationTypesTranslator(AT_PROFIT_REALIZATION)) },
    { value: IMMEDIATE, label: t(feeApplicationTypesTranslator(IMMEDIATE)) },
  ];

  const computationTypeOptions = useMemo(
    () =>
      allowedComputationTypes
        ? computationTypes.map(o => ({ value: o, label: o })).filter(x => allowedComputationTypes.includes(x.value))
        : [],
    [allowedComputationTypes]
  );

  const {
    name = "",
    note = "",
    start = "",
    end = "",
    computationType = AVCO,
    currency = "",
    feeApplicationType = feeApplicationTypes[0],
    initialState,
    accountInitialBalances,
    rates = [],
    labelFilterType = WHITELIST,
    labels = [],
    experimentalComputation = true,
    labelFilterExclRelatedTxs = false,
  } = initialValues;

  const initialCurrency = fiatOptions.find(o => o.value === currency) || "";

  const formattedInitialState = useMemo(() => {
    const unsortedPairs = (initialState?.pairStates || []).map(({ base, quote, ...rest }) => ({
      ...rest,
      pair: {
        base: currencyOptions.find(x => x.value === base) ?? "",
        quote: currencyOptions.find(x => x.value === quote) ?? "",
      },
    }));

    const pairStates = sortBy(unsortedPairs, [x => [x.pair?.base?.value, x.pair?.quote?.value]]);

    return {
      ...initialState,
      pairStates,
      portfolio: portfolioOptions
        .filter(x => x.computationType === computationType)
        .find(x => x.value === initialState?.sourcePortfolioId),
    };
  }, [computationType, initialState, portfolioOptions, currencyOptions]);

  const accountOptions = useMemo(() => accounts.map(x => ({ label: `${x.name} (${x.currency})`, value: x })), [accounts]);

  const formattedAccountInitialBalances = useMemo(() => {
    if (!accountInitialBalances) {
      return emptyAccountInitialBalances;
    }

    return {
      ...accountInitialBalances,
      balances:
        accountInitialBalances.balances?.map(({ accountId, initialValue }) => ({
          account: accountOptions.find(x => x.value.id === accountId) ?? "",
          initialBalance: initialValue ?? "",
        })) ?? [],
      portfolio: portfolioOptions.find(x => x.value === accountInitialBalances.sourcePortfolioId),
    };
  }, [accountInitialBalances, accountOptions, portfolioOptions]);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const isNewerPortfolio = initialValues?.createdAt && isAfter(new Date(initialValues.createdAt), new Date("2022-01-08"));

  useEffect(() => {
    if (activeTabIndex !== undefined || activeSubTabIndex !== undefined) {
      setTimeout(() => document.getElementById("portfolio-detail-tabs")?.scrollIntoView({ behavior: "smooth" }), 300);
    }
  }, [activeTabIndex, activeSubTabIndex]);

  return (
    <Formik
      initialValues={
        reopenValues ?? {
          name,
          note,
          currency: initialCurrency,
          range: { from: start, to: end },
          labels: labels?.map(x => ({ label: x, value: x })),
          labelFilterType,
          computationType: computationTypeOptions.find(o => o.value === computationType) || "",
          feeApplicationType: feeApplicationTypes.find(o => o.value === feeApplicationType) || feeApplicationTypes[0],
          initialState: formattedInitialState,
          rates: rates
            .map(({ base, quote, rate }) => ({
              base: currencyOptions.find(x => x.value === base) ?? "",
              quote: currencyOptions.find(x => x.value === quote) ?? "",
              rate,
            }))
            .filter(o => o.rate && o.base && o.quote),
          accountInitialBalances: formattedAccountInitialBalances,
          experimentalComputation,
          labelFilterExclRelatedTxs,
        }
      }
      enableReinitialize
      validateOnMount
      validate={values => validatePortfolio(values, isSubmitted)}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, values, validateForm, setFieldTouched, setFieldValue, errors }) => {
        const allowedFiatOptions = useMemo(() => {
          const filteredRuleSets = getFilteredRuleSets(ruleSets, values.range?.from, values.range?.to);
          const ruleSetsCurrencies = filteredRuleSets?.map(x => x.accountingCurrencies)?.flat() ?? [];

          const ruleSetsEnableAllCurrencies = ruleSetsCurrencies?.some(x => x === "*") || ruleSetsCurrencies?.length === 0;
          const currencies = ruleSetsEnableAllCurrencies
            ? fiatOptions
            : fiatOptions.filter(x => ruleSetsCurrencies.includes(x.value)) ?? [];

          if (currencies.length === 0 || currencies.length === 1) return currencies;

          return [
            ruleSetsEnableAllCurrencies
              ? {
                  label: t("form.portfolio_detail.frequent_currencies"),
                  options: [
                    currencies.find(x => x.value === "USD"),
                    currencies.find(x => x.value === "EUR"),
                    currencies.find(x => x.value === "GBP"),
                  ].filter(x => !!x),
                }
              : undefined,
            {
              label: t("form.portfolio_detail.all_currencies"),
              options: currencies.sort((a, b) => a.value.localeCompare(b.value)),
            },
          ].filter(x => !!x);
        }, [fiatOptions, t, values.range, ruleSets]);

        return (
          <Form onSubmit={handleSubmit} className={styles.dashboard_header_form}>
            <TextFormField
              name="name"
              label={t("form.portfolio_detail.title")}
              placeholder={t("form.portfolio_detail.title_placeholder")}
              required
              disabled={isSubmitting}
            />
            <div className={styles.fields_row}>
              <SelectInput
                placeholder={taxResidency}
                options={[]}
                label={t("form.portfolio_detail.tax_residency")}
                required
                disabled
              />
              <SelectInput
                placeholder={taxSubject}
                options={[]}
                label={t("form.portfolio_detail.tax_subject")}
                required
                disabled
              />
            </div>
            <div className={styles.fields_row}>
              <CurrencySelectFormField
                name="currency"
                options={allowedFiatOptions}
                label={t("form.portfolio_detail.currency")}
                required
                disabled={isSubmitting}
                hideRequestMoreCurrencies
                disableOptimized
              />
              <DateFormField
                name="range"
                label={t("form.portfolio_detail.range")}
                disabled={isSubmitting}
                isClearable
                isRangeSelect
                showRangeButtons
              />
            </div>
            <div className={styles.fields_row}>
              <SelectFormField
                name="computationType"
                options={computationTypeOptions}
                label={t("form.portfolio_detail.computation_type")}
                required
                disabled={isSubmitting || isEdit}
                isSearchable={false}
                onSelect={() => setFieldValue("initialState", emptyInitialState, false)}
              />
              <SelectFormField
                name="feeApplicationType"
                options={feeApplicationTypes}
                label={t("form.portfolio_detail.fee_application_type")}
                required
                disabled={isSubmitting}
                isSearchable={false}
              />
            </div>
            <TextFormField
              name="note"
              label={t("form.portfolio_detail.note")}
              placeholder={t("form.portfolio_detail.note_placeholder")}
              disabled={isSubmitting}
              wrapperClassName={styles.note}
            />

            {!isNewerPortfolio && isEdit && !experimentalComputation && (
              <CheckBoxFormField
                name="experimentalComputation"
                label={
                  <>
                    {t("form.portfolio_detail.experimental_computation")}{" "}
                    <DynamicTooltip buttonClassName="ml-auto" event={null}>
                      <span
                        style={{ whiteSpace: "pre-line" }}
                        dangerouslySetInnerHTML={{ __html: t("dashboard.convert_pairs_help") }}
                      />
                    </DynamicTooltip>
                  </>
                }
                wrapperClassName="py-3"
                onChange={value => openEnableExperimentalComputation(initialValues, values, isEdit, value)}
                disabled={!isEdit}
              />
            )}

            <Tabs
              disableAnimation
              listClassName={cx(styles.modal_tabs, "mt-4 mb-3")}
              tabWrapperClassName={cx(styles.overflow_y_visible, "mb-5")}
              onTabClick={index => setActiveTabIndex(index)}
              defaultTabIndex={activeTabIndex}
              id="portfolio-detail-tabs">
              <Tab title={t("form.portfolio_detail.initial_balances")}>
                <Tabs
                  disableAnimation
                  listClassName={cx(styles.modal_tabs, "mt-n2 mb-3")}
                  tabWrapperClassName={cx(styles.overflow_y_visible, "mb-5")}
                  onTabClick={index => setActiveSubTabIndex(index)}
                  defaultTabIndex={activeSubTabIndex}>
                  <Tab title={t("form.portfolio_detail.pairs")}>
                    <PairsInitialState
                      currencyOptions={currencyOptions}
                      values={values}
                      errors={errors}
                      isSubmitting={isSubmitting}
                      formOpenedIndex={formOpenedIndex}
                      setFormOpenedIndex={setFormOpenedIndex}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      validateForm={validateForm}
                      onReopen={predecessorsValues => {
                        reOpen(
                          {
                            ...initialValues, // for id etc.
                            ...portfolioValuesTransform({ ...values, ...predecessorsValues }),
                          },
                          predecessorsValues ? null : formOpenedIndex,
                          activeTabIndex
                        );
                      }}
                      modalActions={modalActions}
                      isSmall={isSmall}
                      enhancedSelectStyles={enhancedSelectStyles}
                      isEdit={isEdit}
                      openDeleteAllPairs={() => openDeleteAllPortfolioPairsConfirmation(initialValues, values, isEdit)}
                      openDeleteSourcePtf={() => openDeleteAllPortfolioPairsConfirmation(initialValues, values, isEdit)}
                    />
                  </Tab>
                  <Tab title={t("form.portfolio_detail.accounts")}>
                    <AccountsInitialState
                      values={values}
                      accountInitialBalances={values.accountInitialBalances}
                      isSubmitting={isSubmitting}
                      accounts={accounts}
                      accountOptions={accountOptions}
                      accountInitialBalanceOpenedIndex={accountInitialBalanceOpenedIndex}
                      setAccountInitialBalanceOpenedIndex={setAccountInitialBalanceOpenedIndex}
                      validateForm={validateForm}
                      setFieldTouched={setFieldTouched}
                      modalActions={modalActions}
                      enhancedSelectStyles={enhancedSelectStyles}
                      handleCopyBalances={() =>
                        modalActions.openCopyAccountBalances(
                          predecessorsValues => {
                            reOpen(
                              {
                                ...initialValues, // for id etc.
                                ...portfolioValuesTransform({ ...values, ...predecessorsValues }),
                              },
                              predecessorsValues ? null : formOpenedIndex,
                              0,
                              1
                            );
                          },
                          isEdit,
                          accountOptions,
                          values.name,
                          values.accountInitialBalances?.sourcePortfolioId
                        )
                      }
                      portfolioIdSearch={portfolioIdSearch}
                      openDeleteAllAccounts={() => openDeleteAllPortfolioAccountsConfirmation(initialValues, values, isEdit)}
                    />
                  </Tab>
                </Tabs>
              </Tab>
              <Tab title={t("form.portfolio_detail.labels")}>
                <SelectFormField
                  name="labels"
                  label={t("form.portfolio_detail.labels")}
                  options={labelOptions.map(x => ({ label: x, value: x }))}
                  isMulti
                  disabled={isSubmitting}
                  isSearchable={false}
                />

                <div className={styles.checkbox}>
                  <CheckBox
                    checked={values.labelFilterType === BLACKLIST}
                    disabled={isSubmitting || !labelOptions || labelOptions.length === 0}
                    label={t("form.portfolio_detail.label_filter_type")}
                    onChange={e => setFieldValue("labelFilterType", e.target.checked ? BLACKLIST : WHITELIST)}
                  />
                </div>

                <div className={styles.checkbox}>
                  <CheckBox
                    checked={values.labelFilterExclRelatedTxs}
                    disabled={isSubmitting || !labelOptions || labelOptions.length === 0}
                    label={t("form.portfolio_detail.filter_exclude_related_tx")}
                    onChange={e => setFieldValue("labelFilterExclRelatedTxs", e.target.checked)}
                  />
                </div>
              </Tab>
              <Tab title={t("form.portfolio_detail.rates")}>
                <FieldArray
                  name="rates"
                  render={arrayHelpers => (
                    <div className={styles.fixed_rates}>
                      <div className={styles.flex_space_between}>
                        <b>{t("form.portfolio_detail.fixed_rates")}</b>
                        <GhostButton
                          icon={<AddIcon />}
                          label={t("form.portfolio_detail.add")}
                          disabled={isSubmitting}
                          onClick={async () => {
                            if (await validateFixedRateRow(values, fixedRateOpenedIndex, validateForm, setFieldTouched)) {
                              setFixedRateOpenedIndex(values.rates.length);
                              arrayHelpers.push({ pair: "", rate: "" });
                            }
                          }}
                          className={styles.balance_button}
                        />
                      </div>
                      <div className={styles.fixed_rates_grid}>
                        {values.rates.length > 0 ? (
                          <>
                            <span className={styles.header}>{t("form.portfolio_detail.base_quote_column")}</span>
                            <span />
                            <span className={styles.header}>{t("form.portfolio_detail.base_quote_column_rate")}</span>
                            <span />
                            {values.rates.map(({ base, quote, rate }, i) =>
                              i === fixedRateOpenedIndex ? (
                                <React.Fragment key={`rates[${i}]`}>
                                  <span className="d-flex align-items-center">
                                    <CurrencySelectFormField
                                      name={`rates[${i}].base`}
                                      options={currencyOptions}
                                      required
                                      wrapperClassName={styles.small_form_field}
                                      styles={enhancedSelectStyles}
                                      onSelect={async val => {
                                        try {
                                          const { rate } = await marketDataService.getRate(val.value, quote.value, Date.now());
                                          setFetchedRate({ base: val.value, quote: quote.value, rate: rate.toFixed(2) });
                                        } catch (e) {
                                          console.error("cannot get rate");
                                        }
                                      }}
                                    />
                                    {!isSmall && (
                                      <span style={{ marginLeft: "4px", marginRight: "-4px", fontSize: "20px" }}>/</span>
                                    )}
                                  </span>
                                  <CurrencySelectFormField
                                    name={`rates[${i}].quote`}
                                    options={currencyOptions}
                                    required
                                    wrapperClassName={styles.small_form_field}
                                    styles={enhancedSelectStyles}
                                    onSelect={async val => {
                                      try {
                                        const { rate } = await marketDataService.getRate(base.value, val.value, Date.now());
                                        setFetchedRate({ base: base.value, quote: val.value, rate: rate.toFixed(2) });
                                      } catch (e) {
                                        console.error("cannot get rate");
                                      }
                                    }}
                                  />
                                  <DecimalFormField
                                    name={`rates[${i}].rate`}
                                    required
                                    disabled={isSubmitting}
                                    wrapperClassName={styles.small_form_field}
                                    placeholder={
                                      values.rates[i].base?.value === fetchedRate.base &&
                                      values.rates[i].quote?.value === fetchedRate.quote
                                        ? fetchedRate.rate
                                        : ""
                                    }
                                  />
                                  <div className={styles.edit_buttons}>
                                    <GhostButton
                                      icon={<CheckmarkIcon />}
                                      onClick={async () => {
                                        if (
                                          await validateFixedRateRow(values, fixedRateOpenedIndex, validateForm, setFieldTouched)
                                        ) {
                                          setFixedRateOpenedIndex(null);
                                        }
                                      }}
                                    />
                                    <GhostButton
                                      icon={<DeleteIcon />}
                                      onClick={() => {
                                        arrayHelpers.remove(i);
                                        setFixedRateOpenedIndex(null);
                                      }}
                                    />
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={`rates[${i}]`}>
                                  <div className={styles.label}>{`${base.value}/${quote.value}`}</div>
                                  <div />
                                  <div className={styles.label}>
                                    <CurrencyDecimal className={styles.label} number={parseFloat(rate)} useSpan={false} />
                                  </div>
                                  <div className={styles.edit_buttons}>
                                    <GhostButton
                                      icon={<EditIcon />}
                                      disabled={fixedRateOpenedIndex !== null || isSubmitting}
                                      onClick={() => setFixedRateOpenedIndex(i)}
                                    />
                                    <GhostButton
                                      disabled={fixedRateOpenedIndex !== null || isSubmitting}
                                      icon={<DeleteIcon />}
                                      onClick={() => {
                                        if (fixedRateOpenedIndex === i) {
                                          setFixedRateOpenedIndex(null);
                                          arrayHelpers.remove(i);
                                        } else {
                                          arrayHelpers.remove(i);
                                          if (fixedRateOpenedIndex > i) setFixedRateOpenedIndex(fixedRateOpenedIndex - 1);
                                        }
                                      }}
                                    />
                                  </div>
                                </React.Fragment>
                              )
                            )}
                          </>
                        ) : (
                          <Heading className={styles.no_rates} level={5} color="gray3" textAlign="center">
                            {t("form.portfolio_detail.np_fixed_rates")}
                          </Heading>
                        )}
                      </div>
                    </div>
                  )}
                />

                <div className="mb-3" />
              </Tab>
              <Tab title={t("form.portfolio_detail.tax_rules.title")}>
                <TaxRules portfolioRange={values.range} />
              </Tab>
            </Tabs>
            <PrimaryButton
              label={t("form.portfolio_detail.submit_button")}
              isLoading={isSubmitting}
              onClick={e => {
                e.preventDefault();
                setIsSubmitted(true);
                handleSubmit();
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    currencyOptions: getCurrencyOptions(state),
    fiatOptions: getFiatOptionsWithIcon(state),
    portfolioOptions: getPortfoliosToSelect(state),
    accounts: state.accounts.accounts,
    portfolioIdSearch: getActivePortfolioIdSearchUrl(state),
    user: state.user,
    taxResidency: getActiveOrganizationTaxResidency(state),
    taxSubject: getActiveOrganizationTaxSubject(state),
    ruleSets: getRuleSets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioDetailForm);
