import React from "react";
import { Checkmark as YesIcon, Close as NoIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "../../containers/Modals/ModalEnvelope";
import { SecondaryButton } from "../Common";

import styles from "../Styles/Confirmations.module.scss";
import { LoaderOverlay } from "components/Common";

const ConfirmationEnvelope = props => {
  const { t } = useTranslation();

  const {
    onConfirm,
    onCancel,
    heading = t("confirmation.default.heading"),
    confirmLabel = t("confirmation.default.confirm"),
    confirmTestId,
    cancelLabel = t("confirmation.default.cancel"),
    critical = false,
    criticalCancel,
    children,
    isLoading,
  } = props;

  return (
    <ModalEnvelope id="confirmation-modal" heading={heading} onClose={onCancel}>
      <LoaderOverlay isLoading={isLoading}>
        <div className={styles.modal_confirmation_content}>
          <div className={styles.confirmation}>{children}</div>
          <div className={styles.confirmation_buttons}>
            <SecondaryButton label={cancelLabel} icon={<NoIcon />} onClick={onCancel} critical={criticalCancel} />
            <SecondaryButton
              label={confirmLabel}
              icon={<YesIcon />}
              onClick={onConfirm}
              critical={critical}
              testId={confirmTestId}
            />
          </div>
        </div>
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

export default ConfirmationEnvelope;
