import React, { useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import {
  getIsOpenedPortfolioEmpty,
  getRawOpenedPortfolio,
  getSelectedContainers,
  getTransactionFilter,
  getTransactions,
} from "selectors";
import { GhostButton, Heading, Menu, PermissionCheck, PrimaryButton, Tooltip } from "components/Common";
import LabelSelector from "components/DataManager/LabelSelector";
import {
  Add as AddIcon,
  Ascend as BulkPriceIcon,
  FormDown as FormDownIcon,
  FormUp as FormUpIcon,
  Trash as TrashIcon,
} from "grommet-icons";
import { CheckBox } from "grommet";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { faEdit, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BUY, DEPOSIT, SELL, WITHDRAWAL } from "common/constants/transactionType";
import { useScreenSize } from "hooks";
import { PrimaryButtonSize } from "components/Common/Buttons/PrimaryButton";
import styles from "../../Styles/DataManager.module.scss";

const ContainerTransactionsHeading = ({
  transactions,
  transactionSelection,
  setTransactionSelection,
  labels,
  isTransactionsFetching,
  selectedPortfolio,
  openedPortfolio,
  isOpenedPortfolioEmpty,
  selectedContainers,
  filters: { portfolioFiltersApplied },
  modalActions: {
    openAddTransaction,
    openBulkTxSetPrice,
    openBulkTxSetLabel,
    openAddTransactionsToBucket,
    openSetAddressForTransactions,
  },
  confirmationActions: { openDeleteTransactionsConfirmation },
  transactionActions: { changeFilterAndFetch, labelTransactions, deleteTransactionLabels },
  filter: { isFilterVisible, setIsFilterVisible },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const filtersApplied = portfolioFiltersApplied && selectedPortfolio && openedPortfolio?.hasFilters && !isOpenedPortfolioEmpty;
  const filtersDisabled = !selectedPortfolio || isOpenedPortfolioEmpty || (selectedPortfolio && !openedPortfolio?.hasFilters);

  const selectedTransactions = useMemo(
    () => transactions.filter(o => transactionSelection[o.id]),
    [transactionSelection, transactions]
  );

  const labeledTxSelected = useMemo(() => selectedTransactions.find(o => o.label !== null) !== undefined, [selectedTransactions]);

  const labelSelectedTransactions = async (transactionLabel, deleteLabel = false) => {
    const selection = transactionSelection;

    deleteLabel
      ? await deleteTransactionLabels(
          selectedTransactions.map(o => o.id),
          transactionLabel
        )
      : await labelTransactions(
          selectedTransactions.map(o => o.id),
          transactionLabel
        );

    setTransactionSelection(selection);
  };

  useEffect(() => {
    if (selectedContainers?.length === 0) setIsFilterVisible(false);
  }, [selectedContainers, setIsFilterVisible]);

  const isSetAddressEnabled = selectedTransactions.every(
    x => x.type === BUY || x.type === SELL || x.type === DEPOSIT || x.type === WITHDRAWAL
  );

  const filterButton = (
    <>
      <div className={styles.button_group}>
        {isFilterVisible ? (
          <GhostButton
            label={t("data_manager.transaction_filter.hide")}
            icon={<FormUpIcon />}
            iconRight
            className={styles.filter_button}
            onClick={() => setIsFilterVisible(false)}
          />
        ) : (
          <GhostButton
            label={t("data_manager.transaction_filter.show")}
            icon={<FormDownIcon />}
            iconRight
            className={styles.filter_button}
            onClick={() => setIsFilterVisible(true)}
            disabled={selectedContainers.length === 0}
          />
        )}
      </div>
    </>
  );

  const bulkActionsMenu = (
    <Menu
      label={t("data_manager.transaction_filter.bulk_actions")}
      className={styles.bulk_actions_menu}
      disabled={selectedContainers.length === 0}>
      <GhostButton
        className="mb-1"
        icon={<BulkPriceIcon />}
        label={t("data_manager.transaction_filter.set_price")}
        onClick={() => openBulkTxSetPrice(selectedContainers)}
      />
      <GhostButton
        icon={<BulkPriceIcon />}
        label={t("data_manager.transaction_filter.set_label")}
        onClick={() => openBulkTxSetLabel(selectedContainers, labels)}
      />
    </Menu>
  );

  const applyPtfFilterButton = (
    <div className={cx(styles.checkbox, { [styles.checkbox_disabled]: filtersDisabled })}>
      <CheckBox
        label={t("data_manager.transaction_filter.apply_ptf_filters")}
        disabled={filtersDisabled}
        checked={filtersApplied ?? false} // possibly undefined
        onChange={e => changeFilterAndFetch({ portfolioFiltersApplied: e.target.checked })}
        className="mb-0"
      />
    </div>
  );

  const rowActions = (
    <PermissionCheck>
      <div className={styles.action_buttons_row}>
        {selectedTransactions.length >= 1 && (
          <>
            <LabelSelector
              labels={labels}
              disabled={isTransactionsFetching}
              onApply={labelSelectedTransactions}
              showRemoveLabels={labeledTxSelected}
            />
            <GhostButton
              disabled={isTransactionsFetching}
              icon={<TrashIcon />}
              onClick={() => openDeleteTransactionsConfirmation(selectedTransactions)}
            />
            <Tooltip tooltipContent={t("data_manager.transaction_filter.add_to_bucket")}>
              <GhostButton
                disabled={isTransactionsFetching}
                icon={<FontAwesomeIcon icon={faFolderPlus} />}
                onClick={() => openAddTransactionsToBucket(selectedTransactions.map(x => x.id))}
                className={styles.fa_icon}
              />
            </Tooltip>
            {isSetAddressEnabled && (
              <Tooltip tooltipContent={t("data_manager.transaction_filter.set_addresses")}>
                <GhostButton
                  disabled={isTransactionsFetching}
                  icon={<FontAwesomeIcon icon={faEdit} />}
                  onClick={() => openSetAddressForTransactions(selectedTransactions)}
                  className={styles.fa_icon}
                />
              </Tooltip>
            )}
          </>
        )}
      </div>
    </PermissionCheck>
  );

  const addTxButton = (
    <PermissionCheck>
      <PrimaryButton
        icon={<AddIcon color="white" />}
        label={!isSmall && t("data_manager.transaction_filter.add_transaction")}
        disabled={selectedContainers.length !== 1}
        onClick={() => openAddTransaction(selectedContainers[0])}
        collapsed
        size={isSmall ? PrimaryButtonSize.Medium : undefined}
        className={styles.add_tx_button}
        data-tour="datamanager-add-transaction"
      />
    </PermissionCheck>
  );

  return (
    <>
      <Heading level={3}>
        {filtersApplied
          ? t("data_manager.transaction_filter.heading_ptf_name", { ptfName: openedPortfolio?.name })
          : t("data_manager.transaction_filter.heading")}
      </Heading>

      <div className={styles.transactions_heading_wrapper}>
        {isSmall ? (
          <div className={cx(styles.transactions_heading_grid)} data-tour="datamanager-table-actions">
            {filterButton}
            {bulkActionsMenu}
            {applyPtfFilterButton}
            {rowActions}
            {addTxButton}
          </div>
        ) : (
          <div className={cx(styles.transactions_heading_grid)}>
            <div data-tour="datamanager-table-actions" className="d-flex" style={{ gap: "8px" }}>
              {filterButton}
              {bulkActionsMenu}
              {applyPtfFilterButton}
            </div>

            {rowActions}
            {addTxButton}
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    transactions: getTransactions(state),
    filters: getTransactionFilter(state),
    openedPortfolio: getRawOpenedPortfolio(state),
    isOpenedPortfolioEmpty: getIsOpenedPortfolioEmpty(state),
    selectedContainers: getSelectedContainers(state),
    selectedPortfolio: state.dataManager.selectedPortfolio,
    isTransactionsFetching: state.dataManager.isTransactionsFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTransactionsHeading);
