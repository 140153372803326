import React, { useState } from "react";
import { Motion, spring } from "react-motion";
import cx from "classnames";
import { SMALL } from "common/constants/screenSizes";
import { colors } from "common/colors";
import { ReactComponent as ArrowLeftIcon } from "img/button-arrow-left.svg";
import { getDataTestId } from "utils";
import { Heading, LoaderOverlay } from "..";

import styles from "./Buttons.module.scss";
import { ArrowLeft } from "react-feather";

export const PrimaryButtonSize = {
  Small: "SMALL",
  Medium: "MEDIUM",
  Large: "LARGE",
  ExtraLarge: "XLARGE",
};

export const PrimaryButtonVariant = {
  Default: "default",
  OutlineBlue: "outline-blue",
};

const PrimaryButton = ({
  label,
  isLoading,
  disabled,
  className,
  icon,
  rightIcon = false,
  noIcon = false,
  collapsed = false,
  size = PrimaryButtonSize.Large,
  variant = PrimaryButtonVariant.Default,
  testId,
  ...rest
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  const isDisabled = disabled || isLoading;
  const isBlue = (mouseOver || isLoading) && !isDisabled;

  return (
    <button
      className={cx(styles.loading_button, className, { [styles.loading_button_collapsed]: collapsed })}
      type="submit"
      disabled={isDisabled}
      data-testid={getDataTestId("button", testId ?? label)}
      {...rest}>
      <LoaderOverlay isLoading={isLoading} spinnerSize={SMALL} lighter>
        <Motion defaultStyle={{ x: 0 }} style={{ x: spring(isBlue ? 110 : 0) }}>
          {interpolatingStyle => (
            <div>
              <div
                className={cx(isLoading ? styles.loading_button_content_loading : styles.loading_button_content, {
                  [styles.button_size_small]: size === PrimaryButtonSize.Small,
                  [styles.button_size_medium]: size === PrimaryButtonSize.Medium,
                  [styles.button_size_large]: size === PrimaryButtonSize.Large,
                  [styles.button_size_xlarge]: size === PrimaryButtonSize.ExtraLarge,
                  [styles.right_icon]: !!rightIcon,
                  [styles.with_icon]: !!icon || !noIcon || rightIcon,
                  [styles.variant_outline_blue]: variant === PrimaryButtonVariant.OutlineBlue,
                })}
                style={
                  disabled
                    ? { background: "linear-gradient(to right, rgba(28,106,228,.36), rgba(124,234,130,.36)" }
                    : {
                        background: `linear-gradient(to right, ${colors.gradientBlue} ${interpolatingStyle.x}%, ${
                          colors.gradientGreen
                        } ${100 + interpolatingStyle.x * 0.25}%`,
                      }
                }
                onMouseEnter={() => {
                  if (!disabled) setMouseOver(true);
                }}
                onMouseMove={() => {
                  if (!disabled) setMouseOver(true);
                }}
                onMouseLeave={() => setMouseOver(false)}>
                {icon && rightIcon && React.isValidElement(icon) ? icon : !noIcon && rightIcon ? <ArrowLeft /> : ""}
                <Heading
                  level={6}
                  color="white"
                  className={noIcon ? "m-auto" : ""}
                  style={noIcon ? undefined : rightIcon ? { marginLeft: "15px" } : { marginRight: "15px" }}>
                  {label}
                </Heading>
                {icon && !rightIcon && React.isValidElement(icon) ? (
                  icon
                ) : !noIcon && !rightIcon ? (
                  <ArrowLeftIcon color="white" />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </Motion>
      </LoaderOverlay>
    </button>
  );
};

export default PrimaryButton;
