import React from "react";
import { connect } from "react-redux";
import { supportActions } from "actions/supportActions";
import { modalActions } from "actions/modalActions";
import { Heading } from "components/Common";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import OrganizationTrialPeriodForm from "../Forms/OrganizationTrialPeriod";
import ModalEnvelope from "./ModalEnvelope";

const OrganizationTrialPeriodModal = ({ supportActions, modalActions, organization }) => {
  const { t } = useTranslation();

  const { displayName, id } = organization;

  const onSubmit = async (values, { setSubmitting }) => {
    const res = await supportActions.setOrganizationTrialPeriod(id, values.plan?.value, values.timestamp);
    if (res.value) modalActions.hideModal();
    setSubmitting(false);
  };

  return (
    <ModalEnvelope id="organization-trial-period" heading={t("modal.organization_trial_period.heading")}>
      <Heading level={5} textAlign="center" margin="-1rem auto 1rem auto">{`[${displayName}]`}</Heading>
      <OrganizationTrialPeriodForm onSubmit={onSubmit} organization={organization} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    supportActions: bindActionCreators(supportActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(OrganizationTrialPeriodModal);
