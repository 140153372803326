import React from "react";
import NumberFormat from "react-number-format";
import useDecimalSeparator from "hooks/useDecimalSeparator";
import { removeTrailingZeros } from "utils";
import { DECIMAL_DIGITS } from "../Common/Inputs/DecimalInput";

const CurrencyDecimal = ({ number, currencyPrefix, currencySuffix, useSpan = true, removeZeros = false, ...props }) => {
  const prefix = currencyPrefix ? `${currencyPrefix} ` : "";
  const suffix = currencySuffix ? ` ${currencySuffix}` : "";
  const commaAsDecimalSeparator = useDecimalSeparator();

  return (
    // currencyPrefix - is BEFORE sign (not like prefix)
    <NumberFormat
      value={removeZeros ? removeTrailingZeros(number) : number}
      displayType="text"
      isNumericString
      thousandSeparator=" "
      decimalScale={DECIMAL_DIGITS}
      decimalSeparator={commaAsDecimalSeparator ? "," : undefined}
      renderText={value =>
        useSpan ? (
          <span>
            {prefix}
            {value}
            {suffix}
          </span>
        ) : (
          `${prefix}${value}${suffix}`
        )
      }
      {...props}
    />
  );
};

export default CurrencyDecimal;
