import React, { useMemo } from "react";
import {
  CustomFormField,
  DateInput,
  DecimalFormField,
  SelectFormField,
  SelectInput,
  TextFormField,
  TimeInput,
} from "components/Common/Inputs";
import { GhostButton } from "components/Common";
import { formatTime, formatUtcDate, timeFormat, utc } from "common/formatters";
import { Add as AddIcon } from "grommet-icons";
import {
  baseQuoteTypes,
  DEPOSIT,
  isTxSingleCurrency,
  transactionTypeTranslator,
  TRANSFER_IN,
  TRANSFER_OUT,
} from "common/constants/transactionType";
import { useTranslation } from "react-i18next";
import { Close as CloseIcon } from "grommet-icons/icons";
import { disabledDays } from "utils";
import BigCurrencyDecimal from "components/Formating/BigCurrencyDecimal";
import cx from "classnames";
import RateAndTotalLabel from "containers/DataManager/RateAndTotalLabel";
import { colors } from "common/colors";
import {
  AddressesFields,
  SubtransactionFeeRebateFields,
} from "containers/Forms/Transactions/TransactionTypes/TransactionFormFields";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../../Styles/Forms.module.scss";

const DepositWithdrawalTransactionForm = ({
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
  transactionTypeOptions,
  currencyOptions,
  currencyOptionsWithLastUsed,
  isEdit,
  subtransactionType,
  setSubtransactionType,
  isTransfer,
  setIsTransfer,
  counterTransactions,
  containers,
  openSelectCounterTransactions,
  setCounterTransactions,
  getRate,
  isSubmitted,
  txType,
  areFurtherInputsDisabled,
  submitButton,
  labelOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();

  const fromContainer = useMemo(() => {
    if (counterTransactions?.length > 0) {
      return containers.find(x => x.id === counterTransactions[0].transactionContainerId)?.name;
    }

    return undefined;
  }, [counterTransactions, containers]);

  const hashLink = values.externalLink;

  return (
    <>
      <div className={styles.fields_row}>
        {isTransfer ? (
          <SelectInput
            label={t("form.add_transaction.trade_type")}
            value={
              txType === DEPOSIT
                ? { label: t(transactionTypeTranslator(TRANSFER_IN)), value: "transferIn" }
                : { label: t(transactionTypeTranslator(TRANSFER_OUT)), value: "transferOut" }
            }
            disabled
          />
        ) : (
          <SelectFormField
            name="type"
            options={transactionTypeOptions}
            label={t("form.add_transaction.trade_type")}
            required
            disabled={isSubmitting}
            isClearable
            onSelect={value => {
              const newType = value?.value;

              if (newType && !txType) {
                return;
              }

              if (baseQuoteTypes.includes(newType) && baseQuoteTypes.includes(txType)) {
                return;
              }

              if (isTxSingleCurrency(newType) && isTxSingleCurrency(txType)) {
                return;
              }

              setFieldValue("quoteCurrency", "");
              if (values.volume) {
                setFieldValue("baseQuantity", values.volume);
              }
            }}
          />
        )}

        <CurrencySelectFormField
          name="baseCurrency"
          options={currencyOptionsWithLastUsed}
          label={t("form.add_transaction.currency")}
          required
          disabled={isSubmitting}
          isClearable
          onSelect={val => setFieldValue("quoteCurrency", val)}
        />
      </div>
      <CustomFormField
        name="timestamp"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.add_transaction.date")}
        required
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.date}
            disabled={isSubmitting}
            isPlain
            isCustomField
            minDate={disabledDays.minDate}
            maxDate={disabledDays.maxDate}
            onBlur={() => setFieldTouched("timestamp", true)}
            onChange={value => {
              setFieldValue("date", value);
              if (values.baseCurrency && !areFurtherInputsDisabled) {
                getRate({ date: value, base: values.baseCurrency.value, quote: values.baseCurrency.value });
              }

              if (!values.timestamp) {
                setFieldValue(
                  "timestamp",
                  utc(values.timestamp ?? undefined).set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                );
              }
            }}
            isClearable
            onClear={() => {
              setFieldValue("date", null);
              setFieldValue("timestamp", null);
            }}
          />
          <TimeInput
            value={formatTime(values.timestamp, true)}
            isPlain
            isCustomField
            enableSeconds
            disabled={isSubmitting}
            className={styles.time}
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestamp",
                utc(values.timestamp ?? undefined).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>

      <div className={styles.fields_row}>
        <DecimalFormField
          name="volume"
          label={t("form.add_transaction.basic_volume")}
          disabled={isSubmitting || areFurtherInputsDisabled}
          required>
          <span className={styles.small_text}>{values.baseCurrency?.value || ""}</span>
        </DecimalFormField>
        <TextFormField
          name="note"
          label={t("form.add_transaction.note")}
          disabled={isSubmitting || areFurtherInputsDisabled}
          autoFocus={modalOptions?.focusNote}
        />
      </div>

      <SelectFormField
        name="labels"
        options={labelOptions}
        label={t("form.add_transaction.labels")}
        disabled={isSubmitting || areFurtherInputsDisabled}
        isClearable
        isMulti
        autoFocus={modalOptions?.focusLabels}
      />

      <SubtransactionFeeRebateFields
        values={values}
        txType={txType}
        areFurtherInputsDisabled={areFurtherInputsDisabled}
        isSubmitting={isSubmitting}
        subtransactionType={subtransactionType}
        setSubtransactionType={setSubtransactionType}
        currencyOptions={currencyOptions}
      />

      <>
        <AddressesFields
          values={values}
          txType={txType}
          areFurtherInputsDisabled={areFurtherInputsDisabled}
          isSubmitting={isSubmitting}
        />
        {isEdit && (
          <>
            {isTransfer && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <b>{t("form.add_transaction.counter_transactions")} *</b>
                  {isTransfer && (
                    <GhostButton
                      className="mx-0"
                      icon={<AddIcon />}
                      label={t("form.add_transaction.counter_transactions")}
                      onClick={openSelectCounterTransactions}
                    />
                  )}
                </div>
                {counterTransactions.length > 0 ? (
                  <>
                    <div className={styles.counter_transactions_list}>
                      {counterTransactions.map(transaction => (
                        <React.Fragment key={transaction.id}>
                          <span className={styles.transaction_item}>
                            {formatUtcDate(transaction.timestamp)} {formatUtcDate(transaction.timestamp, timeFormat)}
                          </span>
                          <span className={styles.transaction_item}>
                            {containers.find(x => x.id === transaction.transactionContainerId)?.name}
                          </span>
                          <span className={styles.transaction_item}>
                            {t(transactionTypeTranslator(transaction.type, transaction.transfer))}
                          </span>
                          <span className={styles.transaction_item}>
                            <RateAndTotalLabel {...transaction} />
                          </span>
                          <span className={styles.transaction_item}>
                            <GhostButton
                              icon={<CloseIcon />}
                              className="mr-n5"
                              onClick={() => setCounterTransactions(counterTransactions.filter(x => x.id !== transaction.id))}
                            />
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                ) : (
                  <div
                    style={{ color: isSubmitted ? colors.red : colors.gray3 }}
                    className={cx(styles.font_small, "mt-1 mb-5 d-flex justify-content-center")}>
                    {isSubmitted ? (
                      <>{t("form.add_transaction.no_counter_tx_selected")}</>
                    ) : (
                      <b>{t("form.add_transaction.no_counter_tx_selected")}</b>
                    )}
                  </div>
                )}
              </>
            )}

            <div className="w-100 my-3 d-flex justify-content-between">
              {isTransfer ? (
                <GhostButton
                  className="mx-0 ml-n2"
                  icon={<AddIcon />}
                  label={
                    txType === DEPOSIT
                      ? t("form.add_transaction.convert_to_deposit")
                      : t("form.add_transaction.convert_to_withdrawal")
                  }
                  onClick={() => {
                    setCounterTransactions([]);
                    setIsTransfer(false);
                  }}
                />
              ) : (
                <GhostButton
                  className="mx-0 ml-n2"
                  icon={<AddIcon />}
                  label={
                    txType === DEPOSIT
                      ? t("form.add_transaction.convert_to_transfer_in")
                      : t("form.add_transaction.convert_to_transfer_out")
                  }
                  onClick={() => setIsTransfer(true)}
                />
              )}
            </div>
          </>
        )}{" "}
      </>

      <div
        className={
          values.type && values.baseCurrency && !areFurtherInputsDisabled ? styles.tx_info_label : styles.form_label_hidden
        }>
        <div>
          <div>
            {`${t(transactionTypeTranslator(txType, isTransfer))}${
              fromContainer ? ` ${t("form.add_transaction.from")} ${fromContainer}` : ""
            }: `}
            <BigCurrencyDecimal number={values.volume || 0} roundToFixedCrypto={10} currency={values.baseCurrency?.value} />{" "}
            {values.baseCurrency?.value}
          </div>
          {subtransactionType && (
            <div>
              {`${t(transactionTypeTranslator(subtransactionType))}: `}
              <BigCurrencyDecimal
                number={values.feeRebate || 0}
                roundToFixedCrypto={10}
                currency={values.feeRebateCurrency?.value}
              />
              {` ${values.feeRebateCurrency?.value || ""}`}
            </div>
          )}
          {values.addresses.length > 0 && !!values.addresses[0] && (
            <div style={{ overflowWrap: "anywhere" }}>
              {`${txType === DEPOSIT ? t("form.add_transaction.from_address") : t("form.add_transaction.to_address")}: `}
              {values.addresses.map((x, index) => (index === 0 ? x : `, ${x}`))}
            </div>
          )}
        </div>
      </div>

      {submitButton}

      {hashLink && (
        <div className="d-flex justify-content-center w-75 mt-4 mx-auto">
          Hash:
          <a href={hashLink} rel="noopener noreferrer" target="_blank" className="ml-1" style={{ wordBreak: "break-word" }}>
            {hashLink.split("/")?.[hashLink.split("/")?.length - 1]}
          </a>
        </div>
      )}
    </>
  );
};

export default DepositWithdrawalTransactionForm;
