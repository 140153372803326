import axios from "axios";

export const mapJob = ({ type, percentDone, status, ...rest }) => ({
  jobType: type,
  jobPercentage: percentDone,
  jobStatus: status,
  ...rest,
});

async function getJob(containerId, { organizationId, vaultId }) {
  const response = await axios.get(`organizations/${organizationId}/vaults/${vaultId}/containers/${containerId}/file-import`);
  return mapJob(response.data);
}

export const jobService = {
  getJob,
};
