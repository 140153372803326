import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { supportActions } from "actions/supportActions";
import { SecondaryButton } from "components/Common";
import { Logout as LeaveIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { getIsSpectatorMode } from "selectors";
import styles from "./Styles/App.module.scss";

const Support = ({ isSpectatorMode, isPresentationMode, supportActions }) => {
  const { t } = useTranslation();

  return isSpectatorMode && !isPresentationMode ? (
    <div className={styles.support}>
      <SecondaryButton
        onClick={supportActions.leaveSpectatorMode}
        icon={<LeaveIcon size="medium" />}
        label={t("support.spectator_mode")}
        critical
        className="mx-0"
      />
    </div>
  ) : null;
};

function mapStateToProps(state) {
  return {
    isSpectatorMode: getIsSpectatorMode(state),
    isPresentationMode: state.user.isPresentationMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supportActions: bindActionCreators(supportActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);
