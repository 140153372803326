import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../Styles/Explanations.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.explanation}>
      <p>{t("explanation.new_file.part1")}</p>
      <p>{t("explanation.new_file.part2")}</p>
    </div>
  );
};
