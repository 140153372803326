import React, { useState } from "react";
import { MoreHorizontal } from "react-feather";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import styles from "./Components.module.scss";
import cx from "classnames";
import { getDataTestId } from "utils";
import GhostButton from "components/Common/Buttons/GhostButton";

// Styles - index.scss .atlaskit-portal

export const ActionMenu = ({ children, testId, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu
      onOpenChange={args => setIsOpen(args.isOpen)}
      trigger={({ triggerRef, isSelected, testId, ...props }) => (
        <div
          ref={triggerRef}
          style={trigger ? undefined : { cursor: "pointer", width: "30px", height: "30px", borderRadius: "50%" }}
          className={cx({ [styles.open]: isOpen && !trigger, [styles.action_menu_button_wrapper]: !trigger })}
          {...props}
          data-testid={getDataTestId("action-menu", testId)}>
          {trigger ?? <MoreHorizontal className={styles.action_menu_button} />}
        </div>
      )}>
      {children}
    </DropdownMenu>
  );
};

ActionMenu.Item = ({ onClick, testId, disabled, hidden, className, ...rest }) => {
  return hidden ? null : (
    <DropdownItem onClick={onClick} testId={testId} isDisabled={disabled}>
      <GhostButton disabled={disabled} className={cx("mx-0 px-0", className)} {...rest} />
    </DropdownItem>
  );
};

ActionMenu.ItemGroup = DropdownItemGroup;
