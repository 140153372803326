import React from "react";
import { ReactComponent as FileExcelIcon } from "img/file-excel-icon.svg";
import { ReactComponent as FilePdfIcon } from "img/file-pdf-icon.svg";
import { ACCOUNTING_REPORT, ACCOUNTS_STATEMENT, COMPUTATION_DEBUG } from "common/constants/reportTypes";
import { colors } from "common/colors";

export const ReportTypeIcon = ({ reportType }) => {
  const props = { style: { fill: colors.primary, height: "40px" } };

  if (reportType === ACCOUNTING_REPORT || reportType === ACCOUNTS_STATEMENT || reportType === COMPUTATION_DEBUG) {
    return <FileExcelIcon {...props} />;
  }
  return <FilePdfIcon {...props} />;
};
