import React from "react";
import { useTranslation } from "react-i18next";
import {
  CustomFormField,
  DateInput,
  DecimalFormField,
  SelectFormField,
  TextFormField,
  TimeInput,
} from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";

import { disabledDays } from "utils";
import { formatTime, utc } from "common/formatters";
import { FEE } from "common/constants/transactionType";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../Styles/Forms.module.scss";

const FeeRebateFormContent = ({
  isSubmitting,
  handleSubmit,
  values,
  setFieldValue,
  setFieldTouched,
  transactionTypeOptions,
  currencyOptionsWithTxCurrencies,
  isEdit,
  isSubtransaction,
  labelOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();

  const type = values.type?.value;

  return (
    <Form onSubmit={handleSubmit} className={styles.add_transaction_form}>
      <div className={styles.fields_row}>
        <SelectFormField
          name="type"
          wrapperClassName={styles.type}
          options={transactionTypeOptions}
          label={t("form.add_transaction.trade_type")}
          required
          isSearchable={false}
          disabled={isSubmitting}
          isClearable
        />
        <CurrencySelectFormField
          name="feeRebateCurrency"
          wrapperClassName={styles.pair}
          options={currencyOptionsWithTxCurrencies}
          label={type === FEE ? t("form.add_transaction.currency_fee") : t("form.add_transaction.currency_rebate")}
          required
          disabled={isSubmitting}
          isClearable
        />
      </div>
      <CustomFormField
        name="timestamp"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.add_transaction.date")}
        required
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.date}
            disabled={isSubmitting || isSubtransaction}
            isPlain
            isCustomField
            minDate={disabledDays.minDate}
            maxDate={disabledDays.maxDate}
            onBlur={() => setFieldTouched("timestamp", true)}
            onChange={value => setFieldValue("date", value)}
            isClearable
            onClear={() => {
              setFieldValue("date", null);
              setFieldValue("timestamp", null);
            }}
          />
          <TimeInput
            value={formatTime(values.timestamp, true)}
            isPlain
            isCustomField
            disabled={isSubmitting || isSubtransaction}
            className={styles.time}
            enableSeconds
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestamp",
                utc(values.timestamp ?? undefined).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>

      <div className={styles.fields_row}>
        <DecimalFormField
          name="feeRebate"
          wrapperClassName={styles.quantity}
          label={type === FEE ? t("form.add_transaction.amount_fee") : t("form.add_transaction.amount_rebate")}
          required={!values.quoteQuantity || !values.unitPrice}
          disabled={isSubmitting}>
          <span className={styles.small_text}>{values.feeRebateCurrency?.value || ""}</span>
        </DecimalFormField>
        <TextFormField
          name="note"
          label={t("form.add_transaction.note")}
          disabled={isSubmitting}
          autoFocus={modalOptions?.focusNote}
        />
      </div>

      <SelectFormField
        name="labels"
        options={labelOptions}
        label={t("form.add_transaction.labels")}
        disabled={isSubmitting || isSubtransaction}
        isClearable
        isMulti
        autoFocus={modalOptions?.focusLabels && !isSubtransaction}
      />

      <PrimaryButton
        label={isEdit ? t("form.add_transaction.edit_subtransaction") : t("form.add_transaction.add_subtransaction")}
        isLoading={isSubmitting}
        onClick={handleSubmit}
        className={styles.submit_button}
      />
    </Form>
  );
};

export default FeeRebateFormContent;
