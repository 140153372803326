import { connect } from "react-redux";
import { getActiveMembership } from "selectors";
import { READ } from "common/constants/userPermission";

const PermissionCheck = ({ children, activeMembership, activeRole, allowFor }) => {
  const role = activeRole || activeMembership?.role;
  const check = allowFor ? allowFor.find(o => o === activeRole) !== undefined : role && role !== READ;
  return check ? children : null;
};

function mapStateToProps(state) {
  return {
    activeMembership: getActiveMembership(state),
  };
}

export default connect(mapStateToProps, null)(PermissionCheck);
