import React, { useEffect, useState } from "react";
import { useUrlHelper } from "hooks";
import { useTranslation } from "react-i18next";
import { GhostButton, Heading, LoaderOverlay } from "components/Common";
import styles from "pages/Pages.module.scss";
import { dateTimeFormat, formatDate } from "common/formatters";
import { Link } from "react-router-dom";
import { supportService } from "services/supportService";

const ErrorRateDetail = () => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      supportService.getErrorRate(id).then(response => {
        setData(response);
        setIsLoading(false);
      });
    }
  }, [id]);

  return (
    <div>
      <Heading level={3} className="mb-3">
        {t("et_configured_pairs.detail.header")}
      </Heading>
      <LoaderOverlay isLoading={isLoading}>
        {data && (
          <div className={styles.pairs_detail}>
            <span>{t("et_configured_pairs.table_header.currency_pair")}</span>
            <span>
              {data.base} / {data.quote}
            </span>
            <span>{t("et_configured_pairs.table_header.date")}</span>
            <span>{formatDate(data.rateDate, dateTimeFormat)}</span>
            <span>{t("et_configured_pairs.table_header.rate")}</span>
            <span>-</span>
            <span>{t("et_configured_pairs.table_header.source")}</span>
            <span />
            <span>{t("et_configured_pairs.table_header.calculation_procedure")}</span>
            <span />
            <span>{t("et_configured_pairs.table_header.error_details")}</span>
            <span className={styles.pairs_detail_problems}>
              <span>{t("et_configured_pairs.table_header.problem_type")}</span>
              <span>{t("et_configured_pairs.table_header.rate_id")}</span>
              {data.problems.map((problem, index) => (
                <React.Fragment key={index}>
                  <span>{problem.type}</span>
                  <span>{problem.rates.map(rate => JSON.stringify(rate)).join(", ")}</span>
                </React.Fragment>
              ))}
            </span>
            <span>{t("et_configured_pairs.table_header.creation_date")}</span>
            <span>{formatDate(data.generatedAt, dateTimeFormat)}</span>
          </div>
        )}
      </LoaderOverlay>
      <Link to="/support/pairs/error-rates">
        <GhostButton className="mt-5 ml-n2" label={t("button.close")} />
      </Link>
    </div>
  );
};

export default ErrorRateDetail;
