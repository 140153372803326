import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icons/css/flag-icons.min.css";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { userActions } from "actions/userActions";
import { Grommet } from "grommet";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import { grommetTheme } from "common/styles/grommetStyles";
import AppStructure from "components/AppStructure/AppStructure";
import { LOGIN_MODAL } from "actions/modalTypes";
import { getLocalStorageValue, MAIL_SUBSCRIBED, REBRANDING_DISPLAYED, setLocalStorageValue } from "common/localStorageManager";
import ModalContainer from "containers/ModalContainer";
import AlertsContainer from "./AlertsContainer";
import Loader from "./Modals/Loader";
import ScrollToTop from "./ScrollToTop";
import Support from "./Support";
import Walkthrough from "./Walkthrough/Walkthrough";

import "./Styles/index.css";
import styles from "./Styles/App.module.scss";
import { Title } from "./Title";
import { initAnalyticalScripts } from "./AnalyticalScripts";
import { getIsWalkthroughEnabled } from "selectors/walkthroughSelectors";

if (!document.isOnPremiseSolution) {
  initAnalyticalScripts();
}

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.mailCollectorTimer = null;
  }

  componentDidMount() {
    const isReactSnap = navigator.userAgent === "ReactSnap"; // react-snap uses chromium to simulate client
    const mailSubscribed = getLocalStorageValue(MAIL_SUBSCRIBED);
    const rebrandingDisplayed = getLocalStorageValue(REBRANDING_DISPLAYED);
    if (!this.props.isAuthenticated && !mailSubscribed && !isReactSnap && rebrandingDisplayed) {
      this.mailCollectorTimer = setTimeout(() => {
        if (
          !this.props.isAuthenticated &&
          this.props.openedModal !== LOGIN_MODAL &&
          (this.props.location.pathname.endsWith("/") ||
            this.props.location.pathname.endsWith("/pricing") ||
            this.props.location.pathname.endsWith("/about"))
        ) {
          this.props.modalActions.openMailSubscription(() => {
            setLocalStorageValue(MAIL_SUBSCRIBED, true); // onClose
          });
        }
      }, 20000);
    }

    /* if (!this.props.isAuthenticated && !rebrandingDisplayed) {
      this.props.modalActions.openRebrandingWelcomeModal(() => {
        setLocalStorageValue(REBRANDING_DISPLAYED, true);
      });
    } */
  }

  componentDidUpdate({ history: { action, location } }) {
    if (this.props.history.location.pathname !== location.pathname && action === "POP") {
      this.props.modalActions.hideModal();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.mailCollectorTimer);
  }

  render() {
    return (
      <ScrollToTop>
        <Title />
        <Grommet
          id="grommet_root"
          theme={deepMerge(grommet, grommetTheme)}
          full
          className={this.props.isWalkthrough ? styles.walkthrough_grommet : ""}>
          <AppStructure />
          <AlertsContainer />
          <Support />
          <ModalContainer />
          <Loader />
          <Walkthrough />
        </Grommet>
      </ScrollToTop>
    );
  }
}

function mapStateToProps(state) {
  return {
    isWalkthrough: getIsWalkthroughEnabled(state),
    isAuthenticated: state.user.isAuthenticated,
    openedModal: state.modal.modalType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
