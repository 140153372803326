import React, { useEffect, useMemo, useState } from "react";
import { currencyDropdownStyles } from "common/styles/selectStyles";
import { SelectFormField } from "components/Common/Inputs/SelectInput";
import { matchSorter } from "match-sorter";
import { components } from "react-select";
import { GhostButton } from "components/Common/index";
import { FEATURE_COIN_PAIR, FEATURE_REQUEST } from "common/constants/feedbackTypes";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import styles from "./Inputs/Inputs.module.scss";
import { usedCurrenciesCrypto, usedCurrenciesFiat } from "config/usedCurrencies";
import { useField } from "formik";

// Display only currency logo and code in react-select ValueContainer
const ValueContainer = ({ children, ...props }) => {
  const { hasValue } = props;

  const customChildren =
    hasValue && children?.[0]
      ? [
          {
            ...children?.[0],
            props: {
              ...children?.[0]?.props,
              children: [children?.[0]?.props?.selectProps?.value?.shortcut],
            },
          },
          children?.[1],
        ]
      : children;
  return <components.ValueContainer {...props}>{customChildren}</components.ValueContainer>;
};

const sortOptions = (options, searchString) =>
  matchSorter(options, searchString.toLowerCase(), {
    keys: ["search"],
    threshold: matchSorter.rankings.CONTAINS,
  });

const CurrencySelectFormField = ({
  disableOptimized,
  options: defaultOptions,
  styles: customStyles,
  components: customComponents,
  modalActions: { openFeedbackForm },
  hideRequestMoreCurrencies,
  ...props
}) => {
  const { t } = useTranslation();

  const [field] = useField(props.name);

  const defaultOptionsFormatted = useMemo(() => {
    const hasOptionGroups = defaultOptions.length > 0 && defaultOptions.some(x => !!x.options);
    return hasOptionGroups ? defaultOptions : [{ label: t("currency_select.all_currencies"), options: defaultOptions }];
  }, [defaultOptions]);

  const optimizedOptions = useMemo(() => {
    const usedCurrencies = [...usedCurrenciesCrypto, usedCurrenciesFiat];
    let filteredOptions = [];
    if (!!defaultOptions?.[1]?.options) {
      filteredOptions.push(defaultOptions[0]);
      if (!!defaultOptions?.[1].options) {
        filteredOptions.push({
          label: t("currency_select.selected_currencies"),
          options: defaultOptions[1].options.filter(x => usedCurrencies.includes(x.value)),
        });
      }
    } else {
      filteredOptions.push({
        label: t("currency_select.selected_currencies"),
        options: defaultOptions.filter(x => usedCurrencies.includes(x.value)),
      });
    }

    filteredOptions[0] = { ...filteredOptions?.[0], optimized: true };
    return filteredOptions;
  }, [defaultOptions]);

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!disableOptimized) {
      setOptions(optimizedOptions);
    } else {
      setOptions(defaultOptionsFormatted);
    }
  }, [defaultOptionsFormatted, optimizedOptions, field.value, disableOptimized]);

  const isOptimized = !!options?.[0]?.optimized;
  const [showAllClicked, setShowAllClicked] = useState(false);

  const handleSetShowAllClicked = showAll => {
    if (!disableOptimized) {
      setOptions(showAll ? defaultOptionsFormatted : optimizedOptions);
      setShowAllClicked(showAll);
    }
  };

  return (
    <SelectFormField
      styles={{ ...customStyles, ...currencyDropdownStyles }}
      components={{
        ...customComponents,
        ValueContainer,
        ...(hideRequestMoreCurrencies
          ? {}
          : {
              Menu: props => (
                <components.Menu {...props}>
                  {props.children}
                  <div className={"d-flex justify-content-between my-1"}>
                    {isOptimized && !disableOptimized ? (
                      <GhostButton
                        labelClassName={styles.request_more_currencies_label}
                        label={t("currency_select.all_currencies")}
                        onClick={() => handleSetShowAllClicked(true)}
                      />
                    ) : (
                      <div />
                    )}
                    <GhostButton
                      labelClassName={styles.request_more_currencies_label}
                      label={t("form.portfolio_detail.more_currencies")}
                      onClick={() =>
                        openFeedbackForm({
                          type: FEATURE_REQUEST,
                          subtype: FEATURE_COIN_PAIR,
                          message: "I would like to have currency XYZ supported, thank you",
                        })
                      }
                    />
                  </div>
                </components.Menu>
              ),
            }),
      }}
      onInputChange={inputValue => {
        let sortedOptions;
        if (disableOptimized) {
          sortedOptions =
            defaultOptions.length > 0 && defaultOptions.some(x => !!x.options)
              ? defaultOptions.map(group => ({
                  ...group,
                  options: sortOptions(group.options, inputValue),
                }))
              : sortOptions(defaultOptions, inputValue);
        } else {
          const newOptions = inputValue || showAllClicked || !!field.value ? defaultOptionsFormatted : optimizedOptions;

          sortedOptions = newOptions.map(group => ({
            ...group,
            options: sortOptions(group.options, inputValue),
          }));
        }

        // Clone deep to reset focus to first option in filtered results
        setOptions(sortedOptions);
      }}
      options={options}
      filterOption={() => true}
      minMenuHeight={250}
      maxMenuHeight={322}
      captureMenuScroll
      onMenuClose={() => handleSetShowAllClicked(false)}
      // menuIsOpen
      {...props}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(CurrencySelectFormField);
