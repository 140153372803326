import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { sortArray } from "utils";
import { Heading, Table } from "../Common";

import styles from "../Styles/Modals.module.scss";
import { CurrencyDecimal } from "../Formating";
import cx from "classnames";

const QuoteTable = ({ initialBalances }) => {
  const { t } = useTranslation();

  const quoteTableColumns = useMemo(
    () => [
      {
        Header: t("modal.components.quote_table.base_quote"),
        accessor: ({ base, quote }) => `${base} / ${quote}`,
      },
      {
        Header: (
          <div style={{ textAlign: "right" }} className={styles.copy_balances_row}>
            {t("form.portfolio_detail.base_amount_column")}
          </div>
        ),
        accessor: "basePosition",
        Cell: ({ value, row }) => {
          const rowValue = value.segments?.TRADE;

          return (
            <div className={cx(styles.quote_label, styles.copy_balances_row, { "negative-value": rowValue < 0 })}>
              <CurrencyDecimal number={rowValue} />
              &nbsp;
              <div className={cx("pl-1", styles.small_text)}>{row.original.base}</div>
            </div>
          );
        },
      },
      {
        Header: (
          <div style={{ textAlign: "right" }} className={styles.copy_balances_row}>
            {t("form.portfolio_detail.average_cost_column")}
          </div>
        ),
        accessor: "average",
        Cell: ({ value, row }) => {
          const rowValue = value.segments?.TRADE;

          return (
            <div className={cx(styles.quote_label, styles.copy_balances_row, { "negative-value": rowValue < 0 })}>
              <CurrencyDecimal number={rowValue} />
              &nbsp;
              <div className={cx("pl-1", styles.small_text)}>{row.original.quote}</div>
            </div>
          );
        },
      },
      {
        Header: (
          <div style={{ textAlign: "right" }} className={styles.copy_balances_row}>
            {t("form.portfolio_detail.opening_fee_column")}
          </div>
        ),
        accessor: "openingFee",
        Cell: ({ value, row }) => {
          const rowValue = value.segments?.TRADE;

          return (
            <div className={cx(styles.quote_label, { "negative-value": rowValue < 0 })}>
              <CurrencyDecimal number={value.segments?.TRADE} />
              &nbsp;
              <div className={cx("pl-1", styles.small_text)}>{row.original.quote}</div>
            </div>
          );
        },
      },
    ],
    [t]
  );

  const sortedInitialBalances = useMemo(
    () => sortArray(initialBalances, ["base", "quote"]).map((x, index) => ({ ...x, id: index })),
    [initialBalances]
  );

  return (
    <>
      <Table columns={quoteTableColumns} data={sortedInitialBalances} className="balances-table" fixedLayout />
      {initialBalances.length === 0 && (
        <Heading className="pt-4 pb-3" level={5} color="gray3" margin="-1rem auto 1rem" textAlign="center">
          {t("modal.components.quote_table.no_balances")}
        </Heading>
      )}
    </>
  );
};

export default QuoteTable;
