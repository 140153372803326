import React, { useState, useEffect } from "react";

export default ({ isVisible, maxHeight, children }) => {
  const hidden = { maxHeight: 0, overflowY: "hidden" };
  const visible = { maxHeight: `${maxHeight}px`, overflowY: "hidden" };

  const [style, setStyle] = useState({ ...(isVisible ? visible : hidden), transition: "all 0.5s ease" });

  useEffect(() => {
    setStyle(s => ({ ...s, ...(isVisible ? visible : hidden) }));
    if (isVisible) setTimeout(() => setStyle(s => ({ ...s, overflowY: "visible" })), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, maxHeight]);

  return <div style={style}>{children}</div>;
};
