import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { bindActionCreators } from "redux";
import { TextInput } from "components/Common/Inputs";
import { ActionCell, GhostButton, LoaderOverlay, PrimaryButton, Text } from "components/Common";
import { WIDE } from "common/constants/modalWidths";
import { Search as SearchIcon, Send as ContactIcon } from "grommet-icons";
import { marketDataService } from "services/marketDataService";
import { alertActions } from "actions/alertActions";
import { useTranslation } from "react-i18next";
import { WRITE } from "common/constants/userPermission";
import Delimiter from "components/Common/Delimiter";
import { useScreenSize } from "hooks";
import styles from "../Styles/Modals.module.scss";
import ModalEnvelope from "./ModalEnvelope";
import { Certificate as CertificateIcon } from "grommet-icons/icons";
import { BECOME_ACCOUNTANT, BECOME_ACCOUNTANT_MESSAGE } from "common/constants/feedbackTypes";
import { getIsAccountant } from "selectors";

const SearchForAccountantModal = ({ isAccountant, modalActions: { hideModal, openFeedbackForm } }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const [internalAccountants, setInternalAccountants] = useState([]);
  const [accountants, setAccountants] = useState(undefined);

  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState("");
  const [displayedSearch, setDisplayedSearch] = useState("");

  useEffect(() => {
    loadInternalAccountants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInternalAccountants = async () => {
    try {
      setIsFetching(true);
      const response = await marketDataService.getAccountants(search, true);

      setInternalAccountants(response);
      setIsFetching(false);
    } catch (e) {
      alertActions.error(t("alert.error.search_for_accountant_failed"));
    }
    setIsFetching(false);
  };

  const onSearch = async () => {
    if (!search) return;
    setDisplayedSearch(search);
    try {
      setIsFetching(true);
      const response = await marketDataService.getAccountants(search);

      setAccountants(response);
      setIsFetching(false);
    } catch (e) {
      alertActions.error(t("alert.error.search_for_accountant_failed"));
    }
    setIsFetching(false);
  };

  const onSendContact = useCallback(
    async accountantId => {
      try {
        setIsFetching(true);
        await marketDataService.contactAccountants(accountantId);
        alertActions.success(t("alert.success.accountant_contact_success"));
        hideModal();
      } catch (e) {
        alertActions.error(t("alert.error.accountant_contact_failed"));
        setIsFetching(false);
      }
    },
    [setIsFetching, t, hideModal]
  );

  const accountantsFound = accountants && accountants.length > 0;

  const AccountantsTable = ({ data }) => (
    <>
      <Delimiter />
      {data?.map(accountant => (
        <>
          <div className="d-flex justify-content-between my-2">
            <div>
              <div>
                <b>{accountant.organization ?? "Organization"}</b>
              </div>
              <div>
                {`${accountant.personContactCity}, ${accountant.personContactCountry}${
                  accountant.personContactProvince ? ` (${accountant.personContactProvince})` : ""
                }`}
              </div>

              {isSmall && (
                <ActionCell activeRole={WRITE} style={{ overflow: "visible" }} className="d-inline-block ml-n2 mt-3">
                  <GhostButton
                    onClick={() => onSendContact(accountant.userId)}
                    icon={<ContactIcon />}
                    label={t("modal.search_for_accountant_failed.contact_me")}
                  />
                </ActionCell>
              )}
            </div>

            {!isSmall && (
              <ActionCell activeRole={WRITE} style={{ overflow: "visible" }}>
                <GhostButton
                  onClick={() => onSendContact(accountant.userId)}
                  icon={<ContactIcon />}
                  label={t("modal.search_for_accountant_failed.contact_me")}
                />
              </ActionCell>
            )}
          </div>
          <Delimiter />
        </>
      ))}
    </>
  );

  return (
    <ModalEnvelope
      id="search-for-accountant-modal"
      heading={accountantsFound ? t("modal.search_for_accountant.heading_results") : t("modal.search_for_accountant.heading")}
      width={WIDE}>
      <LoaderOverlay isLoading={isFetching}>
        <div className="mb-4">{t("modal.search_for_accountant.internal_accountant")}</div>
        <AccountantsTable data={internalAccountants} />

        {accountants && (
          <>
            <Text as="div" margin="3rem 0 1rem">
              {t("modal.search_for_accountant.results_for_area")} {displayedSearch}
            </Text>

            {accountants.length > 0 ? (
              <AccountantsTable data={accountants} />
            ) : (
              <>
                <Delimiter />
                <Text as="div" margin="1.2rem 0">
                  {t("modal.search_for_accountant.no_accountants")}
                </Text>
                <Delimiter />
              </>
            )}
          </>
        )}

        <Text as="div" margin="3.5rem 0 1rem">
          {t("modal.search_for_accountant.message")}
        </Text>

        <form
          onSubmit={e => {
            e.preventDefault();
            onSearch();
          }}>
          <TextInput
            value={search}
            onChange={val => setSearch(val)}
            placeholder={t("modal.search_for_accountant.city_state")}
            autoFocus
            error={!isFetching}>
            <SearchIcon />
          </TextInput>
          <PrimaryButton
            label={t("modal.search_for_accountant.search")}
            onClick={onSearch}
            className={styles.search_accountant_button}
          />
        </form>

        <div className="d-flex justify-content-center mt-3">
          <GhostButton
            icon={<CertificateIcon />}
            label={t("page.organization.become_accountant")}
            onClick={() => openFeedbackForm({ type: BECOME_ACCOUNTANT, message: t(BECOME_ACCOUNTANT_MESSAGE) })}
          />
        </div>
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    isAccountant: getIsAccountant(state),
  };
}

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchForAccountantModal);
