import React from "react";
import { useTranslation } from "react-i18next";
import styles from "components/Styles/Tooltips.module.scss";
import DynamicTooltip from "./DynamicTooltip";

const StakingRewardsTooltip = props => {
  const { t } = useTranslation();
  return (
    <DynamicTooltip {...props} className="py-3" position="left">
      <div>
        <h5>{t("dashboard.staking_rewards.heading")}</h5>
        <div
          dangerouslySetInnerHTML={{ __html: t("tooltip.dashboard.staking_rewards.body") }}
          className={styles.staking_rewards_tooltip}
        />
      </div>
    </DynamicTooltip>
  );
};

export default StakingRewardsTooltip;
