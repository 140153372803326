import React from "react";
// import MarketInfo from 'components/Landing/MarketInfo';
import LandingBanner from "components/Landing/LandingBanner";
import Features from "components/Landing/Features";
import Reviews from "components/Landing/Reviews";
import Qualities from "components/Landing/Qualities";
import Exchanges from "components/Landing/Exchanges";
import Customers from "components/Landing/Customers";
// import HowToVideo from "components/Landing/HowToVideo";
import MediaMentions from "components/Landing/MediaMentions";
import cx from "classnames";

import styles from "pages/Pages.module.scss";

const Landing = () => (
  <>
    <div className={styles.landing_banner_wrapper}>
      <div className={cx(styles.app_content_padding, styles.unauthenticated)}>
        <LandingBanner />
      </div>
    </div>
    {/* <HowToVideo /> */}
    {/* <MarketInfo size={size}/> */}
    <div className={styles.features_wrapper}>
      <div className={styles.app_content}>
        <Features />
        <Qualities />
        <Exchanges />
      </div>
    </div>
    <Customers className={styles.app_content} />
    <Reviews className={styles.app_content} />
    <MediaMentions className={styles.app_content} />
  </>
);

export default Landing;
