import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { Trash as TrashIcon, Edit as EditIcon } from "grommet-icons";
import { GhostButton, Heading } from "components/Common";
import { accountTypesTranslator } from "common/constants/accountTypes";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import styles from "pages/Pages.module.scss";

import { getCurrencyIconProps } from "utils/currencyIcon";
import cx from "classnames";
import { Col, Row } from "react-bootstrap";
import Delimiter from "components/Common/Delimiter";
import { sortArray } from "utils";

const GeneralData = ({
  accountDetail,
  modalActions: { openEditAssetAccount },
  confirmationActions: { openDeleteAccountConfirmation },
}) => {
  const { t } = useTranslation();

  const connectedContainers = useMemo(() => {
    const sortedContainerNames = sortArray(accountDetail?.containerRelations ?? [], ["containerName"])
      .map(x => x.containerName)
      .join(", ");

    return sortedContainerNames ?? "~";
  }, [accountDetail]);

  const connectedPortfolios = useMemo(() => {
    const sortedPortfolioNames = sortArray(accountDetail?.portfolioRelations ?? [], ["portfolioName"])
      .map(x => x.portfolioName)
      .join(", ");

    return sortedPortfolioNames ?? "~";
  }, [accountDetail]);

  return (
    <>
      {accountDetail && (
        <Row className="mt-3" noGutters>
          <Col xs={12} lg={6} className={cx(styles.border_right, "pr-lg-3")}>
            <div className="d-flex justify-content-between">
              <Heading level={3}>{t("account_detail.general_data.header")}</Heading>

              <GhostButton
                icon={<EditIcon />}
                label={t("account_detail.general_data.edit_button")}
                onClick={() => openEditAssetAccount(accountDetail.id)}
                className="mr-n2 pr-0"
              />
            </div>

            <Delimiter className="mt-3" />

            <div className="detail-card">
              <div>
                <div>{t("account_detail.general_data.name")}</div>
                <div>{accountDetail.name}</div>

                <div>{t("account_detail.general_data.currency")}</div>
                <div>
                  <div className={styles.currency_image}>
                    <img className="mr-2" {...getCurrencyIconProps(accountDetail.currency)} alt={accountDetail.currency} />{" "}
                    {accountDetail.currency}
                  </div>
                </div>

                <div>{t("account_detail.general_data.type")}</div>
                <div>{t(accountTypesTranslator(accountDetail.type))}</div>

                <div>{t("account_detail.general_data.administered_by")}</div>
                <div>{accountDetail.administeredBy}</div>

                <div>{t("account_detail.general_data.description")}</div>
                <div>{accountDetail.description || "~"}</div>
              </div>

              <Delimiter />

              <div className="d-flex justify-content-between">
                <Heading level={4}>{t("account_detail.general_data.delete_button")}</Heading>
                <GhostButton
                  critical
                  label={
                    <div className="d-flex align-items-center">
                      <TrashIcon /> <span className="ml-1">{t("button.delete")}</span>
                    </div>
                  }
                  onClick={() =>
                    openDeleteAccountConfirmation({
                      accountId: accountDetail.id,
                      accountName: accountDetail.name,
                    })
                  }
                  className="mr-n2 pr-0"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="mt-5 mt-lg-0 pl-lg-3">
            <Heading level={3} className="mb-3">
              {t("account_detail.general_data.links")}
            </Heading>
            <Delimiter className="mt-3" />

            <div className="detail-card">
              <div>
                <div>{t("account_detail.general_data.connected_containers")}</div>
                <div>{connectedContainers}</div>
                <div>{t("account_detail.general_data.connected_portfolios")}</div>
                <div>{connectedPortfolios}</div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    accountDetail: state.accounts.accountDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);
