import { OPEN_MODAL, HIDE_MODAL, TOGGLE_TOOLTIPS } from "actions/types";

const initialState = {
  modalType: null,
  modalProps: {},
  tooltipsDisplayed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    //= ========================================
    case HIDE_MODAL:
      if (typeof action.modalType === "string" && state.modalType !== action.modalType) {
        return state;
      }
      return {
        ...state,
        modalType: null,
        modalProps: {},
      };

    //= ========================================
    case TOGGLE_TOOLTIPS:
      return {
        ...state,
        tooltipsDisplayed: !state.tooltipsDisplayed,
      };

    //= ========================================
    default:
      return state;
  }
};
