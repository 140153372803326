import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import DateLabel from "components/Dashboard/DateLabel";
import { Heading, Text } from "components/Common";
import { useScreenSize } from "hooks";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import { colors } from "common/colors";
import { getCurrencyIconProps } from "utils/currencyIcon";
import styles from "../Styles/Dashboard.module.scss";

const DashboardHeadingReadonly = ({ portfolio }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const taxRuleSetName = portfolio.taxRules?.length > 0 ? portfolio.taxRules[portfolio.taxRules.length - 1].name : "";

  return (
    <div>
      <div className={styles.dashboard_header_main}>
        <Heading margin="0 0 0.5rem" level={2} style={{ borderBottom: `2px solid ${colors.primary}` }} className="pb-2">
          {portfolio.name}
        </Heading>
      </div>
      {portfolio.note && (
        <div className={styles.portfolio_note}>
          <Text size="small">{portfolio.note}</Text>
        </div>
      )}
      <div className={styles.new_header}>
        <div className={styles.portfolio_info}>
          {(portfolio.start || portfolio.end) && (
            <span className="order-0">
              <DateLabel start={portfolio.start} end={portfolio.end} className={styles.dates_label} />
            </span>
          )}

          <div className="d-flex align-items-center order-2 order-md-1">
            {taxRuleSetName && (
              <>
                <Text style={{ maxWidth: isSmall ? "min-content" : "unset" }}>
                  <strong style={{ whiteSpace: "nowrap" }}>{taxRuleSetName}</strong>
                </Text>
                <span className="mx-2">•</span>
              </>
            )}
            <Text>
              <strong>{portfolio.computationType}</strong>
            </Text>
            <span className="mx-2">•</span>
            <div className={styles.dashboard_currency_wrapper}>
              <div className={cx(styles.dashboard_currency, styles.grid_display)} style={{ gridTemplateColumns: "auto auto" }}>
                <img className={styles.dashboard_currency_select} {...getCurrencyIconProps(portfolio.currency)} alt="" />{" "}
                <strong>{portfolio.currency}</strong>
              </div>
            </div>
          </div>

          <div className={cx(styles.grid_display, "order-1 order-md-2")}>
            <span>{t("dashboard.dashboard_header.convert_pairs.header")}:</span>
            <strong>
              {portfolio.experimentalComputation
                ? t("dashboard.dashboard_header.convert_pairs.on")
                : t("dashboard.dashboard_header.convert_pairs.off")}
            </strong>
            <DynamicTooltip className="w-auto" position={isSmall ? "right" : "bottom"}>
              <span style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: t("dashboard.convert_pairs_help") }} />
            </DynamicTooltip>
          </div>
        </div>

        <div className="text-right">
          {t("dashboard.dashboard_header.shared_portfolio")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("dashboard.dashboard_header.shared_portfolio_tooltip")}</DynamicTooltip>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeadingReadonly;
