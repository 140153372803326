import React, { useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import { modalActions } from "actions/modalActions";
import { GhostButton, LoaderOverlay } from "components/Common";
import { getChartOfAccountSubAccounts, getGlobalFetchingState } from "selectors";
import { MEDIUM } from "common/constants/screenSizes";
import { useUrlHelper } from "hooks";
import { organizationActions } from "actions/organizationActions";
import { CRYPTO, currencyTypeTranslator, FIAT } from "common/constants/currencyTypes";
import { colors } from "common/colors";

const SubAccounts = ({
  isLoading,
  subAccounts,
  modalActions: { openEditAssetAccountSubAccount },
  organizationActions: { getChartOfAccountSubAccounts },
}) => {
  const { t } = useTranslation();
  const { id: organizationId } = useUrlHelper();

  useEffect(() => {
    if (organizationId) getChartOfAccountSubAccounts(organizationId);
  }, [getChartOfAccountSubAccounts, organizationId]);

  const fiatSubAccounts = useMemo(() => subAccounts.filter(x => x.currencyType === FIAT), [subAccounts]);
  const cryptoSubAccounts = useMemo(() => subAccounts.filter(x => x.currencyType === CRYPTO), [subAccounts]);

  const SubAccountsRows = ({ items, type }) => (
    <>
      {items?.map((subAccount, index) => (
        <tr key={index}>
          <td>{t("organization_chart_of_account.table.sub_account")}</td>
          <td>{subAccount.currency}</td>
          <td>{subAccount.subAccNumber}</td>
          <td>{t(currencyTypeTranslator(subAccount.currencyType))}</td>
          <td>
            <div style={{ textAlign: "right", float: "right" }}>
              <GhostButton
                onClick={() =>
                  openEditAssetAccountSubAccount(
                    organizationId,
                    subAccount,
                    subAccounts.map(x => x.subAccNumber)
                  )
                }
                icon={<EditIcon />}
                actionButton
                testId={`action-edit-${type}_row-${index + 1}`}
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  return (
    <LoaderOverlay isLoading={isLoading} spinnerSize={MEDIUM}>
      <table>
        <thead>
          <tr>
            <th style={{ width: "23%" }}>{t("organization_chart_of_account.table_header.type")}</th>
            <th style={{ width: "23%" }}>{t("organization_chart_of_account.table_header.currency")}</th>
            <th style={{ width: "23%" }}>{t("organization_chart_of_account.table_header.sub_account_number")}</th>
            <th style={{ width: "23%" }}>{t("organization_chart_of_account.table_header.description")}</th>
            <th className="float-right">{t("organization_chart_of_account.table_header.action")}</th>
          </tr>
        </thead>

        <tbody>
          <SubAccountsRows items={fiatSubAccounts} type="fiat" />

          <tr style={{ borderBottom: `2px solid ${colors.gray2}` }} />

          <SubAccountsRows items={cryptoSubAccounts} type="crypto" />
        </tbody>
      </table>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    subAccounts: getChartOfAccountSubAccounts(state),
    isLoading: state.organizations.chartOfAccountSubAccountsFetching && !getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAccounts);
