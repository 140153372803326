import React from "react";
import cx from "classnames";
import styles from "components/Styles/AppStructure.module.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { changelogLink } from "common/constants/changelogLink";
import LanguageSelector from "components/AppStructure/Header/LanguageSelector";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "components/Common";
import { PrimaryButtonSize } from "components/Common/Buttons/PrimaryButton";

const Landing = ({ isLanding, isMenuExpanded, setIsMenuExpanded, openLoginModal }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  return (
    <div
      className={cx(styles.header_menu, {
        [styles.header_menu_expanded]: isMenuExpanded,
        [styles.landing]: isLanding,
      })}>
      <ul>
        {!document.isOnPremiseSolution && (
          <li>
            <NavLink className="p-2" activeClassName={styles.active_link} exact to="/pricing">
              {t("header.header_menu.pricing")}
            </NavLink>
          </li>
        )}
        <li>
          <NavLink className="p-2" activeClassName={styles.active_link} exact to="/integrations">
            {t("header.header_menu.integrations")}
          </NavLink>
        </li>
        <li>
          <NavLink className="p-2" activeClassName={styles.active_link} exact to="/about">
            {t("header.header_menu.about_us")}
          </NavLink>
        </li>
        <li>
          <a
            className="p-2"
            href={changelogLink}
            rel="noopener noreferrer"
            target="_blank"
            onClick={e => setIsMenuExpanded(false)}>
            {t("header.header_menu.updates")}
          </a>
        </li>
        <li>
          <NavLink className="py-2 p-md-0" activeClassName={styles.active_link} exact to="/help">
            {t("header.menu.help")}
          </NavLink>
        </li>
      </ul>
      <ul>
        <li>
          <NavLink
            activeClassName={styles.active_link}
            exact
            to={`/login${search}`}
            className="py-1 py-md-0"
            onClick={e => {
              e.preventDefault();
              openLoginModal();
              setIsMenuExpanded(false);
            }}>
            {t("header.header_menu.log_in")}
          </NavLink>
        </li>
        <li>
          <Link to={`/signup${search}`}>
            <PrimaryButton size={PrimaryButtonSize.Medium} label={t("header.header_menu.sign_up")} collapsed noIcon />
          </Link>
        </li>
        <li>
          <LanguageSelector />
        </li>
      </ul>
    </div>
  );
};

export default Landing;
