import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "hooks";
import { getCurrencyIconProps } from "utils/currencyIcon";
import { CurrencyDecimal } from "../Formating";
import { Heading, Table } from "../Common";
import RateSource from "./RateSource";

import styles from "../Styles/MasterTable.module.scss";

const getRowStyle = (isSmall, marginRight) =>
  isSmall ? { textAlign: "right" } : { textAlign: "right", marginRight: `${marginRight}px` };

const FiatsInventoryTable = ({ assets, currency }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();
  const rowProps = useCallback(marginRight => ({ style: getRowStyle(isSmall, marginRight) }), [isSmall]);

  const fiatsInventoryTableColumns = useMemo(
    () => [
      {
        Header: t("dashboard.fiats_inventory.currency_column"),
        accessor: "currency",
        Cell: ({ value }) => (
          <div className={styles.currency_image}>
            <img {...getCurrencyIconProps(value)} alt={value} />
            <h4 className="m-0">{value}</h4>
          </div>
        ),
      },
      {
        Header: <div {...rowProps(140)}>{t("dashboard.fiats_inventory.amount_column")}</div>,
        accessor: "quantity",
        Cell: ({ value }) => (
          <div {...rowProps(140)}>
            <h4 className="m-0">
              <CurrencyDecimal number={value} />
            </h4>
          </div>
        ),
      },
      {
        Header: <div {...rowProps(80)}>{t("dashboard.fiats_inventory.rate_column", { currency })}</div>,
        accessor: "rate",
        Cell: ({ value }) => (
          <div className="d-flex justify-content-end align-items-center" {...rowProps(80)}>
            <RateSource source={value.source} currency={currency} />
            <h4 className="m-0">
              <CurrencyDecimal number={value.rate} />
            </h4>
          </div>
        ),
      },
      {
        Header: <div {...rowProps()}>{t("dashboard.fiats_inventory.value_column", { currency })}</div>,
        accessor: "value",
        Cell: ({ value }) => (
          <div {...rowProps()}>
            <h4 className="m-0">
              <CurrencyDecimal number={value} />
            </h4>
          </div>
        ),
      },
    ],
    [t, currency, rowProps]
  );

  const getRowId = useCallback(row => row.currency, []);

  return assets.length > 0 ? (
    <Table data={assets} columns={fiatsInventoryTableColumns} getRowId={getRowId} />
  ) : (
    <Heading level={4} textAlign="center" margin="0.5rem 0 1rem" color="gray3">
      {t("dashboard.fiats_inventory.no_fiats")}
    </Heading>
  );
};

export default FiatsInventoryTable;
