import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { TextFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { userService } from "services";
import { alertActions } from "actions/alertActions";
import { useTranslation, Trans } from "react-i18next";

import { object, string } from "yup";
import styles from "../Styles/Forms.module.scss";

const FogotPasswordForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await userService.resetPassword(values.email);
      setSubmitted(true);
      setEmail(values.email);
      resetForm();
    } catch (err) {
      alertActions.error(t("alert.error.unknown_error"));
    }
    setSubmitting(false);
  };

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={object({
        email: string().email().required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {submitted ? (
            <div>
              <div className={styles.paragraph}>
                <Trans i18nKey="form.forgot_password.sent_message">
                  We have sent an email to {{ email }}. It should arrive soon, if not, please check your SPAM folder.
                  <br /> Link haven&apos;t arrived?
                </Trans>
              </div>
              <div className={styles.button_margin}>
                <PrimaryButton
                  label={t("form.forgot_password.try_again")}
                  isLoading={false}
                  onClick={() => {
                    setSubmitted(false);
                    setEmail("");
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <TextFormField
                name="email"
                label={t("form.forgot_password.email")}
                required
                disabled={isSubmitting}
                autoFocus
                autoComplete="username"
              />
              <div className={styles.button_margin}>
                <PrimaryButton label={t("form.forgot_password.reset_password")} isLoading={isSubmitting} onClick={handleSubmit} />
              </div>
            </div>
          )}
          <div className={styles.flex_space_between}>
            <Link to="/login" className={styles.small_link}>
              {t("form.forgot_password.login")}
            </Link>
            <Link to="/legal" className={styles.small_link}>
              {t("form.forgot_password.legal_privacy")}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FogotPasswordForm;
