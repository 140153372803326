import React from "react";
import { GhostButton, Heading, LoaderOverlay, Tab, Tabs } from "components/Common";
import Delimiter from "components/Common/Delimiter";
import history from "common/history";
import { useTranslation } from "react-i18next";
import { useScreenSize, useUrlHelper } from "hooks";
import { useRouteMatch } from "react-router-dom";
import cx from "classnames";
import { CheckBox } from "grommet";
import { CZ, SK, taxResidencyTranslator } from "common/constants/taxResidencies";
import { getGlobalFetchingState, getRawOrganizationDetails } from "selectors";
import { connect } from "react-redux";
import { SMALL } from "common/constants/screenSizes";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import ChartCZ from "common/assets/chartOfAccount/CZ.pdf";
import ChartSK from "common/assets/chartOfAccount/SK.pdf";
import FileSaver from "file-saver";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import PreAccounting from "containers/Organizations/ChartOfAccount/PreAccounting";
import AllAccounts from "./AllAccounts";
import Accounts from "./Accounts";
import SubAccounts from "./SubAccounts";
import styles from "../../Styles/Organizations.module.scss";
import { LabelWithTooltip } from "components/Tooltips/LabelWithTooltip";

export const chartOfAccountUrl = "chart-of-account";
export const chartOfAccountCategories = ["all", "accounts", "sub-accounts", "pre_accounting"];

const ChartOfAccount = ({ organizationsDetail, isGlobalFetching, organizationActions: { editOrganizationInfo } }) => {
  const { t } = useTranslation();
  const { id: organizationId } = useUrlHelper();
  const match = useRouteMatch();
  const { isSmall } = useScreenSize();

  const organization = organizationsDetail[organizationId];
  const organizationData = organization?.organizationData;
  const isOrganizationDetailLoading = organization?.isOrganizationDataFetching && !isGlobalFetching;

  const setSubAccountsDisplayed = useSubAccounts => {
    editOrganizationInfo(organizationId, { ...organizationData, useSubAccountsInAccountingReport: useSubAccounts });
  };

  const subAccountsDisplayed = organizationData?.useSubAccountsInAccountingReport;
  const taxResidency = organizationData?.taxResidency;

  return (
    <div>
      <Tabs
        listClassName={styles.modal_tabs}
        tabWrapperClassName={isSmall ? undefined : styles.detail_tab}
        HeaderComponent={({ children }) => (
          <div data-tour="organization-chart-account">
            <div className="mb-3 mt-3 d-md-flex justify-content-between">
              <Heading level={3}>
                {t(`organization_detail.transactions.chart_of_account.${match.params.subTab ?? chartOfAccountCategories[0]}`)}
              </Heading>
              <div className="mt-n2">{children}</div>
            </div>

            <Delimiter className="mb-4" />

            <div className={cx(styles.chart_of_account_header, "mb-3")}>
              {match.params.subTab === chartOfAccountCategories[0] ? (
                <div className="d-flex align-items-center">
                  <LoaderOverlay isLoading={isOrganizationDetailLoading} spinnerSize={SMALL} className="w-auto">
                    <CheckBox
                      checked={subAccountsDisplayed}
                      onChange={e => setSubAccountsDisplayed(e.target.checked)}
                      label={
                        <LabelWithTooltip
                          label={t("organization_chart_of_account.use_sub_accounts")}
                          tooltip={t("organization_chart_of_account.tooltips.use_sub_accounts")}
                        />
                      }
                    />
                  </LoaderOverlay>
                </div>
              ) : isSmall ? null : (
                <div />
              )}

              <LoaderOverlay isLoading={isOrganizationDetailLoading} spinnerSize={SMALL} className="m-auto w-auto">
                <div className="d-flex align-items-center">
                  <span className="mr-3">{t("organization_chart_of_account.tax_residency")}</span>

                  <LabelWithTooltip
                    label={<b>{t(taxResidencyTranslator(organizationData?.taxResidency))}</b>}
                    tooltip={t("organization_chart_of_account.tooltips.tax_residency")}
                  />
                </div>
              </LoaderOverlay>

              <div className="d-flex justify-content-md-end align-items-center" style={{ marginRight: "0.15rem" }}>
                {t("organization_chart_of_account.default_chart_of_account")}
                <GhostButton
                  label={t("organization_chart_of_account.download")}
                  onClick={() => {
                    if (taxResidency === SK) {
                      FileSaver.saveAs(ChartSK, "SK_Uctovna-osnova-krypto_WB_22-12.pdf");
                    }
                    if (taxResidency === CZ) {
                      FileSaver.saveAs(ChartCZ, "CZ_Ucetni-osnova-krypto_WB_22-12.pdf");
                    }
                  }}
                  className="m-0 p-0 mr-n2"
                  disabled={!(taxResidency === SK || taxResidency === CZ)}
                />

                <DynamicTooltip buttonClassName="ml-2">
                  {t("organization_chart_of_account.tooltips.default_chart_of_account")}
                </DynamicTooltip>
              </div>
            </div>
          </div>
        )}
        onTabClick={index =>
          history.push(
            `/organization-accounting-settings/${organizationId}/${chartOfAccountUrl}/${chartOfAccountCategories[index] || ""}`
          )
        }
        defaultTabIndex={Math.max(
          chartOfAccountCategories.findIndex(o => o === match.params.subTab),
          0
        )}>
        <Tab title={t("organization_detail.transactions.chart_of_account.all")}>
          <AllAccounts subAccountsDisplayed={subAccountsDisplayed} />
        </Tab>
        <Tab title={t("organization_detail.transactions.chart_of_account.accounts")}>
          <Accounts />
        </Tab>
        <Tab title={t("organization_detail.transactions.chart_of_account.sub-accounts")}>
          <SubAccounts />
        </Tab>
        <Tab title={t("organization_detail.transactions.chart_of_account.pre_accounting")}>
          <PreAccounting />
        </Tab>
      </Tabs>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizationsDetail: getRawOrganizationDetails(state),
    isGlobalFetching: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccount);
