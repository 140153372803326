import React from "react";

import styles from "../Styles/DataManager.module.scss";

const indicatorClassName = (isUploading, emphasized) => {
  let finalClass = styles.indicator_uploading;
  if (!isUploading) finalClass = styles.indicator_processing;
  if (emphasized) finalClass = styles.indicator_emphasized;
  return finalClass;
};

const getCircleProps = (stroke, radius, percents) => {
  const normalizedRadius = Math.max(radius - stroke, 0);
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percents / 100) * circumference;

  return {
    strokeWidth: stroke,
    strokeDasharray: `${circumference} ${circumference}`,
    strokeDashoffset,
    r: radius - stroke,
  };
};

const JobIndicator = ({ jobPercentage = 100, radius, isUploading = false, emphasized = false }) => (
  <svg
    viewBox={`0 0 ${radius * 2} ${radius * 2}`}
    height={radius * 2}
    width={radius * 2}
    className={indicatorClassName(isUploading, emphasized)}>
    <circle className={styles.full_circle} strokeWidth={5} r={radius - 5} cx={radius} cy={radius} />
    <circle
      className={styles.indicator}
      {...(isUploading ? getCircleProps(5, radius, jobPercentage) : getCircleProps(2, 44, 100))}
      cx={radius}
      cy={radius}
    />
    <circle
      key={isUploading}
      className={styles.data_processing_indicator}
      cx={radius}
      cy={radius}
      {...(isUploading ? getCircleProps(2, 44, 100) : getCircleProps(5, radius, jobPercentage))}
    />
  </svg>
);

export default JobIndicator;
