import React, { useMemo } from "react";
import { Formik } from "formik";
import { DateFormField, SelectFormField } from "components/Common/Inputs";
import { Form, GhostButton, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { array, mixed, object } from "yup";
import { getFiatOptions } from "selectors";
import { connect } from "react-redux";
import { computationTypeOptions } from "common/constants/computationTypes";
import { timeTestOptions } from "common/constants/timeTestTypes";
import { cryptoCryptoTaxationOptions } from "common/constants/cryptoCryptoTaxationTypes";
import { rewardTimingOptions } from "common/constants/rewardTimingOptions";
import styles from "../Styles/Forms.module.scss";

const RuleSetDetailForm = ({ ruleSet, allRuleSets, onSubmit, hideModal, fiatOptions }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => ({
      accountingCurrencyOptions: fiatOptions,
      computationMethodOptions: computationTypeOptions,
      cryptoCryptoTaxationOptions: cryptoCryptoTaxationOptions(t),
      timeTestOptions: timeTestOptions(t),
      rewardTimingOptions: rewardTimingOptions(t),
    }),
    [t, fiatOptions]
  );

  return (
    <Formik
      initialValues={{
        validFrom: ruleSet?.validFrom ?? "",
        accountingCurrencies:
          options.accountingCurrencyOptions.filter(x => ruleSet?.accountingCurrencies.includes(x.value)) ?? "",
        computationMethods: options.computationMethodOptions.filter(x => ruleSet?.computationMethods.includes(x.value)) ?? "",
        cryptoCryptoTaxation: ruleSet
          ? ruleSet.cryptoCryptoTaxation
            ? options.cryptoCryptoTaxationOptions[0]
            : options.cryptoCryptoTaxationOptions[1]
          : "",
        timeTest: options.timeTestOptions.find(x => x.value === ruleSet?.timeTest) ?? "",
        realizationTypes: {
          STAKING_REWARD:
            options.rewardTimingOptions.find(x => x.value === ruleSet?.realizationTypes?.STAKING_REWARD) ??
            options.rewardTimingOptions[0],
          REWARD:
            options.rewardTimingOptions.find(x => x.value === ruleSet?.realizationTypes?.REWARD) ??
            options.rewardTimingOptions[0],
          AIRDROP:
            options.rewardTimingOptions.find(x => x.value === ruleSet?.realizationTypes?.AIRDROP) ??
            options.rewardTimingOptions[0],
          EARNING:
            options.rewardTimingOptions.find(x => x.value === ruleSet?.realizationTypes?.EARNING) ??
            options.rewardTimingOptions[0],
          FORK:
            options.rewardTimingOptions.find(x => x.value === ruleSet?.realizationTypes?.FORK) ?? options.rewardTimingOptions[0],
        },
      }}
      validationSchema={object({
        validFrom: mixed().required(),
        accountingCurrencies: array().min(1, t("validation.required")).required(),
        computationMethods: array().min(1, t("validation.required")).required(),
        cryptoCryptoTaxation: object().required(),
        timeTest: object().required(),
        realizationTypes: object({
          STAKING_REWARD: object().required(),
          REWARD: object().required(),
          AIRDROP: object().required(),
          EARNING: object().required(),
          FORK: object().required(),
        }),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className={styles.dashboard_header_form}>
          <div className={styles.fields_row}>
            <DateFormField
              excludeDates={allRuleSets.map(x => new Date(x.validFrom))}
              name="validFrom"
              label={t("organization.effective_from")}
              required
              isClearable
              disabled={isSubmitting}
            />
            <SelectFormField
              options={options.rewardTimingOptions}
              name="realizationTypes.STAKING_REWARD"
              label={t("organization.stake_reward")}
              required
              disabled={isSubmitting}
            />
          </div>

          <div className={styles.fields_row}>
            <SelectFormField
              options={options.accountingCurrencyOptions}
              name="accountingCurrencies"
              label={t("organization.accounting_currency")}
              required
              disabled={isSubmitting}
              isMulti
            />
            <SelectFormField
              options={options.rewardTimingOptions}
              name="realizationTypes.REWARD"
              label={t("organization.reward")}
              required
              disabled={isSubmitting}
            />
          </div>

          <div className={styles.fields_row}>
            <SelectFormField
              options={options.computationMethodOptions}
              name="computationMethods"
              label={t("organization.cost_basis_method")}
              required
              disabled={isSubmitting}
              isMulti
            />
            <SelectFormField
              options={options.rewardTimingOptions}
              name="realizationTypes.AIRDROP"
              label={t("organization.airdrop")}
              required
              disabled={isSubmitting}
            />
          </div>

          <div className={styles.fields_row}>
            <SelectFormField
              options={options.cryptoCryptoTaxationOptions}
              name="cryptoCryptoTaxation"
              label={t("organization.taxed_crypto")}
              required
              disabled={isSubmitting}
            />
            <SelectFormField
              options={options.rewardTimingOptions}
              name="realizationTypes.EARNING"
              label={t("organization.earn")}
              required
              disabled={isSubmitting}
            />
          </div>

          <div className={styles.fields_row}>
            <SelectFormField
              options={options.timeTestOptions}
              name="timeTest"
              label={t("organization.time_test")}
              required
              disabled={isSubmitting}
            />
            <SelectFormField
              options={options.rewardTimingOptions}
              name="realizationTypes.FORK"
              label={t("organization.fork")}
              required
              disabled={isSubmitting}
            />
          </div>

          <div className="w-100 d-flex align-items-center justify-content-end mt-4">
            <GhostButton label={t("button.cancel")} onClick={hideModal} />
            <PrimaryButton
              label={ruleSet ? t("button.update") : t("button.add")}
              isLoading={isSubmitting}
              noIcon
              className="w-auto"
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    fiatOptions: getFiatOptions(state),
  };
}

export default connect(mapStateToProps)(RuleSetDetailForm);
