import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const DeleteTransactionsFromBucket = ({ onConfirm, onCancel, count }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_tx_from_bucket.heading")}
      confirmLabel={t("confirmation.delete_tx_from_bucket.confirm_label")}
      critical>
      <p>{t("confirmation.delete_tx_from_bucket.content", { count })}</p>
    </ConfirmationEnvelope>
  );
};

export default DeleteTransactionsFromBucket;
