import { organizationService } from "services";

export const getOrganizationReferences = organizationId => ({
  queryKey: ["organizationReferences", { organizationId }],
  queryFn: () => organizationService.getOrganizationReferences(organizationId),
});

export const getReferenceOptions = organizationId => ({
  queryKey: ["referenceOptions", { organizationId }],
  queryFn: () =>
    organizationService
      .getOrganizationReferences(organizationId)
      .then(response => response.map(x => ({ label: `${x.name} - ${x.description}`, value: x.id, reference: x }))),
});
