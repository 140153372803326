import React from "react";
import { useTranslation } from "react-i18next";
import { OFFICIAL, USER } from "common/constants/rateSourceType";
import cx from "classnames";

import styles from "../Styles/MasterTable.module.scss";

export default ({ source, currency }) => {
  const { t } = useTranslation();

  return source === USER ? (
    <span className={styles.rate_provider}>{t("dashboard.fiats_inventory.fixed_rate")}</span>
  ) : source === OFFICIAL && currency === "CZK" ? (
    <span className={cx(styles.rate_provider, styles.cnb)}>{t("dashboard.fiats_inventory.cnb")}</span>
  ) : source === OFFICIAL && currency === "EUR" ? (
    <span className={cx(styles.rate_provider, styles.ecb)}>{t("dashboard.fiats_inventory.ecb")}</span>
  ) : null;
};
