import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { modalActions } from "actions/modalActions";
import PricingPlans from "components/Pricing/PricingPlans";
import PricingPlan from "components/Pricing/PricingPlan";
import { getGlobalFetchingState, getRawOrganizationDetails } from "selectors";
import { useUrlHelper } from "hooks";
import { LoaderOverlay } from "components/Common";
import { BUSINESS, ONLINE, ONLINE_PLUS } from "common/constants/paymentPlans";
import styles from "pages/Pages.module.scss";
import PricingWhys from "components/Pricing/PricingWhys";

const Upgrade = ({ modalActions, organizationDetails, organizationActions }) => {
  const { id } = useUrlHelper();

  useLayoutEffect(() => {
    organizationActions.getOrganizationInfo(id);
  }, [id, organizationActions]);

  const { organizationData = {}, isOrganizationDataFetching } = organizationDetails[id] || {};

  return (
    <div className={styles.app_content_padding}>
      <LoaderOverlay isLoading={isOrganizationDataFetching}>
        <PricingPlans>
          <PricingPlan type={ONLINE} isCurrent={organizationData.plan === ONLINE} isDisabled />
          <PricingPlan
            type={ONLINE_PLUS}
            isCurrent={organizationData.plan === ONLINE_PLUS}
            isDisabled={organizationData.plan === ONLINE_PLUS || organizationData.plan === BUSINESS}
            onClick={() => modalActions.openCheckout(ONLINE_PLUS, id)}
          />
          <PricingPlan
            type={BUSINESS}
            isCurrent={organizationData.plan === BUSINESS}
            isDisabled={organizationData.plan === BUSINESS}
            onClick={() => modalActions.openFeedbackForm({ type: "BUSINESS_ACCOUNT" }, true)}
          />
        </PricingPlans>

        <PricingWhys />
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizationDetails: getRawOrganizationDetails(state),
    globalLoader: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
