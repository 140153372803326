import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import cx from "classnames";
import { FormDown as FormDownIcon, FormUp as FormUpIcon } from "grommet-icons";

import { useScreenSize } from "hooks";
import { useIsMounted } from "hooks/useIsMounted";
import { CZECH, ENGLISH, GERMAN, SLOVAK, SPANISH } from "common/constants/languages";
import styles from "../../Styles/AppStructure.module.scss";

const supported_languages = [
  { label: "en", value: ENGLISH, lang: "us" },
  { label: "cz", value: CZECH, lang: "cz" },
  { label: "sk", value: SLOVAK, lang: "sk" },
  { label: "es", value: SPANISH, lang: "es" },
  { label: "de", value: GERMAN, lang: "de" },
];

const LanguageSelector = () => {
  const { t } = useTranslation();

  const isMounted = useIsMounted();
  const { i18n } = useTranslation();
  const { isSmall } = useScreenSize();
  const [expanded, setExpanded] = useState(false);

  const handleClickOutside = e => {
    if (!document.getElementById("language-selector")?.contains(e.target)) {
      isMounted && setExpanded(false);
    }
  };

  const handleResize = () => isMounted && setExpanded(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.addEventListener("click", handleClickOutside);
    };
  });

  const languages = supported_languages.map(o => ({
    ...o,
    selected: i18n.language.startsWith(o.lang) || o.value.startsWith(i18n.language),
    label: o.label.toUpperCase(),
  }));

  const selectedLanguage = languages.find(o => o.selected)?.lang;
  const selectedLanguageLabel = languages.find(o => o.selected)?.label;
  const expansionIconStyle = { display: "inline", marginLeft: "3px", marginRight: "0 !important" };

  return (
    <>
      {expanded && isSmall && (
        <p className="cursor-pointer m-0 mt-1" style={{ color: "black !important", fontSize: "15px" }}>
          {t("header.language_selector.language")}
        </p>
      )}

      <div
        className={cx(styles.language_selector, "pb-1 pb-md-0")}
        onClick={e => {
          // To prevent menu close after expanding
          e.nativeEvent.stopImmediatePropagation(); // https://stackoverflow.com/questions/38619981/react-prevent-event-bubbling-in-nested-components-on-click
          setExpanded(!expanded);
        }}
        id="language-selector">
        {isSmall ? (
          <>
            {expanded ? (
              <div className={styles.non_clickable}>
                {languages.map(o => (
                  <span
                    key={o.value}
                    className={o.selected ? styles.language_selected : styles.language}
                    onClick={() => i18n.changeLanguage(o.value)}>
                    <span className={`fi fi-${o.lang}`} /> <span className="pl-1">{o.label}</span>
                  </span>
                ))}
              </div>
            ) : (
              <span>
                <div className="d-flex align-items-center">
                  <span className={cx(`fi fi-${selectedLanguage}`)} />
                  <span className="pl-1">
                    <strong>{selectedLanguageLabel}</strong>
                  </span>
                  <FormDownIcon style={expansionIconStyle} className="m-0" />
                </div>
              </span>
            )}
          </>
        ) : (
          <>
            <span className={cx(`fi fi-${selectedLanguage} mr-2`, styles.language_selector_flag)} />
            {selectedLanguageLabel}
            {expanded && (
              <div className={styles.language_selector_wrapper}>
                <div className={styles.language_selector_inner}>
                  {languages
                    .filter(x => x.lang !== selectedLanguage)
                    .map(o => (
                      <div
                        key={o.value}
                        className={cx(styles.language_selector_item, o.selected ? styles.language_selected : styles.language)}
                        onClick={() => i18n.changeLanguage(o.value)}>
                        <span className={cx(`fi fi-${o.lang}`, styles.language_selector_flag)} /> {o.label}
                      </div>
                    ))}
                </div>
              </div>
            )}
            {expanded ? <FormUpIcon style={expansionIconStyle} /> : <FormDownIcon style={expansionIconStyle} />}
          </>
        )}
      </div>
    </>
  );
};

export default LanguageSelector;
