import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { Add as AddIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "components/Common";

const AddPortfolioButton = ({ modalActions: { openAddPortfolio }, ...props }) => {
  const { t } = useTranslation();
  return (
    <PrimaryButton
      label={t("dashboard.dashboard_header.new_portfolio")}
      isLoading={false}
      onClick={openAddPortfolio}
      icon={<AddIcon color="white" />}
      collapsed
      {...props}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AddPortfolioButton);
