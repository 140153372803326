export const ADMIN = "ADMINISTRATOR";
export const READ = "READER";
export const WRITE = "WRITER";
export const OWNER = "OWNER";

export const userPermissions = [READ, WRITE, ADMIN, OWNER];

const t_fake = str => str;

const userPermissionsTranslations = {
  [ADMIN]: t_fake("constant.user_permission.admin"),
  [READ]: t_fake("constant.user_permission.read"),
  [WRITE]: t_fake("constant.user_permission.write"),
  [OWNER]: t_fake("constant.user_permission.owner"),
};

export const userPermissionsTranslator = val => userPermissionsTranslations[val] || "";
