import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import { organizationActions } from "actions/organizationActions";
import RuleSetDetailForm from "containers/Forms/RuleSetDetailForm";
import { getRuleSets } from "selectors";
import { WIDE } from "common/constants/modalWidths";
import { getUpdatedRuleSetName } from "utils";
import ModalEnvelope from "./ModalEnvelope";

const RuleSetDetailModal = ({
  organization,
  ruleSet,
  allRuleSets,
  setActiveRuleSet,
  modalActions: { hideModal },
  organizationActions: { addOrganizationRuleSet, updateOrganizationRuleSet },
}) => {
  const { t } = useTranslation();
  const isEdit = !!ruleSet;

  const onSubmit = async values => {
    const formattedValues = {
      taxResidency: organization.taxResidency,
      taxSubject: organization.taxSubject,
      validFrom: values.validFrom,
      computationMethods: values.computationMethods.map(x => x.value),
      accountingCurrencies: values.accountingCurrencies.map(x => x.value),
      cryptoCryptoTaxation: values.cryptoCryptoTaxation?.value,
      timeTest: values.timeTest?.value,
      realizationTypes: {
        STAKING_REWARD: values.realizationTypes?.STAKING_REWARD?.value,
        REWARD: values.realizationTypes?.REWARD?.value,
        AIRDROP: values.realizationTypes?.AIRDROP?.value,
        EARNING: values.realizationTypes?.EARNING?.value,
        FORK: values.realizationTypes?.FORK?.value,
      },
    };

    isEdit
      ? await updateOrganizationRuleSet(organization, formattedValues, ruleSet)
      : await addOrganizationRuleSet(organization, formattedValues);

    setActiveRuleSet({
      ...formattedValues,
      name: getUpdatedRuleSetName(formattedValues),
    });

    hideModal();
  };

  return (
    <ModalEnvelope
      id="rule-set-modal"
      heading={isEdit ? t("modal.rule_set.heading_edit") : t("modal.rule_set.heading")}
      width={WIDE}>
      <RuleSetDetailForm ruleSet={ruleSet} allRuleSets={allRuleSets} onSubmit={onSubmit} hideModal={hideModal} />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    allRuleSets: getRuleSets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RuleSetDetailModal);
