import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { useTranslation } from "react-i18next";
import { object } from "yup";
import { bucketService } from "services/bucketService";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { bucketActions } from "actions/bucketActions";
import { transactionActions } from "actions/transactionActions";

const AddTransactionsToBucketForm = ({
  transactionIds,
  buckets,
  lastUsedBucketId,
  user,
  bucketActions: { getBuckets, setLastUsedBucket },
  modalActions: { hideModal },
  transactionActions: { getTransactions },
}) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    setLastUsedBucket(values.bucketId.value);
    await bucketService.addTransactionsToBucket(values.bucketId.value, transactionIds, user);

    hideModal();
    getBuckets();
    getTransactions();
  };

  const bucketOptions = useMemo(() => buckets.map(x => ({ label: x.name, value: x.id })), [buckets]);
  const getInitialBucketOption = () => {
    if (bucketOptions.length === 1) return bucketOptions[0];
    if (lastUsedBucketId) return bucketOptions.find(x => x.value === lastUsedBucketId) ?? "";
    return "";
  };

  return (
    <Formik
      initialValues={{
        bucketId: getInitialBucketOption(),
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        bucketId: object().required(),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <SelectFormField
            name="bucketId"
            label={t("form.add_bucket_transaction.bucket")}
            disabled={isSubmitting}
            required
            options={bucketOptions}
          />
          <PrimaryButton label={t("form.add_bucket_transaction.submit")} onClick={handleSubmit} isLoading={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    buckets: state.buckets.buckets,
    lastUsedBucketId: state.buckets.lastUsedBucketId,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionsToBucketForm);
