import React, { useEffect, useMemo, useState } from "react";
import { GhostButton, Heading } from "components/Common";
import history from "common/history";
import styles from "containers/Styles/Forms.module.scss";
import { SelectInput } from "components/Common/Inputs";
import {
  getActiveOrganizationData,
  getActiveOrganizationId,
  getActiveOrganizationTaxResidency,
  getActiveOrganizationTaxSubject,
  getRuleSets,
} from "selectors";
import { bindActionCreators } from "redux";
import { portfolioActions } from "actions/portfolioActions";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDate } from "common/formatters";
import { cryptoCryptoTaxationTranslator } from "common/constants/cryptoCryptoTaxationTypes";
import { timeTestTranslator } from "common/constants/timeTestTypes";
import { controlDefaults, customSelectStyles } from "common/styles/selectStyles";
import cx from "classnames";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import { formatRowValue, getFilteredRuleSets } from "utils";
import { rewardTimingTranslator } from "common/constants/rewardTimingOptions";

const enhancedSelectStyles = {
  ...customSelectStyles,
  control: base => ({
    ...base,
    ...controlDefaults,
    height: "100%",
    minHeight: "unset",
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    height: "100%",
  }),
  indicatorsContainer: base => ({
    ...base,
    height: "100%",
  }),
};

const TaxRules = ({ portfolioRange, taxResidency, taxSubject, activeOrganizationId, ruleSets, modalActions: { hideModal } }) => {
  const { t } = useTranslation();

  const ruleSetOptions = useMemo(
    () => getFilteredRuleSets(ruleSets, portfolioRange?.from, portfolioRange?.to),
    [ruleSets, portfolioRange]
  );

  const [activeRuleSet, setActiveRuleSet] = useState();
  useEffect(() => {
    setActiveRuleSet(ruleSetOptions[0]);
  }, [ruleSetOptions]);

  return (
    <>
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mx-auto mb-4">
        <b>{t("form.portfolio_detail.tax_rules.preview")}</b>{" "}
        <GhostButton
          label={t("form.portfolio_detail.tax_rules.link")}
          onClick={() => {
            hideModal();
            history.push(`/organizations/${activeOrganizationId}/tax-rules`);
          }}
        />
      </div>

      <div className={styles.ptf_tax_rules}>
        <div>
          {t("form.portfolio_detail.tax_rules.tax_residency")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("form.portfolio_detail.tax_rules.tooltips.tax_residency")}</DynamicTooltip>
        </div>
        <div>{taxResidency}</div>
        <div>
          {t("form.portfolio_detail.tax_rules.tax_subject")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("form.portfolio_detail.tax_rules.tooltips.tax_subject")}</DynamicTooltip>
        </div>
        <div>{taxSubject}</div>
        <div>
          {t("form.portfolio_detail.tax_rules.rule_sets")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.tax_rule_sets")}</DynamicTooltip>
        </div>
        <div>
          <SelectInput
            value={activeRuleSet}
            options={ruleSetOptions}
            onChange={setActiveRuleSet}
            wrapperClassName={cx(styles.small_form_field, "m-0")}
            styles={enhancedSelectStyles}
            isInline
          />
        </div>

        <Heading level={4} className="mt-3">
          {t("organization.rules")}
        </Heading>
        <div />
        <div>
          {t("form.portfolio_detail.tax_rules.effective_from")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.effective_from")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(formatDate(activeRuleSet?.validFrom))}</div>
        <div>
          {t("form.portfolio_detail.tax_rules.accounting_currency")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.accounting_currency")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(activeRuleSet?.accountingCurrencies.join(", "))}</div>
        <div>
          {t("form.portfolio_detail.tax_rules.cost_basis_method")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.cost_basis_method")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(activeRuleSet?.computationMethods.join(", "))}</div>
        <div>
          {t("form.portfolio_detail.tax_rules.taxed_crypto")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.taxed_crypto")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(cryptoCryptoTaxationTranslator(activeRuleSet?.cryptoCryptoTaxation)))}</div>
        <div>
          {t("form.portfolio_detail.tax_rules.time_test")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.time_test")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(timeTestTranslator(activeRuleSet?.timeTest)))}</div>

        <Heading level={4} className="mt-3">
          {t("organization.rewards.header")}
        </Heading>
        <div />
        <div>
          {t("organization.stake_reward")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.stake_reward")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.STAKING_REWARD)))}</div>
        <div>
          {t("organization.reward")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.reward")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.REWARD)))}</div>
        <div>
          {t("organization.airdrop")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.airdrop")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.AIRDROP)))}</div>
        <div>
          {t("organization.earn")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.earn")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.EARNING)))}</div>
        <div>
          {t("organization.fork")}{" "}
          <DynamicTooltip buttonClassName="ml-1">{t("organization_tax_rules.tooltips.fork")}</DynamicTooltip>
        </div>
        <div>{formatRowValue(t(rewardTimingTranslator(activeRuleSet?.realizationTypes?.FORK)))}</div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    organizationData: getActiveOrganizationData(state),
    activeOrganizationId: getActiveOrganizationId(state),
    ruleSets: getRuleSets(state),
    taxResidency: getActiveOrganizationTaxResidency(state),
    taxSubject: getActiveOrganizationTaxSubject(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxRules);
