import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationEnvelope from "components/Confirmation/ConfirmationEnvelope";

const DeleteAccountAddress = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_account_address.heading")}
      confirmLabel={t("confirmation.delete_account_address.confirm_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.delete_account_address.content" />
      </p>
    </ConfirmationEnvelope>
  );
};

export default DeleteAccountAddress;
