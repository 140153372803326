import React from "react";
import cx from "classnames";
import { Form as FormComponent } from "grommet";

import styles from "./Components.module.scss";

const Form = ({ className, children, ...rest }) => (
  <FormComponent className={cx(styles.grommet_form, className)} {...rest}>
    {children}
  </FormComponent>
);

export default Form;
