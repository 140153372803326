import React, { useEffect, useState } from "react";
import { useField } from "formik";
import cx from "classnames";
import DatePicker from "react-datepicker";
import { getHours, getMinutes, getSeconds, setHours, setMinutes, setSeconds } from "date-fns";
import { Clock as ClockIcon } from "grommet-icons/icons";
import { useTranslation } from "react-i18next";
import styles from "./Inputs.module.scss";
import { ClearButton, IndicatorContainer, InputWrapper, LabelRow } from "./Shared";
import "./TimePicker.scss";

const getDateFromTime = (value, enableSeconds) => {
  if (!value) return undefined;

  let result = new Date();
  result = setHours(result, Number(value.substr(0, 2)));
  result = setMinutes(result, Number(value.substr(3, 2)));

  if (enableSeconds) {
    result = setSeconds(result, Number(value.substr(6, 2)));
  }

  return result;
};

const TimeInput = ({
  value,
  label,
  error,
  required,
  isInline,
  isPlain,
  isCustomField,
  isClearable,
  wrapperClassName,
  children,
  disabled,
  onChange,
  enableSeconds = false,
  name,
}) => {
  const { t } = useTranslation();
  const hasValue = value !== (enableSeconds ? "00:00:00" : "00:00");
  const [ref, setRef] = useState();

  const focusInput = () => !ref.state.open && ref.setOpen(true);

  useEffect(() => {
    const scrollOptionsTop = () => {
      setTimeout(() => {
        const myDiv = document.getElementsByClassName("react-datepicker__time-list")[0];
        if (myDiv && !value) {
          myDiv.scrollTop = 0;
        }
      });
    };

    document.addEventListener("click", scrollOptionsTop);
    return () => {
      document.removeEventListener("click", scrollOptionsTop);
    };
  }, [value]);

  return (
    <InputWrapper
      className={cx(styles.text_input, wrapperClassName, isCustomField && "pl-3")}
      disabled={disabled}
      inline={isInline}
      plain={isPlain}
      error={error}
      onClick={focusInput}>
      <LabelRow label={label} error={error} required={required} />

      <div className="time-picker_container">
        <DatePicker
          ref={datepicker => setRef(datepicker)}
          selected={getDateFromTime(value, enableSeconds)}
          onChange={value => onChange(getHours(value), getMinutes(value), getSeconds(value))}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeFormat="HH:mm"
          timeCaption={t("time_picker.label")}
          dateFormat={enableSeconds ? "HH:mm:ss" : "HH:mm"}
          placeholderText={enableSeconds ? "HH:MM:SS" : "HH:MM"}
          disabled={disabled}
          name={name ?? "time"}
          autoComplete="off"
        />
        <IndicatorContainer>
          {isClearable && hasValue && (
            <ClearButton
              disabled={disabled}
              onClick={e => {
                onChange(0, 0, 0);
                e.stopPropagation();
              }}
            />
          )}
          <ClockIcon className={styles.indicator_icon} size="smallMedium" />
          {children}
        </IndicatorContainer>
      </div>
    </InputWrapper>
  );
};

export const TimeFormField = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  return <TimeInput {...field} {...props} error={meta.touched ? meta.error : ""} />;
};

export default TimeInput;
