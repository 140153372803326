import React, { useCallback, useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { transactionActions } from "actions/transactionActions";
import { marketDataActions } from "actions/marketDataActions";
import {
  getActivePortfolioId,
  getContainers,
  getActiveOrganizationId,
  getIsDataManagerLoading,
  getIsPortfolioSelected,
} from "selectors";
import queryString from "query-string";
import history from "common/history";
import { Heading, LoaderOverlay, Tab, Tabs } from "components/Common";
import { IN_PROGRESS } from "common/constants/synchronizationStatusTypes";
import { useTranslation } from "react-i18next";
import { bucketActions } from "actions/bucketActions";
import { getBucketTransactionsCount } from "selectors/bucketSelectors";
import styles from "pages/Pages.module.scss";
import ContainerTransactions from "containers/DataManager/Containers/ContainerTransactions";
import ContainersHeading from "containers/DataManager/Containers/ContainersHeading";
import ContainersConsole from "containers/DataManager/Containers/ContainersConsole";
import BucketsConsole from "containers/DataManager/Buckets/BucketsConsole";
import BucketTransactions from "containers/DataManager/Buckets/BucketTransactions";
import { useScreenSize } from "hooks";
import { containersRefreshInterval, containersRefreshIntervalSyncActive } from "config/constants";

const pageIndexes = ["containers", "buckets"];

const DataManager = ({
  activePortfolioId,
  isPortfolioSelected,
  isEditMode,
  isLoading,
  containers,
  activeManualContainerSyncIds,
  transactionActions,
  marketDataActions,
  bucketActions,
  bucketTransactionsCount,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { isSmall } = useScreenSize();

  const effectivePortfolioId = isPortfolioSelected ? activePortfolioId : null;

  useEffect(() => {
    marketDataActions.getConnectors();
  }, [marketDataActions]);

  useEffect(() => {
    const { portfolioId } = queryString.parse(location.search, { parseNumbers: true });
    transactionActions.selectPortfolio(portfolioId || null);
  }, [location.search, transactionActions]);

  useEffect(() => {
    transactionActions.fetchPortfolioContainerInfo(effectivePortfolioId);
  }, [effectivePortfolioId, transactionActions]);

  useEffect(() => {
    transactionActions.getContainers();
  }, [transactionActions]);

  useEffect(() => {
    bucketActions.getBuckets();
  }, [bucketActions]);

  // Normal reloader handle - every 40s
  const reloadContainersList = useCallback(() => transactionActions.getContainers(undefined, true), [transactionActions]);
  useEffect(() => {
    const containersReloader = setInterval(reloadContainersList, containersRefreshInterval);

    return () => {
      clearInterval(containersReloader);
    };
  }, [reloadContainersList]);

  // Frequent reloader handle - every 4s
  const isSyncActive = useMemo(
    () => containers.some(x => x.connection?.status === IN_PROGRESS) || !activeManualContainerSyncIds.isEmpty(),
    [containers, activeManualContainerSyncIds]
  );
  useEffect(() => {
    if (isSyncActive) {
      const frequentStatusReloader = setInterval(reloadContainersList, containersRefreshIntervalSyncActive);

      return () => {
        clearInterval(frequentStatusReloader);
      };
    }

    // Reload containers on manual sync finish
    if (!isSyncActive) {
      reloadContainersList();
    }
  }, [isSyncActive, reloadContainersList]);

  const portfolioIdSearch = activePortfolioId ? `?portfolioId=${activePortfolioId}` : "";

  return (
    <LoaderOverlay isLoading={isLoading}>
      <div className={styles.app_content_padding}>
        {isSmall && <Heading level={2}>{t("data_manager.heading")}</Heading>}
        <Tabs
          HeaderComponent={({ children }) => (
            <>
              {isSmall ? (
                <div className="my-2">{children}</div>
              ) : (
                <div className="mb-3 d-flex justify-content-between align-items-end">
                  <Heading level={2} style={{ lineHeight: "28px" }}>
                    {t("data_manager.heading")}
                  </Heading>{" "}
                  {children}
                </div>
              )}
            </>
          )}
          onTabClick={index => history.push(`/datamanager/${pageIndexes[index] || ""}${portfolioIdSearch}`)}
          defaultTabIndex={Math.max(
            pageIndexes.findIndex(o => o === match.params.tab),
            0
          )}
          disableAnimation>
          <Tab title={t("data_manager.containers")}>
            <ContainersHeading />
            <ContainersConsole />
            {!isEditMode && <ContainerTransactions />}
          </Tab>
          <Tab title={`${t("data_manager.buckets")} (${bucketTransactionsCount})`}>
            <BucketsConsole />
            <BucketTransactions />
          </Tab>
        </Tabs>
      </div>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    activePortfolioId: getActivePortfolioId(state),
    isPortfolioSelected: getIsPortfolioSelected(state),
    organizationId: getActiveOrganizationId(state),
    isEditMode: state.dataManager.isEditMode,
    isLoading: getIsDataManagerLoading(state),
    containers: getContainers(state),
    activeManualContainerSyncIds: state.dataManager.activeManualContainerSyncIds,
    bucketTransactionsCount: getBucketTransactionsCount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    marketDataActions: bindActionCreators(marketDataActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataManager);
