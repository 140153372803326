import React from "react";
import cx from "classnames";
import ContainerIcon from "components/DataManager/ContainerIcon";
import { LoaderOverlay } from "components/Common";
import { getDataTestId } from "utils";
import styles from "../../Styles/DataManager.module.scss";

const Bucket = ({ bucket, clickAction, doubleClickAction, width, onRightClick, hideContextMenu, isInDeletion }) => {
  const { name, id, isSelected } = bucket;

  return (
    <LoaderOverlay isLoading={isInDeletion}>
      <div
        data-testid={getDataTestId("bucket", name)}
        className={cx(isSelected ? styles.container_selected : styles.container, styles.bucket)}
        onContextMenu={onRightClick}
        onClick={e => {
          e.stopPropagation();
          hideContextMenu();
          clickAction(id, e.ctrlKey || e.metaKey, e.shiftKey);

          if (e.ctrlKey || e.metaKey || e.shiftKey) window.getSelection().removeAllRanges();
        }}
        onDoubleClick={e => {
          hideContextMenu();
          doubleClickAction();
        }}>
        <div style={{ height: `${width}px`, width: `${width}px`, position: "relative" }}>
          <div className={styles.container_icon}>
            <ContainerIcon name={name} />
          </div>
        </div>
        <div className={styles.bucket_label}>
          <div className={styles.container_name}>{name}</div>
        </div>
      </div>
    </LoaderOverlay>
  );
};

export default Bucket;
