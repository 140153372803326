import React from "react";
import { WIDE } from "common/constants/modalWidths";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import CreateAccountForm from "../Forms/CreateAccountForm";

const CreateAccountModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope id="create-account-modal" heading={t("modal.create_account.heading")} width={WIDE} onClose={onClose}>
      <CreateAccountForm onClose={onClose} />
    </ModalEnvelope>
  );
};

export default CreateAccountModal;
