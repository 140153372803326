import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NORMAL } from "common/constants/modalWidths";
import ModalEnvelope from "./ModalEnvelope";
import CounterTransactionsForm from "../Forms/CounterTransactionsForm";

const CounterTransactionsModal = ({ onClose, mainTransaction }) => {
  const { t } = useTranslation();

  const [modalWidth, setModalWidth] = useState(NORMAL);
  const [heading, setHeading] = useState(t("modal.counter_transactions.heading"));

  return (
    <ModalEnvelope width={modalWidth} onClose={onClose} id="add-bucket-tx-modal" heading={heading}>
      <CounterTransactionsForm
        setModalWidth={setModalWidth}
        onClose={onClose}
        mainTransaction={mainTransaction}
        setHeading={setHeading}
      />
    </ModalEnvelope>
  );
};

export default CounterTransactionsModal;
