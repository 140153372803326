import React, { useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { HotKeys } from "react-hotkeys";
import Dropzone from "react-dropzone";
import { Search as SearchIcon, Share as AssignIcon, Trash as TrashIcon, View as DetailIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { animation, Item, Menu, useContextMenu } from "react-contexify";
import cx from "classnames";
import { faFileExcel, faGear, faHand, faPlug } from "@fortawesome/free-solid-svg-icons";
import { Edit as EditIcon } from "grommet-icons/icons";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import AddItemButton from "components/DataManager/AddItemButton";
import { useScreenSize } from "hooks";
import { ReactComponent as DownloadIcon } from "img/download-icon.svg";
import { getIsPortfolioSelected, getPortfolioContainerAssignments, isContainersConsoleLoading } from "selectors";
import { GhostButton, LoaderOverlay, PermissionCheck } from "components/Common";
import { TextInput } from "components/Common/Inputs";
import { alertActions } from "actions/alertActions";
import {
  CONTAINER_API,
  CONTAINER_CSV,
  CONTAINER_MANUAL,
  containerTypes,
  containerTypesTranslator,
} from "common/constants/containerTypes";
import "../../Styles/ContextMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "./Container";
import styles from "../../Styles/DataManager.module.scss";
import { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { ContextMenu } from "components/Common/ContextMenu";

const CONSOLE_MENU_ID = "console_context_menu";

const ContainersWrapper = ({
  containerName,
  onContainersDrop,
  onContainerClick,
  selectedContainers,
  visibleContainers,
  portfolioId,
  showLoader,
  isEditMode,
  isPortfolioSelected,
  portfolioContainerAssignments,
  transactionActions: {
    getTransactions,
    selectMultipleContainers,
    changeFilterAndFetch,
    changeFilter,
    unselectAll,
    exportContainerTransactions,
  },
  modalActions: { openContainerDetail, openAssignContainers, openCreateContainer, openEditContainerDetail },
  confirmationActions: { openDeleteTransactionContainersConfirmation },
  containersInDeletion,
}) => {
  const { t } = useTranslation();
  const refs = {
    [CONTAINER_API]: useRef(null),
    [CONTAINER_CSV]: useRef(null),
    [CONTAINER_MANUAL]: useRef(null),
  };

  const { isSmall } = useScreenSize();

  const consoleContextMenu = useContextMenu({
    id: CONSOLE_MENU_ID,
  });

  const onContainerRightClick = containerId => {
    const isSelected = selectedContainers.find(o => o.id === containerId);
    if (!isSelected) onContainerClick(containerId);
  };

  const handleShowContainer = () => openContainerDetail(selectedContainers[0].id, selectedContainers[0].type);
  const handleEditContainer = () => openEditContainerDetail(selectedContainers[0].id);
  const handleExportContainer = () => exportContainerTransactions(selectedContainers[0].id);
  const handleOpenSettings = () => openContainerDetail(selectedContainers[0].id, selectedContainers[0].type, 1, true);
  const handleAssignContainers = () =>
    openAssignContainers(
      portfolioId,
      selectedContainers.map(o => o.id)
    );
  const handleDeleteContainers = () =>
    openDeleteTransactionContainersConfirmation(
      selectedContainers.map(o => o.id),
      portfolioId
    );

  const isApiContainer = selectedContainers.length === 1 && selectedContainers[0].type === CONTAINER_API;

  return (
    <>
      <Dropzone
        noKeyboard
        noClick
        onDropAccepted={onContainersDrop}
        onDropRejected={() => alertActions.error(t("error.data_manager.drop_rejected"))}
        accept={{
          "text/csv": [".csv"],
        }}
        multiple
        useFsAccessApi={false}>
        {({ isDragActive, getRootProps, getInputProps, open }) => (
          <LoaderOverlay isLoading={showLoader} className={styles.dropzone_container} data-tour="datamanager-containers-console">
            <div
              {...getRootProps()}
              onContextMenu={e => {
                e.preventDefault();
                consoleContextMenu.show(e);
              }}
              onClick={consoleContextMenu.hideAll}>
              {/* disabled set to false because of https://github.com/react-dropzone/react-dropzone/issues/853 */}
              <input {...getInputProps({ disabled: false })} />
              <div className={styles.containers_console} id="containers-console">
                <div className={styles.controls_top}>
                  <div className={styles.container_type_selector}>
                    {containerTypes.map(o => (
                      <React.Fragment key={o}>
                        <span
                          onClick={() => {
                            refs[o].current.scrollIntoView({
                              behavior: "smooth",
                              block: "nearest",
                              inline: "center",
                            });
                          }}>
                          {t(containerTypesTranslator(o))}
                        </span>
                      </React.Fragment>
                    ))}
                  </div>
                  <div className={styles.button_group}>
                    {selectedContainers.length === 1 && !isEditMode && (
                      <GhostButton icon={<DetailIcon />} onClick={handleShowContainer} />
                    )}
                    <PermissionCheck>
                      {selectedContainers.length === 1 && !isEditMode && (
                        <GhostButton icon={<EditIcon />} onClick={handleEditContainer} />
                      )}
                      {selectedContainers.length === 1 && !isEditMode && (
                        <GhostButton icon={<DownloadIcon />} onClick={handleExportContainer} />
                      )}
                      {isApiContainer && !isEditMode && (
                        <GhostButton icon={<FontAwesomeIcon icon={faGear} />} onClick={handleOpenSettings} />
                      )}
                      {selectedContainers.length >= 1 && !isEditMode && (
                        <GhostButton icon={<AssignIcon />} onClick={handleAssignContainers} />
                      )}
                      {selectedContainers.length >= 1 && !isEditMode && (
                        <GhostButton icon={<TrashIcon />} onClick={handleDeleteContainers} />
                      )}
                    </PermissionCheck>
                    <TextInput
                      label={<SearchIcon size="smallMedium" />}
                      placeholder={t("data_manager.search_placeholder")}
                      isInline
                      value={containerName}
                      onChange={val => {
                        isEditMode ? changeFilter({ containerName: val }) : changeFilterAndFetch({ containerName: val });
                      }}
                      wrapperStyle={{ alignItems: "center" }}
                      tabIndex="2"
                    />
                  </div>
                </div>
                <HotKeys
                  keyMap={{
                    SELECT_ALL: "command+a",
                    DELETE: ["del", "backspace"],
                  }}
                  handlers={{
                    SELECT_ALL: e => {
                      e.preventDefault();
                      selectMultipleContainers(visibleContainers.map(o => o.id));
                      if (!isEditMode) getTransactions();
                    },
                    DELETE: e => {
                      e.preventDefault();
                      if (!isEditMode) handleDeleteContainers();
                    },
                  }}
                  tabIndex="3"
                  className={styles.containers_wrapper}
                  onClick={isEditMode ? null : unselectAll}>
                  {containerTypes.map(containerType => (
                    <div
                      key={containerType}
                      ref={refs[containerType]}
                      className={cx({ "my-5": containerType === CONTAINER_CSV })}>
                      <div className={styles.create_container}>
                        <PermissionCheck>
                          <AddItemButton
                            onClick={() =>
                              containerType === CONTAINER_CSV ? open() : openCreateContainer(portfolioId, containerType)
                            }
                            disabled={isEditMode}
                            label={
                              containerType === CONTAINER_API
                                ? t("data_manager.containers_console.create_blank_api")
                                : containerType === CONTAINER_CSV
                                ? t("data_manager.containers_console.create_blank_file")
                                : t("data_manager.containers_console.create_blank_manual")
                            }
                            type={t(containerTypesTranslator(containerType))}
                          />
                        </PermissionCheck>
                      </div>
                      <div className={styles.containers}>
                        {visibleContainers
                          .filter(o => o.type === containerType)
                          .map(o => (
                            <React.Fragment key={o.tmpId ? o.tmpId : o.id}>
                              <ContextMenu
                                focusClearElementId="containers-console"
                                onContextMenu={() => onContainerRightClick(o.id)}
                                trigger={
                                  <Container
                                    editMode={isEditMode}
                                    isAssigned={portfolioContainerAssignments[o.id]}
                                    showAssignmentInfo={isPortfolioSelected}
                                    clickAction={onContainerClick}
                                    doubleClickAction={() => openContainerDetail(o.id, o.type)}
                                    container={o}
                                    width={isSmall ? 70 : 100}
                                    isInDeletion={containersInDeletion.has(o.id)}
                                  />
                                }>
                                <DropdownItemGroup key={1}>
                                  {selectedContainers.length === 1 && (
                                    <>
                                      <DropdownItem onClick={handleShowContainer}>
                                        <GhostButton
                                          label={t("data_manager.containers_console.view")}
                                          icon={<DetailIcon />}
                                          className="mx-0 px-0 ml-n1"
                                        />
                                      </DropdownItem>
                                      <DropdownItem onClick={handleEditContainer}>
                                        <GhostButton
                                          label={t("data_manager.containers_console.edit")}
                                          icon={<EditIcon />}
                                          className="mx-0 px-0 ml-n1"
                                        />
                                      </DropdownItem>
                                      <DropdownItem onClick={handleExportContainer}>
                                        <GhostButton
                                          label={t("data_manager.containers_console.export")}
                                          icon={<DownloadIcon />}
                                          className="mx-0 px-0 ml-n1"
                                        />
                                      </DropdownItem>
                                    </>
                                  )}
                                  {isApiContainer && (
                                    <DropdownItem onClick={handleOpenSettings}>
                                      <GhostButton
                                        label={t("data_manager.containers_console.settings")}
                                        icon={<FontAwesomeIcon icon={faGear} />}
                                        className="mx-0 px-0 ml-n1"
                                      />
                                    </DropdownItem>
                                  )}
                                  <DropdownItem onClick={handleAssignContainers}>
                                    <GhostButton
                                      label={t("data_manager.containers_console.assign")}
                                      icon={<AssignIcon />}
                                      className="mx-0 px-0 ml-n1"
                                    />
                                  </DropdownItem>
                                </DropdownItemGroup>
                                <DropdownItemGroup key={2} hasSeparator>
                                  <DropdownItem onClick={handleDeleteContainers}>
                                    <GhostButton
                                      label={t("data_manager.containers_console.delete")}
                                      icon={<TrashIcon />}
                                      className="mx-0 px-0 ml-n1"
                                      critical
                                    />
                                  </DropdownItem>
                                </DropdownItemGroup>
                              </ContextMenu>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  ))}
                </HotKeys>
                <div className={styles.controls_bottom} />
              </div>
            </div>
            <div className={isDragActive ? styles.drop_wrapper_active : styles.drop_wrapper}>
              <div />
            </div>
          </LoaderOverlay>
        )}
      </Dropzone>

      <Menu id={CONSOLE_MENU_ID} animation={animation.fade} className={styles.console_menu}>
        <Item onClick={() => openCreateContainer(portfolioId, CONTAINER_API)}>
          <GhostButton label={t("data_manager.containers_console.create_blank_api")} icon={<FontAwesomeIcon icon={faPlug} />} />
        </Item>
        <Item>
          <GhostButton
            label={t("data_manager.containers_console.create_blank_file")}
            icon={<FontAwesomeIcon icon={faFileExcel} />}
          />
        </Item>
        <Item onClick={() => openCreateContainer(portfolioId, CONTAINER_MANUAL)}>
          <GhostButton
            label={t("data_manager.containers_console.create_blank_manual")}
            icon={<FontAwesomeIcon icon={faHand} />}
          />
        </Item>
      </Menu>
    </>
  );
};

function mapStateToProps(state) {
  return {
    showLoader: isContainersConsoleLoading(state),
    isEditMode: state.dataManager.isEditMode,
    isPortfolioSelected: getIsPortfolioSelected(state),
    portfolioContainerAssignments: getPortfolioContainerAssignments(state),
    containersInDeletion: state.dataManager.containersInDeletion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainersWrapper);
