import React from "react";
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import history from "common/history";
import { customSelectStyles, inputDefaults, menuDefaults } from "common/styles/selectStyles";
import { ReactComponent as DownIcon } from "img/DownIcon.svg";
import { ReactComponent as UpIcon } from "img/UpIcon.svg";
import { colors } from "common/colors";
import Delimiter from "components/Common/Delimiter";
import { useScreenSize } from "hooks";
import { SelectInput } from "../Common/Inputs";
import { Heading } from "../Common";
import DateLabel from "./DateLabel";

import styles from "../Styles/Dashboard.module.scss";

const enhancedSelectStyles = {
  ...customSelectStyles,
  menu: base => ({
    ...base,
    ...menuDefaults,
    marginTop: 12,
  }),
  input: base => ({
    ...base,
    ...inputDefaults,
    margin: "0 2px",
    padding: 0,
    fontFamily: "archivoheavy",
    fontSize: "36px",
    "& input": {
      font: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  }),
  indicatorsContainer: base => ({
    ...base,
    pointerEvents: "none",
  }),
};

const SingleValue = ({ children, ...props }) => {
  const isOpen = props.selectProps.menuIsOpen;

  return (
    <components.SingleValue {...props} className={cx({ [styles.portfolio_select_input]: isOpen })}>
      <Heading level={2}>{children}</Heading>
    </components.SingleValue>
  );
};

const DropdownIndicator = props => {
  const isOpen = props.selectProps.menuIsOpen;

  return (
    <components.DropdownIndicator {...props}>
      {isOpen ? (
        <UpIcon style={{ height: "24px", width: "24px", color: colors.primary }} />
      ) : (
        <DownIcon style={{ height: "24px", width: "24px", color: colors.black }} />
      )}
    </components.DropdownIndicator>
  );
};

const PortfolioSelect = ({ portfolios, activePortfolio, isFetching }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <SelectInput
      isPlain
      name="portfolio-select"
      id="portfolio-select"
      components={{
        SingleValue,
        DropdownIndicator,
        Option: props => {
          const {
            data: { name, start, end, currency, computationType, isSelected, latestRuleSet, experimentalComputation },
          } = props;

          const PortfolioInfo = () => (
            <span className="mr-2">
              {latestRuleSet?.name} <span className="mx-1">•</span> {computationType} <span className="mx-1">•</span> {currency}{" "}
              <span className="mx-1">•</span> {experimentalComputation ? "Y" : "N"}
            </span>
          );
          const PortfolioRange = () => <DateLabel start={start} end={end} color={isSelected ? "white" : undefined} />;

          const newProps = { ...props, innerProps: { ...props.innerProps, "data-testid": `option_${name}` } };

          return (
            <components.Option {...newProps}>
              <div className={styles.portfolio_select_option}>
                <div className={styles.name}>{name}</div>
                <div className={cx(styles.currency_row, { [styles.flex_space_between]: !isSmall })}>
                  {isSmall ? (
                    <div className="d-flex flex-column">
                      <PortfolioRange />
                      <PortfolioInfo />
                    </div>
                  ) : (
                    <>
                      <PortfolioInfo />
                      <PortfolioRange />
                    </>
                  )}
                </div>
              </div>
            </components.Option>
          );
        },
        Menu: props => (
          <components.Menu {...props}>
            {props.children}
            <Delimiter />
            <div className={styles.ptf_count_label}>
              {t("dashboard.dashboard_header.portfolio_count", { ptfCount: props.options.length })}
            </div>
          </components.Menu>
        ),
      }}
      styles={enhancedSelectStyles}
      value={activePortfolio}
      loadingMessage={() => t("dashboard.dashboard_header.loading")}
      isLoading={isFetching}
      noOptionsMessage={() => t("dashboard.dashboard_header.no_portfolios")}
      options={portfolios}
      getOptionValue={({ id }) => `${id}`}
      getOptionLabel={({ name }) => name}
      onChange={({ id }) => history.push(`/dashboard/${id}`)}
      tabIndex="-1"
      placeholder={t("components.select.placeholder")}
      maxMenuHeight={392}
      // Search only in labels, not values
      filterOption={(candidate, input) => candidate.label.includes(input)}
    />
  );
};

export default PortfolioSelect;
