import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { useTranslation } from "react-i18next";
import { organizationActions } from "actions/organizationActions";
import SetTaxResidencyForm from "containers/Forms/SetTaxResidencyForm";
import { GhostButton } from "components/Common";
import { Link } from "react-router-dom";
import ModalEnvelope from "./ModalEnvelope";

const SetTaxResidencyModal = ({
  organization,
  displayLongMessage,
  modalActions: { hideModal },
  organizationActions: { editOrganizationInfo },
}) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const formattedValues = {
      ...organization,
      taxResidency: values.taxResidency.value,
      taxSubject: values.taxSubject.value,
    };

    await editOrganizationInfo(organization.id, formattedValues);
    hideModal();
  };

  return (
    <ModalEnvelope id="tax-residency-modal" heading={t("modal.tax_residency.heading")} closeIcon={false} disableClose>
      {displayLongMessage ? (
        <>
          <div className="mb-4 mx-1">{t("modal.tax_residency.long_body1")}</div>
          <div className="mb-4 mx-1">{t("modal.tax_residency.long_body2")}</div>
        </>
      ) : (
        <div className="mb-4 mx-1">{t("modal.tax_residency.body")}</div>
      )}

      <SetTaxResidencyForm organization={organization} onSubmit={onSubmit} />

      <div className="d-flex justify-content-center mt-4">
        <Link to={`/organizations/${organization?.id}/tax-rules`}>
          <GhostButton label={t("modal.tax_residency.more_settings")} onClick={hideModal} />
        </Link>
      </div>
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SetTaxResidencyModal);
