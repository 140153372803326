import React from "react";
import { useTranslation } from "react-i18next";
import DynamicTooltip from "./DynamicTooltip";

const AccountsTooltip = props => {
  const { t } = useTranslation();
  return (
    <DynamicTooltip {...props} className="py-3">
      <div>
        <h5>{t("tooltip.dashboard.accounts.heading")}</h5>
        {t("tooltip.dashboard.accounts.label.p1")}
        <div className="mt-3">{t("tooltip.dashboard.accounts.label.p2")}</div>
      </div>
    </DynamicTooltip>
  );
};

export default AccountsTooltip;
