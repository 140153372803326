import React from "react";
import { connect } from "react-redux";
import { userActions } from "actions/userActions";
import { modalActions } from "actions/modalActions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import ApplicationPreferencesForm from "../Forms/ApplicationPreferencesForm";
import ModalEnvelope from "./ModalEnvelope";

const ApplicationPreferencesModal = ({ initialValues, userActions: { editUserPreferences }, modalActions: { hideModal } }) => {
  const onSubmit = values => {
    editUserPreferences(values);
    hideModal();
  };

  const { t } = useTranslation();
  return (
    <ModalEnvelope id="application-preferences-modal" heading={t("modal.application_preferences.heading")}>
      <ApplicationPreferencesForm initialValues={initialValues} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ApplicationPreferencesModal);
