import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { alertActions } from "actions/alertActions";
import { userService } from "services";
import { useTranslation } from "react-i18next";
import FeedbackForm from "../Forms/FeedbackForm";
import ModalEnvelope from "./ModalEnvelope";

const FeedbackModal = ({ modalActions, ...props }) => {
  const { t } = useTranslation();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await userService.postFeedback(values);
      alertActions.success(t("alert.success.feedback_sent"));
      modalActions.hideModal();
      setSubmitting(false);
    } catch (err) {
      alertActions.error(t("alert.error.feedback_not_sent"));
      setSubmitting(false);
    }
  };

  const isBusinessAccountRequest = props.initialValues?.type === "BUSINESS_ACCOUNT";
  return (
    <ModalEnvelope
      id="feedback-form-modal"
      heading={isBusinessAccountRequest ? t("modal.feedback.business_plan.heading") : t("modal.feedback.regular.heading")}>
      <FeedbackForm
        {...props}
        handleSubmit={async (values, formProps) => handleSubmit(values, formProps)}
        isBusinessAccountRequest={isBusinessAccountRequest}
      />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(FeedbackModal);
