import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { components } from "react-select";
import { Edit as EditIcon, Checkmark as CheckIcon, Close as CloseIcon } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { SelectInput } from "components/Common/Inputs";
import { GhostButton, PermissionCheck } from "components/Common";
import { useScreenSize } from "hooks";
import { customBoldSelectStyles } from "common/styles/selectStyles";
import EmptyIcon from "img/jsIcons/EmptyIcon";
import { getPortfoliosToSelect, isContainersConsoleLoading, getActivePortfolioId, getSelectedContainerIds } from "selectors";
import history from "common/history";
import styles from "../../Styles/DataManager.module.scss";

const ContainersHeading = ({
  portfolios,
  selectedPortfolio,
  isEditMode,
  activePortfolioId,
  selectedContainerIds,
  transactionActions: { setEditMode, getTransactions, setPortfolioContainerAssignments },
  modalActions: { openAddPortfolio },
  isContainersConsoleLoading,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <div className="d-flex justify-content-end">
      <div className={styles.datamanager_heading_wrapper} data-tour="datamanager-transactions-portfolio-filter">
        <div className={styles.datamanager_heading}>
          <div className={styles.portfolio_filter_wrapper}>
            <SelectInput
              label={<b>{t("data_manager.portfolio_select.label")}</b>}
              wrapperClassName={styles.portfolio_select}
              styles={customBoldSelectStyles}
              value={selectedPortfolio ? portfolios.find(o => o.value === activePortfolioId) || "" : ""}
              options={portfolios}
              onChange={val => history.push(val ? `/datamanager/containers?portfolioId=${val.value}` : "/datamanager/containers")}
              isInline
              isClearable
              components={{
                Option: ({ children, ...props }) => (
                  <components.Option {...props} className={styles.portfolio_select_option}>
                    <span>{children}</span>
                    {props.data.assignmentCount === 0 && <EmptyIcon />}
                  </components.Option>
                ),
                Menu: props => (
                  <components.Menu {...props}>
                    {props.children}
                    {!isSmall && (
                      <PermissionCheck>
                        <div className={styles.flex_end}>
                          <GhostButton label={t("data_manager.portfolio_select.new_portfolio")} onClick={openAddPortfolio} />
                        </div>
                      </PermissionCheck>
                    )}
                  </components.Menu>
                ),
              }}
              name="active-portfolio"
            />
          </div>
        </div>
        <div className={styles.ptf_button_row}>
          {isEditMode ? (
            <>
              <GhostButton
                label={t("data_manager.heading_action.cancel_assignments")}
                icon={<CloseIcon />}
                onClick={() => {
                  setEditMode(false, activePortfolioId);
                  getTransactions();
                }}
              />
              <GhostButton
                label={t("data_manager.heading_action.save_assignments")}
                icon={<CheckIcon />}
                onClick={async () => {
                  await setPortfolioContainerAssignments(activePortfolioId, selectedContainerIds);
                  getTransactions();
                }}
              />
            </>
          ) : (
            <PermissionCheck>
              <GhostButton
                label={t("data_manager.heading_action.edit_assignments")}
                icon={<EditIcon />}
                disabled={!selectedPortfolio || isEditMode || isContainersConsoleLoading}
                onClick={() => setEditMode(true, activePortfolioId)}
              />
            </PermissionCheck>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedPortfolio: state.dataManager.selectedPortfolio,
    isEditMode: state.dataManager.isEditMode,
    selectedContainerIds: getSelectedContainerIds(state),
    activePortfolioId: getActivePortfolioId(state),
    portfolios: getPortfoliosToSelect(state),
    isContainersConsoleLoading: isContainersConsoleLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainersHeading);
