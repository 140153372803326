import React from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "common/formatters";
import { SMALL } from "common/constants/screenSizes";
import { feeApplicationTypesTranslator } from "common/constants/feeApplicationTypes";
import { Edit as EditIcon } from "grommet-icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { colors } from "common/colors";
import { ACCOUNTING_QUESTION } from "common/constants/feedbackTypes";
import cx from "classnames";
import { cryptoCryptoTaxationTranslator } from "common/constants/cryptoCryptoTaxationTypes";
import { timeTestTranslator } from "common/constants/timeTestTypes";
import history from "common/history";
import Delimiter from "components/Common/Delimiter";
import { rewardTimingTranslator } from "common/constants/rewardTimingOptions";
import { formatRowValue } from "utils";
import FormattedNumber from "components/Common/FormattedNumber";
import CurrencySpan from "../Formating/CurrencySpan";
import { GhostButton, Heading, LoaderOverlay } from "../Common";
import styles from "../Styles/Reports.module.scss";
import { AVCO } from "common/constants/computationTypes";
import { useScreenSize } from "hooks";
import { AccountantIcon } from "img/icons";

const ReportOverview = ({
  portfolio = {},
  balances: { isBalancesFetching, header },
  tradesAndOtherRewards,
  stakingAndRewards,
  portfolioRuleSets,
  activeOrganizationId,
  isReportsParamsFetching,
  openEditPortfolio,
  rawOpenedPortfolio,
  openFeedbackForm,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const realizedProfitCurrency = header?.quote?.name;

  const { currency, computationType, feeApplicationType, latestRuleSet } = portfolio;

  const isFetching = isReportsParamsFetching || isBalancesFetching;

  const isAvco = computationType === AVCO;
  const pairsCount = isAvco ? portfolio?.initialState?.pairStates?.length : portfolio?.initialState?.sourcePortfolioId ? 1 : 0;
  const accountsCount = portfolio?.accountInitialBalances?.balances?.length ?? 0;

  return (
    <div>
      <div data-tour="reports-portfolio-overview">
        <Heading level={3}>{t("reports.overview.portfolio_summary")}</Heading>

        <LoaderOverlay isLoading={isFetching} spinnerSize={SMALL}>
          <Heading level={4} className="mt-3">
            {t("reports.overview.total")}
          </Heading>
          <div className={styles.rpl_row}>
            <div>{t("reports.overview.realized_profit")}</div>
            {!isFetching && (
              <Heading level={3}>
                <CurrencySpan smaller style={{ marginRight: "3px" }} label={realizedProfitCurrency || currency || ""} />
                <FormattedNumber
                  currency={realizedProfitCurrency || currency || ""}
                  {...formatCurrency(header?.realizedProfit ?? 0)}
                />
              </Heading>
            )}
          </div>
          <div className={cx(styles.report_overview, "mt-n1")}>
            <span>{t("reports.overview.tax_base")}</span>
            {!isFetching && (
              <span>
                <CurrencySpan smaller label={realizedProfitCurrency || currency || ""} />{" "}
                <FormattedNumber
                  currency={realizedProfitCurrency || currency || ""}
                  {...formatCurrency(header?.realizedProfitTaxable ?? 0)}
                />
              </span>
            )}
          </div>

          <Delimiter />

          <Heading level={4} className="mt-3">
            {t("reports.overview.trades_and_other_rewards")}
          </Heading>
          <div className={styles.rpl_row}>
            <div>{t("reports.overview.realized_profit")}</div>
            {!isFetching && (
              <Heading level={3}>
                <CurrencySpan smaller style={{ marginRight: "3px" }} label={tradesAndOtherRewards.currency} />
                <FormattedNumber
                  currency={stakingAndRewards.currency}
                  {...formatCurrency(tradesAndOtherRewards.realizedProfit)}
                />
              </Heading>
            )}
          </div>
          <div className={cx(styles.report_overview, "mt-n1")}>
            <span>{t("reports.overview.tax_base")}</span>
            {!isFetching && (
              <span>
                <CurrencySpan smaller label={tradesAndOtherRewards.currency} />{" "}
                <FormattedNumber
                  currency={stakingAndRewards.currency}
                  {...formatCurrency(tradesAndOtherRewards.realizedProfitTaxable)}
                />
              </span>
            )}
          </div>

          <Delimiter />

          <Heading level={4} className="mt-3">
            {t("reports.overview.stake_rewards")}
          </Heading>
          <div className={styles.rpl_row}>
            <div>{t("reports.overview.realized_profit")}</div>
            {!isFetching && (
              <Heading level={3}>
                <CurrencySpan smaller style={{ marginRight: "3px" }} label={stakingAndRewards.currency} />
                <FormattedNumber
                  currency={stakingAndRewards.currency}
                  {...formatCurrency(stakingAndRewards.stakingRewardRealizedProfit)}
                />
              </Heading>
            )}
          </div>
          <div className={cx(styles.report_overview, "mt-n1")}>
            <span>{t("reports.overview.tax_base")}</span>
            {!isFetching && (
              <span>
                <CurrencySpan smaller label={stakingAndRewards.currency} />
                <FormattedNumber
                  currency={stakingAndRewards.currency}
                  {...formatCurrency(stakingAndRewards.stakingRewardRealizedProfitTaxable)}
                />
              </span>
            )}
          </div>

          <Delimiter />
        </LoaderOverlay>

        <GhostButton
          label={t("reports.overview.help")}
          icon={<AccountantIcon className="clear-fill" />}
          className="mt-3 ml-n2 mb-5"
          onClick={() => openFeedbackForm({ type: ACCOUNTING_QUESTION })}
        />
      </div>

      <div data-tour="reports-tax-rules">
        <Heading level={3}>{t("reports.overview.heading")}</Heading>
        <div className={styles.report_overview}>
          <span>{t("reports.overview.currency")}</span>
          <span>{currency}</span>
          <span>{t("reports.download.method")}</span>
          <span>{computationType}</span>
          <span>{t("reports.download.fee_application_type")}</span>
          <span>{t(feeApplicationTypesTranslator(feeApplicationType))}</span>
        </div>
        <Delimiter />
        <GhostButton
          label={t("dashboard.dashboard_header.edit_portfolio")}
          icon={<EditIcon />}
          className="mt-3 mb-5 ml-n2"
          onClick={() => openEditPortfolio(rawOpenedPortfolio)}
        />

        <Heading level={3}>{t("dashboard.dashboard_header.ptf_initial_balances")}</Heading>
        <div className={styles.report_overview}>
          <span>{t("reports.overview.initial_pairs")}</span>
          <span>
            <GhostButton
              onClick={() => openEditPortfolio(rawOpenedPortfolio, undefined, 0, 0)}
              className="d-inline mx-0 px-0"
              labelClassName="mx-n1 px-0"
              critical={pairsCount === 0}
              label={
                <span style={isSmall ? { fontSize: "1rem" } : undefined}>
                  {isAvco ? (
                    <>
                      {pairsCount !== 0 ? `${pairsCount} ` : ""}
                      {pairsCount === 0
                        ? t("dashboard.dashboard_header.pairs_0")
                        : pairsCount === 1
                        ? t("dashboard.dashboard_header.pairs_1")
                        : pairsCount < 5
                        ? t("dashboard.dashboard_header.pairs_2")
                        : t("dashboard.dashboard_header.pairs_5")}
                    </>
                  ) : pairsCount !== 0 ? (
                    t("dashboard.dashboard_header.pairs_copied")
                  ) : (
                    t("dashboard.dashboard_header.pairs_0")
                  )}
                </span>
              }
            />
          </span>
          <span>{t("reports.overview.initial_accounts")}</span>
          <span>
            <GhostButton
              onClick={() => openEditPortfolio(rawOpenedPortfolio, undefined, 0, 1)}
              className="d-inline mx-0 px-0"
              labelClassName="mx-n1 px-0"
              critical={accountsCount === 0}
              label={
                <span style={isSmall ? { fontSize: "1rem" } : undefined}>
                  {accountsCount !== 0 ? `${accountsCount} ` : ""}
                  {accountsCount === 0
                    ? t("dashboard.dashboard_header.accounts_0")
                    : accountsCount === 1
                    ? t("dashboard.dashboard_header.accounts_1")
                    : accountsCount < 5
                    ? t("dashboard.dashboard_header.accounts_2")
                    : t("dashboard.dashboard_header.accounts_5")}
                </span>
              }
            />
          </span>
        </div>
        <Delimiter />
        <GhostButton
          label={t("dashboard.dashboard_header.edit_initial_balances")}
          icon={<EditIcon />}
          className="mt-3 mb-5 ml-n2"
          onClick={() => openEditPortfolio(rawOpenedPortfolio, undefined, 0, 0)}
        />

        <Heading level={3}>{t("organization.tax_rule_sets")}</Heading>
        <div className={styles.report_overview}>
          {portfolioRuleSets.map((x, index) => (
            <React.Fragment key={index}>
              <span>{t("organization.rule_set")}</span>
              <span>{x.name}</span>
              <span>{t("organization.taxed_crypto")}</span>
              <span>{t(cryptoCryptoTaxationTranslator(x.cryptoCryptoTaxation))}</span>
              <span>{t("organization.time_test")}</span>
              <span>{t(timeTestTranslator(x.timeTest))}</span>
            </React.Fragment>
          ))}
        </div>
        <Delimiter />
        <GhostButton
          label={t("dashboard.dashboard_header.edit_tax_rules")}
          icon={<EditIcon />}
          className="mt-3 mb-5 ml-n2"
          onClick={() => history.push(`/organizations/${activeOrganizationId}/tax-rules`)}
        />
      </div>

      <Heading level={3}>{t("reports.overview.taxation_of_rewards")}</Heading>
      <div className={styles.report_overview}>
        <span>{t("organization.stake_reward")}</span>
        <span>{formatRowValue(t(rewardTimingTranslator(latestRuleSet?.realizationTypes?.STAKING_REWARD)))}</span>
        <span>{t("organization.reward")}</span>
        <span>{formatRowValue(t(rewardTimingTranslator(latestRuleSet?.realizationTypes?.STAKING_REWARD)))}</span>
        <span>{t("organization.airdrop")}</span>
        <span>{formatRowValue(t(rewardTimingTranslator(latestRuleSet?.realizationTypes?.STAKING_REWARD)))}</span>
        <span>{t("organization.earn")}</span>
        <span>{formatRowValue(t(rewardTimingTranslator(latestRuleSet?.realizationTypes?.STAKING_REWARD)))}</span>
        <span>{t("organization.fork")}</span>
        <span>{formatRowValue(t(rewardTimingTranslator(latestRuleSet?.realizationTypes?.STAKING_REWARD)))}</span>
      </div>
      <Delimiter />
      <GhostButton
        label={t("dashboard.dashboard_header.edit_rewards_taxation")}
        icon={<EditIcon />}
        className="mt-3 mb-1 ml-n2"
        onClick={() => history.push(`/organizations/${activeOrganizationId}/tax-rules`)}
      />
    </div>
  );
};

export default ReportOverview;
