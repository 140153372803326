import React from "react";
import Hana from "img/hanaKocourkova.jpg";
import Jan from "img/janKrajca.jpg";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import CustomerReview from "./CustomerReview";
import { Heading } from "../Common";

import styles from "../Styles/Landing.module.scss";

export default ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.reviews, className)}>
      <Heading level={3} textAlign="center" margin="0 auto 1rem">
        {t("landing.reviews.heading")}
      </Heading>
      <div className={styles.reviews_list}>
        <CustomerReview
          name="Hana Kocourková"
          link="www.danehned.cz"
          href="https://www.danehned.cz/"
          review={t("landing.reviews.hana")}
          picture={Hana}
        />
        <CustomerReview
          name="Jan Krajča"
          link="www.bitcoinmat.sk"
          href="https://www.bitcoinmat.sk/"
          review={t("landing.reviews.jan")}
          picture={Jan}
        />
        {/* <CustomerReview
          name='Jan Krajča'
          link='www.bitcoinmat.sk'
          href='https://www.bitcoinmat.sk/'
          review='WhaleBooks allows us to import all our trades into one platform. It gives us a better overview of our business with the perfect output for our accountant. The team is reliable and very responsive'
          picture={Jan}
        /> */}
      </div>
    </div>
  );
};
