import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { GhostButton, Form, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { taxResidencyOptions } from "common/constants/taxResidencies";
import { object } from "yup";
import { taxSubjectOptions } from "common/constants/taxSubjects";
import styles from "../Styles/Forms.module.scss";

const EditTaxRulesForm = ({ organization, onSubmit, hideModal }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => ({
      taxResidencyOptions: taxResidencyOptions(t),
      taxSubjectOptions: taxSubjectOptions(t),
    }),
    [t]
  );

  return (
    <Formik
      initialValues={{
        taxResidency: options.taxResidencyOptions.find(x => x.value === organization?.taxResidency) ?? "",
        taxSubject: options.taxSubjectOptions.find(x => x.value === organization?.taxSubject) ?? "",
      }}
      validationSchema={object({
        taxResidency: object().required(),
        taxSubject: object().required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          <SelectFormField
            options={options.taxResidencyOptions}
            name="taxResidency"
            label={t("form.tax_residency.tax_residency")}
            required
            disabled={isSubmitting}
          />
          <SelectFormField
            name="taxSubject"
            options={options.taxSubjectOptions}
            label={t("form.tax_residency.tax_subject")}
            disabled={isSubmitting}
            required
          />

          <div className="w-100 d-flex align-items-center justify-content-end mt-4">
            <GhostButton label={t("button.cancel")} onClick={hideModal} />
            <PrimaryButton label={t("button.update")} isLoading={isSubmitting} onClick={handleSubmit} noIcon className="w-auto" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditTaxRulesForm;
