import React from "react";
import { useField } from "formik";
import cx from "classnames";
import { LabelRow, InputWrapper } from "./Shared";

import styles from "./Inputs.module.scss";

const CustomInput = ({ label, error, required, isInline, wrapperClassName, children, ...props }) => (
  <InputWrapper className={cx(styles.custom_input, wrapperClassName)} inline={isInline} error={error} {...props}>
    <span style={{ cursor: "default" }}>
      <LabelRow label={label} error={error} required={required} />
    </span>
    {children}
  </InputWrapper>
);

export const CustomFormField = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  return <CustomInput {...field} {...props} error={meta.touched ? meta.error : ""} />;
};

export default CustomInput;
