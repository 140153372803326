import {
  ACCOUNTANT_DETAIL,
  ADD_ACCOUNT_ADDRESS_MODAL,
  ADD_TRANSACTION,
  ADD_TRANSACTIONS_TO_BUCKET,
  APPLICATION_PREFERENCES,
  ASSIGN_CONTAINERS,
  BASE_QUOTE_MODAL,
  BUCKET_DETAIL_MODAL,
  BULK_TX_SET_LABEL,
  BULK_TX_SET_PRICE,
  CHANGE_PASSWORD_MODAL,
  CHECKOUT,
  CONNECTION_CREDENTIALS,
  CONTAINER_DETAIL_MODAL,
  COPY_ACCOUNT_BALANCES_MODAL,
  COUNTER_TRANSACTIONS_MODAL,
  CREATE_ACCOUNT_MODAL,
  CREATE_CONTAINER,
  DELETE_TRANSACTION_FROM_BUCKET,
  DOWNLOAD_ACCOUNTS_STATEMENT,
  DOWNLOAD_ADMIN_REPORT,
  EDIT_ASSET_ACCOUNT_MODAL,
  EDIT_CHART_ACCOUNT_ACCOUNT,
  EDIT_CHART_ACCOUNT_SUB_ACCOUNT,
  EDIT_CONTAINER_DETAIL_MODAL,
  EDIT_REWARDS_MODAL,
  EDIT_TAX_RULES_MODAL,
  EDIT_TRANSACTION,
  EMAIL_SUBSCRIPTION,
  FEEDBACK_FORM_MODAL,
  LOGIN_MODAL,
  EDIT_ACCOUNT_SETTINGS_MODAL,
  OPEN_ORGANIZATION_DETAIL,
  OPEN_PORTFOLIO_DETAIL,
  OPEN_RULE_SET_DETAIL,
  ORGANIZATION_TRIAL_PERIOD,
  ORGANIZATION_USER,
  REBRANDING_WELCOME_MODAL,
  SEARCH_FOR_ACCOUNTANT,
  SELECT_PORTFOLIO_AVCO_PREDECESSOR,
  SELECT_PORTFOLIO_FIFO_PREDECESSOR,
  SELECT_USER_CATEGORY,
  DOWNLOAD_ACCOUNTING_REPORT,
  SET_ADDRESS_FOR_TRANSACTIONS,
  SET_TAX_RESIDENCY,
  SHARE_PORTFOLIO,
  WALKTHROUGH,
  REFERENCE_DETAIL_MODAL,
  WALKTHROUGH_START_MODAL,
} from "actions/modalTypes";
import { mapTransaction } from "utils";
import { HIDE_MODAL, OPEN_MODAL, TOGGLE_TOOLTIPS } from "./types";
import { bucketActions } from "./bucketActions";

function openLoginModal() {
  return {
    type: OPEN_MODAL,
    modalType: LOGIN_MODAL,
  };
}
function openChangePasswordModal() {
  return {
    type: OPEN_MODAL,
    modalType: CHANGE_PASSWORD_MODAL,
  };
}

function openAddPortfolio(portfolio = {}, reopenValues, activeTab, activeSubTab) {
  return {
    type: OPEN_MODAL,
    modalType: OPEN_PORTFOLIO_DETAIL,
    modalProps: {
      initialValues: portfolio,
      isEdit: false,
      reopenValues,
      activeTab,
      activeSubTab,
    },
  };
}

function openEditPortfolio(portfolio = {}, reopenValues, activeTab, activeSubTab) {
  return {
    type: OPEN_MODAL,
    modalType: OPEN_PORTFOLIO_DETAIL,
    modalProps: {
      initialValues: portfolio,
      isEdit: true,
      reopenValues,
      activeTab,
      activeSubTab,
    },
  };
}

function openSharePortfolio(portfolioId) {
  return {
    type: OPEN_MODAL,
    modalType: SHARE_PORTFOLIO,
    modalProps: { portfolioId },
  };
}

function openAddTransaction({ exchangeName, id, name }) {
  return {
    type: OPEN_MODAL,
    modalType: ADD_TRANSACTION,
    modalProps: {
      exchangeName,
      containerId: id,
      containerName: name,
    },
  };
}

function openEditTransaction(transaction, onClose, isTransfer, isReopen, modalOptions) {
  return (dispatch, getState) => {
    if (!isReopen && getState().buckets.counterTransactions.length === 0 && transaction.linkedTxs?.length > 0) {
      dispatch(bucketActions.setCounterTransactions(transaction.linkedTxs?.map(x => mapTransaction(x))));
    }

    return dispatch({
      type: OPEN_MODAL,
      modalType: EDIT_TRANSACTION,
      modalProps: {
        transaction,
        onClose,
        isTransfer,
        modalOptions,
      },
    });
  };
}

function openAddSubtransaction(transaction) {
  return {
    type: OPEN_MODAL,
    modalType: ADD_TRANSACTION,
    modalProps: { originalTransaction: transaction, isSubtransaction: true },
  };
}

function openCreateContainer(portfolioId, containerType) {
  return {
    type: OPEN_MODAL,
    modalType: CREATE_CONTAINER,
    modalProps: {
      portfolioId,
      containerType,
    },
  };
}

function openConnectionCredentialsModal(dataContainerId, connection, connector, onClose) {
  return {
    type: OPEN_MODAL,
    modalType: CONNECTION_CREDENTIALS,
    modalProps: {
      dataContainerId,
      connection,
      connector,
      onClose,
    },
  };
}

function openContainerDetail(containerId, containerType, activeTab, openConnectorSettings) {
  return {
    type: OPEN_MODAL,
    modalType: CONTAINER_DETAIL_MODAL,
    modalProps: { containerId, containerType, activeTab, openConnectorSettings },
  };
}

function openEditContainerDetail(containerId, onClose) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_CONTAINER_DETAIL_MODAL,
    modalProps: { containerId, onClose },
  };
}

function openFeedbackForm(initialValues) {
  return {
    type: OPEN_MODAL,
    modalType: FEEDBACK_FORM_MODAL,
    modalProps: { initialValues },
  };
}

function openSelectUserCategory(props) {
  return {
    type: OPEN_MODAL,
    modalType: SELECT_USER_CATEGORY,
    modalProps: props,
  };
}

function openSelectPortfolioAvcoPredecessor(onClose, isEdit, targetPortfolio, currentSourcePortfolioId) {
  return {
    type: OPEN_MODAL,
    modalType: SELECT_PORTFOLIO_AVCO_PREDECESSOR,
    modalProps: { onClose, isEdit, targetPortfolio, currentSourcePortfolioId },
  };
}

function openSelectPortfolioFifoPredecessor(onClose, isEdit, initialValues, computationType, targetPortfolio) {
  return {
    type: OPEN_MODAL,
    modalType: SELECT_PORTFOLIO_FIFO_PREDECESSOR,
    modalProps: { onClose, isEdit, initialValues, computationType, targetPortfolio },
  };
}

function openCopyAccountBalances(onClose, isEdit, accountOptions, targetPortfolio, currentSourcePortfolioId) {
  return {
    type: OPEN_MODAL,
    modalType: COPY_ACCOUNT_BALANCES_MODAL,
    modalProps: { onClose, isEdit, accountOptions, targetPortfolio, currentSourcePortfolioId },
  };
}

function openAssignContainers(portfolioId, transactionContainerIds) {
  return {
    type: OPEN_MODAL,
    modalType: ASSIGN_CONTAINERS,
    modalProps: {
      portfolioId,
      transactionContainerIds,
    },
  };
}

function openWalkthrough() {
  return {
    type: OPEN_MODAL,
    modalType: WALKTHROUGH,
  };
}

function openBaseQuote(base, quote) {
  return {
    type: OPEN_MODAL,
    modalType: BASE_QUOTE_MODAL,
    modalProps: {
      base,
      quote,
    },
  };
}

function openCheckout(type, organizationId) {
  return {
    type: OPEN_MODAL,
    modalType: CHECKOUT,
    modalProps: { type, organizationId },
  };
}

function openMailSubscription(onClose) {
  return {
    type: OPEN_MODAL,
    modalType: EMAIL_SUBSCRIPTION,
    modalProps: { onClose },
  };
}

function openOrganizationUser(modalProps) {
  return {
    type: OPEN_MODAL,
    modalType: ORGANIZATION_USER,
    modalProps,
  };
}

function openOrganizationTrialPeriod(modalProps) {
  return {
    type: OPEN_MODAL,
    modalType: ORGANIZATION_TRIAL_PERIOD,
    modalProps,
  };
}

function openApplicationPreferences(modalProps) {
  return {
    type: OPEN_MODAL,
    modalType: APPLICATION_PREFERENCES,
    modalProps,
  };
}

function openBulkTxSetPrice(containers) {
  return {
    type: OPEN_MODAL,
    modalType: BULK_TX_SET_PRICE,
    modalProps: {
      containers,
    },
  };
}

function openBulkTxSetLabel(containers, labels) {
  return {
    type: OPEN_MODAL,
    modalType: BULK_TX_SET_LABEL,
    modalProps: {
      containers,
      labels,
    },
  };
}

function openAccountantDetail(modalProps) {
  return {
    type: OPEN_MODAL,
    modalType: ACCOUNTANT_DETAIL,
    modalProps,
  };
}

function openSearchForAccountant(modalProps) {
  return {
    type: OPEN_MODAL,
    modalType: SEARCH_FOR_ACCOUNTANT,
    modalProps,
  };
}

function openDownloadAdminReport() {
  return {
    type: OPEN_MODAL,
    modalType: DOWNLOAD_ADMIN_REPORT,
  };
}

function openCreateAccount(onClose) {
  return {
    type: OPEN_MODAL,
    modalType: CREATE_ACCOUNT_MODAL,
    modalProps: {
      onClose,
    },
  };
}

function openEditAssetAccount(accountId) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_ASSET_ACCOUNT_MODAL,
    modalProps: {
      accountId,
    },
  };
}

function openAddAccountAddress() {
  return {
    type: OPEN_MODAL,
    modalType: ADD_ACCOUNT_ADDRESS_MODAL,
  };
}

function openAddTransactionsToBucket(transactionIds) {
  return {
    type: OPEN_MODAL,
    modalType: ADD_TRANSACTIONS_TO_BUCKET,
    modalProps: {
      transactionIds,
    },
  };
}

function openDeleteTransactionsFromBucket(transactionIds) {
  return {
    type: OPEN_MODAL,
    modalType: DELETE_TRANSACTION_FROM_BUCKET,
    modalProps: {
      transactionIds,
    },
  };
}

function openBucketDetail(bucket, isEdit) {
  return {
    type: OPEN_MODAL,
    modalType: BUCKET_DETAIL_MODAL,
    modalProps: {
      bucket,
      isEdit,
    },
  };
}

function openSelectCounterTransactions(onClose, mainTransaction) {
  return {
    type: OPEN_MODAL,
    modalType: COUNTER_TRANSACTIONS_MODAL,
    modalProps: {
      onClose,
      mainTransaction,
    },
  };
}

function openSetAddressForTransactions(transactions) {
  return {
    type: OPEN_MODAL,
    modalType: SET_ADDRESS_FOR_TRANSACTIONS,
    modalProps: {
      transactions,
    },
  };
}

function openDownloadAccountsStatement(onSubmit, recordGA) {
  return {
    type: OPEN_MODAL,
    modalType: DOWNLOAD_ACCOUNTS_STATEMENT,
    modalProps: {
      onSubmit,
      recordGA,
    },
  };
}

function openOrganizationDetail(organizationData) {
  return {
    type: OPEN_MODAL,
    modalType: OPEN_ORGANIZATION_DETAIL,
    modalProps: {
      organization: organizationData,
    },
  };
}

function openEditAssetAccountAccount(organizationId, account, allAccountNumbers) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_CHART_ACCOUNT_ACCOUNT,
    modalProps: {
      organizationId,
      account,
      allAccountNumbers,
    },
  };
}

function openEditAssetAccountSubAccount(organizationId, subAccount, allSubAccountNumbers) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_CHART_ACCOUNT_SUB_ACCOUNT,
    modalProps: {
      organizationId,
      subAccount,
      allSubAccountNumbers,
    },
  };
}

function openDownloadAccountingReport(from, to, onDownload) {
  return {
    type: OPEN_MODAL,
    modalType: DOWNLOAD_ACCOUNTING_REPORT,
    modalProps: {
      from,
      to,
      onDownload,
    },
  };
}

function openSetTaxResidency(organization, displayLongMessage = true) {
  return {
    type: OPEN_MODAL,
    modalType: SET_TAX_RESIDENCY,
    modalProps: {
      organization,
      displayLongMessage,
    },
  };
}

function openEditTaxRulesModal(organization) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_TAX_RULES_MODAL,
    modalProps: {
      organization,
    },
  };
}

function openRuleSetDetailModal(organization, ruleSet, setActiveRuleSet) {
  return {
    type: OPEN_MODAL,
    modalType: OPEN_RULE_SET_DETAIL,
    modalProps: {
      organization,
      ruleSet,
      setActiveRuleSet,
    },
  };
}

function openRebrandingWelcomeModal(onClose) {
  return {
    type: OPEN_MODAL,
    modalType: REBRANDING_WELCOME_MODAL,
    modalProps: {
      onClose,
    },
  };
}

function openEditRewardsModal(organizationData, ruleSet, setActiveRuleSet) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_REWARDS_MODAL,
    modalProps: {
      organizationData,
      ruleSet,
      setActiveRuleSet,
    },
  };
}

function openAccountSettingsModal(organizationId, settings) {
  return {
    type: OPEN_MODAL,
    modalType: EDIT_ACCOUNT_SETTINGS_MODAL,
    modalProps: {
      organizationId,
      settings,
    },
  };
}

function openReferenceDetailModal(referenceDetail, onClose) {
  return {
    type: OPEN_MODAL,
    modalType: REFERENCE_DETAIL_MODAL,
    modalProps: {
      referenceDetail,
      onClose,
    },
  };
}

function openWalkthroughStartModal() {
  return {
    type: OPEN_MODAL,
    modalType: WALKTHROUGH_START_MODAL,
  };
}

function hideModal(modalType) {
  return (dispatch, getState) => {
    if (getState().modal.tooltipsDisplayed) {
      dispatch(toggleTooltips());
    }

    dispatch({
      type: HIDE_MODAL,
      modalType,
    });
  };
}

function toggleTooltips() {
  return {
    type: TOGGLE_TOOLTIPS,
  };
}

export const modalActions = {
  openLoginModal,
  openChangePasswordModal,
  openAddPortfolio,
  openAddTransaction,
  openEditTransaction,
  openAddSubtransaction,
  openEditPortfolio,
  openCreateContainer,
  openConnectionCredentialsModal,
  openContainerDetail,
  openEditContainerDetail,
  openFeedbackForm,
  openSelectPortfolioAvcoPredecessor,
  openSelectPortfolioFifoPredecessor,
  openCopyAccountBalances,
  openWalkthrough,
  openBaseQuote,
  openSharePortfolio,
  openCheckout,
  openAssignContainers,
  openMailSubscription,
  hideModal,
  toggleTooltips,
  openOrganizationUser,
  openApplicationPreferences,
  openSelectUserCategory,
  openOrganizationTrialPeriod,
  openBulkTxSetPrice,
  openBulkTxSetLabel,
  openAccountantDetail,
  openSearchForAccountant,
  openDownloadAdminReport,
  openCreateAccount,
  openEditAssetAccount,
  openAddAccountAddress,
  openAddTransactionsToBucket,
  openDeleteTransactionsFromBucket,
  openBucketDetail,
  openSelectCounterTransactions,
  openSetAddressForTransactions,
  openDownloadAccountsStatement,
  openOrganizationDetail,
  openEditAssetAccountAccount,
  openEditAssetAccountSubAccount,
  openDownloadAccountingReport,
  openSetTaxResidency,
  openEditTaxRulesModal,
  openRuleSetDetailModal,
  openRebrandingWelcomeModal,
  openEditRewardsModal,
  openAccountSettingsModal,
  openReferenceDetailModal,
  openWalkthroughStartModal,
};
