import { transactionService } from "services";
import history from "common/history";
import i18n from "i18n";
import {
  CONTAINER_UPLOAD_INITIAL_REQUEST,
  UPDATE_UPLOAD_INFO,
  CONTAINER_UPLOADED,
  CONTAINER_EMPHASIZED,
  CONTAINER_UPLOADED_FAILED,
} from "./types";

import { alertActions } from "./alertActions";
import { jobActions } from "./jobActions";
import { transactionActions } from "./transactionActions";

const FILE_PART_SIZE = 256 * 1024;

export function uploadBigContainer(container, portfolioId) {
  return async (dispatch, getState) => {
    const partsCount = Math.ceil(container.size / FILE_PART_SIZE);
    const partSize = Math.floor(container.size / partsCount);

    const res = await dispatch(initialUploadRequest(container.name, container.size, partsCount, portfolioId));
    if (!res || !res.value || !res.value.id) return;
    if (container.size > 100000) alertActions.info(i18n.t("alert.info.file_upload_started"));
    const { id } = res.value;
    let start = 0;
    let blob;
    for (let i = 0; i < partsCount; i++) {
      if (getState().dataManager.containers.find(o => o.id === id) === undefined) break; // container upload canceled

      blob = container.slice(start, i === partsCount - 1 ? container.size : start + partSize);
      const data = await readFilePart(blob);
      if (data === false) {
        alertActions.error(
          i18n.t("alert.error.file_upload_failed", { fileName: container.name }),
          "/help/csv",
          i18n.t("alert.error.file_upload_failed_help_link")
        );
        dispatch(containerUploadedFail(id));
        break;
      }

      const onProgress = partPercents => dispatch(updateUploadInfo(id, partsCount, i, partPercents));
      const success = await dispatch(filePartUpload(id, data, i, onProgress, i === partsCount - 1, portfolioId));
      if (!success) {
        alertActions.error(
          i18n.t("alert.error.file_upload_failed", { fileName: container.name }),
          "/help/csv",
          i18n.t("alert.error.file_upload_failed_help_link")
        );
        dispatch(containerUploadedFail(id));
        return;
      }
      start += partSize;
    }
  };
}

function initialUploadRequest(containerName, containerSize, partsCount, portfolioId) {
  return (dispatch, getState) =>
    dispatch({
      type: CONTAINER_UPLOAD_INITIAL_REQUEST,
      payload: {
        promise: async () => {
          const { id } = await transactionService.initialUploadRequest(
            containerName,
            containerSize,
            partsCount,
            portfolioId,
            getState().user
          );
          return {
            id,
            containerName,
          };
        },
      },
      meta: { portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function filePartUpload(id, data, partNumber, onProgress, isLastPart, portfolioId) {
  return async (dispatch, getState) => {
    try {
      const result = await transactionService.uploadContainerPart(id, data, partNumber, getState().user, onProgress);
      if (isLastPart)
        setTimeout(() => {
          dispatch(containerUploaded(result, id, portfolioId));
          dispatch(
            jobActions.monitorJobProgress(
              { transactionContainerId: result.id },
              () => {
                dispatch(transactionActions.getContainerDetail(result.id));
                dispatch(containerProcessed(result));
              },
              () => {
                dispatch(transactionActions.getContainerDetail(result.id));
                dispatch(containerProcessedFail(result));
              }
            )
          );
        }, 1000);
      return true;
    } catch (err) {
      dispatch(containerUploadedFail(id));
      return false;
    }
  };
}

function updateUploadInfo(transactionContainerId, partsCount, i, partPercents) {
  const percent = (100 / partsCount) * i + (100 / partsCount) * partPercents;
  return {
    type: UPDATE_UPLOAD_INFO,
    payload: {
      transactionContainerId,
      percentUploaded: percent,
    },
  };
}

function containerUploaded(container, id, portfolioId) {
  return {
    type: CONTAINER_UPLOADED,
    payload: {
      container,
      oldContainerId: id,
    },
    meta: { portfolioId },
  };
}

function containerUploadedFail(id) {
  return {
    type: CONTAINER_UPLOADED_FAILED,
    payload: id,
  };
}

function emphasizeContainer(id, emphasize) {
  return {
    type: CONTAINER_EMPHASIZED,
    payload: {
      id,
      emphasized: emphasize,
    },
  };
}

function containerProcessed(container) {
  return dispatch => {
    if (history.location.pathname.startsWith("/datamanager/containers")) {
      dispatch(transactionActions.getTransactions());
      alertActions.success(i18n.t("alert.success.file_uploaded", { fileName: container.displayedFilename }), {
        onMouseEnter: () => dispatch(emphasizeContainer(container.id, true)),
        onMouseLeave: () => dispatch(emphasizeContainer(container.id, false)),
        onClose: () => dispatch(jobActions.deleteContainerJob(container.id)),
      });
    } else {
      alertActions.success(i18n.t("alert.success.file_uploaded", { fileName: container.displayedFilename }), {
        linkTo: "/datamanager/containers",
        linkMessage: "View in datamanager",
        onMouseEnter: () => dispatch(emphasizeContainer(container.id, true)),
        onMouseLeave: () => dispatch(emphasizeContainer(container.id, false)),
        onClose: () => dispatch(jobActions.deleteContainerJob(container.id)),
      });
    }
  };
}

function containerProcessedFail(container) {
  return dispatch => {
    if (history.location.pathname.startsWith("/datamanager/containers")) {
      alertActions.warning(i18n.t("alert.warning.file_uploaded_with_fail", { fileName: container.displayedFilename }), {
        onMouseEnter: () => dispatch(emphasizeContainer(container.id, true)),
        onMouseLeave: () => dispatch(emphasizeContainer(container.id, false)),
        onClose: () => dispatch(jobActions.deleteContainerJob(container.id)),
      });
    } else {
      alertActions.warning(i18n.t("alert.warning.file_uploaded_with_fail", { fileName: container.displayedFilename }), {
        linkTo: "/datamanager/containers",
        linkMessage: "View in datamanager",
        onMouseEnter: () => dispatch(emphasizeContainer(container.id, true)),
        onMouseLeave: () => dispatch(emphasizeContainer(container.id, false)),
        onClose: () => dispatch(jobActions.deleteContainerJob(container.id)),
      });
    }
  };
}

function readFilePart(blob) {
  return new Promise((resolve, _reject) => {
    const fr = new FileReader();
    fr.onloadend = () => {
      if (fr.result === null) {
        resolve(false);
        return;
      }
      const dataStartIndex = fr.result.indexOf("base64,");
      if (dataStartIndex > -1) resolve(fr.result.substring(dataStartIndex + 7));
      resolve(fr.result);
    };
    fr.readAsDataURL(blob);
  });
}
