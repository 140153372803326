import React from "react";
import { connect } from "react-redux";
import { organizationActions } from "actions/organizationActions";
import { modalActions } from "actions/modalActions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import OrganizationUserForm from "../Forms/OrganizationUser";
import ModalEnvelope from "./ModalEnvelope";

const OrganizationUserModal = ({
  initialValues,
  organizationId,
  organizationActions: { addOrganizationUser, editOrganizationUser },
  modalActions: { hideModal },
}) => {
  const { t } = useTranslation();

  const onSubmit = async ({ id, email, isInvite, ...values }, { setSubmitting }) => {
    const result = await (initialValues
      ? editOrganizationUser(organizationId, id, {
          isInvite,
          role: values.role.value,
          position: values.position.value,
        })
      : addOrganizationUser(organizationId, {
          email,
          role: values.role.value,
          position: values.position.value,
        }));

    setSubmitting(false);

    if (result?.action?.type?.endsWith("_FULFILLED")) hideModal();
  };

  return (
    <ModalEnvelope
      id="organization-user-modal"
      heading={initialValues ? t("modal.organization_user.heading.edit") : t("modal.organization_user.heading.add")}>
      <OrganizationUserForm initialValues={initialValues} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(OrganizationUserModal);
