import React, { useCallback } from "react";
import { useTable } from "react-table";
import cx from "classnames";
import { Down as DownIcon, Filter as FilterIcon, Up as UpIcon } from "grommet-icons";
import PermissionCheck from "./PermissionCheck";
import styles from "./Components.module.scss";
import useStickyHeader from "hooks/useStickyHeader";
import { useScreenSize } from "hooks";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { getTopOffset } from "utils";

export const ActionCell = ({ children, className, style, ...props }) => (
  <div className={cx(styles.action_cell_wrapper, className)} style={style}>
    <PermissionCheck {...props}>
      <div className={styles.action_cell}>{children}</div>
    </PermissionCheck>
  </div>
);

export const SortableCell = ({ children, column, sortedBy, setSortedBy }) => {
  const isSelected = sortedBy?.id === column.id;
  return (
    <div
      className={styles.sort_filter_cell}
      onClick={() => setSortedBy({ id: column.id, order: sortedBy.order === "asc" ? "desc" : "asc" })}>
      {children}
      <div>
        <UpIcon color={isSelected && sortedBy.order === "asc" ? "icon" : "greyBorder"} />
        <DownIcon color={isSelected && sortedBy.order === "desc" ? "icon" : "greyBorder"} />
      </div>
    </div>
  );
};

export const FilterableCell = ({ children, column, filteredBy, onClick }) => (
  <div className={styles.sort_filter_cell} onClick={onClick}>
    {children}
    <div>
      <FilterIcon color={filteredBy[column.id] ? "icon" : "greyBorder"} />
    </div>
  </div>
);

const Table = ({
  columns = [],
  data = [],
  onRowClick,
  sortedBy,
  setSortedBy,
  filteredBy,
  setFilteredBy,
  getRowId,
  className,
  fixedLayout,
  style,
  dataTour,
  minWidth,
  stickyHeader,
  calculateWiderBorder,
}) => {
  const { isMedium, isSmall } = useScreenSize();

  const getRowIdFn = useCallback(getRowId || (row => row.id), [getRowId]);

  const tableInstance = useTable({
    columns,
    data,
    getRowId: getRowIdFn,
    sortedBy,
    setSortedBy,
    filteredBy,
    setFilteredBy,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const isClickable = onRowClick && typeof onRowClick === "function";

  // If normal table header is out of screen, returns isSticky true to display fixed header
  const { tableRef, isSticky } = useStickyHeader(false);

  const renderHeader = () => (
    <thead className={styles.table_header}>
      {headerGroups.map(headerGroup => (
        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps({ className: cx(column.className, { [styles.sticky_col_head]: column.sticky }) })}
              style={{ width: column.width }}>
              {column.render("Header")}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );

  return (
    <ScrollSync>
      <>
        {stickyHeader && isSticky && (
          <ScrollSyncPane>
            <div
              className={cx(styles.table_wrapper, className, styles.table_fixed_header_wrapper, "hide-scrollbars")}
              style={{
                position: "fixed",
                top: `${getTopOffset() - 8}px`,
                left: isSmall ? "var(--mobile-padding)" : isMedium ? "var(--desktop-padding)" : "80px",
                right: isSmall ? "var(--mobile-padding)" : isMedium ? "var(--desktop-padding)" : "80px",
                backgroundColor: "white",
                zIndex: 3,
              }}>
              <table
                {...getTableProps()}
                className="sticky"
                style={{
                  tableLayout: "fixed",
                  ...style,
                  minWidth,
                }}>
                {renderHeader()}
              </table>
            </div>
          </ScrollSyncPane>
        )}

        <ScrollSyncPane>
          <div className={cx(styles.table_wrapper, className)} data-tour={dataTour}>
            <table
              {...getTableProps()}
              style={{ ...style, minWidth, tableLayout: fixedLayout || stickyHeader ? "fixed" : undefined }}
              ref={tableRef}>
              {renderHeader()}
              <tbody {...getTableBodyProps()} className={styles.table_body}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  const onClick = isClickable ? () => onRowClick(row.original) : null;

                  return (
                    <tr
                      key={row.id}
                      className={cx({
                        [styles.clickable_row]: onClick,
                      })}
                      onClick={onClick}
                      style={{ borderWidth: calculateWiderBorder ? calculateWiderBorder(row.original, index) : undefined }}
                      {...row.getRowProps()}>
                      {row.cells.map((cell, index) => (
                        <td
                          key={index}
                          {...cell.getCellProps()}
                          style={{
                            width: cell.column.width,
                            ...(stickyHeader ? { wordBreak: "break-word", wordWrap: "break-word", whiteSpace: "pre-line" } : {}),
                          }}
                          className={cx({ [styles.sticky_col_body]: cell.column.sticky })}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </ScrollSyncPane>
      </>
    </ScrollSync>
  );
};

export default Table;
