import React from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import AddTransactionExplanation from "components/Modals/ModalExplanations/AddTransactionExplanation";
import { WIDE } from "common/constants/modalWidths";
import { isTxTypeFeeRebate } from "common/constants/transactionType";
import { bucketActions } from "actions/bucketActions";
import { getLabels, getTransaction } from "queries/transactions";
import { LoaderOverlay } from "components/Common";
import { modalActions } from "actions/modalActions";
import EditFeeRebateTransactionForm from "../Forms/Transactions/EditFeeRebateTransactionForm";
import EditTransactionForm from "../Forms/Transactions/EditTransactionForm";
import ModalEnvelope from "./ModalEnvelope";

const EditTransactionModal = ({
  transaction,
  onClose,
  isTransfer,
  modalOptions,
  user,
  bucketActions: { setCounterTransactions },
  modalActions: { openEditTransaction },
}) => {
  const { transactionContainerId, id, mainTransactionId, ...rawTransaction } = transaction;
  const { t } = useTranslation();

  const isFeeOrRebate = isTxTypeFeeRebate(transaction.type);

  const labelsQuery = useQuery(getLabels(user));
  const labelOptions = labelsQuery?.data ?? [];

  const mainTransactionQuery = useQuery(
    getTransaction({ txId: mainTransactionId, containerId: transactionContainerId }, user, !!mainTransactionId)
  );

  const handleOpenEditMainTransaction = () => openEditTransaction(mainTransactionQuery.data);

  return (
    <ModalEnvelope
      id="edit-transaction-modal"
      heading={t("modal.add_transaction.heading_edit_transaction")}
      description={<AddTransactionExplanation />}
      width={WIDE}
      onClose={() => {
        setCounterTransactions([]);
        if (onClose && typeof onClose === "function") onClose();
      }}>
      <LoaderOverlay isLoading={labelsQuery.isLoading || mainTransactionQuery.isFetching}>
        {isFeeOrRebate ? (
          <>
            <EditFeeRebateTransactionForm
              transaction={transaction}
              transactionContainerId={transactionContainerId}
              transactionId={id}
              labelOptions={labelOptions}
              modalOptions={modalOptions}
            />

            {mainTransactionId && !mainTransactionQuery.isFetching && (
              <div
                onClick={handleOpenEditMainTransaction}
                className="w-100 d-flex mt-4 mb-0 mb-md-n3 justify-content-center cursor-pointer"
                style={{ color: "var(--primary-primary)" }}>
                {t("form.add_transaction.display_main_tx")}
              </div>
            )}
          </>
        ) : (
          <EditTransactionForm
            transaction={rawTransaction}
            transactionContainerId={transactionContainerId}
            transactionId={id}
            transfer={isTransfer}
            fullTransaction={transaction}
            labelOptions={labelOptions}
            modalOptions={modalOptions}
          />
        )}
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bucketActions: bindActionCreators(bucketActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTransactionModal);
