export const emptyInitialState = {
  pairStates: [],
  sourcePortfolioId: undefined,
  timestamp: undefined,
  updatedAt: undefined,
};

export const emptyAccountInitialBalances = {
  balances: [],
  sourcePortfolioId: undefined,
  timestamp: undefined,
  updatedAt: undefined,
};
