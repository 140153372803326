import React, { createContext, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { walkthroughActions } from "actions/walkthroughActions";
import { Trans, useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import { Heading, PrimaryButton } from "components/Common";
import { modalActions } from "actions/modalActions";
import styles from "containers/Styles/Modals.module.scss";
import cx from "classnames";
import { X } from "react-feather";
import ImageIntro from "img/walkthrough/intro.webp";
import ImagePortfolio from "img/walkthrough/portfolio.webp";
import ImageTransactions from "img/walkthrough/transactions.webp";
import ImageReports from "img/walkthrough/reports.webp";
import ImageOrganization from "img/walkthrough/organization.webp";
import { Link } from "react-router-dom";
import { WalkthroughCategory } from "config/walkthroughCategories";
import { useScreenSize } from "hooks";

const CategoryContext = createContext(null);

const Category = ({ children, index }) => {
  const { activeCategory, setActiveCategory } = useContext(CategoryContext);

  return (
    <div
      onClick={() => {
        setActiveCategory(index);
      }}
      className={cx(styles.walkthrough_category, { [styles.walkthrough_category_active]: activeCategory === index })}>
      {children}
    </div>
  );
};

const CategoryContent = ({ image, label, content, index }) => {
  const { activeCategory } = useContext(CategoryContext);

  return activeCategory === index ? (
    <>
      <img src={image} alt="" style={{ width: "497px" }} />
      <div className="p-32" style={{ marginTop: "-10px" }}>
        <Heading level={4} style={{ marginBottom: "12px" }}>
          {label}
        </Heading>
        <div className={styles.content}>{content}</div>
      </div>
    </>
  ) : null;
};

const WalkthroughStartModal = ({ walkthroughActions: { startWalkthrough }, modalActions: { hideModal } }) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const [activeCategory, setActiveCategory] = useState(WalkthroughCategory.Intro);

  const handleArrowKeys = e => {
    if (e.key === "ArrowDown") {
      setActiveCategory(prevState => (prevState !== WalkthroughCategory.End ? prevState + 1 : prevState));
    }
    if (e.key === "ArrowUp") {
      setActiveCategory(prevState => (prevState !== WalkthroughCategory.Intro ? prevState - 1 : prevState));
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleArrowKeys);

    return () => {
      document.removeEventListener("keydown", handleArrowKeys);
    };
  }, []);

  return (
    <ModalEnvelope id="walkthrough-start-modal" className={styles.walkthrough_start_modal} closeIcon={false} disableFocus>
      {isSmall ? (
        <div>
          <img src={ImageIntro} alt="" style={{ width: "330px", borderRadius: "8px 8px 0 0" }} />

          <div onClick={hideModal} className={styles.walkthrough_close_button}>
            <X color="white" />
          </div>

          <div className="px-16 pt-8 pb-16 w-100">
            <Heading level={3} className="mb-8">
              {t("walkthrough_start.intro")}
            </Heading>

            <div>{t("walkthrough_start.intro_content")}</div>

            <div className="d-flex justify-content-center">
              <PrimaryButton
                label={t("walkthrough_start.start")}
                className="w-auto mt-40"
                noIcon
                onClick={() => startWalkthrough(activeCategory)}
              />
            </div>
          </div>
        </div>
      ) : (
        <CategoryContext.Provider value={{ activeCategory, setActiveCategory }}>
          <div className="p-32 d-flex flex-column">
            <div>
              <Heading level={3} className="mb-48">
                {t("walkthrough_start.intro")}
              </Heading>

              <div className={styles.walkthrough_categories}>
                <Category index={WalkthroughCategory.Intro}>{t("walkthrough_start.intro")}</Category>
                <Category index={WalkthroughCategory.Portfolio}>{t("walkthrough_start.portfolio")}</Category>
                <Category index={WalkthroughCategory.Transactions}>{t("walkthrough_start.transactions")}</Category>
                <Category index={WalkthroughCategory.Reports}>{t("walkthrough_start.reports")}</Category>
                <Category index={WalkthroughCategory.Organizations}>{t("walkthrough_start.organization")}</Category>
                <Category index={WalkthroughCategory.End}>{t("walkthrough_start.end")}</Category>
              </div>
            </div>

            <PrimaryButton
              label={t("walkthrough_start.start")}
              className="w-auto mt-auto"
              noIcon
              onClick={() => startWalkthrough(activeCategory)}
            />
          </div>

          <div className={styles.walkthrough_category_content}>
            <div onClick={hideModal} className={styles.walkthrough_close_button}>
              <X color="white" />
            </div>

            <CategoryContent
              image={ImageIntro}
              label={t("walkthrough_start.intro_long")}
              content={t("walkthrough_start.intro_content")}
              index={WalkthroughCategory.Intro}
            />
            <CategoryContent
              image={ImagePortfolio}
              label={t("walkthrough_start.portfolio")}
              content={t("walkthrough_start.portfolio_content")}
              index={WalkthroughCategory.Portfolio}
            />
            <CategoryContent
              image={ImageTransactions}
              label={t("walkthrough_start.transactions")}
              content={t("walkthrough.portfolio.data_manager_link.content")}
              index={WalkthroughCategory.Transactions}
            />
            <CategoryContent
              image={ImageReports}
              label={t("walkthrough_start.reports")}
              content={t("walkthrough_start.reports_content")}
              index={WalkthroughCategory.Reports}
            />
            <CategoryContent
              image={ImageOrganization}
              label={t("walkthrough_start.organization")}
              content={t("walkthrough.reports.organizations_link.content")}
              index={WalkthroughCategory.Organizations}
            />
            <CategoryContent
              image={ImageIntro}
              label={t("alert.success.walkthrough_exited")}
              content={
                <Trans i18nKey="walkthrough.data_manager.last_message.content">
                  You can always rerun to it from
                  <Link onClick={hideModal} to="/">
                    main navigation
                  </Link>
                </Trans>
              }
              index={WalkthroughCategory.End}
            />
          </div>
        </CategoryContext.Provider>
      )}
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    walkthroughActions: bindActionCreators(walkthroughActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(WalkthroughStartModal);
