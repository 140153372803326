import React, { useLayoutEffect, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { organizationActions } from "actions/organizationActions";
import { Heading, Tab, Tabs } from "components/Common";
import { useTranslation } from "react-i18next";
import { getRawOrganizationDetails, getUserMemberships } from "selectors";
import { useUrlHelper } from "hooks";
import BackButton from "components/Common/BackButton";
import styles from "pages/Pages.module.scss";
import Detail from "containers/Organizations/Detail";
import Members from "containers/Organizations/Members";
import history from "common/history";
import { useRouteMatch } from "react-router-dom";
import { SelectInput } from "components/Common/Inputs";
import { customBoldSelectStyles } from "common/styles/selectStyles";
import { Col, Row } from "react-bootstrap";
import cx from "classnames";

const organizationCategories = ["detail", "members"];

const OrganizationDetail = ({ organizationDetails, userMemberships, organizationActions }) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();
  const match = useRouteMatch();

  useLayoutEffect(() => {
    organizationActions.getOrganizationInfo(id);
    organizationActions.getOrganizationUsers(id);
  }, [id, organizationActions]);

  const { organizationData } = organizationDetails[id] || {};
  const organizationOptions = useMemo(
    () => userMemberships.map(x => ({ label: x.name, value: x.organizationId })),
    [userMemberships]
  );

  return (
    <div className={styles.app_content_padding}>
      <Tabs
        tabWrapperClassName={styles.organization_detail_tab}
        listClassName={cx(styles.organization_detail_tabs, styles.modal_tabs)}
        HeaderComponent={({ children }) => (
          <Row className="mb-3 align-items-center" style={{ rowGap: "1rem" }}>
            <Col xs={12} md="auto">
              {organizationData && (
                <Heading level={2} className="d-flex align-items-center w-auto">
                  <BackButton onClick={() => history.push(`/organizations`)} className="mr-1 mb-0" />

                  <SelectInput
                    value={organizationOptions.find(x => x.value === id)}
                    onChange={val => history.push(`/organizations/${val.value}/${match.params.tab}`)}
                    options={organizationOptions}
                    wrapperClassName={styles.organization_detail_select}
                    styles={customBoldSelectStyles}
                    isInline
                  />
                </Heading>
              )}
            </Col>{" "}
            <Col xs="auto" className="ml-auto">
              {children}
            </Col>
          </Row>
        )}
        activeIndex={organizationCategories.indexOf(match.params.tab)}
        onTabClick={index => history.push(`/organizations/${id}/${organizationCategories[index] || ""}`)}
        defaultTabIndex={Math.max(
          organizationCategories.findIndex(o => o === match.params.tab),
          0
        )}>
        <Tab title={t("organization_detail.transactions.detail")}>
          <Detail />
        </Tab>
        <Tab title={t("organization_detail.transactions.members")}>
          <Members />
        </Tab>
      </Tabs>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizationDetails: getRawOrganizationDetails(state),
    userMemberships: getUserMemberships(state),
    userRole: state.user.userRole,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetail);
