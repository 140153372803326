import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styles from "../Styles/AppStructure.module.scss";

const SpectatorBar = ({ isPresentationMode }) => {
  const { t } = useTranslation();

  return !isPresentationMode ? (
    <div className={styles.spectator_bar}>
      <div>{t("spectator_bar.message")}</div>
    </div>
  ) : null;
};

function mapStateToProps(state) {
  return {
    isPresentationMode: state.user.isPresentationMode,
  };
}

export default connect(mapStateToProps)(SpectatorBar);
