import React, { useCallback, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useField, useFormikContext } from "formik";
import cx from "classnames";
import cs from "date-fns/locale/cs";
import sk from "date-fns/locale/sk";
import en from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { convertLocalToUTCDate, convertUTCToLocalDate, dateFormat, formatUtcDate as formatDateToString } from "common/formatters";
import { useIsMounted } from "hooks/useIsMounted";
import { disabledDays } from "utils";
import { ReactComponent as CalendarIcon } from "img/CalendarIcon.svg";
import { ClearButton, IndicatorContainer, InputWrapper, LabelRow } from "./Shared";
import styles from "./Inputs.module.scss";
import "./DateInput.scss";
import { DateInputContainer } from "./DateInputRangeOptions";

registerLocale("en-US", en);
registerLocale("cs-CZ", cs);
registerLocale("cs", cs);
registerLocale("sk-SK", sk);
registerLocale("sk", sk);

const DateInput = ({
  value,
  onChange,
  label,
  error,
  required,
  disabled,
  readOnly,
  wrapperClassName,
  wrapperStyle,
  isPlain,
  isInline,
  isCustomField,
  isClearable,
  isRangeSelect,
  minDate,
  maxDate,
  onBlur,
  showMonthDropdown = true,
  showYearDropdown = true,
  children,
  showRangeButtons,
  hideOutOfRangeOptions,
  thisPortfolioRange,
  onClear,
  customClear,
  excludeDates,
  name,
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { i18n } = useTranslation();
  const hasValue = !!(isRangeSelect ? value?.from || value?.to : value);

  const [ref, setRef] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const focusInput = () => !ref.state.open && ref.setOpen(true);

  const [dates, setDates] = useState([value?.from, value?.to]);
  const [startDate, endDate] = dates;
  useEffect(() => {
    if (isRangeSelect) setDates([value?.from, value?.to]);
  }, [value, isRangeSelect]);

  const setRange = (range, propagateNull = false) => {
    setDates([convertLocalToUTCDate(range[0]), convertLocalToUTCDate(range[1])]);
    if ((range[0] && range[1]) || propagateNull) {
      // Change only when both dates are selected
      onChange({
        from: formatDateToString(convertLocalToUTCDate(range[0])),
        to: formatDateToString(convertLocalToUTCDate(range[1])),
      });
    }
  };

  return (
    <InputWrapper
      className={cx(styles.date_input, wrapperClassName, isCustomField && "pr-3")}
      style={wrapperStyle}
      disabled={disabled}
      inline={isInline}
      plain={isPlain}
      readOnly={readOnly}
      error={error}
      focused={isOpen}
      onClick={focusInput}>
      <LabelRow label={label} error={error} required={required} />

      <div className={styles.input_container}>
        <DatePicker
          ref={datepicker => setRef(datepicker)}
          onFocus={isRangeSelect ? e => e.target.blur() : undefined}
          selected={convertUTCToLocalDate(isRangeSelect ? startDate : value)}
          startDate={convertUTCToLocalDate(isRangeSelect ? startDate : null)}
          endDate={convertUTCToLocalDate(isRangeSelect ? endDate : null)}
          onChange={value => {
            if (isMounted.current) {
              if (isRangeSelect) {
                setRange(value);
              } else {
                onChange(formatDateToString(convertLocalToUTCDate(value)));
              }
            }
          }}
          readOnly={readOnly}
          disabled={disabled}
          minDate={minDate ? new Date(minDate) : disabledDays.minDate}
          maxDate={maxDate ? new Date(maxDate) : null}
          placeholderText={isRangeSelect ? `${dateFormat} ~ ${dateFormat}` : dateFormat}
          dateFormat="yyyy-MM-dd"
          locale={i18n.language}
          onBlur={onBlur}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          yearDropdownItemNumber={5}
          selectsRange={isRangeSelect}
          disabledKeyboardNavigation
          dropdownMode="select"
          calendarContainer={
            isRangeSelect &&
            showRangeButtons &&
            DateInputContainer(
              value => {
                setRange(value);
                ref.setOpen(false);
              },
              thisPortfolioRange,
              t,
              hideOutOfRangeOptions
            )
          }
          excludeDates={excludeDates}
          formatWeekDay={nameOfDay => nameOfDay.slice(0, 2).toUpperCase()}
          onCalendarClose={() => setIsOpen(false)}
          onCalendarOpen={() => setIsOpen(true)}
          name={name ?? "date"}
          autoComplete="off"
          enableTabLoop={false}
          disableKeyboardNavigation
          onKeyDown={e => {
            if (e.key === "Tab") {
              ref.current?.setOpen(false);
            }
            e.preventDefault();
          }}
        />

        <IndicatorContainer>
          {isClearable && hasValue && (
            <ClearButton
              disabled={disabled}
              onClick={() => {
                if (!customClear) onChange(isRangeSelect ? { from: null, to: null } : null);
                if (onClear) onClear();
              }}
            />
          )}
          <CalendarIcon className={styles.indicator_icon} style={{ marginLeft: "1px" }} size="smallMedium" />
          {children}
        </IndicatorContainer>
      </div>
    </InputWrapper>
  );
};

export const DateFormField = ({ name, onChange, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const form = useFormikContext();

  const onInputValueChange = useCallback(
    value => {
      if (onChange && typeof onChange === "function") onChange(value, form);
      helpers.setValue(value);
    },
    [onChange, helpers, form]
  );

  return (
    <DateInput
      name={name}
      error={meta.touched ? meta.error : ""}
      {...field}
      onChange={onInputValueChange}
      onBlur={helpers.setTouched}
      {...props}
    />
  );
};

export default DateInput;
