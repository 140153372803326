import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../Common";
import styles from "../Styles/Metrics.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.metrics_tab_content}>
      <div>
        <Heading level={4}>
          <span>01.</span>
          {t("metrics.faq.q1.heading")}
        </Heading>
        <p>{t("metrics.faq.q1.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>02.</span>
          {t("metrics.faq.q2.heading")}
        </Heading>
        <p>{t("metrics.faq.q2.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>03.</span>
          {t("metrics.faq.q3.heading")}
        </Heading>
        <p>{t("metrics.faq.q3.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>04.</span>
          {t("metrics.faq.q4.heading")}
        </Heading>
        <p>{t("metrics.faq.q4.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>05.</span>
          {t("metrics.faq.q5.heading")}
        </Heading>
        <p>{t("metrics.faq.q5.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>06.</span>
          {t("metrics.faq.q6.heading")}
        </Heading>
        <p>{t("metrics.faq.q6.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>07.</span>
          {t("metrics.faq.q7.heading")}
        </Heading>
        <p>{t("metrics.faq.q7.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>08.</span>
          {t("metrics.faq.q8.heading")}
        </Heading>
        <p>{t("metrics.faq.q8.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>09.</span>
          {t("metrics.faq.q9.heading")}
        </Heading>
        <p>{t("metrics.faq.q9.answer")}</p>
      </div>

      <div>
        <Heading level={4}>
          <span>10.</span>
          {t("metrics.faq.q10.heading")}
        </Heading>
        <p>{t("metrics.faq.q10.answer")}</p>
      </div>
    </div>
  );
};
