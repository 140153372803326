import React from "react";

export function withTooltip(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        tooltipOpened: false,
      };
      this.closeOnClick = this.closeOnClick.bind(this);
      this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    closeOnClick() {
      this.setState({ tooltipOpened: false });
      document.removeEventListener("click", this.closeOnClick);
    }

    toggleTooltip() {
      this.setState({ tooltipOpened: !this.state.tooltipOpened }, () => {
        setTimeout(() => {
          if (this.state.tooltipOpened) {
            document.addEventListener("click", this.closeOnClick);
          } else {
            document.removeEventListener("click", this.closeOnClick);
          }
        });
      });
    }

    render() {
      return <WrappedComponent tooltipOpened={this.state.tooltipOpened} toggleTooltip={this.toggleTooltip} {...this.props} />;
    }
  };
}
