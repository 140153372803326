import i18n from "i18n";
import { reportService } from "services";
import {
  DOWNLOAD_ACCOUNTING_REPORT,
  DOWNLOAD_ACCOUNTS_STATEMENT_REPORT,
  DOWNLOAD_REPORT,
  GET_REPORT_PARAMS,
  SWITCH_PORTFOLIO,
} from "./types";
import { portfolioActions } from "./portfolioActions";
import { alertActions } from "./alertActions";

function selectPortfolio(portfolioId) {
  return {
    type: SWITCH_PORTFOLIO,
    payload: portfolioId,
  };
}

function fetchParams(portfolioId, skipFetchBalances) {
  return async (dispatch, getState) => {
    const params = await dispatch({
      type: GET_REPORT_PARAMS,
      payload: async () => reportService.getReportParameters(portfolioId, getState().user),
      meta: { portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });

    if (params?.value?.reportToDefault && !skipFetchBalances) {
      dispatch(portfolioActions.getPortfolioBalances({ id: portfolioId }, params.value.reportToDefault));
    }
  };
}

function downloadReport(portfolioId, from, to, type) {
  return (dispatch, getState) =>
    dispatch({
      type: DOWNLOAD_REPORT,
      payload: async () => reportService.downloadReport(portfolioId, from, to, type, i18n.language, getState().user),
      meta: { portfolioId, type },
    }).catch(err => {
      alertActions.error(err);
    });
}

function downloadAccountsStatementReport(portfolioId, from, to, accIds) {
  return (dispatch, getState) =>
    dispatch({
      type: DOWNLOAD_ACCOUNTS_STATEMENT_REPORT,
      payload: reportService.downloadAccountsStatementReport(portfolioId, from, to, accIds, i18n.language, getState().user),
      meta: { portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });
}

function downloadAccountingReport(portfolioId, options) {
  return (dispatch, getState) =>
    dispatch({
      type: DOWNLOAD_ACCOUNTING_REPORT,
      payload: reportService.downloadAccountingReport(portfolioId, getState().user, options),
      meta: { portfolioId },
    }).catch(err => {
      alertActions.error(err);
    });
}

export const reportActions = {
  selectPortfolio,
  fetchParams,
  downloadReport,
  downloadAccountsStatementReport,
  downloadAccountingReport,
};
