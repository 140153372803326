import {
  GET_EXCHANGES_DATA,
  GET_CONNECTORS,
  GET_GEOGRAPHICS,
  WEBSOCKET_OPENED,
  WEBSOCKET_CLOSED,
  WEBSOCKET_PRICE_MESSAGE,
} from "actions/types";

const initialState = {
  currencies: [],
  prices: {},
  wsOpened: false,
  isFetching: false,
  connectors: [],
  isConnectorsFetching: false,
  geographics: {},
  isGeographicsFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_EXCHANGES_DATA}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };

    case `${GET_EXCHANGES_DATA}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };

    case `${GET_EXCHANGES_DATA}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    // ====================================
    case `${GET_CONNECTORS}_PENDING`:
      return {
        ...state,
        isConnectorsFetching: true,
      };
    case `${GET_CONNECTORS}_FULFILLED`:
      return {
        ...state,
        isConnectorsFetching: false,
        connectors: action.payload,
      };
    case `${GET_CONNECTORS}_REJECTED`:
      return {
        ...state,
        isConnectorsFetching: false,
      };
    // ====================================
    case `${GET_GEOGRAPHICS}_PENDING`:
      return {
        ...state,
        isGeographicsFetching: true,
      };
    case `${GET_GEOGRAPHICS}_FULFILLED`:
      return {
        ...state,
        isGeographicsFetching: false,
        geographics: action.payload,
      };
    case `${GET_GEOGRAPHICS}_REJECTED`:
      return {
        ...state,
        isGeographicsFetching: false,
      };
    // ====================================
    case WEBSOCKET_PRICE_MESSAGE:
      return action.payload.error
        ? state
        : {
            ...state,
            prices: { ...state.prices, ...action.payload },
          };

    case WEBSOCKET_OPENED:
      return {
        ...state,
        wsOpened: true,
      };

    case WEBSOCKET_CLOSED:
      return {
        ...state,
        wsOpened: false,
      };

    default:
      return state;
  }
};
