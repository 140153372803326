import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Close as CloseIcon, InProgress as TrialIcon, Search as SearchIcon } from "grommet-icons";
import { Inspect as InspectIcon } from "grommet-icons/icons";
import { supportActions } from "actions/supportActions";
import { modalActions } from "actions/modalActions";
import {
  ActionCell,
  FilterableCell,
  GhostButton,
  Heading,
  LoaderOverlay,
  SortableCell,
  Table,
  TableFooter,
  Text,
  Tooltip,
} from "components/Common";
import { SelectInput, TextInput } from "components/Common/Inputs";
import { WRITE } from "common/constants/userPermission";
import DateLabel from "components/Dashboard/DateLabel";
import { supportService } from "services/supportService";
import { BUSINESS, ONLINE, ONLINE_PLUS, paymentPlans } from "common/constants/paymentPlans";
import { getGlobalFetchingState, getIsSpectatorMode } from "selectors";
import { usePrevious } from "hooks";
import { sortArray } from "utils";
import styles from "pages/Pages.module.scss";
import { WB_SUPPORT } from "common/constants/whaleBooksRoles";
import history from "common/history";
import { alertActions } from "actions/alertActions";
import { userActions } from "actions/userActions";
import { useDefaultsStore } from "stores/defaultsStore";

const getUsers = async input => {
  if (input.length > 2) {
    const res = await supportService.getUsers(0, 0, input);
    return sortArray(res.data, ["email"]);
  }
  return [];
};

const paymentPlanOptions = [
  { value: ONLINE_PLUS, label: paymentPlans[ONLINE_PLUS].name },
  { value: BUSINESS, label: paymentPlans[BUSINESS].name },
];
const AdminOrganizations = ({
  isSpectator,
  everyTradeOrganizations: { organizations, totalOrganizationsCount, isOrganizationsFetching },
  globalFetchingState,
  supportActions,
  modalActions: { openOrganizationTrialPeriod },
  userActions,
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const [pagination, setPagination] = useState({ page: 0, count: useDefaultsStore.getState().perPageDefault });
  const [search, setSearch] = useState("");
  const [sortedBy, setSortedBy] = useState({ id: "joined", order: "asc" });
  const [filteredBy, setFilteredBy] = useState(state || {});
  const planFilterRef = useRef(null);

  const handlePagingChange = value => {
    if (value.count) setPagination(prevState => ({ ...prevState, count: value.count }));
    if (value.page || value.page === 0) setPagination(prevState => ({ ...prevState, page: value.page }));
  };

  useEffect(() => {
    supportActions.getOrganizations(pagination.page, pagination.count, search, sortedBy, filteredBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sortedBy, filteredBy, supportActions]);

  const prevSearch = usePrevious(search);
  useEffect(() => {
    if ((prevSearch === undefined && search === "") || (prevSearch === "" && search === "") || prevSearch === search) return;

    const delayDebounceFn = setTimeout(() => {
      setPagination(prevState => ({ ...prevState, page: 0 }));
      supportActions.getOrganizations(0, pagination.count, search, sortedBy, filteredBy);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, prevSearch]);

  useEffect(() => {
    if (isSpectator) supportActions.leaveSpectatorMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInspect = useCallback(
    async userId => {
      try {
        const membershipsResult = await userActions.getJoinedOrganizations(userId, true);
        await userActions.getUserOrganizations(userId);
        await userActions.switchOrganization(membershipsResult.value[0].organizationId, userId);
        history.push("/dashboard");
      } catch (e) {
        alertActions.error(t("alert.error.cannot_inspect_user"));
      }
    },
    [userActions, t]
  );

  const organizationsTableColumns = useMemo(
    () => [
      {
        Header: t("et_organizations.table_header.id"),
        accessor: "id",
        width: 20,
      },
      {
        Header: props => <SortableCell {...props}>{t("et_organizations.table_header.organization_name")}</SortableCell>,
        Cell: ({ value }) => <div style={{ overflowWrap: "break-word", marginRight: "0.5rem" }}>{value}</div>,
        accessor: "displayName",
      },
      {
        Header: props => (
          <FilterableCell {...props} onClick={() => planFilterRef.current.focus()}>
            {t("et_organizations.table_header.plan")}
          </FilterableCell>
        ),
        Cell: ({ value, row: { planStart, planEnd } }) =>
          value === ONLINE ? (
            paymentPlans[ONLINE]?.name
          ) : (
            <Text>
              {paymentPlans[value]?.name}
              <DateLabel start={planStart} end={planEnd} />
            </Text>
          ),
        accessor: "plan",
      },
      {
        Header: props => <SortableCell {...props}>{t("et_organizations.table_header.trial")}</SortableCell>,
        Cell: ({ value }) =>
          value || (
            <Text size="small" style={{ overflowWrap: "break-word" }}>
              {t("et_organizations.table_header.no_trial")}
            </Text>
          ),
        accessor: "trialEnd",
      },
      {
        Header: t("et_organizations.table_header.owner"),
        Cell: ({ value }) => <div style={{ overflowWrap: "break-word", marginRight: "0.5rem" }}>{value.email}</div>,
        accessor: "owner",
      },
      {
        Header: () => <div style={{ textAlign: "right" }}>{t("account_detail.table_header.action")}</div>,
        id: "action",
        Cell: ({ row }) => (
          <ActionCell activeRole={WRITE}>
            {row.original.trialEnd && (
              <Tooltip tooltipContent={t("et_organizations.table_header.cancel_trial")}>
                <GhostButton
                  icon={<CloseIcon />}
                  onClick={() => supportActions.setOrganizationTrialPeriod(row.original.id, null)}
                  actionButton
                />
              </Tooltip>
            )}
            <Tooltip tooltipContent={t("et_organizations.table_header.set_trial")}>
              <GhostButton
                icon={<TrialIcon />}
                onClick={() => openOrganizationTrialPeriod({ organization: row.original })}
                actionButton
              />
            </Tooltip>
            <Tooltip
              tooltipContent={t("et_organizations.table_header.inspect_tooltip")}
              disabled={row.original.owner?.userRole === WB_SUPPORT}>
              <GhostButton
                icon={<InspectIcon />}
                onClick={e => {
                  e.stopPropagation();
                  onInspect(row.original.owner?.id);
                }}
                actionButton
              />
            </Tooltip>
          </ActionCell>
        ),
      },
    ],
    [t, openOrganizationTrialPeriod, supportActions, onInspect]
  );

  return (
    <div className={styles.app_content_padding}>
      <div className={styles.heading_block}>
        <Heading level={2}>{t("et_organizations.heading")}</Heading>
        <div className={styles.filters}>
          <SelectInput
            value={filteredBy.plan || ""}
            onChange={val => setFilteredBy({ ...filteredBy, plan: val })}
            label={t("et_organizations.table_filter.payment_plan")}
            options={paymentPlanOptions}
            isInline
            isClearable
            ref={planFilterRef}
          />
          <SelectInput
            value={filteredBy.user || ""}
            onChange={val => setFilteredBy({ ...filteredBy, user: val })}
            label={t("et_organizations.table_filter.user")}
            getOptionLabel={user => `${user.email}`}
            getOptionValue={user => `${user.userId}`}
            loadOptions={input => getUsers(input)}
            isAsync
            isInline
            isClearable
            wrapperClassName={styles.users_filter}
          />
          <TextInput
            placeholder={t("et_organizations.search_placeholder")}
            value={search}
            label={<SearchIcon />}
            isInline
            onChange={setSearch}
            wrapperStyle={{ alignItems: "center" }}
          />
        </div>
      </div>
      <LoaderOverlay isLoading={isOrganizationsFetching && !globalFetchingState}>
        <div style={{ minHeight: "200px" }}>
          <Table
            data={organizations}
            columns={organizationsTableColumns}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
            filteredBy={filteredBy}
            setFilteredBy={setFilteredBy}
            fixedLayout
          />

          <TableFooter
            page={pagination.page}
            count={pagination.count}
            totalRecords={totalOrganizationsCount}
            onChange={handlePagingChange}
            isVisible={organizations.length > 0}
          />
        </div>
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isSpectator: getIsSpectatorMode(state),
    everyTradeOrganizations: state.support,
    globalFetchingState: getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    supportActions: bindActionCreators(supportActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrganizations);
