import React, { useState } from "react";
import { TransitionMotion, spring } from "react-motion";
import { scaleLinear } from "@vx/scale";
import { useTranslation } from "react-i18next";
import { withScreenSize } from "hoc";
import { colors } from "common/colors";
import { getCurrencyIconProps } from "utils/currencyIcon";
import PercentDecimal from "../Formating/PercentDecimal";
import { Heading } from "../Common";

import styles from "../Styles/Graphs.module.scss";

const SMALL_CAPTION_LONG_THRESHOLD = 6;
const SMALL_CAPTION_SHORT_THRESHOLD = 6.75;

const willEnter = () => ({
  labelOpacity: 0,
  width: 0,
});

const willLeave = () => ({
  labelOpacity: spring(0),
  width: spring(0),
});

const colorPalette = [colors.gray5, "rgb(76,87,106)", "rgb(98,108,124)", colors.gray4];

const getHoveredWidth = ({ compressed, width }, isSmall) =>
  Math.min(Math.max(width, Math.ceil(compressed.length / 4) * (isSmall ? 20 : 10)), 100);
const renderPercents = number => <span className={styles.percent}>{number}</span>;

const getSizeClass = ({ goalWidth, short }) =>
  (short && goalWidth < SMALL_CAPTION_SHORT_THRESHOLD) || (!short && goalWidth < SMALL_CAPTION_LONG_THRESHOLD)
    ? styles.caption_little
    : "";

const DistributionGraph = ({ distribution, message, isSmall }) => {
  const [shortsHovered, setShortsHovered] = useState(false);
  const [longsHovered, setLongsHovered] = useState(false);
  const [hoveredWidth, setHoveredWidth] = useState(0);
  const [hoveredPercentage, setHoveredPercentage] = useState(0);

  const { t } = useTranslation();

  const getStyles = () => {
    const xScale = scaleLinear({
      range: [0, 100 - hoveredWidth],
      domain: [0, 100 - hoveredPercentage],
    });

    return distribution.map((o, i) => ({
      key: o.currency,
      style: {
        labelOpacity: spring(1),
        width: spring(
          (shortsHovered && o.short && o.compressed) || (longsHovered && !o.short && o.compressed)
            ? hoveredWidth
            : xScale(o.width)
        ),
      },
      data: {
        ...o,
        color: colorPalette[i % colorPalette.length],
        goalWidth:
          (shortsHovered && o.short && o.compressed) || (longsHovered && !o.short && o.compressed)
            ? hoveredWidth
            : xScale(o.width),
      },
    }));
  };

  return (
    <TransitionMotion styles={getStyles()} willEnter={willEnter} willLeave={willLeave}>
      {interpolated => (
        <div
          className={styles.distribution_chart}
          style={{ background: colorPalette[Math.max(interpolated.length - 1, 0) % colorPalette.length] }}
          data-tour="distribution_chart">
          {interpolated.map(({ key, style, data }) =>
            data.compressed ? (
              <div
                key={`${key}-transition`}
                style={{
                  width: `${style.width}%`,
                  backgroundColor: data.color,
                }}
                onMouseEnter={() => {
                  if (data.compressed && data.short && style.width !== 0) {
                    setShortsHovered(true);
                    setHoveredWidth(getHoveredWidth(data, isSmall));
                    setHoveredPercentage(data.width);
                  }
                  if (data.compressed && !data.short && style.width !== 0) {
                    setLongsHovered(true);
                    setHoveredWidth(getHoveredWidth(data, isSmall));
                    setHoveredPercentage(data.width);
                  }
                }}
                onMouseLeave={() => {
                  if (data.compressed && data.short && style.width !== 0) {
                    setShortsHovered(false);
                    setHoveredWidth(0);
                    setHoveredPercentage(0);
                  }
                  if (data.compressed && !data.short && style.width !== 0) {
                    setLongsHovered(false);
                    setHoveredWidth(0);
                    setHoveredPercentage(0);
                  }
                }}
                className={`${styles.compressed} ${data.short ? styles.short : styles.long}`}>
                <div className={`${styles.caption} ${getSizeClass(data)}`} style={{ opacity: style.labelOpacity }}>
                  <PercentDecimal number={data.percentage} prefix={data.short ? "-" : ""} renderText={renderPercents} />
                  <div className={styles.currency}>
                    <span>
                      {data.short
                        ? t("dashboard.distribution_chart.other_shorts")
                        : t("dashboard.distribution_chart.other_longs")}
                    </span>
                  </div>
                </div>
                <div className={styles.compressed_list}>
                  {data.compressed.map(({ label, percentage }) => (
                    <div className={styles.compressed_currency} key={label}>
                      <span className={styles.compressed_currency_label}>
                        <img {...getCurrencyIconProps(label)} alt={label} />
                        <span>{label}</span>
                      </span>
                      <PercentDecimal number={percentage} prefix={data.short ? "-" : ""} />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                key={`${key}-transition`}
                style={{
                  width: `${style.width}%`,
                  backgroundColor: data.color,
                }}
                className={`${styles.standard} ${data.short ? styles.short : styles.long}`}>
                <div className={`${styles.caption} ${getSizeClass(data)}`} style={{ opacity: style.labelOpacity }}>
                  <PercentDecimal number={data.percentage} prefix={data.short ? "-" : ""} renderText={renderPercents} />
                  <div className={styles.currency}>
                    <img {...getCurrencyIconProps(data.label)} alt={data.label} />
                    <span>{data.label}</span>
                  </div>
                </div>
              </div>
            )
          )}
          {message && (
            <Heading color="white" margin="1rem auto" textAlign="center" alignSelf="center" level={4}>
              {message}
            </Heading>
          )}
        </div>
      )}
    </TransitionMotion>
  );
};

export default withScreenSize(DistributionGraph);
