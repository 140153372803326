export const BUY = "BUY";
export const SELL = "SELL";
export const FEE = "FEE";
export const REBATE = "REBATE";
export const DEPOSIT = "DEPOSIT";
export const WITHDRAWAL = "WITHDRAWAL";
export const STAKE = "STAKE";
export const UNSTAKE = "UNSTAKE";
export const STAKING_REWARD = "STAKING_REWARD";
export const AIRDROP = "AIRDROP";
export const EARNING = "EARNING";
export const REWARD = "REWARD";
export const FORK = "FORK";

// TRANSFER types are not included in tx type options
export const TRANSFER_IN = "TRANSFER_IN";
export const TRANSFER_OUT = "TRANSFER_OUT";

export const transactionTypes = [
  BUY,
  SELL,
  FEE,
  REBATE,
  DEPOSIT,
  WITHDRAWAL,
  STAKE,
  UNSTAKE,
  STAKING_REWARD,
  REWARD,
  AIRDROP,
  EARNING,
  FORK,
];

const t_fake = str => str;

const transactionTypeTranslations = {
  [BUY]: t_fake("constant.transaction_type.buy"),
  [SELL]: t_fake("constant.transaction_type.sell"),
  [FEE]: t_fake("constant.transaction_type.fee"),
  [REBATE]: t_fake("constant.transaction_type.rebate"),
  [DEPOSIT]: t_fake("constant.transaction_type.deposit"),
  [WITHDRAWAL]: t_fake("constant.transaction_type.withdrawal"),
  [TRANSFER_IN]: t_fake("constant.transaction_type.transfer_in"),
  [TRANSFER_OUT]: t_fake("constant.transaction_type.transfer_out"),
  [STAKE]: t_fake("constant.transaction_type.stake"),
  [UNSTAKE]: t_fake("constant.transaction_type.unstake"),
  [STAKING_REWARD]: t_fake("constant.transaction_type.staking_reward"),
  [REWARD]: t_fake("constant.transaction_type.reward"),
  [AIRDROP]: t_fake("constant.transaction_type.airdrop"),
  [EARNING]: t_fake("constant.transaction_type.earning"),
  [FORK]: t_fake("constant.transaction_type.fork"),
};

export const transactionTypeTranslator = (val, isTransfer = false) => {
  if (isTransfer) {
    if (val === DEPOSIT) return transactionTypeTranslations[TRANSFER_IN];
    if (val === WITHDRAWAL) return transactionTypeTranslations[TRANSFER_OUT];
  }
  return transactionTypeTranslations[val] || "";
};

export const transactionTypeOptions = (t, ...selectedOptions) => {
  const options = [
    { value: BUY, label: t(`${transactionTypeTranslator(BUY)}`) },
    { value: SELL, label: t(`${transactionTypeTranslator(SELL)}`) },
    { value: FEE, label: t(`${transactionTypeTranslator(FEE)}`) },
    { value: REBATE, label: t(`${transactionTypeTranslator(REBATE)}`) },
    { value: DEPOSIT, label: t(`${transactionTypeTranslator(DEPOSIT)}`) },
    { value: WITHDRAWAL, label: t(`${transactionTypeTranslator(WITHDRAWAL)}`) },
    { value: STAKE, label: t(`${transactionTypeTranslator(STAKE)}`) },
    { value: UNSTAKE, label: t(`${transactionTypeTranslator(UNSTAKE)}`) },
    { value: STAKING_REWARD, label: t(`${transactionTypeTranslator(STAKING_REWARD)}`) },
    { value: REWARD, label: t(`${transactionTypeTranslator(REWARD)}`) },
    { value: AIRDROP, label: t(`${transactionTypeTranslator(AIRDROP)}`) },
    { value: EARNING, label: t(`${transactionTypeTranslator(EARNING)}`) },
    { value: FORK, label: t(`${transactionTypeTranslator(FORK)}`) },
  ];

  return selectedOptions?.length > 0 ? options.filter(o => selectedOptions.includes(o.value)) : options;
};

export const allTransactionTypeOptions = t => [
  { value: BUY, label: t(`${transactionTypeTranslator(BUY)}`) },
  { value: SELL, label: t(`${transactionTypeTranslator(SELL)}`) },
  { value: FEE, label: t(`${transactionTypeTranslator(FEE)}`) },
  { value: REBATE, label: t(`${transactionTypeTranslator(REBATE)}`) },
  { value: DEPOSIT, label: t(`${transactionTypeTranslator(DEPOSIT)}`) },
  { value: WITHDRAWAL, label: t(`${transactionTypeTranslator(WITHDRAWAL)}`) },
  { value: TRANSFER_IN, label: t(`${transactionTypeTranslator(TRANSFER_IN)}`) },
  { value: TRANSFER_OUT, label: t(`${transactionTypeTranslator(TRANSFER_OUT)}`) },
  { value: STAKE, label: t(`${transactionTypeTranslator(STAKE)}`) },
  { value: UNSTAKE, label: t(`${transactionTypeTranslator(UNSTAKE)}`) },
  { value: STAKING_REWARD, label: t(`${transactionTypeTranslator(STAKING_REWARD)}`) },
  { value: REWARD, label: t(`${transactionTypeTranslator(REWARD)}`) },
  { value: AIRDROP, label: t(`${transactionTypeTranslator(AIRDROP)}`) },
  { value: EARNING, label: t(`${transactionTypeTranslator(EARNING)}`) },
  { value: FORK, label: t(`${transactionTypeTranslator(FORK)}`) },
];

export const isTxTypeBuySell = type => type === BUY || type === SELL;
export const isTxTypeFeeRebate = type => type === FEE || type === REBATE;
export const isTxTypeDepositWithdrawal = type => type === DEPOSIT || type === WITHDRAWAL;
export const isTxTypeStake = type => type === STAKE || type === UNSTAKE;
export const isTxTypeReward = type =>
  type === STAKING_REWARD || type === REWARD || type === AIRDROP || type === EARNING || type === FORK;

export const canTxHaveRebate = type => !isTxTypeDepositWithdrawal(type) && !isTxTypeStake(type) && !isTxTypeReward(type);

export const baseQuoteTypes = [BUY, SELL];
export const singleValueTypes = [
  FEE,
  REBATE,
  DEPOSIT,
  WITHDRAWAL,
  STAKE,
  UNSTAKE,
  STAKING_REWARD,
  REWARD,
  AIRDROP,
  EARNING,
  FORK,
];

export const isTxSingleCurrency = type => singleValueTypes.includes(type);
