import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { walkthroughActions } from "actions/walkthroughActions";
import { modalActions } from "actions/modalActions";
import Tour from "reactour";
import { Trans, useTranslation } from "react-i18next";
import { GhostButton, Heading } from "components/Common";
import WalkThroughWizard from "./WalkThroughWizard";
import history from "common/history";
import styles from "../Styles/Walkthrough.module.scss";
import { getActiveOrganizationId } from "selectors";
import { useScreenSize } from "hooks";
import ImageOrganization from "img/walkthrough/initial/organization.webp";
import ImageOrganizationMobile from "img/walkthrough/organization.webp";

const OrganizationWalkthrough = ({
  walkthrough: { organizationIsOpen, organizationStep },
  walkthroughActions: { updateStep, endWalkthrough },
  enableBodyScroll,
  disableBodyScroll,
  activeOrganizationId,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const steps = [
    {
      selector: isSmall ? '[data-tour="burger-button"]' : '[data-tour="organizations-link"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.reports.organizations_link.heading")}</Heading>
          <div>{t("walkthrough.reports.organizations_link.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="organization-tax-rules"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.tax_rules.heading")}</Heading>
          <div>{t("walkthrough.organization.tax_rules.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="organization-rule-sets"]',
      position: "left",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.rule_sets.heading")}</Heading>
          <div>{t("walkthrough.organization.rule_sets.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="organization-rewards"]',
      position: "left",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.rewards.heading")}</Heading>
          <div>{t("walkthrough.organization.rewards.content")}</div>
        </div>
      ),
      action: () => history.push(`/organizations/${activeOrganizationId}/tax-rules`),
    },
    {
      selector: '[data-tour="organization-chart-account"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.chart_account.heading")}</Heading>
          <div>{t("walkthrough.organization.chart_account.content")}</div>
        </div>
      ),
      action: () => history.push(`/organizations/${activeOrganizationId}/chart-of-account`),
    },
    {
      selector: '[data-tour="organization-accounting-software"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.accounting_software.heading")}</Heading>
          <div>{t("walkthrough.organization.accounting_software.content")}</div>
        </div>
      ),
      action: () => history.push(`/organizations/${activeOrganizationId}/accounting-software`),
    },
    {
      selector: '[data-tour="organization-software-guide"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.software_guide.heading")}</Heading>
          <div>{t("walkthrough.organization.software_guide.content")}</div>
        </div>
      ),
      action: () => history.push(`/organizations/${activeOrganizationId}/accounting-software`),
    },
    {
      selector: '[data-tour="organization-lists"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.lists.heading")}</Heading>
          <div>{t("walkthrough.organization.lists.content")}</div>
        </div>
      ),
      action: () => history.push(`/organizations/${activeOrganizationId}/lists`),
    },
    {
      selector: '[data-tour="organization-members"]',
      position: "right",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.organization.members.heading")}</Heading>
          <div>{t("walkthrough.organization.members.content")}</div>
        </div>
      ),
      action: () => history.push(`/organizations/${activeOrganizationId}/members`),
    },
    {
      selector: "",
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={4}>{t("walkthrough.data_manager.last_message.heading")}</Heading>
          <div>
            <Trans i18nKey="walkthrough.data_manager.last_message.content">
              You can always rerun to it from
              <Link className={styles.black_link} onClick={endWalkthrough} to="/">
                main navigation
              </Link>
            </Trans>
          </div>
        </div>
      ),
    },
  ];

  const [updateTrigger, setUpdateTrigger] = useState(organizationStep.toString());
  useEffect(() => {
    setTimeout(() => setUpdateTrigger(organizationStep.toString()), 100);
  }, [organizationStep]);

  return (
    <Tour
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
      steps={steps}
      onRequestClose={endWalkthrough}
      closeWithMask={false}
      disableInteraction
      rounded={8}
      startAt={0}
      isOpen={organizationIsOpen}
      goToStep={organizationStep}
      getCurrentStep={step => updateStep(step)}
      update={updateTrigger}
      updateDelay={500}
      maskClassName="tour-mask"
      CustomHelper={props => (
        <WalkThroughWizard
          {...props}
          onLastStepClick={endWalkthrough}
          isLastTourCategory
          InitialStepImage={ImageOrganization}
          InitialStepImageMobile={ImageOrganizationMobile}
        />
      )}
    />
  );
};

const mapStateToProps = state => ({
  walkthrough: state.walkthrough,
  activeOrganizationId: getActiveOrganizationId(state),
});

const mapDispatchToProps = dispatch => ({
  walkthroughActions: bindActionCreators(walkthroughActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationWalkthrough);
