import React from "react";
import { LARGE } from "common/constants/screenSizes";
import cx from "classnames";
import Spinner from "./Spinner";

import styles from "./Components.module.scss";

const LoaderOverlay = ({ isLoading, spinnerSize = LARGE, lighter = false, className, children, ...rest }) => (
  <div className={cx(styles.loadable, className, { [styles.overflow_hidden]: isLoading })} {...rest}>
    {isLoading && (
      <div className={styles.spinner_wrapper}>
        <Spinner size={spinnerSize} />
      </div>
    )}
    {isLoading && <div className={lighter ? styles.dimer_lighter : styles.dimmer} />}
    {children}
  </div>
);

export default LoaderOverlay;
