import React, { useRef, forwardRef } from "react";
import { TextArea as TextAreaComponent } from "grommet";
import { useField } from "formik";
import cx from "classnames";
import { InputWrapper, LabelRow } from "./Shared";

import styles from "./Inputs.module.scss";

const TextAreaInput = ({ onChange, label, error, required, isInline, wrapperClassName, value, ...props }) => {
  const inputRef = useRef();

  return (
    <InputWrapper
      className={cx(styles.text_area_input, wrapperClassName)}
      disabled={props.disabled}
      inline={isInline}
      error={error}
      onClick={() => inputRef.current?.focus()}>
      <LabelRow label={label} error={error} required={required} />
      <TextAreaComponent
        ref={inputRef}
        onChange={e => onChange(e.target.value)}
        plain
        focusIndicator={false}
        fill
        resize="vertical"
        value={value ?? undefined}
        {...props}
      />
    </InputWrapper>
  );
};

export const TextAreaFormField = forwardRef(({ name, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return <TextAreaInput ref={ref} error={meta.touched ? meta.error : ""} {...field} onChange={helpers.setValue} {...props} />;
});

export default TextAreaInput;
