import { createSelector } from "reselect";
import { scaleLinear } from "@vx/scale";
import moment from "moment";
import {
  getOpenedPortfolioBaseQuotes,
  getOpenedPortfolioHistory,
  getOpenedPortfolioBalances,
  getIsOpenedPortfolioEmpty,
  getPortfolioOpenedBalances,
  getPortfolioClosedBalances,
  getNativeOpenedBalances,
  getNativeClosedBalances,
} from ".";

export const getBaseQuoteOptionsForSelect = createSelector(
  [getPortfolioOpenedBalances, getPortfolioClosedBalances, getNativeOpenedBalances, getNativeClosedBalances],
  (ptfOpened, ptfClosed, natOpened, natClosed) => {
    const portfolio = [...ptfOpened, ...ptfClosed].map(o => o.base);
    const nativeObject = [...natOpened, ...natClosed].reduce((res, { base, quote }) => {
      if (!res[base]) res[base] = [{ base, quote }];
      else if (!res[base].find(o => o.quote === quote)) res[base].push({ base, quote });
      return res;
    }, {});

    const portfolioUnique = Array.from(new Set(portfolio));

    const res = [];
    portfolioUnique.forEach(o => {
      res.push(nativeObject[o] || []);
    });
    return res.flat();
  }
);

const generateFakeGraphHistory = () => {
  const steps = 183;
  const dayMiliseconds = 86400000;
  const from = 1550534400000;
  const fakeHistory = [];
  for (let i = 0; i < steps; i++) {
    fakeHistory.push({
      timestamp: from + i * dayMiliseconds,
      marketValue: Math.sin(i * 5 * (Math.PI / 180)) * (Math.sin(i * (Math.PI / 180)) * 0.75) * 1000,
      netValue: Math.sin(i * 5 * (Math.PI / 180)) * (Math.sin(i * (Math.PI / 180)) * 0.75) * 1000,
      marketReference: Math.sin(i * 5 * (Math.PI / 180)) * (Math.sin(i * (Math.PI / 180)) * -0.5) * 1000,
      acquisitionCost: Math.sin(i * 5 * (Math.PI / 180)) * (Math.sin(i * (Math.PI / 180)) * -0.3) * 1000,
    });
  }
  return {
    from,
    to: fakeHistory[fakeHistory.length - 1].timestamp,
    quote: { name: "USD", decimalDigits: 2 },
    graphData: fakeHistory,
  };
};

export const getPortfolioHistory = createSelector(
  [getOpenedPortfolioHistory, getIsOpenedPortfolioEmpty],
  ({ isHistoryFetching, ...portfolioHistory }, isEmpty) => {
    const isExample = !portfolioHistory.graphData || isEmpty;

    const fakeHis = generateFakeGraphHistory();

    const { quote, from, to, graphData } = isExample ? fakeHis : portfolioHistory;

    const digits = quote.decimalDigits;
    const lastElement = graphData[graphData.length - 1];

    const history = [...graphData, { ...lastElement, timestamp: to }].map(
      ({ timestamp, netValue, marketReference, marketValue, acquisitionCost, segments }) => {
        const stakeSegment = segments?.find(x => x.segmentName === "STAKING");

        return {
          timestamp: moment(timestamp).utc(),
          netValue: Number(netValue).toFixed(digits),
          marketReference: Number(marketReference).toFixed(digits),
          marketValue: Number(marketValue).toFixed(digits),
          acquisitionCost: Number(acquisitionCost).toFixed(digits),
          segments: {
            stake: {
              ...stakeSegment,
              netValue: Number(stakeSegment?.netValue).toFixed(digits),
              marketValue: Number(stakeSegment?.marketValue).toFixed(digits),
              acquisitionCost: Number(stakeSegment?.acquisitionCost).toFixed(digits),
            },
          },
        };
      }
    );

    const { portfolioHistoryMin, portfolioHistoryMax, profitHistoryMin, profitHistoryMax } = history.reduce(
      (res, val) => {
        // ***** PORTFOLIO HISTORY MIN + MAX *****
        const portfolioMin = Math.min(val.marketValue, val.acquisitionCost);
        const portfolioMax = Math.max(val.marketValue, val.acquisitionCost);
        const stakeHistoryMin = Math.min(val.segments?.stake?.marketValue, val.segments?.stake?.acquisitionCost);
        const stakeHistoryMax = Math.max(val.segments?.stake?.marketValue, val.segments?.stake?.acquisitionCost);

        if (res.portfolioHistoryMin > portfolioMin) res.portfolioHistoryMin = portfolioMin;
        if (res.portfolioHistoryMax < portfolioMax) res.portfolioHistoryMax = portfolioMax;
        if (res.portfolioHistoryMin > stakeHistoryMin) res.portfolioHistoryMin = stakeHistoryMin;
        if (res.portfolioHistoryMax < stakeHistoryMax) res.portfolioHistoryMax = stakeHistoryMax;

        // ***** PROFIT HISTORY MIN + MAX *****
        const profitMin = Math.min(val.netValue, val.marketReference);
        const profitMax = Math.max(val.netValue, val.marketReference);
        const stakeProfit = val.segments?.stake?.netValue;

        if (res.profitHistoryMin > profitMin) res.profitHistoryMin = profitMin;
        if (res.profitHistoryMax < profitMax) res.profitHistoryMax = profitMax;
        if (res.profitHistoryMin > stakeProfit) res.profitHistoryMin = stakeProfit;
        if (res.profitHistoryMax < stakeProfit) res.profitHistoryMax = stakeProfit;

        return res;
      },
      { portfolioHistoryMin: Infinity, portfolioHistoryMax: -Infinity, profitHistoryMin: Infinity, profitHistoryMax: -Infinity }
    );

    const ptfOffset = (portfolioHistoryMax - portfolioHistoryMin) * 0.1;
    const prfOffset = (profitHistoryMax - profitHistoryMin) * 0.1;

    return {
      currency: quote.name,
      portfolioHistory: {
        domains: {
          xDomain: [from, to],
          yDomain: [Math.min(portfolioHistoryMin, 0) - ptfOffset, Math.max(portfolioHistoryMax, 0) + ptfOffset],
        },
        history: history.map(o => ({
          timestamp: o.timestamp,
          fullY: o.marketValue,
          dottedY: o.acquisitionCost,
          stakeY: o.segments?.stake?.marketValue,
        })),
      },
      marketHistory: {
        domains: {
          xDomain: [from, to],
          yDomain: [Math.min(profitHistoryMin, 0) - prfOffset, Math.max(profitHistoryMax, 0) + prfOffset],
        },
        history: history.map(o => ({
          timestamp: o.timestamp,
          fullY: o.netValue,
          dottedY: o.marketReference,
          stakeY: o.segments?.stake?.netValue,
        })),
      },
      isExample,
      isHistoryFetching,
    };
  }
);

const COMPRESSION_THRESHOLD = 5;

export const getDistribution = createSelector(
  [getOpenedPortfolioBalances, getIsOpenedPortfolioEmpty],
  ({ inventory, isBalancesFetching }, isEmpty) => {
    const assets = isEmpty || !inventory ? [] : inventory.assets;

    const distribution = assets
      .sort((a, b) => b.percentage - a.percentage)
      .filter(o => o.quantity.value !== 0)
      .reduce(
        (res, val) => {
          if (val.short) {
            if (val.percentage >= COMPRESSION_THRESHOLD) {
              res.short.push({
                percentage: val.percentage,
                currency: val.quantity.description.name,
                label: val.quantity.description.name,
                short: true,
              });
            } else {
              if (!res.shortCompressed)
                res.shortCompressed = {
                  percentage: 0,
                  currency: "short_compressed",
                  label: "Shorts",
                  compressed: [],
                  short: true,
                };
              res.shortCompressed.compressed.push({ percentage: val.percentage, label: val.quantity.description.name });
              res.shortCompressed = {
                ...res.shortCompressed,
                percentage: (res.shortCompressed.percentage += val.percentage),
              };
            }
          } else if (val.percentage >= COMPRESSION_THRESHOLD) {
            res.long.push({
              percentage: val.percentage,
              currency: val.quantity.description.name,
              label: val.quantity.description.name,
              short: false,
            });
          } else {
            if (!res.longCompressed)
              res.longCompressed = { percentage: 0, currency: "long_compressed", label: "Longs", compressed: [], short: false };
            res.longCompressed.compressed.push({ percentage: val.percentage, label: val.quantity.description.name });
            res.longCompressed = {
              ...res.longCompressed,
              percentage: (res.longCompressed.percentage += val.percentage),
            };
          }
          return res;
        },
        { long: [], short: [] }
      );

    distribution.short.reverse();

    const shorts = distribution.shortCompressed ? [distribution.shortCompressed, ...distribution.short] : distribution.short;
    const longs = distribution.longCompressed ? [...distribution.long, distribution.longCompressed] : distribution.long;

    const completeDistribution = [...shorts, ...longs];
    const domain = completeDistribution.reduce(
      (res, val) => res - (val.percentage < COMPRESSION_THRESHOLD ? val.percentage : 0),
      100
    );
    const range = completeDistribution.reduce(
      (res, val) => res - (val.percentage < COMPRESSION_THRESHOLD ? COMPRESSION_THRESHOLD : 0),
      100
    ); // 101 - if it's set to 100, a little bit of graph is cut off;

    const xScale = scaleLinear({
      range: [0, range],
      domain: [0, domain],
    });

    return {
      distribution: completeDistribution.map(o => ({
        ...o,
        width: o.percentage < COMPRESSION_THRESHOLD ? COMPRESSION_THRESHOLD : xScale(o.percentage),
      })),
      isBalancesFetching,
      isEmpty,
    };
  }
);

const generateFakeHistory = () => {
  const steps = 61;
  const dayMiliseconds = 86400000;
  const from = 1550534400000;
  const fakeHistory = [];
  for (let i = 0; i < steps; i++) {
    fakeHistory.push({
      timestamp: from + i * dayMiliseconds,
      base: (Math.sin(i * 15 * (Math.PI / 180)) * 10 + Math.sin(i * 0.5 * (Math.PI / 180)) * 50) * (steps - i),
      quote: (Math.sin(i * 15 * (Math.PI / 180)) * -10 + Math.sin(i * 0.5 * (Math.PI / 180)) * -50) * (steps - i),
    });
  }
  return {
    from,
    to: fakeHistory[fakeHistory.length - 1].timestamp,
    graphData: fakeHistory,
  };
};

const dailySummaryMinMax = (res, val) => {
  if (res.baseMin > val.base) {
    res.baseMin = val.base;
    res.baseMinX = val.timestamp;
  }
  if (res.baseMax < val.base) {
    res.baseMax = val.base;
    res.baseMaxX = val.timestamp;
  }
  if (res.quoteMin > val.quote) {
    res.quoteMin = val.quote;
    res.quoteMinX = val.timestamp;
  }
  if (res.quoteMax < val.quote) {
    res.quoteMax = val.quote;
    res.quoteMaxX = val.timestamp;
  }
  return res;
};

const dailyMinMax = (res, val) => {
  // TODO: 1 function with selectors
  if (res.baseMin > val.baseMin) {
    res.baseMin = val.baseMin;
    res.baseMinX = val.timestamp;
  }
  if (res.baseMax < val.baseMax) {
    res.baseMax = val.baseMax;
    res.baseMaxX = val.timestamp;
  }
  if (res.quoteMin > val.quoteMin) {
    res.quoteMin = val.quoteMin;
    res.quoteMinX = val.timestamp;
  }
  if (res.quoteMax < val.quoteMax) {
    res.quoteMax = val.quoteMax;
    res.quoteMaxX = val.timestamp;
  }
  return res;
};

const getSummaryDay = (o, baseDigits, quoteDigits) => ({
  timestamp: o.timestamp,
  base: o.base.toFixed(baseDigits),
  quote: o.quote.toFixed(quoteDigits),
});

const getDay = (o, baseDigits, quoteDigits) => ({
  timestamp: o.timestamp,
  base: o.base.toFixed(baseDigits),
  quote: o.quote.toFixed(quoteDigits),
  baseMin: o.baseMin.toFixed(baseDigits),
  baseMax: o.baseMax.toFixed(baseDigits),
  quoteMin: o.quoteMin.toFixed(quoteDigits),
  quoteMax: o.quoteMax.toFixed(quoteDigits),
});

export const getPortfolioBaseQuoteHistory = createSelector([getOpenedPortfolioBaseQuotes], baseQuote => {
  const isExample = !baseQuote.graphData || baseQuote.isBQFetching;
  const bq = isExample ? { ...baseQuote, ...generateFakeHistory() } : baseQuote;

  const { baseMin, baseMax, quoteMin, quoteMax, baseMinX, baseMaxX, quoteMinX, quoteMaxX } = bq.graphData.reduce(
    bq.isSummary || isExample ? dailySummaryMinMax : dailyMinMax,
    { baseMin: Infinity, baseMax: -Infinity, quoteMin: Infinity, quoteMax: -Infinity }
  );

  const baseDigits = bq.base && bq.base.decimalDigits ? bq.base.decimalDigits : 6;
  const quoteDigits = bq.quote && bq.quote.decimalDigits ? bq.quote.decimalDigits : 2;

  const lastElement = bq.graphData[bq.graphData.length - 1];

  const history = [...bq.graphData, { ...lastElement, timestamp: bq.to }].map(o =>
    bq.isSummary || isExample ? getSummaryDay(o, baseDigits, quoteDigits) : getDay(o, baseDigits, quoteDigits)
  );

  return {
    domains: {
      xDomain: [bq.from, bq.to],
      yDomain: {
        baseDomain: [baseMin.toFixed(baseDigits), baseMax.toFixed(baseDigits)],
        quoteDomain: [quoteMin.toFixed(quoteDigits), quoteMax.toFixed(quoteDigits)],
      },
    },
    minMaxPositions: {
      baseMinX,
      baseMaxX,
      quoteMinX,
      quoteMaxX,
    },
    history,
    isExample,
    isSummary: bq.isSummary || isExample,
    baseCurrency: bq.base ? bq.base.name : "",
    quoteCurrency: bq.quote ? bq.quote.name : "",
  };
});
