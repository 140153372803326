import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { Form, PrimaryButton } from "components/Common";
import { formatDate, roundToFixed, utc } from "common/formatters";
import { marketDataService } from "services";
import {
  isTxTypeBuySell,
  isTxTypeDepositWithdrawal,
  isTxTypeFeeRebate,
  isTxTypeReward,
  isTxTypeStake,
  transactionTypeTranslator,
} from "common/constants/transactionType";
import { useHasChanged } from "hooks/useHasChanged";
import BuySellTransactionForm from "containers/Forms/Transactions/TransactionTypes/BuySellTransactionForm";
import FeeRebateTransactionForm from "containers/Forms/Transactions/TransactionTypes/FeeRebateTransactionForm";
import DepositWithdrawalTransactionForm from "containers/Forms/Transactions/TransactionTypes/DepositWithdrawalTransactionForm";
import StakeTransactionForm from "containers/Forms/Transactions/TransactionTypes/StakeTransactionForm";
import RewardTransactionForm from "containers/Forms/Transactions/TransactionTypes/RewardTransactionForm";
import { rewardTimingTranslator } from "common/constants/rewardTimingOptions";
import { getTransactionRealizationType } from "utils";
import styles from "../../Styles/Forms.module.scss";

const TransactionFormContent = props => {
  const {
    isSubmitting,
    handleSubmit,
    values,
    setFieldValue,
    isEdit,
    subtransactionType,
    isTransfer,
    counterTransactions,
    rewardTxUnitPriceCurrency,
    activeOrganizationId,
    ruleSets,
    hideModal,
    setLastUsedCurrencies,
    modalOptions,
  } = props;

  const { t } = useTranslation();

  const [rateLoading, setRateLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const getRate = async ({ base, quote, date }) => {
    if (base && quote && date) {
      setRateLoading(true);
      const res = await marketDataService.getRate(base, quote, date);
      if (res) setFieldValue("unitPrice", roundToFixed(res.rate));
      setRateLoading(false);
    }
  };

  const txType = values.type?.value;

  useEffect(() => {
    if (!subtransactionType) {
      setFieldValue("feeRebateCurrency", "");
      setFieldValue("feeRebate", "");
    }
  }, [subtransactionType, setFieldValue]);

  const isBuySell = isTxTypeBuySell(txType);
  const isFeeRebate = isTxTypeFeeRebate(txType);
  const isDepositWithdrawal = isTxTypeDepositWithdrawal(txType);
  const isStake = isTxTypeStake(txType);
  const isReward = isTxTypeReward(txType);

  const areFurtherInputsDisabled = useMemo(
    () => (isBuySell && !values.quoteCurrency) || !values.baseCurrency || !values.type || !values.timestamp || !values.date,
    [values, isBuySell]
  );
  const hasFurtherInputsDisabledChanged = useHasChanged(areFurtherInputsDisabled);

  const onSubmit = () => {
    if (isTransfer && counterTransactions.length === 0) {
      setIsSubmitted(true);
      return;
    }
    handleSubmit();
    setLastUsedCurrencies([values.baseCurrency?.value, values.quoteCurrency?.value].filter(x => !!x));
  };

  const rateValues = {
    date: values.date,
    base: values.baseCurrency?.value,
    quote: isReward ? rewardTxUnitPriceCurrency : values.quoteCurrency?.value,
  };

  // Call unit price once after first inputs are filled
  useEffect(() => {
    if (hasFurtherInputsDisabledChanged && !isEdit) {
      getRate(rateValues, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFurtherInputsDisabledChanged, setFieldValue, values, isEdit]);

  // Call unit price on every tx type change
  useUpdateEffect(() => {
    if (values.type) {
      getRate({
        date: values.date,
        base: values.baseCurrency?.value,
        quote: isReward ? rewardTxUnitPriceCurrency : values.quoteCurrency?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  // Calculated based on current tx date
  const txRealizationType = useMemo(
    () => getTransactionRealizationType(txType, formatDate(utc(values.date)), ruleSets),
    [values, ruleSets, txType]
  );

  const submitButton = (
    <PrimaryButton
      label={isEdit ? t("form.add_transaction.edit_transaction") : t("form.add_transaction.add_transaction")}
      isLoading={isSubmitting}
      type="submit"
      className={styles.submit_button}
      testId="save-transaction"
    />
  );

  const innerProps = {
    ...props,
    rateLoading,
    setRateLoading,
    getRate,
    isSubmitted,
    setIsSubmitted,
    txType,
    areFurtherInputsDisabled,
    txRealizationType,
    submitButton,
    modalOptions,
  };

  return (
    <Form onSubmit={onSubmit} className={styles.add_transaction_form}>
      {(isBuySell || !txType) && <BuySellTransactionForm {...innerProps} />}
      {isFeeRebate && <FeeRebateTransactionForm {...innerProps} />}
      {isDepositWithdrawal && <DepositWithdrawalTransactionForm {...innerProps} />}
      {isStake && <StakeTransactionForm {...innerProps} />}
      {isReward && <RewardTransactionForm {...innerProps} />}

      {txRealizationType && (
        <div className="w-100 d-flex mt-4 mb-n3 justify-content-center" tabIndex={-1}>
          {`${t("organization.taxation_of")} ${t(transactionTypeTranslator(txType))}`}
          <Link to={`/organizations/${activeOrganizationId}/tax-rules`} onClick={hideModal} className="ml-2 focus_outline">
            {t(rewardTimingTranslator(txRealizationType))}
          </Link>
        </div>
      )}
    </Form>
  );
};

export default TransactionFormContent;
