import React from "react";
import {
  Add as AddIcon,
  Duplicate as DuplicateIcon,
  Edit as EditIcon,
  Next as NextIcon,
  Trash as TrashIcon,
} from "grommet-icons/icons";
import { FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import { GhostButton } from "components/Common";
import history from "common/history";
import { DecimalFormField, SelectFormField } from "components/Common/Inputs";
import { CurrencyDecimal } from "components/Formating";
import styles from "../../Styles/Forms.module.scss";
import { useScreenSize } from "hooks";
import Delimiter from "components/Common/Delimiter";
import { dateTimeFormatWithSeconds, formatDate } from "common/formatters";
import cx from "classnames";
import { ActionMenu } from "components/Common/ActionMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faArrowUpRightFromSquare, faCheck } from "@fortawesome/free-solid-svg-icons";
import { openInNewTab } from "common/utils";

const validateAccountInitialBalanceRow = async (values, index, validate, setTouched) => {
  const result = await validate();
  if (result.accountInitialBalances?.balances) {
    setTouched(`accountInitialBalances.balances.[${index}].account`);
    setTouched(`accountInitialBalances.balances.[${index}].initialState`);
    return false;
  }
  return true;
};

function AccountsInitialState({
  values,
  accountInitialBalances,
  isSubmitting,
  accounts,
  accountOptions,
  accountInitialBalanceOpenedIndex,
  setAccountInitialBalanceOpenedIndex,
  validateForm,
  setFieldTouched,
  modalActions,
  enhancedSelectStyles,
  handleCopyBalances,
  portfolioIdSearch,
  openDeleteAllAccounts,
}) {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const isEmpty = !accountInitialBalances.balances || accountInitialBalances.balances?.length === 0;

  const sourcePortfolioId = accountInitialBalances?.sourcePortfolioId;

  return (
    <>
      {accounts.length === 0 ? (
        <GhostButton
          label={t("form.portfolio_detail.no_accounts")}
          icon={<NextIcon />}
          iconRight
          className="mb-3"
          onClick={() => {
            modalActions.hideModal();
            history.push(`/asset-accounts${portfolioIdSearch}`);
          }}
        />
      ) : (
        <>
          <FieldArray
            className="mt-3"
            name="accountInitialBalances.balances"
            render={arrayHelpers => {
              const addAccount = async () => {
                if (
                  await validateAccountInitialBalanceRow(values, accountInitialBalanceOpenedIndex, validateForm, setFieldTouched)
                ) {
                  setAccountInitialBalanceOpenedIndex(accountInitialBalances.balances.length);
                  arrayHelpers.push({ account: "", initialBalance: "" });
                }
              };

              return (
                <div className={styles.initial_balances}>
                  <>
                    <div className="d-flex justify-content-between mb-3">
                      <b>{t("form.portfolio_detail.source_portfolio")}</b>

                      <div className="d-flex align-items-center">
                        <GhostButton icon={<EditIcon />} onClick={handleCopyBalances} actionButton />

                        <ActionMenu testId="accounts-menu">
                          <ActionMenu.ItemGroup key={1}>
                            <ActionMenu.Item
                              label={t("form.portfolio_detail.edit_portfolio")}
                              icon={<EditIcon />}
                              onClick={handleCopyBalances}
                            />

                            <ActionMenu.Item
                              icon={<AddIcon />}
                              label={t("form.portfolio_detail.add_balance")}
                              disabled={isSubmitting || accountInitialBalanceOpenedIndex !== null}
                              onClick={addAccount}
                            />

                            <ActionMenu.Item
                              icon={<FontAwesomeIcon icon={faArrowsRotate} />}
                              label={t("form.portfolio_detail.refresh_balances")}
                              disabled
                              testId="action-refresh-balances"
                            />

                            <ActionMenu.Item
                              icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                              label={t("form.portfolio_detail.source_portfolio")}
                              onClick={() => openInNewTab(`/dashboard/${sourcePortfolioId}`)}
                              testId="action-source-ptf"
                              hidden={!sourcePortfolioId}
                            />
                          </ActionMenu.ItemGroup>
                          <ActionMenu.ItemGroup key={2} hasSeparator>
                            <ActionMenu.Item
                              icon={<TrashIcon />}
                              label={t("button.delete_all")}
                              onClick={openDeleteAllAccounts}
                              disabled={
                                isSubmitting ||
                                (!sourcePortfolioId &&
                                  (!accountInitialBalances?.balances || accountInitialBalances.balances.length === 0))
                              }
                              critical
                            />
                          </ActionMenu.ItemGroup>
                        </ActionMenu>
                      </div>
                    </div>

                    <div className="mb-4 mt-2">
                      {accountInitialBalances?.portfolio ? (
                        <>
                          {accountInitialBalances?.portfolio?.label || ""}
                          {accountInitialBalances.timestamp ? ` (${formatDate(accountInitialBalances.timestamp)})` : ""}
                          {accountInitialBalances.updatedAt && (
                            <>
                              , {isSmall && <br />}
                              {t("form.portfolio_detail.update")}:{" "}
                              {formatDate(accountInitialBalances.updatedAt, dateTimeFormatWithSeconds)}
                            </>
                          )}
                        </>
                      ) : isEmpty ? (
                        <GhostButton
                          label={t("form.portfolio_detail.copy_account_balances")}
                          icon={<DuplicateIcon />}
                          onClick={handleCopyBalances}
                          className="px-0 mx-0 ml-n1"
                        />
                      ) : (
                        "~"
                      )}
                    </div>
                  </>

                  {!isEmpty && (
                    <>
                      <div className={styles.initial_balances_header}>
                        <b>{t("form.portfolio_detail.account_initial_balances")}</b>
                      </div>

                      <div className="position-relative" style={{ overflow: isSmall ? "auto" : "visible" }}>
                        <div className={styles.asset_accounts_grid}>
                          <span className={styles.header}>{t("form.portfolio_detail.currency_short")}</span>
                          <span className={styles.header}>{t("form.portfolio_detail.account_name")}</span>
                          <span className={styles.header} style={{ textAlign: "right", marginRight: isSmall ? "10px" : "60px" }}>
                            {t("form.portfolio_detail.balance")}
                          </span>
                          <span />

                          <Delimiter className={styles.accounts_delimiter} />

                          {accountInitialBalances.balances.map(({ account, initialBalance }, i) => {
                            const isRowOpened = i === accountInitialBalanceOpenedIndex;

                            return (
                              <React.Fragment key={`accountInitialBalances.balances[${i}]`}>
                                {isRowOpened ? (
                                  <>
                                    <div className={styles.label}>{account?.value?.currency}</div>
                                    <SelectFormField
                                      name={`accountInitialBalances.balances[${i}].account`}
                                      options={accountOptions.filter(
                                        o =>
                                          accountInitialBalances.balances.find(x => x.account.value?.id === o.value.id) ===
                                          undefined
                                      )}
                                      required
                                      disabled={isSubmitting}
                                      wrapperClassName={cx(styles.small_form_field, styles.small_form_field_currency)}
                                      styles={enhancedSelectStyles}
                                      maxMenuHeight={245}
                                    />
                                    <DecimalFormField
                                      name={`accountInitialBalances.balances[${i}].initialBalance`}
                                      required
                                      disabled={isSubmitting}
                                      wrapperClassName={styles.small_form_field}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className={styles.label}>{account?.value?.currency}</div>
                                    <div className={styles.label}>
                                      <span>{account?.value?.name}</span>
                                    </div>
                                    <div
                                      className={cx(styles.label, { "negative-value": initialBalance < 0 })}
                                      style={{ justifyContent: "end", marginRight: isSmall ? "10px" : "60px" }}>
                                      <CurrencyDecimal className={styles.label} number={initialBalance} />
                                      &nbsp;{account?.value?.currency}
                                    </div>
                                  </>
                                )}

                                <ActionMenu testId={`account-menu_row-${i}`}>
                                  <ActionMenu.ItemGroup key={1}>
                                    <ActionMenu.Item
                                      icon={<FontAwesomeIcon icon={faCheck} />}
                                      label={t("button.save")}
                                      onClick={async () => {
                                        if (
                                          await validateAccountInitialBalanceRow(
                                            values,
                                            accountInitialBalanceOpenedIndex,
                                            validateForm,
                                            setFieldTouched
                                          )
                                        ) {
                                          setAccountInitialBalanceOpenedIndex(null);
                                        }
                                      }}
                                      testId={`save-balance_row-${i}`}
                                      hidden={!isRowOpened}
                                    />

                                    <ActionMenu.Item
                                      icon={<EditIcon />}
                                      label={t("button.edit")}
                                      disabled={accountInitialBalanceOpenedIndex !== null || isSubmitting}
                                      onClick={() => setAccountInitialBalanceOpenedIndex(i)}
                                      testId={`edit-balance_row-${i}`}
                                      hidden={isRowOpened}
                                    />

                                    <ActionMenu.Item
                                      icon={<AddIcon />}
                                      label={t("button.add")}
                                      disabled={isSubmitting || accountInitialBalanceOpenedIndex !== null}
                                      onClick={addAccount}
                                      testId={`add-balance_row-${i}`}
                                    />
                                  </ActionMenu.ItemGroup>
                                  <ActionMenu.ItemGroup key={2} hasSeparator>
                                    <ActionMenu.Item
                                      icon={<TrashIcon />}
                                      label={t("button.delete")}
                                      onClick={() => {
                                        arrayHelpers.remove(i);
                                        setAccountInitialBalanceOpenedIndex(null);
                                      }}
                                      critical
                                      testId={`delete-balance_row-${i}`}
                                      hidden={!isRowOpened}
                                    />

                                    <ActionMenu.Item
                                      icon={<TrashIcon />}
                                      label={t("button.delete")}
                                      disabled={accountInitialBalanceOpenedIndex !== null || isSubmitting}
                                      onClick={() => {
                                        if (accountInitialBalanceOpenedIndex === i) {
                                          setAccountInitialBalanceOpenedIndex(null);
                                          arrayHelpers.remove(i);
                                        } else {
                                          arrayHelpers.remove(i);
                                          if (accountInitialBalanceOpenedIndex > i)
                                            setAccountInitialBalanceOpenedIndex(accountInitialBalanceOpenedIndex - 1);
                                        }
                                      }}
                                      critical
                                      testId={`delete-balance_row-${i}`}
                                      hidden={isRowOpened}
                                    />
                                  </ActionMenu.ItemGroup>
                                </ActionMenu>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }}
          />
        </>
      )}
    </>
  );
}

export default AccountsInitialState;
