import React from "react";
import { connect } from "react-redux";
import { getOpenedPortfolio } from "selectors/portfoliosSelectors";
import LegacyPositions from "components/Dashboard/LegacyPositions";
import Positions from "components/Dashboard/Positions";

const PositionsContainer = ({ openedPortfolio, currentDateRuleSet }) =>
  openedPortfolio.experimentalComputation ? <Positions currentDateRuleSet={currentDateRuleSet} /> : <LegacyPositions />;

function mapStateToProps(state) {
  return {
    openedPortfolio: getOpenedPortfolio(state),
  };
}
export default connect(mapStateToProps)(PositionsContainer);
