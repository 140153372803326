import React, { useMemo } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { validateSubtransaction } from "common/validators";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { transactionService } from "services/transactionService";
import { alertActions } from "actions/alertActions";
import { FEE, REBATE, transactionTypeTranslator } from "common/constants/transactionType";
import { formatDate, formatWithoutTrailingZeros, utc } from "common/formatters";
import { bucketActions } from "actions/bucketActions";
import { getCurrencyOptions } from "selectors";
import FeeRebateFormContent from "./FeeRebateFormContent";

const EditFeeRebateTransactionForm = ({
  transactionContainerId,
  transactionId,
  labelOptions,
  transaction,
  transactionActions: { getTransactions },
  modalActions: { hideModal },
  bucketActions: { getBuckets },
  user,
  currencyOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();
  const isSubtransaction = !!transaction.mainTransactionId;

  const transactionTypeOptions = [
    { value: FEE, label: t(`${transactionTypeTranslator(FEE)}`) },
    { value: REBATE, label: t(`${transactionTypeTranslator(REBATE)}`) },
  ];

  const { baseCurrency, quoteCurrency } = transaction;

  const currencyOptionsWithTxCurrencies = useMemo(
    () =>
      isSubtransaction
        ? [
            {
              label: t("form.add_transaction.tx_currencies"),
              options: [
                currencyOptions.find(x => x.value === baseCurrency),
                ...(baseCurrency !== quoteCurrency ? [currencyOptions.find(x => x.value === quoteCurrency)] : []),
              ],
            },
            {
              label: t("form.add_transaction.all_currencies"),
              options: currencyOptions.filter(x => x.value !== baseCurrency && x.value !== quoteCurrency),
            },
          ]
        : currencyOptions,
    [baseCurrency, quoteCurrency, currencyOptions, isSubtransaction, t]
  );

  const onSubmit = async (
    { date, timestamp, type, feeRebateCurrency, labels, exchangeName, addressesStr, ...tx },
    { setSubmitting }
  ) => {
    const dateMoment = utc(date);

    try {
      await transactionService.editTransaction(
        {
          ...tx,
          type: type.value,
          feeRebateCurrency: feeRebateCurrency.value,
          ...(!isSubtransaction && {
            baseCurrency: feeRebateCurrency.value,
            quoteCurrency: feeRebateCurrency.value,
          }),
          timestamp: timestamp
            .set({
              year: dateMoment.year(),
              month: dateMoment.month(),
              date: dateMoment.date(),
            })
            .valueOf(),
          transactionLabels: labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
        },
        transactionId,
        transactionContainerId,
        user
      );

      hideModal();
      alertActions.success(t("alert.success.transaction_edited"));
      getTransactions();
      getBuckets();
    } catch (err) {
      alertActions.error(err);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        ...transaction,
        type: transactionTypeOptions.find(o => o.value === transaction.type) || transactionTypeOptions[0],
        feeRebateCurrency: currencyOptions.find(o => o.value === transaction.feeRebateCurrency) || currencyOptions[0],
        feeRebate: formatWithoutTrailingZeros(transaction.feeRebate),
        note: transaction.note ?? "",
        date: formatDate(utc(transaction.timestamp)),
        timestamp: utc(transaction.timestamp),
        labels: transaction.transactionLabels?.map(x => labelOptions.find(y => y.value === x.transactionLabelId)) ?? [],
      }}
      validate={validateSubtransaction}
      onSubmit={onSubmit}>
      {props => (
        <FeeRebateFormContent
          {...props}
          transactionTypeOptions={transactionTypeOptions}
          currencyOptionsWithTxCurrencies={currencyOptionsWithTxCurrencies}
          isSubtransaction={isSubtransaction}
          isEdit
          labelOptions={labelOptions}
          modalOptions={modalOptions}
        />
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    currencyOptions: getCurrencyOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFeeRebateTransactionForm);
