import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object } from "yup";
import { getYear } from "date-fns";
import { DateFormField, DecimalFormField, SelectFormField } from "components/Common/Inputs";
import { Form, PrimaryButton } from "components/Common";
import {
  ACCOUNTING_REPORT_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX,
  ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT,
  ACCOUNTING_SOFTWARE_REPORT_FORMAT,
  accountingSoftwareDataFormatTranslator,
  accountingSoftwareTypeTranslator,
  DATA_FORMAT_XLSX,
  POHODA_FORMAT,
  WHALEBOOKS_FORMAT,
} from "common/constants/accountSettings";
import { accountingIncludeOptions } from "common/constants/accountingIncludeOptions";
import styles from "../Styles/Forms.module.scss";

const AccountingReportForm = ({ from, to, onClose, onSubmit, accountSettings }) => {
  const { t } = useTranslation();

  const handleSubmit = async (values, { setSubmitting }) => {
    const reportType = values.type?.value;

    const formattedValues = {
      from: values.range?.from,
      to: values.range?.to,
      type: reportType,
      dataFormat: values.dataFormat?.value,
      firstReportNumber: values.firstReportNumber !== "" ? Number(values.firstReportNumber) : undefined,
      includeDeposits:
        reportType === WHALEBOOKS_FORMAT
          ? values.includeDeposits?.value
          : reportType === POHODA_FORMAT
          ? values.includeDepositsPohoda?.value
          : undefined,
      includeWithdrawals:
        reportType === WHALEBOOKS_FORMAT
          ? values.includeWithdrawals?.value
          : reportType === POHODA_FORMAT
          ? values.includeWithdrawalsPohoda?.value
          : undefined,
    };

    await onSubmit(formattedValues);
    setSubmitting(false);
    if (onClose) onClose();
  };

  const dataFormatOptions = [{ value: DATA_FORMAT_XLSX, label: t(accountingSoftwareDataFormatTranslator(DATA_FORMAT_XLSX)) }];
  const includeOptions = accountingIncludeOptions(t);

  const yearEnd = getYear(new Date()).toString().substr(2, 2);
  const getConfigValue = name => accountSettings?.config?.find(x => x.name === name)?.value;

  const formatOptions = [
    {
      value: WHALEBOOKS_FORMAT,
      label: `${accountingSoftwareTypeTranslator(WHALEBOOKS_FORMAT)}${
        accountSettings?.name === WHALEBOOKS_FORMAT ? " (default)" : ""
      }`,
    },
    {
      value: POHODA_FORMAT,
      label: `${accountingSoftwareTypeTranslator(POHODA_FORMAT)}${accountSettings?.name === POHODA_FORMAT ? " (default)" : ""}`,
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: formatOptions.find(x => x.value === accountSettings?.name) ?? "",
        // Whalebooks
        dataFormat:
          dataFormatOptions.find(x => x.value === getConfigValue(ACCOUNTING_SOFTWARE_REPORT_FORMAT)) || dataFormatOptions[0],
        includeDeposits:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_INCLUDE_DEPOSIT)) ?? includeOptions[0],
        includeWithdrawals:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL)) ?? includeOptions[0],

        // Pohoda
        dataFormatPohoda:
          dataFormatOptions.find(x => x.value === getConfigValue(ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT)) ||
          dataFormatOptions[0],
        range: "",
        firstReportNumber: "",
        includeDepositsPohoda:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT)) ?? includeOptions[0],
        includeWithdrawalsPohoda:
          includeOptions.find(x => x.value === getConfigValue(ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL)) ?? includeOptions[0],
      }}
      onSubmit={handleSubmit}
      validationSchema={object({
        type: object().required(),
        range: object().required(),
      })}>
      {({ values, isSubmitting, handleSubmit, setFieldValue }) => {
        const areFurtherInputsDisabled = isSubmitting || !values.type;

        return (
          <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
            <SelectFormField
              name="type"
              label={t("form.download_accounting_report.type")}
              options={formatOptions}
              required
              disabled={isSubmitting}
            />

            {values.type?.value === WHALEBOOKS_FORMAT && (
              <SelectFormField
                name="dataFormat"
                label={t("form.download_accounting_report.data_format")}
                options={dataFormatOptions}
                required
                disabled={areFurtherInputsDisabled}
              />
            )}
            {values.type?.value === POHODA_FORMAT && (
              <SelectFormField
                name="dataFormatPohoda"
                label={t("form.download_accounting_report.data_format")}
                options={dataFormatOptions}
                required
                disabled={areFurtherInputsDisabled}
              />
            )}

            <DateFormField
              name="range"
              label={t("form.download_accounting_report.period")}
              disabled={areFurtherInputsDisabled}
              required
              isRangeSelect
              isClearable
              onClear={() => setFieldValue("range", "")}
              showRangeButtons
              hideOutOfRangeOptions
              thisPortfolioRange={{ from, to }}
              minDate={from}
              maxDate={to}
            />

            {values.type?.value === WHALEBOOKS_FORMAT && (
              <>
                <SelectFormField
                  name="includeDeposits"
                  label={t("form.account_settings.include_deposits")}
                  options={includeOptions}
                  required
                  disabled={areFurtherInputsDisabled}
                />
                <SelectFormField
                  name="includeWithdrawals"
                  label={t("form.account_settings.include_withdrawals")}
                  options={includeOptions}
                  required
                  disabled={areFurtherInputsDisabled}
                />
              </>
            )}

            {values.type?.value === POHODA_FORMAT && (
              <>
                <div className="d-flex align-items-center">
                  <div className="mr-2" style={{ marginTop: "12px", opacity: isSubmitting ? 0.4 : undefined }}>
                    {yearEnd}
                  </div>
                  <div className="mr-2" style={{ marginTop: "12px", opacity: isSubmitting ? 0.4 : undefined }}>
                    {getConfigValue(ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX)}
                  </div>
                  <DecimalFormField
                    name="firstReportNumber"
                    label={t("form.download_accounting_report.first_report_number")}
                    disabled={isSubmitting}
                    maxLength={5}
                    thousandSeparator=""
                    allowNegative={false}
                    decimalScale={0}
                  />
                </div>

                <SelectFormField
                  name="includeDepositsPohoda"
                  label={t("form.account_settings.include_deposits")}
                  options={includeOptions}
                  required
                  disabled={areFurtherInputsDisabled}
                />
                <SelectFormField
                  name="includeWithdrawalsPohoda"
                  label={t("form.account_settings.include_withdrawals")}
                  options={includeOptions}
                  required
                  disabled={areFurtherInputsDisabled}
                />
              </>
            )}

            <PrimaryButton
              onClick={handleSubmit}
              noIcon
              label={t("form.download_accounting_report.download")}
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AccountingReportForm;
