import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const EditApiConnection = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.edit_api_connection.heading")}
      confirmLabel={t("confirmation.edit_api_connection.confirm_label")}
      cancelLabel={t("confirmation.edit_api_connection.cancel_label")}
      critical>
      <p>
        <Trans i18nKey="confirmation.edit_api_connection.content" />
      </p>
    </ConfirmationEnvelope>
  );
};

export default EditApiConnection;
