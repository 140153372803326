import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getActivePortfolioId, getContainerDetail } from "selectors";
import { bindActionCreators } from "redux";
import { transactionActions } from "actions/transactionActions";
import { LoaderOverlay } from "components/Common";
import { modalActions } from "actions/modalActions";
import { transactionService } from "services";
import ModalEnvelope from "./ModalEnvelope";
import ContainerDetailForm from "../Forms/ContainerDetailForm";

const EditContainerDetailModal = ({
  containerId,
  containerDetail,
  activePortfolioId,
  isLoadingContainerDetail,
  user,
  onClose,
  transactionActions: { updateContainer, getContainerDetail },
  modalActions: { hideModal },
}) => {
  const [accounts, setAccounts] = useState([]);

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const isLoading = isLoadingContainerDetail || isLoadingAccounts;

  useEffect(() => {
    if (containerId) {
      getContainerDetail(containerId);
      setIsLoadingAccounts(true);
      transactionService.getContainerDetailCurrencyAccounts(containerId, user).then(response => {
        setAccounts(response);
        setIsLoadingAccounts(false);
      });
    }
  }, [containerId, getContainerDetail, user]);

  const container = isLoading
    ? undefined
    : { ...containerDetail, accountIds: accounts.map(x => x.assignedAcc?.id).filter(x => !!x) };

  return (
    <ModalEnvelope heading={container?.name} onClose={onClose}>
      <LoaderOverlay isLoading={isLoading}>
        <ContainerDetailForm
          container={container}
          onClose={() => (onClose ? onClose() : hideModal())}
          updateContainer={updateContainer}
          activePortfolioId={activePortfolioId}
        />
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state, { containerId }) {
  return {
    containerDetail: getContainerDetail(state),
    activePortfolioId: getActivePortfolioId(state),
    isLoadingContainerDetail: state.dataManager.containerDetailFetching[containerId],
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContainerDetailModal);
