import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { portfolioActions } from "actions/portfolioActions";
import { getRawOpenedPortfolio, getRuleSets } from "selectors";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import { useUrlHelper } from "hooks";
import { useIsMounted } from "hooks/useIsMounted";
import AddPortfolioButton from "containers/Dashboard/AddPortfolioButton";
import DashboardHeading from "containers/Dashboard/DashboardHeading";
import DashboardContainer from "containers/Dashboard/DashboardContainer";
import styles from "pages/Pages.module.scss";
import { doDateRangesOverlap, getFilteredRuleSets } from "utils";
import { endOfDay, startOfDay } from "date-fns";
import { useDefaultsStore } from "stores/defaultsStore";

const Dashboard = ({
  listOfPortfolios: { isFetching, activePortfolio, portfolios },
  isSelectedPortfolioValid,
  rawOpened,
  ruleSets,
  portfolioActions: { setActivePortfolio, loadPortfolioData },
}) => {
  const isMounted = useIsMounted();
  const { id } = useUrlHelper();
  const { t } = useTranslation();

  const { portfolioGraphDateRange, setPortfolioGraphDateRange } = useDefaultsStore();
  const dateRange = portfolioGraphDateRange;
  const setDateRange = setPortfolioGraphDateRange;

  useEffect(() => {
    if (!Number.isNaN(id)) setActivePortfolio(id);
  }, [id, setActivePortfolio]);

  useEffect(() => {
    if (activePortfolio !== null) loadPortfolioData(activePortfolio);
  }, [activePortfolio, loadPortfolioData]);

  const hasNoPortfolios = portfolios.length === 0 && !isFetching;

  // Rule set for currently selected date range, based on issues:
  // ETD-556 display tax rule set name in ptf
  // ETD-678 display current date rule set in ptf heading
  const currentDateRuleSet = useMemo(() => {
    if (ruleSets && rawOpened) {
      const from = dateRange.from ?? rawOpened.start;
      const to = dateRange.to ?? rawOpened.end;

      // Rule sets relevant for current portfolio
      const filteredRuleSets = getFilteredRuleSets(ruleSets, from, to);

      const todayFrom = startOfDay(new Date());
      const todayTo = endOfDay(new Date());

      // Rule set valid for today's date
      return filteredRuleSets.find(x =>
        doDateRangesOverlap(
          { from: new Date(x.validFrom), to: new Date(x.validTo || "2100-01-01") },
          { from: todayFrom, to: todayTo }
        )
      );
    }
  }, [ruleSets, rawOpened, dateRange]);

  return (
    <div className={styles.app_content_padding}>
      <DashboardHeading noPortfolio={hasNoPortfolios} taxRuleSetName={rawOpened?.latestRuleSet?.name} />

      {isMounted.current && isSelectedPortfolioValid && (
        <DashboardContainer dateRange={dateRange} setDateRange={setDateRange} currentDateRuleSet={currentDateRuleSet} />
      )}

      {!isSelectedPortfolioValid && !isFetching && (
        <div className={styles.icon_header_label}>
          <Heading level={3}>
            {activePortfolio === null ? t("dashboard.no_portfolio_message") : t("dashboard.portfolio_not_found_message")}
          </Heading>
          {hasNoPortfolios && <AddPortfolioButton />}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    listOfPortfolios: state.listOfPortfolios,
    isSelectedPortfolioValid: getRawOpenedPortfolio(state) !== undefined,
    rawOpened: getRawOpenedPortfolio(state),
    ruleSets: getRuleSets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
