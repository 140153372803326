import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { modalActions } from "actions/modalActions";
import AccountSettingsForm from "containers/Forms/AccountSettingsForm";
import {
  ACCOUNTING_REPORT_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT,
  ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL,
  ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX,
  ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES,
  ACCOUNTING_REPORT_REFERENCE_MAPPING,
  ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING,
  ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT,
  ACCOUNTING_SOFTWARE_REPORT_FORMAT,
  ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION,
  ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION,
} from "common/constants/accountSettings";
import ModalEnvelope from "./ModalEnvelope";

const AccountSettingsModal = ({
  organizationId,
  settings,
  organizationActions: { updateOrganizationAccountSettings },
  modalActions: { hideModal },
}) => {
  const { t } = useTranslation();

  const onSubmit = async (values, { setSubmitting }) => {
    const formattedValues = {
      ...settings,
      accountSettings: {
        name: values.name?.value,
        config: [
          // Whalebooks
          { name: ACCOUNTING_SOFTWARE_REPORT_FORMAT, value: values.dataFormat?.value },
          { name: ACCOUNTING_REPORT_INCLUDE_DEPOSIT, value: values.includeDeposits?.value },
          { name: ACCOUNTING_REPORT_INCLUDE_WITHDRAWAL, value: values.includeWithdrawals?.value },
          { name: ACCOUNTING_REPORT_REFERENCE_MAPPING, value: values.referenceMapping?.value },
          { name: ACCOUNTING_REPORT_MOMENT_OF_ACQUISITION, value: values.momentOfAcquisition?.value },

          // Pohoda
          { name: ACCOUNTING_SOFTWARE_POHODA_REPORT_FORMAT, value: values.dataFormatPohoda?.value },
          { name: ACCOUNTING_REPORT_POHODA_SUB_ACCOUNT_PLACES, value: values.numberOfPlacesOfSubAccounts?.value },
          { name: ACCOUNTING_REPORT_POHODA_NUMBER_PREFIX, value: values.queuePrefix },
          { name: ACCOUNTING_REPORT_POHODA_INCLUDE_DEPOSIT, value: values.includeDepositsPohoda?.value },
          { name: ACCOUNTING_REPORT_POHODA_INCLUDE_WITHDRAWAL, value: values.includeWithdrawalsPohoda?.value },
          { name: ACCOUNTING_REPORT_POHODA_REFERENCE_MAPPING, value: values.referenceMappingPohoda?.value },
          { name: ACCOUNTING_REPORT_POHODA_MOMENT_OF_ACQUISITION, value: values.momentOfAcquisitionPohoda?.value },
        ],
      },
    };

    await updateOrganizationAccountSettings(organizationId, formattedValues);
    setSubmitting(false);
    hideModal();
  };

  return (
    <ModalEnvelope id="account-settings-modal" heading={t("modal.account_settings.heading")}>
      <AccountSettingsForm accountSettings={settings.accountSettings} onClose={hideModal} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(AccountSettingsModal);
