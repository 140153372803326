import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { GhostButton } from "components/Common";
import { useTranslation } from "react-i18next";
import styles from "./Styles/App.module.scss";
import history from "common/history";
import { AccountantIcon, WalkthroughIcon } from "img/icons";

const SpecialOffers = ({
  user: { isAuthenticated },
  walkthroughSetupNeeded,
  modalActions: { openSearchForAccountant, openWalkthrough, openWalkthroughStartModal },
}) => {
  const { t } = useTranslation();

  return isAuthenticated ? (
    <div className={styles.special_offers}>
      <div>
        <GhostButton
          icon={<AccountantIcon className="clear-fill" style={{ height: "24px", width: "24px" }} />}
          label={t("page.organization.search_for_accountant")}
          onClick={openSearchForAccountant}
          className="m-0 p-0"
          labelClassName="m-0 p-0"
          f
        />

        <GhostButton
          icon={<WalkthroughIcon className="clear-fill" style={{ height: "24px", width: "24px" }} />}
          label={t("profile.settings.start_walkthrough")}
          onClick={() => {
            history.push("/dashboard");
            if (walkthroughSetupNeeded) {
              openWalkthrough(); // With initial modal that sets up ptf & file
            } else {
              openWalkthroughStartModal();
            }
          }}
          className="mr-md-3 mr-0 p-0"
          labelClassName="m-0 p-0"
        />
      </div>
    </div>
  ) : null;
};

function mapStateToProps(state) {
  return {
    user: state.user,
    walkthroughSetupNeeded: state.listOfPortfolios.portfolios.length === 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOffers);
