import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { modalActions } from "actions/modalActions";
import { alertActions } from "actions/alertActions";
import { supportService } from "services/supportService";
import DownloadAdminReportForm from "../Forms/DownloadAdminReportForm";
import ModalEnvelope from "./ModalEnvelope";

const DownloadAdminReportModal = ({ modalActions }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    try {
      await supportService.downloadAdminReport(values.type?.value, values.changeFrom);
      modalActions.hideModal();
    } catch (e) {
      alertActions.error("alert.error.admin_report_download_failed");
    }
  };
  return (
    <ModalEnvelope id="download-admin-report" heading={t("modal.download_admin_report.heading")}>
      <DownloadAdminReportForm onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(DownloadAdminReportModal);
