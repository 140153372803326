import { setLocale } from "yup";

const t_fake = str => str;
export const setYupLocales = () =>
  setLocale({
    mixed: {
      required: t_fake("validation.required"),
    },
    string: {
      email: t_fake("validation.invalid_email"),
      min: ({ min }) => ({ i18nKey: t_fake("validation.string_min"), i18nValues: { min } }),
      max: ({ max }) => ({ i18nKey: t_fake("validation.string_max"), i18nValues: { max } }),
    },
  });
