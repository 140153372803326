import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const DeleteTransactionContainers = ({ onConfirm, onCancel, count }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.delete_tx_containers.heading")}
      confirmLabel={t("confirmation.delete_tx_containers.confirm_label")}
      critical>
      <p>{t("confirmation.delete_tx_containers.content", { count })}</p>
    </ConfirmationEnvelope>
  );
};

export default DeleteTransactionContainers;
