import { createSelector } from "reselect";
import { JOB_PROCESSING, JOB_ERROR, JOB_DONE } from "common/constants/jobStatusType";
import { CONTAINER_PROCESSING, CONTAINER_UPLOADING } from "common/constants/jobType";

export const getJobs = state => state.jobs.containerJobs;

export const getContainerJobs = createSelector([getJobs], jobs =>
  Object.keys(jobs).reduce((res, val) => {
    if (!jobs[val]) return res;

    const isProcessing = jobs[val].jobStatus === JOB_PROCESSING;

    res[val] = {
      ...jobs[val],
      isDone: jobs[val].jobStatus === JOB_DONE,
      isOngoing: isProcessing,
      isUploading: isProcessing && jobs[val].jobType === CONTAINER_UPLOADING,
      isProcessing: isProcessing && jobs[val].jobType === CONTAINER_PROCESSING,
      isError: jobs[val].jobStatus === JOB_ERROR,
    };
    return res;
  }, {})
);
