import {
  ADD_ORGANIZATION_USER,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_USER,
  EDIT_ORGANIZATION_INFO,
  EDIT_ORGANIZATION_USER,
  GET_CHART_OF_ACCOUNT,
  GET_CHART_OF_ACCOUNT_ACCOUNTS,
  GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING,
  GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS,
  GET_ORGANIZATION_ACCOUNT_SETTINGS,
  GET_ORGANIZATION_INFO,
  GET_ORGANIZATION_RULE_SETS,
  GET_ORGANIZATION_USERS,
  UPDATE_ORGANIZATION_PLAN,
  UPDATE_TRANSACTIONS_COUNT,
} from "actions/types";
import { formatDate } from "common/formatters";
import { subDays } from "date-fns";

const initialState = {
  isFetching: false,
  organizationDetails: {},
  organizationUsers: {},
  organizationInvoices: {},
  transactionsCount: undefined,
  transactionsLimit: undefined,
  transactionsCountFetching: false,
  chartOfAccountFetching: false,
  chartOfAccount: [],
  chartOfAccountAccountsFetching: false,
  chartOfAccountAccounts: [],
  chartOfAccountSubAccountsFetching: false,
  chartOfAccountSubAccounts: [],
  chartOfAccountPreAccountingFetching: false,
  chartOfAccountPreAccounting: [],
  ruleSets: {},
  ruleSetsFetching: false,
  accountSettings: {},
  accountSettingsFetching: false,
};

export default function organizationsReducer(state = initialState, action) {
  switch (action.type) {
    //= ========================================
    case `${GET_ORGANIZATION_INFO}_PENDING`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            ...state.organizationDetails[action.meta.organizationId],
            isOrganizationDataFetching: true,
          },
        },
      };
    case `${GET_ORGANIZATION_INFO}_FULFILLED`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            ...state.organizationDetails[action.meta.organizationId],
            isOrganizationDataFetching: false,
            organizationData: action.payload,
          },
        },
      };
    case `${GET_ORGANIZATION_INFO}_REJECTED`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            isOrganizationDataFetching: false,
          },
        },
      };
    //= ========================================
    case `${CREATE_ORGANIZATION}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${CREATE_ORGANIZATION}_FULFILLED`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.payload.id]: {
            isOrganizationDataFetching: false,
            organizationData: action.payload,
          },
        },
        isFetching: false,
      };
    case `${CREATE_ORGANIZATION}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    //= ========================================
    case `${DELETE_ORGANIZATION}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${DELETE_ORGANIZATION}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
      };
    case `${DELETE_ORGANIZATION}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    //= ========================================
    case `${EDIT_ORGANIZATION_INFO}_PENDING`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            ...state.organizationDetails[action.meta.organizationId],
            isOrganizationDataFetching: true,
          },
        },
      };
    case `${EDIT_ORGANIZATION_INFO}_FULFILLED`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            ...state.organizationDetails[action.meta.organizationId],
            isOrganizationDataFetching: false,
            organizationData: action.payload,
          },
        },
      };
    case `${EDIT_ORGANIZATION_INFO}_REJECTED`:
      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            isOrganizationDataFetching: false,
          },
        },
      };
    //= ========================================
    case `${GET_ORGANIZATION_USERS}_PENDING`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: true,
          },
        },
      };
    case `${GET_ORGANIZATION_USERS}_FULFILLED`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: false,
            users: action.payload,
          },
        },
      };
    case `${GET_ORGANIZATION_USERS}_REJECTED`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            isOrganizationUsersFetching: false,
          },
        },
      };
    //= ========================================
    case `${ADD_ORGANIZATION_USER}_PENDING`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: true,
          },
        },
      };
    case `${ADD_ORGANIZATION_USER}_FULFILLED`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: false,
            users: [action.payload, ...state.organizationUsers[action.meta.organizationId].users],
          },
        },
      };
    case `${ADD_ORGANIZATION_USER}_REJECTED`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: false,
          },
        },
      };
    //= ========================================
    case `${EDIT_ORGANIZATION_USER}_PENDING`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: true,
          },
        },
      };
    case `${EDIT_ORGANIZATION_USER}_FULFILLED`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: false,
            users: state.organizationUsers[action.meta.organizationId].users.map(o =>
              o.id === action.meta.userId
                ? {
                    ...o,
                    ...action.payload,
                  }
                : o
            ),
          },
        },
      };
    case `${EDIT_ORGANIZATION_USER}_REJECTED`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            isOrganizationUsersFetching: false,
          },
        },
      };
    // ====================================
    case `${UPDATE_ORGANIZATION_PLAN}_PENDING`:
      return {
        ...state,
        isFetching: true,
      };
    case `${UPDATE_ORGANIZATION_PLAN}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        organizationDetails: {
          ...state.organizationDetails,
          [action.meta.organizationId]: {
            ...state.organizationDetails[action.meta.organizationId],
            ...action.payload,
          },
        },
      };
    case `${UPDATE_ORGANIZATION_PLAN}_REJECTED`:
      return {
        ...state,
        isFetching: false,
      };
    //= ========================================
    case `${DELETE_ORGANIZATION_USER}_PENDING`:
      return {
        ...state,
        organizationUsers: {
          ...state.organizationUsers,
          [action.meta.organizationId]: {
            ...state.organizationUsers[action.meta.organizationId],
            users: state.organizationUsers[action.meta.organizationId].users.filter(o => o.id !== action.meta.userId),
          },
        },
      };
    //= ========================================
    case `${UPDATE_TRANSACTIONS_COUNT}_PENDING`:
      return {
        ...state,
        transactionsCountFetching: true,
      };
    case `${UPDATE_TRANSACTIONS_COUNT}_FULFILLED`:
      return {
        ...state,
        transactionsCount: action.payload.transactionsCount,
        transactionsLimit: action.payload.transactionsLimit,
        transactionsCountFetching: false,
      };
    case `${UPDATE_TRANSACTIONS_COUNT}_REJECTED`:
      return {
        ...state,
        transactionsCountFetching: false,
      };
    //= ========================================
    case `${GET_CHART_OF_ACCOUNT}_PENDING`:
      return {
        ...state,
        chartOfAccountFetching: true,
      };
    case `${GET_CHART_OF_ACCOUNT}_FULFILLED`:
      return {
        ...state,
        chartOfAccount: action.payload,
        chartOfAccountFetching: false,
      };
    case `${GET_CHART_OF_ACCOUNT}_REJECTED`:
      return {
        ...state,
        chartOfAccountFetching: false,
      };
    //= ========================================
    case `${GET_CHART_OF_ACCOUNT_ACCOUNTS}_PENDING`:
      return {
        ...state,
        chartOfAccountAccountsFetching: true,
      };
    case `${GET_CHART_OF_ACCOUNT_ACCOUNTS}_FULFILLED`:
      return {
        ...state,
        chartOfAccountAccounts: action.payload,
        chartOfAccountAccountsFetching: false,
      };
    case `${GET_CHART_OF_ACCOUNT_ACCOUNTS}_REJECTED`:
      return {
        ...state,
        chartOfAccountAccountsFetching: false,
      };
    //= ========================================
    case `${GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS}_PENDING`:
      return {
        ...state,
        chartOfAccountSubAccountsFetching: true,
      };
    case `${GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS}_FULFILLED`:
      return {
        ...state,
        chartOfAccountSubAccounts: action.payload,
        chartOfAccountSubAccountsFetching: false,
      };
    case `${GET_CHART_OF_ACCOUNT_SUB_ACCOUNTS}_REJECTED`:
      return {
        ...state,
        chartOfAccountSubAccountsFetching: false,
      };
    //= ========================================
    case `${GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING}_PENDING`:
      return {
        ...state,
        chartOfAccountPreAccountingFetching: true,
      };
    case `${GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING}_FULFILLED`:
      return {
        ...state,
        chartOfAccountPreAccounting: action.payload,
        chartOfAccountPreAccountingFetching: false,
      };
    case `${GET_CHART_OF_ACCOUNT_PRE_ACCOUNTING}_REJECTED`:
      return {
        ...state,
        chartOfAccountPreAccountingFetching: false,
      };
    //= ========================================
    case `${GET_ORGANIZATION_RULE_SETS}_PENDING`:
      return {
        ...state,
        ruleSetsFetching: true,
      };
    case `${GET_ORGANIZATION_RULE_SETS}_FULFILLED`:
      return {
        ...state,
        ruleSets: {
          ...state.ruleSets,
          // Calculate validity end dates for rule sets - day before start of next rule set
          // see ETD-555 comments for explanation
          [action.meta.organizationId]: action.payload
            .sort((a, b) => new Date(a.validFrom) - new Date(b.validFrom))
            .map((x, index, array) => {
              if (index === array.length - 1) {
                return x;
              }
              return { ...x, validTo: formatDate(subDays(new Date(array[index + 1].validFrom), 1)) };
            }),
        },
        ruleSetsFetching: false,
      };
    case `${GET_ORGANIZATION_RULE_SETS}_REJECTED`:
      return {
        ...state,
        ruleSetsFetching: false,
      };
    //= ========================================
    case `${GET_ORGANIZATION_ACCOUNT_SETTINGS}_PENDING`:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          [action.meta.organizationId]: {
            ...state.accountSettings[action.meta.organizationId],
            isFetching: true,
          },
        },
      };
    case `${GET_ORGANIZATION_ACCOUNT_SETTINGS}_FULFILLED`:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          [action.meta.organizationId]: {
            ...state.accountSettings[action.meta.organizationId],
            isFetching: false,
            ...action.payload,
          },
        },
      };
    case `${GET_ORGANIZATION_ACCOUNT_SETTINGS}_REJECTED`:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          [action.meta.organizationId]: {
            isFetching: false,
          },
        },
      };
    default:
      return state;
  }
}
