export const EXCHANGE = "EXCHANGE";
export const WALLET = "WALLET";

export const accountTypes = [EXCHANGE, WALLET];

const t_fake = str => str;

const accountTypesTranslations = {
  [EXCHANGE]: t_fake("account_type.exchange"),
  [WALLET]: t_fake("account_type.wallet"),
};

export const accountTypesTranslator = val => accountTypesTranslations[val] || "";
