import React, { useMemo, useRef, useState } from "react";
import { CheckBox } from "grommet";
import { useTranslation } from "react-i18next";
import { faArrowTurnUp, faFolderMinus, faFolderPlus, faNoteSticky, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatTime, formatUtcDate, utc } from "common/formatters";
import {
  BUY,
  canTxHaveRebate,
  FEE,
  isTxTypeDepositWithdrawal,
  isTxTypeFeeRebate,
  isTxTypeReward,
  isTxTypeStake,
  REBATE,
  transactionTypeTranslator,
} from "common/constants/transactionType";
import RateAndTotalLabel from "containers/DataManager/RateAndTotalLabel";
import { FilterableCell, GhostButton, Heading, Table } from "../Common";
import styles from "../Styles/DataManager.module.scss";
import TransactionsTableFilter, { initialTransactionTableFilterValues } from "./TransactionsTableFilter";
import { Add as AddIcon, Edit as EditIcon, Trash as TrashIcon } from "grommet-icons/icons";
import { ActionMenu } from "components/Common/ActionMenu";
import PermissionCheck from "components/Common/PermissionCheck";
import { bindActionCreators } from "redux";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import { connect } from "react-redux";
import { Tooltip } from "components/Common";
import { useScreenSize } from "hooks";

const ContainerTransactionsTable = ({
  transactions,
  addTransaction,
  editTransaction,
  transactionSelection,
  setTransactionSelection,
  containerSelected,
  editMode,
  filterOptions,
  setTransactionsFilter,
  changeFilterAndFetch,
  filter: { isFilterVisible, setIsFilterVisible },
  confirmationActions: { openDeleteTransactionsConfirmation },
  modalActions: { openAddTransactionsToBucket, openDeleteTransactionsFromBucket },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const toggleCheck = id => {
    setTransactionSelection({
      ...transactionSelection,
      [id]: !transactionSelection[id],
    });
  };

  const selectAll = e => {
    setTransactionSelection(
      transactions.reduce((res, val) => {
        res[val.id] = e.target.checked;
        return res;
      }, {})
    );
  };

  const allSelected = useMemo(() => {
    const txIds = transactions.map(o => o.id);
    return transactions.length !== 0 && txIds.find(o => transactionSelection[o] !== true) === undefined;
  }, [transactionSelection, transactions]);

  const handleFilterColumnClicked = () => {
    if (!isFilterVisible) setIsFilterVisible(true);
  };

  const columns = [
    {
      Header: () => <CheckBox disabled={transactions.length === 0} checked={allSelected && !editMode} onChange={selectAll} />,
      Cell: ({ row }) => <CheckBox checked={transactionSelection[row.id]} onChange={() => toggleCheck(row.id)} />,
      id: "check-row",
      width: 40,
    },
    {
      Header: props => (
        <FilterableCell
          {...props}
          onClick={() => {
            handleFilterColumnClicked();
            pairFilterRef.current.focus();
          }}>
          {t("data_manager.transaction_table.currency")}
        </FilterableCell>
      ),
      Cell: ({ row }) => {
        const { type, baseCurrency, quoteCurrency, feeRebateCurrency } = row.original;

        if (isTxTypeDepositWithdrawal(type) || isTxTypeStake(type) || isTxTypeReward(type)) {
          return baseCurrency;
        }
        if (isTxTypeFeeRebate(type)) {
          return feeRebateCurrency;
        }
        if (type === BUY) {
          return (
            <>
              {`${baseCurrency}`} / {`${quoteCurrency}`}
            </>
          );
        }
        return (
          <>
            {`${baseCurrency}`} / {`${quoteCurrency}`}
          </>
        );
      },
      id: "currency",
      width: 80,
    },
    {
      Header: t("data_manager.transaction_table.source"),
      accessor: "exchangeName",
      width: 100,
    },
    {
      Header: t("data_manager.transaction_table.date_column"),
      Cell: ({ value }) => (
        <>
          {formatUtcDate(value)}
          <span className={styles.time_small}>{formatTime(utc(value), true)}</span>
        </>
      ),
      accessor: "timestamp",
      width: 100,
    },
    {
      Header: props => (
        <FilterableCell
          {...props}
          onClick={() => {
            handleFilterColumnClicked();
            typeFilterRef.current.focus();
          }}>
          {t("data_manager.transaction_table.type_column")}
        </FilterableCell>
      ),
      Cell: ({ row: { original } }) => {
        const isSubtransaction = !!original.mainTransactionId;
        return (
          <div style={{ display: "grid", gridTemplateColumns: "20px auto", alignItems: "center" }}>
            <span>{isSubtransaction && <FontAwesomeIcon icon={faArrowTurnUp} className={styles.subtransaction_cell} />}</span>
            {t(`${transactionTypeTranslator(original.type, original.transfer)}`)}
          </div>
        );
      },
      accessor: "type",
      width: 80,
    },
    {
      Header: t("data_manager.transaction_table.quantity_price_total_column"),
      Cell: ({ row: { original } }) => <RateAndTotalLabel {...original} />,
      id: "price-total",
      width: 200,
    },
    {
      Header: t("data_manager.transaction_table.note"),
      Cell: ({ value }) => <span className={styles.note}>{value}</span>,
      accessor: "note",
      width: 90,
    },
    {
      Header: props => (
        <FilterableCell
          {...props}
          onClick={() => {
            handleFilterColumnClicked();
            labelFilterRef.current.focus();
          }}>
          {t("data_manager.transaction_table.tx_label")}
        </FilterableCell>
      ),
      Cell: ({ value }) => <span className={styles.label}>{value?.map(x => x.transactionLabel)?.join(", ")}</span>,
      accessor: "transactionLabels",
      width: 90,
    },
    {
      Header: () => (
        <div style={{ textAlign: "right" }} className="pr-1 pr-md-0 ml-2 ml-md-0">
          {t("data_manager.transaction_table.action")}
        </div>
      ),
      Cell: ({ row }) => {
        const { original, index } = row;
        const rowIndex = index + 1;

        const canHaveRebate = canTxHaveRebate(original.type);
        const isSubtransaction = !!original.mainTransactionId;

        return (
          <PermissionCheck>
            <div className="d-flex justify-content-end pr-1">
              {!isSmall && (
                <>
                  {original.type !== FEE && original.type !== REBATE && (
                    <Tooltip
                      tooltipContent={
                        <>
                          {t("data_manager.transaction_table.add_subtransaction_part1")} <br />
                          {canTxHaveRebate(original.type)
                            ? t("data_manager.transaction_table.add_subtransaction_part2.fee_rebate")
                            : t("data_manager.transaction_table.add_subtransaction_part2.fee")}
                        </>
                      }>
                      <GhostButton
                        icon={<AddIcon />}
                        onClick={() => addTransaction(original)}
                        actionButton
                        testId={`action-add-fee_row-${rowIndex}`}
                      />
                    </Tooltip>
                  )}

                  <Tooltip tooltipContent={t("data_manager.transaction_table.edit_transaction")}>
                    <GhostButton
                      icon={<EditIcon />}
                      onClick={() => editTransaction(original)}
                      actionButton
                      testId={`action-edit_row-${rowIndex}`}
                    />
                  </Tooltip>
                </>
              )}

              <ActionMenu testId="transaction-menu">
                <ActionMenu.ItemGroup key={1}>
                  <ActionMenu.Item
                    icon={<EditIcon />}
                    label={t("data_manager.edit_transaction")}
                    onClick={() => editTransaction(original)}
                    testId={`action-edit_row-${rowIndex}`}
                  />

                  <ActionMenu.Item
                    icon={<AddIcon />}
                    label={canHaveRebate ? t("data_manager.add_subtransaction") : t("data_manager.add_fee")}
                    onClick={() => addTransaction(original)}
                    testId={`action-add-fee-rebate_row-${rowIndex}`}
                    hidden={!(original.type !== FEE && original.type !== REBATE)}
                  />

                  <ActionMenu.Item
                    icon={<FontAwesomeIcon icon={faNoteSticky} />}
                    label={t("data_manager.add_note")}
                    onClick={() => editTransaction(original, undefined, undefined, undefined, { focusNote: true })}
                    testId={`action-note_row-${rowIndex}`}
                  />

                  <ActionMenu.Item
                    icon={<FontAwesomeIcon icon={faTag} />}
                    label={t("data_manager.add_label")}
                    onClick={() => editTransaction(original, undefined, undefined, undefined, { focusLabels: true })}
                    testId={`action-label_row-${rowIndex}`}
                    hidden={isSubtransaction}
                  />

                  {original.bucketIds?.length > 0 ? (
                    <ActionMenu.Item
                      icon={<FontAwesomeIcon icon={faFolderMinus} />}
                      label={t("confirmation.delete_tx_from_bucket.heading")}
                      onClick={() => openDeleteTransactionsFromBucket([original.id])}
                      testId={`action-remove-bucket_row-${rowIndex}`}
                    />
                  ) : (
                    <ActionMenu.Item
                      icon={<FontAwesomeIcon icon={faFolderPlus} />}
                      label={t("data_manager.add_to_bucket")}
                      onClick={() => openAddTransactionsToBucket([original.id])}
                      testId={`action-add-bucket_row-${rowIndex}`}
                    />
                  )}
                </ActionMenu.ItemGroup>

                <ActionMenu.ItemGroup hasSeparator key={2}>
                  <ActionMenu.Item
                    icon={<TrashIcon />}
                    label={t("data_manager.delete_transaction")}
                    critical
                    onClick={() => openDeleteTransactionsConfirmation([original])}
                    testId={`action-delete_row-${rowIndex}`}
                  />
                </ActionMenu.ItemGroup>
              </ActionMenu>
            </div>
          </PermissionCheck>
        );
      },
      id: "actions",
      sticky: true,
      width: isSmall ? 25 : 60,
    },
  ];

  const [filteredBy, setFilteredBy] = useState(initialTransactionTableFilterValues);
  const pairFilterRef = useRef(null);
  const typeFilterRef = useRef(null);
  const labelFilterRef = useRef(null);

  return (
    <div className={styles.styled_table_wrapper}>
      <TransactionsTableFilter
        filteredBy={filteredBy}
        setFilteredBy={setFilteredBy}
        changeFilterAndFetch={changeFilterAndFetch}
        filterOptions={filterOptions}
        setTransactionsFilter={setTransactionsFilter}
        isVisible={isFilterVisible}
        refs={{
          pairFilterRef,
          typeFilterRef,
          labelFilterRef,
        }}
      />

      <Table
        data={transactions}
        columns={columns}
        getRowId={row => row.id}
        filteredBy={filteredBy}
        setFilteredBy={setFilteredBy}
        dataTour="datamanager-transaction-console"
        stickyHeader
        minWidth={1600}
      />
      {transactions.length === 0 && (
        <div className={styles.empty_table_row}>
          <Heading level={4} margin="1rem 0 0" color="gray3">
            {containerSelected
              ? t("data_manager.transaction_table.no_transactions_message")
              : t("data_manager.transaction_table.select_container_message")}
          </Heading>
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(ContainerTransactionsTable);
