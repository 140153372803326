import React from "react";
import { Trans, useTranslation } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const SetPassword = ({ onConfirm, onCancel, email }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.set_password.heading")}
      confirmLabel={t("confirmation.set_password.confirm_label")}>
      <p>
        <Trans i18nKey="confirmation.set_password.content">
          We will send a verification email to <b>{{ email }}</b> with a link to setup your password. It is because of security
          reasons.
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default SetPassword;
