import React from "react";
import { Table } from "grommet";
import { useTranslation } from "react-i18next";
import OpenRow from "./OpenRow";
import CloseRow from "./CloseRow";
import styles from "../Styles/MasterTable.module.scss";
import { GhostButton } from "components/Common";
import { useDefaultsStore } from "stores/defaultsStore";
import { ReactComponent as TableCompactIcon } from "img/iconTableCompact.svg";
import { ReactComponent as TableDetailIcon } from "img/iconTableFull.svg";

const MasterTableWithComputation = ({ open, close, openBaseQuote, isWalkthroughEnabled }) => {
  const { t } = useTranslation();

  const { showPositionsDetail, setShowPositionsDetail } = useDefaultsStore();

  return (
    <>
      <div className={styles.master_table_absolute_header}>
        <div>{open.length > 0 ? t("dashboard.master_table.open_subhead") : t("dashboard.master_table.open_empty_subhead")}</div>

        <div className="d-flex">
          {showPositionsDetail ? (
            <GhostButton
              onClick={() => setShowPositionsDetail(false)}
              icon={<TableDetailIcon className={styles.into_toggle_icon} />}
              className="mx-0 px-0"
              actionButton
            />
          ) : (
            <GhostButton
              onClick={() => setShowPositionsDetail(true)}
              icon={<TableCompactIcon className={styles.into_toggle_icon} />}
              className="mx-0 px-0"
              actionButton
            />
          )}
        </div>
      </div>

      <div style={{ width: "100%", height: "40px" }} />

      <Table className={styles.master_table} style={isWalkthroughEnabled ? undefined : { overflowX: "scroll" }}>
        <tbody data-tour="master-table-open" tabIndex="15">
          {open.length > 0 && (
            <tr className={styles.master_table_heading}>
              <th>{t("dashboard.master_table.currency")}</th>
              <th>{t("dashboard.master_table.quantity")}</th>
              <th>{t("dashboard.master_table.acquisition_cost_column")}</th>
              <th>{t("dashboard.master_table.market_value_column")}</th>
              <th>{t("dashboard.master_table.unrealized_profit_column")}</th>
              <th>{t("dashboard.master_table.action_column")}</th>
            </tr>
          )}
          {open.map(o => (
            <OpenRow
              key={`${o.base}_${o.quote}_open`}
              rowId={`${o.base}_${o.quote}_open`}
              {...o}
              openBaseQuote={openBaseQuote}
              withComputation
              showDetails={showPositionsDetail}
            />
          ))}
          <tr className={styles.table_divider}>
            <td colSpan="5">
              <div className={styles.divider} />
            </td>
          </tr>
        </tbody>

        <tbody data-tour="master-table-close" tabIndex="15">
          <tr className={styles.master_table_header}>
            <th colSpan="2" className="pb-2" style={{ width: "200px", left: 0, right: 0 }}>
              <div>
                {close.length > 0 ? t("dashboard.master_table.close_subhead") : t("dashboard.master_table.close_empty_subhead")}
              </div>
            </th>
          </tr>
          {close.length > 0 && (
            <tr className={styles.master_table_heading}>
              <th>{t("dashboard.master_table.currency")}</th>
              <th>{t("dashboard.master_table.quantity")}</th>
              <th>{t("dashboard.master_table.acquisition_cost_column")}</th>
              <th>{t("dashboard.master_table.revenue_column")}</th>
              <th>{t("dashboard.master_table.realized_profit_column")}</th>
              <th>{t("dashboard.master_table.action_column")}</th>
            </tr>
          )}
          {close.map(o => (
            <CloseRow
              key={`${o.base}_${o.quote}_close`}
              rowId={`${o.base}_${o.quote}_close`}
              {...o}
              openBaseQuote={openBaseQuote}
              withComputation
              showDetails={showPositionsDetail}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default MasterTableWithComputation;
