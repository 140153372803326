import React from "react";
import { useTranslation } from "react-i18next";
import { reportTypesTranslator } from "common/constants/reportTypes";
import { SMALL } from "common/constants/screenSizes";
import { ReportTypeIcon } from "components/Reports/ReportTypeIcon";
import { ReactComponent as DownloadIcon } from "img/download-icon.svg";
import { GhostButton, Heading, LoaderOverlay, Text } from "../Common";
import styles from "../Styles/Reports.module.scss";
import { PrimaryButton } from "components/Common";
import { PrimaryButtonSize } from "components/Common/Buttons/PrimaryButton";
import { bindActionCreators } from "redux";
import { confirmationActions } from "actions/confirmationActions";
import { connect } from "react-redux";
import SubscriptionPlanCheck from "containers/SubscriptionPlanCheck";
import { ONLINE } from "common/constants/paymentPlans";
import { useScreenSize } from "hooks";

const ReportTypeRow = ({
  reportType,
  reportTypeDescription,
  available,
  onClick,
  isDownloading,
  isAccountingReport,
  confirmationActions: { openChangePlanConfirmation },
  dataTour,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  return (
    <LoaderOverlay isLoading={isDownloading} spinnerSize={SMALL} className={styles.report_type_row} data-tour={dataTour}>
      <ReportTypeIcon reportType={reportType} />
      <div>
        <Heading level={4} margin="0 0 9px 1rem">
          {t(`${reportTypesTranslator(reportType)}`)}
        </Heading>

        <Text size="small" as="div" margin={`0 0 0 ${isSmall ? "1rem" : "17px"}`}>
          {reportTypeDescription}
        </Text>

        <div className="d-flex align-items-start align-items-md-center mt-1 flex-column flex-md-row">
          <GhostButton
            label={t("reports.report_type.button")}
            onClick={onClick}
            disabled={isDownloading || !available}
            icon={<DownloadIcon style={{ height: "18px" }} />}
          />
          <SubscriptionPlanCheck>
            {isAccountingReport && (
              <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row mt-1 mt-md-0">
                <Text className="mr-3 text-nowrap ml-3 ml-md-0">{t("reports.accounting_report.limited_tx")}</Text>
                <PrimaryButton
                  label={t("button.upgrade")}
                  onClick={openChangePlanConfirmation}
                  size={PrimaryButtonSize.Small}
                  noIcon
                  className="w-auto ml-3 ml-md-0 mt-2 mt-md-0"
                />
              </div>
            )}
          </SubscriptionPlanCheck>
        </div>
      </div>
    </LoaderOverlay>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(ReportTypeRow);
