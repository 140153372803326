import { transactionService } from "services";

export const getLabels = (user, portfolioId, includeDefaults, enabled = true) => ({
  queryKey: ["labels", { user, portfolioId, includeDefaults }],
  queryFn: () =>
    transactionService
      .getLabels(user, portfolioId, includeDefaults)
      .then(response =>
        response.filter(x => !!x.transactionLabelId).map(x => ({ value: x.transactionLabelId, label: x.transactionLabel }))
      ),
  enabled,
  initialData: [],
});

export const getTransaction = (transaction, user, enabled) => ({
  queryKey: ["transaction", { transaction, user }],
  queryFn: () => transactionService.getTransaction(transaction, user),
  enabled,
});
