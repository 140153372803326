import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { supportActions } from "actions/supportActions";
import { modalActions } from "actions/modalActions";
import { Heading } from "components/Common";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import AccountantDetailForm from "../Forms/AccountantDetailForm";

const AccountDetailModal = ({ supportActions, modalActions, user }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    await supportActions.editUserDetail(user.userId, values);
    modalActions.hideModal();
  };

  return (
    <ModalEnvelope id="accountant-detail-modal" heading={t("modal.accountant_detail.heading")}>
      <Heading level={5} textAlign="center" margin="-1.5rem auto 1rem auto">{`[${user.email}]`}</Heading>
      <AccountantDetailForm initialValues={user} onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    supportActions: bindActionCreators(supportActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AccountDetailModal);
