import React from "react";
import SummaryHeaderHelp from "img/summaryHeaderHelp.png";
import DistributionHelp1 from "img/distributionHelp1.png";
import DistributionHelp2 from "img/distributionHelp2.png";
import DistributionHelp3 from "img/distributionHelp3.png";
import FiatPositions from "img/fiatPositions.png";
import BaseAndQuoteChart from "img/baseAndQuoteChart.png";
import PortfolioHistoryHelp from "img/portfolioHistoryHelp.png";
import ProfitHistoryHelp from "img/profitHistoryHelp.png";
import PortfolioCurrencyHelp from "img/portfolioCurrencyHelp.png";
import TradeCurrencyHelp from "img/tradeCurrencyHelp.png";
import { useTranslation } from "react-i18next";
import { Heading } from "../Common";

import styles from "../Styles/Metrics.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.metrics_tab_content}>
      <div>
        <Heading level={4}>
          <span>01.</span>
          {t("metrics.new_portfolio.heading")}
        </Heading>
        <p>{t("metrics.new_portfolio.p1")}</p>
        <p>{t("metrics.new_portfolio.p2")}</p>
        <p>{t("metrics.new_portfolio.p3")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>02.</span>
          {t("metrics.edit_portfolio.heading")}
        </Heading>
        <p>{t("metrics.edit_portfolio.p1")}</p>
        <p>{t("metrics.edit_portfolio.p2")}</p>
        <p>{t("metrics.edit_portfolio.p3")}</p>
        <p>{t("metrics.edit_portfolio.p4")}</p>
        <p>{t("metrics.edit_portfolio.p5")}</p>
        <p>{t("metrics.edit_portfolio.p6")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>03.</span>
          {t("metrics.ptf_summary.heading")}
        </Heading>
        <img src={SummaryHeaderHelp} alt="" />
        <div className={styles.summary_grid_4}>
          <div>
            <Heading level={6}>
              <span>A.</span>
              {t("metrics.ptf_summary.aq.heading")}
            </Heading>
            <div className={styles.small_text}>{t("metrics.ptf_summary.aq.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>B.</span>
              {t("metrics.ptf_summary.mv.heading")}
            </Heading>
            <div className={styles.small_text}>{t("metrics.ptf_summary.mv.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>C.</span>
              {t("metrics.ptf_summary.upl.heading")}
            </Heading>
            <div className={styles.small_text}>{t("metrics.ptf_summary.upl.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>D.</span>
              {t("metrics.ptf_summary.rpl.heading")}
            </Heading>
            <div className={styles.small_text}>{t("metrics.ptf_summary.rpl.content")}</div>
          </div>
        </div>
      </div>
      <div>
        <Heading level={4}>
          <span>04.</span>
          {t("metrics.distribution_chart.heading")}
        </Heading>
        <p>{t("metrics.distribution_chart.content")}</p>
        <img src={DistributionHelp1} alt="" />
        <img src={DistributionHelp2} alt="" />
        <img src={DistributionHelp3} alt="" />
      </div>
      <div>
        <Heading level={4}>
          <span>05.</span>
          {t("metrics.main_chart.heading")}
        </Heading>
        <Heading level={6}>{t("metrics.main_chart.portfolio_history.heading")}</Heading>
        <p>{t("metrics.main_chart.portfolio_history.content")}</p>
        <img src={PortfolioHistoryHelp} alt="" />

        <Heading level={6}>{t("metrics.main_chart.profit_history.heading")}</Heading>
        <p>{t("metrics.main_chart.profit_history.content")}</p>
        <img src={ProfitHistoryHelp} alt="" />
      </div>
      <div>
        <Heading level={4}>
          <span>06.</span>
          {t("metrics.your_position.heading")}
        </Heading>
        <Heading level={6}>{t("metrics.your_position.accounting_currency.heading")}</Heading>
        <p>{t("metrics.your_position.accounting_currency.content")}</p>
        <img src={PortfolioCurrencyHelp} alt="" />
        <div className={styles.summary_grid_5}>
          <div>
            <Heading level={6}>
              <span>A.</span>
              {t("metrics.your_position.accounting_currency.bq.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.bq.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>B.</span>
              {t("metrics.your_position.accounting_currency.mp.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.mp.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>C.</span>
              {t("metrics.your_position.accounting_currency.ac.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.ac.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>D.</span>
              {t("metrics.your_position.accounting_currency.mv.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.mv.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>E.</span>
              {t("metrics.your_position.accounting_currency.upl.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.upl.content")}</div>
          </div>
        </div>

        <div className={styles.summary_grid_4}>
          <div>
            <Heading level={6}>
              <span>F.</span>
              {t("metrics.your_position.accounting_currency.bq.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.bq.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>G.</span>
              {t("metrics.your_position.accounting_currency.ac.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.ac.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>H.</span>
              {t("metrics.your_position.accounting_currency.r.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.r.content")}</div>
          </div>
          <div>
            <Heading level={6}>
              <span>I.</span>
              {t("metrics.your_position.accounting_currency.rpl.heading")}
            </Heading>
            <div>{t("metrics.your_position.accounting_currency.rpl.content")}</div>
          </div>
        </div>
        <Heading level={6}>{t("metrics.your_position.trade_currency.heading")}</Heading>
        <p id="test">{t("metrics.your_position.trade_currency.content")}</p>
        <img src={TradeCurrencyHelp} alt="" />
        <div>
          <Heading level={4}>
            <span>07.</span>
            {t("metrics.fiat_positions.heading")}
          </Heading>
          <p>{t("metrics.fiat_positions.content")}</p>
          <img src={FiatPositions} alt="" />
        </div>
        <div>
          <Heading level={4}>
            <span>08.</span>
            {t("metrics.base_quote_chart.heading")}
          </Heading>
          <p>{t("metrics.base_quote_chart.content")}</p>
          <img src={BaseAndQuoteChart} alt="" />
        </div>
      </div>
    </div>
  );
};
