import React from "react";
import cx from "classnames";
import styles from "./Buttons.module.scss";
import { X } from "react-feather";

const CloseButton = ({ className, iconProps, ...rest }) => (
  <button type="button" className={cx(styles.close_button, className, "mr-n1 pr-0")} {...rest}>
    <X />
  </button>
);

export default CloseButton;
