import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "components/Common/Components.module.scss";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { LoaderOverlay } from "components/Common";
import { SMALL } from "common/constants/screenSizes";
import FormattedNumber from "components/Common/FormattedNumber";
import { useQuery } from "@tanstack/react-query";
import { organizationService } from "services";

const TransactionCapacityGrid = ({ organization: { id } }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ["transactions-count", { id }],
    queryFn: () => organizationService.getOrganizationTransactionsCount(id),
    enabled: !!id,
  });

  const isUnderLimit = data?.transactionsCount < data?.transactionsLimit;

  return (
    <>
      <span>{t("transaction_capacity.tx_count")}</span>
      <LoaderOverlay isLoading={isLoading} spinnerSize={SMALL}>
        <div>
          {data && (
            <div>
              <FormattedNumber number={data?.transactionsCount} /> Tx
              {data?.transactionsLimit && (
                <>
                  {" "}
                  / <FormattedNumber number={data?.transactionsLimit} /> Tx
                </>
              )}
            </div>
          )}
          {data?.transactionsLimit && (
            <div className="progress" style={{ height: "8px", width: "auto" }}>
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax={data?.transactionsLimit ?? 0}
                style={{
                  width: `${(data?.transactionsCount / (data?.transactionsLimit || 100)) * 100}%`,
                  backgroundColor: isUnderLimit ? "var(--green-4)" : "rgb(253,86,51)",
                  borderRadius: "0 .25rem .25rem 0",
                  minWidth: "9px",
                }}
              />
            </div>
          )}
        </div>
      </LoaderOverlay>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionCapacityGrid);
