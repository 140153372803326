import React from "react";
import { getDataTestId } from "utils";
import styles from "../Styles/DataManager.module.scss";

const AddDbIcon = () => (
  <svg aria-label="Database" viewBox="0 0 26 30">
    <path
      fill="none"
      stroke="#000"
      strokeWidth="2"
      d="M0,5 L17,5 Z M18,5 L28,5 ZM4,15 L5,15 L5,16 L4,16 L4,17 Z M4,8 L5,8 L5,9 L4,9 L4,8 Z M4,22 L5,22 L5,23 L4,23 L4,22 Z M1,19 L23,19 L23,26 L1,26 L1,19 Z M23,10 L23,0  M1,12 L23,12 L23,19 L1,19 L1,6 Z"
    />
  </svg>
);

const AddItemButton = ({ label, onClick, disabled, type }) => (
  // TODO: Use GhostButton instead
  <>
    {type}
    <button
      onClick={onClick}
      className={styles.add_blank_button}
      disabled={disabled}
      data-testid={getDataTestId("button", label)}>
      <div className={styles.add_blank_icon}>
        <AddDbIcon />
      </div>
      <span className={styles.container_label}>{label}</span>
    </button>
  </>
);
export default AddItemButton;
