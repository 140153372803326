export const NATURAL_PERSON = "NATURAL_PERSON";
export const LEGAL_PERSON = "LEGAL_PERSON";

const t_fake = str => str;

const taxSubjectTranslations = {
  [NATURAL_PERSON]: t_fake("tax_subject.natural_person"),
  [LEGAL_PERSON]: t_fake("tax_subject.legal_person"),
};

export const taxSubjectTranslator = val => taxSubjectTranslations[val] || "";

export const taxSubjectOptions = t => [
  { label: t(taxSubjectTranslator(NATURAL_PERSON)), value: NATURAL_PERSON },
  { label: t(taxSubjectTranslator(LEGAL_PERSON)), value: LEGAL_PERSON },
];
