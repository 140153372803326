import bigDecimal from "js-big-decimal";
import moment from "moment";
import {
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  isAfter,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  startOfDay,
  startOfMonth,
  subMonths,
  subYears,
} from "date-fns";
import { matchSorter } from "match-sorter";
import { padEnd } from "lodash";
import { convertLocalToUTCDate, formatUtcDate } from "common/formatters";
import { DECIMAL_DIGITS } from "components/Common/Inputs/DecimalInput";
import { NATURAL_PERSON } from "common/constants/taxSubjects";

const LosslessJSON = require("lossless-json");

export const isProduction =
  window.location.origin === "https://whalebooks.com" || window.location.origin === "https://www.whalebooks.com";

export const disabledDays = { minDate: new Date(2009, 0, 3), maxDate: new Date() };

// Parse JSON with long numbers - these numbers will be returned as string with max DECIMAL_DIGITS precision
export const losslesslyParseJSON = jsonString => {
  const convertLosslessNumber = (key, value) => {
    if (value && value.isLosslessNumber) {
      if (value.value.includes(".")) {
        const longNumber = value.value.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
        return bigDecimal.round(longNumber, DECIMAL_DIGITS); // longNumber.substring(0, longNumber.indexOf(".") + DECIMAL_DIGITS + 1);
      }
      return Number(value.value);
    }
    return value;
  };
  return LosslessJSON.parse(jsonString, convertLosslessNumber);
};

export const isNullOrUndefined = value => value === null || value === undefined || value === "";

export const removeItemFromArray = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

// eslint-disable-next-line no-extend-native,func-names
Array.prototype.forEachAsync = async function (fn) {
  // eslint-disable-next-line no-restricted-syntax
  for (const t of this) {
    await fn(t);
  }
};

export const formatAddresses = (addresses, addressSingleAdd) => {
  if (addresses.length === 1 && addresses[0] === "") return [];
  return addressSingleAdd ? addresses : addresses.split("\n");
};

export const reduceIntArray = arr =>
  arr.reduce((res, val) => {
    res[val] = true;
    return res;
  }, {});

export const mapTransaction = ({ portfolioTransaction, transactionContainerId }) => ({
  ...portfolioTransaction,
  transactionContainerId,
});

// At least one number, one upper case and one lower case char
export const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$");
export const referenceRegex = new RegExp("^[a-zA-Z0-9]+$");

export const normalizeNumber = number => Math.max(number, 0);

// Moves date range in specified direction by numberOfDays
// If whole month is detected - moves to previous/next month
// If whole year is detected - moves to previous/next year
export const moveDateRange = ({ from, to }, forward = true, numberOfDays = null) => {
  if (from.endsWith("01-01") && to.endsWith("12-31")) {
    return forward
      ? { from: formatUtcDate(addYears(new Date(from), 1)), to: formatUtcDate(addYears(new Date(to), 1)) }
      : {
          from: formatUtcDate(subYears(new Date(from), 1)),
          to: formatUtcDate(subYears(new Date(to), 1)),
        };
  }
  if (isFirstDayOfMonth(new Date(from)) && isLastDayOfMonth(new Date(to))) {
    return forward
      ? {
          from: formatUtcDate(
            convertLocalToUTCDate(startOfMonth(addMonths(new Date(moment.utc(from).set("date", 10).valueOf()), 1)))
          ),
          to: formatUtcDate(convertLocalToUTCDate(endOfMonth(addMonths(new Date(moment.utc(to).set("date", 10).valueOf()), 1)))),
        }
      : {
          from: formatUtcDate(
            convertLocalToUTCDate(startOfMonth(subMonths(new Date(moment.utc(from).set("date", 10).valueOf()), 1)))
          ),
          to: formatUtcDate(convertLocalToUTCDate(endOfMonth(subMonths(new Date(moment.utc(to).set("date", 10).valueOf()), 1)))),
        };
  }

  const fromDate = moment.utc(from);
  const toDate = moment.utc(to);
  const difference = numberOfDays ?? Math.abs(fromDate.diff(toDate, "days"));
  const updatedFrom = formatUtcDate(
    forward
      ? toDate.clone().add(1, "days").valueOf()
      : fromDate
          .clone()
          .subtract(difference + 1, "days")
          .valueOf()
  );
  const updatedTo = formatUtcDate(
    forward
      ? toDate
          .clone()
          .add(difference + 1, "days")
          .valueOf()
      : fromDate.clone().subtract(1, "days").valueOf()
  );
  return {
    from: updatedFrom,
    to: updatedTo,
  };
};

export const sortArray = (array, sortKeys) =>
  matchSorter(array || [], "", {
    keys: sortKeys,
    threshold: matchSorter.rankings.NO_MATCH,
  });

export const formatToMinDecimals = (number, minDecimals = 2) => {
  if (number === null || number === undefined) return;

  const numberString = number.toString();
  if (numberString.indexOf(".") === -1) {
    return padEnd(`${numberString}.`, numberString.length + minDecimals + 1, "0");
  }

  const numberOfDecimals = numberString.substr(numberString.indexOf(".") + 1).length;
  if (numberOfDecimals < minDecimals) {
    return padEnd(numberString, numberString.length + (minDecimals - numberOfDecimals), "0");
  }

  return number;
};

// Removes trailing zeros of number string and adds zeros,
// if number of decimal places is lower than minDecimals (2 by default)
// https://stackoverflow.com/questions/3612744/remove-insignificant-trailing-zeros-from-a-number
export const removeTrailingZeros = (numberString, minDecimals) => formatToMinDecimals(numberString * 1, minDecimals);

export const trimStringToMaxChars = (text, chars) => (text?.length > chars ? `${text.substr(0, chars)}...` : text);

export const doDateRangesOverlap = (range1, range2) => range1.from <= range2.to && range1.to >= range2.from;

export const getFilteredRuleSets = (ruleSets, rangeFrom, rangeTo) => {
  if (!ruleSets) return;

  const portfolioFrom = rangeFrom ? new Date(rangeFrom) : undefined;
  const portfolioTo = rangeTo ? endOfDay(new Date(rangeTo)) : undefined;
  const portfolioHasRange = !!(portfolioFrom && portfolioTo);

  return ruleSets
    .filter(
      x =>
        !portfolioHasRange ||
        (!x.validTo && (isAfter(portfolioFrom, new Date(x.validFrom)) || isAfter(portfolioTo, new Date(x.validFrom)))) ||
        (x.validTo &&
          doDateRangesOverlap({ from: portfolioFrom, to: portfolioTo }, { from: new Date(x.validFrom), to: new Date(x.validTo) }))
    )
    .map(x => ({ ...x, label: x.name, value: x.name }));
};

export const copyToClipboard = text => navigator.clipboard.writeText(text);

export const formatRowValue = value => value || "~";

export const getTransactionRealizationType = (txType, txDate, ruleSets) => {
  const txRange = { from: startOfDay(new Date(txDate)), to: endOfDay(new Date(txDate)) };
  const filteredRuleSets = getFilteredRuleSets(ruleSets, txRange.from, txRange.to);
  return filteredRuleSets?.[filteredRuleSets.length - 1]?.realizationTypes?.[txType];
};

export const getUpdatedRuleSetName = ruleSet => {
  if (!ruleSet) return "";

  return `OT-${ruleSet.taxSubject === NATURAL_PERSON ? "NP" : "LP"}-${ruleSet.validFrom
    .split("-")
    .map(x => Number(x).toString())
    .join("/")}`;
};

// eslint-disable-next-line no-extend-native,func-names
Number.prototype.toFixedWithoutScientific = function (n) {
  let str = this.toFixed(n);
  if (str.indexOf("e+") === -1) return str;

  // if number is in scientific notation, pick (b)ase and (p)ower
  str = str
    .replace(".", "")
    .split("e+")
    .reduce((b, p) => b + Array(p - b.length + 2).join(0));

  if (n > 0) str += `.${Array(n + 1).join(0)}`;

  return str;
};

export const getDataTestId = (prefix, uniqueValue) =>
  `${prefix.toLowerCase()}_${
    uniqueValue && typeof uniqueValue === "string"
      ? uniqueValue?.replaceAll("/", "")?.replaceAll("+ ", "")?.replaceAll("& ", "")?.replaceAll(" ", "-")?.toLowerCase()
      : ""
  }`;

export const TOP_OFFSET = 70;
export const getTopOffset = () => {
  const headerPosition = document.getElementById("navbar-header").getBoundingClientRect();
  return headerPosition.top + TOP_OFFSET;
};
