import React, { useMemo } from "react";
import { Form } from "grommet";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { PrimaryButton, LoaderOverlay } from "components/Common";
import { SelectFormField, TextFormField } from "components/Common/Inputs";
import { object, string } from "yup";

import { languages } from "common/constants/languages";
import { taxResidencyOptions } from "common/constants/taxResidencies";
import { taxSubjectOptions } from "common/constants/taxSubjects";
import LanguageIcon from "components/Common/LanguageIcon";
import styles from "../Styles/Forms.module.scss";

const WalkthroughForm = ({ email, organizationData, onSubmit, editUserPreferences, isLoading }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const languageOptions = useMemo(
    () =>
      languages.map(x => ({
        ...x,
        label: (
          <>
            <LanguageIcon lang={x.lang} /> {t(x.label)}
          </>
        ),
      })),
    [t]
  );

  const selectedLanguage =
    languageOptions.find(o => o.value === i18n.language || o.value.startsWith(i18n.language)) || languageOptions[0];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: email,
        taxResidency: taxResidencyOptions(t).find(x => x.value === organizationData?.taxResidency) ?? "",
        taxSubject: taxSubjectOptions(t).find(x => x.value === organizationData?.taxSubject) ?? "",
        language: selectedLanguage,
      }}
      validationSchema={object({
        name: string().required(),
        taxResidency: object().required(),
        taxSubject: object().required(),
        language: object().required(),
      })}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, values }) => (
        <Form onSubmit={handleSubmit} className={styles.grommet_form}>
          <LoaderOverlay isLoading={isSubmitting || isLoading}>
            <TextFormField name="name" label={t("form.walkthrough_setup.organization_name")} disabled={isSubmitting} required />

            <SelectFormField
              name="taxResidency"
              options={taxResidencyOptions(t)}
              label={t("form.walkthrough_setup.tax_residency")}
              disabled={isSubmitting}
              required
            />

            <SelectFormField
              name="taxSubject"
              options={taxSubjectOptions(t)}
              label={t("form.walkthrough_setup.tax_subject")}
              disabled={isSubmitting || !values.taxResidency}
              required
            />

            <SelectFormField
              name="language"
              options={languageOptions}
              label={t("form.walkthrough_setup.language")}
              disabled={isSubmitting}
              onSelect={value => editUserPreferences({ language: value })}
              required
            />
            <PrimaryButton label={t("walkthrough.start_walkthrough")} isLoading={isSubmitting} onClick={handleSubmit} />
          </LoaderOverlay>
        </Form>
      )}
    </Formik>
  );
};

export default WalkthroughForm;
