import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import {
  getOpenedPortfolio,
  getOpenedPortfolioBalances,
  getPortfolioClosedBalances,
  getPortfolioOpenedBalances,
  getTradesAndOtherRewards,
} from "selectors/portfoliosSelectors";
import { getGlobalFetchingState } from "selectors/globalSelectors";
import { GhostButton, Heading, LoaderOverlay, Spinner, Text } from "components/Common";
import { formatCurrency, formatDate } from "common/formatters";
import { useTranslation } from "react-i18next";
import TransactionsTooltip from "components/Tooltips/TransactionsTooltip";
import { rewardTimingTranslator } from "common/constants/rewardTimingOptions";
import { getActiveOrganizationId } from "selectors";
import ExpandableMenu from "components/Common/ExpandableMenu";
import { formatRowValue } from "utils";
import Delimiter from "components/Common/Delimiter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import history from "common/history";
import cx from "classnames";
import { ChangeIcon, CurrencyDecimal, PercentDecimal } from "components/Formating";
import { colors } from "common/colors";
import MasterTableWithComputation from "components/MasterTable/MasterTableWithComputation";
import { NavLink } from "react-router-dom";
import { ReactComponent as DownIcon } from "img/DownIcon.svg";
import { useScreenSize } from "hooks";
import styles from "../Styles/Dashboard.module.scss";
import { getIsWalkthroughEnabled } from "selectors/walkthroughSelectors";

const CurrencySpan = ({ label, className }) => <span className={cx(styles.small_currency, className)}>{label}</span>;
const SpinnerWrapper = () => (
  <div className={styles.flex_center}>
    <Spinner />
  </div>
);

const Positions = ({
  selectedDate,
  portfolioOpened,
  portfolioClosed,
  isBalancesFetching,
  portfolio,
  activeOrganizationId,
  tradesAndOtherRewards,
  currentDateRuleSet,
  isWalkthroughEnabled,
  modalActions: { openBaseQuote },
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const rewardsSettings = currentDateRuleSet ? currentDateRuleSet.realizationTypes : portfolio?.latestRuleSet?.realizationTypes;

  const {
    isLoading,
    currency,
    marketValue,
    acquisitionCost,
    acquisitionCostFee,
    acquisitionCostRewards,
    unrealizedProfit,
    realizedProfit,
    realizedProfitTaxable,
    profitPercentage,
  } = tradesAndOtherRewards;

  return (
    <div className={styles.master_table}>
      <div className={cx(styles.master_table_header, "mb-32")}>
        <Heading level={3}>
          {t("dashboard.master_table.trades_header")}{" "}
          {selectedDate && <span className={styles.small}>{`(${formatDate(selectedDate)})`}</span>}
        </Heading>

        <div className="mt-n2 mt-md-0">
          <span className="mr-3">{t("dashboard.master_table.taxation_of_other_rewards")}</span>
          <ExpandableMenu
            label={
              <span
                style={{ cursor: "pointer", color: colors.primary }}
                className="d-flex align-items-center justify-content-start mr-3">
                {t("dashboard.master_table.settings")} <DownIcon className="mx-1" /> <TransactionsTooltip />
              </span>
            }
            className={styles.rewards_settings}
            mobileListClassName={styles.rewards_settings_dropdown}>
            <li className={styles.rewards_setting_option}>
              <div>{t("organization.reward")}</div>
              <div>{formatRowValue(t(rewardTimingTranslator(rewardsSettings?.REWARD)))}</div>
            </li>
            <li className={styles.rewards_setting_option}>
              <div>{t("organization.airdrop")}</div>
              <div>{formatRowValue(t(rewardTimingTranslator(rewardsSettings?.AIRDROP)))}</div>
            </li>
            <li className={styles.rewards_setting_option}>
              <div>{t("organization.earn")}</div>
              <div>{formatRowValue(t(rewardTimingTranslator(rewardsSettings?.EARNING)))}</div>
            </li>
            <li className={styles.rewards_setting_option}>
              <div>{t("organization.fork")}</div>
              <div>{formatRowValue(t(rewardTimingTranslator(rewardsSettings?.FORK)))}</div>
            </li>
            <Delimiter />
            <li className={cx(styles.rewards_setting_option, "d-flex justify-content-between align-items-center")}>
              <NavLink to={`/organizations/${activeOrganizationId}/tax-rules`} style={{ textDecoration: "none" }}>
                {t("dashboard.master_table.settings").toUpperCase()}
              </NavLink>
              <GhostButton
                icon={<FontAwesomeIcon icon={faGear} />}
                onClick={e => {
                  e.preventDefault();
                  history.push(`/organizations/${activeOrganizationId}/tax-rules`);
                }}
                actionButton
                className="mr-n2"
              />
            </li>
          </ExpandableMenu>
        </div>
      </div>

      <div className={styles.positions_and_rewards}>
        <div>
          <Heading level={4} margin="-5px 0 0.5rem 0">
            {t("dashboard.portfolio_summary.acquisition_cost")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <>
              <Heading level={3} className={cx({ "negative-value": acquisitionCost < 0 })}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(acquisitionCost)} removeZeros />
              </Heading>
              <div className={styles.portfolio_summary_box_detail}>
                <Text size="small">
                  <CurrencyDecimal
                    currencyPrefix={`${t("dashboard.portfolio_summary.acquisition_cost_fee")} ${currency} `}
                    {...formatCurrency(acquisitionCostFee)}
                    removeZeros
                  />
                </Text>
              </div>
              <div className={styles.portfolio_summary_box_detail}>
                <Text size="small">
                  <CurrencyDecimal
                    currencyPrefix={`${t("dashboard.portfolio_summary.acquisition_cost_rewards")} ${currency} `}
                    {...formatCurrency(acquisitionCostRewards)}
                    removeZeros
                  />
                </Text>
              </div>
            </>
          )}
        </div>

        <div>
          <Heading level={4} margin="-5px 0 0.5rem 0">
            {t("dashboard.portfolio_summary.market_value")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <Heading level={3} className={cx({ "negative-value": marketValue < 0 })}>
              <CurrencySpan label={currency} />
              <CurrencyDecimal {...formatCurrency(marketValue)} removeZeros />
            </Heading>
          )}
        </div>

        <div>
          <Heading level={4} margin="-5px 0 0.5rem 0">
            {t("dashboard.portfolio_summary.realized_profit")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <>
              <Heading level={3}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(realizedProfit)} removeZeros />
              </Heading>
              <div className={styles.portfolio_summary_box_detail}>
                <Text size="small">
                  <CurrencyDecimal
                    currencyPrefix={`${t("dashboard.portfolio_summary.tax_base")} ${currency} `}
                    {...formatCurrency(realizedProfitTaxable)}
                    removeZeros
                  />
                </Text>
              </div>
            </>
          )}
        </div>

        <div>
          <Heading level={4} margin="-5px 0 0.5rem 0">
            {t("dashboard.portfolio_summary.unrealized_profit")}
          </Heading>
          {isLoading ? (
            <SpinnerWrapper />
          ) : (
            <>
              <Heading level={3}>
                <CurrencySpan label={currency} />
                <CurrencyDecimal {...formatCurrency(unrealizedProfit)} removeZeros />
              </Heading>
              <div className={cx(styles.portfolio_summary_box_detail, "ml-n1")}>
                <ChangeIcon val={profitPercentage} />
                <Text color={profitPercentage < 0 ? colors.red : ""} size="small">
                  <PercentDecimal number={profitPercentage} useSpan={false} />
                </Text>
              </div>
            </>
          )}
        </div>
      </div>

      <LoaderOverlay isLoading={isBalancesFetching}>
        <div className={styles.master_table_wrapper} style={isWalkthroughEnabled ? undefined : { overflowX: "scroll" }}>
          <MasterTableWithComputation
            open={portfolioOpened}
            close={portfolioClosed}
            openBaseQuote={base => openBaseQuote(base)}
            isWalkthroughEnabled={isWalkthroughEnabled}
          />
        </div>
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  const { timestamp, isBalancesFetching } = getOpenedPortfolioBalances(state);
  return {
    selectedDate: timestamp,
    portfolioOpened: getPortfolioOpenedBalances(state),
    portfolioClosed: getPortfolioClosedBalances(state),
    isBalancesFetching: isBalancesFetching === true && !getGlobalFetchingState(state),
    portfolio: getOpenedPortfolio(state),
    activeOrganizationId: getActiveOrganizationId(state),
    tradesAndOtherRewards: getTradesAndOtherRewards(state),
    isWalkthroughEnabled: getIsWalkthroughEnabled(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Positions);
