import React from "react";
import { ToastContainer, Slide } from "react-toastify";
import { CloseButton } from "components/Common";
import "react-toastify/dist/ReactToastify.css";

import styles from "components/Styles/Toasts.module.scss";

const CloseButtonWrapper = ({ closeToast }) => <CloseButton onClick={closeToast} className={styles.close_button} />;

const AlertsContainer = () => (
  <ToastContainer
    position="top-right"
    transition={Slide}
    autoClose={4000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    draggable
    pauseOnHover
    pauseOnFocusLoss={false}
    closeButton={<CloseButtonWrapper />}
    className={styles.toast_container}
  />
);

export default AlertsContainer;
