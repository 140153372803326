import React from "react";
import {
  CustomFormField,
  DateInput,
  DecimalFormField,
  SelectFormField,
  TextFormField,
  TimeInput,
} from "components/Common/Inputs";
import { formatThousandsSeparator, formatTime, utc } from "common/formatters";
import { baseQuoteTypes, isTxSingleCurrency, transactionTypeTranslator } from "common/constants/transactionType";
import { useTranslation } from "react-i18next";
import { disabledDays, isNullOrUndefined } from "utils";
import BigCurrencyDecimal from "components/Formating/BigCurrencyDecimal";
import { SubtransactionFeeRebateFields } from "containers/Forms/Transactions/TransactionTypes/TransactionFormFields";
import useDecimalSeparator from "hooks/useDecimalSeparator";
import { SMALL } from "common/constants/screenSizes";
import { Refresh as RefreshIcon } from "grommet-icons/icons";
import { colors } from "common/colors";
import { LoaderOverlay } from "components/Common";
import bigDecimal from "js-big-decimal";
import { DECIMAL_DIGITS, DECIMAL_PLACES } from "components/Common/Inputs/DecimalInput";
import { ON_ACQUISITION } from "common/constants/rewardTimingOptions";
import DynamicTooltip from "components/Tooltips/DynamicTooltip";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../../Styles/Forms.module.scss";

const RewardTransactionForm = ({
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
  transactionTypeOptions,
  currencyOptions,
  currencyOptionsWithLastUsed,
  subtransactionType,
  setSubtransactionType,
  rateLoading,
  getRate,
  txType,
  areFurtherInputsDisabled,
  rewardTxUnitPriceCurrency,
  txRealizationType,
  submitButton,
  labelOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();
  const commaAsDecimalSeparator = useDecimalSeparator();

  const unitPricePlaceholder = bigDecimal.round(
    isNullOrUndefined(values.unitPrice) && !isNaN(values.acquisitionPrice) && !isNaN(values.volume) && Number(values.volume) !== 0
      ? bigDecimal.divide(values.acquisitionPrice, values.volume, DECIMAL_DIGITS)
      : 1,
    DECIMAL_PLACES
  );
  const volumePlaceholder = bigDecimal.round(
    isNullOrUndefined(values.volume) &&
      !isNaN(values.acquisitionPrice) &&
      !isNaN(values.unitPrice) &&
      Number(values.unitPrice) !== 0
      ? bigDecimal.divide(values.acquisitionPrice, values.unitPrice, DECIMAL_DIGITS)
      : 1,
    DECIMAL_PLACES
  );
  const acquisitionPricePlaceholder = bigDecimal.round(
    !values.acquisitionPrice && !isNaN(values.unitPrice)
      ? !isNaN(values.volume)
        ? bigDecimal.multiply(values.volume, values.unitPrice)
        : bigDecimal.multiply(volumePlaceholder, values.unitPrice)
      : 1,
    DECIMAL_PLACES
  );

  const clearInputs = () => {
    setFieldValue("unitPrice", "");
    setFieldValue("volume", "");
    setFieldValue("acquisitionPrice", "");
  };

  return (
    <>
      <div className={styles.fields_row}>
        <SelectFormField
          name="type"
          options={transactionTypeOptions}
          label={t("form.add_transaction.trade_type")}
          required
          disabled={isSubmitting}
          onSelect={value => {
            const newType = value?.value;

            if (newType && !txType) {
              return;
            }

            if (baseQuoteTypes.includes(newType) && baseQuoteTypes.includes(txType)) {
              return;
            }

            if (isTxSingleCurrency(newType) && isTxSingleCurrency(txType)) {
              return;
            }

            setFieldValue("quoteCurrency", "");
            if (values.volume) {
              setFieldValue("baseQuantity", values.volume);
            }
          }}
          isClearable
          onClear={clearInputs}
        />

        <CurrencySelectFormField
          name="baseCurrency"
          options={currencyOptionsWithLastUsed}
          label={t("form.add_transaction.currency")}
          required
          disabled={isSubmitting}
          onSelect={val => {
            setFieldValue("quoteCurrency", val);
            if (!areFurtherInputsDisabled) {
              getRate({ date: values.date, base: val.base, quote: rewardTxUnitPriceCurrency });
            }
          }}
          isClearable
          onClear={clearInputs}
        />
      </div>
      <CustomFormField
        name="timestamp"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.add_transaction.date")}
        required
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.date}
            disabled={isSubmitting}
            isPlain
            isCustomField
            minDate={disabledDays.minDate}
            maxDate={disabledDays.maxDate}
            onBlur={() => setFieldTouched("timestamp", true)}
            onChange={value => {
              setFieldValue("date", value);
              if (values.baseCurrency && !areFurtherInputsDisabled) {
                getRate({
                  base: values.baseCurrency?.value,
                  quote: rewardTxUnitPriceCurrency,
                  date: value,
                });
              }

              if (!values.timestamp) {
                setFieldValue(
                  "timestamp",
                  utc(values.timestamp ?? undefined).set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                );
              }
            }}
            isClearable
            onClear={() => {
              setFieldValue("date", null);
              setFieldValue("timestamp", null);
            }}
          />
          <TimeInput
            value={formatTime(values.timestamp, true)}
            isPlain
            isCustomField
            enableSeconds
            disabled={isSubmitting}
            className={styles.time}
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestamp",
                utc(values.timestamp ?? undefined).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>
      {txRealizationType === ON_ACQUISITION ? (
        <>
          <div className={styles.fields_row}>
            <LoaderOverlay isLoading={rateLoading} className={styles.price} spinnerSize={SMALL}>
              <DecimalFormField
                name="unitPrice"
                label={
                  <span className="d-flex align-items-center">
                    <span className="mr-1">{t("form.add_transaction.price")}</span>
                    <DynamicTooltip position="top">
                      <div dangerouslySetInnerHTML={{ __html: t("form.add_transaction.unit_price_tooltip") }} />
                    </DynamicTooltip>
                    <span title={t("form.add_transaction.refresh_price")} style={{ height: "18px" }}>
                      {values.baseCurrency && !areFurtherInputsDisabled && (
                        <RefreshIcon
                          color={isSubmitting ? colors.gray3 : undefined}
                          title="Refresh price"
                          style={{ height: "18px" }}
                          onClick={() =>
                            getRate({
                              base: values.baseCurrency?.value,
                              quote: rewardTxUnitPriceCurrency,
                              date: values.date,
                            })
                          }
                        />
                      )}
                    </span>
                  </span>
                }
                required
                disabled={isSubmitting || areFurtherInputsDisabled}
                placeholder={
                  areFurtherInputsDisabled ? undefined : formatThousandsSeparator(unitPricePlaceholder, commaAsDecimalSeparator)
                }
                onChange={val => {
                  if (val !== "" && values.volume !== "" && values.acquisitionPrice !== "") setFieldValue("acquisitionPrice", "");
                }}>
                <span className={styles.small_text}>{rewardTxUnitPriceCurrency}</span>
              </DecimalFormField>
            </LoaderOverlay>
            <DecimalFormField
              name="volume"
              label={t("form.add_transaction.quantity")}
              required
              disabled={isSubmitting || areFurtherInputsDisabled}
              placeholder={
                areFurtherInputsDisabled ? undefined : formatThousandsSeparator(volumePlaceholder, commaAsDecimalSeparator)
              }
              onChange={() => {
                if (values.acquisitionPrice !== "" && values.unitPrice !== "") setFieldValue("acquisitionPrice", "");
              }}>
              <span className={styles.small_text}>{values.baseCurrency?.value || ""}</span>
            </DecimalFormField>
          </div>

          <div className={styles.fields_row}>
            <DecimalFormField
              name="acquisitionPrice"
              label={
                <span className="d-flex align-items-center">
                  <span className="mr-1">{t("form.add_transaction.acquisition_price")}</span>

                  <DynamicTooltip position="top">
                    <div dangerouslySetInnerHTML={{ __html: t("form.add_transaction.acquisition_price_tooltip") }} />
                  </DynamicTooltip>
                </span>
              }
              required
              disabled={isSubmitting || areFurtherInputsDisabled}
              placeholder={
                areFurtherInputsDisabled
                  ? undefined
                  : formatThousandsSeparator(acquisitionPricePlaceholder, commaAsDecimalSeparator)
              }
              onChange={val => {
                if (val !== "" && values.volume !== "" && values.unitPrice !== "") setFieldValue("unitPrice", "");
              }}>
              <span className={styles.small_text}>{rewardTxUnitPriceCurrency}</span>
            </DecimalFormField>
            <TextFormField
              name="note"
              label={t("form.add_transaction.note")}
              disabled={isSubmitting || areFurtherInputsDisabled}
              autoFocus={modalOptions?.focusNote}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.fields_row}>
            <DecimalFormField
              name="volume"
              label={t("form.add_transaction.quantity")}
              required={!values.acquisitionPrice || !values.unitPrice}
              disabled={isSubmitting || areFurtherInputsDisabled}
              placeholder={
                areFurtherInputsDisabled ? undefined : formatThousandsSeparator(volumePlaceholder, commaAsDecimalSeparator)
              }
              onChange={() => {
                if (values.acquisitionPrice !== "" && values.unitPrice !== "") setFieldValue("acquisitionPrice", "");
              }}>
              <span className={styles.small_text}>{values.baseCurrency?.value || ""}</span>
            </DecimalFormField>
            <TextFormField
              name="note"
              label={t("form.add_transaction.note")}
              disabled={isSubmitting || areFurtherInputsDisabled}
            />
          </div>
        </>
      )}

      <SelectFormField
        name="labels"
        options={labelOptions}
        label={t("form.add_transaction.labels")}
        disabled={isSubmitting || areFurtherInputsDisabled}
        isClearable
        isMulti
        autoFocus={modalOptions?.focusLabels}
      />

      <SubtransactionFeeRebateFields
        values={values}
        txType={txType}
        areFurtherInputsDisabled={areFurtherInputsDisabled}
        isSubmitting={isSubmitting}
        subtransactionType={subtransactionType}
        setSubtransactionType={setSubtransactionType}
        currencyOptions={currencyOptions}
      />
      <div
        className={
          values.type && values.baseCurrency && !areFurtherInputsDisabled ? styles.tx_info_label : styles.form_label_hidden
        }>
        <div>
          <div>
            {txRealizationType === ON_ACQUISITION ? (
              <>
                {`${values.type?.label}: `}
                <BigCurrencyDecimal
                  number={values.volume || volumePlaceholder || 0}
                  roundToFixedCrypto={10}
                  currency={values.baseCurrency?.value}
                />
                {` ${values.baseCurrency?.value} @ `}
                <BigCurrencyDecimal
                  number={values.unitPrice || unitPricePlaceholder || 0}
                  roundToFixedCrypto={10}
                  currency={rewardTxUnitPriceCurrency}
                />{" "}
                {rewardTxUnitPriceCurrency}
                <span className={styles.arrow}>&larr;</span>
                <BigCurrencyDecimal
                  number={values.acquisitionPrice || acquisitionPricePlaceholder || 0}
                  roundToFixedCrypto={10}
                  currency={rewardTxUnitPriceCurrency}
                />{" "}
                {rewardTxUnitPriceCurrency}
              </>
            ) : (
              <>
                {`${values.type?.label}: `}
                <BigCurrencyDecimal
                  number={values.volume || volumePlaceholder || 0}
                  roundToFixedCrypto={10}
                  currency={values.baseCurrency?.value}
                />{" "}
                {values.baseCurrency?.value}
              </>
            )}
          </div>
          {subtransactionType && (
            <div>
              {`${t(transactionTypeTranslator(subtransactionType))}: `}
              <BigCurrencyDecimal
                number={values.feeRebate || 0}
                roundToFixedCrypto={10}
                currency={values.feeRebateCurrency?.value}
              />
              {` ${values.feeRebateCurrency?.value || ""}`}
            </div>
          )}
        </div>
      </div>

      {submitButton}
    </>
  );
};

export default RewardTransactionForm;
