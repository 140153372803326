import React from "react";
import { Formik } from "formik";
import { DecimalFormField } from "components/Common/Inputs";
import { Form, GhostButton, LoaderOverlay, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { number, object } from "yup";
import { chartOfAccountNameTranslator } from "common/constants/chartOfAccountNames";
import styles from "../Styles/Forms.module.scss";

const EditChartAccountAccountForm = ({ account, allAccountNumbers, settingAccountPlaces, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const allAccountNumbersWithoutCurrent = allAccountNumbers.filter(x => x !== account.accountNumber);

  return (
    <Formik
      initialValues={{
        accountNumber: account.accountNumber,
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        accountNumber: number()
          .typeError(t("validation.invalid_number"))
          .required()
          .test("Max length", t("validation.string_max", { max: 10 }), value => value?.toString().length <= 10)
          .test("Duplicates", t("validation.unique"), value => !allAccountNumbersWithoutCurrent.includes(value?.toString())),
      })}>
      {({ isSubmitting, handleSubmit }) => (
        <LoaderOverlay isLoading={isSubmitting}>
          <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
            <p style={{ fontSize: "17px" }}>{t(chartOfAccountNameTranslator(account.accountCode))}</p>

            <DecimalFormField
              name="accountNumber"
              label={t("form.chart_account_detail.number")}
              disabled={isSubmitting}
              required
              autoFocus
              maxLength={settingAccountPlaces ?? 3}
              thousandSeparator=""
              allowNegative={false}
              decimalScale={0}
            />

            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
              <GhostButton onClick={onClose} className="mr-4" label={t("form.chart_account_detail.cancel")} />
              <PrimaryButton
                type="submit"
                noIcon
                label={t("form.chart_account_detail.update")}
                isLoading={isSubmitting}
                className="w-auto"
              />
            </div>
          </Form>
        </LoaderOverlay>
      )}
    </Formik>
  );
};

export default EditChartAccountAccountForm;
