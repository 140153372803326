import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useScreenSize } from "hooks";
import styles from "components/Styles/AppStructure.module.scss";

// defaultExpanded - development purpose
const ExpandableMenu = ({
  label,
  children,
  dataTour,
  className,
  listClassName,
  mobileListStyle,
  mobileListClassName,
  defaultExpanded,
  expandOnClickOnly = false,
  testId,
  onClose,
}) => {
  const { isSmall } = useScreenSize();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!expanded) onClose?.();
  }, [expanded]);

  return (
    <>
      {isSmall ? (
        <li
          data-tour={dataTour}
          onClick={() => setExpanded(prevState => !prevState)}
          className={cx(styles.menu_expandable, className)}
          data-testid={testId}>
          {label}
          {expanded && (
            <ul style={mobileListStyle} className={mobileListClassName} onClick={() => setExpanded(false)}>
              {children}
            </ul>
          )}
        </li>
      ) : (
        <li
          data-tour={dataTour}
          onMouseEnter={() => {
            !expandOnClickOnly && setExpanded(true);

            // Close all opened dropdowns on open
            setTimeout(() => document.getElementById("expand-catch")?.click(), 200);
          }}
          onMouseLeave={() => !expandOnClickOnly && !defaultExpanded && setExpanded(false)}
          onClick={e => {
            if (expandOnClickOnly) {
              e.stopPropagation();
              setExpanded(prevState => !prevState);
            }
          }}
          className={cx(styles.menu_expandable, className)}
          data-testid={testId}>
          {label}
          {expanded && (
            <ul className={cx(styles.menu_expandable_wrapper, listClassName)} onClick={() => setExpanded(false)}>
              <div id="expand-catch" onClick={e => e.stopPropagation()} />
              {children}
            </ul>
          )}
        </li>
      )}
    </>
  );
};

export default ExpandableMenu;
