import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Heading } from "components/Common";

import styles from "pages/Pages.module.scss";

const LegalPrivacy = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.app_content_padding}>
      <div className={styles.legal_privacy}>
        <Heading level={2} margin="0 0 2rem 0">
          {t("legal_privacy.terms_of_use.heading")}
        </Heading>
        <div>
          <div className={styles.paragraph}>{t("legal_privacy.terms_of_use.p1")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.terms_of_use.p2")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.terms_of_use.p3")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.terms_of_use.p4")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.terms_of_use.p5")}</div>
        </div>

        <Heading level={2} margin="0 0 2rem 0" id="privacy_policy">
          {t("legal_privacy.privacy_policy.heading")}
        </Heading>
        <div>
          <div className={styles.paragraph}>{t("legal_privacy.privacy_policy.p1")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.privacy_policy.p2")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.privacy_policy.p3")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.privacy_policy.p4")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.privacy_policy.p5")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.privacy_policy.p6")}</div>
          <div className={styles.paragraph}>
            <Trans i18nKey="legal_privacy.privacy_policy.p7">
              If you have any questions, queries, notices or requests, do not hesitate to contact us at: 
              <a href="mailto:support@whalebooks.com" className={styles.black_link}>
                support@whalebooks.com
              </a>
            </Trans>
          </div>
        </div>

        <Heading level={2} margin="0 0 2rem 0">
          {t("legal_privacy.cookie_notice.heading")}
        </Heading>
        <div>
          <div className={styles.paragraph}>{t("legal_privacy.cookie_notice.p1")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.cookie_notice.p2")}</div>
          <div className={styles.paragraph}>{t("legal_privacy.cookie_notice.p3")}</div>
        </div>
      </div>
    </div>
  );
};

export default LegalPrivacy;
