import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContainerDetailExplanation from "components/Modals/ModalExplanations/ContainerDetailExplanation";
import { CONTAINER_API } from "common/constants/containerTypes";
import { WIDER } from "common/constants/modalWidths";
import { MEDIUM } from "common/constants/screenSizes";
import { getConnectors, getContainerDetail, getPortfolios } from "selectors";
import { LoaderOverlay, Tab, Tabs } from "components/Common";
import { useTranslation } from "react-i18next";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import { IN_PROGRESS } from "common/constants/synchronizationStatusTypes";
import Synchronization from "components/DataManager/ContainerDetail/Synchronization";
import Header from "components/DataManager/ContainerDetail/Header";
import Transactions from "components/DataManager/ContainerDetail/Transactions";
import Settings from "components/DataManager/ContainerDetail/Settings";
import Accounts from "containers/DataManager/Containers/Detail/Accounts";
import Addresses from "containers/DataManager/Containers/Detail/Addresses";
import Pairs from "containers/DataManager/Containers/Detail/Pairs";
import styles from "../Styles/Modals.module.scss";
import ModalEnvelope from "./ModalEnvelope";
import { containersRefreshInterval } from "config/constants";

const ContainerDetailModal = ({
  containerId,
  containerType,
  activeTab,
  openConnectorSettings,
  isLoading,
  portfolios,
  transactionActions,
  modalActions,
  connectorsData: { connectors, isConnectorsFetching },
  user,
  isContainerSyncActive,
  activeManualContainerSyncIds,
  confirmationActions: { openReloadContainerTransactionsConfirmation },
  containerDetail,
}) => {
  const { t } = useTranslation();
  const [assignments, setAssignments] = useState({});

  const isApiConnection = containerType === CONTAINER_API;

  useEffect(() => {
    if (containerDetail.assignedPortfolios) {
      setAssignments(
        containerDetail.assignedPortfolios.reduce((res, val) => {
          res[val] = true;
          return res;
        }, {})
      );
    }
  }, [containerDetail]);

  const { connection, ...container } = containerDetail;

  const [loadCounter, setLoadCounter] = useState(0);
  const loadContainerDetail = useCallback(
    (asynchronous = true) => {
      setLoadCounter(prevState => prevState + 1);
      return transactionActions.getContainerDetail(containerId, asynchronous);
    },
    [transactionActions, containerId]
  );

  const containerConnection = useMemo(
    () => connectors.find(o => o.id === (connection?.connectorId || -1)),
    [connection, connectors]
  );

  const isSyncActive = isContainerSyncActive || activeManualContainerSyncIds.has(containerId);

  // Normal status reloader handle - every 40s
  useEffect(() => {
    if (isApiConnection) {
      const normalStatusReloader = setInterval(loadContainerDetail, containersRefreshInterval);

      return () => {
        clearInterval(normalStatusReloader);
        transactionActions.getTransactions();
      };
    }
  }, [transactionActions, isApiConnection, loadContainerDetail]);

  // Frequent status reloader handle - every 15s
  const { synchronizationStatus } = container;
  useEffect(() => {
    if (!activeManualContainerSyncIds.has(containerId)) {
      transactionActions.setIsContainerSyncActive(synchronizationStatus === IN_PROGRESS);
    }
  }, [synchronizationStatus, transactionActions, activeManualContainerSyncIds, containerId]);

  useEffect(() => {
    // Load detail on manual sync finish and on initial load of detail
    if (!isSyncActive || loadCounter === 0) {
      loadContainerDetail(loadCounter !== 0);
    }

    if (isSyncActive) {
      const frequentStatusReloader = setInterval(loadContainerDetail, 15000);

      return () => {
        clearInterval(frequentStatusReloader);
      };
    }
  }, [isSyncActive, loadContainerDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isLogFetching, setIsLogFetching] = useState(false);

  // Reload containers list on close
  useEffect(
    () => () => {
      transactionActions.getContainers(undefined, true);
      transactionActions.setExtendedContainerDetail();
    },
    [transactionActions]
  );

  const openDetailCallback = () => modalActions.openContainerDetail(containerId, containerType, activeTabIndex);
  const isApiContainer = containerType === CONTAINER_API;

  const [activeTabIndex, setActiveTabIndex] = useState(activeTab ?? undefined);

  const TabsWrapper = ({ children }) => (
    <Tabs
      disableAnimation
      listClassName={styles.modal_tabs}
      tabWrapperClassName={styles.overflow_y_visible}
      onTabClick={index => setActiveTabIndex(index)}
      defaultTabIndex={activeTabIndex}>
      {children}
    </Tabs>
  );

  return (
    <ModalEnvelope
      id="bach-detail-modal"
      heading={t("modal.bach_detail.heading")}
      description={<ContainerDetailExplanation />}
      width={WIDER}>
      <LoaderOverlay isLoading={isLoading} spinnerSize={MEDIUM} className={styles.edit_api_credentials_wrapper}>
        <Header
          container={container}
          openEditContainerDetail={() => modalActions.openEditContainerDetail(containerId, openDetailCallback)}
          assignments={assignments}
          isSyncActive={isSyncActive}
          portfolios={portfolios}
        />

        <Transactions container={container} user={user} transactionActions={transactionActions} />

        {isApiContainer ? (
          <TabsWrapper>
            <Tab title={t("modal.container_detail.sync")}>
              <Synchronization
                connection={connection}
                isSyncActive={isSyncActive}
                transactionActions={transactionActions}
                containerId={containerId}
                container={container}
                openReloadContainerTransactionsConfirmation={openReloadContainerTransactionsConfirmation}
                openDetailCallback={openDetailCallback}
                synchronizationStatus={synchronizationStatus}
                setIsLogFetching={setIsLogFetching}
                user={user}
                isLogFetching={isLogFetching}
              />
            </Tab>
            <Tab title={t("modal.container_detail.settings")}>
              <Settings
                isSyncActive={isSyncActive}
                containerId={containerId}
                connection={connection}
                containerConnection={containerConnection}
                isConnectorsFetching={isConnectorsFetching}
                modalActions={modalActions}
                openDetailCallback={openDetailCallback}
                openConnectorSettings={openConnectorSettings}
              />
            </Tab>
            <Tab title={t("modal.container_detail.accounts")}>
              <Accounts />
            </Tab>
            <Tab title={t("modal.container_detail.addresses")}>
              <Addresses />
            </Tab>
            <Tab title={t("modal.container_detail.pairs")}>
              <Pairs />
            </Tab>
          </TabsWrapper>
        ) : (
          <TabsWrapper>
            <Tab title={t("modal.container_detail.accounts")}>
              <Accounts />
            </Tab>
            <Tab title={t("modal.container_detail.addresses")}>
              <Addresses />
            </Tab>
            <Tab title={t("modal.container_detail.pairs")}>
              <Pairs />
            </Tab>
          </TabsWrapper>
        )}
      </LoaderOverlay>
    </ModalEnvelope>
  );
};

function mapStateToProps(state, { containerId }) {
  return {
    containerDetail: getContainerDetail(state),
    portfolios: getPortfolios(state),
    isLoading:
      state.dataManager.containerDetailFetching[containerId] ||
      state.dataManager.isContainersFetching ||
      state.dataManager.isExportContainerCsvFetching,
    connectorsData: getConnectors(state),
    user: state.user,
    isContainerSyncActive: state.dataManager.isContainerSyncActive,
    activeManualContainerSyncIds: state.dataManager.activeManualContainerSyncIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContainerDetailModal);
