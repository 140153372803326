import { DecimalFormField, TextFormField } from "components/Common/Inputs";
import { DEPOSIT, FEE, isTxTypeBuySell, REBATE, WITHDRAWAL } from "common/constants/transactionType";
import styles from "containers/Styles/Forms.module.scss";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faRightFromBracket, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "common/colors";
import { CloseButton, GhostButton } from "components/Common";
import cx from "classnames";
import { ReactComponent as ClipboardIcon } from "img/clipboard-solid.svg";
import { copyToClipboard } from "utils";
import { Add as AddIcon, Close as CloseIcon } from "grommet-icons/icons";
import CurrencySelectFormField from "components/Common/CurrencySelect";

export const FeeRebateField = ({ values, txType, isSubmitting, areFurtherInputsDisabled, isUnboundTx }) => {
  const { t } = useTranslation();

  return (
    <DecimalFormField
      name="feeRebate"
      label={txType === FEE ? t("form.add_transaction.fee") : t("form.add_transaction.rebate")}
      disabled={isSubmitting || areFurtherInputsDisabled}
      required>
      <span className={styles.small_text}>
        {isUnboundTx
          ? values.baseCurrency?.value ?? values.feeRebateCurrency?.value ?? ""
          : values.feeRebateCurrency?.value ?? ""}
      </span>
    </DecimalFormField>
  );
};

export const AddressesFields = ({ values, txType, isSubmitting, areFurtherInputsDisabled }) => {
  const { t } = useTranslation();
  const addressIcon = txType === DEPOSIT ? faRightFromBracket : txType === WITHDRAWAL ? faRightToBracket : undefined;

  return (
    <div className="mb-4">
      <FieldArray
        name="addresses"
        render={arrayHelpers => (
          <>
            {values.addresses.map((address, index) => (
              <div key={index}>
                <TextFormField
                  name={`addresses.${index}`}
                  label={
                    <span className="d-flex align-items-center" style={{ cursor: "default" }}>
                      {txType === DEPOSIT
                        ? t("form.add_transaction.address_sender")
                        : txType === WITHDRAWAL
                        ? t("form.add_transaction.address_receiver")
                        : t("form.add_transaction.address")}

                      {addressIcon && (
                        <FontAwesomeIcon
                          icon={addressIcon}
                          className="ml-2"
                          size="sm"
                          color={areFurtherInputsDisabled ? colors.gray3 : "#615e80"}
                        />
                      )}
                    </span>
                  }
                  disabled={isSubmitting || areFurtherInputsDisabled}>
                  {!areFurtherInputsDisabled && values.addresses[index] && (
                    <GhostButton
                      className={cx("mx-0", styles.input_button)}
                      icon={<ClipboardIcon />}
                      onClick={() => copyToClipboard(values.addresses[index])}
                    />
                  )}
                  {index > 0 && (
                    <GhostButton
                      className={cx("mr-0", styles.input_button)}
                      icon={<CloseIcon size="medium" />}
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  )}
                </TextFormField>
                {index === values.addresses.length - 1 && (
                  <div className={cx(styles.buttons_row, "mt-n3")}>
                    <GhostButton
                      onClick={() => arrayHelpers.push("")}
                      label={t("form.add_transaction.add_address_button")}
                      disabled={isSubmitting || areFurtherInputsDisabled}
                      icon={<AddIcon />}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};

export const SubtransactionFeeRebateFields = ({
  values,
  txType,
  isSubmitting,
  areFurtherInputsDisabled,
  subtransactionType,
  setSubtransactionType,
  currencyOptions,
  enableRebate,
}) => {
  const { t } = useTranslation();
  const isBuySell = isTxTypeBuySell(txType);

  const feeRebateCurrencyOptions = useMemo(() => {
    if (!areFurtherInputsDisabled) {
      return [
        {
          label: t("form.add_transaction.tx_currencies"),
          options: [...(isBuySell ? [values.baseCurrency, values.quoteCurrency] : [values.baseCurrency])],
        },
        {
          label: t("form.add_transaction.all_currencies"),
          options: currencyOptions.filter(x => x.value !== values.baseCurrency?.value && x.value !== values.quoteCurrency?.value),
        },
      ];
    }
    return [];
  }, [isBuySell, values.baseCurrency, values.quoteCurrency, currencyOptions, t, areFurtherInputsDisabled]);

  return (
    <>
      <div className={styles.add_fee_rebate}>
        <GhostButton
          icon={<AddIcon />}
          label={t("form.add_transaction.add_fee")}
          onClick={() => setSubtransactionType(FEE)}
          disabled={subtransactionType || areFurtherInputsDisabled}
        />
        {enableRebate && (
          <GhostButton
            icon={<AddIcon />}
            label={t("form.add_transaction.add_rebate")}
            onClick={() => setSubtransactionType(REBATE)}
            disabled={subtransactionType || areFurtherInputsDisabled}
          />
        )}
      </div>
      {subtransactionType && (
        <>
          <div className={styles.fee_rebate_container}>
            <CurrencySelectFormField
              name="feeRebateCurrency"
              options={feeRebateCurrencyOptions}
              noOptionsMessage={() => t("form.add_transaction.fee_rebate_no_options")}
              label={
                subtransactionType === FEE ? t("form.add_transaction.currency_fee") : t("form.add_transaction.currency_rebate")
              }
              required
              disabled={isSubmitting || areFurtherInputsDisabled}
              isClearable
            />
            <div className={styles.close_container}>
              <FeeRebateField
                values={values}
                txType={subtransactionType}
                areFurtherInputsDisabled={areFurtherInputsDisabled}
                isSubmitting={isSubmitting}
              />
              <CloseButton onClick={() => setSubtransactionType(null)} />
            </div>
          </div>

          <TextFormField
            name="feeRebateNote"
            label={t(`form.add_transaction.note_${subtransactionType === FEE ? "fee" : "rebate"}`)}
            disabled={isSubmitting}
          />
        </>
      )}
    </>
  );
};
