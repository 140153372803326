export const INVESTOR = "INVESTOR";
export const ACCOUNTANT = "ACCOUNTANT";
export const ATM_OPERATOR = "CRYPTO_ATM_OPERATOR";
export const PROFESSIONAL_TRADER = "TRADER";
export const ALGORITHMIC_TRADER = "ALGORITHMIC_TRADER";
export const FUND_MANAGER = "FUND_MANAGER";
export const OTHER = "OTHER";

const t_fake = str => str;

export const userCategories = [INVESTOR, ACCOUNTANT, ATM_OPERATOR, PROFESSIONAL_TRADER, ALGORITHMIC_TRADER, FUND_MANAGER, OTHER];

const userCategoriesTranslations = {
  [INVESTOR]: t_fake("constant.user_category_investor"),
  [ACCOUNTANT]: t_fake("constant.user_category_accountant"),
  [ATM_OPERATOR]: t_fake("constant.user_category_atm_operator"),
  [PROFESSIONAL_TRADER]: t_fake("constant.user_category_professional_trader"),
  [ALGORITHMIC_TRADER]: t_fake("constant.user_category_algorithmic_trader"),
  [FUND_MANAGER]: t_fake("constant.user_category_fund_manager"),
  [OTHER]: t_fake("constant.user_category_other"),
};

export const userCategoriesTranslator = val => userCategoriesTranslations[val] || "";
