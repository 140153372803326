export const YES = true;
export const NO = false;

const t_fake = str => str;

const cryptoCryptoTaxationTranslations = {
  [YES]: t_fake("organization.taxed_yes"),
  [NO]: t_fake("organization.taxed_no"),
};

export const cryptoCryptoTaxationTranslator = val => cryptoCryptoTaxationTranslations[val] || "";

export const cryptoCryptoTaxationOptions = t => [
  { label: t(cryptoCryptoTaxationTranslator(YES)), value: YES },
  { label: t(cryptoCryptoTaxationTranslator(NO)), value: NO },
];
