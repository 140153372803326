import React, { useState } from "react";
import { FieldArray, Formik } from "formik";
import { SelectFormField, TextAreaFormField, TextFormField } from "components/Common/Inputs";
import { Form, GhostButton, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { accountTypesTranslator, EXCHANGE, WALLET } from "common/constants/accountTypes";
import { accountService } from "services/accountService";
import { getCurrencyOptions } from "selectors";
import { bindActionCreators } from "redux";
import { accountActions } from "actions/accountActions";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import cx from "classnames";
import { formatAddresses } from "utils";
import { Add as AddIcon, Close as CloseIcon } from "grommet-icons/icons";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../Styles/Forms.module.scss";

const CreateAccountForm = ({ onClose, currencyOptions, user, accountActions: { getAccounts }, modalActions: { hideModal } }) => {
  const { t } = useTranslation();

  const typeOptions = [
    { label: t(accountTypesTranslator(EXCHANGE)), value: EXCHANGE },
    { label: t(accountTypesTranslator(WALLET)), value: WALLET },
  ];
  const [addressSingleAdd, setAddressSingleAdd] = useState(true);

  const onSubmit = async values => {
    const addresses = formatAddresses(values.addresses, addressSingleAdd);
    const formattedValues = {
      ...values,
      addresses: addresses.map(x => ({ address: x })),
      currency: values.currency.value,
      type: values.type.value,
    };

    await accountService.createAccount(formattedValues, user);

    hideModal();
    getAccounts();
    onClose?.();
  };

  return (
    <Formik
      initialValues={{
        name: "",
        currency: "",
        administeredBy: "",
        description: "",
        addresses: [""],
        type: "",
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        name: string().required(),
        currency: object().required(),
        administeredBy: string().required(),
        type: object().required(),
      })}>
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <TextFormField name="name" label={t("form.account_detail.name")} disabled={isSubmitting} required autoFocus />
          <div className={styles.fields_row}>
            <CurrencySelectFormField
              name="currency"
              label={t("form.account_detail.currency")}
              options={currencyOptions}
              disabled={isSubmitting}
              required
            />
            <SelectFormField
              name="type"
              label={t("form.account_detail.type")}
              options={typeOptions}
              disabled={isSubmitting}
              required
            />
          </div>

          <div className={styles.fields_row}>
            <TextFormField
              name="administeredBy"
              label={t("form.account_detail.administered_by")}
              disabled={isSubmitting}
              required
            />
            <TextFormField name="description" label={t("form.account_detail.description")} disabled={isSubmitting} />
          </div>

          {addressSingleAdd && (
            <>
              <FieldArray
                name="addresses"
                render={arrayHelpers => (
                  <>
                    {values.addresses.map((address, index) => (
                      <div key={index}>
                        <TextFormField
                          name={`addresses.${index}`}
                          label={t("form.account_detail.address")}
                          disabled={isSubmitting}>
                          {index > 0 && (
                            <span className="mt-n3 mr-2" onClick={() => arrayHelpers.remove(index)}>
                              <CloseIcon size="medium" />
                            </span>
                          )}
                        </TextFormField>
                        {index === values.addresses.length - 1 && (
                          <div className={cx(styles.buttons_row, "mt-n3")}>
                            <GhostButton
                              onClick={() => arrayHelpers.push("")}
                              label={t("form.account_detail.add_address_button")}
                              icon={<AddIcon />}
                            />
                            <GhostButton
                              onClick={() => {
                                setFieldValue("addresses", "");
                                setAddressSingleAdd(false);
                              }}
                              label={t("form.account_detail.edit_many_addresses_button")}
                              icon={<AddIcon />}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              />
            </>
          )}
          {!addressSingleAdd && (
            <>
              <TextAreaFormField name="addresses" label={t("form.account_detail.address_many")} disabled={isSubmitting} />
              <div className={cx(styles.buttons_row, "mt-n3")}>
                <GhostButton
                  onClick={() => {
                    setFieldValue("addresses", [""]);
                    setAddressSingleAdd(true);
                  }}
                  label={t("form.account_detail.cancel_many_addresses_button")}
                />
              </div>
            </>
          )}

          <PrimaryButton label={t("form.account_detail.create_account")} onClick={handleSubmit} isLoading={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    currencyOptions: getCurrencyOptions(state),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm);
