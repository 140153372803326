import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "hooks";
import { Tooltip } from "components/Common";
import { getDataTestId } from "utils";
import styles from "../Styles/Modals.module.scss";

const ModalTopButton = ({ onClick, icon, isDisplayed = true, isActive = false, isClose = false }) => {
  const { isSmall } = useScreenSize();
  const { t } = useTranslation();

  const style = isSmall ? undefined : isClose ? { marginRight: "-20px" } : { marginLeft: "-20px" };

  return (
    <Tooltip tooltipContent={t("button.close")} disabled={!isClose} placement="bottom">
      <button
        className={cx(styles.modal_top_button, {
          [styles.modal_top_button_close]: isClose,
          [styles.modal_top_button_hidden]: !isDisplayed,
          [styles.modal_top_button_active]: isActive,
        })}
        onClick={onClick}
        style={style}
        data-testid={getDataTestId("button", isClose ? "close-modal" : "modal-tooltip")}>
        {icon}
      </button>
    </Tooltip>
  );
};

export default ModalTopButton;
