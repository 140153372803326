import {
  GET_ACCOUNT_DETAIL,
  GET_ACCOUNT_STATES,
  GET_ACCOUNT_TRANSACTIONS,
  GET_ACCOUNTS,
  LOGOUT,
  SET_IS_DETAIL_FETCHING,
} from "actions/types";

const initialState = {
  isAccountsFetching: false,
  accounts: [],
  accountDetail: null,
  isDetailFetching: false,
  accountTransactions: {
    entries: [],
    totalCount: undefined,
  },
  isAccountTransactionsFetching: false,
  accountStates: [],
  isAccountsStateFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ACCOUNTS}_PENDING`:
      return {
        ...state,
        isAccountsFetching: true,
      };
    case `${GET_ACCOUNTS}_FULFILLED`:
      return {
        ...state,
        isAccountsFetching: false,
        accounts: action.payload,
      };
    case `${GET_ACCOUNTS}_REJECTED`:
      return {
        ...state,
        isAccountsFetching: false,
      };
    //= ========================================
    case `${GET_ACCOUNT_DETAIL}_PENDING`:
      return {
        ...state,
        isDetailFetching: true,
      };
    case `${GET_ACCOUNT_DETAIL}_FULFILLED`:
      return {
        ...state,
        isDetailFetching: false,
        accountDetail: action.payload,
      };
    case `${GET_ACCOUNT_DETAIL}_REJECTED`:
      return {
        ...state,
        isDetailFetching: false,
      };
    //= ========================================
    case `${GET_ACCOUNT_TRANSACTIONS}_PENDING`:
      return {
        ...state,
        isAccountTransactionsFetching: true,
      };
    case `${GET_ACCOUNT_TRANSACTIONS}_FULFILLED`:
      return {
        ...state,
        isAccountTransactionsFetching: false,
        accountTransactions: action.payload,
      };
    case `${GET_ACCOUNT_TRANSACTIONS}_REJECTED`:
      return {
        ...state,
        isAccountTransactionsFetching: false,
      };
    //= ========================================
    case SET_IS_DETAIL_FETCHING:
      return {
        ...state,
        isDetailFetching: action.payload,
      };
    //= ========================================
    case `${GET_ACCOUNT_STATES}_PENDING`:
      return {
        ...state,
        isAccountsStateFetching: true,
      };
    case `${GET_ACCOUNT_STATES}_FULFILLED`:
      return {
        ...state,
        isAccountsStateFetching: false,
        accountStates: action.payload.sort((a, b) => a.accountName.localeCompare(b.accountName)),
      };
    case `${GET_ACCOUNT_STATES}_REJECTED`:
      return {
        ...state,
        isAccountsStateFetching: false,
      };
    // ====================================
    case LOGOUT:
      return initialState;
    //= ========================================
    default:
      return state;
  }
};
