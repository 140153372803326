import { createSelector } from "reselect";
import { AVCO } from "common/constants/computationTypes";
import i18n from "i18n";
import { getRuleSets } from "selectors/organizationSelectors";
import { getFilteredRuleSets } from "utils";
import { sortBy } from "lodash";

export const getRawPortfolios = state => state.listOfPortfolios.portfolios;

export const getActivePortfolioId = state =>
  state.listOfPortfolios.vrMode
    ? state.listOfPortfolios.sharedPortfolioId
    : state.listOfPortfolios.sharedPortfolioId || state.listOfPortfolios.activePortfolio;

export const getActivePortfolioIdSearchUrl = createSelector([getActivePortfolioId], activePortfolioId =>
  activePortfolioId ? `?portfolioId=${activePortfolioId}` : "?"
);

export const getPortfoliosAreFetching = state => state.listOfPortfolios.isFetching;

export const getPortfoliosFetching = state => state.listOfPortfolios.portfoliosFetching;

export const getPortfoliosBaseQuotes = state => state.listOfPortfolios.portfoliosBaseQuotes;

export const getPortfoliosHistories = state => state.listOfPortfolios.portfoliosHistories;

export const getPortfoliosBalances = state => state.listOfPortfolios.portfoliosBalances;

export const getSharedPortfolio = state => state.listOfPortfolios.sharedPortfolio;

const mapPortfolio = ptf => ({
  ...ptf,
  hasFilters: (ptf.start !== null && ptf.end !== null) || ptf.labels.length > 0,
});

export const getOwnedPortfolios = createSelector([getRawPortfolios, getRuleSets], (portfolios, ruleSets) =>
  portfolios.map(mapPortfolio).map(x => {
    const filteredRuleSets = getFilteredRuleSets(ruleSets, x.start, x.end);
    const latestRuleSet = filteredRuleSets ? filteredRuleSets[filteredRuleSets.length - 1] : undefined;

    return { ...x, latestRuleSet };
  })
);

export const getPortfolios = createSelector([getOwnedPortfolios, getSharedPortfolio], (portfolios, sharedPortfolio) => {
  if (sharedPortfolio) {
    return [...portfolios, mapPortfolio(sharedPortfolio)];
  }
  return portfolios;
});

export const getRawOpenedPortfolio = createSelector([getPortfolios, getActivePortfolioId], (portfolios, activePortfolio) =>
  portfolios.find(o => o.id === activePortfolio)
);

export const getPortfolioRuleSetOptions = createSelector([getRawOpenedPortfolio, getRuleSets], (openedPortfolio, ruleSets) =>
  getFilteredRuleSets(ruleSets, openedPortfolio?.from, openedPortfolio?.to)
);

export const getOpenedPortfolio = createSelector(
  [getRawOpenedPortfolio, getPortfoliosFetching],
  (portfolio, portfoliosFetching) =>
    portfolio
      ? {
          ...portfolio,
          rates: portfolio.rates.reduce((res, val) => {
            res[`${val.base}_${val.quote}`] = val.rate;
            return res;
          }, {}),
          portfolioIsFetching: portfoliosFetching[portfolio.id],
        }
      : portfolio
);

export const getOpenedPortfolioEndDate = createSelector([getOpenedPortfolio], openedPortfolio => openedPortfolio?.end);

const isPortfolioEmpty = portfolio => {
  if (!portfolio) return true;
  const hasAssignments = portfolio.transactionContainerIds.length > 0;
  const hasInitialState =
    portfolio.computationType === AVCO
      ? portfolio.initialState?.pairStates?.length > 0
      : portfolio.initialState?.pairStates !== null;
  return !hasAssignments && !hasInitialState;
};

export const getIsOpenedPortfolioEmpty = createSelector([getRawOpenedPortfolio], portfolio => isPortfolioEmpty(portfolio));

export const getOpenedPortfolioHistory = createSelector(
  [getPortfoliosHistories, getActivePortfolioId],
  (histories, activePortfolio) => histories[activePortfolio] || {}
);

export const getOpenedPortfolioBalances = createSelector(
  [getPortfoliosBalances, getActivePortfolioId],
  (balances, activePortfolio) => balances[activePortfolio] || {}
);

export const getOpenedPortfolioBaseQuotes = createSelector(
  [getPortfoliosBaseQuotes, getActivePortfolioId],
  (baseQuotes, activePortfolio) => baseQuotes[activePortfolio] || {}
);

const fakeHeaderData = {
  marketValue: 0,
  acquisitionCost: 0,
  acquisitionCostFee: 0,
  acquisitionCostRewards: 0,
  unrealizedProfit: 0,
  realizedProfit: 0,
  realizedProfitTaxable: 0,
  realizedProfitNonTaxable: 0,
  quote: {
    name: "",
    decimalDigits: "2",
  },
};

export const getSummary = createSelector(
  [getOpenedPortfolioBalances, getIsOpenedPortfolioEmpty],
  ({ header, isBalancesFetching }, isEmpty) => {
    const {
      quote,
      marketValue,
      acquisitionCost,
      acquisitionCostFee,
      acquisitionCostRewards,
      unrealizedProfit,
      realizedProfit,
      realizedProfitTaxable,
      realizedProfitNonTaxable,
    } = isEmpty || !header ? fakeHeaderData : header;

    const digits = quote.decimalDigits;

    // Values in fiat currency, rounded to 2 decimal places
    return {
      marketValue: Number(marketValue).toFixed(digits),
      acquisitionCost: Number(acquisitionCost).toFixed(digits),
      acquisitionCostFee: Number(acquisitionCostFee).toFixed(digits),
      acquisitionCostRewards: acquisitionCostRewards ? Number(acquisitionCostRewards).toFixed(digits) : 0,
      unrealizedProfit: Number(unrealizedProfit).toFixed(digits),
      realizedProfit: Number(realizedProfit).toFixed(digits),
      realizedProfitTaxable: Number(realizedProfitTaxable).toFixed(digits),
      realizedProfitNonTaxable: Number(realizedProfitNonTaxable).toFixed(digits),
      profitPercentage: Number(acquisitionCost) === 0 ? 0 : (unrealizedProfit / Math.abs(Number(acquisitionCost))) * 100,
      currency: quote.name,
      isLoading: isBalancesFetching,
    };
  }
);

export const getTradesAndOtherRewards = createSelector(
  [getOpenedPortfolioBalances, getSummary],
  ({ trades }, { currency, isLoading }) => ({
    marketValue: trades?.marketValue ? Number(trades?.marketValue)?.toFixed(2) : 0,
    acquisitionCost: trades?.acquisitionCost ? Number(trades?.acquisitionCost)?.toFixed(2) : 0,
    acquisitionCostFee: trades?.acquisitionCostFee ? Number(trades?.acquisitionCostFee)?.toFixed(2) : 0,
    acquisitionCostRewards: trades?.acquisitionCostRewards ? Number(trades?.acquisitionCostRewards)?.toFixed(2) : 0,
    unrealizedProfit: trades?.unrealizedProfit ? Number(trades?.unrealizedProfit)?.toFixed(2) : 0,
    realizedProfit: trades?.realizedProfit ? Number(trades?.realizedProfit)?.toFixed(2) : 0,
    realizedProfitTaxable: trades?.realizedProfitTaxable ? Number(trades?.realizedProfitTaxable)?.toFixed(2) : 0,
    profitPercentage:
      trades?.acquisitionCost || trades?.unrealizedProfit
        ? Number(trades?.acquisitionCost) === 0
          ? 0
          : (Number(trades?.unrealizedProfit) / Math.abs(Number(trades?.acquisitionCost))) * 100
        : 0,
    currency,
    isLoading,
  })
);

export const getStakingAndRewards = createSelector(
  [getOpenedPortfolioBalances, getSummary],
  ({ staking }, { currency, isLoading }) => ({
    rewardsMarketValue: staking?.rewardsMarketValue ? Number(staking?.rewardsMarketValue)?.toFixed(2) : 0,
    rewardsAcquisitionCost: staking?.rewardsAcquisitionCost ? Number(staking?.rewardsAcquisitionCost)?.toFixed(2) : 0,
    rewardsAcquisitionCostFee: staking?.rewardsAcquisitionCostFee ? Number(staking?.rewardsAcquisitionCostFee)?.toFixed(2) : 0,
    rewardsUnrealizedProfit: staking?.rewardsUnrealizedProfit ? Number(staking?.rewardsUnrealizedProfit)?.toFixed(2) : 0,
    stakingRewardRealizedProfit: staking?.stakingRewardRealizedProfit
      ? Number(staking?.stakingRewardRealizedProfit)?.toFixed(2)
      : 0,
    stakingRewardRealizedProfitTaxable: staking?.stakingRewardRealizedProfitTaxable
      ? Number(staking?.stakingRewardRealizedProfitTaxable)?.toFixed(2)
      : 0,
    positions: staking?.positions ?? [],
    currency,
    isLoading,
  })
);

export const getOpenedBalances = createSelector([getOpenedPortfolioBalances], balances => balances.open ?? []);

export const getClosedBalances = createSelector([getOpenedPortfolioBalances], balances => balances.closed ?? []);

const roundOpened = (balance, _priceDigits, _volumeDigits) => {
  const acquisitionCostFee = balance.acquisitionCostFee ?? 0;
  const acquisitionCostRewards = balance.acquisitionCostRewards ?? 0;
  const totalValueChange = Number(balance.unrealizedProfit) / Math.abs(Number(balance.acquisitionCost));
  return {
    baseQuantity: balance.baseQuantity,
    quoteQuantity: balance.quoteQuantity,
    acquisitionUnitPrice: balance.acquisitionUnitPrice,
    marketValueUnitPrice: balance.marketValueUnitPrice,
    marketValue: balance.marketValue,
    acquisitionCost: balance.acquisitionCost,
    acquisitionCostFee,
    acquisitionCostRewards,
    unrealizedProfit: balance.unrealizedProfit,
    valueChange: Number(balance.acquisitionCost) === 0 ? 0 : totalValueChange * 100,
    stakeQuantity: balance.stakeQuantity ?? 0,
  };
};

const roundClosed = (balance, _priceDigits, _volumeDigits) => {
  const costFee = balance.costFee ?? 0;
  const costRewards = balance.costRewards ?? 0;
  const totalValueChange = (balance.revenue - balance.cost) / balance.cost;

  return {
    quantity: balance.quantity,
    acquisitionUnitPrice: balance.acquisitionUnitPrice,
    revenueUnitPrice: balance.revenueUnitPrice,
    revenue: balance.revenue,
    revenueFee: balance.revenueFee,
    revenueRewards: balance.revenueRewards,
    realizedProfit: balance.realizedProfit,
    realizedProfitTaxable: balance.realizedProfitTaxable,
    realizedProfitNonTaxable: balance.realizedProfitNonTaxable,
    cost: balance.cost,
    costFee,
    costRewards,
    valueChange: balance.cost === 0 ? 0 : totalValueChange * 100,
  };
};

const sortByBaseQuote = values => sortBy(values, x => [x.base, x.quote]);

export const getNativeOpenedBalances = createSelector([getOpenedBalances], openedBalances => {
  const positions = openedBalances
    .map(balance => {
      const volumeDigits = balance.base.decimalDigits;
      return balance.raw.map(o => {
        const priceDigits = o.quote.decimalDigits;
        return {
          ...roundOpened(o, priceDigits, volumeDigits),
          base: balance.base.name,
          quote: o.quote.name,
        };
      });
    })
    .flat();
  return sortByBaseQuote(positions);
});

export const getPortfolioOpenedBalances = createSelector([getOpenedBalances], openedBalances => {
  const positions = openedBalances
    .filter(o => o.hasOwnProperty("sum"))
    .map(o => {
      const priceDigits = o.sum.quote.decimalDigits;
      const volumeDigits = o.base.decimalDigits;
      return {
        ...roundOpened(o.sum, priceDigits, volumeDigits),
        base: o.base.name,
        quote: o.sum.quote.name,
      };
    });
  return sortByBaseQuote(positions);
});

export const getNativeClosedBalances = createSelector([getClosedBalances], closedBalances => {
  const positions = closedBalances
    .map(balance => {
      const volumeDigits = balance.base.decimalDigits;
      return balance.raw.map(o => {
        const priceDigits = o.quote.decimalDigits;
        return {
          ...roundClosed(o, priceDigits, volumeDigits),
          base: balance.base.name,
          quote: o.quote.name,
        };
      });
    })
    .flat();
  return sortByBaseQuote(positions);
});

export const getPortfolioClosedBalances = createSelector([getClosedBalances], closedBalances => {
  const positions = closedBalances
    .filter(o => o.hasOwnProperty("sum"))
    .map(o => {
      const priceDigits = o.sum.quote.decimalDigits;
      const volumeDigits = o.base.decimalDigits;
      return {
        ...roundClosed(o.sum, priceDigits, volumeDigits),
        base: o.base.name,
        quote: o.sum.quote.name,
      };
    });
  return sortByBaseQuote(positions);
});

const getRates = createSelector([getOpenedPortfolioBalances, getIsOpenedPortfolioEmpty], ({ rates }, isEmpty) => {
  if (isEmpty || !rates || rates.length === 0) return {};

  return rates.reduce((res, val) => {
    res[val.base] = {
      rate: Number(val.value).toFixed(6),
      source: val.sourceType,
    };
    return res;
  }, {});
});

export const getFiatInventory = createSelector(
  [getOpenedPortfolioBalances, getRates, getIsOpenedPortfolioEmpty],
  ({ inventory, isBalancesFetching }, rates, isEmpty) => {
    if (isEmpty || !inventory || inventory.assets.length === 0) return { currency: "", assets: [], isBalancesFetching };

    const { name, decimalDigits } = inventory.valuationCurrency;

    return {
      assets: inventory.assets
        .filter(o => o.fiat)
        .map(o => ({
          currency: o.quantity.description.name,
          quantity: Number(o.quantity.value).toFixed(o.quantity.description.decimalDigits),
          rate: rates[o.quantity.description.name],
          value: Number(o.valuation).toFixed(decimalDigits),
        }))
        .sort((a, b) => a.currency.localeCompare(b.currency)),
      currency: name,
      isBalancesFetching,
    };
  }
);

export const getPortfoliosToSelect = createSelector([getPortfolios], portfolios =>
  portfolios.map(o => ({
    value: o.id,
    label: o.name,
    assignmentCount: o.transactionContainerIds.length,
    computationType: o.computationType,
  }))
);

export const getPortfoliosToSelectExcludingCurrent = createSelector(
  [getOpenedPortfolio, getPortfolios],
  (openedPortfolio, portfolios) =>
    portfolios
      .filter(x => x.id !== openedPortfolio.id)
      .map(o => ({ value: o.id, label: o.name, assignmentCount: o.transactionContainerIds.length }))
);

export const getNotEmptyPortfoliosToSelect = createSelector([getPortfolios], portfolios =>
  portfolios.filter(o => !isPortfolioEmpty(o)).map(o => ({ value: o.id, label: o.name, computationType: o.computationType }))
);

export const getNotEmptyPortfoliosToSelectExcludingCurrent = createSelector(
  [getOpenedPortfolio, getPortfolios],
  (openedPortfolio, portfolios) =>
    portfolios
      .filter(x => x.id !== openedPortfolio.id)
      .filter(o => !isPortfolioEmpty(o))
      .map(o => ({ value: o.id, label: o.name, computationType: o.computationType }))
);

export const getPortfoliosToSelectGroupedByEmpty = createSelector([getPortfolios], portfolios =>
  portfolios.reduce(
    (res, portfolio) => {
      if (isPortfolioEmpty(portfolio)) {
        res[1].options.push({ value: portfolio.id, label: portfolio.name, isEmpty: true });
      } else {
        res[0].options.push({ value: portfolio.id, label: portfolio.name, isEmpty: false });
      }
      return res;
    },
    [{ options: [] }, { label: i18n.t("constant.reports.empty_portfolios"), options: [] }]
  )
);
