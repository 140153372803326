import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Heading } from "../Common";
import styles from "../Styles/Metrics.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.metrics_tab_content}>
      <div>
        <Heading level={3}>{t("metrics.customization.q1.heading")}</Heading>
        <div className={styles.paragraph}>
          <Trans i18nKey="metrics.customization.q1.answer">
            You are able to code your own plugins on top of WhaleBooks platform. By doing so you extend the current system. At the
            moment you can create your own connectors to exchanges and services. In near future we plan to add support also for
            coins, parsers, rates providers. To read more about the plugins visit our{" "}
          </Trans>{" "}
          <a href="https://github.com/everytrade-io" target="_blank" rel="noopener noreferrer" className={styles.black_link}>
            GitHub
          </a>
          .
        </div>
      </div>
    </div>
  );
};
