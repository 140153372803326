import React from "react";
import { Group } from "@vx/group";
import { Line } from "@vx/shape";
import { colors } from "common/colors";
import { LEFT } from "common/constants/positions";

import styles from "../Styles/Graphs.module.scss";

export default ({ xMax, x, y, val, visible, position = LEFT }) => (
  <Group
    transform={`translate(0, ${y})`}
    style={{
      pointerEvents: "none",
      opacity: visible ? 1 : 0,
      transition: "opacity 0.2s, transform 0.2s",
    }}>
    <Line
      from={{ x: position === LEFT ? 0 : x, y: 0 }}
      to={{ x: position === LEFT ? x : xMax, y: 0 }}
      stroke={colors.red}
      opacity={0.5}
      strokeWidth={1}
    />
    <Line from={{ x, y: -5 }} to={{ x, y: 5 }} stroke={colors.red} strokeWidth={3} />
    <text className={styles.min_max_value}>
      <tspan x={x} dy="-8px">
        {val}
      </tspan>
    </text>
  </Group>
);
