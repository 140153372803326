import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { GhostButton, Heading, PermissionCheck } from "components/Common";
import { ADMIN, OWNER, READ } from "common/constants/userPermission";
import { useUrlHelper } from "hooks";
import { getAllAccountSettings, getGlobalFetchingState, getUserMemberships } from "selectors";
import { bindActionCreators } from "redux";
import { organizationActions } from "actions/organizationActions";
import { modalActions } from "actions/modalActions";
import { confirmationActions } from "actions/confirmationActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import styles from "pages/Pages.module.scss";
import Delimiter from "components/Common/Delimiter";
import cx from "classnames";
import AccountingSoftwareInfo from "components/Organization/AccountingSoftwareInfo";
import AccountingSoftwareHelp from "components/Organization/AccountingSoftwareHelp";

const AccountingSoftware = ({ userMemberships, globalFetchingState, allSettings, modalActions }) => {
  const { t } = useTranslation();
  const { id } = useUrlHelper();

  const currentUserRole = useMemo(() => userMemberships.find(o => o.organizationId === id)?.role || READ, [id, userMemberships]);
  const settings = allSettings[id];

  return (
    <Row className="mt-3" noGutters>
      <Col xs={12} lg={6} className={cx(styles.border_right, "pr-lg-3")} data-tour="organization-accounting-software">
        <div className="d-flex justify-content-between">
          <Heading level={3}>{t("organization_detail.transactions.accounting_software")}</Heading>

          <PermissionCheck activeRole={currentUserRole} allowFor={[ADMIN, OWNER]}>
            <GhostButton
              icon={<EditIcon />}
              label={t("organization.edit")}
              onClick={() => modalActions.openAccountSettingsModal(id, settings)}
              className="mr-n2 pr-0"
            />
          </PermissionCheck>
        </div>

        <Delimiter className="mt-3" />
        <AccountingSoftwareInfo
          accountSettings={settings?.accountSettings}
          isFetching={settings?.isFetching && !globalFetchingState}
        />
      </Col>
      <Col xs={12} lg={6} className="mt-5 mt-lg-0 pl-lg-3" data-tour="organization-software-guide">
        <AccountingSoftwareHelp />
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    userMemberships: getUserMemberships(state),
    globalFetchingState: getGlobalFetchingState(state),
    allSettings: getAllAccountSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingSoftware);
