import React, { useState } from "react";
import { FieldArray, Formik } from "formik";
import { TextAreaFormField, TextFormField } from "components/Common/Inputs";
import { PrimaryButton, Form, GhostButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { object, string, array, lazy } from "yup";
import cx from "classnames";
import { formatAddresses } from "utils";
import { Add as AddIcon, Close as CloseIcon } from "grommet-icons/icons";
import styles from "../Styles/Forms.module.scss";

const AddAccountAddressForm = ({ onClose, accountDetail, updateAccount }) => {
  const { t } = useTranslation();

  const [addressSingleAdd, setAddressSingleAdd] = useState(true);

  const onSubmit = async (values, { setSubmitting }) => {
    const addresses = formatAddresses(values.addresses, addressSingleAdd);
    const formattedValues = {
      ...accountDetail,
      addresses: [...accountDetail.addresses, ...addresses.map(x => ({ address: x }))],
    };

    const response = await updateAccount(formattedValues);
    setSubmitting(false);
    if (onClose) onClose();
    return response;
  };

  return (
    <Formik
      initialValues={{
        addresses: [""],
      }}
      onSubmit={onSubmit}
      validationSchema={object({
        addresses: lazy(val => (Array.isArray(val) ? array().of(string().required()) : string().required())),
      })}>
      {({ isSubmitting, handleSubmit, values, setFieldValue, setFieldTouched }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          {addressSingleAdd ? (
            <>
              <FieldArray
                name="addresses"
                render={arrayHelpers => (
                  <>
                    {values.addresses.map((address, index) => (
                      <div key={index}>
                        <TextFormField
                          name={`addresses.${index}`}
                          label={t("form.account_detail.address")}
                          disabled={isSubmitting}
                          required
                          autoFocus>
                          {index > 0 && (
                            <span className="mt-n3 mr-2" onClick={() => arrayHelpers.remove(index)}>
                              <CloseIcon size="medium" />
                            </span>
                          )}
                        </TextFormField>
                        {index === values.addresses.length - 1 && (
                          <div className={cx(styles.buttons_row, "mt-n3")}>
                            <GhostButton
                              onClick={() => arrayHelpers.push("")}
                              label={t("form.account_detail.add_address_button")}
                              icon={<AddIcon />}
                            />
                            <GhostButton
                              onClick={() => {
                                setFieldValue("addresses", "");
                                setFieldTouched("addresses", false);
                                setAddressSingleAdd(false);
                              }}
                              label={t("form.account_detail.edit_many_addresses_button")}
                              icon={<AddIcon />}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              />
            </>
          ) : (
            <>
              <TextAreaFormField
                name="addresses"
                label={t("form.account_detail.address_many")}
                disabled={isSubmitting}
                required
              />
              <div className={cx(styles.buttons_row, "mt-n3")}>
                <GhostButton
                  onClick={() => {
                    setFieldValue("addresses", [""]);
                    setAddressSingleAdd(true);
                  }}
                  label={t("form.account_detail.cancel_many_addresses_button")}
                />
              </div>
            </>
          )}
          <div>
            <PrimaryButton
              noIcon
              label={t("form.account_detail.add_addresses")}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddAccountAddressForm;
