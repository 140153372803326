import React from "react";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import DownloadAccountsStatementForm from "../Forms/DownloadAccountsStatementForm";

const DownloadAccountsStatementModal = ({ onSubmit, recordGA }) => {
  const { t } = useTranslation();

  return (
    <ModalEnvelope id="download-accounts-statement" heading={t("modal.download_accounts_statement.heading")}>
      <DownloadAccountsStatementForm onSubmit={onSubmit} recordGA={recordGA} />
    </ModalEnvelope>
  );
};

export default DownloadAccountsStatementModal;
