export const NO_TIME_TEST = "NO";
export const ONE_YEAR = "1Y";
export const TWO_YEARS = "2Y";
export const THREE_YEARS = "3Y";
export const FOUR_YEARS = "4Y";
export const FIVE_YEARS = "5Y";

const t_fake = str => str;

const timeTestTranslations = {
  [NO_TIME_TEST]: t_fake("time_test.no_test"),
  [ONE_YEAR]: t_fake("time_test.one_year"),
  [TWO_YEARS]: t_fake("time_test.two_years"),
  [THREE_YEARS]: t_fake("time_test.three_years"),
  [FOUR_YEARS]: t_fake("time_test.four_years"),
  [FIVE_YEARS]: t_fake("time_test.five_years"),
};

export const timeTestTranslator = val => timeTestTranslations[val] || "";

export const timeTestOptions = t => [
  { label: t(timeTestTranslator(NO_TIME_TEST)), value: NO_TIME_TEST },
  { label: t(timeTestTranslator(ONE_YEAR)), value: ONE_YEAR },
  { label: t(timeTestTranslator(TWO_YEARS)), value: TWO_YEARS },
  { label: t(timeTestTranslator(THREE_YEARS)), value: THREE_YEARS },
  { label: t(timeTestTranslator(FOUR_YEARS)), value: FOUR_YEARS },
  { label: t(timeTestTranslator(FIVE_YEARS)), value: FIVE_YEARS },
];
