import React, { useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getBuckets, getSelectedBuckets } from "selectors/bucketSelectors";
import { bucketActions } from "actions/bucketActions";
import BucketsWrapper from "./BucketsWrapper";

const BucketsConsole = ({ buckets, selectedBuckets, bucketActions: { clickOnBucket, getBucketTransactions } }) => {
  const bucketClickAction = useCallback(
    (id, ctrl, shift) => {
      clickOnBucket(buckets, id, ctrl, shift);
      getBucketTransactions();
    },
    [clickOnBucket, buckets, getBucketTransactions]
  );

  return <BucketsWrapper onBucketClick={bucketClickAction} buckets={buckets} selectedBuckets={selectedBuckets} />;
};

function mapStateToProps(state) {
  return {
    buckets: getBuckets(state),
    selectedBuckets: getSelectedBuckets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketsConsole);
