import React, { useEffect } from "react";
import styles from "pages/Pages.module.scss";
import { Heading, LoaderOverlay, PrimaryButton } from "components/Common";
import { useTranslation } from "react-i18next";
import { Add as AddIcon } from "grommet-icons/icons";
import OrganizationsTable from "containers/Organizations/OrganizationsTable";
import { bindActionCreators } from "redux";
import { modalActions } from "actions/modalActions";
import { connect } from "react-redux";
import { userActions } from "actions/userActions";
import { isOrganizationsLoading } from "selectors";

const Organizations = ({ isLoading, userActions: { getUserOrganizations }, modalActions: { openOrganizationDetail } }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getUserOrganizations();
  }, [getUserOrganizations]);

  return (
    <div className={styles.app_content_padding}>
      <div className="d-md-flex justify-content-between align-items-center mb-3">
        <Heading level={2} className="mb-3 mb-md-0">
          {t("organizations.heading")}
        </Heading>
        <PrimaryButton
          label={t("page.profile.new_organization")}
          icon={<AddIcon color="white" />}
          onClick={_e => openOrganizationDetail()}
          style={{ width: "fit-content" }}
        />
      </div>

      <LoaderOverlay isLoading={isLoading}>
        <OrganizationsTable />
      </LoaderOverlay>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: isOrganizationsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
