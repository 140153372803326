import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { userActions } from "actions/userActions";
import { bindActionCreators } from "redux";
import queryString from "query-string";

import axios from "axios";
import { StatusCritical as ErrorIcon, StatusGood as GoodIcon } from "grommet-icons";
import { Trans, useTranslation } from "react-i18next";
import { userService } from "services";
import { alertActions } from "actions/alertActions";
import { jwtDecode } from "jwt-decode";
import { Heading, LoaderOverlay, PrimaryButton } from "components/Common";
import { SMALL } from "common/constants/screenSizes";

import styles from "pages/Pages.module.scss";

const VerifyEmail = ({ location }) => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const { t } = useTranslation();
  const { token } = queryString.parse(location.search);

  useEffect(() => {
    const verifyToken = async token => {
      try {
        await axios.post("public/sign-ups/email-verification", { token });
      } catch (err) {
        setError(err);
      }
      setFetching(false);
    };

    if (!token) {
      setError(t("verify_email.verification_failed"));
      setFetching(false);
    } else verifyToken(token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.app_content_padding}>
      <Heading level={2} textAlign="center">
        {t("verify_email.heading")}
      </Heading>
      {fetching ? (
        <LoaderOverlay isLoading spinnerSize={SMALL} />
      ) : error ? (
        <div className={styles.icon_header_label}>
          <ErrorIcon />
          <Heading level={3} textAlign="center">
            {error}
          </Heading>
          {token && (
            <PrimaryButton
              disabled={isResendLoading}
              label={t("sign_up.resend_verification")}
              onClick={async () => {
                setIsResendLoading(true);
                try {
                  const { sub } = jwtDecode(token);
                  await userService.resendVerification(sub);
                  alertActions.success(t("alert.success.verification_resent"));
                } catch (err) {
                  alertActions.error(t("alert.error.verification_not_resent"));
                }
                setIsResendLoading(false);
              }}
              collapsed
            />
          )}
        </div>
      ) : (
        <div className={styles.icon_header_label}>
          <GoodIcon />
          <Heading level={3} textAlign="center">
            <Trans i18nKey="verify_email.success_log_in">
              Verification successful. You can now{" "}
              <Link to="login" className={styles.link}>
                Log in
              </Link>
            </Trans>
          </Heading>
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(connect(undefined, mapDispatchToProps)(VerifyEmail));
