import {
  GET_PORTFOLIOS,
  GET_SHARED_PORTFOLIO,
  UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS,
  SWITCH_PORTFOLIO,
  CREATE_PORTFOLIO,
  UPDATE_PORTFOLIO,
  DELETE_PORTFOLIO,
  GET_PORTFOLIO_BALANCES,
  GET_PORTFOLIO_HISTORY,
  GET_BASE_QUOTE,
  SET_SHARED_PORTFOLIO,
  SET_VR_MODE,
} from "actions/types";
import { portfolioService } from "services";
import history, { getNewActivePortfolioUrl } from "common/history";
import i18n from "i18n";
import { initialDashboardFilter } from "common/constants/dashboardFilter";
import { alertActions } from "./alertActions";
import { modalActions } from "./modalActions";
import { accountActions } from "./accountActions";
import { useDefaultsStore } from "stores/defaultsStore";
import { getActivePortfolioId } from "selectors";

function setActivePortfolio(portfolioId) {
  return (dispatch, getState) => {
    const currentActivePortfolioId = getActivePortfolioId(getState());

    if (portfolioId === currentActivePortfolioId) return;

    // Reset selection only when switching portfolio, not in first init on page load
    if (!!currentActivePortfolioId) {
      useDefaultsStore.getState().resetPortfolioGraph();
    }

    useDefaultsStore.getState().setActivePortfolioId(portfolioId);

    dispatch({
      type: SWITCH_PORTFOLIO,
      payload: portfolioId,
    });
  };
}

function loadPortfolioData(portfolioId) {
  return (dispatch, getState) => {
    const currency = getState().listOfPortfolios.portfolios.find(o => o.id === portfolioId)?.currency || null;
    dispatch(getPortfolioBalancesAndHistory({ id: portfolioId, currency }));
  };
}

function setSharedPortfolio(id, sharingToken = null, organizationId, vaultId) {
  return {
    type: SET_SHARED_PORTFOLIO,
    payload: {
      id,
      sharingToken,
      organizationId,
      vaultId,
    },
  };
}

function getPortfolioList() {
  return (dispatch, getState) =>
    dispatch({
      type: GET_PORTFOLIOS,
      payload: {
        promise: async () => {
          const { user, listOfPortfolios } = getState();
          const portfolios = await portfolioService.getPortfolioList(user);

          // Persist active portfolioId even after logout
          const savedActivePortfolioId = useDefaultsStore.getState().activePortfolioId;
          const activePortfolioId =
            listOfPortfolios.activePortfolio ||
            portfolios.find(x => x.id === savedActivePortfolioId)?.id ||
            portfolios[0]?.id ||
            null;

          dispatch(setActivePortfolio(activePortfolioId));
          history.replace(getNewActivePortfolioUrl(activePortfolioId, history));
          return portfolios;
        },
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function getSharedPortfolio(portfolioId, organizationId, vaultId) {
  return dispatch =>
    dispatch({
      type: GET_SHARED_PORTFOLIO,
      payload: portfolioService.getSharedPortfolio(portfolioId, organizationId, vaultId),
    }).catch(err => {
      alertActions.error(err);
    });
}

function setSharedPortfolioForVR(sharedPortfolio) {
  return {
    type: `${GET_SHARED_PORTFOLIO}_FULFILLED`,
    payload: sharedPortfolio,
  };
}

function updatePortfolioAssignmentCounts() {
  // Container deleted -> Update container assignment counts for all portfolios
  return (dispatch, getState) =>
    dispatch({
      type: UPDATE_PORTFOLIOS_ASSIGNMENT_COUNTS,
      payload: portfolioService.getPortfolioList(getState().user),
    }).catch(err => {
      alertActions.error(err);
    });
}

function createPortfolio(portfolio, createdInDataManager = false) {
  return (dispatch, getState) =>
    dispatch({
      type: CREATE_PORTFOLIO,
      payload: {
        promise: async () => {
          const p = await portfolioService.createPortfolio(portfolio, getState().user);
          dispatch(modalActions.hideModal());
          alertActions.success(i18n.t("alert.success.portfolio_created"));
          history.push(createdInDataManager ? `/datamanager/containers?portfolioId=${p.id}` : `/dashboard/${p.id}`);
          return p;
        },
      },
    }).catch(err => {
      alertActions.error(err);
    });
}

function updatePortfolio(id, portfolio) {
  return (dispatch, getState) =>
    dispatch({
      type: UPDATE_PORTFOLIO,
      payload: {
        promise: async () => {
          const { portfolioAssignmentsCount, ...portfolioResult } = await portfolioService.updatePortfolio(
            id,
            portfolio,
            getState().user
          );

          dispatch(modalActions.hideModal());
          dispatch(getPortfolioBalancesAndHistory(portfolioResult));
          dispatch(accountActions.getAccountStates(portfolioResult.id));
          alertActions.success(i18n.t("alert.success.portfolio_updated"));
          return {
            portfolioAssignmentsCount,
            portfolio: portfolioResult,
          };
        },
      },
      meta: { portfolioId: id },
    }).catch(err => {
      alertActions.error(err);
    });
}

function deletePortfolio(portfolioId) {
  return (dispatch, getState) => {
    const portfolios = getState().listOfPortfolios.portfolios.filter(o => o.id !== portfolioId);
    if (portfolios.length > 0) {
      history.push(`/dashboard/${portfolios[0].id}`);
    } else {
      dispatch(setActivePortfolio(null));
      history.push("/dashboard");
    }

    dispatch({
      type: DELETE_PORTFOLIO,
      payload: {
        promise: async () => {
          dispatch(modalActions.hideModal());
          await portfolioService.deletePortfolio(portfolioId, getState().user);
          alertActions.success(i18n.t("alert.success.portfolio_deleted"));
        },
        data: portfolioId,
      },
    }).catch(err => {
      alertActions.error(err);
    });
  };
}

function togglePortfolioSharing(portfolioId, sharingEnabled) {
  return (dispatch, getState) =>
    dispatch({
      type: UPDATE_PORTFOLIO,
      payload: {
        promise: async () => {
          if (sharingEnabled) {
            const token = await portfolioService.enablePortfolioSharing(portfolioId, getState().user);
            return { portfolio: { shortLink: token } };
          }
          await portfolioService.disablePortfolioSharing(portfolioId, getState().user);
          return { portfolio: { shortLink: null } };
        },
      },
      meta: { portfolioId },
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
}

function getPortfolioBalances({ id, currency }, filter = initialDashboardFilter) {
  return (dispatch, getState) =>
    dispatch({
      type: GET_PORTFOLIO_BALANCES,
      payload: portfolioService.getPortfolioBalances(id, currency, filter, getState().user),
      meta: { portfolioId: id, filter },
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
}

function getPortfolioHistory({ id, currency }, filter = initialDashboardFilter) {
  return (dispatch, getState) =>
    dispatch({
      type: GET_PORTFOLIO_HISTORY,
      payload: portfolioService.getPortfolioHistory(id, currency, filter, getState().user),
      meta: { portfolioId: id },
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
}

// Same as calling getPortfolioBalances and getPortfolioHistory separately
function getPortfolioBalancesAndHistory({ id, currency }, filter = initialDashboardFilter) {
  return (dispatch, getState) => {
    let balancesPromiseResolve;
    const balancesPromise = new Promise((resolve, _) => {
      balancesPromiseResolve = resolve;
    });

    let historyPromiseResolve;
    const historyPromise = new Promise((resolve, _) => {
      historyPromiseResolve = resolve;
    });

    portfolioService
      .getPortfolioStateAndHistory(id, currency, filter, getState().user)
      .then(response => {
        if (response) {
          balancesPromiseResolve(response.portfolioState);
          historyPromiseResolve(response.portfolioGraph);
        }
      })
      .catch(err => {
        if (err && !err.isCancelled) alertActions.error(err);
      });

    dispatch({
      type: GET_PORTFOLIO_BALANCES,
      payload: balancesPromise,
      meta: { portfolioId: id, filter },
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
    dispatch({
      type: GET_PORTFOLIO_HISTORY,
      payload: historyPromise,
      meta: { portfolioId: id },
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
  };
}

function getBaseQuoteData(portfolioId, base, quote, isSummary) {
  return (dispatch, getState) =>
    dispatch({
      type: GET_BASE_QUOTE,
      payload: {
        promise: portfolioService.getPortfolioBaseQuote(portfolioId, base, quote, isSummary, getState().user),
        data: { base, quote, isSummary },
      },
      meta: { portfolioId },
    }).catch(err => {
      if (err && !err.isCancelled) alertActions.error(err);
    });
}

function setVrMode(vrMode) {
  return {
    type: SET_VR_MODE,
    payload: vrMode,
  };
}

export const portfolioActions = {
  getPortfolioList,
  loadPortfolioData,
  getSharedPortfolio,
  setSharedPortfolioForVR,
  setActivePortfolio,
  setSharedPortfolio,
  createPortfolio,
  updatePortfolioAssignmentCounts,
  updatePortfolio,
  deletePortfolio,
  getPortfolioBalances,
  getPortfolioHistory,
  getPortfolioBalancesAndHistory,
  getBaseQuoteData,
  togglePortfolioSharing,
  setVrMode,
};
