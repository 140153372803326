import React, { Component } from "react";
import PropTypes from "prop-types";
import { GhostButton } from "components/Common";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      errorInfo: "",
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div
          className="card my-5"
          style={{
            width: "90%",
            borderRadius: "12px",
            margin: "0 auto",
            border: "2px dotted var(--primary-primary)",
            overflow: "hidden",
          }}>
          <div className="card-header" style={{ border: "none" }}>
            <h4 className="my-3 d-flex align-items-center">
              An error has occurred. Try to
              <GhostButton
                label={<span style={{ fontSize: "18px" }}>reload the application</span>}
                className="m-0"
                onClick={() => window.location.reload()}
              />
              or contact support.
            </h4>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
