import React from "react";

import styles from "./Components.module.scss";

const Video = ({ youtubeId, title }) => (
  <div className={styles.video}>
    <iframe src={`https://www.youtube.com/embed/${youtubeId}`} title={title} frameBorder="0" />
  </div>
);

export default Video;
