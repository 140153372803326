import { useLayoutEffect } from "react";
import { connect } from "react-redux";
import { confirmationActions } from "actions/confirmationActions";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { jwtDecode } from "jwt-decode";
import history from "common/history";

const Invitation = ({
  user,
  location,
  confirmationActions: { openAcceptInvitationConfirmation, logoutToAcceptInvitationConfirmation },
}) => {
  useLayoutEffect(() => {
    const { token, organization } = queryString.parse(location.search);
    const tokenData = jwtDecode(token);
    if (!user.email) {
      history.push(`/signup${location.search}`);
    } else if (user.email?.toLowerCase() === tokenData.sub?.toLowerCase()) {
      history.replace("/profile");
      openAcceptInvitationConfirmation(organization, token);
    } else {
      history.replace("/profile");
      logoutToAcceptInvitationConfirmation(location.search, tokenData.sub);
    }
  }, [location.search, user.email, openAcceptInvitationConfirmation, logoutToAcceptInvitationConfirmation]);

  return null;
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    confirmationActions: bindActionCreators(confirmationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation);
