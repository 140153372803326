import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { modalActions } from "actions/modalActions";
import { portfolioActions } from "actions/portfolioActions";
import { RadioButtonsInput, SelectInput } from "components/Common/Inputs";
import { getBaseQuoteOptionsForSelect, getPortfolioBaseQuoteHistory, getOpenedPortfolio } from "selectors";

import { ULTRAWIDE } from "common/constants/modalWidths";
import { defaultOptionStyles, customSelectStyles } from "common/styles/selectStyles";
import { useTranslation } from "react-i18next";
import ModalEnvelope from "./ModalEnvelope";
import BaseQuoteGraphContainer from "../Graphs/BaseQuoteGraphContainer";

import styles from "../Styles/Modals.module.scss";

const enhancedSelectStyles = {
  ...customSelectStyles,
  option: (styles, option) => {
    const {
      data: { quote },
    } = option;
    return {
      ...defaultOptionStyles(styles, option),
      fontWeight: quote === null ? "bold" : "normal",
      paddingLeft: quote === null ? "0.5rem" : "1rem",
    };
  },
};

const BaseQuoteModal = ({
  portfolioActions: { getBaseQuoteData },
  portfolio: { id, currency },
  baseQuoteSelectOptions,
  baseQuoteHistory,
  base,
  quote,
}) => {
  const { t } = useTranslation();
  const [displayed, setDisplayed] = useState("BOTH");
  const [selectedPair, setSelectedPair] = useState({ base, quote: quote || null });

  useEffect(() => {
    const getBaseQuoteDataFn = () => {
      if (selectedPair.quote) {
        getBaseQuoteData(id, selectedPair.base, selectedPair.quote, false);
      } else {
        getBaseQuoteData(id, selectedPair.base, null, true);
      }
    };
    getBaseQuoteDataFn();
  }, [selectedPair, id, currency, getBaseQuoteData]);

  return (
    <ModalEnvelope id="currency-deposits-modal" heading={t("modal.base_quote.heading")} width={ULTRAWIDE}>
      <div className={styles.bq_field_group}>
        <SelectInput
          label={<b>{t("modal.base_quote.base_quote_label")}</b>}
          value={baseQuoteSelectOptions.find(o => o.base === selectedPair.base && o.quote === selectedPair.quote)}
          options={baseQuoteSelectOptions}
          getOptionLabel={({ base, quote }) => `${base} - ${quote ?? currency}`}
          getOptionValue={({ base, quote }) => `${base}_${quote}`}
          onChange={val => setSelectedPair(val)}
          styles={enhancedSelectStyles}
          isInline
        />
        <RadioButtonsInput
          options={[
            { label: t("modal.base_quote.display_options.both"), value: "BOTH", disabled: false },
            { label: t("modal.base_quote.display_options.base"), value: "BASE", disabled: false },
            { label: t("modal.base_quote.display_options.quote"), value: "QUOTE", disabled: false },
          ]}
          isInline
          label={<b>{t("modal.base_quote.display_label")}</b>}
          onChange={value => setDisplayed(value)}
          value={displayed}
          className={styles.bq_options}
        />
      </div>
      <BaseQuoteGraphContainer baseQuoteHistory={baseQuoteHistory} displayed={displayed} />
    </ModalEnvelope>
  );
};

function mapStateToProps(state) {
  return {
    baseQuoteSelectOptions: getBaseQuoteOptionsForSelect(state),
    baseQuoteHistory: getPortfolioBaseQuoteHistory(state),
    portfolio: getOpenedPortfolio(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    portfolioActions: bindActionCreators(portfolioActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseQuoteModal);
