import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { walkthroughActions } from "actions/walkthroughActions";
import { modalActions } from "actions/modalActions";
import Tour from "reactour";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Common";
import WalkThroughWizard from "./WalkThroughWizard";
import styles from "../Styles/Walkthrough.module.scss";
import history from "common/history";
import { getActivePortfolioId } from "selectors";
import { useScreenSize } from "hooks";
import ImageTransactions from "img/walkthrough/initial/transactions.webp";
import ImageTransactionsMobile from "img/walkthrough/transactions.webp";

const DataManagerWalkthrough = ({
  activePortfolioId,
  walkthrough: { dataManagerIsOpen, dataManagerStep },
  walkthroughActions: { updateStep, startReportsWalkthrough, endWalkthrough },
  enableBodyScroll,
  disableBodyScroll,
}) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const steps = [
    {
      selector: isSmall ? '[data-tour="burger-button"]' : '[data-tour="data-manager-link"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.portfolio.data_manager_link.heading")}</Heading>
          <div>{t("walkthrough.portfolio.data_manager_link.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="datamanager-transactions-portfolio-filter"]',
      position: "bottom",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.data_manager.portfolio_filter.heading")}</Heading>
          <div>{t("walkthrough.data_manager.portfolio_filter.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="datamanager-containers-console"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.data_manager.containers_console.heading")}</Heading>
          <div>{t("walkthrough.data_manager.containers_console.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="datamanager-transaction-console"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.data_manager.transaction_table.heading")}</Heading>
          <div>{t("walkthrough.data_manager.transaction_table.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="datamanager-add-transaction"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.data_manager.add_transaction.heading")}</Heading>
          <div>{t("walkthrough.data_manager.add_transaction.content")}</div>
        </div>
      ),
    },
    {
      selector: '[data-tour="datamanager-table-actions"]',
      position: "top",
      content: () => (
        <div className={styles.walkthrough_step}>
          <Heading level={5}>{t("walkthrough.data_manager.table_actions.heading")}</Heading>
          <div>{t("walkthrough.data_manager.table_actions.content")}</div>
        </div>
      ),
    },
  ];

  const [updateTrigger, setUpdateTrigger] = useState(dataManagerStep.toString());
  useEffect(() => {
    setTimeout(() => setUpdateTrigger(dataManagerStep.toString()), 100);
  }, [dataManagerStep]);

  return (
    <Tour
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
      steps={steps}
      onRequestClose={endWalkthrough}
      closeWithMask={false}
      disableInteraction
      rounded={8}
      startAt={0}
      isOpen={dataManagerIsOpen}
      goToStep={dataManagerStep}
      getCurrentStep={step => updateStep(step)}
      update={updateTrigger}
      updateDelay={500}
      maskClassName="tour-mask"
      CustomHelper={props => (
        <WalkThroughWizard
          {...props}
          onLastStepClick={() => {
            history.push(`/reports?portfolioId=${activePortfolioId}`);
            startReportsWalkthrough();
          }}
          InitialStepImage={ImageTransactions}
          InitialStepImageMobile={ImageTransactionsMobile}
        />
      )}
    />
  );
};

const mapStateToProps = state => ({
  walkthrough: state.walkthrough,
  activePortfolioId: getActivePortfolioId(state),
});

const mapDispatchToProps = dispatch => ({
  walkthroughActions: bindActionCreators(walkthroughActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataManagerWalkthrough);
