import React from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../Common";

import styles from "../Styles/Metrics.module.scss";

export default () => {
  const { t } = useTranslation();
  return (
    <div className={styles.metrics_tab_content}>
      <div>
        <Heading level={4}>
          <span>01.</span>
          {t("metrics.create_blank.heading")}
        </Heading>
        <p>{t("metrics.create_blank.content")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>02.</span>
          {t("metrics.manual_transactions.heading")}
        </Heading>
        <p>{t("metrics.manual_transactions.p1")}</p>
        <p>{t("metrics.manual_transactions.p2")}</p>
        <p>{t("metrics.manual_transactions.p3")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>03.</span>
          {t("metrics.data_containers_explain.heading")}
        </Heading>
        <p>{t("metrics.data_containers_explain.content")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>04.</span>
          {t("metrics.container_detail.heading")}
        </Heading>
        <p>{t("metrics.container_detail.content")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>05.</span>
          {t("metrics.apply_portfolio_filters.heading")}
        </Heading>
        <p>{t("metrics.apply_portfolio_filters.content")}</p>
      </div>
      <div>
        <Heading level={4}>
          <span>06.</span>
          {t("metrics.data_manager_transactions_labels.heading")}
        </Heading>
        <p>{t("metrics.data_manager_transactions_labels.content")}</p>
      </div>
    </div>
  );
};
