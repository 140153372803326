import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

const t_fake = str => str;

const titleTranslations = {
  name: t_fake("title.name"),
  home: t_fake("title.home"),
  pricing: t_fake("title.pricing"),
  about: t_fake("title.about"),
  help: t_fake("title.help"),
  signup: t_fake("title.signup"),
  legal: t_fake("title.legal"),
  vr: t_fake("title.vr"),
  dashboard: t_fake("title.dashboard"),
  reports: t_fake("title.reports"),
  datamanager: t_fake("title.data_manager"),
  support: t_fake("title.support"),
  profile: t_fake("title.profile"),
  forgottenpassword: t_fake("title.forgotten_password"),
  forgot: t_fake("title.reset_password"),
  portfolio: t_fake("title.portfolio"),
  invitation: t_fake("title.invitation"),
  verify: t_fake("title.verify_email"),
  notfound: t_fake("title.not_found"),
  organization: t_fake("title.organization"),
  "asset-accounts": t_fake("title.asset_accounts"),
};

const descriptionTranslations = {
  home: t_fake("description.home"),
  pricing: t_fake("description.pricing"),
  about: t_fake("description.about"),
  help: t_fake("description.help"),
};

const titleTranslator = val => titleTranslations[val] || "";
const descriptionTranslator = val => descriptionTranslations[val] || "";

export const Title = () => {
  const { t } = useTranslation();
  const subPageName = window.location.pathname.split("/")[1];

  const formattedTitle = useMemo(() => {
    const et = t(titleTranslator("name"));
    const translatedSubpageName = t(titleTranslator(subPageName));

    if (subPageName && translatedSubpageName) {
      return `${translatedSubpageName} | ${et}`;
    }
    if (subPageName) {
      return et;
    }
    return `${et} | ${t(titleTranslator("home"))}`;
  }, [subPageName, t]);

  const description = useMemo(
    () =>
      subPageName && t(descriptionTranslator(subPageName))
        ? t(descriptionTranslator(subPageName))
        : t(descriptionTranslator("home")),
    [subPageName, t]
  );

  useEffect(() => {
    document.getElementById("meta-description").content = description;
  }, [description]);

  useEffect(() => {
    document.getElementById("page-title").innerHTML = formattedTitle;
  }, [formattedTitle]);

  return null;
};
