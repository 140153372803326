import { createBrowserHistory } from "history";

export default createBrowserHistory();

export function getNewActivePortfolioUrl(portfolioId, history) {
  // TODO: Create middleware
  if (history.location?.pathname.startsWith("/reports")) {
    return portfolioId ? `/reports?portfolioId=${portfolioId}` : "/reports";
  }
  if (history.location?.pathname.startsWith("/datamanager")) {
    if (history.location?.pathname.startsWith("/datamanager/containers")) {
      return portfolioId ? `/datamanager/containers?portfolioId=${portfolioId}` : "/datamanager/containers";
    }
    if (history.location?.pathname.startsWith("/datamanager/buckets")) {
      return portfolioId ? `/datamanager/buckets?portfolioId=${portfolioId}` : "/datamanager/buckets";
    }
  }

  if (history.location?.pathname.startsWith("/asset-accounts")) {
    return portfolioId ? `${history.location.pathname}?portfolioId=${portfolioId}` : history.location.pathname;
  }

  if (history.location?.pathname.startsWith("/dashboard")) {
    if (history.location?.pathname.endsWith("/vr")) {
      return history.location.pathname;
    }

    return portfolioId ? `/dashboard/${portfolioId}` : "/dashboard";
  }

  return `${history.location?.pathname || ""}${history.location?.search || ""}`;
}
