import axios from "axios";
import queryString from "query-string";
import { losslesslyParseJSON } from "utils";

const cancelToken = axios.CancelToken;
let getAccountTransactionsCancelToken;
let getAccountStatesCancelToken;

async function getAccounts({ organizationId }) {
  const response = await axios.get(`/organizations/${organizationId}/accounts`);
  return response.data;
}

async function getAccountDetail(accountId, { organizationId }) {
  const response = await axios.get(`/organizations/${organizationId}/accounts/${accountId}`);
  return response.data;
}

async function getAccountTransactions(page, count, filteredBy, accountId, { organizationId }) {
  if (getAccountTransactionsCancelToken) getAccountTransactionsCancelToken.cancel();
  getAccountTransactionsCancelToken = cancelToken.source();

  const query = queryString.stringifyUrl(
    {
      url: `/organizations/${organizationId}/accounts/${accountId}/entries`,
      query: {
        page: page + 1,
        count,
        portfolioId: filteredBy?.portfolioId?.value,
        timeOrder: filteredBy?.timeOrder,
      },
    },
    {
      skipNull: true,
    }
  );

  const response = await axios.get(query, {
    cancelToken: getAccountTransactionsCancelToken.token,
    transformResponse: response => losslesslyParseJSON(response),
  });

  return response.data;
}

async function createAccount(data, { organizationId }) {
  const response = await axios.post(`/organizations/${organizationId}/accounts`, data);
  return response.data;
}

async function updateAccount(data, { organizationId }) {
  const response = await axios.post(`/organizations/${organizationId}/accounts`, data);
  return response.data;
}

async function deleteAccount(accountId, { organizationId }) {
  const response = await axios.delete(`/organizations/${organizationId}/accounts/${accountId}`);
  return response.data;
}

async function getAccountStates(portfolioId, filter, { organizationId }) {
  if (getAccountStatesCancelToken) getAccountStatesCancelToken.cancel();
  getAccountStatesCancelToken = cancelToken.source();

  const response = await axios.get(`/organizations/${organizationId}/accounts/states`, {
    cancelToken: getAccountStatesCancelToken.token,
    transformResponse: response => losslesslyParseJSON(response),
    getPublicUrl: oId => `organizations/${oId}/accounts/states`,
    params: {
      portfolioId,
      from: filter.from || undefined,
      to: filter.to || undefined,
    },
  });
  return response.data.states;
}

export const accountService = {
  getAccounts,
  getAccountDetail,
  getAccountTransactions,
  createAccount,
  updateAccount,
  deleteAccount,
  getAccountStates,
};
