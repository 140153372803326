import React from "react";
import { TableRow } from "grommet";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import GraphIcon from "img/GraphIcon.svg";
import { colors } from "common/colors";
import { formatCurrency } from "common/formatters";
import FormattedNumber from "components/Common/FormattedNumber";
import { getCurrencyIconProps } from "utils/currencyIcon";
import { ChangeIcon, PercentDecimal } from "../Formating";
import CurrencySpan from "./CurrencySpan";

import styles from "../Styles/MasterTable.module.scss";
import { GhostButton } from "../Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export default ({
  base,
  quote,
  quantity,
  acquisitionUnitPrice,
  revenueUnitPrice,
  revenue,
  revenueFee,
  revenueRewards,
  cost,
  costFee,
  costRewards,
  realizedProfit,
  realizedProfitTaxable,
  valueChange,
  openBaseQuote,
  withComputation = false,
  showDetails,
  rowId,
}) => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    // https://stackoverflow.com/questions/56801421/how-to-select-text-to-copy-without-triggering-click-event-in-reactjs
    const selectedText = window.getSelection().toString(); // TODO: Move text selection test to common/utils
    if (selectedText === "") openBaseQuote(base, quote);
  };

  return (
    <TableRow className={cx(styles.table_row_clickable, { [styles.table_row_details]: showDetails })} onClick={handleOnClick}>
      <td>
        <div>
          <img {...getCurrencyIconProps(base)} alt={base} />
          <h4 style={{ marginTop: "3px" }} className="mr-2">
            {withComputation ? base : `${base} - ${quote}`}
          </h4>
        </div>
      </td>
      {withComputation ? (
        <td>
          <h4 className={cx({ "negative-value": quantity < 0 })}>
            <CurrencySpan label={base} />
            <FormattedNumber number={quantity} currency={base} />
          </h4>
        </td>
      ) : (
        <td />
      )}
      <td>
        <div className="mr-2">
          <h4 className={cx({ "negative-value": cost < 0 })}>
            <CurrencySpan label={quote} />
            <FormattedNumber number={cost} currency={quote} />
          </h4>
          {showDetails && (
            <h6>
              <span className={cx({ "negative-value": quantity < 0 })}>
                <FormattedNumber number={quantity} currency={base} suffix={` ${base}`} />
              </span>
              {` @ `}
              <FormattedNumber number={acquisitionUnitPrice} currency={quote} suffix={` ${quote}`} />

              <div className="mt-1">
                <FormattedNumber
                  number={costFee}
                  currency={quote}
                  prefix={`${t("dashboard.master_table.acquisition_cost_fee")} ${quote} `}
                />
              </div>
              {withComputation && (
                <div className="mt-1">
                  <FormattedNumber
                    number={costRewards}
                    currency={quote}
                    prefix={`${t("dashboard.master_table.acquisition_cost_rewards")} ${quote} `}
                  />
                </div>
              )}
            </h6>
          )}
        </div>
      </td>
      <td>
        <div className="mr-2">
          <h4 className={cx({ "negative-value": revenue < 0 })}>
            <CurrencySpan label={quote} />
            <FormattedNumber number={revenue} currency={quote} />
          </h4>
          {showDetails && (
            <h6>
              <span className={cx({ "negative-value": quantity < 0 })}>
                <FormattedNumber number={quantity} currency={base} suffix={` ${base}`} />
              </span>
              {` @ `}
              <FormattedNumber number={revenueUnitPrice} currency={quote} suffix={` ${quote}`} />

              {withComputation && (
                <>
                  <div className="mt-1">
                    <FormattedNumber
                      number={revenueFee}
                      currency={quote}
                      prefix={`${t("dashboard.master_table.acquisition_cost_fee")} ${quote} `}
                    />
                  </div>
                  <div className="mt-1">
                    <FormattedNumber
                      number={revenueRewards}
                      currency={quote}
                      prefix={`${t("dashboard.master_table.acquisition_cost_rewards")} ${quote} `}
                    />
                  </div>
                </>
              )}
            </h6>
          )}
        </div>
      </td>
      <td>
        <h4>
          <CurrencySpan label={quote} />
          <FormattedNumber number={realizedProfit} currency={quote} />
        </h4>
        {showDetails && (
          <>
            <h6 className={cx(styles.change_field, "ml-n1")} style={{ height: "15px" }}>
              <ChangeIcon val={valueChange} />
              <span style={valueChange < 0 ? { color: colors.red } : undefined}>
                <PercentDecimal number={valueChange} useSpan={false} />
              </span>
            </h6>

            <h6 className="m-0 p-0">
              <div style={{ paddingTop: "7px" }}>
                <FormattedNumber
                  currency={quote}
                  prefix={`${t("dashboard.portfolio_summary.tax_base")} ${quote} `}
                  {...formatCurrency(realizedProfitTaxable)}
                />
              </div>
            </h6>
          </>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-end align-items-center">
          <GhostButton
            icon={<FontAwesomeIcon icon={faChartLine} />}
            onClick={handleOnClick}
            actionButton
            testId={`open-position-detail_${rowId}`}
          />
        </div>
      </td>
    </TableRow>
  );
};
