import React, { useState } from "react";
import { Formik } from "formik";
import { utc } from "common/formatters";
import { validateTransaction } from "common/validators";
import { connect } from "react-redux";
import { transactionActions } from "actions/transactionActions";
import { modalActions } from "actions/modalActions";
import { transactionService } from "services/transactionService";
import { alertActions } from "actions/alertActions";
import { bindActionCreators } from "redux";
import {
  isTxTypeDepositWithdrawal,
  isTxTypeFeeRebate,
  isTxTypeReward,
  isTxTypeStake,
  transactionTypes,
  transactionTypeTranslator,
} from "common/constants/transactionType";
import {
  getActiveOrganizationId,
  getCurrencyOptions,
  getCurrencyOptionsWithLastUsed,
  getOpenedPortfolio,
  getRuleSets,
} from "selectors";
import { useTranslation } from "react-i18next";
import { formatAddresses } from "utils";
import { bucketActions } from "actions/bucketActions";
import TransactionFormContent from "./TransactionFormContent";

const transformTx = (values, rewardTxUnitPriceCurrency, addressSingleAdd) => {
  const dateMoment = utc(values.date);

  const type = values.type.value;
  const isDepositWithdrawal = isTxTypeDepositWithdrawal(type);
  const isStake = isTxTypeStake(type);
  const isReward = isTxTypeReward(type);

  return {
    baseCurrency: values.baseCurrency?.value,
    ...(isReward
      ? {
          quoteCurrency: rewardTxUnitPriceCurrency,
        }
      : {
          quoteCurrency: values.quoteCurrency?.value,
        }),
    type: values.type.value,
    timestamp: values.timestamp
      .set({
        year: dateMoment.year(),
        month: dateMoment.month(),
        date: dateMoment.date(),
      })
      .valueOf(),
    note: values.note,
    addresses: formatAddresses(values.addresses, addressSingleAdd),
    ...(isDepositWithdrawal || isStake
      ? {
          volume: values.volume,
        }
      : isReward
      ? {
          unitPrice: values.unitPrice || undefined,
          volume: values.volume,
          acquisitionPrice: values.acquisitionPrice || undefined,
        }
      : {
          baseQuantity: values.baseQuantity,
          quoteQuantity: values.quoteQuantity,
          unitPrice: values.unitPrice,
        }),
    transactionLabels: values.labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
  };
};

const defaultValues = {
  type: "",
  baseCurrency: "",
  quoteCurrency: "",
  baseQuantity: "",
  quoteQuantity: "",
  unitPrice: "",
  feeRebate: "",
  feeRebateCurrency: "",
  feeRebateNote: "",
  date: undefined,
  timestamp: undefined,
  note: "",
  addresses: [""],
  volume: "",
  acquisitionPrice: "",
  labels: [],
};

const AddTransactionForm = ({
  containerId,
  currencyOptions,
  currencyOptionsWithLastUsed,
  openedPortfolio,
  transactionActions: { getTransactions, setLastUsedCurrencies },
  modalActions: { hideModal },
  bucketActions: { getBuckets },
  user,
  activeOrganizationId,
  ruleSets,
  labelOptions,
}) => {
  const { t } = useTranslation();

  const rewardTxUnitPriceCurrency = openedPortfolio?.currency ?? "USD";

  const [subtransactionType, setSubtransactionType] = useState(null);
  const [addressSingleAdd, setAddressSingleAdd] = useState(true);

  const transactionTypeOptions = transactionTypes.map(o => ({
    value: o,
    label: t(`${transactionTypeTranslator(o)}`),
  }));

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const type = values.type.value;

    try {
      if (isTxTypeFeeRebate(type)) {
        const dateMoment = utc(values.date);

        const tx = {
          type: values.type.value,
          baseCurrency: values.baseCurrency.value,
          quoteCurrency: values.quoteCurrency.value,
          feeRebateCurrency: values.quoteCurrency.value,
          feeRebate: values.feeRebate,
          timestamp: values.timestamp
            .set({
              year: dateMoment.year(),
              month: dateMoment.month(),
              date: dateMoment.date(),
            })
            .valueOf(),
          note: values.feeRebateNote,
          addresses: formatAddresses(values.addresses, addressSingleAdd),
          transactionLabels: values.labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
        };
        await transactionService.createTransaction(tx, containerId, user);
      } else if (subtransactionType) {
        const mainTx = transformTx(values, rewardTxUnitPriceCurrency, addressSingleAdd);
        await transactionService.createTransaction(
          {
            mainTransaction: mainTx,
            relatedTransactions: [
              {
                type: subtransactionType,
                feeRebateCurrency: values.feeRebateCurrency.value,
                feeRebate: values.feeRebate,
                note: values.feeRebateNote,
                baseCurrency: mainTx.baseCurrency,
                quoteCurrency: mainTx.quoteCurrency,
                timestamp: mainTx.timestamp,
                transactionLabels: values.labels?.map(x => ({ transactionLabelId: x.value, transactionLabel: x.label })),
              },
            ],
          },
          containerId,
          user
        );
      } else {
        await transactionService.createTransaction(
          transformTx(values, rewardTxUnitPriceCurrency, addressSingleAdd),
          containerId,
          user
        );
      }

      alertActions.success(t("alert.success.tx_added"));
      resetForm();
      setSubtransactionType(null);
      getTransactions();
      getBuckets();
    } catch (err) {
      alertActions.error(err);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={defaultValues}
      validate={values => validateTransaction(values, subtransactionType)}
      onSubmit={onSubmit}>
      {props => (
        <TransactionFormContent
          {...props}
          transactionTypeOptions={transactionTypeOptions}
          setSubtransactionType={setSubtransactionType}
          subtransactionType={subtransactionType}
          addressSingleAdd={addressSingleAdd}
          setAddressSingleAdd={setAddressSingleAdd}
          rewardTxUnitPriceCurrency={rewardTxUnitPriceCurrency}
          activeOrganizationId={activeOrganizationId}
          ruleSets={ruleSets}
          hideModal={hideModal}
          currencyOptions={currencyOptions}
          currencyOptionsWithLastUsed={currencyOptionsWithLastUsed}
          setLastUsedCurrencies={setLastUsedCurrencies}
          labelOptions={labelOptions}
        />
      )}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    currencyOptions: getCurrencyOptions(state),
    currencyOptionsWithLastUsed: getCurrencyOptionsWithLastUsed(state),
    user: state.user,
    openedPortfolio: getOpenedPortfolio(state),
    activeOrganizationId: getActiveOrganizationId(state),
    ruleSets: getRuleSets(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    bucketActions: bindActionCreators(bucketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionForm);
