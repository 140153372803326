import React from "react";
import { colors } from "common/colors";
import { Update as UpdateIcon } from "grommet-icons/icons";
import { ERROR, IN_PROGRESS, OK } from "common/constants/synchronizationStatusTypes";
import styles from "../Styles/DataManager.module.scss";

const SyncStatus = ({ statusType }) => (
  <>
    {statusType === OK && (
      <span
        style={{
          backgroundColor: colors.statusOk,
          height: "13px",
          width: "13px",
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "0.5rem",
        }}
      />
    )}
    {statusType === ERROR && (
      <span
        style={{
          backgroundColor: colors.statusError,
          height: "13px",
          width: "13px",
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "0.5rem",
        }}
      />
    )}
    {statusType === IN_PROGRESS && <UpdateIcon size="small" className={`mr-2 ${styles.rotate}`} color={colors.primary} />}
  </>
);

export default SyncStatus;
