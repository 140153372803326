import React, { useMemo } from "react";
import { Formik } from "formik";
import { SelectFormField } from "components/Common/Inputs";
import { PrimaryButton, Form } from "components/Common";
import { CheckBox } from "grommet";
import { useTranslation } from "react-i18next";
import { validateAssignContainers } from "common/validators";

import styles from "../Styles/Forms.module.scss";

const AssignContainersForm = ({ transactionContainerIds, portfolioId, portfolios, assignContainers, isPortfolioSelected }) => {
  const onSubmit = async (values, { setSubmitting }) => {
    await assignContainers(portfolioId, values.portfolio.value, transactionContainerIds, values.unassign);
    setSubmitting(false);
  };

  const selectValues = useMemo(() => {
    if (!isPortfolioSelected) return portfolios;
    return portfolios.filter(o => o.value !== portfolioId);
  }, [portfolios, portfolioId, isPortfolioSelected]);

  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{ portfolio: "", unassign: false }}
      validate={values => validateAssignContainers(values)}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit} className={styles.checkbox_form}>
          <SelectFormField
            name="portfolio"
            label={t("form.assign_containers.portfolio")}
            disabled={isSubmitting}
            options={selectValues}
            autoFocus
          />
          {isPortfolioSelected && (
            <div className="mb-3">
              <CheckBox
                checked={values.unassign}
                onChange={e => setFieldValue("unassign", e.target.checked)}
                label={t("form.assign_containers.unassign_from_source_ptf")}
              />
            </div>
          )}
          <PrimaryButton
            label={t("form.assign_containers.assign", { count: transactionContainerIds.length })}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AssignContainersForm;
