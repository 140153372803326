// This needs to be in sync with ./styles.module.scss
export const colors = {
  primary: "#1C6AE4",
  secondary: "#7CEA82",
  lightBlue: "#E3EDFB",

  gray1: "#EDEEF0",
  gray2: "#CED1D6",
  gray3: "#B5BAC2",
  gray4: "#6C7584",
  gray5: "#334055",

  gradientBlue: "rgb(28,106,228)",
  gradientBlue1: "rgba(28,106,228, 0.24)",
  gradientBlue2: "rgba(28,106,228, 0.64)",
  gradientGreen: "rgb(124,234,130)",
  graphGradient1: "#49C87B",
  graphGradient2: "#7CEA82",
  graphGradient3: "#E8FCE9",

  red: "#F64B66",
  rose: "#FDDEE3",
  yellow: "#FFD677",
  greyDark: "rgba(0,0,0,0.35)",
  greyBorder: "rgba(0,0,0,.15)",

  white: "#fff",
  black: "#000",
  text: "#334055",

  statusOk: "#49C87B",
  statusError: "#F64B66",
};
