import React, { useRef, forwardRef } from "react";
import { RadioButtonGroup } from "grommet";
import { useField } from "formik";
import cx from "classnames";
import { InputWrapper, LabelRow } from "./Shared";

import styles from "./Inputs.module.scss";

const RadioButtonsInput = ({
  onChange,
  label,
  error,
  required,
  isInline,
  isPlain,
  wrapperClassName,
  className,
  disabled,
  ...props
}) => {
  const inputRef = useRef();

  return (
    <InputWrapper
      className={cx(styles.radio_buttons_input, wrapperClassName)}
      disabled={disabled}
      inline={isInline}
      error={error}
      plain={isPlain}
      onClick={() => (disabled ? null : inputRef.current?.focus())}>
      <LabelRow label={label} error={error} required={required} />
      <RadioButtonGroup
        ref={inputRef}
        onChange={e => (disabled ? null : onChange(e.target.value))}
        className={cx(styles.radio_buttons, className)}
        name="radio"
        disabled={disabled}
        {...props}
      />
    </InputWrapper>
  );
};

export const RadioButtonsFormField = forwardRef(({ name, onSelect, ...props }, ref) => {
  const [field, meta, helpers] = useField(name);

  return (
    <RadioButtonsInput
      ref={ref}
      error={meta.touched ? meta.error : ""}
      {...field}
      onChange={val => {
        if (onSelect && typeof onSelect === "function") onSelect(val);
        helpers.setValue(val);
      }}
      {...props}
    />
  );
});

export default RadioButtonsInput;
