export const ON_ACQUISITION = "ON_ACQUISITION";
export const ON_SALE = "ON_SALE";

const t_fake = str => str;

const rewardTimingTranslations = {
  [ON_ACQUISITION]: t_fake("reward_timing.on_acquisition"),
  [ON_SALE]: t_fake("reward_timing.on_sale"),
};

export const rewardTimingTranslator = val => rewardTimingTranslations[val] || "";

export const rewardTimingOptions = t => [
  { label: t(rewardTimingTranslator(ON_ACQUISITION)), value: ON_ACQUISITION },
  { label: t(rewardTimingTranslator(ON_SALE)), value: ON_SALE },
];
