import cx from "classnames";
import React from "react";
import styles from "components/Common/Buttons/Buttons.module.scss";
import { getDataTestId } from "utils";
import { Button } from "./GhostButton";

const SecondaryButton = ({ className, critical, label, testId, ...props }) => (
  <Button
    className={cx(className, styles.secondary_button, { [styles.critical_secondary_button]: critical })}
    data-testid={getDataTestId("button", testId ?? label)}
    label={label}
    {...props}
  />
);

export default SecondaryButton;
