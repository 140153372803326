import { getActiveOrganizationPlan } from "selectors";
import { connect } from "react-redux";
import { ONLINE } from "common/constants/paymentPlans";

const SubscriptionPlanCheck = ({ organizationPlan, children, isVisibleFreePlan = true, isVisiblePaidPlan = false }) => {
  const isFreePlan = organizationPlan && organizationPlan === ONLINE;

  return (isFreePlan && isVisibleFreePlan) || (!isFreePlan && isVisiblePaidPlan) ? children : null;
};

function mapStateToProps(state) {
  return {
    organizationPlan: getActiveOrganizationPlan(state),
  };
}

export default connect(mapStateToProps)(SubscriptionPlanCheck);
