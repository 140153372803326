import React from "react";
import { connect } from "react-redux";
import { userActions } from "actions/userActions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import ChangePasswordForm from "../Forms/ChangePasswordForm";
import ModalEnvelope from "./ModalEnvelope";

const ChangePasswordModal = ({ userActions }) => {
  const onSubmit = async (values, { setSubmitting }) => {
    const { newPasswordConfirm, ...otherValues } = values;
    const { changePassword } = userActions;
    await changePassword(otherValues);
    setSubmitting(false);
  };
  const { t } = useTranslation();
  return (
    <ModalEnvelope id="change-password-modal" heading={t("modal.change_password.heading")}>
      <ChangePasswordForm onSubmit={onSubmit} />
    </ModalEnvelope>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ChangePasswordModal);
