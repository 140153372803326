import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ConfirmationEnvelope from "./ConfirmationEnvelope";

const ResendInvitation = ({ onConfirm, onCancel, email }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationEnvelope
      onConfirm={onConfirm}
      onCancel={onCancel}
      heading={t("confirmation.resend_invitation.heading")}
      confirmLabel={t("confirmation.resend_invitation.confirm_label")}>
      <p>
        <Trans i18nKey="confirmation.resend_invitation.content">
          Action will resend invitation to <b>{{ email }}</b>
        </Trans>
      </p>
    </ConfirmationEnvelope>
  );
};

export default ResendInvitation;
