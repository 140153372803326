import { colors } from "../colors";

export const grommetTheme = {
  global: {
    breakpoints: {
      small: {
        value: 900,
      },
      medium: {
        value: 1325,
      },
      large: {
        value: 5000,
      },
    },
    colors: {
      ...colors,
      focus: colors.primary,
      text: {
        light: colors.text,
      },
      placeholder: colors.greyBorder,
      blue: colors.primary,
      green: colors.gradientGreen,
      icon: colors.text,
    },
    font: {
      family: "'archivoregular', sans-serif",
      size: "1rem",
    },
    input: {
      weight: "normal",
      opacity: "unset",
    },
  },
  heading: {
    responsiveBreakpoint: undefined,
    weight: "normal",
    font: {
      family: "'archivoheavy', sans-serif",
    },
    extend: () => ({
      maxWidth: "unset",
    }),
  },

  icon: {
    size: {
      small: "12px",
      smallMedium: "18px", // TODO - implement this size for Text & Secondary buttons
      medium: "24px",
      mediumLarge: "34px",
      large: "48px",
      xlarge: "72px",
    },
    extend: undefined,
  },

  checkBox: {
    color: {
      light: colors.white,
    },
    check: {
      thickness: "2.5px",
      extend: ({ checked }) => ` 
          transition: border-color 0.2s;
          box-shadow: unset !important; 
          border-color: ${!checked ? colors.gray2 : colors.primary};
          height: 20px;
          width: 20px;
          border-radius: 2px !important;
          background-color: ${checked ? colors.primary : colors.white};
        `,
    },
    border: {
      width: "1px",
    },
    hover: {
      border: {
        color: colors.primary,
      },
    },
    toggle: {
      knob: {
        extend: () => `
          background-color: ${colors.white} !important;
          width: 22px;
          height: 22px;
          top: 0;
          left: 0;
          `,
      },
      extend: ({ checked }) => `
        border-color: ${checked ? colors.primary : colors.gray2} !important;
        background-color: ${checked ? colors.primary : colors.gray2};
        box-shadow: unset !important;
        &:hover, &:focus, &:active {
          border-color: ${checked ? colors.primary : colors.gray2} !important;
        }
      `,
    },
  },
  radioButton: {
    color: colors.primary,
    size: "18px",
    check: {
      color: colors.white,
      extend: ({ children }) => `
          transition: border-color 0.3s; 
          box-shadow: none !important;
          background-color: ${children ? colors.primary : colors.white};
        `,
    },
    border: {
      width: "1px",
    },
    hover: {
      border: {
        color: colors.primary,
      },
    },
  },

  table: {
    extend: () => `
      table-layout: unset !important;
    `,
  },

  textArea: {
    extend: () => `
      box-shadow: none !important;
    `,
  },

  rangeInput: {
    track: {
      height: "2px",
      color: colors.greyBorder,
    },

    thumb: {
      color: colors.text,
      extend: () => `
        transition: all 0.3s
      `,
    },
  },

  text: {
    xsmall: {
      size: "12px",
      height: "18px",
      maxWidth: "288px",
    },
    small: {
      size: "14px",
      height: "20px",
      maxWidth: "336px",
    },
    medium: {
      size: "16px",
      height: "22px",
      maxWidth: "432px",
    },
    large: {
      size: "22px",
      height: "28px",
      maxWidth: "528px",
    },
    xlarge: {
      size: "26px",
      height: "32px",
      maxWidth: "624px",
    },
    xxlarge: {
      size: "34px",
      height: "40px",
      maxWidth: "816px",
    },
  },
};
