import { createSelector } from "reselect";

export const getRawBuckets = state => state.buckets.buckets;

export const getRawBucketSelection = state => state.buckets.bucketSelection;

export const getBuckets = createSelector([getRawBuckets, getRawBucketSelection], (buckets, selection) =>
  buckets.map(o => ({
    ...o,
    isSelected: selection[o.id] === true,
  }))
);

export const getSelectedBuckets = createSelector([getBuckets], buckets => buckets.filter(o => o.isSelected));

export const getBucketTransactionsCount = createSelector([getRawBuckets], buckets => {
  const bucketsTransactionsCount = buckets.map(x => x.transactions.length);
  return bucketsTransactionsCount.length > 0 ? bucketsTransactionsCount.reduce((a, b) => a + b) : 0;
});

export const getBucketTransactionsFilter = state => state.buckets.transactionsFilter;
