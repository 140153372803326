import React, { useEffect, useMemo } from "react";
import styles from "components/Styles/DataManager.module.scss";
import { formatThousandsSeparator } from "common/formatters";
import useDecimalSeparator from "hooks/useDecimalSeparator";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { transactionService } from "services";
import { SMALL } from "common/constants/screenSizes";
import { LoaderOverlay } from "components/Common";
import { getContainerDetail, getExtendedContainerDetail } from "selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { transactionActions } from "actions/transactionActions";
import { sortArray } from "utils";

const Pairs = ({
  containerDetail: { id },
  extendedContainerDetail,
  user,
  transactionActions: { setExtendedContainerDetail },
}) => {
  const { t } = useTranslation();
  const commaAsDecimalSeparator = useDecimalSeparator();
  const isLoading = extendedContainerDetail.pairsLoading;
  const pairs = extendedContainerDetail.id === id ? extendedContainerDetail.pairs : null;

  const loadPairs = async () => {
    setExtendedContainerDetail(id, { pairsLoading: true });
    const response = await transactionService.getContainerDetailPairs(id, user);
    setExtendedContainerDetail(id, { pairs: response, pairsLoading: false });
  };

  useEffect(() => {
    if (id && user && !pairs && !isLoading) {
      loadPairs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user, pairs]);

  const sortedPairs = useMemo(() => sortArray(pairs, ["base", "quote"]), [pairs]);

  return (
    <LoaderOverlay isLoading={isLoading} spinnerSize={SMALL}>
      <div className={cx(styles.container_detail, "mt-3")} style={{ overflowX: "scroll" }}>
        <span className={styles.full_block} />
        <span>
          <b>{t("data_manager.container_detail.pairs")}</b>
        </span>
        <span className={styles.tx_pairs}>
          <span>
            <b>{t("data_manager.container_detail.base_bought")}</b>
          </span>
          <span>
            <b>{t("data_manager.container_detail.base_sold")}</b>
          </span>
        </span>

        <span className={styles.pair_values}>
          {sortedPairs?.map(({ base, quote }) => (
            <React.Fragment key={`${base}_${quote}`}>
              <div className="pt-1">{`${base} / ${quote}`}</div>
            </React.Fragment>
          ))}
        </span>
        <span className={styles.tx_pairs}>
          {sortedPairs?.map(({ base, quote, totalBaseBought, totalBaseSold }) => (
            <React.Fragment key={`${base}_${quote}`}>
              <div className="pt-1">{formatThousandsSeparator(totalBaseBought, commaAsDecimalSeparator)}</div>
              <div className="pt-1">{formatThousandsSeparator(totalBaseSold, commaAsDecimalSeparator)}</div>
            </React.Fragment>
          ))}
        </span>
      </div>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    containerDetail: getContainerDetail(state),
    extendedContainerDetail: getExtendedContainerDetail(state),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pairs);
