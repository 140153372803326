import { SUPPORT_GET_USERS, SUPPORT_GET_ORGANIZATIONS, SET_TRIAL_PERIOD, EDIT_ET_USER_DETAIL, LOGOUT } from "actions/types";

const initialState = {
  users: [],
  totalUsersCount: 0,
  isUsersFetching: false,
  organizations: [],
  totalOrganizationsCount: 0,
  isOrganizationsFetching: false,
};

export default function supportReducer(state = initialState, action) {
  switch (action.type) {
    case `${SUPPORT_GET_USERS}_PENDING`:
      return {
        ...state,
        isUsersFetching: true,
      };
    case `${SUPPORT_GET_USERS}_FULFILLED`:
      return {
        ...state,
        isUsersFetching: false,
        totalUsersCount: action.payload.meta.total,
        users: action.payload.data,
      };
    case `${SUPPORT_GET_USERS}_REJECTED`:
      return {
        ...state,
        isUsersFetching: false,
      };
    //= ========================================
    case `${SUPPORT_GET_ORGANIZATIONS}_PENDING`:
      return {
        ...state,
        isOrganizationsFetching: true,
      };
    case `${SUPPORT_GET_ORGANIZATIONS}_FULFILLED`:
      return {
        ...state,
        isOrganizationsFetching: false,
        totalOrganizationsCount: action.payload.meta.total,
        organizations: action.payload.data,
      };
    case `${SUPPORT_GET_ORGANIZATIONS}_REJECTED`:
      return {
        ...state,
        isOrganizationsFetching: false,
      };
    //= ========================================
    case `${SET_TRIAL_PERIOD}_PENDING`:
      return {
        ...state,
        isOrganizationsFetching: true,
      };
    case `${SET_TRIAL_PERIOD}_FULFILLED`:
      return {
        ...state,
        organizations: state.organizations.map(o => (o.id === action.payload.id ? action.payload : o)),
        isOrganizationsFetching: false,
      };
    case `${SET_TRIAL_PERIOD}_REJECTED`:
      return {
        ...state,
        isOrganizationsFetching: false,
      };
    //= ========================================
    case `${EDIT_ET_USER_DETAIL}_PENDING`:
      return {
        ...state,
        isUsersFetching: true,
      };
    case `${EDIT_ET_USER_DETAIL}_FULFILLED`:
      return {
        ...state,
        isUsersFetching: false,
        users: state.users.map(o =>
          o.userId === action.payload.userId
            ? {
                ...o,
                ...action.payload,
              }
            : o
        ),
      };
    case `${EDIT_ET_USER_DETAIL}_REJECTED`:
      return {
        ...state,
        isUsersFetching: false,
      };
    //= ========================================

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
