import React, { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { allTransactionTypeOptions } from "common/constants/transactionType";
import { sortArray } from "utils";
import styles from "containers/Styles/Forms.module.scss";
import { formatDate, formatTime, utc } from "common/formatters";
import { GhostButton, Heading, PrimaryButton } from "../Common";
import { CustomInput, DateInput, DecimalInput, SelectInput, TimeInput } from "../Common/Inputs";
import Animated from "../Common/Animated";
import { getCurrencyOptions, getFilterOptions, getSelectedContainers, getTransactionFilter, getTransactions } from "selectors";
import { connect } from "react-redux";

const operatorOptions = [
  { value: "EQUALS", label: "=" },
  { value: "GREATER_THAN", label: ">" },
  { value: "LOWER_THAN", label: "<" },
  { value: "EQUALS_GREATER_THAN", label: ">=" },
  { value: "EQUALS_LOWER_THAN", label: "<=" },
];

export const initialTransactionTableFilterValues = {
  pair: [],
  type: [],
  label: [],
  currency: [],
  amount: null,
  operator: null,
  dateFrom: null,
  timeFrom: null,
  dateTo: null,
  timeTo: null,
};

const TransactionsTableFilter = ({
  filteredBy,
  setFilteredBy,
  refs: { pairFilterRef, typeFilterRef, labelFilterRef },
  filterOptions,
  setTransactionsFilter,
  isVisible,
  currencyOptions,
  changeFilterAndFetch,
}) => {
  const { t } = useTranslation();

  const setFilterValues = newValues => setFilteredBy({ ...filteredBy, ...newValues });

  const onApplyFilter = () => {
    document.activeElement.blur();
    setTransactionsFilter(filteredBy);
    changeFilterAndFetch({ page: 0 });
  };
  const onResetFilter = () => {
    initFilterValues();
    setTransactionsFilter(initialTransactionTableFilterValues);
    changeFilterAndFetch({ page: 0 });
  };

  const initFilterValues = useCallback(() => setFilteredBy(initialTransactionTableFilterValues), [setFilteredBy]);

  const filteredPairOptions = useMemo(() => {
    const currencies = filteredBy?.currency?.map(x => x.value);
    return currencies?.length > 0
      ? filterOptions?.pairs.filter(x => currencies.some(currency => currency === x.base || currency === x.quote))
      : filterOptions?.pairs;
  }, [filteredBy, filterOptions]);

  const filteredCurrencies = useMemo(() => {
    const allowedCurrencies =
      filteredBy.pair?.length > 0
        ? [...filteredBy.pair.map(x => x.base), ...filteredBy.pair.map(x => x.quote)]
        : filterOptions?.currencies;

    return currencyOptions.filter(x => allowedCurrencies?.includes(x.code));
  }, [filteredBy, filterOptions, currencyOptions]);

  const options = useMemo(
    () => ({
      pairOptions: sortArray(filteredPairOptions, ["base", "quote"]),
      typeOptions: allTransactionTypeOptions(t).filter(x => filterOptions?.transactionTypes.includes(x.value)),
      labelOptions: filterOptions?.labels.map(o => ({ value: o, label: o })),
      currencyOptions: filteredCurrencies,
      operatorOptions,
    }),
    [t, filteredPairOptions, filterOptions, filteredCurrencies]
  );

  const isFilterDefault = JSON.stringify(filteredBy) === JSON.stringify(initialTransactionTableFilterValues);

  return (
    <Animated maxHeight={1000} isVisible={isVisible}>
      <Heading level={5} className="mt-4 mb-3">
        {t("data_manager.transaction_table.table_filter.currency.header")}
      </Heading>
      <Row noGutters>
        <Col xs={12} md className="mb-4 mb-md-0 mr-0 mr-md-4 pl-0 pl-md-1">
          <SelectInput
            value={filteredBy.currency ?? ""}
            onChange={value => setFilterValues({ currency: value, ...(!value && { amount: null, operator: null }) })}
            label={t("data_manager.transaction_table.table_filter.currency.currency")}
            options={options.currencyOptions}
            isInline
            isClearable
            isMulti
          />
        </Col>
        <Col xs={12} md className="mb-4 mb-md-0 mr-0 mr-md-4">
          <DecimalInput
            label={t("data_manager.transaction_table.table_filter.currency.amount")}
            value={filteredBy.amount ?? ""}
            onChange={value => setFilterValues({ amount: value, ...(!value && { operator: null }) })}
            isInline
            disabled={!filteredBy.currency}
            allowNegative={false}
          />
        </Col>
        <Col xs={12} md className="pr-0 pr-md-1">
          <SelectInput
            value={filteredBy.operator ?? ""}
            onChange={value => setFilterValues({ operator: value })}
            label={t("data_manager.transaction_table.table_filter.currency.operator")}
            options={options.operatorOptions}
            isInline
            isClearable
            disabled={!filteredBy.currency || !filteredBy.amount}
          />
        </Col>
      </Row>

      <Row className="my-3" noGutters>
        <Col xs={12} md className="mb-4 mb-md-0 mr-md-4 pl-0 pl-md-1">
          <SelectInput
            value={filteredBy.pair}
            onChange={value => setFilterValues({ pair: value })}
            label={t("data_manager.transaction_table.table_filter.pair")}
            options={options.pairOptions}
            isInline
            isClearable
            ref={pairFilterRef}
            getOptionValue={({ base, quote }) => `${base}/${quote}`}
            getOptionLabel={({ base, quote }) => `${base} / ${quote}`}
            maxMenuHeight={200}
            isMulti
          />
        </Col>
        <Col xs={12} md className="mb-4 mb-md-0 mr-md-4">
          <SelectInput
            value={filteredBy.type ?? []}
            onChange={value => setFilterValues({ type: value })}
            label={t("data_manager.transaction_table.table_filter.type")}
            options={options.typeOptions}
            isInline
            isClearable
            ref={typeFilterRef}
            isMulti
          />
        </Col>
        <Col xs={12} md className="mb-4 mb-md-0 pr-0 pr-md-1">
          <SelectInput
            value={filteredBy.label ?? []}
            onChange={value => setFilterValues({ label: value })}
            label={t("data_manager.transaction_table.table_filter.label")}
            options={options.labelOptions}
            isInline
            isClearable
            ref={labelFilterRef}
            isMulti
          />
        </Col>
      </Row>

      <Heading level={5} className="mt-4 mb-3">
        {t("data_manager.transaction_table.table_filter.date_header")}
      </Heading>
      <Row noGutters>
        <Col xs={12} md className="mb-4 mb-md-0 mr-md-4 pl-0 pl-md-1">
          <CustomInput
            wrapperClassName={styles.date_time_field_timestamp}
            label={t("data_manager.transaction_table.table_filter.date_from")}>
            <div className={styles.fields}>
              <DateInput
                value={filteredBy.dateFrom}
                onChange={value => {
                  setFilterValues({
                    dateFrom: value,
                    timeFrom: !filteredBy.timeFrom
                      ? utc(filteredBy.timeFrom ?? undefined).set({
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        })
                      : filteredBy.timeFrom,
                  });
                }}
                maxDate={filteredBy.dateTo}
                isPlain
                isCustomField
                isClearable
                onClear={() => setFilterValues({ dateFrom: null, timeFrom: null })}
              />
              <TimeInput
                value={formatTime(filteredBy.timeFrom, true)}
                isPlain
                isCustomField
                enableSeconds
                className={styles.time}
                onChange={(hours, minutes, seconds) => {
                  setFilterValues({
                    dateFrom: filteredBy.dateFrom ?? formatDate(new Date()),
                    timeFrom: utc(filteredBy.timeFrom ?? undefined).set({
                      hours,
                      minutes,
                      seconds,
                    }),
                  });
                }}
              />
            </div>
          </CustomInput>
        </Col>
        <Col xs={12} md className="mb-4 mb-md-0 mr-md-4">
          <CustomInput
            wrapperClassName={styles.date_time_field_timestamp}
            label={t("data_manager.transaction_table.table_filter.date_to")}>
            <div className={styles.fields}>
              <DateInput
                value={filteredBy.dateTo}
                onChange={value => {
                  setFilterValues({
                    dateTo: value,
                    timeTo: !filteredBy.timeTo
                      ? utc(filteredBy.timeTo ?? undefined).set({
                          hours: 23,
                          minutes: 59,
                          seconds: 59,
                        })
                      : filteredBy.timeTo,
                  });
                }}
                minDate={filteredBy.dateFrom}
                isPlain
                isCustomField
                isClearable
                onClear={() => setFilterValues({ dateTo: null, timeTo: null })}
              />
              <TimeInput
                value={formatTime(filteredBy.timeTo, true)}
                isPlain
                isCustomField
                enableSeconds
                className={styles.time}
                onChange={(hours, minutes, seconds) => {
                  setFilterValues({
                    dateTo: filteredBy.dateTo ?? formatDate(new Date()),
                    timeTo: utc(filteredBy.timeTo ?? undefined).set({
                      hours,
                      minutes,
                      seconds,
                    }),
                  });
                }}
              />
            </div>
          </CustomInput>
        </Col>
        <Col />
      </Row>

      <Row className="mt-3 mt-md-4 align-items-center" noGutters style={{ marginBottom: "1.7rem" }}>
        <Col xs={6} md={2}>
          <PrimaryButton label={t("data_manager.transaction_table.table_filter.apply")} onClick={onApplyFilter} />
        </Col>
        <Col xs={6} md={2}>
          <GhostButton
            label={t("data_manager.transaction_table.table_filter.reset")}
            onClick={onResetFilter}
            disabled={isFilterDefault}
          />
        </Col>
      </Row>
    </Animated>
  );
};

function mapStateToProps(state) {
  return {
    currencyOptions: getCurrencyOptions(state),
  };
}

export default connect(mapStateToProps)(TransactionsTableFilter);
