import React from "react";
import { CustomFormField, DateInput, SelectFormField, TextFormField, TimeInput } from "components/Common/Inputs";
import { formatTime, utc } from "common/formatters";
import { baseQuoteTypes, isTxSingleCurrency } from "common/constants/transactionType";
import { useTranslation } from "react-i18next";
import { disabledDays } from "utils";
import { FeeRebateField } from "containers/Forms/Transactions/TransactionTypes/TransactionFormFields";
import CurrencySelectFormField from "components/Common/CurrencySelect";
import styles from "../../../Styles/Forms.module.scss";

const TransactionFormContent = ({
  currencyOptionsWithLastUsed,
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
  transactionTypeOptions,
  getRate,
  txType,
  areFurtherInputsDisabled,
  submitButton,
  labelOptions,
  modalOptions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.fields_row}>
        <SelectFormField
          name="type"
          options={transactionTypeOptions}
          label={t("form.add_transaction.trade_type")}
          required
          disabled={isSubmitting}
          isClearable
          onSelect={value => {
            const newType = value?.value;

            if (newType && !txType) {
              return;
            }

            if (baseQuoteTypes.includes(newType) && baseQuoteTypes.includes(txType)) {
              return;
            }

            if (isTxSingleCurrency(newType) && isTxSingleCurrency(txType)) {
              return;
            }

            setFieldValue("baseCurrency", "");
            setFieldValue("quoteCurrency", "");
          }}
        />
        <CurrencySelectFormField
          name="baseCurrency"
          options={currencyOptionsWithLastUsed}
          label={t("form.add_transaction.currency")}
          required
          disabled={isSubmitting}
          isClearable
          onSelect={val => setFieldValue("quoteCurrency", val)}
        />
      </div>
      <CustomFormField
        name="timestamp"
        wrapperClassName={styles.date_time_field_timestamp}
        label={t("form.add_transaction.date")}
        required
        disabled={isSubmitting}>
        <div className={styles.fields}>
          <DateInput
            value={values.date}
            disabled={isSubmitting}
            isPlain
            isCustomField
            minDate={disabledDays.minDate}
            maxDate={disabledDays.maxDate}
            onBlur={() => setFieldTouched("timestamp", true)}
            onChange={value => {
              setFieldValue("date", value);
              if (values.baseCurrency && !areFurtherInputsDisabled) {
                getRate({ date: value, base: values.baseCurrency.value, quote: values.baseCurrency.value });
              }

              if (!values.timestamp) {
                setFieldValue(
                  "timestamp",
                  utc(values.timestamp ?? undefined).set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                  })
                );
              }
            }}
            isClearable
            onClear={() => {
              setFieldValue("date", null);
              setFieldValue("timestamp", null);
            }}
          />
          <TimeInput
            value={formatTime(values.timestamp, true)}
            isPlain
            isCustomField
            enableSeconds
            disabled={isSubmitting}
            className={styles.time}
            onChange={(hours, minutes, seconds) => {
              setFieldValue(
                "timestamp",
                utc(values.timestamp ?? undefined).set({
                  hours,
                  minutes,
                  seconds,
                })
              );
            }}
          />
        </div>
      </CustomFormField>

      <div className={styles.fields_row}>
        <FeeRebateField
          values={values}
          txType={txType}
          areFurtherInputsDisabled={areFurtherInputsDisabled}
          isSubmitting={isSubmitting}
          isUnboundTx
        />
        <TextFormField
          name="feeRebateNote"
          label={t("form.add_transaction.note")}
          disabled={isSubmitting || areFurtherInputsDisabled}
          autoFocus={modalOptions?.focusNote}
        />
      </div>

      <SelectFormField
        name="labels"
        options={labelOptions}
        label={t("form.add_transaction.labels")}
        disabled={isSubmitting || areFurtherInputsDisabled}
        isClearable
        isMulti
        autoFocus={modalOptions?.focusLabels}
      />

      {submitButton}
    </>
  );
};

export default TransactionFormContent;
