export const ACCOUNTANT = "ACCOUNTANT";
export const ANALYST = "ANALYST";
export const AUDITOR = "AUDITOR";
export const MANAGER = "MANAGER";
export const FAMILY = "FAMILY";
export const OTHER = "OTHER";

export const memberPositions = [ACCOUNTANT, FAMILY, OTHER];

const t_fake = str => str;

const memberPositionsTranslations = {
  [ACCOUNTANT]: t_fake("constant.member_position.accountant"),
  [ANALYST]: t_fake("constant.member_position.analyst"),
  [AUDITOR]: t_fake("constant.member_position.auditor"),
  [MANAGER]: t_fake("constant.member_position.manager"),
  [FAMILY]: t_fake("constant.member_position.family"),
  [OTHER]: t_fake("constant.member_position.other"),
};

export const memberPositionsTranslator = val => memberPositionsTranslations[val] || "";

export const memberPositionsOptions = t => [
  { value: ACCOUNTANT, label: t(`${memberPositionsTranslator(ACCOUNTANT)}`) },
  { value: ANALYST, label: t(`${memberPositionsTranslator(ANALYST)}`) },
  { value: AUDITOR, label: t(`${memberPositionsTranslator(AUDITOR)}`) },
  { value: MANAGER, label: t(`${memberPositionsTranslator(MANAGER)}`) },
  { value: FAMILY, label: t(`${memberPositionsTranslator(FAMILY)}`) },
  { value: OTHER, label: t(`${memberPositionsTranslator(OTHER)}`) },
];
