import React, { useEffect } from "react";
import { Controls } from "reactour";
import { CloseButton, PrimaryButton } from "components/Common";
import styles from "../Styles/Walkthrough.module.scss";
import { useTranslation } from "react-i18next";
import { PrimaryButtonSize, PrimaryButtonVariant } from "components/Common/Buttons/PrimaryButton";
import cx from "classnames";
import ImageIntro from "img/walkthrough/initial/intro.webp";
import { useScreenSize } from "hooks";

const InitialCategoryStep = props => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const { current, content, totalSteps, gotoStep, close, InitialStepImage, InitialStepImageMobile } = props;

  const Image = isSmall ? InitialStepImageMobile : InitialStepImage;

  return (
    <div className={styles.wizard_step_initial}>
      <img src={Image} className={styles.image} alt="" />

      <CloseButton onClick={close} className={styles.close_button_wrapper} />

      <div className={styles.divider} />

      {content}

      <Controls data-tour-elem="controls" className={styles.wizard_controls}>
        <div className={styles.steps}>
          {current + 1} / {totalSteps}
        </div>

        <PrimaryButton
          label={t("button.next")}
          className="w-auto"
          onClick={() => gotoStep(current + 1)}
          size={isSmall ? PrimaryButtonSize.Small : undefined}
        />
      </Controls>
    </div>
  );
};

const TourEnd = props => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const { content, close, onLastStepClick } = props;

  return (
    <div className={styles.wizard_step_initial}>
      <img src={ImageIntro} className={cx(styles.image, styles.intro)} alt="" />

      <CloseButton onClick={close} className={styles.close_button_wrapper} />

      <div style={{ width: "100%", marginTop: isSmall ? "166px" : "320px" }} />
      {content}
      <Controls data-tour-elem="controls" className={styles.wizard_controls}>
        <div className="w-100 d-flex justify-content-center justify-content-md-center mt-4 mt-md-0">
          <PrimaryButton
            label={t("walkthrough.exit_walkthrough")}
            className="w-auto"
            noIcon
            onClick={() => {
              onLastStepClick();
            }}
          />
        </div>
      </Controls>
    </div>
  );
};

const WalkThroughWizard = props => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();

  const { current, content, totalSteps, gotoStep, close, onLastStepClick, isLastTourCategory } = props;

  const isFirstStep = current === 0;
  const isLastStep = current === totalSteps - 1;
  const isTourEnd = isLastStep && isLastTourCategory;

  const handleRightArrowKey = e => {
    if (isLastStep && e.key === "ArrowRight") {
      e.stopPropagation();
      onLastStepClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleRightArrowKey);
    return () => {
      document.removeEventListener("keydown", handleRightArrowKey);
    };
  }, []);

  return isFirstStep ? (
    <InitialCategoryStep {...props} />
  ) : isTourEnd ? (
    <TourEnd {...props} />
  ) : (
    <div className={styles.wizard_step}>
      <CloseButton onClick={close} className={styles.close_button_wrapper} />
      {content}
      <Controls data-tour-elem="controls" className={styles.wizard_controls}>
        <div className={styles.steps}>
          {current + 1} / {totalSteps}
        </div>

        <div className="d-flex align-items-center" style={{ gap: "12px" }}>
          <PrimaryButton
            label={t("button.back")}
            size={isSmall ? PrimaryButtonSize.Small : undefined}
            rightIcon
            variant={PrimaryButtonVariant.OutlineBlue}
            onClick={() => gotoStep(current - 1)}
          />

          <PrimaryButton
            label={t("button.next")}
            size={isSmall ? PrimaryButtonSize.Small : undefined}
            onClick={() => {
              if (isLastStep && onLastStepClick) {
                onLastStepClick();
                return;
              }

              gotoStep(current + 1);
            }}
          />
        </div>
      </Controls>
    </div>
  );
};

export default WalkThroughWizard;
