import { JOB_DONE, JOB_ERROR } from "common/constants/jobStatusType";
import { jobService } from "services/jobService";
import { DELETE_CONTAINER_JOB, GET_CONTAINER_JOB, SET_CONTAINER_JOB_FETCHING } from "./types";
import { alertActions } from "./alertActions";

function monitorJobProgress(initialJob, onJobDone, onJobFail) {
  return async (dispatch, getState) => {
    const jobContainerId = initialJob.transactionContainerId;
    const isJobFetching = getState().jobs.jobsFetching?.[jobContainerId];

    if (isJobFetching) return;

    while (true) {
      dispatch(setContainerJobFetching(jobContainerId, true));

      const result = await new Promise(resolve =>
        setTimeout(async () => {
          const response = await dispatch(getJob(initialJob));
          resolve(response);
        }, 5000)
      );

      if (result && result.value.jobStatus === JOB_DONE) {
        if (onJobDone && typeof onJobDone === "function") onJobDone();
        break;
      }
      if (result && result.value.jobStatus === JOB_ERROR) {
        if (onJobFail && typeof onJobFail === "function") onJobFail();
        break;
      }
      if (!result || result.value.jobStatus === JOB_ERROR) break;
    }

    dispatch(setContainerJobFetching(jobContainerId, false));
  };
}

function getJob({ transactionContainerId, ...initialJob }) {
  return (dispatch, getState) =>
    dispatch({
      type: GET_CONTAINER_JOB,
      payload: jobService.getJob(transactionContainerId, getState().user),
      meta: { transactionContainerId, initialJob },
    }).catch(err => {
      alertActions.error(err);
      return null;
    });
}

function deleteContainerJob(containerId) {
  return {
    type: DELETE_CONTAINER_JOB,
    payload: containerId,
  };
}

function setContainerJobFetching(containerId, isFetching) {
  return {
    type: SET_CONTAINER_JOB_FETCHING,
    meta: { containerId, isFetching },
  };
}

export const jobActions = {
  monitorJobProgress,
  getJob,
  deleteContainerJob,
  setContainerJobFetching,
};
