import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CurrencyDecimal } from "components/Formating";
import { Heading, Table } from "../Common";
import styles from "../Styles/Modals.module.scss";
import cx from "classnames";

const AccountBalancesTable = ({ balances }) => {
  const { t } = useTranslation();

  const accountBalanceColumns = useMemo(
    () => [
      {
        Header: t("modal.components.account_balances_table.currency"),
        accessor: "currency",
        width: 80,
      },
      {
        Header: t("modal.components.account_balances_table.account_name"),
        accessor: "accountName",
        Cell: ({ value }) => <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{value}</div>,
      },
      {
        Header: <div style={{ textAlign: "right" }}>{t("modal.components.account_balances_table.balance")}</div>,
        accessor: "amount",
        Cell: ({ value, row }) => (
          <div
            style={{
              textAlign: "right",
              float: "right",
              whiteSpace: "nowrap",
            }}
            className={cx({ "negative-value": value < 0 })}>
            <CurrencyDecimal number={value} currencySuffix={row.original.currency} />
          </div>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      <Table
        columns={accountBalanceColumns}
        data={balances}
        className={styles.initial_balances_table}
        getRowId={row => row.id}
        fixedLayout
      />
      {balances.length === 0 && (
        <Heading className="pt-4 pb-3" level={5} color="gray3" margin="-1rem auto 1rem" textAlign="center">
          {t("modal.components.quote_table.no_balances")}
        </Heading>
      )}
    </>
  );
};

export default AccountBalancesTable;
