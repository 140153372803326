import React from "react";
import { useScreenSize } from "hooks";
import { FormUp as FormUpIcon, FormDown as FormDownIcon, FormSubtract as FormIcon } from "grommet-icons";

const ChangeIcon = ({ val }) => {
  const { isSmall } = useScreenSize();
  const size = isSmall ? "smallMedium" : "medium";
  return val === 0 ? <FormIcon size={size} /> : val > 0 ? <FormUpIcon size={size} /> : <FormDownIcon size={size} />;
};

export default ChangeIcon;
