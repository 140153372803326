import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Delimiter from "components/Common/Delimiter";
import { ReactComponent as DownIcon } from "img/DownIcon.svg";
import ExpandableMenu from "components/Common/ExpandableMenu";
import { getDataTestId } from "utils";
import styles from "../../Styles/AppStructure.module.scss";
import { getActiveOrganizationId } from "selectors";
import { connect } from "react-redux";
import { ReactComponent as Portfolio } from "img/menuIcons/portfolio.svg";
import { ReactComponent as DataManager } from "img/menuIcons/dataManager.svg";
import { ReactComponent as Transactions } from "img/menuIcons/transactions.svg";
import { ReactComponent as AssetAccounts } from "img/menuIcons/assetAccounts.svg";
import { ReactComponent as Reports } from "img/menuIcons/reports.svg";
import { ReactComponent as Settings } from "img/menuIcons/settings.svg";
import { organizationAccountingSettingsCategories } from "pages/OrganizationAccountingSettings";

const MainMenu = ({ activePortfolio, activeOrganizationId }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <li className={styles.align_center}>
        <NavLink
          className={styles.menu_link}
          activeClassName={styles.active_link}
          to={`/dashboard${activePortfolio === null ? "" : `/${activePortfolio}`}`}
          isActive={(_, location) => location.pathname.startsWith("/dashboard")}
          data-testid={getDataTestId("link", "portfolios")}
          data-tour="portfolio-link">
          <Portfolio />
          {t("header.header_menu.my_portfolios")}
        </NavLink>
      </li>
      <ExpandableMenu
        dataTour="data-manager-link"
        label={
          <span
            className={cx(styles.align_center, styles.menu_link, "align-items-center", {
              [styles.active_link]:
                location.pathname.startsWith("/datamanager") || location.pathname.startsWith("/asset-accounts"),
            })}>
            <DataManager style={{ marginRight: "8px" }} />
            {t("header.header_menu.data_manager")}
            <DownIcon className="mr-n1" />
          </span>
        }
        testId={getDataTestId("link-expandable", "data-manager")}>
        <li className={styles.align_center}>
          <NavLink
            className={styles.menu_link_nested}
            activeClassName={styles.active_link_nested}
            to={{
              pathname: "/datamanager/containers",
              search: activePortfolio === null ? "" : `?portfolioId=${activePortfolio}`,
            }}
            data-testid={getDataTestId("link", "transactions")}
            isActive={(_, location) => location.pathname.startsWith("/datamanager")}>
            <Transactions className="mr-2" />
            {t("header.header_menu.transactions")}
          </NavLink>
        </li>
        <li className={styles.align_center}>
          <NavLink
            className={styles.menu_link_nested}
            activeClassName={styles.active_link_nested}
            to={{
              pathname: "/asset-accounts",
              search: activePortfolio === null ? "" : `?portfolioId=${activePortfolio}`,
            }}
            isActive={(_, location) => location.pathname.startsWith("/asset-accounts")}
            data-testid={getDataTestId("link", "asset-accounts")}>
            <AssetAccounts className="mr-2" />
            {t("header.header_menu.accounts")}
          </NavLink>
        </li>
      </ExpandableMenu>

      <Delimiter />

      <li className={styles.align_center}>
        <NavLink
          className={styles.menu_link}
          activeClassName={styles.active_link}
          to={{
            pathname: "/reports",
            search: activePortfolio === null ? "" : `portfolioId=${activePortfolio}`,
          }}
          isActive={(_, location) => location.pathname === "/reports"}
          data-testid={getDataTestId("link", "reports")}
          data-tour="reports-link">
          <Reports />
          {t("header.header_menu.reports")}
        </NavLink>
      </li>

      <Delimiter />

      <li className={styles.align_center}>
        <NavLink
          className={styles.menu_link}
          activeClassName={styles.active_link}
          to={{
            pathname: `/organization-accounting-settings/${activeOrganizationId}/${organizationAccountingSettingsCategories[0]}`,
            search: activePortfolio === null ? "" : `portfolioId=${activePortfolio}`,
          }}
          isActive={(_, location) => location.pathname.startsWith(`/organization-accounting-settings/${activeOrganizationId}`)}
          data-testid={getDataTestId("link", "settings")}
          data-tour="settings-link">
          <Settings />
          {t("header.header_menu.settings")}
        </NavLink>
      </li>
    </>
  );
};

function mapStateToProps(state) {
  return {
    activeOrganizationId: getActiveOrganizationId(state),
  };
}

export default connect(mapStateToProps)(MainMenu);
