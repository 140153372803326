import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { alertActions } from "actions/alertActions";
import { DateFormField, RadioButtonsFormField, SelectFormField } from "components/Common/Inputs";
import { Form, LoaderOverlay, PrimaryButton } from "components/Common";
import { validatePortfolioPredecessor } from "common/validators";
import { getFiatOptions, getPortfoliosToSelect, getPortfoliosToSelectExcludingCurrent } from "selectors";
import { portfolioService } from "services";
import {
  dateSelectOptionTranslator,
  dateSelectOptionValues,
  SOURCE_PORTFOLIO_END,
  SOURCE_PORTFOLIO_LAST_TRANSACTION,
  USER_SPECIFIED,
} from "common/constants/dateSelectOptions";
import { useTranslation } from "react-i18next";
import { MEDIUM, SMALL } from "common/constants/screenSizes";

import { formatDate } from "common/formatters";
import AccountBalancesTable from "components/Modals/AccountBalancesTable";
import styles from "../../Styles/Forms.module.scss";
import { sortBy } from "lodash";

const CopyAccountBalancesForm = ({
  onClose,
  isEdit,
  accountOptions,
  allPortfolios,
  portfoliosWithoutCurrent,
  user,
  currentSourcePortfolioId,
  fiatOptions,
}) => {
  const [isBalancesFetching, setIsBalancesFetching] = useState(false);
  const [isOptionsFetching, setIsOptionsFetching] = useState(false);
  const [selectOptions, setSelectOptions] = useState(false);
  const [finalState, setFinalState] = useState([]);
  const { t } = useTranslation();

  const sortedInitialBalances = useMemo(() => {
    const balancesWithCurrencyType = finalState.map(x => ({
      ...x,
      // Fiat first, then crypto
      currencyType: fiatOptions.some(y => y.value === x.currency) ? "FIAT" : "KRYPTO",
      lowerCaseName: x.accountName.toLowerCase(),
    }));

    return balancesWithCurrencyType ? sortBy(balancesWithCurrencyType, x => [x.lowerCaseName, x.currencyType, x.currency]) : [];
  }, [finalState, fiatOptions]);

  const onSubmit = values => {
    const formattedValues = {
      accountInitialBalances: {
        balances: sortedInitialBalances.map(({ id, amount }) => ({
          account: accountOptions.find(x => x.value.id === id),
          initialBalance: amount,
        })),
        portfolio: values.portfolio,
        timestamp: new Date(values.timestamp).valueOf(),
        updatedAt: Date.now(),
      },
    };

    onClose?.(formattedValues);
  };

  const getAccountBalances = async values => {
    setIsBalancesFetching(true);
    try {
      const res = await portfolioService.getPortfolioFinalBalances({ ...values, collapse: false }, user);
      setFinalState(res.accountStates);
      setIsBalancesFetching(false);
    } catch (err) {
      setIsBalancesFetching(false);
    }
  };

  const [hasEnd, setHasEnd] = useState(true);
  const getDateOptions = async (portfolioId, setFieldValue) => {
    setIsOptionsFetching(true);
    try {
      const res = await portfolioService.getPortfolioFinalBalancesDateOptions(portfolioId, user);
      const resObj = res.reduce((res, val) => {
        res[val.type] = { timestamp: val.timestamp || null };
        return res;
      }, {});
      setSelectOptions(resObj);
      setIsOptionsFetching(false);
      if (res.length === 0) {
        alertActions.error(t("alert.error.portfolio_no_assignments"));
        setFieldValue("dateType", null);
        setFieldValue("timestamp", "");
        setFinalState([]);
      } else {
        const hasEnd = resObj[SOURCE_PORTFOLIO_END].timestamp !== null;
        setHasEnd(hasEnd);
        const dateType = hasEnd ? SOURCE_PORTFOLIO_END : SOURCE_PORTFOLIO_LAST_TRANSACTION;
        const timestamp = hasEnd ? resObj[SOURCE_PORTFOLIO_END].timestamp : resObj[SOURCE_PORTFOLIO_LAST_TRANSACTION].timestamp;
        setFieldValue("dateType", dateType);
        setFieldValue("timestamp", formatDate(timestamp));
        getAccountBalances({ portfolioId, dateType, timestamp });
      }
    } catch (err) {
      setIsOptionsFetching(false);
    }
  };

  const dateSelectOptions = useMemo(
    () => (hasEnd ? dateSelectOptionValues : dateSelectOptionValues.filter(x => x !== SOURCE_PORTFOLIO_END)),
    [hasEnd]
  );

  const portfolioOptions = isEdit ? portfoliosWithoutCurrent : allPortfolios;

  return (
    <Formik
      initialValues={{
        portfolio: portfolioOptions.find(x => x.value === currentSourcePortfolioId) ?? "",
        dateType: "",
        timestamp: "",
      }}
      validate={values => validatePortfolioPredecessor(values)}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
        useEffect(() => {
          if (values.portfolio?.value) {
            getDateOptions(values.portfolio.value, setFieldValue);
          }
        }, [values.portfolio]);

        return (
          <Form onSubmit={handleSubmit}>
            <SelectFormField
              name="portfolio"
              options={portfolioOptions}
              label={t("form.portfolio_predecessor.source_portfolio")}
              required
              disabled={isSubmitting}
            />
            <LoaderOverlay isLoading={isOptionsFetching} spinnerSize={MEDIUM}>
              <b>{t("form.portfolio_predecessor.date_strategy_heading")}</b>
              <div className={styles.fields_row}>
                <RadioButtonsFormField
                  name="dateType"
                  disabled={isSubmitting || isBalancesFetching}
                  isPlain
                  className={styles.radio_buttons_block_group}
                  options={dateSelectOptions.map(o => ({
                    value: o,
                    label: t(`${dateSelectOptionTranslator(o)}`),
                    disabled: selectOptions[o] === undefined,
                    timestamp: selectOptions[o] ? selectOptions[o].timestamp : null,
                  }))}
                  onSelect={value => {
                    setFinalState([]);
                    const timestamp = selectOptions[value]?.timestamp || values.timestamp;
                    setFieldValue("dateType", value);
                    setFieldValue("timestamp", formatDate(timestamp));

                    getAccountBalances({
                      ...values,
                      portfolioId: values.portfolio.value,
                      dateType: value,
                      timestamp,
                    });
                  }}
                />
                <DateFormField
                  name="timestamp"
                  label={t("form.portfolio_predecessor.date")}
                  disabled={isSubmitting || isBalancesFetching || values.dateType !== USER_SPECIFIED}
                  inputProps={{ readOnly: true }}
                  onChange={val => getAccountBalances({ ...values, portfolioId: values.portfolio.value, timestamp: val })}
                  className={styles.inline_field}
                  wrapperClassName={styles.height_fit_content}
                />
              </div>
              <LoaderOverlay spinnerSize={SMALL} isLoading={isBalancesFetching && !isOptionsFetching}>
                <AccountBalancesTable balances={sortedInitialBalances} fiatOptions={fiatOptions} />
              </LoaderOverlay>
            </LoaderOverlay>
            <PrimaryButton
              label={t("form.portfolio_predecessor.copy_balances")}
              isLoading={isBalancesFetching || isOptionsFetching}
              onClick={handleSubmit}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    allPortfolios: getPortfoliosToSelect(state),
    portfoliosWithoutCurrent: getPortfoliosToSelectExcludingCurrent(state),
    user: state.user,
    fiatOptions: getFiatOptions(state),
  };
}

export default connect(mapStateToProps)(CopyAccountBalancesForm);
