import { marketDataService } from "services";
import {
  GET_EXCHANGES_DATA,
  GET_CONNECTORS,
  GET_GEOGRAPHICS,
  // WEBSOCKET_CONNECT,
  // WEBSOCKET_DISCONNECT,
} from "./types";
import { alertActions } from "./alertActions";

function getExchangeData() {
  return dispatch =>
    dispatch({
      type: GET_EXCHANGES_DATA,
      payload: marketDataService.getExchangeData(),
    }).catch(err => {
      alertActions.error(err);
    });
}

function getConnectors() {
  return dispatch =>
    dispatch({
      type: GET_CONNECTORS,
      payload: marketDataService.getConnectors(),
    }).catch(err => {
      alertActions.error(err);
    });
}

function getGeographicInfo() {
  return dispatch =>
    dispatch({
      type: GET_GEOGRAPHICS,
      payload: marketDataService.getGeographicInfo(),
    }).catch(err => {
      alertActions.error(err);
    });
}

// function openWsConnection(url, coins, token) {
//   return {
//     type: WEBSOCKET_CONNECT,
//     payload: {
//       url,
//       coins,
//       token,
//     }
//   }
// }

// function closeWsConnection(){
//   return {
//     type: WEBSOCKET_DISCONNECT,
//   };
// }

export const marketDataActions = {
  getExchangeData,
  getConnectors,
  getGeographicInfo,
  // openWsConnection,
  // closeWsConnection,
};
