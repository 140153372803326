import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "grommet-icons/icons";
import { modalActions } from "actions/modalActions";
import { GhostButton, LoaderOverlay } from "components/Common";
import { getChartOfAccountAccounts, getGlobalFetchingState } from "selectors";
import { MEDIUM } from "common/constants/screenSizes";
import { useUrlHelper } from "hooks";
import { organizationActions } from "actions/organizationActions";
import { chartOfAccountNameTranslator } from "common/constants/chartOfAccountNames";
import { currencyTypeTranslator } from "common/constants/currencyTypes";
import { chartOfAccountTypeTranslator } from "common/constants/chartOfAccountTypes";

const Accounts = ({
  isLoading,
  chartOfAccountAccounts,
  modalActions: { openEditAssetAccountAccount },
  organizationActions: { getChartOfAccountAccounts },
}) => {
  const { t } = useTranslation();
  const { id: organizationId } = useUrlHelper();

  useEffect(() => {
    if (organizationId) getChartOfAccountAccounts(organizationId);
  }, [getChartOfAccountAccounts, organizationId]);

  return (
    <LoaderOverlay isLoading={isLoading} spinnerSize={MEDIUM}>
      <table>
        <thead>
          <tr>
            <th>{t("organization_chart_of_account.table_header.type")}</th>
            <th>{t("organization_chart_of_account.table_header.currency")}</th>
            <th>{t("organization_chart_of_account.table_header.account_number")}</th>
            <th>{t("organization_chart_of_account.table_header.account_name")}</th>
            <th>{t("organization_chart_of_account.table_header.description")}</th>
            <th className="float-right">{t("organization_chart_of_account.table_header.action")}</th>
          </tr>
        </thead>

        <tbody>
          {chartOfAccountAccounts?.map((account, index) => (
            <tr key={index}>
              <td>{t("organization_chart_of_account.table.account")}</td>
              <td>{account.currencyTypes?.map(x => t(currencyTypeTranslator(x)))?.join(", ")}</td>
              <td>{account.accountNumber}</td>
              <td>{t(chartOfAccountNameTranslator(account.accountCode))}</td>
              <td>{t(chartOfAccountTypeTranslator(account.accountType))}</td>
              <td>
                <div style={{ textAlign: "right", float: "right" }}>
                  <GhostButton
                    actionButton
                    onClick={() =>
                      openEditAssetAccountAccount(
                        organizationId,
                        account,
                        chartOfAccountAccounts.map(x => x.accountNumber)
                      )
                    }
                    icon={<EditIcon />}
                    testId={`action-edit_row-${index + 1}`}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </LoaderOverlay>
  );
};

function mapStateToProps(state) {
  return {
    chartOfAccountAccounts: getChartOfAccountAccounts(state),
    isLoading: state.organizations.chartOfAccountAccountsFetching && !getGlobalFetchingState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
